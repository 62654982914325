import React, { useContext, useState, useRef, useEffect } from "react";
import classNames from "classnames";
import axios from "axios";
import { useFlashMessage } from "../context/FlashMessageContext";
//import Select2 from "react-chosen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { post } from "../services/apiService";
import { useNavigate } from "react-router-dom";
import FlagIcon from "./FlagIcon";
import DialCodes from "../data/dialCodes.json";
import SelectBox from "./Select";
import Input from "./Input";

const ExporterInfoForm = (props) => {

  const {
    formId,
    loadingCallback,
    navigate,
    setOpen,
    setWarningMessage,
    setShowNestedModal,
    setNestedModalData,
    setFilteredExporters,
    filteredExporter,
    state,
    setExporter,
    setShowModal,
  } = props;
  const { countryList } = state;
  const [country, setCountry] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState("");
  const [cityList, setCityList] = useState([]);
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedExporter, setSelectedExporter] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [showNewCustomerForm, setShowNewCustomerForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(true);

  const page = 1;
  const perPage = 20;
  const token = localStorage.getItem("token");
  const getExporterListData = async (searchKeyword = "") => {
    loadingCallback(true);
    const page1 = 1;
    console.log(searchKeyword);
    if (searchKeyword === "") {
      const options = [];
      axios
        .get(`${process.env.REACT_APP_API_URL}exporter-list`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            const customers = response?.data?.data?.data
              ? response?.data?.data?.data
              : response.data.data;
            // Append other options if there are customers
            if (customers.length > 0) {
              const customerOptions = customers.map((customer) => ({
                id: customer.id,
                name: customer.name,
                email: customer.email,
                phone_no: customer.phone_no,
                address: customer.address,
                additional_phone_no: customer.additional_phone_no,
                org_name: customer.org_name,
                org_email: customer.org_email
              }));
              options.push(...customerOptions);
            }
          } else {
            console.log("Data Not Found!");
          }
          setFilteredExporters(options);
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    } else {
      setFilteredExporters([]);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}search-exporter?page=${page1}&limit=${perPage}`,
          { keyword: searchKeyword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          loadingCallback(false);
          const options = [];
          if (response.status === 200) {
            //setfilteredExporter(response.data.data.data);
            const customers = response?.data?.data?.data
              ? response.data.data.data
              : response.data.data;

            // Append other options if there are customers
            if (customers.length > 0) {
              const customerOptions = customers.map((customer) => ({
                id: customer.id,
                name: customer.name,
                email: customer.email,
                phone_no: customer.phone_no,
                address: customer.address,
                additional_phone_no: customer.additional_phone_no,
                org_name: customer.org_name,
                org_email: customer.org_email,
              }));
              options.push(...customerOptions);
            }
          } else {
            console.log("Data Not Found!");
            const customerOptions = [];
            options.push(customerOptions);
          }
          setFilteredExporters(options);
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  };
  useEffect(() => {
    getExporterListData(searchKeyword);
  }, [searchKeyword]);
  // useEffect(() => {
  //   if (selectedExporter && selectedExporter.country) {
  //     setCountry(selectedExporter.country);
  //     getDistrictList(selectedExporter.country);
  //     if (selectedExporter && selectedExporter.district) {
  //       setDistrict(selectedExporter.district);
  //       getCityList(selectedExporter.district);
  //     }
  //   } else {
  //     getDistrictList(country);
  //   }
  // }, [country, selectedExporter]);


  const handleValidation = (formData) => {
    const errors = {};
    let validate = true;
    // if (formData.customer_email==='') {
    //   errors.customer_email = 'Email is required.';
    //   setIsValid(false);
    //   validate=false;
    // }
    if (formData.customer_email && formData.customer_email !== "") {
      if (formData.customer_email.indexOf("@") === -1) {
        errors.customer_email = "Email is not valid.";
        setIsValid(false);
        validate = false;
      } else {
        //Email address cannot have only numbers before @
        const emailParts = formData.customer_email.split("@");
        if (emailParts[0].match(/^[0-9]+$/) !== null) {
          errors.customer_email = "Email is not valid.";
          setIsValid(false);
          validate = false;
        }
      }
    }
    if (formData.customer_name === "") {
      errors.customer_name = "Name is required.";
      setIsValid(false);
      validate = false;
    }

    if (formData.customer_phone === "") {
      errors.customer_phone = "Phone is required.";
      setIsValid(false);
      validate = false;
    }
    setFormErrors(errors);
    if (!validate) {
      setErrorFlashMessage("Please fill all the required fields.");
    }

    return validate;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formObject = {};

    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    if (!selectedExporter) {
      formObject.id = 0;
    }
    if (handleValidation(formObject)) {
      // console.log("============");
      // console.log(isValid);
      // console.log(formObject);
      // console.log(formErrors);
      //if (isValid) {
      const customerData = {
        name: formObject.name ? formObject.name : null,
        email: formObject.email ? formObject.email : null,
        phone_no: formObject.phone_number ? formObject.phone_number : null,
        additional_phone_no: formObject.additional_phone_number
          ? formObject.additional_phone_number
          : null,
        org_name: formObject.organization_name
          ? formObject.organization_name
          : null,
        org_email: formObject.organization_email
          ? formObject.organization_email
          : null,
        address: formObject.address ? formObject.address : null,
      };
      // console.log("============");
      // console.log(customerData);
      // console.log(selectedExporter);

      if (selectedExporter) {
        customerData.id = Number(selectedExporter?.id);
        //navigate(`/add-exporter/${selectedExporter.id}`);
        updateCustomer(customerData);
      } else {
        //createCustomer(customerData);
        //setExporter(response.data);
        setShowModal(false);
        //setSuccessFlashMessage('customer saved successfully');
        setShowNewCustomerForm(false);
      }
    } else {
      console.error("Form validation failed.");
    }
  };

  const updateCustomer = (formData) => {
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
    post("save-exporter", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          setExporter(response.data);
          //setShowModal(false);
          setSuccessFlashMessage("Exporter updated successfully");
          addExporterOrder(selectedExporter.id);
          //navigate(`/add-exporter/${selectedExporter.id}`);
        } else {
          console.log("Exporter update failed");
          setErrorFlashMessage("Exporter update failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
    //}
  };

  const addExporterOrder = (exporter_id) => {
    const formData = {
      exporter_id: exporter_id,
    };
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
    post("save-exporter-order", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          setExporter(response.data);
          setShowModal(false);
          setSuccessFlashMessage("Exporter added successfully");
          navigate(`/add-exporter/${response.data.id}`);
        } else {
          console.log("Exporter update failed");
          setErrorFlashMessage("Exporter update failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
    //}
  };

  const handleErrorInputChange = (fieldName, value) => {
    if (value !== "") {
      //formErrors[fieldName] = null;
      // unset formErrors by fieldName
      const updatedFormErrors = { ...formErrors };
      delete updatedFormErrors[fieldName];
      setFormErrors(updatedFormErrors);
    }
  };
  const handleallInputChange = (fieldName, value) => {

      // Update the selectedExporter state with the modified field
      setSelectedExporter((prevCustomer) => ({
        ...prevCustomer,
        [fieldName]: value,
      }));
  };
  const handleInputChange = (inputValue) => {
    //if (action && action === 'input-change') {
    // Perform an API call using the inputValue to fetch the filtered options
    // Update the options state with the fetched data
    setSearchKeyword(inputValue);
    //}
  };

  const handleSelectChange = (name, option) => {
    const { value, label } = option;
    if (value === "") {
      setSelectedExporter(null);
      return;
    }
    // console.log("============");
    // console.log(value);
    // setSearchKeyword(value);
    const exporterId = parseInt(value);
    const selectedExporter = filteredExporter.find(
      (customer) => customer.id === exporterId
    );
    setSelectedExporter(selectedExporter);
    setShowNewCustomerForm(false);

  };

  const handleShowAddNewExporterModal = () => {
    setNestedModalData({
      title: "Add New Exporter",
      type: "add_new_exporter",
      setData: setSelectedExporter,
    });
    setShowNestedModal(true);
  }

  return (
    <form
      method="get"
      className="col-sm-12 min-h-[10vh]"
      data-autosubmit="false"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-row gap-3 mt-3">
        <div className="w-[90%]">
          <SelectBox
            name="exporter_id"
            options={filteredExporter}
            value={selectedExporter ? selectedExporter.id : ""}
            //onChange={handleCustomerSelect}
            transparentBorder={false}
            onInputChangeData={handleInputChange}
            onChange={handleSelectChange.bind(this, "exporter_id")}
            placeholder={"Name/Email"}
            className={classNames("w-full", "rounded-[15px]", "bg-white")}
          />
          {/* <Input
            name={"manufactory_date"}
            label="Exporter Name/Email"
            placeholder={"Name/Email"}
            value=""
          ></Input> */}
        </div>
        <label htmlFor="nested_modal" onClick={handleShowAddNewExporterModal}>
          <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-3 py-2 rounded-[10px] cursor-pointer"></i>
        </label>
      </div>

      {selectedExporter && (
        <div className="flex flex-col gap-3 mt-3">
          <div className="mb-3 relative">
            <input
              type="text"
              id="name"
              name="name"
              className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
              placeholder="Name"
              value={selectedExporter.name ? selectedExporter.name : ""}
              onChange={(e) => {
                handleallInputChange("name", e.target.value);
                handleErrorInputChange("name", e.target.value);
              }}
            />
            {formErrors && formErrors.name ? (
              <p className="error-message text-red-500">{formErrors.name}</p>
            ) : null}
            <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
              Name
            </label>
          </div>
          <div className="mb-3 relative">
            <input
              type="text"
              id="email"
              name="email"
              className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
              placeholder="Email"
              value={selectedExporter.email ? selectedExporter.email : ""}
              onChange={(e) => {
                handleallInputChange("email", e.target.value);
                handleErrorInputChange("email", e.target.value);
              }}
            />
            {formErrors && formErrors.email ? (
              <p className="error-message text-red-500">{formErrors.email}</p>
            ) : null}
            <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
              Email
            </label>
          </div>
          <div className="mb-3 relative">
            <input
              type="text"
              id="phone"
              name="phone_number"
              className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
              placeholder="Phone Number"
              value={selectedExporter.phone_no ? selectedExporter.phone_no : ""}
              onChange={(e) => {
                handleallInputChange("phone_no", e.target.value);
                handleErrorInputChange("phone_no", e.target.value);
              }}
            />
            {formErrors && formErrors.phone_no ? (
              <p className="error-message text-red-500">
                {formErrors.phone_no}
              </p>
            ) : null}
            <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
              Phone Number
            </label>
          </div>
          <div className="mb-3 relative">
            <input
              type="text"
              id="additional_phone_no"
              name="additional_phone_no"
              className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
              placeholder="Additional Phone Number"
              value={
                selectedExporter.additional_phone_no
                  ? selectedExporter.additional_phone_no
                  : ""
              }
              onChange={(e) =>
                handleallInputChange("additional_phone_no", e.target.value)
              }
            />
            <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
              Additional Phone Number
            </label>
          </div>
          <div className="mb-3 relative">
            <input
              type="text"
              id="address"
              name="address"
              className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
              placeholder="Address"
              value={selectedExporter.address ? selectedExporter.address : ""}
              onChange={(e) => handleallInputChange("address", e.target.value)}
            />
            <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
              Address
            </label>
          </div>
          <div className="mb-3 relative">
            <input
              type="text"
              id="organization_name"
              name="organization_name"
              className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
              placeholder="Organization Name"
              value={selectedExporter.org_name ? selectedExporter.org_name : ""}
              onChange={(e) => handleallInputChange("org_name", e.target.value)}
            />
            <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
              Organization Name
            </label>
          </div>
          <div className="mb-3 relative">
            <input
              type="text"
              id="organization_email"
              name="organization_email"
              className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
              placeholder="Organization Email"
              value={
                selectedExporter.org_email ? selectedExporter.org_email : ""
              }
              onChange={(e) =>
                handleallInputChange("org_email", e.target.value)
              }
            />
            <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
              Organization Email
            </label>
          </div>
        </div>
      )}

      <div className="mt-4">
        <input
          type="submit"
          value="Next"
          className="btn btn-primary w-full text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF]"
        />
      </div>
    </form>
  );
};

export default ExporterInfoForm;
