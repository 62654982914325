import React, { useContext, useState, useRef, useEffect } from "react";
import Breadcrumb from '../Settings_components/Breadcrumb';
import Table from '../Settings_components/Table';
import { AppContext } from '../../../context/context.js';
import Pagination from '../../../utils/pagination';
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

//import { brands } from '../../../data/data';

import axios from "axios";
const Brands = () => {
    const {
        state,
        isLoading,
        updateBrandList
    } = useContext(AppContext);

    //---------------------
    const location = useLocation();
    const navigate = useNavigate();
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
      const employeeApiResponse = localStorage.getItem("employee");

      if (!employeeApiResponse) {
        setHasPermission(true);
        return; // Exit useEffect early
      }

      if (employeeApiResponse) {
        // Parse the employee data from local storage
        const employeeData = JSON.parse(employeeApiResponse);

        // Get the "Exporter" feature from the employee's role features
        const settingsFeature = employeeData.role.features.find(
          (feature) => feature.name === "Settings"
        );

        // Check if the "Exporter" feature and "Visible" option are both active
        if (settingsFeature && settingsFeature.status === 1) {

          // Grant access based on the route and permissions
          if (location.pathname === "/settings/brands") {
            setHasPermission(true);
            return; // Exit useEffect if permission is granted
          }
        }
        setHasPermission(false);
        // If no permission, navigate to dashboard first
        navigate("/dashboard", { replace: true });
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Permission Denied",
            text: "You do not have permission for this feature.",
          });
        }, 100);
      }
      // If not an employee, the useEffect gets skipped
    }, [navigate, location]);
    //---------------------

    const token = localStorage.getItem("token");
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;

    useEffect(() => {
      if(hasPermission){
        getBrand(currentPage);
      }
    }, [currentPage,hasPermission]);
    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const [loader, setLoader] = useState(true);

    const getBrand = (page) => {

        axios
        .get(
            `${process.env.REACT_APP_API_URL}brands?page=${page}&limit=${perPage}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        )
        .then((response) => {

            //console.log(response.data.data.data)

            if(response.data.success)
            {
                setLoader(false)
                //setBrands(response.data.data.data);
                updateBrandList(response.data.data)
                console.log("============");
                console.log(response.data.data);

            }

        })
        .catch((error) => {
            console.log(error);
        });
    };
    const shouldRenderPagination = state.brandList?.total > perPage;


    return (
        <div className='rounded-[27px]'  style={{backgroundColor: 'rgba(0, 184, 148, 0.15)' }}>
            <Breadcrumb></Breadcrumb>
           <Table data={state.brandList} type="brands" getBrand={getBrand}></Table>
           {shouldRenderPagination && (
                <Pagination
                    currentPage={currentPage}
                    lastPage={state.brandList.last_page}
                    onPageChange={onPageChange}
                />
            )}
            <div className="w-full relative px-10 md:px-[27px] overflow-x-auto sm:rounded-lg">
                {loader && (
                    <div className="custom-loader"></div>
                )}
            </div>
        </div>
    );
};

export default Brands;