import React, { useContext, useEffect, useState } from 'react';
import ContentLayout from '../../layout/ContentLayout';
import Analytics from '../../components/Analytics/Analytics';



const AnalyticsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);
  return (
    <ContentLayout>
       <Analytics/>
    </ContentLayout>
  );
};

export default AnalyticsPage;