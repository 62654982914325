import React from 'react';

const CustomCheckbox = ({ label, name, checked, onChange }) => {
  return (
    <div className="relative flex items-center">
      {/* Hidden checkbox */}
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className="absolute opacity-0 w-4 h-4"
      />

      {/* Custom radio button style */}
      <div className="w-4 h-4 border-2 border-[#407BFF] rounded-full flex items-center justify-center">
        {checked && <div className="w-2 h-2 bg-[#407BFF] rounded-full"></div>}
      </div>

      {/* Label */}
      <label htmlFor={name} className="ml-2 text-[#102048]">
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
