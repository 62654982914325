// import React from 'react';

// const DeliverySettings = () => {
//   return (
//     <div>
//       <h2>hello</h2>
//     </div>
//   );
// };

// export default DeliverySettings;


import React, { useContext, useState, useRef, useEffect } from "react";
import Breadcrumb from '../Settings_components/Breadcrumb';

import { AppContext } from '../../../context/context.js';
import Pagination from '../../../utils/pagination';

import axios from "axios";
import PaymentTableItems from "../Settings_components/PaymentTableItems.js";
import { useNavigate } from "react-router-dom";
import DeliveryTableItems from "../Settings_components/DeliveryTableItems.js";
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

const DeliverySettings = () => {
  const {
      state,
      isLoading,
      updateDeliveryList,
      updateDeliveryAccountList
  } = useContext(AppContext);

  const { deliveryList, deliveryAccountList } = state;

  const navigate = useNavigate();

  //---------------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      // Parse the employee data from local storage
      const employeeData = JSON.parse(employeeApiResponse);

      // Get the "Exporter" feature from the employee's role features
      const settingsFeature = employeeData.role.features.find(
        (feature) => feature.name === "Settings"
      );

      // Check if the "Exporter" feature and "Visible" option are both active
      if (settingsFeature && settingsFeature.status === 1) {

        // Grant access based on the route and permissions
        if (location.pathname === "/settings/delivery") {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
      }
      setHasPermission(false);
      // If no permission, navigate to dashboard first
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
    // If not an employee, the useEffect gets skipped
  }, [navigate, location]);
  //---------------------

  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;

  useEffect(() => {
    if(hasPermission){
      getDelivery(currentPage);
    }
  }, [currentPage, hasPermission]);

  useEffect(()=>{
    if(hasPermission){
      getDeliveryAccountList()
    }
  },[currentPage,hasPermission])

  const onPageChange = (page) => {
    setCurrentPage(page);
  }

  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };

  const getDelivery = (page) => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}delivery-method-list?page=${page}&limit=${perPage}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success)
      {
        loadingCallback(false)
        //setBrands(response.data.data.data);
        console.log(response.data.data);
        updateDeliveryList(response.data.data)
        console.log("============");
        console.log(response.data.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  const getDeliveryAccountList = (page) => {
    const token = localStorage.getItem("token");
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}delivery-info-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success){
        loadingCallback(false)
        console.log("delivery Account:", response.data.data);
        updateDeliveryAccountList(response.data.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  const shouldRenderPagination = state.deliveryList?.total > perPage;

  return (
      <div className='rounded-[27px]'  style={{backgroundColor: 'rgba(0, 184, 148, 0.15)' }}>
        <Breadcrumb></Breadcrumb>

        <DeliveryTableItems loading={loading} data={deliveryList} accountData={deliveryAccountList} getDelivery={getDelivery} getDeliveryAccountList={getDeliveryAccountList}></DeliveryTableItems>
          {shouldRenderPagination && (
            <Pagination
              currentPage={currentPage}
              lastPage={state.deliveryList.last_page}
              onPageChange={onPageChange}
            />
          )}
          <div className="w-full relative px-10 md:px-[27px] overflow-x-auto sm:rounded-lg">
            {loading && (
              <div className="custom-loader"></div>
            )}
          </div>
      </div>
  );
};

export default DeliverySettings;