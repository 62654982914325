import React from "react";
import DashedLine from "../../../components/DashedLine/DashedLine";
import DashedLine2 from "../../../components/DashedLine/DashedLine2";
import { formatPrice, numberToWords, invoiceCalculate, formatPhoneNumber } from "../../../utils/Common";
import Watermark from "../Watermark/Watermark";
import "./PdfHtmlFor2mm.css";

import QRCode from "react-qr-code";

const PdfHtmlFor2mm = (props) => {
  const { invoice, invoiceSetting, id } = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, "0");
  const day = String(invoiceDate.getDate()).padStart(2, "0");
  const hours = String(invoiceDate.getHours() % 12).padStart(2, "0");
  const minutes = String(invoiceDate.getMinutes()).padStart(2, "0");
  const ampm = invoiceDate.getHours() >= 12 ? "PM" : "AM";

  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;
  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;


  return (
    <div id="pdfContentAreas2" className="pdfConentAreas2">
      <div id="invoice-detail-page" className="invoice-detail-page font-extrabold">
        {/* <div className="invoice-info">
          <h1>Invoice</h1>
          <p>{invoice.invoice_id}</p>
          <p>{formattedDate}</p>
        </div> */}
        <div className="invoice-header">
          <div className="invoice-logo invoice-logo-pdf flex justify-center">
            {/* <img className="h-[100px]" src={`https://api-outlet-expense.squadinnovators.com/uploads/61-test/1700124528.jpg`} alt={invoice.invoice_id} /> */}

            <img className="h-[80px]" src={shop_logo} alt={invoice.invoice_id} />

          </div>
          <DashedLine2 />
          <div className='flex justify-between '>
            <div>
              {invoiceSetting.shop_name &&
                <h1 className='    text-[12px] '> {invoiceSetting?.shop_name}</h1>
              }
              {invoiceSetting.shop_address &&
                <p className="mt-1 mb-1  text-[10px]    ">  {invoiceSetting?.shop_address}</p>
              }
                          {invoiceSetting?.mobile_number &&
                <p>Mobile : {formatPhoneNumber(invoiceSetting?.mobile_number) }</p>
              }
               <div className='flex flex-col '>
           <span>{invoiceSetting?.web_address ?
              invoiceSetting?.web_address
            : (
              ""
            )}</span>
            <span>
            {invoiceSetting?.social_link ?
              invoiceSetting?.social_link
            : (
              ""
            )}
            </span>

            </div>
            </div>
            <div className="w-[70px]">
              <QRCode className="w-full object-contain" value={BASE_URL + "/purchase-invoice/" + invoice.invoice_id} size={50} />
            </div>
          </div>
        </div>
        <DashedLine />

        <div className="invoice-customerflex justify-between">
          <div>
            <p className=' text-[12px]  '>Date: {formattedDateTime}</p>
            <p className=' text-[12px]  '>Vendor Id:  #{invoice.vendor_id}</p>
          </div>
          <div>
            <p className=' text-[12px]  ' >Name:{" "}
              <span className=" ">{invoice.vendor_name}</span>{" "} </p>
            <p className=' text-[12px]  '>  Phone:{" "}
              <span className=" ">
                {invoice.country_code}
                {invoice.vendor_phone}
              </span></p>
          </div>
        </div>
        <div className="" style={{ textAlign: 'center', margin: '5px auto' }}>
          <p className="text-[11px] p-3 "> Invoice No: {invoice.invoice_id}</p>
        </div>

        <div className="invoice-items">
          <table className="table ">
            <thead>
              <tr className=" text-[12px] relative">
                <th>Name</th>
                <th>Qty</th>
                <th>Price</th>
                <th className='text-right'>Total</th>
              </tr>
            </thead>

            <tbody>
              {invoice.purchase_details ?
                invoice.purchase_details.map((item) => {
                  totalQty += Number(item.qty);
                  totalItems += 1;
                  const subtotalPrice = formatPrice(item.qty * item.price);
                  totalPrice += Number(subtotalPrice);
                  return (
                    <tr
                      key={item.id} className="  relative  text-[12px]">
                      <td>{item?.product_info?.name}<br /><span>{item?.product_info?.serial ? item?.product_info?.serial : ""}</span></td>
                      <td>{item.qty}</td>
                      <td>{item.price}</td>
                      <td className='text-right '>{subtotalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </table>
        </div>
        <DashedLine2 />

        <div className="invoice-summary ">
          <p className='flex   justify-between'>
            <span className=' text-[12px]   '>Total Items</span>
            <span className=' text-[12px]   '>{totalItems}</span>
          </p>

          <p className='flex   justify-between'>
            <span className=' text-[12px]'>Sub Total</span>
            <span className=' text-[12px]'>{totalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>

          <p className='flex   justify-between'>
            <span className='text-[12px]'>Vat {''}</span>
            <span className='text-[12px]'>{invoice.vat ? formatPrice(invoice.vat) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <p className='flex   justify-between'>
            <span className='text-[12px]'>Service Charge {''}</span>
            <span className='text-[12px]'>{invoice.tax ? formatPrice(invoice.tax) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <DashedLine2 />
          <p className='flex   justify-between      '>
            <span className='text-[12px]'>Grand Totals</span>
            <span className='text-[12px]'>{formatPrice(invoiceCalculate(invoice, 'purchase').grandTotal)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
        </div>
        <p className='flex   justify-between    '>
          <span className='text-[12px]'>Paid Amount</span>
          <span className='text-[12px]'>{formatPrice(invoice.paid_amount)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
        </p>
        <hr></hr>
        <p className='flex   justify-between    '>
          <span className='text-[12px]'>Due Amount</span>
          <span className='text-[12px]'>{formatPrice(invoiceCalculate(invoice, 'purchase').dueAmount)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
        </p>
        <hr></hr>
        <div className="" style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
          <p className="    text-[11px] p-3 ">You saved {formatPrice(invoice.discount ? invoice.discount : 0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
          <p className='    text-[11px] pl-2 pr-2 '>Thanks for purchase</p>
          <p className='    text-[10px] pl-2 pr-2 '>A Product of SQUAD INNOVATORS </p>
        </div>

      </div>

    </div>
  );
};

export default PdfHtmlFor2mm;
