import React, { useState } from "react";
import Swal from "sweetalert2";
import InputField from "../SettingsV2Components.jsx/InputField";
import { Helmet } from "react-helmet-async";

const ChangePin = () => {
  const token = localStorage.getItem("token");
  const { pin } = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const [formData, setFormData] = useState({
    currentPin: "",
    newPin: "",
    confirmPin: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(""); // Clear error on input change
    setSuccess(""); // Clear success message on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trim the input values to remove leading/trailing whitespace
    const currentPin = formData.currentPin.trim();
    const newPin = formData.newPin.trim();
    const confirmPin = formData.confirmPin.trim();

    // Validate that no fields are empty
    if (!currentPin || !newPin || !confirmPin) {
      setError("Please fill in all pin fields!");
      return;
    }

    // Check if current pin matches the stored pin
    if (String(formData.currentPin) !== String(pin)) {
      setError("Your current PIN did not match, please recheck!");
      return;
    }

    // Ensure the new PIN is 4 digits long (can be modified as per your requirements)
    if (formData.newPin.length !== 6) {
      setError("PIN must be exactly 6 digits long.");
      return;
    }

    // Validate that new PIN and confirm PIN are the same
    if (formData.newPin !== formData.confirmPin) {
      setError("Pins do not match.");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}set-pin`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pin: formData.newPin, // Send only the new pin to the API
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setError("");
        // Use SweetAlert2 for success message
        Swal.fire({
          title: "Success!",
          text: "PIN successfully changed!",
          icon: "success",
          confirmButtonText: "OK",
        });

        setFormData({
          currentPin: "",
          newPin: "",
          confirmPin: "",
        });
      } else {
        setSuccess("");
        setError(data.message || "An error occurred while changing the PIN.");
      }
    } catch (error) {
      setSuccess("");
      setError("Failed to change PIN. Please try again later.");
    }
  };

  return (
    <div className="rounded-[20px] bg-[#FAFAFA] p-3 font-nunito">
      <Helmet>
        <title>Outlet Expense | Settings | Change PIN</title>
      </Helmet>
      <form
        onSubmit={handleSubmit}
        className="p-4 shadow rounded-md max-w-md mx-auto"
      >
        <h2 className="text-xl font-semibold mb-6">Change Pin</h2>

        {/* Input for Current Pin */}
        <InputField
          label="Current Pin"
          type="password"
          name="currentPin"
          value={formData.currentPin}
          onChange={handleChange}
        />

        {/* Input for New Pin */}
        <InputField
          label="New Pin"
          type="password"
          name="newPin"
          value={formData.newPin}
          onChange={handleChange}
        />

        {/* Input for Confirm New Pin */}
        <InputField
          label="Confirm New Pin"
          type="password"
          name="confirmPin"
          value={formData.confirmPin}
          onChange={handleChange}
        />

        {/* Error message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Success message */}
        {success && <p className="text-green-500 mb-4">{success}</p>}

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-[#407bff] text-white py-2 rounded-md hover:bg-blue-700 focus:ring focus:ring-blue-300"
        >
          Change Pin
        </button>
      </form>
    </div>
  );
};

export default ChangePin;
