import React, { useEffect, useState } from "react";

import ContentLayout from "../../layout/ContentLayout";
import AddExporter from "../../components/AddExporter/AddExporter";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import WholesalerProfile from "../../components/WholesalerProfile/WholesalerProfile";
import { useFlashMessage } from "../../context/FlashMessageContext";
import Swal from 'sweetalert2';
import { get, post } from '../../services/apiService.js';
import { set } from 'react-hook-form';
import { useLocation } from 'react-router-dom';


const AddExporterPage = () => {
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [warningMessage, setWarningMessage] = useState(false);
  const [addedProduct, setAddedProduct] = useState(false);
  const [addedCarrier, setAddedCarrier] = useState(false);
  const [addedPayment, setPayment] = useState(false);
  const [productQty, setProductQty] = useState(0);
  const [receivedQty, setReceivedQty] = useState(0);

  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [fetchedData, setFetchedData] = useState({});
  const [orderData, setOrderData] = useState({});
  const { id } = useParams(); // vendor id
  //---------------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      // Parse the employee data from local storage
      const employeeData = JSON.parse(employeeApiResponse);

      // Get the "Exporter" feature from the employee's role features
      const exporterFeature = employeeData.role.features.find(
        (feature) => feature.name === "Exporter"
      );

      // Check if the "Exporter" feature and "Visible" option are both active
      if (exporterFeature && exporterFeature.status === 1) {
        const exporterVisibleOption = exporterFeature.feature_options.find(
          (option) => option.name === "Visible" && option.status === 1
        );
        // Check if the "Hidden" option is active for /editproduct/:id route
        const exporterHiddenOption = exporterFeature.feature_options.find(
          (option) => option.name === "Hidden" && option.status === 1
        );

        // Grant access based on the route and permissions
        if (location.pathname === "/add-exporter/:id" && exporterVisibleOption) {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
      }
      setHasPermission(false);
      // If no permission, navigate to dashboard first
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
    // If not an employee, the useEffect gets skipped
  }, [navigate, location]);
  //---------------------
  useEffect(() => {
    //getExporterDetail(id);
    if (hasPermission) {
      getExporterDetail(id); // Only make the API call if permission is granted
    }
  }, [id,addedProduct,addedCarrier, addedPayment, hasPermission]);
  const getExporterDetail = (id) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}exporter-order-details/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setOrderData(fetchedData);
          //getExporterProfileDetail(fetchedData?.exporter_id)
          setAddedCarrier(false);
          setPayment(false);
          setAddedProduct(false);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };

  const showModalBeforeDelete = (id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id, index);
      }
    });
  };
  const showModalBeforeDeleteCarrier = (id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCarrier(id, index);
      }
    });
  };

  const deleteData = (id, index) => {
    loadingCallback(true);
    const reqData = { id: id };
    // Use your API service functions to make API requests here
    post("delete-exporter-product", reqData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          console.log("product deleted");
          //let cartProducts = [...orderData.exporter_products];
          setAddedProduct(true);
          //cartProducts.splice(index, 1);
          //updateAdvanceBookingList(cartProducts);
        } else {
          console.log("booking delete failed");
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
  };

  const deleteCarrier = (id, index) => {
    loadingCallback(true);
    const reqData = { id: id };
    // Use your API service functions to make API requests here
    post("delete-exporter-carrier", reqData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          console.log("product deleted");
          //let cartProducts = [...orderData.exporter_products];
          setAddedProduct(true);
          //cartProducts.splice(index, 1);
          //updateAdvanceBookingList(cartProducts);
        } else {
          console.log("booking delete failed");
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
  };
  useEffect(() => {
    if (orderData?.exporter_products) {
      let totalQty = 0;
      let totalReceivedQty = 0;
      orderData.exporter_products.forEach((product) => {
        totalQty += product.qty;
        //totalReceivedQty += product.received_qty;
      });
      //exporter_carriers
      orderData.exporter_carriers.forEach((carrier) => {
        totalReceivedQty += carrier.received_qty;
      });
      setProductQty(totalQty);
      setReceivedQty(totalReceivedQty);
    }
  }, [orderData]);

  return (
    <>
      <ContentLayout>
        {warningMessage && (
          <div className="warning-message">something wrong!</div>
        )}
        {flashMessage && (
          <div
            className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
              flashMessage.type
            )}`}
          >
            {flashMessage.text}
          </div>
        )}
        <AddExporter
          loadingCallback={loadingCallback}
          setSuccessFlashMessage={setSuccessFlashMessage}
          setErrorFlashMessage={setErrorFlashMessage}
          setWarningMessage={setWarningMessage}
          id={id}
          setPayment={setPayment}
          orderData={orderData}
          addedProduct={addedProduct}
          setAddedProduct={setAddedProduct}
          addedCarrier={addedCarrier}
          setAddedCarrier={setAddedCarrier}
          showModalBeforeDelete={showModalBeforeDelete}
          showModalBeforeDeleteCarrier={showModalBeforeDeleteCarrier}
          productQty={productQty}
          receivedQty={receivedQty}
        />
        {loading && (
          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img
              className=""
              src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
              alt="logo"
            />
          </div>
        )}
      </ContentLayout>
    </>
  );
};

export default AddExporterPage;
