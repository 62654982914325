import React from 'react';

const Pagination = ({ currentPage=1, lastPage, onPageChange }) => {
  const renderPageNumbers = () => {
    const pages = [];
    const visiblePages = 5;

    if (lastPage <= visiblePages) {
      // If total pages are less than or equal to visible pages, show all pages
      for (let i = 1; i <= lastPage; i++) {
        pages.push(renderPageButton(i));
      }
    } else {
      // If total pages are more than visible pages, handle ellipsis
      const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
      const endPage = Math.min(lastPage, startPage + visiblePages - 1);

      // Add "first" button
      if (startPage > 1) {
        pages.push(renderPageButton(1));
        if (startPage > 2) {
          pages.push(renderEllipsis());
        }
      }

      // Add visible page numbers
      for (let i = startPage; i <= endPage; i++) {
        pages.push(renderPageButton(i));
      }

      // Add "last" button
      if (endPage < lastPage) {
        if (endPage < lastPage - 1) {
          pages.push(renderEllipsis());
        }
        pages.push(renderPageButton(lastPage));
      }
    }

    return pages;
  };

  const renderPageButton = (page) => (
    <span
      key={page}
      className={page === currentPage ? 'cursor-pointer bg-[#2F80ED] hover:bg-[#2F80ED] m-1 border border-gray-400 flex items-center justify-center w-[28px] sm:w-[32px] h-[28px] sm:h-[32px] rounded-[8px] text-[#FFF]' : ' cursor-pointer  hover:bg-[#2F80ED] hover:text-[#FFF] p-[6px] sm:p-2 m-1 border border-gray-400 flex items-center justify-center w-[28px] sm:w-[32px] h-[28px] sm:h-[32px] rounded-[8px] text-[#333333]'}
      onClick={() => onPageChange(page)}
    >
      {page}
    </span>
  );

  const renderEllipsis = () => (
    <span className='cursor-pointer'>...</span>
  );

  return (
    <div className="pagination text-xs sm:text-sm md:text-base flex justify-center items-center md:justify-start mt-2 mb-4">
      {/* <span
        className={`cursor-pointer p-2  text-[15px] border-solid rounded-[5px] transition duration-300 ease-in-out background-gray-600 font-nunito ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:bg-orange-500 active:bg-green-500'}`}
         onClick={() => (currentPage !== 1 && onPageChange(1))}
      >
        &laquo;&laquo; First
      </span> */}
      <span
        className={`cursor-pointer p-[6px] sm:p-2 border-solid rounded-[5px] transition duration-300 ease-in-out background-gray-600 font-nunito ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:bg-[#2F80ED] hover:text-[#FFF]'}`}
        onClick={() => (currentPage !== 1 && onPageChange(currentPage - 1))}
      >
       Prev {/* <i class="fa-solid fa-backward-fast"></i> */}
      </span>
      {renderPageNumbers()}
      <span
        className={`cursor-pointer p-[6px] sm:p-2 ml-1 mr-2 border-solid rounded-[5px] transition duration-300 ease-in-out background-gray-600 ${currentPage === lastPage ? 'cursor-not-allowed opacity-50' : 'hover:bg-[#2F80ED] hover:text-[#FFF]'}`}
        onClick={() => (currentPage !== lastPage && onPageChange(currentPage + 1))}
      >
       Next {/* <i class="fa-solid fa-forward-fast"></i> */}
      </span>
      {/* <span
        className={`cursor-pointer px-2  mr-2 border-solid rounded-[5px] transition duration-300 ease-in-out background-gray-600 ${currentPage === lastPage ? 'cursor-not-allowed opacity-50' : 'hover:bg-orange-500 active:bg-green-500'}`}
        onClick={() => (currentPage !== lastPage && onPageChange(lastPage))}
      >
        Last &raquo;&raquo;
      </span> */}
    </div>
  );
};

export default Pagination;
