import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { GrFormClose } from 'react-icons/gr';
import {useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '15px',
    borderColor: '#C1CFEF',
    width: '100%',
    //boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#C1CFEF' : 'white',
    color: '#102048',
    '&:hover': {
      backgroundColor: '#C1CFEF',
    },
  }),
};

const EditFormPayment = props => {
  const {
    subtotal,
    vats,
    taxes,
    grandTotal,
    product,
    currentUser,
    onOrderSubmit,
    discount,
    type,
    selectedValue,
    handleSelectChange,
    deliveryAccountList,
    deliveryFormData,
    setDeliveryFormData,
    invoice,
    status,
    setStatus,
    invoiceData,
    isEdit,
    setIsSave,
    setSaveNoRedirect
  } = props;

  const formRef = useRef(null); // Create a ref to the form
  const [showModal, setShowModal] = useState(false)
  const [statusBtn, setStatusBtn] = useState('');
  // hold button handler
  const handleStatus = (statusBtn)=>{
    onOrderSubmit()
  }

  const [inputErors, setInputErrors] = useState({
    name: "This field can not be empty!",
    error1: false,
    error2: false,
    error3: false,
    error4: false,
    error5: false,
  })

  const handleDeliveryInfo = (e) =>{
    e.preventDefault()
    setInputErrors({...inputErors,
      error1: ( deliveryFormData.customerName=='' ),
      error2: ( deliveryFormData.customerAddress==''),
      error3: ( deliveryFormData.customerPhone==''),
      // error4: ( deliveryFormData.deliveryFee==''),
      error5: ( deliveryFormData.companyName=='' || deliveryFormData.companyName==undefined)
     })
    if(deliveryFormData.customerName&&deliveryFormData.customerAddress&&deliveryFormData.customerPhone&&deliveryFormData.companyName){
      setShowModal(!showModal)
      if(statusBtn=='done'){
        setStatus(1);
        onOrderSubmit(1)
      }
      else if(statusBtn=='hold') {
        setStatus(0);
        onOrderSubmit(0)
      }
      setStatusBtn('');
      setselctedDeliveryCompany('')
    }
  }
  console.log("deliveryForm", deliveryFormData);

  const [selectedDeliveryCompany, setselctedDeliveryCompany] = useState( deliveryFormData.companyName);
  const [temp, setTemp] = useState({});

  useEffect(()=>{
    setTemp(selectedDeliveryCompany)
  },[selectedDeliveryCompany])

  useEffect(()=>{
    setDeliveryFormData({...deliveryFormData,
      companyName: temp.label,
      companyId: temp.value})

    setInputErrors({ ...inputErors, error5: false });
  },[temp])



  const handleSaveSubmit = (statusBtn) =>{
    if((deliveryFormData.deliveryName).toLowerCase() !== 'hand-to-hand' ) {
      setShowModal(!showModal)
    }
    else{
      if(statusBtn=='done'){
        setStatus(1);
        onOrderSubmit(1)
      }
      else if(statusBtn=='hold') {
        setStatus(0);
        onOrderSubmit(0)
      }
    }
  }

  // Child component
  const handleSaveClick = (e) => {
    e.preventDefault();
    if (invoice) {
      return;
    }

    const formData = new FormData(e.target.form);
    const data = Object.fromEntries(formData.entries());
    // console.log("============");
    // console.log(data);
    onOrderSubmit(formData, true); // Pass form data and param
  };
    const handleCancleBtn = () =>{
    setselctedDeliveryCompany('');
    setInputErrors({...inputErors,
      error1:false,
      error2:false,
      error3:false,
      error4:false,
      error5:false,
    })
    setShowModal((e)=>!e)
  }

  const handleOnSubmit = (e,isSave=1) =>{
    e.preventDefault();
    setIsSave(isSave);
    if(isSave!==1){
      setStatus(1);
    }
    onOrderSubmit();
  }

  console.log("formData", invoiceData);

  return (
    <div>
      <div>
        {(type === "billing" || type === "wholesale") ? (
          <div className="flex gap-3">
            {/* select option */}
            {/* hold button */}
            <div className="flex-1">
              <label
                onClick={()=>{handleSaveSubmit('hold'),setStatusBtn('hold')}}
                htmlFor='delivery_form'
                className={`mt-[22px] border-[#034AFF] border-[1px]  ${status === 0? "bg-[#034AFF] text-white":"bg-white text-[#034AFF]"} p-[6px] w-full font-nunito font-semibold text-lg text-center cursor-pointer rounded-[8px]`}
              >Hold</label>
              {/* </Link> */}
            </div>
            {/* done button */}
            <div className="flex-1">
              {/* <Link to={`/invoice/${invoice.invoice_id}`}> */}
              <label
                type="submit"
                // onClick={()=>handleStatus('done')}
                onClick={()=>{handleSaveSubmit('done'),setStatusBtn('done')}}
                htmlFor='delivery_form'
                className={`mt-[22px] border-[#034AFF] border-[1px] ${statusBtn === "done"? "bg-[#034AFF] text-white":"bg-white text-[#034AFF]"} p-[6px] w-full font-nunito font-semibold text-lg text-center cursor-pointer rounded-[8px]`}
              >
                {/* Print Bill */}
                Done
              </label>
              {/* </Link> */}
            </div>
          </div>
        ) : null}
        {type === "purchase" ? (
          <button
            // type="submit"
            onClick={(e) => handleOnSubmit(e)}
            className={`mt-[22px] bg-[#034AFF] p-2 w-full rounded-[8px] font-nunito font-semibold text-lg text-center cursor-pointer text-[#FCFCFC]`}
          >
            Print Bill{" "}
          </button>
        ) : null}
        {!isEdit && type === "booking" ? (
          <div className="flex gap-2">
            <button
              onClick={(e) => handleOnSubmit(e, 1)}
              //type="submit"
              className={`border-2 border-solid cursor-pointer rounded-[8px] border-[#407BFF] mt-[22px] bg-[#FFFFFF] p-[6px] mr-2.5 w-[30%] font-nunito font-semibold text-lg text-center text-[#034AFF]`}
            >
              save
            </button>

            {invoice ? (
              <Link to={`/advance-bookings/${invoice.invoice_id}`}>
                <button
                  //type="submit" // Change to type="button" to prevent form submission
                  className="mt-[22px] bg-[#034AFF] p-2 w-[50%] rounded-[8px] font-nunito font-semibold text-lg text-center cursor-pointer text-[#FCFCFC]"
                >
                  Print Bill{" "}
                </button>
              </Link>
            ) : (
              <button
                // type="submit" // Change to type="button" to prevent form submission
                onClick={(e) => handleOnSubmit(e, 0)}
                className="mt-[22px] bg-[#034AFF] p-2 w-[50%] rounded-[8px] font-nunito cursor-pointer font-semibold text-lg text-center text-[#FCFCFC]"
              >
                Print Bill{" "}
              </button>
            )}
          </div>
        ) : null}
        {isEdit && type === "booking" ? (
          <div className="flex gap-2">
            <label
              // onClick={(e) => {handleBookingSubmit(0),handleSaveSubmit('hold'),setStatusBtn('hold')}}
              // onClick={(e) => {handleSaveSubmit('hold'),setStatusBtn('hold')}}
              onClick={()=>{handleSaveSubmit('hold'),setStatusBtn('hold')}}
              //type="submit"
              htmlFor='delivery_form'
              className={`border-2 border-solid cursor-pointer rounded-[8px] border-[#407BFF] mt-[22px] bg-[#FFFFFF] p-[6px] mr-2.5 w-[30%] font-nunito font-semibold text-lg text-center text-[#034AFF]`}
            >
              save
            </label>

            <label
              // type="submit" // Change to type="button" to prevent form submission
              onClick={()=>{handleSaveSubmit('done'),setStatusBtn('done')}}
              htmlFor='delivery_form'
              className="flex-grow w-[70%] cursor-pointer rounded-[16px] mt-[22px] bg-[#034AFF] p-2 font-nunito font-semibold text-lg text-center text-[#FCFCFC]"
            >
              Confirm Sale{" "}
            </label>
          </div>
        ) : null}
        {/* <button type="submit" className="mt-[22px] bg-[#034AFF] p-2 w-full font-nunito font-semibold text-lg text-center text-[#FCFCFC]">
          Invoice{" "}
        </button> */}
      </div>


        {showModal ? (
          <>
            <div>
              <input type='checkbox' id='delivery_form' className='modal-toggle' />
              <div className='modal rounded-2xl'>
                <div className='modal-box w-[450px] p-0 bg-white'>
                  <div className='flex justify-between px-[18px] pt-[30px] pb-[25px] border-b border-[#D9D9D9]'>
                    <h4 className='font-nunito font-semibold text-lg'>Delivery Method</h4>
                    <div className='modal-action'>
                      <label htmlFor='delivery_form'  className='cursor-pointer modal-action bg-red-600 text-white rounded-lg' onClick={handleCancleBtn}>
                        <GrFormClose />
                      </label>
                    </div>
                  </div>
                  <div className='px-5 pt-5 pb-[22px]'>
                    <form
                      method="get"
                      className="col-sm-12"
                      data-autosubmit="false"
                      autoComplete="off"
                      onSubmit={handleDeliveryInfo}
                    >

                      <div className={` w-full relative`}>
                        <Select
                          id="mySelect"
                          className='select w-full my-3 text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none px-[0px]  text-md focus:outline-none'
                          // value={selectedDeliveryCompany}
                          onChange={(e)=> {
                            setselctedDeliveryCompany(e)
                            setInputErrors({ ...inputErors, error5: false });
                          }}

                          defaultValue={  {
                            value: deliveryFormData.companyId,
                            label: deliveryFormData.companyName,
                          }}
                          options={deliveryAccountList
                            .filter(item => item.delivery_method_id === deliveryFormData.deliveryId)
                            .map(item => ({
                              value: item.id,
                              label: item.company_name,
                            }))}
                          // placeholder="Select Delivery Company"
                          styles={customStyles}
                        />
                        {inputErors.error5
                        ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                        : null}
                      </div>
                      <input
                        type='text'
                        name="name"
                        value={deliveryFormData.customerName}
                        onChange={(e) => {
                          const val = e.target.value;
                          setDeliveryFormData({...deliveryFormData, customerName: val})
                          setInputErrors({ ...inputErors, error1: false });
                        }}
                        placeholder='Receiver Name'
                        noOptionsMessage={() => "No options available"}
                        className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                      />
                      {inputErors.error1
                      ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                      : null}
                      <input
                        type='text'
                        name="name"
                        value={deliveryFormData.customerAddress}
                        onChange={(e) => {
                          const val = e.target.value;
                          setDeliveryFormData({...deliveryFormData, customerAddress: val})
                          setInputErrors({ ...inputErors, error2: false });
                        }}
                        placeholder='Receiver Address'
                        className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                      />
                      {inputErors.error2
                      ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                      : null}
                      <input
                        type='number'
                        name="name"
                        value={deliveryFormData.customerPhone}
                        onChange={(e) => {
                          const val = e.target.value;
                          setDeliveryFormData({...deliveryFormData, customerPhone: val})
                          setInputErrors({ ...inputErors, error3: false });
                        }}
                        placeholder='Receiver Phone'
                        className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                      />
                      {inputErors.error3
                      ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                      : null}
                      <div className="button-disabled">
                        <button
                          htmlFor='delivery_form'
                          type="submit"
                          className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                        >Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
  );
};

EditFormPayment.defaultProps = {
  setIsSave: () => {},
  setSaveNoRedirect: () => {},
};


export default EditFormPayment;
