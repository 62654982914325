import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL; // Set your base API URL here
console.log(BASE_URL);
export const get = async (url, token) => {
  try {
    const response = await axios.get(BASE_URL + url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const post = async (url, data, token) => {
  try {
    const response = await axios.post(BASE_URL + url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
