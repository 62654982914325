import React, { useContext, useState, useRef, useEffect } from "react";
import Breadcrumb from '../Settings_components/Breadcrumb';
import Table from '../Settings_components/Table';
import { category } from '../../../data/data';
import { AppContext } from '../../../context/context.js';
import Pagination from '../../../utils/pagination';
import { BeatLoader } from "react-spinners";
import { get, post } from "../../../services/apiService";
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { set } from 'react-hook-form';


const Category = () => {
    const {
        state,
        isLoading,
        updateCategoryList,
        updateSingleCategoryList
    } = useContext(AppContext);
    const { searchKeyword } = state;

    //---------------------
    const location = useLocation();
    const navigate = useNavigate();
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
      const employeeApiResponse = localStorage.getItem("employee");

      if (!employeeApiResponse) {
        setHasPermission(true);
        return; // Exit useEffect early
      }

      if (employeeApiResponse) {
        // Parse the employee data from local storage
        const employeeData = JSON.parse(employeeApiResponse);

        // Get the "Exporter" feature from the employee's role features
        const settingsFeature = employeeData.role.features.find(
          (feature) => feature.name === "Settings"
        );

        // Check if the "Exporter" feature and "Visible" option are both active
        if (settingsFeature && settingsFeature.status === 1) {

          // Grant access based on the route and permissions
          if (location.pathname === "/settings/category") {
            setHasPermission(true);
            return; // Exit useEffect if permission is granted
          }
        }
        setHasPermission(false);
        // If no permission, navigate to dashboard first
        navigate("/dashboard", { replace: true });
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Permission Denied",
            text: "You do not have permission for this feature.",
          });
        }, 100);
      }
      // If not an employee, the useEffect gets skipped
    }, [navigate, location]);
  //---------------------

    const [currentPage, setCurrentPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const perPage = 20;

    useEffect(() => {
      if(hasPermission){
        getSingleCategoryList(currentPage,searchKeyword);
      }
    } , [currentPage,searchKeyword,hasPermission]);
    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const [category, setCategory] = useState(state.singleCategoryList);
    const token = localStorage.getItem("token");

    const [loading, setLoading] = useState(false);
    const loadingCallback = loading => {
        setLoading(loading);
    };

    const getCategory = () => {
        loadingCallback(true);
        axios
        .get(
            `${process.env.REACT_APP_API_URL}get-all-category`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        )
        .then((response) => {
            loadingCallback(false);
            if(response.data.success)
            {
                //setCategory(response.data.data);
                updateCategoryList(response.data.data)
            }

        })
        .catch((error) => {
            console.log(error);
            loadingCallback(false);
            if (error?.response?.status === 401) {
                localStorage.clear();
                localStorage.setItem('token', false);
                // Clear session and local storage
                localStorage.setItem('isPinVerify', false);

                navigate('/login');
            }
        });
    };
    const getSingleCategoryList = (page,searchKeyword) => {
        //loadingCallback(true)
        setLoader(true)
        if (searchKeyword !== "") {
            const reqData = { keyword: searchKeyword };
            post(`search-category?page=${page}&limit=${perPage}`, reqData, token)
            .then((response) => {
                setLoader(false)
                if(response.success)
                {
                    loadingCallback(false)
                    setCategory(response.data);
                    updateSingleCategoryList(response.data)
                }

            })
            .catch((error) => {
                console.log(error);
                setLoader(false)
            });
        } else {
            axios
            .get(
                `${process.env.REACT_APP_API_URL}category?page=${page}&limit=${perPage}`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                setLoader(false)
                if(response.data.success)
                {
                    loadingCallback(false)
                    setCategory(response.data.data);
                    updateSingleCategoryList(response.data.data)
                }

            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
        }
    };
    const shouldRenderPagination = state.singleCategoryList?.total > perPage;
    return (
            <div className='rounded-[27px]' style={{backgroundColor: 'rgba(85, 31, 255, 0.15)' }}>
                <Breadcrumb></Breadcrumb>
                <Table
                data={state.singleCategoryList}
                type="category"
                getCategory={getCategory}
                // getSingleCategoryList={getSingleCategoryList}>
                getSingleCategoryList={hasPermission ? getSingleCategoryList : undefined}>
                </Table>
                {shouldRenderPagination && (
                    <Pagination
                        currentPage={currentPage}
                        lastPage={state.singleCategoryList.last_page}
                        onPageChange={onPageChange}
                    />
                )}
                <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
                  {loader && (
                      <div className="custom-loader"></div>
                  )}
                </div>
                {/* {loading && (
                    <div className="overlay">
                        <BeatLoader color={'#123abc'} size={20} />
                    </div>
                )} */}
            </div>
    );
};

export default Category;