const env = process.env.REACT_APP_ENV;
const dev = process.env.REACT_APP_ENV === 'development';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale = 'en';

// Canonical root url is needed in social media sharing and SEO optimization purposes.
const canonicalRootURL = process.env.REACT_APP_CANONICAL_ROOT_URL;

// Site title is needed in meta tags (bots and social media sharing reads those)
const siteTitle = '';


// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets in this file.
const config = {
  env,
  dev,
  locale,
  canonicalRootURL,
  siteTitle,
  BASE_URL
};

export default config;
