import React, { useContext, useState, useRef, useEffect } from "react";

import { AppContext } from "../../../context/context.js";
import Pagination from "../../../utils/pagination.js";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../SettingsV2Components.jsx/Breadcrumb.js";
import DeliveryTableItemsV2 from "../SettingsV2Components.jsx/DeliveryTableItemsV2.js";
import { Helmet } from "react-helmet-async";

const DeliverySettingsV2 = () => {
  const { state, isLoading, updateDeliveryList, updateDeliveryAccountList } =
    useContext(AppContext);

  const { deliveryList, deliveryAccountList } = state;

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;

  useEffect(() => {
    getDelivery(currentPage);
  }, [currentPage]);

  useEffect(() => {
    getDeliveryAccountList();
  }, [currentPage]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const getDelivery = (page) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}delivery-method-list?page=${page}&limit=${perPage}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          //setBrands(response.data.data.data);
          console.log(response.data.data);
          updateDeliveryList(response.data.data);
          console.log("============");
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };
  const getDeliveryAccountList = (page) => {
    const token = localStorage.getItem("token");
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}delivery-info-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          console.log("delivery Account:", response.data.data);
          updateDeliveryAccountList(response.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };
  const shouldRenderPagination = state.deliveryList?.total > perPage;

  return (
    <div className="rounded-[27px]" style={{ backgroundColor: "#FAFAFA" }}>
      <Helmet>
        <title>Outlet Expense | Settings | Delivery Settings</title>
      </Helmet>
      <Breadcrumb></Breadcrumb>

      <DeliveryTableItemsV2
        loading={loading}
        data={deliveryList}
        accountData={deliveryAccountList}
        getDelivery={getDelivery}
        getDeliveryAccountList={getDeliveryAccountList}
      ></DeliveryTableItemsV2>
      {shouldRenderPagination && (
        <Pagination
          currentPage={currentPage}
          lastPage={state.deliveryList.last_page}
          onPageChange={onPageChange}
        />
      )}
      <div className="w-full relative px-10 md:px-[27px] overflow-x-auto sm:rounded-lg">
        {loading && <div className="custom-loader"></div>}
      </div>
    </div>
  );
};

export default DeliverySettingsV2;
