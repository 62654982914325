import React, { useEffect, useState, useRef } from "react";

import PaymentInfo from "../../components/VendorProfile/PaymentInfo/Index";
import InvoiceHistory from "../../components/VendorProfile/InvoiceHistory/Index";
import MostPurchaseProducts from "../../components/VendorProfile/MosPurchaseProducts/Index.jsx";
import VendorHistory from "../../components/VendorProfile/VendorHistory/Index";

const CustomerProfile = (props) => {
  const {
    hideModal,
    dueCollectionData,
    activeTab,
    loadingCallback,
    fetchedData,
    invoiceData,
    customerId,
    onSubmitPayment,
    handleSubmitAddDue,
    handleSubmitAddPiad,
  } = props;
  return (
    <>
      <div className="flex flex-col xl:flex-row gap-[3%]">
        <div className="w-[100%] xl:w-[276px] mb-[25px] xl:mb-0 bg-[#F8F9FA] rounded-[16px] p-3">
          <PaymentInfo
            activeTab={activeTab}
            hideModal={hideModal}
            loadingCallback={loadingCallback}
            fetchedData={fetchedData}
            invoiceData={invoiceData}
            onSubmitPayment={onSubmitPayment}
            dueCollectionData={dueCollectionData}
            handleSubmitAddDue={handleSubmitAddDue}
            handleSubmitAddPiad={handleSubmitAddPiad}
            setSuccessFlashMessage={props.setSuccessFlashMessage}
            setErrorFlashMessage={props.setErrorFlashMessage}
            setWarningMessage={props.setWarningMessage}
            type="customer"
          />
        </div>
        <div className="flex-1">
          <VendorHistory
            activeTab={activeTab}
            loadingCallback={loadingCallback}
            fetchedData={fetchedData}
            type="customer"
          />

          <MostPurchaseProducts
            activeTab={activeTab}
            loadingCallback={loadingCallback}
            fetchedData={fetchedData}
            vendorId={customerId}
            type="customer"
          />

          <InvoiceHistory
            activeTab={activeTab}
            loadingCallback={loadingCallback}
            invoiceData={invoiceData}
            vendorId={customerId}
            type="customer"
          />
        </div>
      </div>
    </>
  );
};

export default CustomerProfile;
