import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from '../../utils/Common';
import manIcon from "../../assets/images/man2.png";

const CarrierListItem = (props) => {
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;

  const {
    vendor
  } = props;
  return (
    <div>
      <div className="border-t-[1px] text-[11px] md:text-sm border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-5 items-start">
        <div>
          <div className="flex flex-col sm:flex-row gap-2">
            <div>
              <img src={vendor?.image ? vendor?.image :manIcon} width={40} className="rounded-[50%]" />{" "}
            </div>
            <div className="font-nunito text-[14px]">
              <h1 className="text-[#000232] font-[500]">#{vendor.id}</h1>
              <p className="text-[#87889E] font-[700]">{vendor.name}</p>
            </div>
          </div>
        </div>
        <p className="text-[#000] font-[500] text-center">{vendor.email}</p>
        <p className="text-[#000] font-[500] text-center">{vendor.phone_number}</p>
        <div className="text-[#000] font-[500] text-center">{vendor.last_receive_date}</div>

        <div className="text-[12px] font-nunito font-[700] flex flex-col lg:flex-row gap-2 justify-end items-end">
         <Link className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
          to={`/carrier-profile/${vendor.id}`}
         //to={`/carrier-profile`}
         >VIEW</Link>
        </div>
      </div>

    </div>
  );
};

export default CarrierListItem;