import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from './../../utils/Modal';
import Swal from "sweetalert2";
import axios from "axios";

const ExpenseItem = (props) => {

  const {
    expense,
    showModalBeforeDelete,
    index,
    loadingCallback,
    token,
    setSuccessFlashMessage,
    setPayment,
    setShowNewExpenseModal,
  } = props;

  const [warningMessage, setWarningMessage] = useState(false);
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(false)


  const [showExpenseViewModal, setShowExpenseViewModal] = useState(false);
  const [expenseData, setExpenseData] = useState({});

  const handleExpenseViewOpen = (id) => () => {
    getExpenseDetail(id)
  };

  const getExpenseDetail = (id) => {
    setShowExpenseViewModal(true)
    setExpenseData(id);
  };

  // -------------
  const [showExpenseEditModal, setShowExpenseEditModal] = useState(false);
  const [formData, setFormData] = useState({

  });

  const handleExpenseEditOpen = (id) => () => {
    const token = localStorage.getItem("token");
    setShowExpenseEditModal(true);
    setExpenseData(id);
    axios.get(`${process.env.REACT_APP_API_URL}expense/${id}`, {
        headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
      console.log("API Response:", response);
        if (response.data.success) {
            const expenseData = response.data.data;
            setFormData({
                catogory_name: expenseData.catogory_name,
                transaction_date: expenseData.transaction_date,
                description: expenseData.description,
                amount: expenseData.amount,
            });
            console.log("editable data: ", expenseData)
        }
    })
    .catch((error) => {
      loadingCallback(false);
      console.log(error);
      localStorage.clear();
      localStorage.setItem("token", false);
      // Clear session and local storage
      localStorage.setItem("isPinVerify", false);

      navigate("/login");
    });
};

  return(
    <>
      <div>
        <div className="grid grid-cols-5 md:gap-5  gap-2 items-center font-nunito font-[500] text-[14px] text-[#000232] border-t-[1px] border-solid border-[#E7EBF4] py-[8px]">
        {/* <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start"> */}
          <div>
            <div className="">
              {/* <div className="font-nunito text-[14px]"> */}
                <div className="font-[500] text-[#000232]">#{expense.id}</div>
                <div className="font-[700] text-[#87889E]">{expense.catogory_name}</div>
              {/* </div> */}
            </div>
          </div>
          {/* <div className="text-center">{expense.catogory_name}</div> */}
          <div className="text-center">{expense.transaction_date}</div>
          <div className="text-center">{expense.amount}  {" BDT"}</div>
          <div className="text-center">{expense.description}</div>
          <div className="flex gap-2  text-xs justify-end">
            <label
              htmlFor="view_expense_modal"
              // onClick={()=>setShowViewModal(val=>!val)}
              //onClick={()=> Swal.fire("View option coming soon")}
              onClick={handleExpenseViewOpen(expense?.id)}

              className="cursor-pointer border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[6px] py-1"
            >
            VIEW
            </label>
            <label
              htmlFor="edit_expense_modal"
              //onClick={()=> Swal.fire("Edit option coming soon")}
              onClick={handleExpenseEditOpen(expense?.id)}
              className="cursor-pointer border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-[5px] px-[7px] py-1"
            >
            EDIT
            </label>
            <label
              // htmlFor="editAccTxn_modal"
              // onClick={()=> Swal.fire("Edit option coming soon")}
              onClick={() => showModalBeforeDelete(expense?.id, index)}
              className="cursor-pointer border-[1px] border-solid bg-[#FF0303] text-white rounded-[5px] px-[7px] py-1"
            >
            DELETE
            </label>
          </div>
        </div>
      </div>

      {showExpenseViewModal ? (
        <Modal
          isOpen={showExpenseViewModal}
          setOpen={setShowExpenseViewModal}
          setWarningMessage={setWarningMessage}
          title="Details"
          formId="view_expense_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="view_expense_modal"
          expenseId={expenseData}
        />
      ) : null}

      {showExpenseEditModal ? (
        <Modal
          isOpen={showExpenseEditModal}
          setOpen={setShowExpenseEditModal}
          setWarningMessage={setWarningMessage}
          title="Edit Expense"
          formId="edit_expense_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="edit_expense_modal"
          expenseFormData={expenseData}
          formData={formData}
          setFormData={setFormData}
          expenseId={expenseData}
          refetch={()=>setRefetch(val=>!val)}
        />
      ) : null}
    </>
  );
};

export default ExpenseItem;