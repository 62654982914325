import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import Barcode from "react-barcode";
import { GrFormClose } from "react-icons/gr";
import { BiPlus } from "react-icons/bi";
import { MdModeEdit } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Pagination from "../../../utils/pagination";
import { useFlashMessage } from "../../../context/FlashMessageContext";
import { AppContext } from "../../../context/context";
import { post } from "../../../services/apiService";
import { formatNumber, scrollUp, useStateArray } from "../../../utils/Common";
import ExporterProductReportList from "../ExporterProductReportList/ExporterProductReportList";
import ExporterProductReportList1 from "../ExporterProductReportList1/ExporterProductReportList1";
// import "./index.scss";

import downloadIcon from "../../../assets/images/download.svg";
import printIcon from "../../../assets/images/print.svg";

import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import { AiOutlineSearch } from "react-icons/ai";
import pdfIcon from "./../../../assets/images/pdf-Icon.png";
import { useLocation } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;

function ExporterProductList() {
  const { state } = useContext(AppContext);
  const { currentUser, invoiceSetting } = state;
  const navigate = useNavigate();
  //---------------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      // Parse the employee data from local storage
      const employeeData = JSON.parse(employeeApiResponse);

      // Get the "Exporter" feature from the employee's role features
      const exporterFeature = employeeData.role.features.find(
        (feature) => feature.name === "Exporter"
      );

      // Check if the "Exporter" feature and "Visible" option are both active
      if (exporterFeature && exporterFeature.status === 1) {

        // Grant access based on the route and permissions
        if (location.pathname === "/exporter-product-list") {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
      }
      setHasPermission(false);
      // If no permission, navigate to dashboard first
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
    // If not an employee, the useEffect gets skipped
  }, [navigate, location]);
  //---------------------
  const { flashMessage, getFlashMessageStyle } = useFlashMessage();
  const token = localStorage.getItem("token");
  const [products, setProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [showDateFilter, setShowDateFilter] = useState(false);

  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const perPage = 20;
  const barcodeRef = useRef();
  const exporterProductListRef = useRef();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showNavMenu, setShowNavMenu] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [exporterListSelect, setExporterListSelect] = useState([]);
  const [carrierListSelect, setCarrierListSelect] = useState([]);
  const [customerListSelect, setCustomerListSelect] = useState([]);
  const [exporterProductReportList, setExporterProductReportList] = useState([]);

  const [searchFilter, setSearchFilter] = useState({
    keyword: "",
    categoryId: false,
    subCategoryId: false,
    unitId: false,
    brandId: false,
    stockIn: false,
    stockOut: false,
    startDate: new Date(),
    endDate: new Date(),
  });

  const [printFilter, setPrintFilter] = useState({
    exporter_name: "",
    carrier_name: "",
    stock_type: "",
    start_date: "",
    end_date: "",
    customer_name: "",
});

  // useEffect(()=>{
  //     console.log( searchFilter);
  //     getFilteredProductlist2();
  // },[searchFilter])

  // const getFilteredProductlist2 = (page) => {
  //     const data = {
  //         keyword: searchFilter.keyword,
  //         categoryId: searchFilter.categoryId,
  //         subCategoryId: searchFilter.subCategoryId,
  //         unitId: searchFilter.unitId,
  //         brandId: searchFilter.brandId,
  //         stockIn: searchFilter.stockIn,
  //         stockOut: searchFilter.stockOut,
  //     }
  //     loadingCallback(true)
  //     console.log("data", data);
  //     axios
  //         .post(
  //             `${process.env.REACT_APP_API_URL}search-product-v1?page=${page}&limit=${perPage}`,
  //                 data,
  //             {
  //                 headers: {
  //                     Authorization: `Bearer ${token}`,
  //                     'Content-Type': 'application/json',
  //                 },
  //             }
  //         )
  //         .then((response) => {
  //             loadingCallback(false)
  //             if (response.status === 200) {
  //                 setProducts(response.data.data);
  //                 setProductsData(response.data.data.data);
  //             } else {
  //                 console.log('Data Not Found!');
  //             }
  //         })
  //         .catch((error) => {
  //             loadingCallback(false)
  //             console.log(error);
  //             if (error?.response?.status === 401) {
  //                 localStorage.clear();
  //                 localStorage.setItem('token', false);
  //                 // Clear session and local storage
  //                 localStorage.setItem('isPinVerify', false);

  //                 navigate('/login');
  //             }
  //         });
  // };

  const getExporterProductList = async () => {
    try {
      const response = await axios.post( `${process.env.REACT_APP_API_URL}exporter-product-report-list`,
        printFilter,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        //console.log(response.data)
      setExporterProductReportList(response.data);
    } catch (error) {
      console.error("Error fetching the exporter list:", error);
    }
  }

  const [prevSearchKeyword, setPrevSearchKeyword] = useState("");
  useEffect(() => {
    // Check if the search keyword has changed
    const isSearchKeywordChanged = searchKeyword !== prevSearchKeyword;

    // Update the previous search keyword
    setPrevSearchKeyword(searchKeyword);

    // If it's a new search keyword, set the page to 1
    if (isSearchKeywordChanged) {
      setCurrentPage(1);
    } else {
      if(hasPermission){
        getProductlist(currentPage, searchKeyword);
      }
    }
  }, [currentPage, searchKeyword, prevSearchKeyword, hasPermission]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const getFilteredProductlist = (page, searchKeyword) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}search-product-v1?page=${page}&limit=${perPage}`,
        { keyword: searchKeyword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          setProducts(response.data.data);
          setProductsData(response.data.data.data);
        } else {
          console.log("Data Not Found!");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });

      if(exporterProductReportList.length < 1){
        getExporterProductList();
      }
  };

  const getProductlist = (page, searchKeyword = null) => {
    setLoader(true);
    if (searchKeyword) {
      getFilteredProductlist(page, searchKeyword);
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}exporter-product-list?page=${page}&limit=${perPage}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          //console.log(response.data.data.data);

          if (response.data.success) {
            setLoader(false);
            setProducts(response.data.data);
            setProductsData(response.data.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            navigate("/login");
            localStorage.setItem("token", "");
            localStorage.setItem("token", "");
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  };
  const deleteProduct = (id, index) => {
    loadingCallback(true);
    const reqData = { productId: id };

    //let cartProducts = [...products];

    // cartProducts.splice(index, 1);
    // setProducts(cartProducts);
    // Use your API service functions to make API requests here
    post("delete-product", reqData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          console.log("Product deleted");
          let cartProducts = [...products];

          cartProducts.splice(index, 1);
          setProducts(cartProducts);
          setProductsData(cartProducts);
        } else {
          console.log("Product delete failed");

          // setWarningMessage(true);
          // const timer = setTimeout(() => {
          //     setWarningMessage(false);
          // }, 3000);
          // return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
  };
  const handleGeneratePDF = () => {
    const input = document.getElementById("invoice-detail-page");

    // Configure html2pdf options
    const pdfOptions = {
      margin: 10, // Set margin to ensure entire content is captured
      filename: "invoice.pdf",
      image: { type: "jpeg", quality: 0.98 }, // Adjust image quality if needed
      html2canvas: { scale: 1 }, // Adjust scale if needed
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(input)
      .set(pdfOptions)
      .outputPdf((pdf) => {
        // You can save or display the PDF here if needed
        // For example, you can save it to a file using FileSaver.js:
        // import { saveAs } from 'file-saver';
        // saveAs(pdf, 'invoice.pdf');
      });
  };

  const showModalBeforeDelete = (id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(id, index);
      }
    });
  };

  const GotoAddProducts = () => {
    navigate("/addproducts");
  };
  const handleClick = (id) => {
    navigate(`/products/${id}`);
  };

  const handleImageClick = async (imageName, item, id) => {
    const barcodeContainer = document.getElementById(`barcode-container-${id}`);
    if (!barcodeContainer) return;

    if (imageName === "Download Icon") {
      const canvas = await html2canvas(barcodeContainer);

      // Create a new canvas for the image and additional information
      const newCanvas = document.createElement("canvas");
      newCanvas.width = canvas.width;
      newCanvas.height = canvas.height + 40; // Adjust height for additional info

      const ctx = newCanvas.getContext("2d");

      // Draw the barcode image onto the new canvas
      ctx.drawImage(canvas, 0, 0);

      // Add product name and price
      ctx.fillStyle = "#ffffff";
      ctx.font = "14px Arial";
      ctx.fillText(`Product Name: ${item?.name}`, 10, canvas.height + 20);
      ctx.fillText(
        `Product Price: ${item?.retails_price}`,
        10,
        canvas.height + 40
      );

      // Convert the canvas to a blob
      newCanvas.toBlob((blob) => {
        saveAs(blob, `barcode-${item.id}.png`);
      });
    } else if (imageName === "Print Icon") {
      const printContent = `
                <div id="print-content" style="text-align: center;">
                <div>${barcodeContainer.innerHTML}</div>
                    <div>Product Name: ${item?.name}</div>
                    <div>Product Price: ${item?.retails_price}</div>

                </div>
            `;

      const printWindow = window.open("", "_blank");
      if (printWindow) {
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
      } else {
        console.error("Failed to open print window.");
      }
    }
  };

  const handleButtonClick = () => {
    console.log("Clicked");
    setShowNavMenu((prevShowNavMenu) => !prevShowNavMenu);
  };

  // const handleClickOutside = (event) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //     setShowNavMenu(false);
  //     }
  // };
  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowNavMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const shouldRenderPagination = products.total > perPage;
  //scroll up
  useEffect(() => {
    scrollUp();
  }, [currentPage]);

  useEffect(() => {
    if(exporterListSelect.length < 1){
      if(hasPermission){
        getExporters();
      }
    }

    if(carrierListSelect.length < 1){
      if(hasPermission){
        getCarriers();
      }
    }

    if(customerListSelect.length < 1){
      if(hasPermission){
        getCustomers();
      }
    }

  },[exporterListSelect, carrierListSelect, customerListSelect, hasPermission]);

    // Trigger print function
    const handlePrint = () => {
      const printContent = exporterProductListRef.current.innerHTML;
      const printWindow = window.open('', '_blank');

      printWindow.document.write('<html><head><title>Print</title>');
      printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid #ddd; padding: 8px; } th { background-color: #f2f2f2; }</style>'); // Optional: Add CSS styling
      printWindow.document.write('</head><body>');
      printWindow.document.write(printContent);
      printWindow.document.write('</body></html>');

      printWindow.document.close(); // Necessary for IE >= 10
      printWindow.focus(); // Necessary for IE >= 10
      printWindow.print();
      printWindow.close();
      if(printFilter.carrier_name !== '' || printFilter.exporter_name !== '' || printFilter.stock_type !== '' || printFilter.start_date !== '' || printFilter.end_date !== ''){
        setPrintFilter({
          exporter_name: "",
          carrier_name: "",
          stock_type: "",
          start_date: "",
          end_date: ""
        });
        setSearchFilter({
          ...searchFilter,
          stockIn: false,
          stockOut: false,
        });
      }
    };

  useEffect(() => {
    if(hasPermission){
      getExporterProductList();
    }
    console.log(printFilter);
    console.log(exporterProductReportList);
  },[printFilter]);

  const updateFilter = (key, value) => {
    setPrintFilter((prev)=>({
      ...prev,
      [key]:value

      })
    );
  }

  const getExporters = async () => {
    try {
      const response = await axios.get( `${process.env.REACT_APP_API_URL}exporter-list`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      setExporterListSelect(response.data.data);
    } catch (error) {
      console.error("Error fetching the exporter list:", error);
    }
  };

  const getCarriers = async () => {
    try {
      const response = await axios.get( `${process.env.REACT_APP_API_URL}carrier-list`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      setCarrierListSelect(response.data.data);
    } catch (error) {
      console.error("Error fetching the exporter list:", error);
    }
  };

  // ---------

  const getCustomers = async () => {
    try {
      const response = await axios.get( `${process.env.REACT_APP_API_URL}customer-lists`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setCustomerListSelect(response.data.data.data);
    } catch (error) {
      console.error("Error fetching the exporter list:", error);
    }
  };

  const handleStartDateChange = (date) => {
    setSearchFilter(prevState => ({
      ...prevState,
      startDate: date
    }));

    updateFilter('start_date', date);
  };

  const handleEndDateChange = (date) => {
    setSearchFilter(prevState => ({
      ...prevState,
      endDate: date
    }));

    updateFilter('end_date', date);
  };

  const [productImeis, setProductImeis] = useState([]);

  return (
    <div className="productsList-container">
      <div className="productsList-header mb-6">
        <div className="productsList-header-left">
          <span>Products / Products</span>
          <p>Product List</p>
        </div>
        <div className="productsList-header-right">
          <div className="flex gap-2 justify-center">
            <div className="flex relative w-[95%]">
              <input
                autoFocus
                type="text"
                className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                placeholder="Search Product"
                onChange={(e) =>
                  setSearchFilter({ ...searchFilter, keyword: e.target.value })
                }
              />
              <div className="absolute top-[17px] left-[10px]">
                <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
              </div>

              <div className="absolute top-[17px]  right-[10px]">
                <div
                  // ref={menuRef}
                  ref={buttonRef}
                  onClick={handleButtonClick}
                  className=" relative flex flex-col items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z"
                      fill="#102048"
                    />
                    <path
                      d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z"
                      fill="#102048"
                    />
                    <path
                      d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z"
                      fill="#102048"
                    />
                    <path
                      d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z"
                      fill="#102048"
                    />
                    <path
                      d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z"
                      fill="#102048"
                    />
                    <path
                      d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z"
                      fill="#102048"
                    />
                    <path
                      d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z"
                      fill="#102048"
                    />
                    <path
                      d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z"
                      fill="#102048"
                    />
                    <path
                      d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z"
                      fill="#102048"
                    />
                  </svg>
                </div>
              </div>
              {showNavMenu && (
                <div
                  ref={menuRef}
                  className="absolute z-50  w-[230px] shadow-sm left-[35px] top-[50px] bg-[#FFF] border-1 border-solid p-3 rounded-[10px]"
                >
                  <select
                    name="discount_type"
                    onChange={(e) => updateFilter("exporter_name",e.target.value)}
                    className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  >
                    <option value="">Select Exporter</option>
                    {exporterListSelect.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <select
                    name="discount_type"
                    onChange={(e) => updateFilter("carrier_name", e.target.value)}
                    className="select mt-1 w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  >
                    <option value="">Select Carrier</option>
                    {carrierListSelect.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {/* customer list */}
                  <select
                    name="discount_type"
                    onChange={(e) => updateFilter("customer_name", e.target.value)}
                    className="select mt-1 w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  >
                    <option value="">Select Customer</option>
                    {customerListSelect.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
{/*                   <div
                    onClick={(e) =>
                      setSearchFilter({
                        ...searchFilter,
                        categoryId: !searchFilter.categoryId,
                      })
                    }
                    className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
                  >
                    <div className="">
                      <input
                        type="checkbox"
                        checked={searchFilter.categoryId}
                        onChange={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            categoryId: !searchFilter.categoryId,
                          })
                        }
                        // className="bg-[#E5EDFF] p-3 "
                      />
                    </div>
                    <div className="">
                      <p className=" text-[#000] font-[500] ps-2 text-xs">
                        Category
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={(e) =>
                      setSearchFilter({
                        ...searchFilter,
                        subCategoryId: !searchFilter.subCategoryId,
                      })
                    }
                    className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
                  >
                    <div className="">
                      <input
                        type="checkbox"
                        checked={searchFilter.subCategoryId}
                        onChange={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            subCategoryId: !searchFilter.subCategoryId,
                          })
                        }
                        // className="bg-[#E5EDFF] p-3 "
                      />
                    </div>
                    <div className="">
                      <p className=" text-[#000] whitespace-nowrap font-[500] ps-2 text-xs">
                        Sub Category
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={(e) =>
                      setSearchFilter({
                        ...searchFilter,
                        unitId: !searchFilter.unitId,
                      })
                    }
                    className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
                  >
                    <div className="">
                      <input
                        type="checkbox"
                        checked={searchFilter.unitId}
                        onChange={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            unitId: !searchFilter.unitId,
                          })
                        }
                        // className="bg-[#E5EDFF] p-3 "
                      />
                    </div>
                    <div className="">
                      <p className=" text-[#000] font-[500] ps-2 text-xs">
                        Unit
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={(e) =>
                      setSearchFilter({
                        ...searchFilter,
                        brandId: !searchFilter.brandId,
                      })
                    }
                    className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
                  >
                    <div className="">
                      <input
                        type="checkbox"
                        checked={searchFilter.brandId}
                        onChange={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            brandId: !searchFilter.brandId,
                          })
                        }
                        // className="bg-[#E5EDFF] p-3 "
                      />
                    </div>
                    <div className="">
                      <p className=" text-[#000] font-[500] ps-2 text-xs">
                        Brand
                      </p>
                    </div>
                  </div> */}
                  <div className="flex justify-between w-full col-span-3 items-center pt-[10px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                    <div
                      onClick={(e) =>{
                        setSearchFilter({
                          ...searchFilter,
                          stockIn: !searchFilter.stockIn,
                          stockOut: false
                        });
                        /* setPrintFilter({
                          ...printFilter,
                          stock_type: printFilter.stock_type === 'stock_in' ? '' : 'stock_in',
                        }); */
                        updateFilter("stock_type",printFilter.stock_type === 'stock_in' ? '' : 'stock_in')
                      }
                      }
                      className={`relative ${
                        searchFilter.stockIn
                          ? "bg-[#407BFF] cursor-pointer text-white"
                          : "bg-gray-100"
                      }  border-gray-100 py-1 border w-full flex-1 rounded-xl`}
                    >
                      <p className="w-full text-[13px] cursor-pointer text-center">
                        Stock In
                      </p>
                    </div>
                    <div
                      onClick={(e) =>{
                        setSearchFilter({
                          ...searchFilter,
                          stockOut: !searchFilter.stockOut,
                          stockIn: false
                        });
                        /* setPrintFilter({
                          ...printFilter,
                          stock_type: printFilter.stock_type === 'stock_out' ? '' : 'stock_out',
                        }); */
                        updateFilter("stock_type",printFilter.stock_type === 'stock_out' ? '' : 'stock_out')

                      }}
                      className={`relative ${
                        searchFilter.stockOut
                          ? "bg-[#407BFF] cursor-pointer text-white"
                          : "bg-gray-100"
                      }  border-gray-100 py-1 border w-full flex-1 rounded-xl`}
                    >
                      <p className="w-full text-[13px] cursor-pointer text-center ">
                        Stock Out
                      </p>
                    </div>
                  </div>
                  {/* date */}
                  <div className='flex justify-start w-full col-span-3 items-center pt-1 md:mt-0 flex-col md:flex-row font-nunito gap-2 mt-3 pl-1'>
                    <input
                       checked={showDateFilter} // Check the checkbox if formData.have_variant has a value
                      type="checkbox"
                      id="product"
                      value="1"
                      className=""
                      name="have_variant"
                      onChange={()=> setShowDateFilter(!showDateFilter)}
                    />
                    <p className='text-[#91919F] text-sm px-1'>Filter Datewise</p>
                  </div>
                  {showDateFilter && <div className="mt-3">
                                                {/* <p className="p-2 text-md">Date</p> */}
                                                <div className="flex justify-between w-full col-span-3 items-center mt-[5px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                                                    <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                                                        <DatePicker
                                                        selected={searchFilter.startDate}
                                                        // onChange={(date) => setSearchFilter({...searchFilter, startDate: date})}
                                                        onChange={handleStartDateChange}
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholderText="00/00/00"
                                                        className="w-full px-2 text-xs focus:outline-none"
                                                        //onClick={(e) => e.stopPropagation()}
                                                        />
                                                         <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                                            Start Date
                                                        </span>
                                                    </div>
                                                    <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                                                        <DatePicker
                                                        selected={searchFilter.endDate}
                                                        //onChange={(date) => setSearchFilter({...searchFilter, endDate: date})}
                                                        onChange={handleEndDateChange}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="w-full px-2 text-xs focus:outline-none"
                                                        />
                                                         <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                                            End Date
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}
                </div>
              )}
            </div>

            <button
              onClick={GotoAddProducts}
              className="bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 pl-[22px] pr-[24px] flex items-center gap-2"
            >
              <BiPlus className="text-xl"></BiPlus>
            </button>
            <div
                // onClick={() => handlePrintPdf()}
                onClick={() => handlePrint() }
                className="bg-[#EBF1FF] rounded-full w-[56px] h-[47px] flex items-center justify-center cursor-pointer transform hover:scale-110 duration-200"
              >
                <img className="w-[16px]" src={pdfIcon} alt="pdfIcon" />

              </div>
          </div>
        </div>
        {/* Pagination component */}
      </div>
      {flashMessage && (
        <div
          className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
            flashMessage.type
          )}`}
        >
          {flashMessage.text}
        </div>
      )}
      {loading && (
        <div className="custom-loader-logo">
          {/* <BeatLoader color={"#123abc"} size={20} /> */}
          <img
            className=""
            src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
            alt="logo"
          />
        </div>
      )}

      {productsData.map((item, index) => {
        return (
          <div
            className={`gap-3 ${
              item?.current_stock === 0
                ? "border-[#ca1830]"
                : item?.current_stock <= 5
                ? "border-[#FBBC05]"
                : "border-[#18CA29]"
            } border-2 border-solid productlist-table`}
            style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
            key={item.id}
          >
            <div className="flex-1 flex flex-col lg:flex-row gap-3">
              {/* product header image*/}
              <div className="table-content-image w-full md:w-[160px] h-auto md:h-[160px]">
                <img
                  className="w-[160px] h-[160px] md:w-[100%] md:max-h-[160px] md:min-h-[160px] rounded-[12px] m-auto md:m-0"
                  src={item?.image_path ? item.image_path : shop_logo}
                  alt="img"
                />
              </div>

              {/* old code */}

              {/* new code */}

              {/* table info */}
              <div className="flex-1 font-nunito">
                {/* product header content*/}
                <div className="flex items-center gap-4 mt-2 mb-3">
                  <div>
                    <div className="text-[#2D3748] text-[16px] font-bold">
                      {item?.product_name}
                    </div>
                    <div className="text-[#718096] text-[14px] font-extrabold">
                      #{item?.id}
                    </div>
                  </div>
                  <div className="bg-[#718096] w-px rounded-full h-10"></div>
                  <span className="bg-[#FF6B02] rounded-[5px] text-[#FFF] text-xs font-extrabold px-2 py-2">
                    {item?.discount ? item?.discount : 0}{" "}
                    {item?.discount_type === "Percentage" ||
                    item?.discount_type === "2"
                      ? "%"
                      : invoiceSetting?.currency_info?.code || "BDT"}
                  </span>
                </div>

                <div className="flex flex-col lg:flex-row gap-3 w-full sm:gap-4 md:gap-5 lg:gap-6">
                  {/* first info */}
                  <div className="text-sm font-nunito flex-1 space-y-1">
                    {/* category */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Category
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {item.category_name ? item.category_name : null}
                        </span>
                      </div>
                    </div>
                    {/* sub category */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] whitespace-nowrap font-medium col-span-2">
                        Sub Category
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {item.sub_category_name
                            ? item.sub_category_name
                            : null}
                        </span>
                      </div>
                    </div>
                    {/* brand */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Brand
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {item.brand_name ? item.brand_name : null}
                        </span>
                      </div>
                    </div>
                    {/* stock */}
                    <div className="grid grid-cols-5">
                      <div
                        className={`font-medium col-span-2 ${
                          item?.current_stock === 0 ? "text-[#F00000]" : "text-[#718096]"
                        }`}
                      >
                        {item?.current_stock === 0 ? "Stock Out" : "Stock In"}
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <label
                          className="text-[#3276FF] underline"
                          onClick={() => {
                            setShowModal((e) => !e),
                            setProductImeis(item);
                          }}
                          htmlFor="variation_list_view_modal"
                          style={{ wordBreak: "break-word" }}
                        >
                          {item.current_stock != "" ? item.current_stock : "0"}{" "}
                          {item.unit_name ? item?.unit_name : null}
                        </label>
                        {/* <a
                                        class="ml-auto lg:ml-0 w-[46px] text-center border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] py-[2px]"
                                        href="#"
                                    ></a> */}
                      </div>
                    </div>
                    {/* serial */}
                    {/* <div className="grid grid-cols-5">
                                  <div className="text-[#718096] font-medium col-span-2">Serial/IMEI</div>
                                  <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                                   <span>:</span>
                                   <span style={{wordBreak:"break-word"}}>{item?.serial ? item?.serial  : null}</span>
                                   </div>
                                </div> */}
                  </div>
                  {/* second info */}
                  <div className="text-sm font-nunito flex-1 space-y-1">
                    {/* unit */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Unit
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {item.unit_name ? item?.unit_name : null}
                        </span>
                      </div>
                    </div>
                    {/* Purchase */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Purchase price
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <label
                          style={{ wordBreak: "break-word" }}
                          className="text-[#3276FF] underline"
                          onClick={() => {
                            setShowModal((e) => !e),
                            setProductImeis(item);
                          }}
                          htmlFor="variation_list_view_modal"
                        >
                          {/* {formatNumber(item?.purchase_price)} */}
                          {"Variant"}
                          {/* {invoiceSetting?.currency_info?.code
                            ? invoiceSetting?.currency_info?.code
                            : "BDT"} */}
                        </label>
                      </div>
                    </div>
                    {/* Retail price */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Sale price
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <label
                          style={{ wordBreak: "break-word" }}
                          className="text-[#3276FF] underline"
                          onClick={() => {
                            setShowModal((e) => !e),
                            setProductImeis(item);
                          }}
                          htmlFor="variation_list_view_modal"
                        >
                          {/* {formatNumber(item?.sale_price)} */}
                          {"Varient"}
                          {/* {invoiceSetting?.currency_info?.code
                            ? invoiceSetting?.currency_info?.code
                            : "BDT"} */}
                        </label>
                      </div>
                    </div>
                    {/* Wholesale price */}
                    <div className="grid grid-cols-5">
                      <div className="text-[#718096] font-medium col-span-2">
                        Wholesale price
                      </div>
                      <div className="text-[#2D3748] font-bold flex gap-1 col-span-3">
                        <span>:</span>
                        <label
                          style={{ wordBreak: "break-word" }}
                          className="text-[#3276FF] underline"
                          onClick={() => {
                            setShowModal((e) => !e),
                            setProductImeis(item);
                          }}
                          htmlFor="variation_list_view_modal"
                        >
                          {/* {formatNumber(item?.wholesale_price==null ? 0  : item?.wholesale_price)} */}
                          {"Variant"}
                          {/* {invoiceSetting?.currency_info?.code
                            ? invoiceSetting?.currency_info?.code
                            : "BDT"} */}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* table info */}
            </div>

            <div className="flex flex-col justify-start">
              <div className="table-action justify-center">
                <div className="table-action-view">
                  <Link
                    className="hover:text-[#FFF]"
                    to={`/exporter-products/${item.id}`}
                  >
                    <button className="flex justify-center items-center bg-[#407BFF] text-[#FFF] text-[12px] rounded-[5px] px-3 py-[6px]">
                      <span>View Product</span>
                    </button>
                  </Link>
                </div>
              </div>

              <div className="flex flex-col gap-3 items-start md:items-end">
                <div className="w-full flex-col md:mt-3">
                  <div id="barcode" className="w-full">
                    <div
                      id={`barcode-container-${index}`}
                      className="flex justify-center mb-[10px] w-full"
                    >
                      {/* <img src={barcodeIcon} /> */}
                      <Barcode value={item.id} options={{ width: "100%" }} />
                    </div>
                  </div>

                  <div className="flex justify-center gap-3">
                    <img
                      className="cursor-pointer"
                      onClick={() =>
                        handleImageClick("Download Icon", item, index)
                      }
                      src={downloadIcon}
                    />
                    <img
                      className="cursor-pointer"
                      onClick={() =>
                        handleImageClick("Print Icon", item, index)
                      }
                      src={printIcon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {shouldRenderPagination && (
        <Pagination
          currentPage={currentPage}
          lastPage={products.last_page}
          onPageChange={onPageChange}
        />
      )}

      <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
        {loader && <div className="custom-loader"></div>}
      </div>

      {showModal ? (
        <div>
          <input
            type="checkbox"
            id="variation_list_view_modal"
            className="modal-toggle"
          />
          <div className="modal">
            <div className="modal-box w-[600px] p-0 bg-white">
              <div className="flex justify-between flex-col md:flex-row px-[18px] py-[18px] border-b border-[#cccbcb]">
                <h4 className="font-nunito font-semibold text-lg ">
                  Product SL/IMEI
                </h4>
                <div className="modal-action">
                  <label
                    htmlFor="variation_list_view_modal"
                    className="cursor-pointer h-[22px] w-[22px] justify-between items-center flex rounded-lg bg-red-500"
                    onClick={() => setShowModal((e) => !e)}
                  >
                    <GrFormClose className="justify-between items-center flex text-white" />
                  </label>
                </div>
              </div>
              <div className="px-[16px] pt-3 pb-[22px]">
                {/* <div className="font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#F1F1FA] flex flex-row gap-3">

                </div> */}
                <table className=" w-full  text-sm text-left rounded-lg font-nunito">
                    <thead className="border-b-[1px] border-solid border-[#d9d9da]">
                      <tr >
                        <td className="py-2 font-semibold text-center whitespace-nowrap">SL NO</td>
                        <td className="py-2 font-semibold text-center">Sl/IMEI</td>
                        <td className="py-2 font-semibold text-center">Optional Name</td>
                        <td className="py-2 font-semibold text-center">Purchase Price</td>
                        <td className="py-2 font-semibold text-center">Sale Price</td>
                      </tr>
                    </thead>
                    <tbody className="font-thin">
                      {productImeis?.product_imeis_all?.map((items, index) => (
                        <tr className="border-t border-[#005DAE1C] rounded-xl">
                          <td className="py-3  text-center">
                            <h4 className="font-nunito font-medium text-18px text-[#000232] ">
                              {index + 1}{" "}
                            </h4>
                          </td>
                          <td className="py-3 text-center">
                            <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">
                              {items?.imei}{" "}
                            </h4>
                          </td>
                          <td className="py-3 text-center">
                            <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">
                              {items?.optional_name}{" "}
                            </h4>
                          </td>
                          <td className="py-3 text-center">
                            <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">

                              {items?.currency_rate !== null
                                ? Number.parseFloat(
                                (items?.purchase_price * items?.currency_rate) + items?.carrier_charge
                              ).toFixed(2) + " " + items?.base_currency_code
                                : Number.parseFloat(
                                items?.purchase_price + items?.carrier_charge
                              ).toFixed(2) + " " + items?.purchase_currency_code
                              }
                            </h4>
                          </td>
                          <td className="py-3 text-center">
                            <h4 className="font-nunito me-2 font-medium text-18px text-[#000232]">
                              {items?.currency_rate !== null
                                ? Number.parseFloat(
                                items?.sale_price
                              ).toFixed(2) + " " + items?.base_currency_code
                                : Number.parseFloat(
                                items?.sale_price
                              ).toFixed(2) + " " + items?.purchase_currency_code
                              }
                            </h4>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                {/* {productImeis?.map((variation, index) => {
                  return (
                    <div key={index}>
                      <li>{variation?.optional_name}</li>
                    </div>
                  );
                })} */}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div id="printableTable" className="hidden">
        {/* <ExporterProductReportList ref={exporterProductListRef} data={exporterProductReportList} filters={printFilter}/> */}
        <ExporterProductReportList1 ref={exporterProductListRef} data={exporterProductReportList} filters={printFilter}/>
      </div>
    </div>

  );
}

export default ExporterProductList;
