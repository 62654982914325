
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import Swal from "sweetalert2";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WarrantyTableItems = ({loader,  data,  getWarranty}) => {
  // const deliveryData = data;
  console.log(data);
  const WarrantyData = data.data ? data.data : data;

  const token = localStorage.getItem("token");

  const [showModal, setShowModal] = useState(false);

  const [warranty, setWarranty] = useState({})

  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
    return [ htmlElRef, setFocus ]
  }
  const [inputRef, setInputFocus] = useFocus();
  const [inputErors, setInputErrors] = useState({
    name: 'This field can not be empty!',
    error1: false,
    error2: false,
    error3: false
  });

  const notify = (msg,type) =>{
    if(type=='success'){
        toast.success(msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
        });
    }
    if(type=='error'){
        toast.error(msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
        });
    }
  }

  const resetFormError = () => {
    setInputErrors({
        ...inputErors,
        error1: false,
        error2: false
    });
  };

  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
      setLoading(loading);
  };

  const showModalWarning = (msg, icon='warning', data = null) => {
    Swal.fire({
      title: msg,
      text: "",
      icon: icon,
    });
  };

  const handleEditWarranty = (e) => {
    e.preventDefault();
    setInputErrors({...inputErors, error1: (warranty.warranties_count==''), error2: warranty.name=='' })
    if(warranty.name&&warranty.warranties_count){
      loadingCallback(true)
      axios.post(
        `${process.env.REACT_APP_API_URL}update-warranty`,
        { id: warranty.id, name: warranty.name, warranties_count: warranty.warranties_count},
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
          loadingCallback(false)
          console.log(response.data);
          if(response.data.success)
          {
            showModalWarning("Updated successfully!", 'success')
            setShowModal(false);
            getWarranty();
          }

          else if(response?.data?.errors) {
            response.data.errors?.name ? showModalWarning("The condition has already been taken.") : showModalWarning('Condition already exits!');
          }
          else {
            showModalWarning("Something went wrong!")
          }

      })
      .catch((error) => {
        loadingCallback(false)
        console.log(error);
      });
    }
  }

  const handleDeleteCondition = (id) =>{
    console.log("warranty", id);
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Condition!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {

        loadingCallback(true)
        axios.post(
          `${process.env.REACT_APP_API_URL}warranty-delete`,
          {
            warrantyId: id
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
            loadingCallback(false)
            console.log("warranty", response.data);
            if(response.data.success)
            {
              notify("Warranty Condition deleted successfully!", 'success')
              // showModalWarning("Warranty Condition deleted successfully!", 'success')
              getWarranty();
            }
            else if( !response.data.success){
              notify( response.data.message, 'error')
              // ("Warranty Condition deleted successfully!", 'success')
            }
            else if(response.data.error) {
              response.data.error?.name ? showModalWarning("days not negative") : showModalWarning('Condition name already exits!');
            }
            else {
              showModalWarning("Something went wrong!")
            }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    });
  }


  return (
    <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
      <ToastContainer />
      {loading && (
        <div className="custom-loader-logo">
          <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
        </div>
      )}
      <table className=" w-full text-sm text-left">
        <thead>
          <tr className="border-t border-[#005DAE1C]  rounded-xl">
            <td className="py-2">
              <h4 className='font-lato font-semibold text-[15px] text-[#000232]'>Days</h4>
            </td>
            <td className="py-3">
              <h4 className="font-lato font-semibold text-[15px] text-[#000232]">Condition Details</h4>
            </td>
            <td className=" text-right">
              <div className="text-[15px] font-nunito font-semibold flex flex-col lg:flex-row gap-2 justify-center items-center">Action</div>
            </td>
          </tr>
        </thead>
        <tbody>
          {WarrantyData.map((items, index) => (
            <>
              <tr key={items.id} className="border-t border-[#005DAE1C] rounded-xl">
                <td className="py-2">
                  <h4 className='font-lato font-semibold text-18px text-[#000232]'>{items.warranties_count}</h4>
                </td>

                <td className="py-3">
                  <h4 className="font-lato font-semibold text-18px text-[#000232]">{items.name} </h4>
                </td>

                <td className=" text-right w-1/4">
                  <div className="text-[12px] font-nunito font-[7000] flex flex-col lg:flex-row gap-2 justify-center items-center">
                    <label onClick={()=>{setWarranty(items), setShowModal(!showModal)}} htmlFor='edit_modal' className="cursor-pointer block border-[1px] border-solid border-[#407BFF]  text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Edit</label>
                    <label onClick={()=>handleDeleteCondition(items.id)}  className="cursor-pointer block border-[1px] border-solid border-[#f14343] text-[#fff] bg-[#f14343] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]"  >Delete</label>
                  </div>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>


       {/* { edit warranty method form} */}
        {showModal ? (
        <>
          <div>
            <input type='checkbox' id='edit_modal' className='modal-toggle' />
            <div className='modal'>
                <div className='modal-box w-[390px] p-0 bg-white'>
                  <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                    <h4 className='font-nunito font-semibold text-lg '>Edit Warranty Condition</h4>
                    <div className='modal-action'>
                      <label htmlFor='edit_modal' className='cursor-pointer modal-action bg-red-600 text-white rounded-md' onClick={()=>setShowModal(!showModal)}>
                        <GrFormClose />
                      </label>
                    </div>
                  </div>
                    <div className='px-5 pt-5 pb-[22px]'>
                    <form
                      method="get"
                      className="col-sm-12"
                      data-autosubmit="false"
                      autoComplete="off"
                      onSubmit={handleEditWarranty}
                    >
                      <input
                        type='number'
                        name="name"
                        value={warranty.warranties_count}

                        onChange={(e) => {
                          setWarranty( {...warranty, warranties_count: e.target.value});
                          setInputErrors({ ...inputErors, error1: false });
                        }}
                        placeholder='Warrenty Days'
                        className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                      />
                      {inputErors.error1
                      ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                      : null}
                      <input
                        type='text'
                        name="condition"
                        value={warranty.name}
                        onChange={(e) => {
                          setWarranty( {...warranty, name: e.target.value});
                          setInputErrors({ ...inputErors, error2: false });
                        }}
                        placeholder='Condition Details'
                        className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                      />
                      {inputErors.error2
                      ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                      : null}
                        <div className="button-disabled">
                          <button
                            htmlFor='edit_modal'
                            type="submit"
                            className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
          </div>
        </>
      ) : null}



      </div>
  );
};

export default WarrantyTableItems;
