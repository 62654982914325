import React from 'react';
import DashedLine from '../../../components/DashedLine/DashedLine';
import DashedLine2 from '../../../components/DashedLine/DashedLine2';
import { formatPhoneNumber, numberToWords,formatPrice,invoiceCalculate } from '../../../utils/Common';
import Watermark from '../Watermark/Watermark';
import './PdfHtmlContentFor3mm.css';



import QRCode from 'react-qr-code';

const PdfHtmlFor3mm = props => {
  const {invoice, invoiceSetting, id, shop_logo} = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';


  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;




  return (
    <div id="pdfConentAreas3" className="pdfConentAreas3">
    {/* <div id={id} className={parentClassName+ ' '+ id}> */}
      <div id="invoice-detail-page" className="invoice-detail-page font-bold">
        {/* <div className="invoice-info">
          <h1>Invoice</h1>
          <p>{invoice?.invoice_id}</p>
          <p>{formattedDate}</p>
        </div> */}
        <div className="invoice-header">
          <div className="invoice-logo invoice-logo-pdf flex justify-center font-bold">
            <img className="h-[100px]" src={shop_logo} alt={invoice?.invoice_id} />

            </div>
          <DashedLine2 />
          <div className='flex justify-between font-bold '>
            <div>
              {invoice?.user_info?.outlet_name &&
                <h1 className='text-[14px] '>{invoice?.user_info?.outlet_name}</h1>
              }
              {invoice?.user_info?.address &&
                <p className="mt-1 mb-1  text-[10px]  ">{invoice?.user_info?.address}</p>
              }
              {invoice?.user_info?.phone &&
                <p className=' text-[10px]  '>Mobile: {formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}</p>
              }
            </div>
            <div className="w-[70px]">
              <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice?.invoice_id} size={50} />
            </div>
          </div>
        </div>
        <DashedLine />

        <div className="invoice-customer font-bold flex justify-between">
          <div>
            <p className=' text-[10px]  '>Date: {formattedDateTime}</p>
            <p className=' text-[10px]  '>Customer Id:  #{invoice?.customer_id}</p>
          </div>
          <div>
            <p className=' text-[10px]  ' > Name: {invoice?.customer_name} </p>
            <p className=' text-[10px]  '> Phone: {invoice?.country_code}{invoice?.customer_phone}</p>
          </div>
        </div>
        <div style={{ textAlign: 'center', margin: '0 auto' }}>
          <p className="   text-[11px] p-2 font-bold  uppercase ">Invoice No: {invoice?.invoice_id}</p>
        </div>

        <div className="invoice-items font-bold">
          <table className="table">
            <thead>
              <tr className="text-[12px] ">
                <th className=' text-[12px]'>Name</th>
                <th className=' text-[12px]'>Qty</th>
                <th className=' text-[12px]'>Price</th>
                <th className=' text-[12px]'>Total</th>
              </tr>
            </thead>

            <tbody style={{ fontSize: '11px' }}>
              <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark>
              {invoice?.sales_details.map((item) => {
                totalQty += Number(item.qty);
                totalItems += 1;
                const subtotalPrice = formatPrice(item.qty * item.price);
                totalPrice += Number(subtotalPrice);
                return (
                  <tr key={item.id}>
                  <td style={{ fontWeight: '800', whiteSpace: 'nowrap' }}>{item?.product_info?.name}<br/><span className='text-[13px]'>{item?.product_info?.serial}</span></td>
                  <td style={{ fontWeight: '800', whiteSpace: 'nowrap' }}>{item.qty}</td>
                  <td style={{ fontWeight: '800', whiteSpace: 'nowrap' }}>{item.price}</td>
                  <td style={{ fontWeight: '800', whiteSpace: 'nowrap' }}>{subtotalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</td>
                </tr>
                );
              }
              )}
            </tbody>
          </table>
        </div>
        <DashedLine2 />
        <div className="invoice-summary">
          <p className='flex   justify-between  font-bold  text-[11px]'>
            <span className=''>TOTAL ITEMS</span>
            <span className=''>{totalItems}</span>
          </p>

          <p className='flex   justify-between  font-bold text-[11px] '>
            <span className=''>SUB TOTAL</span>
            <span className=''>{totalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>

          <p className='flex   justify-between  font-bold  text-[11px]'>
            <span className=''>DISCOUNT</span>
            <span className=''>{formatPrice(invoice?.discount ? invoice?.discount : 0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <p className='flex   justify-between  font-bold   text-[11px]'>
            <span className=''>Vat {''}</span>
            <span className=''>{invoice?.vat ? formatPrice(invoice?.vat) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <p className='flex font-bold  justify-between text-[11px]'>
            <span className=''>Service Charge {''}</span>
            <span className=''>{invoice?.tax ? formatPrice(invoice?.tax) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <DashedLine2 />
          <p className='flex   justify-between font-bold text-[11px]'>
            <span className=''>Grand Totals</span>
            <span className=''>{formatPrice(invoiceCalculate(invoice).grandTotal)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
        </div>
        <p className='flex   justify-between  text-[11px]  font-bold '>
          <span className=''>Paid Amount</span>
          <span className=''>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
        </p>
        <p className='flex   justify-between  text-[11px]  font-bold '>
          <span className=''>Due Amount</span>
          {/* <span className=''>{formatPrice(invoice?.sub_total - invoice?.paid_amount)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
          <span className=''>{formatPrice(invoiceCalculate(invoice).dueAmount) }{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
        </p>
        <hr></hr>
        <p className='flex flex-col  font-bold'>
          <p className='  text-[11px] font-bold '>In word: {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))} taka only</p>
        </p>
        <div className='font-bold' style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
          <p className="   text-[11px] p-2  uppercase ">You saved {formatPrice(invoice?.discount ? invoice?.discount : 0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
          <p className='   text-[11px] pl-2 pr-2  uppercase '>Thanks for purchase</p>
          <p className='   text-[10px] pl-2 pr-2  uppercase '>A Product of SQUAD INNOVATORS </p>
        </div>

        <div className='text-center font-nunito '>
          <p className='invisible'>.</p>

        </div>

      </div>
    </div>
  );
};

export default PdfHtmlFor3mm;