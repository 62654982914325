import classNames from 'classnames';
import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { useFlashMessage } from "../context/FlashMessageContext";
//import Select2 from "react-chosen";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { post } from "../services/apiService";
import { useNavigate } from 'react-router-dom';
import FlagIcon from "./FlagIcon";
import DialCodes from '../data/dialCodes.json';
import { FlashMessage } from '../utils/FlashMessage';
import { ToastContainer, toast } from 'react-toastify';


const InvoiceSellerForm = props => {
  const {
    loadingCallback,
    setSeller,
    setWarningMessage,
    state,
    setShowModal,
    formId,
    filteredSeller,
    setFilteredSeller
  } = props;
  const invoiceDate = new Date();
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';


  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;


  const { countryList, bloodGroups } = state;
  const [country, setCountry] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState('');
  const [cityList, setCityList] = useState([]);
  const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [showNewSellerForm, setShowNewSellerForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const page = 1;
  const perPage = 20;
  const token = localStorage.getItem("token");
  const getSellerListData = async (searchKeyword='') => {
    loadingCallback(true);
    setFilteredSeller([]);
    const page1 = 1;
    if (searchKeyword === "") {
      const options = [{ id: '', name: 'Select' }];
      axios
      .get(
        `${process.env.REACT_APP_API_URL}employee`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          const sellers = response.data.data.data;
          // Append other options if there are sellers
          if (sellers.length > 0) {
            const sellerOptions = sellers.map((seller) => ({
              id: seller.id,
              employee_type: seller.employee_type,
              name: seller.name,
              email: seller.email,
              country_code: seller.country_code,
              mobile_number: seller.mobile_number,
              address: seller.address,
              country: seller.country,
              district: seller.district,
              city: seller.city,
              zipcode: seller.zipcode,
              nid: seller.nid,
              joining_date: seller.joining_date,
              blood_group_id: seller.blood_group_id,
              emergency_contact_name: seller.emergency_contact_name,
              emergency_country_code: seller.emergency_country_code,
              emergency_mobile_numbers: seller.emergency_mobile_numbers,
              relation_with: seller.relation_with,
              status: seller.status,
            }));
            options.push(...sellerOptions);
          }
        } else {
          console.log("Data Not Found!");
        }
        setFilteredSeller(options);
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}search-employee?page=${page1}&limit=${perPage}`,
          { keyword: searchKeyword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          loadingCallback(false);
          const options = [];
          if (response.status === 200) {
            //setFilteredSeller(response.data.data.data);
            const sellers = response.data.data.data;

            // Append other options if there are sellers
            if (sellers.length > 0) {
              const sellerOptions = sellers.map((seller) => ({
                id: seller.id,
                name: seller.name,
                email: seller.email,
                country_code: seller.country_code,
                mobile_number: seller.mobile_number,
                address: seller.address,
                country: seller.country,
                district: seller.district,
                city: seller.city,
                zipcode: seller.zipcode,
                nid: seller.nid
              }));
              options.push(...sellerOptions);
            }

          } else {
            console.log("Data Not Found!");
            const sellerOptions = [{ id: '', name: 'Select' }];
            options.push(sellerOptions);
          }
          setFilteredSeller(options);
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);

            navigate('/login');
          }
        });
    }
  };

  useEffect(() => {
    getSellerListData(searchKeyword);
  }, [searchKeyword]);
  useEffect(() => {
    if (selectedSeller && selectedSeller.country) {
      setCountry(selectedSeller.country);
      getDistrictList(selectedSeller.country);
    } else {
      getDistrictList(country);
    }

  }, [country,selectedSeller]);

  const getDistrictList = (country) => {
    post("get-district-by-country", { country_id:country }, token)
      .then((response) => {
        if (response.success) {
          setDistrictList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityList = (district) => {
    setDistrict(district);
    post("get-cities-by-district", { district_id:district }, token)
      .then((response) => {
        if (response.success) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDialCodeByCountryCode = (code) => {
    const country = DialCodes.find((item) => item.code === code);
    return country ? country.dial_code : '';
  };





  const handleSellerSelect = (event) => {
    if (event.target.value === "") {
      setSelectedSeller(null);
      return;
    }
    const sellerId = parseInt(event.target.value);
    const selectedSeller = filteredSeller.find((seller) => seller.id === sellerId);
    setSelectedSeller(selectedSeller);
    setShowNewSellerForm(false);
    // Update the value of the input field
    if (selectedSeller.district) {
      getCityList(selectedSeller.district);
    }
  };




  const handleValidation = (formData) => {
    const errors = {};
    let validate = true
    if (formData.seller_email==='') {
      errors.seller_email = 'Email is required.';
      setIsValid(false);
      validate=false;
    }
    if (formData.seller_name==='') {
      errors.seller_name = 'Seller name is required.';
      setIsValid(false);
      validate=false;

    }

    if (formData.seller_phone==='') {
      errors.seller_phone = 'Phone Number is required.';
      setIsValid(false);
      validate=false;
    }
    setFormErrors(errors);
    if (!validate) {
      // setErrorFlashMessage('Please fill all the required fields.');
      FlashMessage('Please fill all the required fields.','error');
    }

    return validate;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formObject = {};

    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    if (!selectedSeller) {
      formObject.cutomer_id = 0;
    }
    if (handleValidation(formObject)) {
      // console.log("============");
      // console.log(isValid);
      // console.log(formObject);
      // console.log(formErrors);
      //if (isValid) {
        const sellerData = {
          employee_type: "Full Time",
          joining_date: formattedDate,
          blood_group_id: formObject.blood_group_id ? formObject.blood_group_id : null,
          //designation_id: 1,
          //branch_id: 1,
          //department_id: 1,
          //role_id: 1,
          emergency_contact_name: formObject.emergency_contact_name ? formObject.emergency_contact_name : null,
          emergency_country_code: formObject.emergency_country_code ? formObject.emergency_country_code : null,
          emergency_mobile_numbers: formObject.emergency_mobile_numbers ? formObject.emergency_mobile_numbers : null,
          relation_with: formObject.relation_with ? formObject.relation_with : null,
          status: 1,
          in_time: "10:00",
          out_time: "18:00",
          password: formObject.password ? formObject.password : null,
          name: formObject.seller_name ? formObject.seller_name : null,
          email: formObject.seller_email ? formObject.seller_email : null,
          country_code: formObject.country_code ? formObject.country_code : null,
          mobile_number: formObject.seller_phone ? formObject.seller_phone : null,
          address: formObject.seller_address ? formObject.seller_address : null,
          country: formObject.country_id ? Number(formObject.country_id) : null,
          district: formObject.district_id ? Number(formObject.district_id) : null,
          city: formObject.city_id ? Number(formObject.city_id) : null,
          zipcode: formObject.seller_zip ? Number(formObject.seller_zip) : null,
          nid: formObject.nid ? Number(formObject.nid) : null,
        };
        // console.log("============");
        // console.log(sellerData);

        if (selectedSeller) {
          sellerData.emp_id = Number(formObject.seller_id);
          sellerData.id = Number(formObject.seller_id);
          updateSeller(sellerData);
        } else {
          createSeller(sellerData);
        }
      // } else {
      //   console.error('Form validation failed.');
      // }

    } else {
      console.error('Form validation failed.');
    }

  };

  const createSeller = (formData) => {
      loadingCallback(true);
      // setFlashMessage('Product saved successfully');
      // navigate('/products');
      // Use your API service functions to make API requests here
      //if(isValid){
        post("save-employee", formData, token)
          .then((response) => {
            loadingCallback(false);
            if (response.status===200) {
              setSeller(response.data);
              setShowModal(false);
              // setSuccessFlashMessage('seller saved successfully');
              FlashMessage('seller saved successfully','success');
              setShowNewSellerForm(false);
            } else {
              console.log("seller insertion failed");

                setWarningMessage(true);
                const timer = setTimeout(() => {
                  setWarningMessage(false);
                }, 3000);
                return () => clearTimeout(timer);
            }
          })
          .catch((error) => {
            loadingCallback(false);
            if (error?.response?.status === 401) {
              localStorage.clear();
              localStorage.setItem('token', false);
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
            }
            console.log(error);
          });
      //}
  }
  const updateSeller = (formData) => {
    //loadingCallback(true);
    setSeller(formData);
    setShowModal(false);
    // post("update-employee", formData, token)
    //   .then((response) => {
    //     loadingCallback(false);
    //     if (response.status === 200) {
    //       setSeller(response.data);
    //       setShowModal(false);
    //       setSuccessFlashMessage('Seller updated successfully');
    //     } else {
    //       console.log("Seller update failed");
    //       setErrorFlashMessage('Seller update failed');

    //         setWarningMessage(true);
    //         const timer = setTimeout(() => {
    //           setWarningMessage(false);
    //         }, 3000);
    //         return () => clearTimeout(timer);
    //     }
    //   })
    //   .catch((error) => {
    //     loadingCallback(false);
    //     if (error?.response?.status === 401) {
    //         localStorage.setItem('token', '');
    //         localStorage.setItem('token', '');
    //         // Clear session and local storage
    //         localStorage.setItem('isPinVerify', false);

    //         navigate('/login');
    //     }
    //     console.log(error);
    //   });
  }

  const handleNewSellerFormClick = () => {
    setShowNewSellerForm(true);
    setSelectedSeller(false);
  };
  const handleErrorInputChange = (fieldName, value) => {
    if (value!=='') {
      //formErrors[fieldName] = null;
      // unset formErrors by fieldName
      const updatedFormErrors = { ...formErrors };
      delete updatedFormErrors[fieldName];
      setFormErrors(updatedFormErrors);
    }

  }
  const handleallInputChange = (fieldName, value) => {

    if (fieldName === 'country') {
      // Update the country state
      setCountry(value);
      setCityList([]);

      // Nullify the city and district values
      setSelectedSeller((prevSeller) => ({
        ...prevSeller,
        country: value,
        city: null,
        district: null,
      }));
    }
    else if (fieldName === 'district') {
      // Update the district state
      getCityList(value);

      // Update the selectedSeller state with the modified field
      setSelectedSeller((prevSeller) => ({
        ...prevSeller,
        city: null,
        [fieldName]: value,
      }));
    } else {
      // Update the selectedSeller state with the modified field
      setSelectedSeller((prevSeller) => ({
        ...prevSeller,
        [fieldName]: value,
      }));
    }
  };

  return (
    <form
        method="get"
        className="col-sm-12"
        data-autosubmit="false"
        autoComplete="off"
        onSubmit={handleSubmit}
    >
      <ToastContainer />
      <div className="mb-3 font-nunito">
        <label htmlFor="sellerSearch" className="form-label">Search Seller</label>
        {/* <Select2
          id="customerSearch"
          placeholder="Enter customer name or email"
          value={selectedCustomer}
          options={filteredCustomers}
          onChange={handleSelectChange}
        /> */}
        <input
          type="text"
          id="sellerSearch"
          name="sellerSearch"
          className="form-control rounded-[15px] p-3"
          placeholder="Enter Seller name"
          value={searchKeyword}
          onChange={(e) =>setSearchKeyword(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="sellerSelect" className="form-label">
          Select Seller
        </label>
        <div className="d-flex align-items-center">
          <select
            id="seller_id"
            name="seller_id"
            className={classNames(
              'w-full',
              'rounded-[15px]',
              'p-3',
              'bg-white'
            )}
            style={{  border: "1px solid #ccc" }}
            onChange={handleSellerSelect}
            value={selectedSeller ? selectedSeller.id : ""}
          >
            {filteredSeller && ( filteredSeller.map((seller) => (
              <option key={seller.id} value={seller.id}>
                {seller.name}
              </option>
            )) )}
          </select>
        </div>
      </div>

      {selectedSeller && (
        <>
          <div className="mb-3">
            <input
              type="text"
              id="seller_name"
              name="seller_name"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white'

              )}
              placeholder="Seller Name"
              value={selectedSeller.name}
              onChange={(e) => {
                handleallInputChange("name", e.target.value)
                handleErrorInputChange('seller_name', e.target.value)
              }}
            />
            {formErrors && formErrors.seller_name ? <p className="error-message text-red-500">{formErrors.seller_name}</p> : null}
          </div>
          <div className="mb-3">
            <input
              type="text"
              id="seller_email"
              name="seller_email"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white'

              )}
              placeholder="Seller Email"
              value={selectedSeller.email}
              onChange={(e) => {
                handleallInputChange("email", e.target.value)
                handleErrorInputChange('seller_email', e.target.value)
              }}
            />
            {formErrors && formErrors.seller_email ? <p className="error-message text-red-500">{formErrors.seller_email}</p> : null}
          </div>
          <div className="mb-3 flex ">
            <select
              id="country_code"
              name="country_code"
              className={classNames(
                'w-2/4',
                'rounded-[15px]',
                'p-3',
                'bg-white',
                'mr-2'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedSeller.country_code}
              onChange={(e) => handleallInputChange("country_code", e.target.value)}
            >

              <option value="">Select</option>
              {countryList.map((country) => (
                <option key={country.id} className="text-[14px]" value={getDialCodeByCountryCode(country.code)}>
                  <FlagIcon countryCode={country.code} />{getDialCodeByCountryCode(country.code)}
                </option>
              ))}
            </select>
            <input
              type="text"
              id="seller_phone"
              name="seller_phone"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white',
                'w-3/4'

              )}
              placeholder="Seller Phone Number"
              value={selectedSeller.mobile_number}
              onChange={(e) => {
                handleallInputChange("mobile_number", e.target.value)
                handleErrorInputChange('seller_phone', e.target.value)
              }}
            />
            {formErrors && formErrors.seller_phone ? <p className="error-message text-red-500">{formErrors.seller_phone}</p> : null}
          </div>
          <div className="mb-3">
            <input
              type="text"
              id="seller_address"
              name="seller_address"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white'

              )}
              placeholder="Seller Address"
              value={selectedSeller.address}
              onChange={(e) => handleallInputChange("address", e.target.value)}
            />
          </div>
          {/* {country select box} */}
          <div className="mb-3">
            <select
              id="country_id"
              name="country_id"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'p-3',
                'bg-white'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedSeller.country}
              onChange={(e) => handleallInputChange("country", e.target.value)}
            >
              <option value="">Select Country</option>
              {countryList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <select
              id="district_id"
              name="district_id"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'p-3',
                'bg-white'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedSeller.district}
              onChange={(e) => handleallInputChange("district", e.target.value)}
            >
              <option value="">Select District</option>
              {districtList.length === 0 && (
                <option value={selectedSeller.district}>{selectedSeller.district}</option>
              )}
              {districtList.map((district) => (
                <option key={district.id} value={district.id}>
                  {district.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">

            <select
              id="city_id"
              name="city_id"
              className={classNames(
                'w-full',
                'rounded-[15px]',
                'p-3',
                'bg-white'
              )}
              style={{  border: "1px solid #ccc" }}
              value={selectedSeller.city}
              onChange={(e) => handleallInputChange("city", e.target.value)}
            >
              <option value="">Select City</option>
              {cityList.length === 0 && (
                <option value={selectedSeller.city}>{selectedSeller.city}</option>
              )}
              {cityList.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              id="seller_zip"
              name="seller_zip"
              className={classNames(
                'form-control',
                'rounded-[15px]',
                'p-3',
                'bg-white',
                'mt-2'

              )}
              placeholder="Zip Code"
              value={selectedSeller.zipcode}
              onChange={(e) => handleallInputChange("zipcode", e.target.value)}
            />
          </div>
        </>
      )}
      {selectedSeller ? (
        <div className="button-disabled">
          <button
            htmlFor={formId}
            type="submit"
            className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
          > Add Seller
          </button>
        </div>
      ) : null}
    </form>
  );
};

export default InvoiceSellerForm;
