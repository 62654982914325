// import React from 'react';

// const AccountingHistoryItem = () => {
//   return (
//     <div>

//     </div>
//   );
// };

// export default AccountingHistoryItem;


import React, { useEffect, useState } from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const AccountingHistoryItem = (props) => {
  const {
    transactionData,
    paymentList,
    paymentAccountList,
    activeTab,
    selectAllChecked,
    setAllFetchedData,
    allFetchedData,
    onSelect,
    index
} = props;

  const [isChecked, setIsChecked] = useState(false);

  // useEffect(() => {
  //   setIsChecked(selectAllChecked);
  // }, [selectAllChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelect(index, transactionData);
  };

  const paymentData = paymentList.data ? paymentList.data : paymentList;
  const paymentAccountData = paymentAccountList.data ? paymentAccountList.data : paymentAccountList;

  const createdDate = new Date(transactionData?.created_at);

  return (
    <>
    {activeTab &&
      <div className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-2 font-nunito grid grid-cols-10 items-start">

        <div className='text-left col-span-1'>
          <input
            type="checkbox"
            checked={transactionData.completed}
            onChange={handleCheckboxChange}
          />
        </div>

        <div className="text-left text-[.70rem] col-span-1">
          <h1 className=" text-[#000] font-[600]">{formatDate(transactionData?.created_at)}</h1>
          <p className=" text-[#87889E] font-[500] text-sm">{timeDiff(createdDate)}</p>
        </div>

        <div className="text-[#000] font-[500] text-center col-span-2">
          {/* {transactionData.payment_method && transactionData.payment_method.map((item, index) => (
            (item.payment_type_category_id && item.payment_type_id) ? (

              paymentData.map((payment) => (
                (payment.id === item.payment_type_id) ? (
                  payment?.payment_type_category.map((account)=>(
                    ( account.id==item.payment_type_category_id) &&
                      (
                        <div className='w-20 mx-auto'>
                          <p key={index} className="text-[#000] font-[500] whitespace-nowrap text-left text-md">
                            {payment.type_name}
                          </p>
                          { String(payment?.type_name).toLowerCase() !='cash' &&
                          <p className='text-left'>{" "}{account.payment_category_name}<br/><span className='whitespace-nowrap text-[#FF0000]'>{"AC: "}{account.account_number}</span><br></br>
                            <span className='font-bold text-emerald-600'>{Number.parseFloat(item.payment_amount).toFixed(2)}{" BDT"}</span>
                          </p> }
                          <hr />
                        </div>
                      )
                      // : null
                  ))

                ) : null
              ))
            ) : (
              <div className='w-20 mx-auto col-span-1'>
                <p key={index} className="text-[#000] text-left whitespace-nowrap font-[500] text-md">{"Cash-"}
                { item.payment_amount==null? 0 : <span className='font-bold text-emerald-600'>{Number.parseFloat(item.payment_amount).toFixed(2)}{" BDT"}</span>}
                </p>
              </div>
            )
          ))} */}
          {
            transactionData?.multiple_payments.map((item)=>(
              item?.payment_type?.type_name =='cash' ?
              <div className='text-[.70rem]'>
                <p className='text-center'>{item?.payment_type?.type_name}</p>
              </div>
              :
              <div className='text-[.70rem]'>
                <p className='whitespace-nowrap text-center'>{item?.payment_type?.type_name}</p>
                <p className='font-semibold whitespace-nowrap text-center'>{item?.payment_type_category?.payment_category_name}</p>
                <p className='text-center'>{item?.payment_type_category?.account_number}</p>
              </div>
            ))
          }
        </div>


        <div className="text-[#000] font-semibold text-center text-[.70rem] col-span-1">
          <p className="text-[#000] font-[500] text-md">{transactionData?.transaction_category}</p>
        </div>


        <div className="text-[#000] font-[500] text-[.70rem] text-center col-span-1">
          <p className="text-[#000] font-[500] text-md">{transactionData?.transaction_type?.head_name}</p>
        </div>

        <div className="text-[#000] font-[500] text-[.70rem] text-center col-span-2">
          <p className="text-[#000] font-[500] text-md">{transactionData?.transaction_description}</p>
        </div>

        <div className=" text-[#000] font-[500] text-[.70rem] text-center col-span-1">{transactionData?.transaction_name}</div>

        <div className=" text-[#000] font-[500] text-[.70rem] text-right v">{Number.parseFloat(transactionData?.transaction_amount).toFixed(2)}</div>
      </div>}
    </>
  );
};
AccountingHistoryItem.defaultProps = { className: null };
export default AccountingHistoryItem;