import React from 'react';
import DashedLine from '../../../components/DashedLine/DashedLine';
import DashedLine2 from '../../../components/DashedLine/DashedLine2';
import { formatPhoneNumber,formatPrice, numberToWords } from '../../../utils/Common';
import Watermark from '../Watermark/Watermark';
// import './PdfHtmlContentFor3mm.css';
import './../../../pages/InvoiceDetailPage/PdfHtmlContentFor3mm/PdfHtmlContentFor3mm.css';

import QRCode from 'react-qr-code';

const PdfHtmlContent2mm = props => {
  const { invoice, invoiceSetting,type } = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';

  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;;
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalPrice = 0;
  const item = type === 'salesReturn' ? invoice?.sales_details : invoice?.purchase_details;
  const totalQty = Number(item?.return_qty);
  const subtotalPrice = totalQty * Number(item?.return_unit_price);
  //fetchImage();


  return (
    <div id="pdfConentAreas2" className="pdfConentAreas2 px-2">
    {/* <div id={id} className={parentClassName+ ' '+ id}> */}
      <div id="invoice-detail-page" className="invoice-detail-page font-bold">
        {/* <div className="invoice-info">
          <h1>Invoice</h1>
          <p>{invoice?.invoice_id}</p>
          <p>{formattedDate}</p>
        </div> */}
        <div className="invoice-header">
          <div className="invoice-logo invoice-logo-pdf flex justify-center font-bold">
          <img
           src={shop_logo}
            alt={invoice.invoice_id}
               className="mx-auto p-3" />
               <div className='my-2'>
              <p>{invoiceSetting?.shop_name} <br />
                {invoiceSetting.shop_address}</p>
            </div>
          </div>
          <DashedLine2 />
          <div className='flex justify-between font-bold text-[10px]'>

            <p>{invoice?.user_info?.outlet_name} <br />
                {invoice?.user_info?.address}<br/>
                {formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}
                </p>
            <div className="w-[70px]">
{type === 'salesReturn' ?
              <QRCode className="w-full object-contain" value={BASE_URL + '/sales-return/' + invoice.return_id} size={50} />
            :
              <QRCode className="w-full object-contain" value={BASE_URL + '/purchase-return/' + invoice.return_id} size={50} />
            }
            </div>
          </div>
        </div>
        <DashedLine />

        <div className="invoice-customer text-[10px] font-bold flex justify-between">
          {/* <div>
            <p className=' text-[10px]  '>Date: {formattedDateTime}</p>
            <p className=' text-[10px]  '>Customer Id:  #{invoice?.customer_id}</p>
          </div>
          <div>
            <p className=' text-[10px]  ' > Name: {invoice?.customer_name} </p>
            <p className=' text-[10px]  '> Phone: {invoice?.country_code}{invoice?.customer_phone}</p>
          </div> */}
          <div>
            {type === 'salesReturn' ?
              <p className='mb-1'> <span className='font-semibold'>Customer Name</span>  :  {invoice?.sales?.customer_name} </p>
            :
              <p className='mb-1'> <span className='font-semibold'>Vendor Name</span>  :  {invoice?.purchase?.vendor_name} </p>
            }
            {type === 'salesReturn' ?
              <p className='mb-1'><span className='font-semibold'>Customer Phone</span> :  {invoice?.sales?.customers?.country_code}{invoice?.sales?.customers?.mobile_number}</p>
            :
              <p className='mb-1'><span className='font-semibold'>Vendor Phone</span> :  {invoice?.purchase?.vendor?.country_code}{invoice?.sales?.vendor?.mobile_number}</p>
            }
            {type === 'salesReturn' ?
              <p className='mb-1'><span className='font-semibold'> Customer ID </span> : #{invoice.customer_id}</p>
            :
              <p className='mb-1'><span className='font-semibold'> Vendor ID </span> : #{invoice.vendor_id}</p>
            }
            <p className='mb-1'><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p>
            </div>
        </div>
        <div style={{ textAlign: 'center', margin: '0 auto' }}>
          <p className="   text-[10px] p-2 font-bold  uppercase ">Invoice No: {invoice.return_id}</p>
        </div>

        <div className="invoice-items font-bold">
          <table className="table">
            <thead>
              <tr className="text-[10px]">
                <th style={{verticalAlign:"baseline"}}>Date</th>
                <th style={{verticalAlign:"baseline"}}>Name</th>
                <th style={{verticalAlign:"baseline"}}>Qty</th>
                <th className='text-right' style={{verticalAlign:"baseline"}}>Return<br/>Amt.</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '10px' }}>
              <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark>
              { item && (
                  <tr key={item.id} className=" font-nunito">
                    <td>{formattedDate}</td>
                    <td>{item?.product_info?.name}</td>
                    <td className='text-center'>{item.return_qty}</td>
                    <td className='text-right'>{formatPrice(item.return_amount)} {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</td>
                  </tr>

              )}
            </tbody>
          </table>
        </div>
        <DashedLine2 />
        <div className="invoice-summary" style={{fontSize:"8px"}}>
          <p className='flex   justify-between  font-bold  !text-[8px]'>
            <span className=''>TOTAL ITEMS</span>
            <span className=''>{totalQty}</span>
          </p>

          <p className='flex   justify-between  font-bold !text-[8px] '>
            <span className=''>SUB TOTAL</span>
            <span className=''>{formatPrice(subtotalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
          </p>

          <p className='flex   justify-between font-bold  !text-[8px]'>
          <span>Grand Total</span>
              <span>{formatPrice(subtotalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
          </p>

          <p className='flex   justify-between  font-bold  text-[10px]'>
          <span>Paid Amount</span>
              <span>{type === "salesReturn" ? formatPrice(invoice?.sales?.paid_amount) : formatPrice(invoice?.purchase?.paid_amount) }
              {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
          </p>

          <p className='flex   justify-between  font-bold  text-[10px]'>
          <span>Return Amount</span>
              <span>{item?.return_amount}
               {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
          </p>

          <p className='flex justify-between  font-bold  text-[10px]'>
              <span>Due Amount</span>
              <span>{formatPrice(item?.return_amount-subtotalPrice)}
               {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
            </p>


          <DashedLine2 />

          <div className='flex flex-col text-[10px]'>
            In word: {numberToWords(subtotalPrice)} taka only
          </div>

          <div className="text-[10px] text-center mt-[15px] mb-[15px]">
            You have returned {formatPrice(item?.return_amount)} {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
          </div>


        </div>
        <div className='font-bold' style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', fontSize:"8px" }}>
          <p className='pl-2 pr-2  uppercase '>Thanks for purchase</p>
          <p className='pl-2 pr-2  uppercase font-[400]'>A Product of SQUAD INNOVATORS </p>
        </div>

        <div className='text-center font-nunito '>
          <p className='invisible'>.</p>

        </div>

      </div>
    </div>
  );
};

export default PdfHtmlContent2mm;