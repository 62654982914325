import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from '../../utils/Common';


const AdvanceBookingItem = (props) => {
  const { invoice, className,showModalBeforeDelete, index } = props;
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/advance-bookings/${invoice.name}`);
  };

  return (
    <div className="border-t-[1px] text-sm border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-4 sm:grid-cols-5 items-start">
      <div className="">
        <h1 className="text-[#000232] font-[500]">{invoice.name}</h1>
        <p className="text-[#87889E] text-xs  font-[700]">{invoice.sname}</p>
      </div>

      <div className=" text-[#000] font-[500] text-center">{invoice.price}</div>
      <div className=" text-[#000] font-[500] text-center">{invoice.due}</div>
      <div className="hidden sm:block">
        <h1 className=" text-[#000] font-[500] text-center">{formatDate(invoice.date)}</h1>
        <p className=" text-[#87889E] text-xs font-[600] text-center">{invoice.time}</p>
      </div>
      <div className="text-[12px] font-nunito font-[7000] flex flex-col lg:flex-row gap-2 justify-center items-center">
        <a className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]" href={`/advance-bookings/${invoice.name}`}>VIEW</a>
        <a className="block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#407BFF] px-[12px] pt-[2px] pb-[2px]" href={`/advance-bookings/edit/${invoice.name}`}>EDIT</a>
        <a onClick={() => showModalBeforeDelete(invoice?.name, index)} className="cursor-pointer block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#FF0303] px-[5px] pt-[2px] pb-[2px]">DELETE</a>
      </div>
    </div>
  );
};

AdvanceBookingItem.defaultProps = { className: null };

export default AdvanceBookingItem;
