import React, { useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import Resizer from "react-image-file-resizer";
import { validateData } from "../utils/Common";
import { useFlashMessage } from "../context/FlashMessageContext";
import { useNavigate } from "react-router-dom";
import SelectBox from "./Select";

const ShopSettingFormV2 = (props) => {
  const {
    districtList,
    cityList,
    handleChangeSelect,
    handleChange,
    state,
    initialValues,
    setIsEditing,
  } = props;
  const { shopCategoryList } = state;
  const { setErrorFlashMessage } = useFlashMessage();
  const [fileLogo, setFile] = useState(null);
  const [selectedValue, setSelectedValue] = useState(
    initialValues ? initialValues.watermark_type : "text"
  );
  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  return (
    <div className="rounded-lg w-full">
      <h2 className="text-lg font-semibold mb-6">Personal Information</h2>
      <FinalForm
        {...props}
        initialValues={initialValues}
        onSubmit={props.onSubmit}
        validate={(values) => ({
          outlet_name: validateData(values.outlet_name, "Shop Name"),
        })}
        render={(formRenderProps) => {
          const { handleSubmit } = formRenderProps;
          return (
            <form
              className="grid grid-cols-1 md:grid-cols-3 gap-6 bg-[#FAFAFA]"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 col-span-3">
                {/* Shop Name */}
                <div className="relative">
                  <label
                    htmlFor="shopName"
                    className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                  >
                    Shop Name
                  </label>
                  <Field name="outlet_name">
                    {({ input, meta }) => (
                      <>
                        <input
                          {...input}
                          type="text"
                          id="shopName"
                          placeholder="Shop Name"
                          onChange={handleChange}
                          className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 placeholder:text-sm"
                        />
                        {meta.touched && meta.error && (
                          <div className="warning-message">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </div>

                {/* Owner Name */}
                <div className="relative">
                  <label
                    htmlFor="ownerName"
                    className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                  >
                    Owner Name
                  </label>
                  <Field name="owner_name">
                    {({ input, meta }) => (
                      <>
                        <input
                          {...input}
                          type="text"
                          id="ownerName"
                          placeholder="Owner Name"
                          onChange={handleChange}
                          className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 placeholder:text-sm"
                        />
                        {meta.touched && meta.error && (
                          <div className="warning-message">{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                </div>
              </div>

              {/* Email */}
              <div className="relative col-span-3 md:col-span-3">
                <label
                  htmlFor="email"
                  className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                >
                  Account Email Address
                </label>
                <Field name="email">
                  {({ input, meta }) => (
                    <>
                      <input
                        {...input}
                        type="text"
                        id="email"
                        placeholder="Account Email Address"
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 placeholder:text-sm"
                      />
                    </>
                  )}
                </Field>
              </div>

              {/* Phone Number */}
              <div className="relative col-span-3 md:col-span-3">
                <label
                  htmlFor="phone"
                  className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                >
                  Account Phone Number
                </label>
                <Field name="phone">
                  {({ input, meta }) => (
                    <>
                      <input
                        {...input}
                        type="text"
                        id="phone"
                        placeholder="Account Phone Number"
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 placeholder:text-sm"
                      />
                    </>
                  )}
                </Field>
              </div>

              {/* Company Name */}
              <div className="relative col-span-3 md:col-span-1">
                <label
                  htmlFor="company_name"
                  className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                >
                  Company Name
                </label>
                <Field name="outlet_name">
                  {({ input, meta }) => (
                    <>
                      <input
                        {...input}
                        type="text"
                        id="company_name"
                        placeholder="Company Name"
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 placeholder:text-sm"
                      />
                    </>
                  )}
                </Field>
              </div>

              {/* Company Site */}
              <div className="relative col-span-3 md:col-span-1">
                <label
                  htmlFor="company_site"
                  className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                >
                  Company Site
                </label>
                <Field name="web_address">
                  {({ input, meta }) => (
                    <>
                      <input
                        {...input}
                        type="text"
                        id="company_site"
                        placeholder="Company Site"
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 placeholder:text-sm"
                      />
                    </>
                  )}
                </Field>
              </div>

              {/* Postal Code */}
              <div className="relative col-span-3 md:col-span-1">
                <label
                  htmlFor="postal_code"
                  className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                >
                  Postal Code
                </label>
                <Field name="zipcode">
                  {({ input, meta }) => (
                    <>
                      <input
                        {...input}
                        type="text"
                        id="postal_code"
                        placeholder="Postal Code"
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 placeholder:text-sm"
                      />
                    </>
                  )}
                </Field>
              </div>

              {/* Address */}
              <div className="relative col-span-3 md:col-span-3">
                <label
                  htmlFor="address"
                  className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                >
                  Address
                </label>
                <Field name="address">
                  {({ input, meta }) => (
                    <>
                      <input
                        {...input}
                        type="text"
                        id="address"
                        placeholder="Address"
                        onChange={handleChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 placeholder:text-sm"
                      />
                      {meta.touched && meta.error && (
                        <div className="warning-message">{meta.error}</div>
                      )}
                    </>
                  )}
                </Field>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 col-span-1 md:col-span-3">
                {/* Save Button */}
                <div className="md:col-span-2 flex justify-between gap-2">
                  <button
                    type="button"
                    className="flex-1 px-4 py-2 text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-100"
                    onClick={() => setIsEditing(false)}
                  >
                    Discard Changes
                  </button>
                  <button
                    type="submit"
                    className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default ShopSettingFormV2;
