import React from 'react'
// import firstImage from "../../../../assets/registration/firstsignup.svg"
// import illustrationimg from "../../../../assets/registration/Illustration.svg"
// import indicatorimg from "../../../../assets/registration/Indicator.svg"
import "./index.css"
const BASE_URL = process.env.REACT_APP_ROOT_URL;
function FirstStep(props) {
    return (
        <section style={{ background: "#032843" }} >
            <div className='first-container'>
                <div className="first-left">
                    <img src={`${BASE_URL}/assets/registration/webp/firstsignup.webp`} alt='firstimage' className="img-fluid" />
                </div>
                <div className="first-right">
                    <div className="first-right-card">
                        <div className="first-log-img">
                            <img src={`${BASE_URL}/assets/registration/webp/Illustration.webp`} alt="logo" className="img-fluid" />
                        </div>
                        <div className="first-form-main">
                            <p>Gain total control <br></br>
                                of your money</p>
                        </div>
                        <div className="firstform-console">
                            <p>Become your own money manager and <br></br> make every cent count</p>
                        </div>
                        <div className="continue-button ">
                            <button className='continue-form-button' onClick={props.continueSubmit}>Continue</button>
                        </div>
                        <div className="continue-button ">
                            <button className='continue-form-button' onClick={props.gotoLogin}>Skip</button>
                        </div>

                        <div className="first-progressbar-signup">
                            {/* <img src={indicatorimg} alt="logo" className="img-fluid" /> */}
                            <div className="indicator-one"></div>
                            <div className="indicator-two" onClick={props.continueSubmit}></div>
                            <div className="indicator-three" onClick={props.continueSubmit}></div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default FirstStep