import React, { useContext, useState } from "react";
import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from "../../utils/Common";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal } from "../../utils/Modal";
import axios from "axios";

const ChartOfExpenseItem = ({ txnType, loadingCallback ,refetch}) => {
  const { config, state, updatePaymentList } = useContext(AppContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [showViewModal, setShowViewModal] = useState(false);
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF0303",
      cancelButtonColor: "#407BFF",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${process.env.REACT_APP_API_URL}delete-expense-type`,{ id: txnType.id }, {
            headers:{
              authorization: `Bearer ${token}`,
              "content-type":"application/json",
            }
          })
          .then((response) => {
            console.log("delete",response)
            if (response.data.success) {
              Swal.fire("Deleted!", "Your expense item has been deleted.", "success");
              refetch();
            } else {
              Swal.fire("Error!", "Failed to delete expense item.", "error");
            }
          })
          .catch((error) => {
            console.error("Error deleting expense item:", error);
            Swal.fire("Error!", "Failed to delete expense item.", "error");
          });
      }
    });
  };
  return (
    <div>
      <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-xs sm:text-sm md:text-[16px] text-[#000232] grid grid-cols-3 items-start">
        <div>
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="font-nunito ">
              <div className="font-[700]  capitalize text-black">
                {txnType?.expense_name}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">{txnType?.cost_amount || 0} BDT</div>
        <div className="flex gap-2  text-xs justify-end">
          <label
            htmlFor="viewExpense_Modal"
            onClick={() => setShowViewModal((val) => !val)}
            className="border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-[5px] px-[7px] py-1"
          >
            View
          </label>
          <label
            htmlFor="deleteChartOfAcc_modal"
            onClick={() => handleDelete()}
            className="border-[1px] border-solid bg-[#FF0303] border-[#FF0303] text-white rounded-[5px] px-[6px] py-1"
          >
            Delete
          </label>
        </div>
      </div>
      {showViewModal ? (
        <Modal
          formData={txnType}
          title={"Details"}
          formId="viewExpense_Modal"
          isOpen={showViewModal}
          setOpen={setShowViewModal}
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="viewExpense"
          selectType={"chartOfExpense"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ChartOfExpenseItem;
