import React from 'react';
import Breadcrumb from '../Settings_components/Breadcrumb';
import Table from '../Settings_components/Table';
import { discounts } from '../../../data/data';
const Discounts = () => {
    return (
        <div className='rounded-[27px]'  style={{backgroundColor: 'rgba(255, 108, 2, 0.15)' }}>
        <Breadcrumb></Breadcrumb>
       <Table  data={discounts} isDiscount={true}></Table>
        </div>  
    );
};

export default Discounts;