import React, { useRef } from 'react';
//import DueReportHistory from './DueReportHistory';
// import LogoAlignLeft from './bigLogo.png';

// const printableComponent = () => {

// }
const user = JSON.parse(localStorage.getItem('user'));

const ImeiReportPrintPage = ({reports,componentRef,checkedAll,handleCheckboxChange}) => {
  let serialNumber = 1;

  // const today = new Date().toISOString().split('T')[0];
  const today = new Date().toLocaleDateString('en-us',{
    month : 'numeric',
    day : 'numeric',
    year : 'numeric'
  });
  const currentTime = new Date().toLocaleTimeString('en-us',{
    hour : '2-digit',
    minute : '2-digit',
    hour12 : true

  });


  return (
    <div ref={componentRef} className='px-8 mt-2'>

      <div className='flex justify-between mb-2'>
        <p className='font-extrabold'>{currentTime}</p>
        <p className='font-extrabold'>Print</p>
      </div>

      <table class="w-full border border-gray-300">
      <tr>
          <th colspan="3" class="p-2 border-b border-gray-300">
            <h1 class="text-lg font-bold text-center">IMEI/Serial Stock Report</h1>
          </th>
        </tr>
      <tbody>
        <tr>
          <td class="border border-gray-300 p-2">
            <p className='font-normal'>Shop Name: {user?.invoice_settings.shop_name}</p>
            <p className='font-normal'>Email: {user?.email}</p>
            <p className='font-normal'>Address: {user?.address}</p>
          </td>
          <td class="border border-gray-300 p-2">
            <p className='font-normal'>Date: {today}</p>
            <p className='font-normal'>Mobile: {user?.phone}</p>
          </td>
          <td class="border border-gray-300 p-2 text-center">
          <img src={user?.invoice_settings?.shop_logo} alt="Company Logo" class="w-32 h-auto mx-auto"/>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="border border-gray-300 text-center font-semibold p-1">
            From: N/A to N/A
          </td>
        </tr>
      </tbody>

    </table>

      {/* <div className=" grid grid-cols-11 text-left text-[14px] mt-3  font-nunito font-bold text-[#000232]  border-b-[1px] border-[#E7EBF4] border-l border-r bg-blue-100">
                  <div className="text-left border-r-2">Serial</div>
                  <div className="text-left border-r-2">Date</div>
                  <div className="text-left border-r-2">Brand</div>
                  <div className="text-left border-r-2">Product Name</div>
                  <div className="text-left border-r-2">IMEI/Serial</div>
                  <div className="text-left border-r-2">Purchase Inv.</div>
                  <div className="text-left border-r-2">Sale Inv.</div>
                  <div className="text-left border-r-2">Purchase Price</div>
                  <div className="text-left border-r-2">Sale Price</div>
                  <div className="text-left border-r-2">Vendor Name</div>
                  <div className="text-center">Customer Name</div>
      </div> */}
    {
      Object.keys(reports).map((brandName) => {
        return(
          <div key={brandName}>
            <table style={{...styles.table, ...styles.productHeader, ...{ fontWeight: 'normal' }}}>
              <tbody>
                <tr>
                  <td style={styles.productRow}>Product Name: {brandName}</td>
                </tr>
              </tbody>
            </table>
              {/* <div className=" grid grid-cols-11 mt-3 text-left text-[14px] font-nunito font-bold text-[#000232]  border-b-[1px] border-[#E7EBF4] border-l border-r bg-blue-100">
                  <div className="text-left border-r-2">Sl.</div>
                  <div className="text-left border-r-2">Date</div>
                  <div className="text-left border-r-2">Brand</div>
                  <div className="text-left border-r-2">Name</div>
                  <div className="text-left border-r-2">IMEI/Serial</div>
                  <div className="text-center border-r-2">P. Inv.</div>
                  <div className="text-left border-r-2">S. Inv.</div>
                  <div className="text-left border-r-2">P. Price</div>
                  <div className="text-left border-r-2">S. Price</div>
                  <div className="text-left border-r-2">V. Name</div>
                  <div className="text-center">C. Name</div>
              </div> */}


            <table style={{...styles.table, ...{ fontWeight: 'normal' }}}>
            <thead style={{ width: '100%' }}>
                <tr>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>Sl.</th>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>Date</th>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>Brand</th>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>Name</th>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>IMEI/Serial</th>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>P. Inv.</th>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>S. Inv.</th>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>P. Price</th>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>S. Price</th>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>V. Name</th>
                  <th style={{ ...styles.th, ...styles.printTh, ...{ fontWeight: 'normal' } }}>C. Name</th>
                </tr>
              </thead>
              {/* <thead>
                <tr>
                  <th style={{ ...styles.th, ...styles.printTh }}>SL No.</th>
                  <th style={{ ...styles.th, ...styles.printTh }}>Transaction Date</th>
                  <th style={{ ...styles.th, ...styles.printTh }}>IMEI</th>

                  no filter
                  {(!stock_type && !exporter_name && !carrier_name && !customer_name && !product_name) && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Exporter</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Carrier</th>
                    </>
                  )}

                  stock in filter
                  {stock_type === 'stock_in' && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Exporter</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Carrier</th>
                    </>
                  )}

                  stock out filter
                  {(stock_type === 'stock_out' || customer_name) && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Invoice</th>
                      {!customer_name && stock_type === 'stock_out' && (
                        <th style={{ ...styles.th, ...styles.printTh }}>Customer Name</th>
                      )}
                    </>
                  )}

                  product filter
                  {(product_name) && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Brand Name</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Customer Name</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Vendor Name</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Exporter Name</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Carrier Name</th>
                    </>
                  )}

                  exporter/carrier/both filter
                  {(exporter_name || carrier_name) && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                      {!exporter_name && carrier_name && (
                        <th style={{ ...styles.th, ...styles.printTh }}>Exporter Name</th>
                      )}
                      {!carrier_name && exporter_name && (
                        <th style={{ ...styles.th, ...styles.printTh }}>Carrier Name</th>
                      )}
                    </>
                  )}
                </tr>
              </thead> */}
              <tbody>
                {reports[brandName].map((product, index) => (
                  <tr key={index}>
                    <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{serialNumber++}</td>
                    <td style={{...styles.tdTransactionDate, ...{ fontWeight: 'normal' }}}>{product.date}</td>
                    <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{product.brand_name}</td>
                    <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{product.product_name}</td>
                    <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{product.imei}</td>
                    <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{product.purchase_invoice || 'N/A'}</td>
                    <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{product.sale_invoice || 'N/A'}</td>
                    <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{product.purchase_price || 'N/A'} {user?.invoice_settings.currency_info.code}</td>
                    <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{product.sale_price || 'N/A'} {user?.invoice_settings.currency_info.code}</td>
                    <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{product.vendor_name || 'N/A'}</td>
                    <td style={{ ...styles.td, ...{ fontWeight: 'normal' }}}>{product.customer_name || 'N/A'}</td>

                  {/* {(!stock_type && !exporter_name && !carrier_name && !customer_name && !product_name) && (
                    <>
                      <td style={styles.td}>{product.purchase_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                      <td style={styles.td}>{product.sale_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                      <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                      <td style={styles.td}>{product.sale_invoice || 'N/A'}</td>
                      <td style={styles.td}>{product.exporter_name || 'N/A'}</td>
                      <td style={styles.td}>{product.carrier_name || 'N/A'}</td>
                      <td style={styles.td}>{product.customer_name || 'N/A'}</td>
                    </>
                  )} */}

                    {/* {stock_type === 'stock_in' && (
                      <>
                        <td style={styles.td}>{product.purchase_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                        <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                        <td style={styles.td}>{product.exporter_name || 'N/A'}</td>
                        <td style={styles.td}>{product.carrier_name || 'N/A'}</td>
                      </>
                    )} */}

                   {/*  {(stock_type === 'stock_out' || customer_name) && (
                      <>
                        <td style={styles.td}>{product.purchase_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                        <td style={styles.td}>{product.sale_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                        <td style={styles.td}>{product.sale_invoice || 'N/A'}</td>
                        {!customer_name && stock_type === 'stock_out' && (
                          <td style={styles.td}>{product.customer_name || 'N/A'}</td>
                        )}
                      </>
                    )} */}

                  {/* {(product_name) && (
                    <>
                      <td style={styles.td}>{product.brand_name || 'N/A'}</td>
                      <td style={styles.td}>{product.purchase_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                      <td style={styles.td}>{product.sale_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                      <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                      <td style={styles.td}>{product.sale_invoice || 'N/A'}</td>
                      <td style={styles.td}>{product.customer_name || 'N/A'}</td>
                      <td style={styles.td}>{product.vendor_name || 'N/A'}</td>
                      <td style={styles.td}>{product.exporter_name || 'N/A'}</td>
                      <td style={styles.td}>{product.carrier_name || 'N/A'}</td>
                    </>
                  )} */}

                  {/* {(exporter_name || carrier_name) && (
                    <>
                      <td style={styles.td}>{product.purchase_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                      <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                      {!exporter_name && carrier_name && (
                        <td style={styles.td}>{product.exporter_name || 'N/A'}</td>
                      )}
                      {!carrier_name && exporter_name && (
                        <td style={styles.td}>{product.carrier_name || 'N/A'}</td>
                      )}
                    </>
                  )} */}
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        );
      })}

  </div>
  );
};

const styles = {
  titletext: {
    border: '1px solid black',
    textAlign: 'center',
    margin: '0',
    padding: '2px',
    fontSize: '12px',
    width: '100%'
  },
  productHeader: {
    marginTop: '30px'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  column: {
    border: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'top',
    padding: '5px',
    fontSize: '12px',
  },
  logo: {
    width: '100px', // Adjust size as needed
    height: 'auto',
  },
  dateRange: {
    textAlign: 'center',
    padding: '5px',
    fontSize: '12px',
    border: '1px solid black',
  },
  productRow: {
    border: '1px solid black',
    textAlign: 'center',
    padding: '5px',
    fontSize: '14px',
    width: '100%',
  },
  th: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '14px',
  },
  td: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
  },
  tdTransactionDate: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
};
/* const styles = {
  titletext: {
    border: '1px solid black',
    textAlign: 'center',
    margin: '0',
    padding: '2px',
    fontSize: '12px',
    width: '100%'
  },

  productHeader: {
    marginTop: '30px'
  },

  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  column: {
    border: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'top',
    padding: '5px',
    fontSize: '12px',
  },
  logo: {
    width: '100px', // Adjust size as needed
    height: 'auto',
  },
  dateRange: {
    textAlign: 'center',
    padding: '5px',
    fontSize: '12px',
    border: '1px solid black',
  },
  productRow: {
    border: '1px solid black',
    textAlign: 'center',
    padding: '5px',
    fontSize: '18px',
    width: '100%',
  },
  th: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
    backgroundColor: 'white',

  },
  printTh: {
    backgroundColor: 'white',
    color: 'black',
    WebkitPrintColorAdjust: 'exact',
  },
  td: {
    border: '1px solid black',
    padding: '2px',
    textAlign: 'center',
    fontSize: '10px',
    width: '80px', // Fixed width (adjust as needed)
    wordWrap: 'break-word', // Allows content to wrap
    whiteSpace: 'normal',   // Enables wrapping to next line
  },
  tdTransactionDate: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },

};
 */
export default ImeiReportPrintPage;