import React, { useEffect, useState } from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';
import { formatTimeStr } from 'antd/es/statistic/utils';

const CategoryStockHistoryItem = (props) => {
  const { CStockData,
    tabActive,
    shouldRenderPagination,
    currentPage,
    onPageChange,
    loading,
    selectAllChecked,
    allFetchedData,
    onSelect,
    index
} = props;

  const [isChecked, setIsChecked] = useState(false);

  // useEffect(() => {
  //   setIsChecked(selectAllChecked);
  // }, [selectAllChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelect(index, CStockData);
  };

  return (
    <>
      {tabActive &&
         <div className="grid text-left text-[14px] mt-3 py-[8px] font-nunito font-bold text-[#000232] grid-cols-7 border-b-[1px] border-[#E7EBF4]">

         <div className='text-left'>
           <input
             type="checkbox"
             checked={CStockData?.completed}
             onChange={handleCheckboxChange}
           />
         </div>

          <div className="text-[#000] font-[600]">{CStockData?.name}
          </div>
          {/* category name */}
          <div className="text-[#000] font-[500] text-center">
            {CStockData?.product_stock_unit}
          </div>

          {/* product name */}
          <div className="text-[#000] font-[500] text-center">
            {CStockData?.total_sales_qty}
          </div>

          {/* customer */}
          <div className="text-[#000] font-[500] text-center">
         {CStockData?.total_purchase_qty}
          </div>
          {/* Due */}
          <div className=" text-[#000] font-[500] text-center ">{CStockData?.due_amount}</div>
          {/* Total */}
          <div className=" text-[#000] font-[500] text-right">{CStockData?.total_sales_amount}</div>
        </div>}
    </>
  );
};
CategoryStockHistoryItem.defaultProps = { className: null };

export default CategoryStockHistoryItem;