import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment, reset } from '../app/counter';

const Playground = () => {
  const counter = useSelector((state) => state.counter.value)
  console.log(counter)
  const dispatch = useDispatch()
  return (
    <div>
      <h1>{counter}</h1>
      <button onClick={()=>dispatch(increment())}>Increment</button>
      <br />
      <button onClick={()=>dispatch(decrement())}>Decrement</button>
      <br />
      <button onClick={()=>dispatch(reset())}>Reset</button>
    </div>
  );
};

export default Playground;