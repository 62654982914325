// import React from 'react';

// const TransactionHistoryItem4Cols = () => {
//   return (
//     <div>

//     </div>
//   );
// };

// export default TransactionHistoryItem4Cols;


import React, { useEffect, useState } from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const TransactionHistoryItem4Cols = (props) => {
  const {
    transactionData,
    paymentList,
    paymentAccountList,
    activeTab,
    selectAllChecked,
    setAllFetchedData,
    allFetchedData,
    onSelect,
    index
} = props;

  const [isChecked, setIsChecked] = useState(false);


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelect(index, transactionData);
  };

  // const paymentData = paymentList.data ? paymentList.data : paymentList;
  // const paymentAccountData = paymentAccountList.data ? paymentAccountList.data : paymentAccountList;

  // const createdDate = new Date(transactionData?.date);

  return (
    <>
    {activeTab &&
      <div className="border-b-[1px] text-sm border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-5 items-start">

        <div className='text-left ms-5'>
          <input
            type="checkbox"
            checked={transactionData.completed}
            onChange={handleCheckboxChange}
          />
        </div>

        {/* transaction status */}
        <div className="text-[#000] font-semibold text-center">
          <p className="text-[#000] font-[500] text-md">{transactionData?.transaction_history?.transaction_status}</p>
        </div>

        {/* transaction type  */}
        <div className="text-[#000] font-[500] text-center">
          <p className="text-[#000] font-[500] text-md">{transactionData?.type}</p>
        </div>

        {/* transaction id */}
        <div className=" text-[#000] font-[500] text-center ">{transactionData?.invoice}</div>


        {/* <div className="text-[#000] font-[500] text-center">
          {transactionData.payment_method && transactionData.payment_method.map((item, index) => (
            (item.payment_type_category_id && item.payment_type_id) ? (

              paymentData.map((payment) => (
                (payment.id === item.payment_type_id) ? (
                  payment?.payment_type_category.map((account)=>(
                    ( account.id==item.payment_type_category_id) &&
                      (
                        <div className='w-20 mx-auto'>
                          <p key={index} className="text-[#000] font-[500]  text-md">
                            {payment.type_name}
                          </p>
                          { String(payment?.type_name).toLowerCase() !='cash' &&
                          <p className=''>{" "}{account.payment_category_name}{" "}{account.account_number}{" "}
                          <span className='font-bold text-emerald-600'>{item.payment_amount}{"BDT"}</span>
                          </p> }
                          <hr />
                        </div>
                      )
                      // : null
                  ))

                ) : null
              ))
            ) : (
              <div className='w-20 mx-auto'>
                <p key={index} className="text-[#000] whitespace-nowrap font-[500] text-md">{"Cash-"}
                { item.payment_amount==null? 0 : <span className='font-bold text-emerald-600'>{item.payment_amount}{"BDT"}</span>}
                </p>
              </div>
            )
          ))}
        </div> */}

        {/* transactoin amount */}
        <div className=" text-[#000]  font-[500] text-center">{Number.parseFloat(transactionData?.total_paid).toFixed(2)}</div>
      </div>}
    </>
  );
};
TransactionHistoryItem4Cols.defaultProps = { className: null };
export default TransactionHistoryItem4Cols;