import React, { useContext, useEffect, useState } from 'react';
import ContentLayout from '../../layout/ContentLayout/index.jsx';
import { AppContext } from '../../context/context.js';
import { get } from '../../services/apiService.js';
import Pagination from '../../utils/pagination.js'; // Adjust the path accordingly
import {RiMoneyDollarCircleLine} from "react-icons/ri";
import { timeDiff,formatPrice } from '../../utils/Common.js';
import { AiOutlineSearch } from 'react-icons/ai';
import { post } from '../../services/apiService.js';
import { Link } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import BarcodeItem from '../../components/BarcodeItem/BarcodeItem';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;
const BarcodeListPage = () => {
    const [barcodeData, setBarcodeData] = useState([]);
    const { state, isLoading } = useContext(AppContext);
    const { invoiceSetting } = state;
    const token = localStorage.getItem('token');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const perPage = 20;
    useEffect(() => {
        getProductList(currentPage,searchKeyword);
    }, [currentPage,searchKeyword]);
    const getProductList = (page,searchKeyword) => {
        if (searchKeyword !== "") {
            const reqData = { keyword: searchKeyword };
            post(`search-product-v1?page=${page}&limit=${perPage}`, reqData, token)
            .then((response) => {

                if(response.status === 200)
                {
                    const listData = response.data.data;


                    const transformedInvoiceArray = listData.map(item => ({
                        id: item.id,
                        icon: <RiMoneyDollarCircleLine/>,
                        name: item.name,
                        image_path: item.image_path ? item.image_path : shop_logo,
                        brand_name: item?.brands?.name ? item.brands.name : '',
                        category_name: item?.category?.name ? item.category.name : '',
                        price: invoiceSetting?.currency_info?.code ? formatPrice(item.retails_price)+' '+invoiceSetting.currency_info.code : formatPrice(item.retails_price)+' BDT',
                        time: timeDiff(new Date(item.updated_at)),
                        colors: "#00A3EE"
                    }));
                    setBarcodeData(transformedInvoiceArray)
                    setPagination(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
            get(`product?page=${page}&limit=${perPage}`, token)
                .then((response) => {
                    console.log(response.data.data);
                    if (response.data.data) {
                        const listData = response.data.data;

                        const transformedInvoiceArray = listData.map(item => ({
                            id: item.id,
                            icon: <RiMoneyDollarCircleLine/>,
                            name: item.name,
                            image_path: item.image_path ? item.image_path : shop_logo,
                            brand_name: item?.brands?.name ? item.brands.name : '',
                            category_name: item?.category?.name ? item.category.name : '',
                            price: invoiceSetting?.currency_info?.code ? formatPrice(item.retails_price)+' '+invoiceSetting.currency_info.code : formatPrice(item.retails_price)+' BDT',
                            time: timeDiff(new Date(item.updated_at)),
                            colors: "#00A3EE"
                        }));
                        setBarcodeData(transformedInvoiceArray);
                        setPagination(response.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const shouldRenderPagination = pagination?.total > perPage;

    return (
        <>
            <ContentLayout>
                <div className="main-content">
                    <div className="Barcode-container flex flex-col w-full">
                        <div className="title-link">
                            <h1>Barcodes</h1>
                        </div>
                        <div className="w-[250px] md:w-[400px] relative mt-4 ml-0 mb-4">
                            <input
                                autoFocus
                                type="text"
                                className="bg-[#E5EDFF] rounded-[11px] w-full pt-[17px] pr-[45px] pl-[21px] pb-[15px] placeholder:text-[#91919F]"
                                placeholder="Search product"
                                onChange={(e) => {
                                    setSearchKeyword(e.target.value);
                                }}
                            />
                            <div className="absolute top-5 right-[17px]">
                                <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                            </div>
                        </div>
                        <div className="productsList-container">
                            {barcodeData.map((barcode) => {
                                return (
                                    <BarcodeItem key={barcode.id} barcode={barcode} />
                                );
                            })}
                            <div className="hrtable"></div>
                            {/* Pagination component */}
                            {shouldRenderPagination && (
                            // {invoiceData.length > 2 && (
                                <Pagination
                                    // currentPage={invoiceList.current_page}
                                    lastPage={pagination.last_page}
                                    currentPage={currentPage}
                                    //lastPage={10}
                                    onPageChange={onPageChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </ContentLayout>
        </>
    );
};

export default BarcodeListPage;
