import React, { useEffect, useState } from "react";
import calculatorIcon from '../../../assets/images/calculator.svg'
// import ShowHistory from "./ShowHistory";
import SaveHistory from "./SaveHistory";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import axios from "axios";
import pow from '../../../assets/images/pow.png'
import sqrt from '../../../assets/images/sqrt.png'

const Calculator = ({isCalculatorOpen,setCalculatorOpen}) => {
  const btnValues = [
    ["←", "CE", "C", "+/-"],
    ["7", "8", "9", "/"],
    ["4", "5", "6", "*"],
    ["1", "2", "3", "-"],
    ["0", "00", ".", "+"],
  ];
  const btnValues2 = ["√n", "X^n", "%", "="];
  const [activeBtn, setActiveBtn] = useState("");
  const [expression, setExpression] = useState("0");
  const [prevExpression, setPrevExpression] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [history, setHistory] = useState([]);
  const token = localStorage.getItem("token");
  const [percentage, setPercentage] = useState({
    total: "",
    operator: "",
    percent: "",
  });

  const handleCalculatorOpen = () =>{
    setCalculatorOpen(!isCalculatorOpen)
  }

  const handleKeyPress = (event) => {
    const key = event.key;
    console.log(key);
    if (/[0-9+\-*/.=%]|Backspace|Escape|Enter/.test(key) && (!showModal&&!isMenuVisible)) {
      setActiveBtn(key);
      setTimeout(() => {
        setActiveBtn("");
      }, 250);
      handleInput(key);
    }
  };

  const handleInput = (input) => {
    if (expression.length > 16) {
      if (input == "Backspace" || input == "X")
        setExpression(expression.slice(0, -1));
      else if(input === "Enter" || input === "="){
        enterClickHandler(input)
      }
    }
    // else if (input == "0" && expression.slice(-1) == "/") {
    //   alert("Can't divide with 0");
    // }
    else if (
      input == "+" ||
      input == "-" ||
      input == "/" ||
      input == "*" ||
      input == "%" ||
      input == "√n" ||
      input == "X^n" ||
      input == "+/-"
    ) {
      operatorClickHandler(input);
    } else if (input === "Escape" || input == "C") {
      setExpression("0");
      setPrevExpression("");
    } else if (input == "CE") {
      let cnt = 0;
      for (let i = expression.length - 1; i >= 0; i--) {
        if (/[+/*-]/.test(expression[i])) {
          break;
        }
        cnt++;
      }
      if (cnt == expression.length) setExpression("0");
      else if (/[+/*-]/.test(expression.slice(-1))) {
        setExpression(expression.slice(0, -1));
        setPrevExpression(expression);
      } else {
        setExpression(expression.slice(0, -1 * cnt));
      }
    } else if (input == ".") {
      let char = expression.slice(-1);
      if (!/[+/*-]/.test(char)) {
        let flag = "";

        for (let i = expression.length - 1; i >= 0; i--) {
          if (/[+/*-]/.test(expression[i])) {
            break;
          }
          flag += expression[i];
        }
        if (!flag.includes(".")) {
          setExpression(expression + input);
        }
      }
    } else if (input == "Backspace" || input == "←") {
      if (expression.length == 1) {
        setExpression("0");
      } else setExpression(expression.slice(0, -1));
    } else if (input == "<-") {
    } else if (input === "Enter" || input === "=") {
      enterClickHandler(input)
    }
    else {
      if (prevExpression.length > 0 && prevExpression.slice(-1) == "=") {
        setExpression("");
        setPrevExpression("");
        setExpression(input);
      } else if (input == "00") {
        let ch = expression.slice(-1);
        expression.length == 1 && ch == "0"
          ? setExpression("0")
          : expression.length > 1 && /[+/%*-]/.test(ch)
          ? setExpression(expression)
          : setExpression(expression + input);
      } else {
        let ch = expression.slice(-1);
        expression.length == 1 && ch == "0"
          ? setExpression(input)
          : expression.length >= 2 &&
            ch == "0" &&
            /[+/*-]/.test(expression.slice(-2))
          ? setExpression(expression.slice(0, -1) + input)
          : setExpression(expression + input);
      }
    }
  };

  const enterClickHandler = (input) =>{
    // console.log("express", isFinite(eval(expression)));
    // if (isNaN(eval(expression)) || !isFinite(eval(expression))) {
    //   alert("Invalid arithmetic operation: Division by zero or NaN");
    //   setExpression( '0')
    //   setPrevExpression('')
    //   return;
    // }
    let char = expression.slice(-1);
    if (/[+-/*]/.test(char)) {
      setExpression(String(Number(eval(expression.slice(0, -1)).toFixed(3))) );
      setPrevExpression(expression.slice(0, -1) + "=");
    } else if (char == "%") {
      let divide = percentage.percent / 100;
      let final = divide * percentage.total;
      let total =
        percentage.operator == "+"
          ? Number(percentage.total) + Number(final)
          : percentage.operator == "-"
          ? Number(percentage.total) - Number(final)
          : percentage.operator == "*"
          ? final
          : Number(percentage.total) / Number(final);
      setExpression(String(Number(eval(total).toFixed(3))));
      if (percentage.operator == "*")
        setPrevExpression(
          percentage.total +
            " " +
            percentage.operator +
            " " +
            percentage.percent +
            "% ="
      );
      else {
        setPrevExpression(
          percentage.total +
            " " +
            percentage.operator +
            " " +
            Number(eval(final).toFixed(2)) +
            " ="
        );
      }

    } else if (expression.length > 0) {
      setExpression( String(Number(eval(expression).toFixed(3))) );
      setPrevExpression(expression + "=");
    }
  }

  const operatorClickHandler = async(input) => {
    if (input == "+/-") {
      if (/[0-9]/.test(expression.slice(-1))) {
        let current = "",
          cnt = expression.length - 1;
        for (let i = expression.length - 1; i >= 0; i--) {
          if (/[+/*-]/.test(expression[i])) break;
          current = expression[i] + current;
          cnt--;
        }
        if (expression[0] == "-") {
          setExpression(expression.substring(1, expression.length));
        } else if (cnt < 0) {
          setExpression("-" + expression);
        } else {
          let curr =
            expression.substring(0, cnt) +
            (expression[cnt] === "+" ? "-" : "+") +
            expression.substring(cnt + 1);
          setExpression(curr);
        }
      }
    }
    else if (input == "√n") {
      console.log("root",expression.slice(-1));
      let currentVal = /[+/*-]/.test(expression.slice(-1))
        ? expression.slice(0, -1)
        : expression;
      let sqrtVal = Math.sqrt(eval(currentVal))
      if( eval(currentVal)>0){
        let value=  String(sqrtVal).includes('.') ? Number(sqrtVal).toFixed(3): sqrtVal
        setExpression(  String(value) );
        setPrevExpression(eval(currentVal) + "=");
      }
      else{setExpression('0')}
    }
    else if( /[+/*-]/.test(input)&&expression.slice(-1)=='%'){
      let divide = percentage.percent / 100;
      let final = divide * percentage.total;
      let total =
        percentage.operator == "+"
          ? Number(percentage.total) + Number(final)
          : percentage.operator == "-"
          ? Number(percentage.total) - Number(final)
          : percentage.operator == "*"
          ? final
          : Number(percentage.total) / Number(final);
      setExpression(String(Number(eval(total).toFixed(3))) + input);
      if (percentage.operator == "*"){
        setPrevExpression(
          percentage.total +
            " " +
            percentage.operator +
            " " +
            percentage.percent +
            "% " + input
          );
      }
      else {
        setPrevExpression(
          percentage.total +
            " " +
            percentage.operator +
            " " +
            Number(eval(final).toFixed(2)) +
            " " + input
        );
      }
    }
    else if (/[+/*-]/.test(expression.slice(-1))) {
      setPrevExpression(expression.slice(0, -1) + input);
      setExpression(expression.slice(0, -1) + input);
    } else if (input == "%") {
      if (/[+/*-]/.test(expression)) {
        let char = expression.slice(-1);
        if (/[+/*%-]/.test(char)) {
          // setExpression( eval(expression.slice(0,-1)).toString());
          // setPrevExpression( expression.slice(0,-1) + "="  );
          // console.log("value: ", expression);
        } else {
          let currentVal = "",
            cnt = 0,
            ope = "";
          for (let i = expression.length - 1; i >= 0; i--) {
            if (/[+/*-]/.test(expression[i])) {
              cnt++;
              ope += expression[i];
              break;
            }
            currentVal = expression[i] + currentVal;
            cnt++;
          }
          let totalval = eval(expression.slice(0, -1 * cnt)).toString();
          setPercentage({
            total: totalval,
            percent: currentVal,
            operator: ope,
          });
          setExpression(totalval + ope + currentVal + input);
          setPrevExpression(expression + input);
        }
      }
    }  else if (input == "X^n") {
    } else {
      let val = expression.length == 0 ? 0 : expression;
      if (val == 0) {
        setExpression("0");
      }
      setPrevExpression(val + input);
      setExpression((e) => e + input);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
        window.removeEventListener('keydown', handleKeyPress);
    };
  }, [expression, showModal, isMenuVisible]);

  const handleChange = (event) => {
    setExpression(event.target.value);
  };

  const handleSaveBtn = async() => {
    handleInput('=')
    await document.getElementById("my_modal_3").showModal();
    setShowModal(true);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}calculator-history`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setHistory(response.data.data)
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.setItem("token", "");
          localStorage.setItem("token", "");
          localStorage.setItem("isPinVerify", false);
        }
      });
  }, []);


  const handleHistoryBtn = async () => {
    // if (!isMenuVisible) setShowModal(true);
    // else setShowModal(false);
    setIsMenuVisible(!isMenuVisible);
  };

  const handleHistoryId = (total) =>{
    setExpression(String(total));
    setPrevExpression('')
    handleHistoryBtn()
  }


  return (
    <div className="flex justify-center  ">
      <div className=" border-[2px] p-[16px] shadow-xl rounded-2xl bg-white">
        <div className="flex justify-between">
          <div className="flex justify-left items-center">
            <img className="w-[20px] h-[20px]" src={calculatorIcon}></img>
            <h1 className="text-center flex justify-center font-semibold ms-1">
              Calculator
            </h1>
          </div>
          <div className="">
            <button onClick={handleCalculatorOpen} className="outline-none text-white font-semibold bg-[#FF0303] hover:bg-[#FF0303] btn-circle btn-xs">
              X
            </button>
          </div>
        </div>

        <div className="grid justify-items-center">
          {/* Textarea show field  */}
          <div className="w-[340px]">
            <div className="mt-[12px] mb-[4px] px-[8px] py-[16px] bg-[#3D3F4A] rounded-3xl">
              <input
                readOnly
                value={prevExpression}
                type="text"
                className="opacity-70 outline input focus:border-none outline-none focus:outline-offset-0 focus:outline-none py-[4px] text-right w-full  text-white  bg-[#3D3F4A] max-w-xs"
              ></input>
              <input
                readOnly
                ref={(inp) => {
                if (inp && !isMenuVisible && !showModal) {
                    inp.focus();
                  }
                }}

                // value={ expression.includes('.')? Number(expression).toFixed(3): expression}
                value={ expression=="Infinity"? setExpression("0") : expression }
                onClick={handleChange}
                type="text"
                className="outline input focus:border-none outline-none focus:outline-offset-0 focus:outline-none outline-offset-0 text-right items-right text-3xl text-white bg-[#3D3F4A] w-full max-w-xs "
              ></input>
            </div>
          </div>

          {/* save and history button */}
          <div className="w-[340px]">
            <div className="flex m-[3px] gap-2 justify-between items-center">
              <div className="w-2/3">
                {/* <button className="btn bg-[#407BFF] text-white w-full rounded-2xl">History</button> */}
                {/* <ShowHistory  */}
                  {/* setShowModal={setShowModal} */}
                  {/* total={expression} */}
                {/* ></ShowHistory> */}

                <Menu as="div" className=" text-center">
                  <div className=" ">
                    <Menu.Button
                      onClick={handleHistoryBtn}
                      className="border-none  flex justify-between items-center px-5 w-full  h-11  rounded-2xl  text-md font-medium text-white bg-[#5A5D6C] hover:bg-[#5A5D6C] focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
                    >
                      <h1 className="">History</h1>
                      {/* <ChevronDownIcon
                        className="-mr-1 ml-2 h-8 w-8 text-white"
                        aria-hidden="true"
                      /> */}
                      { isMenuVisible ?
                      <IoIosArrowUp className="font-bold text-xl" /> :
                      <IoIosArrowDown className=" font-bold text-xl"/>
                      }
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    show={isMenuVisible}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="overflow-y-auto h-72 absolute mt-2 z-10 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="px-[8px] py-[8px">
                        {/* Search input */}
                        <input
                          type="text"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={(e) =>setSearchQuery(e.target.value)}
                          className="focus:outline-none input w-full px-2 py-1 border border-gray-300 rounded-md mb-2"
                        />
                        {/* History items */}
                        {history
                          .filter((item) => {
                            return searchQuery.toLocaleLowerCase() === ""
                              ? item
                              : item.name.toLocaleLowerCase().includes(searchQuery);
                          }).reverse()
                          .map((cal, index) => (
                            <Menu.Item key={index}>
                              {({ active }) => (
                                <h1
                                  onClick={()=>handleHistoryId(cal.total)}
                                  key={index}
                                  className={`${
                                    active
                                      ? "bg-violet-500 text-white"
                                      : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                >
                                  <span className="font-bold">{cal.name}</span>:{" "}
                                  {cal.total} BDT
                                </h1>
                              )}
                              {/* {
                              <h1>className="font-bold"{cal.name}: {cal.taka} BDT</h1>
                            } <span className="font-bold">{cal.name}</span>: {cal.taka} BDT */}
                            </Menu.Item>
                          ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

              </div>
              <div className="w-1/3 relative">
                <button
                  onClick={handleSaveBtn}
                  className="border-none outline-none btn bg-[#5A5D6C] hover:bg-[#5A5D6C] text-white w-full rounded-2xl"
                >
                  Save
                </button>
                <SaveHistory
                  setShowModal={setShowModal}
                  total={expression}
                  setHistory={setHistory}
                  history={history}
                  setExpression={setExpression}
                  setPrevExpression={setPrevExpression}
                ></SaveHistory>
              </div>
            </div>
          </div>

          {/* numeric key button */}
          <div className="flex w-[340px]">
            <div className="">
              {btnValues.flat().map((btn, index) => {
                if (
                  btn == "1" ||
                  btn == "2" ||
                  btn == "3" ||
                  btn == "4" ||
                  btn == "5" ||
                  btn == "6" ||
                  btn == "5" ||
                  btn == "6" ||
                  btn == "7" ||
                  btn == "8" ||
                  btn == "9" ||
                  btn == "0" ||
                  btn == "." ||
                  btn == "00"
                ) {
                  return (
                    <input
                      readOnly
                      value={btn}
                      onClick={() => handleInput(btn)}
                      className={`${
                        activeBtn == btn ? "scale-90" : ""
                      } border-none hover:border-none btn outline-0 focus:outline-0 mx-[3.5px] my-[1.5px] rounded-xl text-lg w-[60px] text-white bg-[#5A5D6C] hover:bg-[#5A5D6C]`}
                    >
                      {/* {btn} */}
                    </input>
                  );
                } else if (btn == "←") {
                  return (
                    <input
                      readOnly
                      value={"←"}
                      onClick={() => handleInput(btn)}
                      className={`${
                        activeBtn == "←"||activeBtn=='Backspace' ? "scale-90" : ""
                      } border-none mt-[6px] outline outline-0 focus:outline-0 mx-[3.5px] my-[1.5px] rounded-xl text-xl btn w-[60px] text-white bg-[#407BFF] hover:bg-[#407BFF] `}
                    ></input>
                  );
                } else {
                  return (
                    <input
                      readOnly
                      value={btn}
                      onClick={() => handleInput(btn)}
                      className={`${
                        activeBtn == btn ? "scale-90" : ""
                      } border-none outline outline-0 focus:outline-0 mx-[3.5px] my-[1.5px] rounded-xl text-lg btn w-[60px] text-white bg-[#407BFF] hover:bg-[#407BFF]`}
                    >
                      {/* {btn} */}
                    </input>
                  );
                }
              })}
            </div>
            <div className="me-[7px] mt-[4px]">
              {btnValues2.map((btn, i) => {
                if (btn == "=") {
                  return (
                    <input
                      readOnly
                      value={btn}
                      onClick={() => handleInput(btn)}
                      className={`${
                        activeBtn == "=" || activeBtn == "Enter"
                          ? "scale-90"
                          : ""
                      } border-none outline outline-0 focus:outline-0 my-[1.5px] text-2xl bg-[#5A5D6C] hover:bg-[#5A5D6C] btn w-[60px] h-[102px] text-white`}
                    >
                      {/* {btn}  */}
                    </input>
                  );
                }
                else if(btn=='√n'){
                  return (
                    <button
                      readOnly
                      // value={btn}
                      onClick={() => handleInput(btn)}
                      className={`${
                        activeBtn == btn ? "scale-90" : ""
                      }  outline outline-0 focus:outline-0 border-none my-[1.5px] text-md btn w-[60px] text-white bg-[#407BFF] hover:bg-[#407BFF]`}
                    >
                      <img src={sqrt} className="w-8" ></img>
                    </button>
                  );
                }else if(btn=='X^n'){
                  return (
                    <button
                      readOnly
                      // value={btn}
                      onClick={() => handleInput(btn)}
                      className={`${
                        activeBtn == btn ? "scale-90" : ""
                      }  outline outline-0 focus:outline-0 border-none my-[1.5px] text-md btn w-[60px] text-white bg-[#407BFF] hover:bg-[#407BFF]`}
                    >
                      <img src={pow} className="w-[16px]" ></img>
                    </button>
                  );
                } else {
                  return (
                    <button
                      readOnly
                      // value={btn}
                      onClick={() => handleInput(btn)}
                      className={`${
                        activeBtn == btn ? "scale-90" : ""
                      }  outline outline-0 focus:outline-0 border-none my-[2px] text-md btn w-[60px] text-white bg-[#407BFF] hover:bg-[#407BFF]`}
                    >
                      {btn}
                    </button>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
