import React, { useState } from 'react';
import "./CategoryStockGraph.css";

import ReactApexChart from 'react-apexcharts';

const CategoryStockGraph = ({ revenueProductData, allFetchedData}) => {


  return (
    <>
      <div className="chart-info w-full">
        <div className="chart-info-title">
          <p className='text-[24px]'>Category Sale Chart</p>
        </div>
      </div>
      <div id="category-chart">
        <ReactApexChart options={revenueProductData.options} series={revenueProductData.series} type="area" height={300} />
      </div>
    </>
  );
};

export default CategoryStockGraph;
