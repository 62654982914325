import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlinePicLeft } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import { MdModeEdit } from "react-icons/md";
import Resizer from "react-image-file-resizer";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { useFlashMessage } from '../../../context/FlashMessageContext';
import { AppContext } from '../../../context/context.js';
import ImageUploader2 from '../../../forms/ImageUploader2';
import { get } from '../../../services/apiService';
import "./TableRow.css"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  uploadFile,
  validateData
} from '../../../utils/Common';
import SelectBox from '../../../forms/Select';
import Swal from "sweetalert2";
import { FlashMessage } from "../../../utils/FlashMessage.js";

const TableRow = ({ items, type, isDiscount,getCategorys,getSingleCategoryList,getSubCategorys,getBrands,getUnit,getInvoicelist }) => {
  const navigate = useNavigate();
  const { getFlashMessageStyle, setSuccessFlashMessage,flashMessage,setErrorFlashMessage } = useFlashMessage();
  const [isValid, setIsValid] = useState(true);
  const {
      state
  } = useContext(AppContext);
  const pathname = window.location.pathname;
  const [modalOne, setModalOne] = useState(true);
  const [modalTwo, setModalTwo] = useState(false);
  const [modalThree, setModalThree] = useState(false);
 // const [modalFour, setModalFour] = useState(false);
  const [modalFour, setModalFour] = useState(false);
  const [modalFive, setModalFive] = useState(false);


  const token = localStorage.getItem("token");
  const [catId, setCatId] = useState(0);
  const [subCatId, setSubCatId] = useState(0);
  const [catName, setCatName] = useState("");
  const [catDescription, setCatDescription] = useState("");

  const [category, setCategory] = useState(state?.categoryList);
  const [categoryId, setCategoryId] = useState(0);

  const [allItems, setAllItems] = useState([]);
  const [row, setRow] = useState("");
  const [showModal, setShowModal] = React.useState(false);
  //const handleOpen = () => setShowModal(!showModal);

  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [loader, setLoader] = useState(true);
  const handleOpen = () => {
    setShowModal(!showModal);
    setAllItems([]);
    resetFormError();
    if (!showModal) {
      setName("");
      setCategoryId("");
      setFile("");
      setBrandfile("");
      setUploadDetails([]);
    }
  };

  const getCategory = () => {
    get("get-all-category", token)
      .then((response) => {
        if (response.success) {
          setCategory(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const [show, setShow] = useState(false);

  const parts = pathname.split("/");
  let firstPart, secondPart;
    let checker;
  if (parts[2]) {
    [firstPart, secondPart] = parts[2].split("-");
    checker=firstPart;
  } else {
    console.log("Invalid pathname structure");
    checker = 'invoice';
  }
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
      setLoading(loading);
  };
  useEffect(() => {


    setModalOne(true);
    setModalTwo(false);
    setModalThree(false);
    setModalFour(false);
    setModalFive(false)
    if (checker === "category") {
      setModalOne(true);
    } else if (checker === "sub") {
      if (!state.categoryList.length) {
        getCategory();
      }
      setModalOne(false);
      setModalTwo(true);
    } else if (checker === "brands") {
      setModalOne(false);
      setModalThree(true);
    }
    else if (checker === "units") {
      setModalOne(false);
      setModalFour(true);
    }
    else if (checker === 'discounts') {
      setModalOne(false);
      setModalFive(true);
    }
    else if (checker === 'invoice') {
      // setModalInvoice(true);
      // getCategory();
      // getCurrency();
      setModalOne(false);
    } else {
      setModalOne(false);
      setModalTwo(false);
      setModalThree(false);
      setModalFour(false);
      setModalFive(false);
    }
  }, [firstPart]);
  const navigateToBranch = (id) => () => {
    navigate(`/settings/branch-settings/${id}`);
  }
  // React.useCallback.
  const updateCategory = React.useCallback((item) => () => {
    setShowModal(true);
    setAllItems([]);
    setAllItems(item);

    setCatId(item.id);
    setCatName(item.name);
    setImage(item.image_path);
    setFile(item.image_path);
    setCatDescription(item.description);

  }, []);

  // React.useCallback.
  const updateSubCategory = React.useCallback((item) => () => {

    setShowModal(true);
    setAllItems([]);
    setAllItems(item);

    setSubCatId(item.id);
    setCategoryId(item.category_id);
    setCatName(item.name);
    setCatDescription(item.description);
    console.log(item);
    //console.log(categoryId);

}, []);




function handleSubmitCategory(e) {
  e.preventDefault();
  if (validateForm("Category")) {
    loadingCallback(true)
    axios
    .post(
        `${process.env.REACT_APP_API_URL}update-category`,
        { categoryId: catId, name: catName, description: catDescription },
        {
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    )
    .then((response) => {
        loadingCallback(false)
        //console.log(response.data.success);
        if(response.data.success)
        {

          setShowModal(false);
          getCategorys();
          getSingleCategoryList();
          //setLoader(false)

          // window.location.reload();
          //setName(" ");
          //setDescription(" ");
          //navigate('/settings/category');
        }
    })
    .catch((error) => {
        console.log(error);
        loadingCallback(false)
    });
  }
}

function handleSubmitSubCategory(e) {
  e.preventDefault();

  if (validateForm("Sub Category")) {
    axios
    .post(
        `${process.env.REACT_APP_API_URL}update-sub-category`,
        { subCategoryId:subCatId, category_id: categoryId, name: catName, description: catDescription },
        {
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    )
    .then((response) => {
        //console.log(response.data.success);
        if(response.data.success)
        {

          setShowModal(false);
          getSubCategorys();
          // window.location.reload();
            //setName(" ");
            //setDescription(" ");
            //navigate('/settings/category');

        }else{
          setLoader(true)      }

    })
    .catch((error) => {
        console.log(error);
    });
  }
}
const showModalWarning = (msg, icon='warning') => {
  Swal.fire({
    title: msg,
    text: "",
    icon: icon,
  });
};

  const handleDeleteUnit = (id) =>{
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {

        loadingCallback(true)
        axios.post(
          `${process.env.REACT_APP_API_URL}delete-unit`,{
            unitId: id
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
            loadingCallback(false)
            if(response.data.success)
            {
              showModalWarning("Unit deleted successfully!", 'success')
              getUnit();
            }
            else if(!response.data.success) {
              showModalWarning(response.data.message)
            }
            else {
              showModalWarning("Something went wrong!")
            }
        })
        .catch((error) => {
          loadingCallback(false)
          console.log(error);
        });
      }
    });
  }

  const handleDeleteBrand = (id) =>{
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {

        loadingCallback(true)
        axios.post(
          `${process.env.REACT_APP_API_URL}delete-brand`,{
            brandId: id
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
            loadingCallback(false)
            if(response.data.success)
            {
              showModalWarning("Brand deleted successfully!", 'success')
              getBrands();
            }
            else if(!response.data.success) {
              showModalWarning(response.data.message)
            }
            else {
              showModalWarning("Something went wrong!")
            }
        })
        .catch((error) => {
          loadingCallback(false)
          console.log(error);
        });
      }
    });
  }
  const handleDeleteCatagory = (id) =>{
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("Catagory", id);
        loadingCallback(true)
        axios.post(
          `${process.env.REACT_APP_API_URL}delete-category`,{
            categoryId: id
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
            loadingCallback(false)
            if(response.data.success)
            {
              showModalWarning("Category deleted successfully!", 'success')
              getCategorys();
              getSingleCategoryList();
            }
            else if(!response.data.success) {
              showModalWarning(response.data.message)
            }
            else {
              showModalWarning("Something went wrong!")
            }
        })
        .catch((error) => {
          loadingCallback(false)
          console.log(error);
        });
      }
    });
  }
  const handleDeleteSubCatagory = (id) =>{
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        loadingCallback(true)
        axios.post(
          `${process.env.REACT_APP_API_URL}delete-sub-category`,{
            subCategoryId: id
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
            loadingCallback(false)
            console.log("Subcatagory",response.data);
            if(response.data.success)
            {
              showModalWarning("SubCategory deleted successfully!", 'success')
              getSubCategorys();
            }
            else if(!response.data.success) {
              showModalWarning(response.data.message)
            }
            else {
              showModalWarning("Something went wrong!")
            }
        })
        .catch((error) => {
          loadingCallback(false)
          console.log(error);
        });
      }
    });
  }



function handleSubmitUnit(e) {

  ///console.log(catId, catName, catDescription);

  e.preventDefault();
  console.log("even value");

  if (validateForm("Unit")) {
    axios
    .post(
        `${process.env.REACT_APP_API_URL}update-unit`,
        { unitId: catId, name: catName, description: catDescription },
        {
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    )
    .then((response) => {
        //console.log(response.data.success);
        if(response.data.success)
        {
          setShowModal(false);
          getUnit();
          showModalWarning("Unit successfully Updated!", 'success')
          // window.location.reload();
            //setName(" ");
            //setDescription(" ");
            //navigate('/settings/category');
        }else{
          setLoader(true)
          showModalWarning("Unit successfully Updated!")
        }

    })
    .catch((error) => {
        console.log(error);
    });
  }

}


const [uploadedFiles, setUploadedFiles] = useState({});
const [uploadDetails, setUploadDetails] = useState([]);

const brandDataSave = (brandLogo) => {
	axios
		.post(
			`${process.env.REACT_APP_API_URL}update-brand`,
			{
				brandId: catId,
        image_path: brandLogo.length > 0 ? brandLogo[0] : image,
				name: catName,
				description: catDescription,
			},
			{
				headers: {
					authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			}
		)
		.then((response) => {
			if(response.data.success)
      {
        setShowModal(false);
        getBrands();
      }
      loadingCallback(false);
		})
		.catch((error) => {
			console.log(error);
			loadingCallback(false);
      if (error?.response?.status === 401) {
        localStorage.clear();
        localStorage.setItem('token', false);
        // Clear session and local storage
        localStorage.setItem('isPinVerify', false);

        navigate('/login');
      }
		});
};

const [inputErors, setInputErrors] = useState({
  name: '',
  categoryId:0
});

function handleSubmitBrand(e) {
  e.preventDefault();

  if (validateForm("Brand")) {
    loadingCallback(true);
    uploadFile(
        uploadedFiles,
        (brandLogo) => {
            // Success callback
            brandDataSave(brandLogo);
        },
        (error) => {
            // Error callback
            console.log(error);
            loadingCallback(false);
        },
        (progressDetails) => {
            // Progress callback, update uploadDetails here
            setUploadDetails(progressDetails);
        }
    );
  }
}

const validateForm = (validationFor ="Brand") => {
  let valid = true;
  const newInputErrors = { ...inputErors };

  if(validateData(catName,validationFor)){
    newInputErrors.name = validateData(catName,validationFor);
    valid = false;
  }

  if(validationFor === 'Sub Category') {
    if (categoryId === '' || categoryId ===0) {
        newInputErrors.categoryId = `${validationFor} is required`;
        valid = false;
    } else {
      newInputErrors.categoryId = '';
    }
  }

  setInputErrors(newInputErrors);
  return valid;
};

const resetFormError = () => {
  setInputErrors({
    catName: ''
  });
};


const handleChange = ({ target: { files } }) => {
  let tempUploadDetails = [...uploadDetails];

  for (let i = 0; i < files.length; i++) {
    let size = files[i].size / 1024;
    let sizeName = '';

    if (size > 1024) {
      size = (size / 1024).toFixed(2);
      sizeName = size + 'MB';
    } else {
      sizeName = size.toFixed(2) + 'KB';
    }

    tempUploadDetails.push({
      name: files[i].name,
      size: sizeName,
      percentage: 0,
    });
  }

  setUploadDetails(tempUploadDetails);
  setUploadedFiles(files);

  setFile(URL.createObjectURL(files[0]));
};

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });
  const handleImageChange = async (files, name) => {
    let tempUploadDetails = [...uploadDetails];
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        // setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        FlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,'error');
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob],files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
        file: file,
        input_name: name,
      });
    }
    //console.log("============");
    //console.log(tempUploadDetails);
    setFile(URL.createObjectURL(files[0]));
    setUploadedFiles(files);
    setUploadDetails(tempUploadDetails);
  };

  const handleSelectChange = (name, option) => {

    const { value, label } = option;
    const selectedValue = value;

    if (name === 'category_id') {
        setCategoryId(selectedValue);
    }
  };

  return (
    <>
      {loading && (
        <div className="custom-loader-logo">

          <img
            className=""
            src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
            alt="logo"
          />
        </div>
      )}

      <tr className="border-t  border-[#005DAE1C] rounded-xl">
        <td className="py-2 w-1/12">
          <div
            className={`rounded-full w-[42px] h-[42px] mr-8 bg-white flex justify-center items-center text-[#551FFF] ${
              isDiscount && "-scale-x-100"
            }`}
          >
            <ToastContainer/>
            <AiOutlinePicLeft></AiOutlinePicLeft>
          </div>
        </td>
        <td className="py-3">
          <h4 className="font-lato font-semibold text-18px text-[#000232]">
            {type == "branchList" ? items.branch_name : items.name}{" "}
          </h4>
          <p className="font-lato font-semibold text-xs text-[#00023278]">
            {items.description}
          </p>
        </td>
        {/* <td className="w-2/4 py-3 text-[#87889E] font-lato font-semibold text-xs"><p className="w-[150px]">{items.product}</p></td>
        <td className="px-6 py-3 w-1/4 text-right">
          <h4 className="font-lato font-semibold text-lg text-[#000232]">{items.price}</h4>
          <p className="font-lato font-semibold text-xs text-[#00023278]">{items.time}</p>
        </td> */}
        <td className="w-1/4 text-right">
          {modalOne && (
            <div className="flex md:flex-row flex-col justify-end">
              <label
                htmlFor="CategoryEditModal"
                onClick={updateCategory(items)}
                className=" text-12px cursor-pointer font-normal  m-1 block w-16 text-center border-[1px] border-solid border-[#407BFF] text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                >
                Edit
                {/* <MdModeEdit style={{ cursor: "pointer" }} /> */}
              </label>
              <label onClick={()=>handleDeleteCatagory(items.id)} className="w-16 font-normal m-1 text-center cursor-pointer block border-[1px] border-solid border-[#f14343] text-[#f14343] rounded-[5px] px-[5px] pt-[2px] pb-[0px]">Delete</label>
          </div>
          )}
          {modalTwo && (
            <div className="flex md:flex-row flex-col justify-end">
              <label
                htmlFor="SubCategoryEditModal"
                onClick={updateSubCategory(items)}
                className=" text-12px cursor-pointer font-normal  m-1 block w-16 text-center border-[1px] border-solid border-[#407BFF] text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                >
                Edit
                {/* <MdModeEdit style={{ cursor: "pointer" }} /> */}
              </label>
              <label onClick={()=>handleDeleteSubCatagory(items.id)} className="w-16 font-normal m-1 text-center cursor-pointer block border-[1px] border-solid border-[#f14343] text-[#f14343] rounded-[5px] px-[5px] pt-[2px] pb-[0px]">Delete</label>
            </div>
          )}
          {modalThree && (
            <div className="flex md:flex-row flex-col justify-end">
              <label
                htmlFor="updateBrand"
                onClick={updateCategory(items)}
                className=" text-12px cursor-pointer font-normal  m-1 block w-16 text-center border-[1px] border-solid border-[#407BFF] text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
                >
                Edit
                {/* <MdModeEdit style={{ cursor: "pointer" }} /> */}
              </label>
              <label onClick={()=>handleDeleteBrand(items.id)} className="w-16 font-normal m-1 text-center cursor-pointer block border-[1px] border-solid border-[#f14343] text-[#f14343] rounded-[5px] px-[5px] pt-[2px] pb-[0px]">Delete</label>
            </div>
          )}
          {modalFour && (
            <div className="flex md:flex-row flex-col justify-end">
              <label
                htmlFor="my_modal_units"
                onClick={updateCategory(items)}
                className=" text-12px cursor-pointer font-normal  m-1 block w-16 text-center border-[1px] border-solid border-[#407BFF] text-[#fff] hover:text-[#fff] bg-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
              >
                Edit
                {/* <MdModeEdit style={{ cursor: "pointer" }} /> */}
              </label>
              <label onClick={()=>handleDeleteUnit(items.id)} className="w-16 font-normal m-1 text-center cursor-pointer block border-[1px] border-solid border-[#f14343] text-[#f14343] rounded-[5px] px-[5px] pt-[2px] pb-[0px]">Delete</label>
            </div>
          )}
          {modalFive && (
            <label
              htmlFor="my_modal_discount"
              className="flex gap-1 w-full text-xs rounded-[15px] justify-center items-center text-[#000000]  px-6 py-3"
            >
              {" "}
              <MdModeEdit style={{ cursor: "pointer" }} />
            </label>
          )}

          {type == "branchList" && (
            <label
                onClick={navigateToBranch(items.id)}
                className="flex gap-1 w-full text-xs rounded-[15px] justify-center items-center text-[#000000]  px-6 py-3"
              >
              {" "}
              <MdModeEdit style={{ cursor: "pointer" }} />
            </label>

          )}
        </td>
      </tr>

      {/* {/ Modal 11  /}  */}
      <div>
        {showModal && (
          <>
            <input
              type="checkbox"
              id="CategoryEditModal"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Update Category
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="CategoryEditModal"
                      onClick={handleOpen}
                      className="cursor-pointer"
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitCategory}
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Category name"
                      className="input input-bordered font-normal placeholder:font-normal w-full mb-[15px] rounded-2xl bg-white"
                      value={catName}
                      onChange={(e) => {
                        const val = e.target.value;
                        setCatName(val);
                        setInputErrors({ ...inputErors, name: null });
                      }}
                    />
                    {catName == "" ? (
                      <div className="warning-message font-normal placeholder:font-normal">
                        This field can not be empty.
                      </div>
                    ) : catName != "" && inputErors.catName ? (
                      <p className="error-message font-normal placeholder:font-normal text-red-500 mb-3 pl-1">
                        {inputErors.catName}
                      </p>
                    ) : null}
                    <textarea
                      name="description"
                      value={catDescription}
                      onChange={(e) => {
                        const val = e.target.value;
                        setCatDescription(val);
                      }}
                      className="textarea textarea-bordered w-full mb-[15px] font-normal placeholder:font-normal resize-none rounded-2xl bg-white"
                      placeholder="Category Description..."
                    >
                      {catDescription}
                    </textarea>
                    <div className="button-disabled">
                      <button
                        htmlFor="CategoryEditModal"
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {" "}
                        Update {""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Modal 10   */}
      <div className="">
        {showModal && (
          <>
            {" "}
            <input
              type="checkbox"
              id="SubCategoryEditModal"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Update Subcategory
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="SubCategoryEditModal"
                      onClick={handleOpen}
                      className="cursor-pointer"
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitSubCategory}
                  >
                    <SelectBox
                      name="category_id"
                      options={category}
                      value={categoryId}
                      //onChange={e => setCategoryId(e.target.value)}
                      onChange={handleSelectChange.bind(this, "category_id")}
                      //style={{ maxHeight: '300px', overflowY: 'scroll' }}
                      // error={formErrors.category_id}
                      // setFormErrors={setFormErrors}
                      placeholder="Select Category"
                      className="select px-[0] w-full font-normal placeholder:font-normal rounded-2xl mb-[15px] bg-white"
                    />
                    {categoryId == "" && (
                      <div className="warning-message font-normal placeholder:font-normal">
                        This field can not be empty.
                      </div>
                    )}

                    {/* <select name="category_id" onChange={e => setCategoryId(e.target.value)} className='select select-bordered w-full rounded-2xl mb-[15px] bg-white'>
                    <option disabled selected>
                        Select Category
                    </option>
                    {category?.map((item,index)=><option key={index} value={item.id} selected={categoryId===item.id ? "selected": ""}>{item.name}</option>) }
                </select> */}
                    <input
                      type="text"
                      name="name"
                      placeholder="Category name"
                      className="input input-bordered w-full mb-[15px] font-normal placeholder:font-normal rounded-2xl bg-white"
                      value={catName}
                      onChange={(e) => {
                        const val = e.target.value;
                        setCatName(val);
                        setInputErrors({ ...inputErors, name: null });
                      }}
                    />
                    {catName == "" ? (
                      <div className="warning-message">
                        This field can not be empty.
                      </div>
                    ) : catName != "" && inputErors.catName ? (
                      <p className="error-message font-normal placeholder:font-normal text-red-500 mb-3 pl-1">
                        {inputErors.catName}
                      </p>
                    ) : null}
                    <textarea
                      name="description"
                      value={catDescription}
                      onChange={(e) => {
                        const val = e.target.value;
                        setCatDescription(val);
                      }}
                      className="textarea textarea-bordered w-full mb-[15px] font-normal placeholder:font-normal resize-none rounded-2xl bg-white"
                      placeholder="Category Description..."
                    >
                      {catDescription}
                    </textarea>

                    <div className="button-disabled">
                      <button
                        htmlFor="CategoryEditModal"
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {" "}
                        Update {""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* {/ Modal Three  /} */}
      <div>
        {/* {/ The button to open modal /}
          {/ Put this part before </body> tag /} */}
        {showModal && (
          <>
            <input type="checkbox" id="updateBrand" className="modal-toggle" />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Update Brand
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="updateBrand"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitBrand}
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Brand name"
                      className="input input-bordered w-full font-normal placeholder:font-normal mb-[15px] rounded-2xl bg-white"
                      value={catName}
                      onChange={(e) => {
                        const val = e.target.value;
                        setCatName(val);
                        setInputErrors({ ...inputErors, name: null });
                      }}
                    />
                    {inputErors.name
                      ? <p className="text-[1rem] font-normal error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                    : null
                    }
                    <div className="flex justify-between flex-col border font-normal placeholder:font-normal border-opacity-[0.2px] w-full mb-[15px] rounded-2xl">
                      <h4 className="pl-4 py-3">Brand's Logo</h4>
                      <ImageUploader2
                        name={"file-input"}
                        isBig={true}
                        //onChange={(files) => handleImageChange("image_path", files)}
                        onChange={handleImageChange}
                        image={file}
                      />
                      {/* <input
                    type="file"
                    onChange={handleChange}
                    id="file-input"
                    name="file-input"
                    className="hidden"

                  />
                  <label
                    className="w-[147px] rounded-r-2xl px-3 py-3 bg-[#F5F8FF]"
                    htmlFor="file-input"
                  >
                    Browse Files
                  </label>
                  <img src={file ? file : image} /> */}
                    </div>
                    <textarea
                      name="description"
                      value={catDescription}
                      onChange={(e) => {
                        const val = e.target.value;
                        setCatDescription(val);
                      }}
                      className="textarea textarea-bordered w-full mb-[15px] font-normal placeholder:font-normal resize-none rounded-2xl bg-white"
                      placeholder="Brand Description..."
                    >
                      {catDescription}
                    </textarea>

                    <div className="button-disabled">
                      <button
                        htmlFor="CategoryEditModal"
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {" "}
                        Update {""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* Modal units  */}
      <div className="">
        {showModal && (
          <>
            {console.log(items.name)}
            {/* Put this part before </body> tag */}
            <input
              type="checkbox"
              id="my_modal_units"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Update Units
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="my_modal_units"
                      className="cursor-pointer"
                      onClick={handleOpen}
                    >
                      <GrFormClose />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <form
                    method="get"
                    className="col-sm-12"
                    data-autosubmit="false"
                    autoComplete="off"
                    onSubmit={handleSubmitUnit}
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Units name"
                      className="input input-bordered w-full mb-[15px] font-normal placeholder:font-normal rounded-2xl bg-white"
                      value={catName}
                      onChange={(e) => {
                        const val = e.target.value;
                        setCatName(val);
                        setInputErrors({ ...inputErors, name: null });
                      }}
                    />
                    {catName == "" ? (
                      <div className="warning-message font-normal placeholder:font-normal">
                        This field can not be empty.
                      </div>
                    ) : catName != "" && inputErors.catName ? (
                      <p className="error-message font-normal placeholder:font-normal text-red-500 mb-3 pl-1">
                        {inputErors.catName}
                      </p>
                    ) : null}
                    <textarea
                      name="description"
                      value={catDescription}
                      onChange={(e) => {
                        const val = e.target.value;
                        setCatDescription(val);
                      }}
                      className="textarea textarea-bordered w-full mb-[15px] font-normal placeholder:font-normal resize-none rounded-2xl bg-white"
                      placeholder="Units Description..."
                    >
                      {catDescription}
                    </textarea>

                    <div className="button-disabled">
                      <button
                        htmlFor="my_modal_units"
                        type="submit"
                        className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        {" "}
                        Update {""}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TableRow;
