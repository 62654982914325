import React from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const ProductMostHistoryItems = (props) => {
  const { categoryMostDataHistory } = props;


  return (
    <div className="border-b-[1px] text-sm border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-4 items-start">
           <div className=" text-[#000] font-[500] text-left">{categoryMostDataHistory?.product_info?.name}</div>
      {/* Total sell unit */}
      <div className=" text-[#000] font-[500] text-center">{categoryMostDataHistory?.total_sale_unit}</div>
      {/* due */}
      <div className=" text-[#000] font-[500] text-center">{formatPrice(categoryMostDataHistory?.total_purchase_qty)}</div>
      {/* Total Sale Amount*/}
      <div className=" text-[#000] font-[500] text-right">{formatPrice(categoryMostDataHistory?.total_sale_amount)}</div>
    </div>
  );
};
ProductMostHistoryItems.defaultProps = { className: null };
export default ProductMostHistoryItems;