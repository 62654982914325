import axios from "axios";
import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { ImCancelCircle, ImCross } from "react-icons/im";


const TermsAndConditionForm = () => {

  const [loader, setLoader] = useState(true);
  const token = localStorage.getItem('token');
  const [deletedIds, setDeletedIds] = useState([]);

  useEffect(() => {
    fetchDescriptions();
  }, []);

  const fetchDescriptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}terms-conditions-list`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setLoader(false);
        const descriptions = response.data.data.map(item => ({ id: item.id, description: item.description }));
        // setInputs(descriptions);
        setInputs(descriptions.length > 0 ? descriptions : [{ id: null, description: "" }]);
      }
    } catch (error) {
      console.error("Error fetching descriptions:", error);
    }
  };

  const [inputs, setInputs] = useState([{ id: null, description: "" }]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send deletion requests for each ID in `deletedIds`
    await Promise.all(deletedIds.map(async (id) => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}delete-terms-conditions`, { id }, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error(`Error deleting description with id ${id}:`, error);
      }
    }));
    // Update descriptions after handling deletions
    const termsData = {
      descriptions: inputs.map(({ id, description }) => ({ id, description })),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}terms-conditions`,
        termsData,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      );
      console.log("Save response:", response.data);
      window.location.reload();
      fetchDescriptions(); // Refresh descriptions list after save
      setDeletedIds([]); // Clear deleted IDs list
    } catch (error) {
      console.error("Error saving descriptions:", error);
    }
  };

  const handleAddInput = () => {
    setInputs([...inputs, { id: null, description: "" }]);
  };

  const handleRemoveInput = (index) => {
    const idToDelete = inputs[index].id;
    if (idToDelete) setDeletedIds([...deletedIds, idToDelete]);

    if (inputs.length === 1) {
      setInputs([{ id: null, description: "" }]); // Reset to one empty textarea if all are deleted
    } else {
      setInputs(inputs.filter((_, i) => i !== index));
    }
  };

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs];
    // newInputs[index] = value;
    newInputs[index].description = value;
    setInputs(newInputs);
  };

  return (

    <div className="mt-2">
      <div className="w-full relative px-10 md:px-[27px] overflow-x-auto sm:rounded-lg">
        {loader && <div className="custom-loader"></div>}
      </div>
      <form onSubmit={handleSubmit} className="flex gap-5 items-center ml-4">
        <div className="relative col-span-3">
          <div className="max-w-md mx-auto space-y-4">
            {inputs.map((input, index) => (
              <div key={index} className="flex items-center space-x-2">
                <span className="font-semibold text-xl">{index + 1}</span>
                <textarea
                  value={input.description}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  placeholder={`Terms and Condition box ${index + 1}`}
                  className="textarea w-96 px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveInput(index)}
                  aria-label="Remove input"
                  className="mt-2 ml-2 p-2 rounded-full border-2 border-red-500"
                >
                  <ImCross className="text-sm text-red-500" />
                </button>
                {index === inputs.length - 1 && (
                  <button
                    type="button"
                    onClick={handleAddInput}
                    className="p-2 rounded-full border-2 border-green-500"
                  >
                    <FaPlus className="text-green-500" />
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center">
            <button className="p-1 px-4 mt-4 w-40 bg-blue-600 text-white rounded-md">Save</button>
          </div>
        </div>
      </form>
    </div>


  );
};

export default TermsAndConditionForm;
