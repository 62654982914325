import React, { useContext } from 'react';
import QRCode from 'react-qr-code';
import {formatPhoneNumber, numberToWords, formatPrice,invoiceCalculate } from '../../../utils/Common';
import './../../InvoiceDetailPage/PdfHtmlContentA3Challan/PdfHtmlContentA3Challan.css';

const DeliveryInvoiceA3Challan = (props) => {
  const { invoice, invoiceSetting, id, parentClassName, invoiceImeis } = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  const processedProductIds = new Set(); // To track processed product_ids

  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;
  let totalItemsPrint = 0;
  let totalPricePrint = 0;

  return(
    <>
      <div id="pdfConentAreasA3Challan" className="flex items-center justify-center h-screen font-nunito">
        <div id="invoice-detail-page" className="invoice-detail-page">
          {/* Header */}
          <div className="flex flex-col items-center  mx-auto text-center" style={{ maxWidth: "148mm", marginTop: "150px" }}>
            {/* Title */}
            <div className="font-bold text-2xl mb-4">
              Sales Challan
            </div>
            {/* Client Information */}
            <div className="flex justify-between w-full ">
            <div className="text-left text-sm w-1/2 ">
              <p>
                <span className="font-semibold">Party Name</span> :
                <span className="font-semibold italic"> {invoice.customer_name}</span>
              </p>
            </div>

              <div className="flex flex-col justify-end items-end text-sm w-1/2 ">
                <div className="flex flex-col items-start">
                  <p> <span>Challan No</span> : {invoice.invoice_id} </p>
                  <p><span>Challan Date</span> :  {formattedDateTime}</p>
                </div>
              </div>
            </div>
            {/* Table */}
            <div className=' mx-auto mt-4  w-full mb-20'>
                <table className='table print-table border mb-0'>
                  <thead id='pdfContentTHead'>
                    <tr className="leading-normal text-sm font-nunito">
                      <th className='font-nunito text-left w-1/12"' style={{...styles.th }}>SL #</th>
                      <th className='font-nunito text-start w-4/12"' style={{...styles.th }}>Name of the Item</th>
                      <th className='font-nunito text-center w-3/12"' style={{...styles.th }}>Part Number</th>
                      <th className='font-nunito text-right w-2/12"' style={{...styles.th }}>Total Quantity</th>
                      {/* <th className='font-nunito text-center' style={{...styles.th }}>Rate</th>
                      <th className='font-nunito text-right' style={{...styles.th }}>Amount</th> */}
                    </tr>

                  </thead>
                  <tbody>
                    {(() => {
                      let rowCounter = 0;

                      return invoice.sales_details.map((item) => {
                        const productId = item.product_id;

                        // Skip if the product_id has already been processed
                        if (processedProductIds.has(productId)) {
                          return null;
                        }
                        let subtotalPrice = 0;
                        subtotalPrice = formatPrice(item.qty * item.price); // Calculate subtotal price for the product
                        totalQty += Number(item.qty); // Add the quantity of the product
                        totalItems += item.qty; // Count this product as a single item
                        totalPrice += Number(subtotalPrice); // Add to the total price

                        return (
                          <tr key={item.id} className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative">
                            <td className="text-left w-1/12" style={{ padding: '3px 5px', ...styles.td }}>{++rowCounter}</td> {/* SL # */}
                            <td className="w-4/12" style={{ padding: '3px 5px', ...styles.td }}>{item?.product_info?.name} <br /><span className="text-[13px]">{item?.product_info?.serial}</span></td>
                            <td className="text-center w-3/12" style={{ padding: '3px 5px', ...styles.td }}></td>
                            <td className="text-right text-sm w-2/12" style={{ padding: '3px 5px', ...styles.td }}>{item.qty}</td>
                            {/* <td className="text-right text-sm" style={{ padding: '3px 5px', ...styles.td }}>{item.price}</td>
                            <td className="text-right font-semibold md:font-semibold text-sm" style={{ padding: '3px 5px', ...styles.td }}>
                              {subtotalPrice}
                            </td> */}
                          </tr>
                        );
                      });
                    })()}
                    {/*  Total Quantity */}
                    <tr className="font-nunito text-sm lg:text-base border-t border-[#E7EBF4]" style={{ ...styles.tr }}>
                      <td colSpan="2" className="text-left font-bold" style={{ padding: '3px 5px' }}>
                        Total Quantity:
                      </td>
                      <td colSpan="2" className="text-right font-semibold md:font-semibold text-sm" style={{ padding: '3px 5px' }}>
                        {formatPrice(totalQty)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* amount in words */}
                {/* <p className="text-gray-900 font-nunito text-xs  lg:text-base font-semibold leading-normal text-left pl-4 mt-1">
                  <span  style={{ color: '#A0AEC0' }}>Amount in Word : (in {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"})</span>{" "}
                  {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))}  Only
                </p> */}
                {/* narration */}
                <p className=" mt-4 font-nunito text-xs  lg:text-base font-bold leading-normal text-left pl-4">
                  <span>Narration : </span>{" "}

                </p>
            </div>
            {/* signature */}
            <div className="flex space-x-5 text-sm lg:text-sm  w-full" style={{ marginTop: '200px' }}>
              <div className="p-4 text-sm flex flex-col items-start w-1/2 ">
                <div className="flex justify-start items-start">
                  <div className="flex flex-col items-start border-t border-black border-dashed">
                    <hr className="w-full" />
                    <span>Received By:</span>
                  </div>
                </div>
              </div>
              <div className="p-4 text-sm flex flex-col items-end w-1/2 ">
                <div className="flex justify-end items-end">
                  <div className="flex flex-col items-end border-t border-black border-dashed">
                    <hr className="w-full" />
                    <span>Signature</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );

};

const styles = {
  th: {
    backgroundColor: '#FFFFE0',
    WebkitPrintColorAdjust: 'exact',
    padding: '3px 5px',
    border: '1px solid black',

  },
  td: {
    padding: '3px 5px',
    border: '1px solid black',
  },
  tr: {
    padding: '3px 5px',
    border: '1px solid black',
  }
}

export default DeliveryInvoiceA3Challan;