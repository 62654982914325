import React from "react";

const StoreLedgerPdf = ({ tableRef, reports, closingRate, closingQty,closingAmount,openingRate,openingAmount,openingQty,dateFrom,dateTo }) => {
const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div>

      <table ref={tableRef} className="w-full font-nunito mt-8">
        <thead>
        <tr>
          <td className="text-center" colSpan={10}>
            <h2 className="text-2xl font-semibold font-nunito text-nowrap">
              {user?.invoice_settings.shop_name}
            </h2>
          </td>
        </tr>
        <tr>
          <td className="text-center" colSpan={10}>
          <p className="mb-2">
            {user?.address}
            </p>
          </td>
        </tr>
        <tr>
          <td className="text-center " colSpan={10}><p className="mb-2">
              Mobile - {user?.phone}
            </p></td>
        </tr>
        <tr>
          <td className="text-center " colSpan={10}><div className="h-[2px] mb-[2px] bg-black w-full">
          </div></td>
        </tr>
        <tr>
          <td className="text-center" colSpan={10}>
          <div className="h-[2px] mb-[2px] bg-black w-full">
          </div>
          </td>
        </tr>
        <tr>
          <td className="text-center w-full" colSpan={10}><div className="h-[2px]  bg-black w-full">
          </div></td>
        </tr>
        <tr>
          <td className="text-center w-full" colSpan={10}>
          <p className="text-lg font-semibold text-blue-500 mb-2">
             Store Ledger Details
            </p>
          </td>
        </tr>
        <tr>
          <td className="text-center w-full" colSpan={10}>
          <p className="mb-2">
              <span className="font-semibold">{dateFrom}</span> to <span className="font-semibold">{dateTo}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td className="text-center w-full" colSpan={10}>
          <p className="text-lg font-bold text-blue-900 mb-2">
              Cash
            </p>
          </td>
        </tr>
        </thead>

        <thead  className=" border border-black w-full bg-lime-200">
                  <tr className="text-center border-b-2 text-[14px] border-black">
                    <th colSpan="4" className="py-[8px] px-2 font-semibold font-nunito text-center border-r-2 border-black">Particulars</th>
                    <th colSpan="3" className="py-[8px] px-2 font-semibold font-nunito text-center border-r-2 border-black">Outward</th>
                    <th colSpan="3" className="py-[8px] px-2 font-semibold font-nunito text-center ">Balance</th>
                  </tr>
                  <tr className="text-center border-b text-[14px] border-gray-300">
                    {/* particulars column */}
                    <th className="py-[8px]  font-semibold font-nunito text-center ">Date</th>
                    <th className="py-[8px]  font-semibold font-nunito text-center ">Ledger Name</th>
                    <th className="py-[8px]  font-semibold font-nunito text-center ">Ref. No.</th>
                    <th className="p-[8px]  font-semibold font-nunito text-center border-r-2 border-black">Voucher Type</th>
                    {/* <!-- Outward Columns --> */}
                    <th className="p-[8px] font-semibold font-nunito text-center ">Qty</th>
                    <th className="p-[8px]  font-semibold font-nunito text-center ">Rate</th>
                    <th className="p-[8px]  font-semibold font-nunito text-center border-r-2 border-black">Amount <br /> BDT</th>
                    {/* <!-- Balance Columns --> */}
                    <th className="p-[8px]  font-semibold font-nunito text-center ">Qty</th>
                    <th className="p-[8px] font-semibold font-nunito text-center ">Rate</th>
                    <th className="p-[8px]  font-semibold font-nunito text-center ">Amount <br /> BDT</th>
                  </tr>
                </thead>
                {/* <!-- Body of the table would go here --> */}
                <tbody>
                  {/* opening  stocks*/}
                  <tr className="border-b text-[14px] mt-[2px]">
                    {/* particulars column */}
                    <td></td>
                    <td className="py-[8px] px-2 text-[14px] font-medium font-nunito text-center ">Opening Balance</td>
                    <td></td>
                    <td ></td>
                    {/* <!-- Outward Columns --> */}
                    <td></td>
                    <td className="py-[8px] px-2 text-[14px] font-medium font-nunito text-center ">{openingRate}</td>
                    <td className="py-[8px] px-2 text-[14px] font-medium font-nunito text-center "></td>
                    {/* <!-- Balance Columns --> */}
                    <td className="py-[8px] px-2 text-[14px] font-medium font-nunito text-center ">{openingQty}</td>
                    <td className="py-[8px] px-2 text-[14px] font-medium font-nunito text-center "></td>
                    <td className="py-[8px] px-2 text-[14px] font-medium font-nunito text-center ">{openingAmount}</td>
                  </tr>
                  {/*dynamic tdata */}
                  {
                    reports.length > 0 &&
                    reports.map((report,idx) => {
                        return (
                          <tr key={idx} className="border-b text-[14px] mt-[2px]">
                          <td className="py-[8px] px-2 font-medium font-nunito text-center ">{report.created_date}</td>
                          <td className="py-[8px] px-2 font-medium font-nunito text-center">
                            {report.payment_type_name && report.payment_category_name ?
                            `${report.payment_type_name} (${report.payment_category_name})`
                            :report.payment_type_name}
                          </td>
                          <td className="py-[8px] px-2 font-medium font-nunito text-center ">{report.invoice_id}</td>
                          <td className="py-[8px] px-2 font-medium font-nunito text-center ">{report.type}</td>
                          <td className="py-[8px] px-2 font-medium font-nunito text-center ">{report.out_qty}</td>
                          <td className="py-[8px] px-2 font-medium font-nunito text-center ">{report.out_rate}</td>
                          <td className="py-[8px] px-2 font-medium font-nunito text-center ">{report.out_amount}</td>
                          <td className="py-[8px] px-2 font-medium font-nunito text-center ">{report.balance_qty}</td>
                          <td className="py-[8px] px-2 font-medium font-nunito text-center ">{report.balance_rate}</td>
                          <td className="py-[8px] px-2 font-medium font-nunito text-center ">{report.balance_amount}</td>
                      </tr>
                        )
                    })
                  }


                  {/*  closing balances */}

                  {/* closing balance column */}
                  <tr className="border-b  mt-[2px] text-center font-semibold">
                    <td></td>
                    <td className="py-[8px] px-2 font-semibold font-nunito text-center ">
                      Closing Balance
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="py-[8px] px-2 font-medium font-nunito text-center ">{closingRate}</td>
                    <td></td>
                    <td className="py-[8px] px-2 font-medium font-nunito text-center ">{closingQty}</td>
                    <td></td>
                    <td className="py-[8px] px-2 font-medium font-nunito text-center ">{closingAmount}</td>
                  </tr>
                </tbody>
        {/* <tfoot className="border-none">
        <tr className="border-none">
          <td className="pt-16 pb-2 border-none bg-transparent">
            <div className="text-center">
              <div className="border-t border-black w-40 mx-auto"></div>
              <span className="text-sm pb-5">Prepared by</span>
            </div>
          </td>
          <td className="border-none bg-transparent"></td>
          <td className="pt-16 pb-2 border-none bg-transparent">
            <div className="text-center">
              <div className="border-t border-black w-52 mx-auto"></div>
              <span className="text-gray-700 text-sm">Checked/Recommended by</span>
            </div>
          </td>
          <td className="border-none bg-transparent"></td>
          <td className="border-none bg-transparent"></td>
          <td className="pt-16 border-none pb-2 bg-transparent">
            <div className="text-center">
              <div className="border-t border-black w-40 mx-auto"></div>
              <span className="text-gray-700 text-sm">Authorized by</span>
            </div>
          </td>
        </tr>
      </tfoot> */}

      </table>
    </div>
  );
};

export default StoreLedgerPdf;
