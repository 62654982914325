import React, { useEffect, useState } from 'react';
import ContentLayout from '../../layout/ContentLayout';
import { AiOutlineSearch } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { useFlashMessage } from '../../context/FlashMessageContext';
import { Modal } from './../../utils/Modal';
import axios from 'axios';
import { set } from 'react-hook-form';
import Swal from 'sweetalert2';

const WareHouseList = () => {
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [houses,setHouses] = useState([]);
  const [showWareHouseModal,setShowWareHouseModal] = useState(false);
  const [showViewModal,setShowViewModal] = useState(false);
  const [showEditHouseModal,setShowEditHouseModal] = useState(false);
  // const [showDeleteHouseModal,setShowDeleteHouseModal] = useState(false);
  const [currentHouse,setCurrentHouse] = useState(null);
  const [warningMessage, setWarningMessage] = useState(false);
  const userId = JSON.parse(localStorage.getItem('user'));
  const [formData, setFormData] = useState({
            name : '',
            email : '',
            phone : '',
            address : ''
  });
  const token = localStorage.getItem('token');
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage();





  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  useEffect(() => {
    loadingCallback(true)
    fetchHouses();
  },[refetch])




// fetching warehouses
  const fetchHouses = async() => {
    try{
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}stores/user/${userId.id}`,{
        headers : {
          authorization : `Bearer ${token}`,
          'content-type' : 'application/json'
        }
      });
      loadingCallback(false)
      return setHouses(data);
    }catch(error){
      loadingCallback(false);
      console.log(error);
    }
  }

  const handleHouseViewOpen = (house) => {
    loadingCallback(true);
    setShowViewModal(true);
    setCurrentHouse(house);
    setFormData({
      name : house?.name || '',
      email : house?.email || '',
      phone : house?.phone || '',
      address : house?.address || ''
    })
    loadingCallback(false)
  }

  const handleOpenEditHouse = (house) => {
    // loadingCallback(true);
    setShowEditHouseModal(true)
    setCurrentHouse(house);
    setFormData({
      name : house?.name || '',
      email : house?.email || '',
      phone : house?.phone || '',
      address : house?.address || ''
    })
  }

  // delete operation
  const showModalBeforeDelete = (id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id, index);
      }
    });
  };

  const deleteData = (id,idx) => {
    console.log(id,idx);
  }



  return (
    <>
    <ContentLayout>
    <div className="main-content recentTableContent">
          <div className="flex flex-col w-full">
            <div className='flex flex-col md:flex-row justify-between mb-4'>
              <div>
                <h1 className='text-[#000232] text-[22px] font-[600] font-lato mb-2'>Ware House List</h1>
              </div>
              <div className='flex gap-2 justify-center'>
                <div className="flex relative  w-[95%]">
                  <input
                    autoFocus
                    type="text"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                    placeholder="Search Category"
                    // onChange={(e) => {
                    //   setSearchFilter({...searchFilter, keyword: e.target.value})
                    // }}
                  />

                  <div className="absolute top-[37%] left-[10px]">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>
                </div>
                <label
                  // onClick={handleOpen}
                  onClick={() => setShowWareHouseModal(val => !val)}
                  htmlFor="add_expense_modal"
                  className="cursor-pointer bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 pl-[22px] pr-[24px] flex items-center gap-2"
                >
                  <BiPlus className="text-xl"></BiPlus>
                </label>
              </div>
            </div>
            {flashMessage && (
            <div
              className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
                flashMessage.type
              )}`}
            >
              {flashMessage.text}
            </div>
          )}
          {loading && (
            <div className="custom-loader-logo">
              {/* <BeatLoader color={"#123abc"} size={20} /> */}
              <img
                className=""
                src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                alt="logo"
              />
            </div>
          )}
          <div className="grid grid-cols-5  gap-2 md:gap-5 text-center font-nunito font-bold text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
            {/* <div className="py-[8px]">ID</div> */}
            <div className="text-left py-[8px] hidden sm:block">
              Name
            </div>
            <div className="py-[8px] text-center hidden sm:block">
              Email
            </div>
            <div className="py-[8px] text-center hidden sm:block">
              Phone
            </div>
            <div className="py-[8px] text-center">
              Address
            </div>
            <div className="py-[8px] text-right">Action</div>
          </div>
          <div className="max-h-[70vh] overflow-y-auto">
            {/* warehouse's list from api */}
            {
              houses && houses.length > 0 ?
              houses.map((house,idx) => {
                return (
                  <div key={idx} className="grid grid-cols-5 md:gap-5  gap-2 items-center font-nunito font-[500] text-[14px] text-[#000232] border-t-[1px] border-solid border-[#E7EBF4] py-[8px]">
        {/* <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start"> */}
                  <div>
                    <div className="">
                      {/* <div className="font-nunito text-[14px]"> */}
                        <div className="font-[500] text-[#000232]">#{house?.id}</div>
                        <div className="font-[700] text-[#87889E]">{house?.name}</div>
                      {/* </div> */}
                    </div>
                  </div>
          {/* <div className="text-center">{expense.catogory_name}</div> */}
                    <div className="text-center">{house?.email}</div>
                    <div className="text-center">{house?.phone} </div>
                    <div className="text-center">{house?.address}</div>
                    <div className="flex gap-2  text-xs justify-end">
                      <label
                        // htmlFor="view_warehouse_modal"
                        onClick={()=>handleHouseViewOpen(house)}
                        //onClick={()=> Swal.fire("View option coming soon")}
                        // onClick={handleHouseViewOpen(house?.id)}

                        className="cursor-pointer border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[6px] py-1"
                      >
                      VIEW
                      </label>
                      <label
                        // htmlFor="edit_warehouse_modal"
                        //onClick={()=> Swal.fire("Edit option coming soon")}
                        onClick={() =>handleOpenEditHouse(house)}
                        className="cursor-pointer border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-[5px] px-[7px] py-1"
                      >
                      EDIT
                      </label>
                      <label
                        // htmlFor="editAccTxn_modal"
                        // onClick={()=> Swal.fire("Edit option coming soon")}
                        onClick={() => showModalBeforeDelete(house?.id, idx)}
                        className="cursor-pointer border-[1px] border-solid bg-[#FF0303] text-white rounded-[5px] px-[7px] py-1"
                      >
                      DELETE
                      </label>
                    </div>
                  </div>
                )
              })
               : ''
            }

              <div className="hrtable"></div>
          </div>
          </div>


         {
           showEditHouseModal ? <Modal
           loadingCallback={loadingCallback}
           type="edit_warehouse_modal"
           formId="edit_warehouse"
           title="Edit Warehouse"
           setFormData={setFormData}
           formData={formData}
           isOpen={showEditHouseModal}
           warehouseId={currentHouse?.id}
           setOpen={setShowEditHouseModal}
           warehouseData={currentHouse}
           refetch={() => setRefetch(val => !val)}
           />
           : null
         }
         {
           showViewModal ? <Modal
           loadingCallback={loadingCallback}
           type="view_warehouse_modal"
           formId="view_warehouse"
           title="Details"
           setFormData={setFormData}
           formData={formData}
           isOpen={showViewModal}
           warehouseId={currentHouse?.id}
           warehouseData={currentHouse}
           setOpen={setShowViewModal}
           />
           : null
         }

         <Modal
          loadingCallback={loadingCallback}
          type="create_warehouse_list"
          formId="create_warehouse"
          title="Create Warehouse"
          setFormData={setFormData}
          formData={formData}
          isOpen={showWareHouseModal}
          setOpen={setShowWareHouseModal}
          refetch={() => setRefetch(val => !val)}
          />

      </div>
    </ContentLayout>
    </>
  );
};

export default WareHouseList;