import React from 'react';
import { formatPrice, numberToWords,invoiceCalculate, formatPhoneNumber } from '../../../utils/Common';
import Watermark from '../Watermark/Watermark';
import './PdfHtmlContent.css';



import QRCode from 'react-qr-code';

const PdfHtmlContent = props => {

  const { invoice, invoiceSetting, id } = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';

  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;




  return (
    <div id="pdfContentAreas" className="pdfConentAreas">
      <div id="invoice-detail-page" className="invoice-detail-page font-nunito">
        {/* <div className="invoice-info">
          <h1>Invoice</h1>
          <p>{invoice.invoice_id}</p>
          <p>{formattedDate}</p>
        </div> */}
        <div className="invoice-header">
          <div className="invoice-logo font-nunito font-semibold mx-auto w-[100px]  md:w-[551px]  text-center ">

              <img src={shop_logo} alt={invoice.invoice_id} className="mx-auto w-1/2 md-[0px] max-w-[220px]" />
            <div className='my-2'>
              {invoiceSetting.shop_name &&
                <p className='text-[20px]'>{invoiceSetting.shop_name} </p>
              }
              {invoiceSetting.shop_address &&
                <p>{invoiceSetting.shop_address}</p>
              }
               {invoiceSetting?.mobile_number &&
                <p>Mobile : {formatPhoneNumber(invoiceSetting?.mobile_number) }</p>
              }
               <div className='flex flex-col '>
           <span>{invoiceSetting?.web_address ?
              invoiceSetting?.web_address
            : (
              ""
            )}</span>
            <span>
            {invoiceSetting?.social_link ?
              invoiceSetting?.social_link
            : (
              ""
            )}
            </span>

            </div>
            </div>
          </div>

          {/* <div>
            <h1 className='font-bold text-[20px] font-nunito '>{invoiceSetting.shop_name}</h1>
            <p className="mt-1 mb-1 text-13 font-semibold text-gray-700 font-nunito">{invoiceSetting.shop_address}</p>
            <p className="text-blue-500 font-nunito text-12 font-bold">Mobile: +880 1726594320</p>
          </div> */}
        </div>
        {/* <DashedLine /> */}

        <div className="invoice-customer flex justify-between m-3 items-center">
          <div className="text-black font-nunito text-24 leading-140">
            <p className='mb-2'> <span className='font-semibold'>Vendor Name</span>  :  {invoice.vendor_name} </p>
            <p className='mb-2'><span className='font-semibold'>Vendor Phone</span> :  {invoice.country_code}{invoice.vendor_phone}</p>
            <p className='mb-2'><span className='font-semibold'> Vendor ID </span> : {invoice.vendor_id}</p>
            <p className='mb-2'><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p>
          </div>
          <div className="w-[100px]">
            <QRCode className="w-full object-contain" value={BASE_URL + '/purchase-invoice/' + invoice.invoice_id} size={100} />
          </div>
        </div>

        <div style={{ width: '100%', backgroundColor: '#3056FA', padding: '10px', textAlign: 'center', margin: '15px auto' }}>
          <p className="text-white font-nunito text-14 font-bold uppercase">Invoice No: {invoice.invoice_id}</p>
        </div>

        <div className={`invoice-items`}>
          <table className={`table ${'h-[600px]' ? 'overflow-x-scroll' : 'overflow-x-hidden'}`}>
            <thead>
              <tr className="text-gray-900 font-nunito text-base font-semibold leading-normal tracking-wide relative">
                <th><span className="hidden md:inline-block">Item</span> Name</th>
                <th>Warranties</th>
                <th>Quantity</th>
                <th>Price</th>
                <th className='absolute right-0'>Total</th>
              </tr>
            </thead>
            <tbody>
              {invoice.purchase_details ?
                invoice.purchase_details.map((item) => {
                  totalQty += Number(item.qty);
                  totalItems += 1;
                  const subtotalPrice = formatPrice(item.qty * item.price);
                  totalPrice += Number(subtotalPrice);
                  return (
                    <tr key={item.id} className="text-blue-gray-500 font-nunito text-[15px]">
                      <td>{item?.product_info?.name}<br /><span className='text-[13px]'>{item?.product_info?.serial}</span></td>
                      <td>{item.qty}</td>
                      <td>{item.price}</td>
                      <td className='text-gray-900 font-nunito  font-semibold '>{subtotalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</td>
                    </tr>
                  );
                }
                ) : null}
            </tbody>
            <Watermark text={invoice?.user_info?.invoiceSetting?.watermark_text}></Watermark>
          </table>
        </div>
        {/* <DashedLine2 /> */}
        <br />
        <div className='flex justify-between'>
          <div className="terms-condition font-semibold text-[18px]">
            {invoiceSetting?.purchase_condition ?
              invoiceSetting?.purchase_condition
              : (
                ""
                // <p>Damage caused by misuse, abuse, or neglect.<br />
                //   Unauthorized modifications or repairs.<br />
                //   Acts of nature, such as floods, earthquakes, or other disasters.<br />
                //   This may include proper use.</p>
              )}
          </div>
          <div className="invoice-summary ">

            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>TOTAL ITEMS</span>
              <span>{totalItems}</span>
            </p>

            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>SUB TOTAL</span>
              <span>{totalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>

            <p className='flex mt-2 justify-between text-blue-gray-500 font-nunito font-semibold gap-5'>
              <span>Vat {''}</span>
              <span>{invoice.vat ? formatPrice(invoice.vat) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex mt-2 justify-between text-blue-gray-500 font-nunito font-semibold gap-5'>
              <span>Service Charge {''}</span>
              <span>{invoice.tax ? formatPrice(invoice.tax) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <hr></hr>
            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>Grand Total</span>
              <span>{formatPrice(invoiceCalculate(invoice,'purchase').grandTotal)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>Paid Amount</span>
              <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <hr></hr>
            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>Due Amount</span>
              <span> { formatPrice(invoiceCalculate(invoice,'purchase').dueAmount) }{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
          </div>
        </div>
        <p className='text-gray-900 mt-3 font-nunito text-[18px] font-semibold leading-normal text-center'>In Word: {numberToWords(formatPrice(invoiceCalculate(invoice,'purchase').grandTotal))} taka only</p>
        <div style={{ width: '100%', backgroundColor: '#034AFF', padding: '10px', textAlign: 'center', margin: '25px auto' }}>
          <p className="text-white font-nunito text-14 font-bold">You saved {formatPrice(invoice.discount ? invoice.discount : 0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
        </div>
        <div>
          <div className='flex justify-between'>
            <p className="invisible">.</p>
            {!id && invoiceSetting?.sign_authority ? <div className='w-[120px] h-[120px] rounded-lg'>
              <img src={invoiceSetting.sign_authority} className='object-cover' />
            </div> : null }
          </div>
          <div className="flex justify-between m-2">
            <p ><hr></hr> Vendor Signature</p>
            <p ><hr></hr> Customer Signature </p>
          </div>
        </div>

      </div>
      <div className='text-center font-nunito'>

        <h2 className='text-orange-500  text-xl font-semibold '>Thanks for purchase</h2>
        <p className='text-black mt-3 text-xs mb-2'>A Product of SQUAD INNOVATORS </p>
      </div>

    </div>

  );
};

export default PdfHtmlContent;
