import React, { useEffect, useState } from 'react';
import ContentLayout from '../../layout/ContentLayout';
import axios from 'axios';
import Swal from 'sweetalert2';

const CreateWareHouse = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: ''
  });
  const token = localStorage.getItem("token");
  const userId = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = { ...formData, status: 1, user_id: userId.id };

    // Ensure the payload is correct
    // console.log("Payload:", payload);

    axios.post(`${process.env.REACT_APP_API_URL}warehouses`, payload, {
      headers: {
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      }
    }).then((res) => {
      // console.log(res);
      // if (res.data) {
      Swal.fire({
        title: "Created!",
        text: "Warehouse has been created.",
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      // Reset the form fields after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        address: ''
      });

      // Verify the form is cleared
      // console.log("Form after reset:", formData);
      // }
    }).catch((err) => {
      console.log(err);
    });

  }
  return (
    <>
      <ContentLayout>
        <div className='main-content'>
          {/* <form
                method="get"
                className="col-sm-12 min-h-[10vh]"
                data-autosubmit="false"
                id='warehouse-creation'
                autoComplete="off"
                onSubmit={handleSubmit}
              >
              <div className="duration-500">

                <div className="w-full relative">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    placeholder="Name"
                    //value={formData.catogory_name || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      name: e.target.value
                    })}
                    className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
                  />
                </div>
                <div className="w-full relative mt-2">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    //value={formData.transaction_date || ''}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        email: e.target.value
                      });
                    }}
                    className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
                  />
                </div>

                <div className="w-full relative">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={(e) => setFormData({
                      ...formData,
                      phone: e.target.value
                    })}
                    className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
                  />
                </div>
                <div className="w-full relative mt-2">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                    Address
                  </label>
                  <textarea
                    type="text"
                    name="address"
                    value={formData.address}
                    //value={formData.description || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      address: e.target.value
                    })}
                    placeholder="Text"
                    className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full  h-20 pt-2 rounded-xl bg-white resize-none"
                  />
                </div>

                <div>
                  <button
                    htmlFor="my_modal_payment_info"
                    // onClick={ () => handleClicks()}
                    type="submit"
                    className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                  >
                    Next
                  </button>
                </div>
                {showPaymentModal ? (
                      <Modal
                        isOpen={showPaymentModal}
                        setOpen={setShowPaymentModal}
                        purchaseAmount={formData.amount}
                        getData={paymentData}
                        listData={paymentList}
                        formData={formData}
                        setFormData={setFormData}
                        paymentAccountList={paymentAccountList}
                        setSelectedAccountList={setSelectedPaymentAccountList}
                        setData={setPaymentData}
                        setPaidAmount={setPaidAmount}
                        // setWarningMessage={setWarningMessage}
                        title="Payment Info"
                        formId="my_modal_payment_info"
                        loadingCallback={loadingCallback}
                        navigate={navigate}
                        type="payment-info"
                        selectType={type}
                        setFilteredAccountList={setFilteredAccountList}
                        filteredAccountList={filteredAccountList}
                      />
                    ) : null}

                    <button type='submit' className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl mb-5 text-[#fff] font-lato font-semibold text-lg">Add WareHouse</button>
              </div>
        </form> */}
          <form
            method="get"
            className="w-full items-start mx-auto bg-white rounded-xl p-8"
            data-autosubmit="false"
            id="warehouse-creation"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-3 gap-6">

             {/*  <div className='relative row-start-1'>
                <span className='font-semibold text-xl'>Add Warehouse</span>
              </div> */}

              <div className="relative row-start-1">
                <label className="absolute font-nunito text-sm text-[#1F2A37] -top-3 left-3 bg-white px-1 font-semibold">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  placeholder="Warehouse Name"
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="border border-[#E5E7EB] text-sm w-full py-2 px-2 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300"
                />
              </div>

              <div className="relative row-start-1">
                <label className="absolute font-nunito text-sm text-[#1F2A37] -top-3 left-3 bg-white px-1 font-semibold">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  placeholder="Warehouse Email"
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="border border-[#E5E7EB] text-sm w-full py-2 px-2 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300"
                />
              </div>

              <div className="relative row-start-1">
                <label className="absolute font-nunito text-sm text-[#1F2A37] -top-3 left-3 bg-white px-1 font-semibold">
                  Phone
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  placeholder="Warehouse Phone Number"
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  className="border border-[#E5E7EB] text-sm w-full py-2 px-2 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300"
                />
              </div>

              <div className="relative row-start-2">
                <label className="absolute font-nunito text-sm text-[#1F2A37] -top-3 left-3 bg-white px-1 font-semibold">
                  Address
                </label>
                <textarea
                  name="address"
                  value={formData.address}
                  placeholder="Warehouse Address"
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                  className="border border-[#E5E7EB] text-sm w-full py-2 px-2 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none resize-none transition duration-300"
                ></textarea>
              </div>
              <div className=" row-start-2">
              <button
                type="submit"
                className="w-[40%] bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-indigo-600 hover:to-blue-500 text-white py-2 rounded-lg text-md transition duration-300"
              >
                Add Warehouse
              </button>
            </div>

            </div>


          </form>
        </div>
      </ContentLayout>
    </>
  );
};

export default CreateWareHouse;