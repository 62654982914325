import React from 'react'
import ContentLayout from '../../layout/ContentLayout'
import Footer from '../../components/footer/Index'
import "./index.css"
import SettingsCompV2 from '../../components/settings-v2/SettingsCompV2'
import { Helmet } from 'react-helmet-async'
const SettingsPageV2= () => {
    return (
    <>
        <ContentLayout className="w-full">
            <Helmet>
                <title>Outlet Expense | Settings</title>
            </Helmet>
            {/* settingsV2 component */}
            <div className="main-content">
                <SettingsCompV2 />
                {/* <Footer /> */}
            </div>
        </ContentLayout>
    </>
    );
};
export default SettingsPageV2