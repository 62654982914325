import React, { useContext, useEffect, useRef, useState } from 'react';
import ContentLayout from "../../../layout/ContentLayout";
import searchIcons from "./../../../assets/images/search-icon.png";
import pdfIcon from "./../../../assets/images/pdf-Icon.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../../utils/Common";
import { get, post } from '../../../services/apiService';
import { RiMoneyDollarCircleLine } from "react-icons/ri";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Pagination from '../../../utils/pagination';
import { AppContext } from '../../../context/context';
import { AiOutlineSearch } from 'react-icons/ai';
import ExporterProductReportList1 from '../../ExporterProduct/ExporterProductReportList1/ExporterProductReportList1';
import SelectBox2 from '../../../forms/Select2.js';
import SelectBox from '../../../forms/Select.js';
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';


const BASE_URL = process.env.REACT_APP_ROOT_URL;

const ExporterStockPage = () => {
  const navigate = useNavigate();
  //---------------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      // Parse the employee data from local storage
      const employeeData = JSON.parse(employeeApiResponse);

      // Get the "Exporter" feature from the employee's role features
      const exporterFeature = employeeData.role.features.find(
        (feature) => feature.name === "Exporter"
      );

      // Check if the "Exporter" feature and "Visible" option are both active
      if (exporterFeature && exporterFeature.status === 1) {

        // Grant access based on the route and permissions
        if (location.pathname === "/exporter-stock") {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
      }
      setHasPermission(false);
      // If no permission, navigate to dashboard first
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
    // If not an employee, the useEffect gets skipped
  }, [navigate, location]);
  //---------------------
  const [showPdf, setShowPdf] = useState(false);
  const [activeTab, setActiveTab] = useState("daily");
  const [reportBtn, setReportBtn] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [exporterListSelect, setExporterListSelect] = useState([]);
  const [carrierListSelect, setCarrierListSelect] = useState([]);
  const [customerListSelect, setCustomerListSelect] = useState([]);
  const [productListSelect, setProductListSelect] = useState([]);
  const [exporterProductReportList, setExporterProductReportList] = useState([]);
  const token = localStorage.getItem("token");
  const exporterProductListRef = useRef();
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null); // To store the selected product

  const [printFilter, setPrintFilter] = useState({
    exporter_name: "",
    carrier_name: "",
    stock_type: "",
    start_date: "",
    end_date: "",
    customer_name: "",
    product_name: "",
});

  const handleTabClick = (index) => {
    setReportBtn(false)
    setSelectAllChecked(false)
    setSelectedItems([])
    setCurrentPage(1)
    setActiveTab(index);
  };
  const handleReportButton = () => {
    setReportBtn(true);
    setSelectAllChecked(false)
    //getDateWiseStockReport(1);
  };

  const updateFilter = (key, value) => {
    setPrintFilter((prev)=>({
      ...prev,
      [key]:value

      })
    );
  }

    // ------------
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 10;
    const { state, isLoading, updateCustomerList, updateProductList } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const loadingCallback = loading => {
      setLoading(loading);
    };
    const [customerData, setCustomerData] = useState([]);
    const [currentTotal, setCurrentTotal] = useState(0);
    const { customerLists,invoiceSetting } = state;

    const [searchFilter, setSearchFilter] = useState({
      keyword: "",
      invoiceId: false,
      startDate: 0,
      endDate: new Date(),
    })
    //----------------------

  useEffect(() => {
    if(hasPermission){
      if(exporterListSelect.length < 1){
        getExporters();
      }
      if(carrierListSelect.length < 1){
        getCarriers();
      }
    }
    // if(customerListSelect.length < 1){
    //   getCustomers();
    // }
    //if(productListSelect.length < 1){
      //getExporterProductList();
      //getProducts();
    //}
  },[exporterListSelect, carrierListSelect, hasPermission]);

  useEffect(() => {if(hasPermission){getCustomers(currentPage);}},[currentPage, searchFilter,hasPermission]);

  const getExporters = () => {
    if (!token) {
      console.error("Token is not defined");
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}exporter-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setExporterListSelect(response.data.data);
        console.log("Exporter list: ", response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching the exporter list:", error);
      });
  };

  const getCarriers = () => {
    if (!token) {
      console.error("Token is not defined");
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}carrier-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Carrier list: ", response.data.data);
        setCarrierListSelect(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching the exporter list:", error);
      });
  };

  const getCustomers = (page) => {
    if (searchFilter.keyword !== "") {
      const reqData = { keyword: searchFilter.keyword };
      const data = {
        keyword: searchFilter.keyword,
        invoiceId: searchFilter.invoiceId,
        startDate:searchFilter.startDate,
        endDate: searchFilter.endDate
      }
      loadingCallback(true);
      post(`search-customer?page=${page}&limit=${perPage}`, data, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            const listData = response.data.data;
            setCustomerData(response.data)
            setCurrentTotal(listData.length)
            setCustomerListSelect(listData)
            console.log("customer names after search", listData)

            const transformedCustomerArray = listData.map(item => ({
              id: item.id,
              icon: <RiMoneyDollarCircleLine />,
              name: item.name,
              type: item.customer_type,
              purchase_number: item.invoice_list_count,
              purchase_amount: invoiceSetting?.currency_info?.code ? formatPrice(item.total_purchase_amount) + ' ' + invoiceSetting?.currency_info?.code : formatPrice(item.total_purchase_amount) + ' BDT',
              due: invoiceSetting?.currency_info?.code ? formatPrice(item.total_due_amount) + ' ' + invoiceSetting?.currency_info?.code : formatPrice(item.total_due_amount) + ' BDT',
            }));
            updateCustomerList(transformedCustomerArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);
          console.log("error", error)
          navigate("/login");
        });
    } else {
      loadingCallback(true);
      get(`customer-lists?page=${page}&limit=${perPage}`, token)
        .then((response) => {
          loadingCallback(false);
          if (response.data.data) {
            const listData = response.data.data;
            const customerNames = response.data.data;
            setCustomerData(response.data)
            setCurrentTotal(listData.length)
            setCustomerListSelect(listData)
            console.log("customer names before search", listData);
            const transformedCustomerArray = listData.map(item => ({
              id: item.id,
              icon: <RiMoneyDollarCircleLine />,
              name: item.name,
              type: item.customer_type,
              purchase_number: item.invoice_list_count,
              purchase_amount: invoiceSetting?.currency_info?.code ? formatPrice(item.total_purchase_amount) + ' ' + invoiceSetting?.currency_info?.code : formatPrice(item.total_purchase_amount) + ' BDT',
              due: invoiceSetting?.currency_info?.code ? formatPrice(item.total_due_amount) + ' ' + invoiceSetting?.currency_info?.code : formatPrice(item.total_due_amount) + ' BDT',
            }));
            updateCustomerList(transformedCustomerArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log("Full error object:", error);

          if (error.response) {
            console.log("Error response data:", error.response.data);
            console.log("Error response status:", error.response.status);
            console.log("Error response headers:", error.response.headers);

            if (error.response.status === 401) {
              localStorage.clear();
              localStorage.setItem("token", false);
              localStorage.setItem("isPinVerify", false);
              navigate("/login");
            } else {
              // Other error handling based on status or data
              console.error("Other API Error:", error.response.data);
            }
          } else {
            // Handle errors without a response (e.g., network issues)
            console.error("Network or other error:", error.message);
          }
        });

    }
  };

  //------------
  const [searchProdFilter, setSearchProdFilter] = useState({
    keyword: "",
  })
  const [productData, setProductData] = useState([]);
  const [productTotal, setProductTotal] = useState(0);

  useEffect(() => {if(hasPermission){getProducts(currentPage);}},[currentPage, searchProdFilter,hasPermission]);

  const getProducts = (page) => {
    if (searchProdFilter.keyword !== "") {
      const reqData = { keyword: searchProdFilter.keyword };
      const data = {
        keyword: searchProdFilter.keyword,
      }
      loadingCallback(true);
      post(`search-product-v1?page=${page}&limit=${perPage}`, data, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            const listData = response.data.data;
            setProductData(response.data)
            setProductTotal(listData.length)
            setProductListSelect(listData)
            console.log("product names after search", listData)

            const transformedProductArray = listData.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateProductList(transformedProductArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);
          console.log("error", error)
          navigate("/login");
        });
    } else {
      loadingCallback(true);
      get(`product?page=${page}&limit=${perPage}`, token)
        .then((response) => {
          loadingCallback(false);
          if (response.data.data) {
            const listData = response.data.data;
            setProductData(response.data)
            setProductTotal(listData.length)
            setProductListSelect(listData)
            console.log("product names before search", listData);
            const transformedProductArray = listData.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateProductList(transformedProductArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log("Full error object:", error);

          if (error.response) {
            console.log("Error response data:", error.response.data);
            console.log("Error response status:", error.response.status);
            console.log("Error response headers:", error.response.headers);

            if (error.response.status === 401) {
              localStorage.clear();
              localStorage.setItem("token", false);
              localStorage.setItem("isPinVerify", false);
              navigate("/login");
            } else {
              // Other error handling based on status or data
              console.error("Other API Error:", error.response.data);
            }
          } else {
            // Handle errors without a response (e.g., network issues)
            console.error("Network or other error:", error.message);
          }
        });

    }
  };

  useEffect(() => {
    if(hasPermission){
      getExporterProductList();
    }
    console.log("filters set", printFilter);
  },[printFilter, hasPermission]);

  const getExporterProductList = () => {
    if (!token) {
      console.error("Token is not defined");
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}exporter-product-report-list`, printFilter, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("All data: ", response.data);
        setExporterProductReportList(response.data);
        const productNames = Object.keys(response.data).map((productName) => ({
          value: productName,  // Store product name as value
          label: productName,  // Display product name as label
        }));
        //setProductListSelect(productNames);
        //console.log("product names", productNames)
      })
      .catch((error) => {
        console.error("Error fetching the exporter list:", error);
      });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setPrintFilter((prevState) => ({
      ...prevState,
      start_date: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setPrintFilter((prevState) => ({
      ...prevState,
      end_date: date,
    }));
  };

  const handleCheckboxChange = () => {
    setShowDatePickers(true);
    const today = new Date();
    setStartDate(today);
    setEndDate(today);

    // Update the printFilter with today's date
    setPrintFilter((prevState) => ({
      ...prevState,
      start_date: today,
      end_date: today,
    }));
  };

  const handlePrint = () => {
    const printContent = exporterProductListRef.current.innerHTML;
    const printWindow = window.open('', '_blank');

    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid #ddd; padding: 8px; } th { background-color: #f2f2f2; }</style>'); // Optional: Add CSS styling
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');

    printWindow.document.close(); // Necessary for IE >= 10
    printWindow.focus(); // Necessary for IE >= 10
    printWindow.print();
    //printWindow.close();
    setTimeout(() => {
      printWindow.close();

      // Reload the page after the print window is closed
      window.location.reload();
    }, 500);
    if(printFilter.carrier_name !== '' || printFilter.exporter_name !== '' || printFilter.stock_type !== '' || printFilter.start_date !== '' || printFilter.end_date !== '' || printFilter.customer_name !== '' || printFilter.product_name !== ''){
      setPrintFilter({
        exporter_name: "",
        carrier_name: "",
        stock_type: "",
        start_date: "",
        end_date: "",
        product_name: "",
        customer_name: ""
      });
      setSearchFilter({
        ...searchFilter,
        stockIn: false,
        stockOut: false,
      });
    }
  };

  useEffect(() => {
    const handleAfterPrint = () => {
      window.location.reload();
    };
    window.addEventListener('afterprint', handleAfterPrint);
    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
        <ContentLayout>
          <div className="bg-white rounded-3xl mt-5 font-nunito p-[26px] ">
            <div className='pl-5'>
                <h1 className='text-[#000232] text-[22px] font-[600] font-lato mb-2'>Exporter Product Stock Report</h1>
            </div>
            <div classname="grid grid-rows-3 gap-y-4 justify-center items-center">
              {/* Row 1: Dropdowns */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full justify-center items-center mt-5 mb-4 pl-5 pr-5">
                <div className="w-full justify-start">
                  <SelectBox
                        name="exporter_name"
                        options={exporterListSelect}
                        value={exporterListSelect[0]}
                        //onChange={(e) => updateFilter("exporter_name", e.label)}
                        onChange={(e) => {
                          if (e.value === "") {
                            updateFilter("exporter_name", "");
                          } else {
                            updateFilter("exporter_name", e.label);
                          }
                        }}
                        style={{ maxHeight: '300px', overflowY: 'scroll' }}
                        placeholder="Select/Search Exporter"
                        label={false}
                  />
                </div>

                <div className="w-full justify-start">
                  <SelectBox
                        name="carrier_name"
                        options={carrierListSelect}
                        value={carrierListSelect[0]}
                        //onChange={(e) => updateFilter("carrier_name", e.label)}
                        onChange={(e) => {
                          if (e.value === "") {
                            updateFilter("carrier_name", "");
                          } else {
                            updateFilter("carrier_name", e.label);
                          }
                        }}
                        style={{ maxHeight: '300px', overflowY: 'scroll' }}
                        placeholder="Select/Search Carrier"
                        label={false}
                  />
                </div>

                <div className="w-full justify-start">
                  <SelectBox
                        name="customer_name"
                        options={customerListSelect}
                        value={customerListSelect[0]}
                        //value={customerListSelect.length > 0 ? customerListSelect[0] : null}
                        //onChange={(e) => {updateFilter("customer_name", e.label)}}
                        onChange={(e) => {
                          if (e.value === "") {
                            updateFilter("customer_name", "");
                          } else {
                            updateFilter("customer_name", e.label);
                          }
                        }}
                        onInputChangeData={(input) => { setSearchFilter({...searchFilter, keyword: input})}}
                        style={{ maxHeight: '300px', overflowY: 'scroll' }}
                        placeholder="Select/Search Customer"
                        label={false}
                  />
                </div>
              </div>

              {/* Row 3: Product Dropdown */}
              <div className="mb-5 pl-5 pr-5 flex justify-between w-full flex-col md:flex-row font-nunito gap-4 items-center mb-5 pl-5 pr-5">
                <div className="w-full md:w-1/3">
                  <SelectBox
                        name="product_name"
                        options={productListSelect}
                        value={productListSelect[0]}
                        //onChange={(e) => updateFilter("product_name", e.label)}
                        onChange={(e) => {
                          if (e.value === "") {
                            updateFilter("product_name", "");
                          } else {
                            updateFilter("product_name", e.label);
                          }
                        }}
                        onInputChangeData={(input) => { setSearchProdFilter({...searchProdFilter, keyword: input})}}
                        style={{ maxHeight: '300px', overflowY: 'scroll' }}
                        placeholder="Select/Search Product"
                        label={false}
                  />
                </div>

                <div className="w-full md:w-1/3">
                  {!showDatePickers ? (
                    // Checkbox to show datepickers
                    <div className="select-date flex ml-3">
                      <input
                        type="checkbox"
                        id="selectDate"
                        className="mr-2"
                        //onChange={() => setShowDatePickers(true)}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="selectDate" className="text-[17px] px-2 font-regular ">
                        Select Date
                      </label>
                    </div>
                  ) : (
                    // Datepickers are shown once checkbox is selected
                    <>
                    <div className="flex gap-3 w-full">
                      <div className="relative border-[#C1CFEF] py-2.5 border w-full md:w-[35%] flex-1 rounded-xl">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => handleStartDateChange(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yy"
                          className="w-full px-2 focus:outline-none text-sm"
                        />
                        <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                          Start Date
                        </span>
                      </div>

                      <div className="relative border-[#C1CFEF] py-2.5 border w-full md:w-[35%] flex-1 rounded-xl">
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => handleEndDateChange(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yy"
                          className="w-full px-2 focus:outline-none text-sm"
                        />
                        <span className="bg-white absolute left-2 px-1 font-semibold text-xs top-0 text-[#102048] translate-y-[-50%]">
                          End Date
                        </span>
                      </div>
                    </div>
                    </>
                  )}
                </div>

                {/* Stock In/Out Buttons */}
                <div className="w-full md:w-1/3 flex gap-4">
                  <div
                    onClick={() => {
                      setSearchFilter({
                        ...searchFilter,
                        stockIn: !searchFilter.stockIn,
                        stockOut: false,
                      });
                      updateFilter("stock_type", searchFilter.stockIn ? "" : "stock_in");
                    }}
                    className={`relative ${
                      searchFilter.stockIn
                        ? "bg-[#407BFF] cursor-pointer text-white"
                        : "bg-gray-100"
                    } border-gray-100 py-1 border w-full flex-1 rounded-xl py-2`}
                  >
                    <p className="w-full text-[13px] cursor-pointer text-center">Stock In</p>
                  </div>

                  <div
                    onClick={() => {
                      setSearchFilter({
                        ...searchFilter,
                        stockOut: !searchFilter.stockOut,
                        stockIn: false,
                      });
                      updateFilter("stock_type", searchFilter.stockOut ? "" : "stock_out");
                    }}
                    className={`relative ${
                      searchFilter.stockOut
                        ? "bg-[#407BFF] cursor-pointer text-white"
                        : "bg-gray-100"
                    } border-gray-100 py-1 border w-full flex-1 rounded-xl py-2`}
                  >
                    <p className="w-full text-[13px] cursor-pointer text-center">Stock Out</p>
                  </div>
                </div>
              </div>

              {/* Row 3: Filter Button */}
              <div className="flex justify-center w-full">
                <button
                  onClick={() => handlePrint() }
                  className="bg-[#034AFF] cursor-pointer font-nunito text-white rounded-xl px-9 xl:px-10 py-2 text-md"
                >
                  Report
                </button>
              </div>

              <div id="printableTable" className="hidden">

                <ExporterProductReportList1 ref={exporterProductListRef} data={exporterProductReportList} filters={printFilter}/>
              </div>
            </div>
          </div>


        </ContentLayout>
    </>
  );
}

export default ExporterStockPage;
