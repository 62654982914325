import React, { useContext } from "react";
import ContentLayout from "../../layout/ContentLayout";
import TopHeader from "../../components/topheader/Index";
import Footer from "../../components/footer/Index";
// import "./index.css";
import OrderProduct from "../../components/OrderProduct/OrderProduct";
import { AppContext } from "../../context/context.js";
import NewSalesBilling from "../../components/OrderProduct/NewSalesBilling";
import { HelmetProvider } from "react-helmet-async";

const NewSalesBillingPage = () => {
  const { state } = useContext(AppContext);
  const { currentUser } = state;
  return (
    <>
      <HelmetProvider>
        <ContentLayout className="w-full">
          <div className="">
            <NewSalesBilling
              type="billing"
              currentUser={currentUser ? currentUser : null}
            ></NewSalesBilling>
          </div>
        </ContentLayout>
      </HelmetProvider>
      <Footer />
    </>
  );
};

export default NewSalesBillingPage;
