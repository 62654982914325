import React from 'react';
import { formatPrice } from '../../utils/Common';
import PaymentTypeItemBank from './PaymentTypeItemBank';


const ExpoBankBalanceHistoryItem = (props) => {
  const {
    creditData,
    key
  } = props;

  // let accountName, payAmount, accountNum;
  // creditData?.map(cr => {
  //   cr?.details?.map(crd => {
  //     accountName = crd?.payment_type_category_id;
  //     payAmount = crd?.payAmount;
  //     accountNum = crd?.account_number;
  //   })
  // })


  return (
    <>

      <div className="flex flex-col text-center mt-2 pt-[4px] pb-[18px]  text-xl px-3 ">

        <div className='text-left'>{key}</div>
        <hr className="border-[#000232] border-[1px] mt-[10px]" />
        <div className='grid grid-cols-3 text-sm mt-3'>
          <div className='text-left'>Bank</div>
          <div className='text-center'>Account</div>
          <div className='text-right'>Amount</div>
        </div>
        {creditData ?
           Object.entries(creditData).map(([key, value], index) => (
            <PaymentTypeItemBank
              ptData={value}
              key={key} // Use the key from the object for uniqueness
            />
          ))
          : null}
        {/* {creditData?.map((cr, index) => {
            return (<>
              <PaymentTypeItemBank ptData={cr} key={index} />
            </>)

          })} */}

      </div>


    </>
  );
};

export default ExpoBankBalanceHistoryItem;
