import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from 'sweetalert2';
import FormPayment from "../../forms/FormPayment";
import BarcodeReader from "react-barcode-reader";
import DatePicker from "react-datepicker";
import { get } from "../../services/apiService";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import warrantyIcon from "./../../assets/images/warranty-icon.png"
import ProductVariationIcon from "./../../assets/images/product-variation.png"
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineSearch,
} from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { Modal } from '../../utils/Modal';
import "./OrderProduct.css";
//import categoryDymmyImaage from "../../assits/product1.png";
import { GrFormClose } from 'react-icons/gr';
import Pagination from '../../utils/pagination'; // Adjust the path accordingly
import { useFlashMessage } from '../../context/FlashMessageContext';
import { AppContext } from '../../context/context.js';

import cardIcon from "../../assets/images/card.svg";
import moneyIcon from "../../assets/images/coin-hand.svg";
import qrIcon from "../../assets/images/qr_code.svg";
import wholeSale from "../../assets/images/wholesale.png";
import {
  formatPrice,
  eclipse,
  formatNumber
} from '../../utils/Common';
import ItemSelectBox2 from "../../forms/ItemSelectBox2.js";
import { FlashMessage } from "../../utils/FlashMessage.js";
import { useLocation } from 'react-router-dom';



function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="p-[6px] rounded-full absolute top-[40%] -right-[8px]"
      style={{ ...style, display: "inline-block", background: "#7F8098" }}
      onClick={onClick}
    >
      <AiOutlineArrowRight className="text-[#FFF] text-base font-bold"></AiOutlineArrowRight>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="p-[6px] rounded-full absolute top-[40%] -left-[9px] z-50"
      style={{ ...style, display: "inline-block", background: "#7F8098" }}
      onClick={onClick}
    >
      <AiOutlineArrowLeft className="text-[#FFF] text-base font-bold"></AiOutlineArrowLeft>
    </div>
  );
}
const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;

const OrderProduct = (props) => {
  const {
    type
  } = props;
  const {
    state,
    setPaperSize,
    setDeliveryMode,
    updateBrandList,
    updateFilteredCustomer,
    updateFilteredWholeSaler,
    updateWarrantyList,
    updateCountryList,
    countryList
  } = useContext(AppContext);

  //---------------------
  const location = useLocation();
  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return;
    }

    if (employeeApiResponse) {
      const employeeData = JSON.parse(employeeApiResponse);

      const purchaseFeature = employeeData.role.features.find(
        (feature) => feature.name === "Purchase"
      );

      if (purchaseFeature && purchaseFeature.status === 1) {

        const purchaseBillingOptionActive = purchaseFeature.feature_options.find(
          (option) => option.name === "Purchase Billing" && option.status === 1
        );
        if (purchaseBillingOptionActive && location.pathname === "/purchase-billing") {
          setHasPermission(true);
          return;
        }
      }
      setHasPermission(false);
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
  }, [navigate, location]);
  //---------------------

  const [isRender,setIsRender] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [deliveryAccountList, setDeliveryAccountList] = useState([])
  const [searchVarientItem, setSearchVarientItem] = useState(''); //this is the state for search of imei of varient item//
  const [selectedImeis, setSelectedImeis] = useState([]); // Track selected IMEIs
  const [status, setStatus] = useState(1);
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library

  const { currentUser, invoiceSetting } = state;
  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [filterItem, setFilterItem] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [wholeSaler, setWholeSaler] = useState(null);
  const [seller, setSeller] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [warningMessage, setWarningMessage] = useState(false);
  const [vat, setVat] = useState(invoiceSetting?.vat != '' ? Number(invoiceSetting?.vat) : 0);
  const [tax, setTax] = useState(invoiceSetting?.tax != '' ? Number(invoiceSetting?.tax) : 0);
  const [moodActive, setMoodActive] = useState(null);
  const [sizeActive, setSizeActive] = useState(null);
  const [sugarActive, setSugarActive] = useState(null);
  const [iceActive, setIceActive] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [selectedFood, setSelectedFood] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [moreDiscount, setMoreDiscount] = useState(0);
  const [totalMoreDiscount, setTotalMoreDiscount] = useState(0);
  const [selectedDiscountType, setSelectedDiscountType] = useState('');
  const [grandTotal, setGrandTotal] = useState(0);
  const [subTotalNew, setSubTotalNew] = useState(0);
  const [grandTotalAfterDiscount, setGrandTotalDiscounted] = useState(0);
  const [change, setChange] = useState(false)
  const [loader, setLoader] = useState(true);
  const [paymentAccountList, setPaymentAccountList] = useState([]);
  const [due, setDue] = useState(0.00);
  const [paidAmount, setPaidAmount] = useState(0.00);
  const [selectedProductWarrantyList, setSelectedProductWarrantyList] = useState([]);
  const [deliveryAmount, setDeliveryAmount] = useState(0)
  const [isHandToHand, setisHandToHand] = useState(false);
  const [saveNoRedirect, setSaveNoRedirect] = useState(false);
  const [imeis, setImeis] = useState([]);
  const [subtotalPurchasePrice, setSubtotalPurchasePrice] = useState(0);  // Control subtotalPurchasePrice with state
  const [warrantyModal, setWarrantyModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showModalWholeSaler, setShowModalWholeSaler] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouse, setWarehouse] = useState("");
  const MAX_MOBILE_SCREEN_WIDTH = 767;
  // const [scannedImei,setScannedImei] = useState(0);
  const [scannedAmount,setScannedAmount] = useState(0);
  let subtotal = 0;
  // Calculate totals
  const token = localStorage.getItem("token");

  const getCountryList = () => {
    const page = 1;
    const perPage = 100;
    axios
    .get(
        `${process.env.REACT_APP_API_URL}get-countries?page=${page}&limit=${perPage}`,
        {
            headers: {
                authorization: `Bearer ${token} `,
                "Content-Type": "application/json",
            },
        }
    )
    .then((response) => {
        if(response.data.success)
        {
          console.log('resData ' + response.data.data)
          updateCountryList(response.data.data)
        }
    })
    .catch((error) => {
        console.log(error);
    });
  };

  const getWarehouseList = () => {
    axios
    .get(
      `${process.env.REACT_APP_API_URL}warehouses-list`,
      {
        headers: {
          authorization: `Bearer ${token} `,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success)
      {
        console.log('wareHouseData ' + response.data.data)
        setWarehouseList(response.data.data)
      }
  })
  .catch((error) => {
      console.log(error);
  });
  }

  useEffect(() => {
    if(warehouseList.length == 0 && type === "purchase"){
      if(hasPermission){
        getWarehouseList();
      }
    }
  },[warehouseList, type,hasPermission])

  useEffect(() => {
    if (orderList.length == 0) {
      setSubTotalNew(0);
      setGrandTotal(0);
      setGrandTotalDiscounted(0);
      setPaidAmount(0);
      setTotalMoreDiscount(0);
      setMoreDiscount(0);
      setDue(0);
      setMoney('')
      setDeliveryAmount(0)
      return;
    }
    let discountTotal = 0;
    let subtotaln = 0;
    orderList.forEach((orderFood) => {
      let discount = 0;
      if (type == 'purchase' || type == 'wholesale') {
        discount = 0;
      }
      else {
        if ((orderFood?.discount_type === 'Percentage' || orderFood?.discount_type === '2') && orderFood?.discount) {
          discount = formatPrice(orderFood?.retails_price * (orderFood?.discount / 100));
        } else {
          discount = orderFood?.discount;
        }
      }
      let subtotalPrice = 0;
      if (type == 'purchase') {
        if (orderFood.variantsSum) {
          subtotalPrice = orderFood.variants.reduce((s, item) => s += Number(item.purchase_price), 0);
        }
        else {
          subtotalPrice = formatPrice(orderFood?.qty * (orderFood?.purchase_price - discount));
        }
        subtotaln += Number(subtotalPrice);
      } else if (type == 'wholesale') {
        subtotalPrice = formatPrice(orderFood?.qty * (orderFood?.wholesale_price - discount));
        subtotaln += Number(subtotalPrice);
      } else {
        subtotalPrice = formatPrice(orderFood?.qty * (orderFood?.retails_price - discount));
        subtotaln += Number(subtotalPrice);
      }


      //discountTotal += formatPrice(orderFood.current_stock * discount);
    });

    setSubTotalNew(subtotaln);
    //const vats = formatPrice(0.15 * subtotal);
    //const taxes = formatPrice(0.1 * subtotal);
    let vats = vat && vat > 0 ? (vat / 100) * subtotaln : 0;
    let taxes = tax && tax > 0 ? (tax / 100) * subtotaln : 0;
    //const vats = 0;
    //const taxes = 0;
    if (type === "purchase") {
      vats = 0;
      taxes = 0;
    }
    const grandTotalnew = formatPrice(subtotaln + Number(vats) + Number(taxes));
    setGrandTotal(grandTotalnew);
    setGrandTotalDiscounted(formatPrice(Number(grandTotalnew) + Number(deliveryAmount) - Number(totalMoreDiscount)));
    // setGrandTotalDiscounted(formatPrice(Number(grandTotalnew) + Number(deliveryAmount)));
    // setPaidAmount(formatPrice(Number(grandTotalnew) - Number(totalMoreDiscount) - Number(due)));
    setDue((Number(grandTotalnew) + Number(deliveryAmount) - Number(paidAmount) - Number(totalMoreDiscount)))
  }, [orderList, totalMoreDiscount, paidAmount, deliveryAmount]);


  useEffect(() => {
    setDue(Number(grandTotalAfterDiscount) - paidAmount);
  }, [paidAmount])

  const notify = (type, msg) => {
    if (type == 'success') {
      toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
    if (type == 'error') {
      toast.error(msg, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
  }

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH
    ) {
      setIsMobile(true);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const handleResize = () => {
    if (
      typeof window !== 'undefined' &&
      document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const [showNavMenu, setShowNavMenu] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const [searchFilter, setSearchFilter] = useState({
    keyword: "",
    categoryId: false,
    subCategoryId: false,
    unitId: false,
    brandId: false,
    stockIn: false,
    stockOut: false,
    startDate: 0,
    endDate: new Date(),
  })

  const handleButtonClick = () => {
    console.log("Clicked");
    setShowNavMenu((prevShowNavMenu) => !prevShowNavMenu);
  };
  const handleClickOutside = (event) => {
    if (
      (menuRef.current && !menuRef.current.contains(event.target)) &&
      (buttonRef.current && !buttonRef.current.contains(event.target))
    ) {
      setShowNavMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleChangePaidAmount = value => {
    //if value is number then set the value
    if (!isNaN(value)) {
      // if (value <= Number(grandTotalAfterDiscount)) {
      console.log(value);
      if (value[0] == '0') {
        value = String(value).slice(1, value?.length)
        setPaidAmount(value);
      }
      else {
        if (value > Number(grandTotalAfterDiscount)) {
          return
        } else {
          setPaidAmount(value);
        }

      }
      setDue(Number(grandTotalAfterDiscount) - value);
      // }
      // else {
      //   return;
      // }
    } else {
      return;
    }
  };
  const handleChangeDeliveryAmount = value => {
    //if value is number then set the value
    if (!orderList.length) {
      showModalWarning("Please Select A product")
      return;
    }
    if (!(/[0-9.]/.test(String(value).slice(-1)))) {
      setDeliveryAmount(0)
      return;
    }
    const parseFlat = parseFloat(value)
    if (!isNaN(parseFlat) && parseFlat) {
      // if (value <= Number(grandTotalAfterDiscount)) {
      console.log(value);
      if (value[0] == '0') {
        value = String(value).slice(1, value?.length)
        setDeliveryAmount(value);
      }
      else {
        setDeliveryAmount(value)
      }
      // setGrandTotalDiscounted(Number(grandTotalAfterDiscount) + parseFloat(value));
      // }
      // else {
      //   return;
      // }
    } else {
      setDeliveryAmount(0)
    }
  };
  // useEffect(()=>{
  //   handleChangePaidAmount(paidAmount)
  // },[paidAmount])

  const handleChangeWarehouse = (e) => {
    e.preventDefault();
    setWarehouse(e.target.value);

  }

  const handleChangeDiscount = (value) => {
    // if not integer then return
    if (isNaN(value)) {
      setMoreDiscount();
      return;
    }
    if (selectedDiscountType === 'Percentage' || selectedDiscountType === '2') {
      // Check if the discount percentage is 100%
      if (value > 100) {
        // Set the discount equal to the grand total
        setTotalMoreDiscount(0);
        setMoreDiscount(0);
      } else {
        // Apply other percentage discount
        setTotalMoreDiscount(formatPrice(Number(grandTotal) * (value / 100)));
        setMoreDiscount(value);
      }
    } else {
      // If discount type is not percentage, set the discount directly
      //but discount should not be more than grand total
      if (value > Number(grandTotal)) {
        setTotalMoreDiscount(0);
        setMoreDiscount(0);
      } else {
        setTotalMoreDiscount(value);
        setMoreDiscount(value);
      }
    }
  };

  const handleChangeDiscountType = (e) => {
    const { name, value } = e.target;
    setSelectedDiscountType(value);
  };
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  //const navigate = useNavigate();

  const [money, setMoney] = useState("");


  let vats = 0;
  let taxes = 0;
  let discountTotal = 0;
  //let grandTotal = 0;
  const discountType = ["Fixed", "Percentage"];


  const [currentPage, setCurrentPage] = useState(1);
  const [selectedValue, setSelectedValue] = useState('A4');
  const [firstTime, setfirstTime] = useState(true);
  const perPage = 10;

  // const [prevSearchKeyword, setPrevSearchKeyword] = useState('');


  // product api ----------->>>>>
  const getTopSaleProductlist = (page) => {

    if (searchFilter.keyword ||
      searchFilter.brandId ||
      searchFilter.categoryId ||
      searchFilter.subCategoryId ||
      searchFilter.unitId ||
      searchFilter.stockIn ||
      searchFilter.stockOut) {
      // //setCurrentPage(1);
      // if (firstTime) {
      //   //loadingCallback(true);
      //   setfirstTime(false);
      // }
      // getFilteredProductlist(page, searchKeyword);
      // console.log("custom search");
      getFilteredProductlist2(page)

    } else {
      if (firstTime) {
        setLoader(true);
        //loadingCallback(true);
        setfirstTime(false);
      }
      // console.log("get data array");
      loadingCallback(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}top-sales?page=${page}&limit=${perPage}`, {
          headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          setLoader(false);
          loadingCallback(true);
          if (response.data.success) {
            loadingCallback(false);
            setFilterItem(response?.data?.data?.data);
            setFilteredProducts(response?.data?.data?.data);
            const produ = response?.data?.data?.data;
            produ?.map((valu) => {
              console.log(valu?.current_stock);
            })
            setPagination(response?.data?.data)
            setSelectedFood(response?.data?.data?.data[0]);
            if (response?.data?.data?.data[0]?.image_path) {
              setSelectedFoodImg(response?.data?.data?.data[0]?.image_path);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);

            navigate('/login');
          }
        });
    }

  };



  useEffect(() => {

    // Check if the search keyword has changed
    // const isSearchKeywordChanged = searchKeyword !== prevSearchKeyword;

    // Update the previous search keyword
    // setPrevSearchKeyword(searchKeyword);

    // If it's a new search keyword, set the page to 1
    if (searchKeyword) {
      setCurrentPage(1);
      // setfirstTime(true);
    } else {
      // It's the same search keyword, proceed with your logic
      if (firstTime) {
        setfirstTime(false);
      }
      if(hasPermission){
        getTopSaleProductlist(currentPage, searchKeyword);
      }
    }
  }, [currentPage, searchKeyword, firstTime,hasPermission]);


  useEffect(() => {
    // getProductData();
    const updateProduct = filterItem.filter((curElement) => {
      return curElement?.category_id === category;
    });
    //setFilteredProducts(updateProduct);

  }, [category, filterItem]);


  const [selectedPaymentAccountList, setSelectedPaymentAccountList] = useState([])
  const [filteredAccountList, setFilteredAccountList,] = useState({})

  const getPayment = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-list`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false)
          setPaymentList(response?.data?.data?.data);
        }
      })
      .catch((error) => {
        navigate('/login');
        console.log(error);
      });
  };

  useEffect(() => {
    if(hasPermission){
      getPayment();
      getWarranty();
      getPaymentAccountList();
      getDelivery();
      getDeliveryAccountList()
    }
  }, [currentPage, hasPermission])

  useEffect(() => {
      getDelivery()
      getDeliveryAccountList()
  }, [currentPage])



  useEffect(() => {
    if(showPaymentModal){
      getPaymentAccountList()
    }
  },[showPaymentModal])

  useEffect(() => {

      getWarranty()

  },[])


  // payment type category
  const getPaymentAccountList = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-category-list`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    )
      .then((response) => {
        if (response?.data.success) {
          loadingCallback(false)
          setPaymentAccountList(response?.data?.data?.data);
        }
      })
      .catch((error) => {
        navigate('/login');
        console.log(error);
      });
  };
 // payment type category  ends   ->>>>>

  const handleSelectChange = (event) => {
    setChange(true)
    setSelectedValue(event.target.value);
    setPaperSize(event.target.value);
  };

  const getFilteredProductlist2 = (page) => {
    const data = {
      keyword: searchFilter.keyword,
      categoryId: searchFilter.categoryId,
      subCategoryId: searchFilter.subCategoryId,
      unitId: searchFilter.unitId,
      brandId: searchFilter.brandId,
      stockIn: searchFilter.stockIn,
      stockOut: searchFilter.stockOut,
    }
    console.log('type',type)
    // check if data.keyword string length is greater than 2
    if(type === "purchase" && (data.brandId || data.unitId ? data.keyword.length >= 2 : data.keyword.length >= 3)){
      setLoader(true)
      const token = localStorage.getItem("token");
      axios
        .post(
          `${process.env.REACT_APP_API_URL}search-product-purchase?page=${page}&limit=${perPage}`,
          // { keyword: searchKeyword },
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          //loadingCallback(false);
          setLoader(false)

          if (response.status === 200) {
            console.log("Search Data", response.data);
            //setCategoryData(response.data.data.data);
            setFilterItem(response?.data?.data?.data);
            setFilteredProducts(response?.data?.data?.data);
            setPagination(response?.data?.data)
            setSelectedFood(response?.data?.data?.data[0]);
            if (response?.data?.data?.data[0]?.image_path) {
              setSelectedFoodImg(response?.data?.data?.data[0]?.image_path);
            }

          } else {
            console.log("Data Not Found!");
          }
        })
        .catch((error) => {
          loadingCallback(false);
          //setLoader(false)
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);
            navigate('/login');
          }
        });
    }
    else if ( type != "purchase" && (data.brandId || data.unitId ? data.keyword.length >= 2 : data.keyword.length >= 3)) {
      setLoader(true)
      const token = localStorage.getItem("token");
      axios
        .post(
          `${process.env.REACT_APP_API_URL}search-product?page=${page}&limit=${perPage}`,
          // { keyword: searchKeyword },
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          //loadingCallback(false);
          setLoader(false)

          if (response.status === 200) {
            // console.log("Search Data", response.data);
            //setCategoryData(response.data.data.data);
            setFilterItem(response?.data?.data?.data);
            setFilteredProducts(response?.data?.data?.data);
            setPagination(response?.data?.data)
            setSelectedFood(response?.data?.data?.data[0]);
            if (response?.data?.data?.data[0]?.image_path) {
              setSelectedFoodImg(response?.data?.data?.data[0]?.image_path);
            }

          } else {
            console.log("Data Not Found!");
          }
        })
        .catch((error) => {
          loadingCallback(false);
          //setLoader(false)
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);
            navigate('/login');
          }
        });
    }
  };


  useEffect(() => {
    // getFilteredProductlist2(currentPage);
    getFilteredProductlist2(currentPage);
    if(hasPermission){
      //getTopSaleProductlist(currentPage);
    }
  }, [searchFilter,hasPermission])

  const showModalWarning = (msg, data = null) => {
    Swal.fire({
      title: msg,
      text: "",
      icon: 'warning',
    });
  };


  const handleSelectedFood = (newItem, order = 'increment') => {
    // console.log("selected Product", item);
    const item = { ...newItem }

    console.log(newItem);
    // if(type != 'purchase' && (item?.stock_unit_no)===0 && isImeis ){
    //   console.log("true1");
    //   showModalWarning('This product is stockout!');
    //   return;
    // }
    if (item?.product_type != 'service' && type != 'purchase' && item?.is_variable_weight == 0 && (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0) {
      showModalWarning('This product is stockout!');
      return;
    }

    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item?.id
    );

    if (order == 'decrement') {
      // Item already exists, update its current_stock
      // for non-variable weight product
      if(item?.is_variable_weight == 0){
        const updatedOrderList = [...orderList];
        const parseQty = parseFloat(updatedOrderList[existingIndex].qty)
        if (parseQty <= 1) {
          handleDelete(existingIndex)
        } else {
          const initialVariationData = Array.from({ length: Number.parseInt(parseQty - 1) }, () => ({
            ...variationForm, product_id: item.id, purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
          }));
          updatedOrderList[existingIndex].variantsSum = 0;
          updatedOrderList[existingIndex].variants = initialVariationData;
          updatedOrderList[existingIndex].qty = parseQty - 1;
          setOrderList(updatedOrderList);
        }

      } else if(item?.is_variable_weight == 1){
        const updatedOrderList = [...orderList];
        const parseQty = parseFloat(updatedOrderList[existingIndex].qty)
        console.log('Reached');
        if (parseQty <= 0.1) {
          handleDelete(existingIndex)
        } else {
          const initialVariationData = Array.from({ length: Number.parseInt(parseQty > 1 ? parseQty - 1 : (parseQty - 0.1).toFixed(2)) }, () => ({
            ...variationForm, product_id: item.id, purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
          }));
          console.log(parseQty);
          updatedOrderList[existingIndex].variantsSum = 0;
          updatedOrderList[existingIndex].variants = initialVariationData;
          updatedOrderList[existingIndex].qty = parseQty > 1 ? parseQty - 1 : (parseQty - 0.1).toFixed(2);
          console.log(updatedOrderList)
          setOrderList(updatedOrderList);
        }
      }

    } else {

      //const current_stock = item.current_stock;
      if (existingIndex !== -1) {
        // Item already exists, update its current_stock
        if(item?.is_variable_weight == 0){
          const updatedOrderList = [...orderList];
          const parseQty = parseFloat(updatedOrderList[existingIndex].qty)
          if (item?.product_type != 'service' && type != 'purchase' && item.current_stock < parseQty + 1) {
            console.log("true2");
            showModalWarning('This product is stockout!', item);
            return;
          } else {
            const initialVariationData = Array.from({ length: Number.parseInt(parseQty + 1) }, () => ({
              ...variationForm, product_id: item.id, purchase_price: item.purchase_price,
              wholesale_price: item.wholesale_price,
              retails_price: item.retails_price,
            }));
            updatedOrderList[existingIndex].variantsSum = 0;
            updatedOrderList[existingIndex].variants = initialVariationData;
            updatedOrderList[existingIndex].qty = parseQty + 1;
            setOrderList(updatedOrderList);
          }
        }
        else if(item?.is_variable_weight == 1){
          const updatedOrderList = [...orderList];
          const parseQty = parseFloat(updatedOrderList[existingIndex].qty)
          if ( item?.product_type != 'service' && type != 'purchase' && item.current_stock < parseQty + 0.1) {
            console.log("true2");
            showModalWarning('This product is stockout!', item);
            return;
          } else {
            const initialVariationData = Array.from({ length: Number.parseInt(parseQty > 0.9 ? parseQty + 1 : (parseQty + 0.1).toFixed(2)) }, () => ({
              ...variationForm, product_id: item.id, purchase_price: item.purchase_price,
              wholesale_price: item.wholesale_price,
              retails_price: item.retails_price,
            }));
            updatedOrderList[existingIndex].variantsSum = 0;
            updatedOrderList[existingIndex].variants = initialVariationData;
            updatedOrderList[existingIndex].qty = parseQty > 0.9 ? parseQty + 1 : (parseQty + 0.1).toFixed(2);
            setOrderList(updatedOrderList);
        }
      }


      } else {
        item.qty = 1;
        // Item doesn't exist, add it to the orderList with current_stock 1
        const initialVariationData = Array.from({ length: Number.parseInt(1) }, () => ({
          ...variationForm, product_id: item.id, purchase_price: item.purchase_price,
          wholesale_price: item.wholesale_price,
          retails_price: item.retails_price,
        }));
        item.variants = initialVariationData;
        item.variantsSum = 0;
        let newOrderArr = [...orderList, item];
        setOrderList(newOrderArr);

        const value = {
          id: item?.id,
          warranty: [
            {
              id: '',
              product_id: item?.id,
              warranty_id: "",
              warranties_count: item.warranties_count ? item.warranties_count : "",
              name: item.warrenty ? item.warrenty : ""
            },
          ]
        }

        // if (item?.warranties_count !== null && item?.warrenty !== null) {
        if (selectedProductWarrantyList && selectedProductWarrantyList?.length) {
          const newWarrantyList = [...selectedProductWarrantyList, value];
          setSelectedProductWarrantyList(newWarrantyList);
        } else {
          setSelectedProductWarrantyList([value]);
        }
        //   console.log("warranty1", value);
        //   setSelectedProductWarrantyList([value]);
        // }
        // else{
        //   console.log("warranty2", value);
        // }

      }
      //item.current_stock -= 1;
      setSelectedFood(item);
      if (item?.image_path) {
        setSelectedFoodImg(item.image_path);
      }
    }

  };

  const handleSelectedFoodForImei = (newItem, order = 'increment') => {
    // console.log("selected Product", item);
    const item = { ...newItem }

    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.imei_id === item?.imei_id
    );

    if (order == 'decrement') {
      // Item already exists, update its current_stock
      const updatedOrderList = [...orderList];
      const parseQty = parseFloat(updatedOrderList[existingIndex].qty)
      if (parseQty <= 1) {
        handleDelete(existingIndex)
      } else {
        const initialVariationData = Array.from({ length: Number.parseInt(parseQty - 1) }, () => ({
          ...variationForm, product_id: item.id, purchase_price: item.purchase_price,
          wholesale_price: item.wholesale_price,
          retails_price: item.retails_price,
          storage: item.storage,
          ram: item.ram,
          battery_life: item.battery_life
        }));
        updatedOrderList[existingIndex].variantsSum = 0;
        updatedOrderList[existingIndex].variants = initialVariationData;
        updatedOrderList[existingIndex].qty = parseQty - 1;
        setOrderList(updatedOrderList);
      }

    } else {

      //const current_stock = item.current_stock;
      if (existingIndex !== -1) {
        // Item already exists, update its current_stock
        const updatedOrderList = [...orderList];
        const parseQty = parseFloat(updatedOrderList[existingIndex].qty)
        if (item?.product_type != 'service' && type != 'purchase' && item.stock_unit_no < parseQty + 1) {
          showModalWarning('This product is stockout!', item);
          return;
        } else {
          const initialVariationData = Array.from({ length: Number.parseInt(parseQty + 1) }, () => ({
            ...variationForm, product_id: item.id, purchase_price: item.purchase_price,
            wholesale_price: item.wholesale_price,
            retails_price: item.retails_price,
            storage: item.storage,
            ram: item.ram,
            battery_life: item.battery_life
          }));
          updatedOrderList[existingIndex].variantsSum = 0;
          updatedOrderList[existingIndex].variants = initialVariationData;
          updatedOrderList[existingIndex].qty = parseQty + 1;
          setOrderList(updatedOrderList);
        }
      } else {
        item.qty = 1;
        // Item doesn't exist, add it to the orderList with current_stock 1
        const initialVariationData = Array.from({ length: Number.parseInt(1) }, () => ({
          ...variationForm, product_id: item.id, purchase_price: item.purchase_price,
          wholesale_price: item.wholesale_price,
          retails_price: item.retails_price,
        }));
        item.variants = initialVariationData;
        item.variantsSum = 0;
        let newOrderArr = [...orderList, item];
        setOrderList(newOrderArr);

        const value = {
          id: item?.id,
          warranty: [
            {
              id: '',
              product_id: item?.id,
              warranty_id: "",
              warranties_count: item.warranties_count ? item.warranties_count : "",
              name: item.warrenty ? item.warrenty : ""
            },
          ]
        }

        // if (item?.warranties_count !== null && item?.warrenty !== null) {
        if (selectedProductWarrantyList && selectedProductWarrantyList?.length) {
          const newWarrantyList = [...selectedProductWarrantyList, value];
          setSelectedProductWarrantyList(newWarrantyList);
        } else {
          setSelectedProductWarrantyList([value]);
        }
        //   console.log("warranty1", value);
        //   setSelectedProductWarrantyList([value]);
        // }
        // else{
        //   console.log("warranty2", value);
        // }

      }
      //item.current_stock -= 1;
      setSelectedFood(item);
      if (item?.image_path) {
        setSelectedFoodImg(item.image_path);
      }
    }

  };
  const [selectedFoodImg, setSelectedFoodImg] = useState(null);
  const handleSelectedFoodImage = (imgPath = '') => {
    setSelectedFoodImg(imgPath);
  };

  const handleSelectedFoodQtyHoverMove = (item, e) => {
    console.log("function call",);
    if ((e.target.value).slice(-1) == '.') {
      const existingIndex = orderList.findIndex(
        (orderItem) => orderItem?.id === item?.id
      );
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].qty = 0;
      setOrderList(updatedOrderList);
    }
  }
  const variationForm = {
    product_id: "",
    name: "",
    serial: "",
    purchase_price: 0,
    wholesale_price: 0,
    retails_price: 0,
    discount_type: 0,
    storage: "",
    ram:"",
    battery_life:"",
    quantity: 1,
  }

  const handleSelectedFoodQty = (item, e) => {
    console.log("selected item", item);
    // const qty = e.target.value.startsWith('x') ? e.target.value.slice(1) : e.target.value;
    // const parsedQty = parseInt(qty, 10);
    // const existingIndex = orderList.findIndex(
    //   (orderItem) => orderItem?.id === item.id
    // );
    // if (qty && parsedQty && existingIndex !== -1 && !isNaN(parsedQty)) {

    //   const existingProductIndex = filteredProducts.filter(
    //     (filteredItem) => filteredItem?.id === item?.id
    //   );
    //   // Item already exists, update its current_stock
    //   const updatedOrderList = [...orderList];
    //   if (type != 'purchase' && existingProductIndex && existingProductIndex[0] && existingProductIndex[0].current_stock < parsedQty) {
    //     showModalWarning('This product is stockout!', item);
    //     return;
    //   } else {
    //     updatedOrderList[existingIndex].qty = parsedQty;
    //     setOrderList(updatedOrderList);
    //   }

    // } else {

    //   const updatedOrderList = [...orderList];
    //   updatedOrderList[existingIndex].qty = 0;
    //   setOrderList(updatedOrderList);
    // }
    // console.log("quantity updated");
    const qty = e.target.value.startsWith('x') ? e.target.value.slice(1) : e.target.value;
    const parsedQty = parseFloat(qty);
    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item.id
    );
    if (qty[0] == '0' && qty.length == 2 && qty[1] != '.') {

      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].qty = parseFloat(qty)

      const initialVariationData = Array.from({ length: Number.parseFloat(qty) }, () => ({
        ...variationForm, product_id: item.id, purchase_price: item.purchase_price,
        wholesale_price: item.wholesale_price,
        retails_price: item.retails_price,
      }));
      // updatedOrderList[existingIndex].qty = qty;

      updatedOrderList[existingIndex].variants = initialVariationData;
      // console.log("increate data", updatedOrderList);
      setOrderList(updatedOrderList);
    }
    else if (qty && existingIndex !== -1 && !isNaN(qty)) {
      const existingProductIndex = filteredProducts.filter(
        (filteredItem) => filteredItem?.id === item?.id
      );
      // Item already exists, update its current_stock
      const updatedOrderList = [...orderList];
      // if (type != 'purchase' && existingProductIndex && existingProductIndex[0] && existingProductIndex[0].current_stock < qty) {
      if (item?.product_type != 'service' && type != 'purchase' && existingProductIndex && existingProductIndex[0] && existingProductIndex[0].current_stock < qty) {
        showModalWarning('This product is stockout!', item);
        return;
      } else {
        //console.log("inside 1 " + qty)
        const initialVariationData = Array.from({ length: qty/* Number.parseInt(qty) */ }, () => ({
          ...variationForm, product_id: item.id, purchase_price: item.purchase_price,
          wholesale_price: item.wholesale_price,
          retails_price: item.retails_price,
        }));
        //console.log("inside 2 " + qty)
        updatedOrderList[existingIndex].qty = qty/* parseInt(qty) */;
        updatedOrderList[existingIndex].variants = initialVariationData;
        //console.log("increate data", updatedOrderList);
        //console.log("inside 3 " + qty)

        setOrderList(updatedOrderList);
        //console.log("inside 4 " + qty)
        //console.log("update " +updatedOrderList[existingIndex].qty);
      }
    } else {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].qty = 0;
      updatedOrderList[existingIndex].variants = [];
      setOrderList(updatedOrderList);
    }
  };
  //console.log(orderList)

  const handleSelectedFoodPrice = (item, price, idx) => {
  //const newPriceStr = String(price);
    const newPriceStr = price;
    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item?.id
    )
    if(newPriceStr.slice(-1)=='.'){
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].purchase_price = newPriceStr;
      setOrderList(updatedOrderList);
      return;
    }
    // const newPrice = parseFloat(newPriceStr) / parseInt(item.qty);
    // Skip parsing while typing; only process full numbers or decimals
    const newPrice = newPriceStr ? newPriceStr / item.qty : 0; //const newPrice = newPriceStr ? parseFloat(newPriceStr) / parseInt(item.qty) : 0;
    if (price && newPrice && existingIndex !== -1 && !isNaN(newPrice)) { //
      const updatedOrderList = [...orderList];
      if (updatedOrderList[idx].variantsSum) {
        updatedOrderList[idx].variantsSum = 0;
      }
      // Only update when there is a valid number input
      updatedOrderList[idx].purchase_price = !isNaN(newPrice) ? newPrice : 0; //!isNaN(newPrice) ? newPrice : 0
      console.log('updatedOrderList ', updatedOrderList[idx].purchase_price);
      setSubtotalPurchasePrice(updatedOrderList[idx].purchase_price);
      setOrderList(updatedOrderList);
    } else {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].purchase_price = 0;
      setOrderList(updatedOrderList);
      setSubtotalPurchasePrice(0);
    }

  }

  // Function to select the whole input value on focus
  const handleFocus = (event) => {
    event.target.select();
  };

  const handleSelectedFoodPriceBilling = (item, price, idx,type) => {

    //const newPriceStr = String(price)
    const newPriceStr = price;
    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === item?.id
    )
    // if(newPriceStr.slice(-1)=='.'){
    //   const updatedOrderList = [...orderList];
    //   updatedOrderList[existingIndex].purchase_price = newPriceStr;
    //   setOrderList(updatedOrderList);
    //   return;
    // }
    // console.log("orderList", idx,price,item);
    const newPrice = newPriceStr / item.qty;// const newPrice = parseFloat(newPriceStr) / parseInt(item.qty);

    if(type === 'wholesale'){
      if (price && newPrice && existingIndex !== -1 && !isNaN(newPrice)) {
        const updatedOrderList = [...orderList];
        if (updatedOrderList[idx].variantsSum) {
          updatedOrderList[idx].variantsSum = 0;
        }
        updatedOrderList[idx].wholesale_price = (newPrice) + (item.discount);
        setOrderList(updatedOrderList);
      } else {
        const updatedOrderList = [...orderList];
        updatedOrderList[existingIndex].wholesale_price = (0) + (item.discount);
        setOrderList(updatedOrderList);
      }
    }


    if (price && newPrice && existingIndex !== -1 && !isNaN(newPrice)) {
      const updatedOrderList = [...orderList];
      if (updatedOrderList[idx].variantsSum) {
        updatedOrderList[idx].variantsSum = 0;
      }
      updatedOrderList[idx].retails_price = (newPrice) + (item.discount);
      setOrderList(updatedOrderList);
    } else {
      const updatedOrderList = [...orderList];
      updatedOrderList[existingIndex].retails_price = (0) + (item.discount);
      setOrderList(updatedOrderList);
    }
  }

  // useEffect(()=>{
  //   console.log("order product", orderList);
  // },[orderList])

  const [paymentData, setPaymentData] = useState([]);

  const handleDeliveryClicks = () => {
    if (!orderList.length) {
      showModalWarning("Please Select A product")
      return;
    }
  }

  useEffect(()=>{

  },[orderList])

  const handleClicks = (data) => {
    setIsRender(true);
    console.log('orderList ',orderList)
    if (type == 'purchase' && orderList?.every(order =>
      order.have_variant == 1 &&
      order.variants?.every(variation => variation.serial == "")
    )) {
      showModalWarning("Please Enter Variation Information for Variation Product!");
      return;
}
    if (!orderList.length) {
      showModalWarning("Please Select A product")
      return;
    }
    /* name: "",
    method_id: 0,
    account_id: 0,
    account_number: "",
    methodLabel: "",
    accountLabel: "",
    type_name: "", */
    // else{
    setShowPaymentModal(!showPaymentModal);
    setPaidAmount(0)
    console.log("paydata ", paymentData)
    console.log('data '+ JSON.stringify(data))
    let val = paymentList.find((items) => { return items?.type_name == data?.type_name })
    setFilteredAccountList(val.payment_type_category)
    if (val) {
      setFormData({
        ...formData,
        payment_id: data?.id,
        account_id: data?.payment_type_category[0].id,
        account_number: data?.payment_type_category[0].account_number
      });
      setMoney(data?.type_name);
    }
    if (showPaymentModal) {
      setPaymentData((prevPaymentData) => [...prevPaymentData, data]);

      // setSelectedPaymentAccountList([]);
    } else {
      setPaymentData([data]);
    }
    // }
  };

  const handlePurchesClicks = (active) => {
    if (active === "cash" || active === "card" || active === "qr") {
      return setMoney(active);
    }
  };

  const handleDelete = async (index) => {
    // console.log(index);
    // const findImei = imeis.find( e => e.product_id===orderList[index].id);
    // if(findImei) {
    const newImeis = imeis.filter((e) => e.product_id !== orderList[index].id);
    setImeis(newImeis)
    // }

    const newOrderList = [...orderList];
    newOrderList.splice(index, 1); // Remove the item from the copied array
    setOrderList(newOrderList);

    const newWarrantyList = [...selectedProductWarrantyList];
    newWarrantyList.splice(index, 1);
    setSelectedProductWarrantyList(newWarrantyList);


  };
  //  const handleDelete=(index)=>{
  //   console.log(index)
  //   const newOrderList = [...orderList];
  //   newOrderList.splice(index, 1); // Remove the item from the copied array
  //   setOrderList(newOrderList);
  //  }

  // Calculate the number of products to show in the carousel
  const numberOfProducts = Math.min(filteredProducts.length - 2, 5); // Set a maximum number of 7 products


  let settings = {};

  if (isMobile) {
    settings = {
      className: "sliderArea w-[300px]",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1, // Adjust slidesToShow based on the number of products
      slidesToScroll: 1, // Adjust slidesToScroll as well
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  } else {
    settings = {
      //className: "sliderArea w-[920px]",
      className:
        "sliderArea md:max-w-[550px] lg:max-w-[600px] 2xl:max-w-[800px]",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2, // Adjust slidesToShow based on the number of products
      slidesToScroll: 3, // Adjust slidesToScroll as well
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  }

  const [deliveryFormData, setDeliveryFormData] = useState({
    deliveryName: '',
    deliveryId: '',
    companyName: '',
    companyId: '',
    customerName: '',
    customerAddress: '',
    customerPhone: '',
    deliveryFee: '',
  })
  // const [isHold, setIsHold] = useState(false);
  // const paymentDetailsMap = {
  //   payment_type_id: "",
  //   payment_type_category_id: "",
  //   payment_amount: ""
  // }

  const [formData, setFormData] = useState({
    name: "",
    method_id: 0,
    account_id: 0,
    account_number: "",
    methodLabel: "",
    accountLabel: "",
    type_name: "",
  });

  // const [paymentMap, setPaymentMap] = useState([])
  const handleSubmitOrder = async (value, isSave = false) => {
    console.log("status Data", status, type, value);
    if (change === false) {
      setSelectedValue('A4');
      setPaperSize('A4');
    }
    //const { pay_mode, paid_amount, sub_total } = isSave ? values : values.target.elements;
    // {
    //         "product_id": 1,
    //         "qty": 1,
    //         "price": 1,
    //         "mode": 1,
    //         "size": 1,
    //         "sales_id": 1
    //     },
    // Transform orderList array
    let transformedProductArray = orderList.map(item => ({
      product_id: item?.id,
      qty: item.qty,
      price:
        type === "purchase"
          ? item?.purchase_price
          : type === "wholesale"
            ? item.wholesale_price
            : item?.discount_type === "Percentage" || item?.discount_type === "2"
              ? item.retails_price - (item?.retails_price * (item.discount / 100))
              : item?.retails_price - item?.discount,
      mode: 1,
      size: 1,
      sales_id: 1,
      imei_id: item?.imei_id ? item?.imei_id : null
    }));
    let totalPrice = transformedProductArray.reduce((total, item) => {
      // Calculate the total price for each item
      const itemTotalPrice = item.qty * item.price;

      // Add the item's total price to the overall total
      return total + itemTotalPrice;
    }, 0);
    const vatsN = vat && vat > 0 ? (vat / 100) * totalPrice : 0;
    const taxN = tax && tax > 0 ? (tax / 100) * totalPrice : 0;
    const sub_total_c = totalPrice - Number(totalMoreDiscount) + vatsN + taxN;

    const paymentMap = selectedPaymentAccountList.map(item => ({
      payment_type_id: item?.payment_type_id,
      payment_type_category_id: item?.payment_type_category_id,
      payment_amount: item?.Amount,
    }));

    const defaultWarrantyList = selectedProductWarrantyList?.flatMap((item) => {
      // const { id, warranty } = item;
      return item?.warranty?.map((warrantyItem) => ({
        product_id: item?.id,
        warranty_id: warrantyItem?.id,
        default_warranties_count: warrantyItem?.warranties_count,
      }));
    });

    if (!selectedPaymentAccountList.length) {
      showModalWarning("Select At-least one payment method!")
      return;
    }

    let variationsData = [];
    const uniqueProduct = new Set();
    if (type == 'purchase') {
      variationsData = orderList.flatMap((item) => {
        return item.variants
          .filter((t) => t.serial.length)
          .map((t) => ({
            product_id: t.product_id,
            name: t.name,
            serial: t.serial,
            purchase_price: t.purchase_price,
            wholesale_price: t.wholesale_price,
            retails_price: t.retails_price,
            storage: t.storage,
            ram: t.ram,
            battery_life: t.battery_life,
            discount_type: 0,
            quantity: 1,
          }));
      });
      variationsData.map((item) => {
        if (!uniqueProduct.has(item.product_id)) {
          uniqueProduct.add(item.product_id);
        }
      })

      transformedProductArray = transformedProductArray.filter((item) => !uniqueProduct.has(item.product_id));
      totalPrice = transformedProductArray.reduce((total, item) => {
        const itemTotalPrice = item.qty * item.price;
        return total + itemTotalPrice;
      }, 0);
      totalPrice += variationsData.reduce((total, item) => {
        return total + Number(item.purchase_price);
      }, 0);
    }
    if (type == 'billing') {
      const uniqueProductIds = new Set();
/*       transformedProductArray = transformedProductArray.filter(item => {
        if (!uniqueProductIds.has(item.product_id)) {
          uniqueProductIds.add(item.product_id);
          return true;
        }
        return false;
      }); */

      transformedProductArray = transformedProductArray.filter(item => {
        // If item has imei_id, always include it, even if product_id already exists
        if (item.imei_id) {
          return true;
        }

        // Otherwise, check if the product_id already exists in the Set
        if (!uniqueProductIds.has(item.product_id)) {
          uniqueProductIds.add(item.product_id);
          return true;
        }

        // If product_id exists and item does not have imei_id, filter it out
        return false;
      });
    }
    const transformImeis = imeis.map((e) => e.id);

    const data = {
      pay_mode: money,
      paid_amount: Number(paidAmount),
      //sub_total: sub_total_c,
      sub_total: totalPrice,
      vat: vat && vat > 0 ? Number(formatNumber((vat / 100) * Number(totalPrice))) : 0,
      tax: tax && tax > 0 ? Number(formatNumber((tax / 100) * Number(totalPrice))) : 0,
      discount: Number(totalMoreDiscount),
      product: transformedProductArray,
      delivery_method_id: deliveryFormData?.deliveryId,
      delivery_info_id: deliveryFormData?.companyId,
      delivery_customer_name: deliveryFormData?.customerName,
      delivery_customer_address: deliveryFormData?.customerAddress,
      delivery_customer_phone: deliveryFormData?.customerPhone,
      delivery_fee: deliveryAmount,
      payment_method: paymentMap,
      warranty: defaultWarrantyList,
      variants: variationsData,
      imeis: transformImeis,
      created_at:transactionDate,
    }
    // console.log("default WarrantyList:", defaultWarrantyList);
    if (type === 'purchase') {
      data.vendor_id = vendor?.id;
      data.vendor_name = vendor.name;
      data.vat = 0;
      data.tax = 0;
      data.warehouse_id = warehouse;
      //data.sales_id = seller?.id ? seller?.id : currentUser.id;
    }
    else {
      if (type === 'wholesale') {
        data.wholeseller_id = wholeSaler ? wholeSaler?.id : null;
        data.wholeseller_name = wholeSaler ? wholeSaler?.name : null;
      }
      else {
        data.customer_id = customer ? customer?.id : null;
        data.customer_name = customer ? customer?.name : null;
      }
      if (currentUser) {
        data.sales_id = seller?.id ? seller?.id : currentUser.id;
      } else {
        data.sales_id = seller?.id ? seller?.id : null;
      }
    }
    if (type == 'billing' || type == 'wholesale') {
      data.status = value;
    }
    if (type == 'wholesale') {
      data.sales_type = 'wholesale';
    }
    // console.log("============");
    // console.log(data);
    // console.log(transformedProductArray);
    // return false;

    //if type is purchase then axios post to save-purchase else save-sales
    const url = type === 'purchase' ? 'save-purchase' : type === 'booking' ? 'save-advance-sales' : 'save-sales';
    setDeliveryMode(deliveryFormData.deliveryName)
    loadingCallback(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}${url}`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          // setInvoice(response.data.data);
          console.log(response.data.data);
          setOrderList([]);
          if (type === 'purchase') {
            navigate(`/purchase-invoice/${response.data.data.invoice_id}`);
          }
          else if (type === 'booking') {
            if (value) {
              navigate(`/advance-bookings/${response.data.data.invoice_id}`);
            }
            else {
              notify('success', "Order submitted successfully.Go to booking list to update");
            }
          }
          else {
            if (isSave === false && value === 1) {
              if (type === "wholesale") {
                navigate(`/wholesale-invoice/${response.data.data.invoice_id}`);
              }
              else {
                if (type === "booking" && saveNoRedirect && saveNoRedirect === 1) {
                  notify('success', "Order submitted successfully.Go to booking list to update");
                } else {
                  navigate(`/invoice/${response.data.data.invoice_id}`);
                }
              }
            }
            else {
              console.log("Hold", isSave, status, deliveryFormData, type);
              setStatus(1);
              if (type === 'wholesale') {
                setWholeSaler(null)
              }
              else {
                setCustomer(null)
              }
              setSeller(null)
              if (type === "booking") {
                // notify('success', 'Order submitted successfully.Go to booking list to update')
                FlashMessage('Order submitted successfully.Go to booking list to update', 'success');
                // setSuccessFlashMessage("Order submitted successfully.Go to booking list to update");
              }
              else {
                if (type === "wholesale") {
                  // notify('success', 'Order submitted successfully.Go to hold wholesale invoice list to update')
                  FlashMessage('Order submitted successfully.Go to hold wholesale invoice list to update', 'success');
                  // setSuccessFlashMessage("Order submitted successfully.Go to hold wholesale invoice list to update");
                }
                else {
                  // notify('success', 'Order submitted successfully.Go to hold list to update')
                  FlashMessage('Order submitted successfully.Go to hold list to update', 'success');
                  window.location.reload();
                  // setSuccessFlashMessage("Order submitted successfully.Go to hold list to update");
                }
              }
            }
          }
        }
        else {
          if (response.data.message) {
            // setErrorFlashMessage('something went wrong!');
            FlashMessage('something went wrong!', 'error');
          }
          if (response.data.errors) {
            // setErrorFlashMessage(response.data.errors?.customer_id[0]);
            FlashMessage(response.data.errors?.customer_id[0], 'error');
          }
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  };
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const shouldRenderPagination = pagination.total > perPage;
  const [showQuickProductAddForm, setShowQuickProductAddForm] = useState(false);
  const handleQuickProductFormClick = () => {
    setShowQuickProductAddForm(!showQuickProductAddForm);
  };

  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => {
    setShowModal(!showModal);
    getCountryList();
  }

  const handleOpenWholesaler = () => setShowModalWholeSaler(!showModalWholeSaler);
  const [showModalSeller, setShowModalSeller] = useState(false);
  const handleOpenSeller = () => setShowModalSeller(!showModalSeller);
  const [showModalVendor, setShowModalVendor] = useState(false);
  const handleOpenVendor = () => setShowModalVendor(!showModalVendor);
  const handleOpenPayment = () => setShowPaymentModal(!showPaymentModal);


  // Gneral Focus Hook
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
  }

  const [inputRef, setInputFocus] = useFocus();
  const [filteredCustomers, setFilteredCustomers] = useState(state.customerList);
  const [filteredWholeSaler, setFilteredWholeSaler] = useState(state.wholeSalerList);
  const eclipse = (str, length) => {
    return str && str.length > 0 && str.length > length ? str.substring(0, length) + "..." : str;
  }
  const modifiedFilteredProducts = [...filteredProducts];
  const filteredWithoutSelected = modifiedFilteredProducts.filter(
    (item) => item?.id !== selectedFood?.id
  );
  filteredWithoutSelected.unshift(selectedFood);
  useEffect(() => {
    if(hasPermission && isRender){
      getSellerListData();
    }
  }, [hasPermission, isRender]);

  const [filteredSeller, setFilteredSeller] = useState([{ 'id': 1 }]);
  const [showSeller, setShowSeller] = useState(false);


  // employee list api
  const getSellerListData = async (searchKeyword = '') => {
    //loadingCallback(true);
    setFilteredSeller([]);
    const page1 = 1;

    const options = [{ id: '', name: 'Select' }];
    axios
      .get(
        `${process.env.REACT_APP_API_URL}employee`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          const sellers = response.data.data.data;
          // Append other options if there are sellers
          if (sellers.length > 0) {
            const sellerOptions = sellers.map((seller) => ({
              id: seller?.id,
              employee_type: seller.employee_type,
              name: seller.name,
              email: seller.email,
              country_code: seller.country_code,
              mobile_number: seller.mobile_number,
              address: seller.address,
              country: seller.country,
              district: seller.district,
              city: seller.city,
              zipcode: seller.zipcode,
              nid: seller.nid,
              joining_date: seller.joining_date,
              blood_group_id: seller.blood_group_id,
              emergency_contact_name: seller.emergency_contact_name,
              emergency_country_code: seller.emergency_country_code,
              emergency_mobile_numbers: seller.emergency_mobile_numbers,
              relation_with: seller.relation_with,
              status: seller.status,
            }));
            options.push(...sellerOptions);
            setShowSeller(true);
          } else {
            setSeller(currentUser ? currentUser?.id : null);
          }
        } else {
          console.log("Data Not Found!");
        }
        setFilteredSeller(options);
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });

  };

   // employee list api  ----->


  const minWidth = 80; // Set a reasonable minimum width
  const inputStyle = {
    width: `${Math.max(paidAmount.length * 10 + 30, minWidth)}px`,
  };



  const [barcodeRead, setBarcodeRead] = useState("");


  const handleScan = (data) => {
      if (data) {
      // Immediately send the scanned data in the request
      axios.post(
        `${process.env.REACT_APP_API_URL}search-product?page=${1}&limit=${10}`,
         {'keyword':data} ,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
          const items = res?.data?.data?.data;
            items.map(item => {
              if(item.imeis.length > 0){
                const productImeis = item.imeis;
                const thisProduct =  productImeis.find(item => item.imei == data);
                console.log(thisProduct);
                handleSelectedImeis(item);
                handleImeisSelect(thisProduct,item);
                setBarcodeImei(thisProduct.imei);
              }else{
                handleSelectedFood(item);
              }
            })


          // console.log("Product data:", res.data);
      })
      .catch(err => {
          console.error("Error fetching product:", err);
      });
    }
  };


  // const barcodeCatch = () => {

    // console.log(selectedImeis);
  // }

  //  document.addEventListener('keydown', (event) => {
  //   const imeis = [];
  //   if(event.key){
  //     [...imeis,event.key]
  //   }
  //   console.log(imeis);
  // })

  const handleError = (error) => {
    console.log(error);
    setBarcodeRead(error);
  };

  const handleBarCodeSearch = () => {

  }
  const fetchProductData = (productId) => {
    //loadingCallback(true);
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    get("product-details/" + productId, token)
      .then((response) => {
        const productData = response.data; // Replace with your API response data

        loadingCallback(false);
        //setProductData(productData);
        handleSelectedFood(productData);
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
          // Navigate to the '/login' route
        }
      });
  };

  const [methodIndex, setMethodIndex] = useState(null)

  useEffect(() => {
    if (barcodeRead.length > 0) {
      fetchProductData(barcodeRead);
    }
  }, [barcodeRead]);

  // useEffect(() => {
  //   if (status===0) {
  //     handleSubmitOrder();
  //   }
  // }, [status]);






  const handleChangeSelect = (name, option) => {
    const { value, label } = option;  //some error in this code.
    setDeliveryFormData({
      ...deliveryFormData,
      deliveryId: value,
      deliveryName: label,
    })
  }

  useEffect(() => {

    if (deliveryList?.length) {
      console.log(deliveryList);
      let selectInitial = deliveryList.find((item) => ((item?.type_name).toLowerCase() == 'hand-to-hand'))
      setDeliveryFormData({
        ...deliveryFormData,
        deliveryId: selectInitial?.id,
        deliveryName: selectInitial?.type_name,
      })
    }
  }, [deliveryList])

  // delivery method api
  const getDelivery = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}delivery-method-list`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false)
          setDeliveryList(response.data.data.data)
        }
      })
      .catch((error) => {
        navigate('/login');
        console.log(error);
      });
  };
  // delivery method api ----->

  // delivery-info api
  const getDeliveryAccountList = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}delivery-info-list`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false)
          setDeliveryAccountList(response.data.data.data)
        }
      })
      .catch((error) => {
        navigate('/login');
        console.log(error);
      });
  };
   // delivery-info api ------> ends

  // warranty api
  const getWarranty = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}get-warranty-list`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false)
          setWarrantyList(response.data.data.data)
          updateWarrantyList(response.data.data)
          // setSelectedProductWarrantyList(response.data.data.data)
        }
      })
      .catch((error) => {
        navigate('/login');
        console.log(error);
      });
  };
  //  warranty api endsssss --->

  const [productVariation, setProductVariation] = useState(false);
  const [warrantyList, setWarrantyList] = useState([]);
  const [barcodeImei,setBarcodeImei] = useState('');

  const [selectedItem, setSelectedItem] = useState({});

  const handleImeisSelect = (imei, selectedFood) => {
    // console.log(imei.imei);
    setBarcodeImei(imei.imei);
    const newItems = { ...selectedFood }
    newItems.checked = 1;

    // console.log("selectPrime", newItems, imei);
    //newItems.retails_price = imei.sale_price;

    // if(type === 'wholesale'){
    //   newItems.retails_price = imei?.currency_rate !== null
    //   ? Number.parseFloat(
    //     imei?.wholesale_price
    //   ).toFixed(2)
    //   : Number.parseFloat(
    //     imei?.wholesale_price
    //   ).toFixed(2);
    // newItems.currency_code = imei?.currency_rate !== null ? imei?.base_currency_code : imei.purchase_currency_code;
    // newItems.imei_id = imei.id;

    // const findImei = imeis.find(e => e.id === imei.id);
    // if (findImei) {
    //   handleSelectedFoodForImei(newItems, "decrement", 1);
    //   const newImeis = imeis.filter(e => e.id !== imei.id);
    //   // const transformArray =  newImeis.map( e=>({ id: e.id, product_id: e.product_id}))
    //   setImeis(newImeis)
    // }
    // else {
    //   handleSelectedFoodForImei(newItems, "increment", 1);
    //   setImeis(e => [...e, { id: imei.id, product_id: imei.product_id }]);
    // }
    // }
    newItems.retails_price = imei?.currency_rate !== null
      ? Number.parseFloat(
        imei?.sale_price
      ).toFixed(2)
      : Number.parseFloat(
        imei?.sale_price
      ).toFixed(2);
    newItems.currency_code = imei?.currency_rate !== null ? imei?.base_currency_code : imei.purchase_currency_code;
    //newItems.purchase_price = imei.purchase_price;

    newItems.purchase_price = imei?.currency_rate !== null
      ? Number.parseFloat(
        imei?.purchase_price
      ).toFixed(2)
      : Number.parseFloat(
        imei?.purchase_price
      ).toFixed(2);
    ;
    newItems.wholesale_price = imei?.currency_rate !== null
      ? Number.parseFloat(
        imei?.wholesale_price
      ).toFixed(2)
      : Number.parseFloat(
        imei?.wholesale_price
      ).toFixed(2);
    ;
    newItems.imei_id = imei.id;
    const findImei = imeis.find(e => e.id === imei.id);
    if (findImei) {
      handleSelectedFoodForImei(newItems, "decrement", 1);
      const newImeis = imeis.filter(e => e.id !== imei.id);
      // const transformArray =  newImeis.map( e=>({ id: e.id, product_id: e.product_id}))
      setImeis(newImeis)
    }
    else {
      handleSelectedFoodForImei(newItems, "increment", 1);
      setImeis(e => [...e, { id: imei.id, product_id: imei.product_id }]);
    }
  }


  const handleSelectedImeis = (item) => {
    console.log(item);
    setSelectedFood(item);
    if (item?.image_path) {
      setSelectedFoodImg(item.image_path);
    }
  }

  useEffect(() => {
    // console.log("imei list", imeis);
  }, [imeis])

  // console.log(selectedFood);
  // Filter the IMEIs based on the search term
  const filteredImeis = selectedFood?.imeis.filter(imei =>
    imei?.imei.includes(searchVarientItem)
  )

  const toggleImeiSelection = (imei) => {
    const isSelected = selectedImeis.includes(imei.id);
    const updatedSelections = isSelected
      ? selectedImeis.filter(id => id !== imei.id)
      : [...selectedImeis, imei.id];
    setSelectedImeis(updatedSelections);
    handleImeisSelect(imei, selectedFood);  // You might need to update this logic if you handle selection differently
  };

  // const filteredImeis = selectedFood?.length > 0 ? selectedFood?.forEach(food => food.hasOwnProperty("imei") && toggleImeiSelection(food.imei)) : selectedFood?.imeis.filter(imei =>
  //   imei?.imei.includes(searchVarientItem)
  // );

  // const filteredImeis = selectedFood?.length > 0
  //   ? selectedFood
  //         .filter(food => food.hasOwnProperty("imei"))
  //         .map(food => food.imei)
  //         .filter(imei => imei.includes(searchVarientItem))
  //   : selectedFood?.imeis?.filter(imei =>
  //         imei?.imei.includes(searchVarientItem)
  //     );

  // if(selectedFood.length > 0){
  //   selectedFood.map(food => food?.imeis.length > 0 ? const filteredImeis = selectedFood?.imeis.filter(imei =>
  //     imei?.imei.includes(searchVarientItem)
  //   ))
  // }

  return (
    <div className="mt-[30px]">
      <BarcodeReader onScan={handleScan} onError={handleError} />
      <ToastContainer />

      <div className="flex gap-x-[2%] flex-col md:flex-row">
        <div className="w-full md:w-[60%] p-3 box-border shadow-xs bg-[#fff] rounded-[15px] mb-4 md:!mb-0">
          <div className="pt-0 slider-area">
            <div className="flex justify-between md:flex-row flex-col px-0 md:px-4">
              <div>
                <p className="text-[#A0AEC0] font-nunito text-bases font-normal">
                  Home <span className="text-xs">/</span> POS
                </p>
                <p className="text-[#2D3748] font-nunito text-sm font-bold">
                  Point Of Sale
                </p>
              </div>
              <div className="flex gap-2">
                <div className="flex relative w-[95%]">
                  <input
                    autoFocus
                    type="text"
                    // className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[17px] pr-[45px] pl-[21px] pb-[15px] placeholder:text-[#91919F]"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] "
                    placeholder="Search Product"
                    // onChange={(e) => setSearchKeyword(e.target.value)}
                    onChange={(e) =>
                      // getFilteredProductlist2(1, e.target.value)
                      setSearchFilter({ ...searchFilter, keyword: e.target.value })
                    }
                  />
                  <div className="absolute top-[37%] left-[10px] ">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>
                  <div
                    ref={buttonRef}
                    onClick={handleButtonClick}
                    className="absolute top-[36%] right-[10px]"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z" fill="#102048" />
                      <path d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z" fill="#102048" />
                      <path d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z" fill="#102048" />
                      <path d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z" fill="#102048" />
                      <path d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z" fill="#102048" />
                      <path d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z" fill="#102048" />
                      <path d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z" fill="#102048" />
                      <path d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z" fill="#102048" />
                      <path d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z" fill="#102048" />
                    </svg>
                  </div>
                  {showNavMenu && (
                    <div ref={menuRef} className="absolute z-50  w-[230px] shadow-sm left-[40px] top-[52px] bg-[#FFF] border-1 border-solid p-3 rounded-[10px]">
                      <div onClick={(e) => setSearchFilter({ ...searchFilter, categoryId: (!searchFilter.categoryId) })} className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                        <div className=''>
                          <input
                            type="checkbox"
                            checked={searchFilter.categoryId}
                            onChange={(e) => setSearchFilter({ ...searchFilter, categoryId: (!searchFilter.categoryId) })}
                          // className="bg-[#E5EDFF] p-3 "
                          />
                        </div>
                        <div className="">
                          <p className=" text-[#000] font-[500] ps-2 text-xs">Category</p>
                        </div>
                      </div>
                      <div onClick={(e) => setSearchFilter({ ...searchFilter, subCategoryId: (!searchFilter.subCategoryId) })} className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                        <div className=''>
                          <input
                            type="checkbox"
                            checked={searchFilter.subCategoryId}
                            onChange={(e) => setSearchFilter({ ...searchFilter, subCategoryId: (!searchFilter.subCategoryId) })}
                          // className="bg-[#E5EDFF] p-3 "
                          />
                        </div>
                        <div className="">
                          <p className=" text-[#000] whitespace-nowrap font-[500] ps-2 text-xs">Sub Category</p>
                        </div>
                      </div>
                      <div onClick={(e) => setSearchFilter({ ...searchFilter, unitId: (!searchFilter.unitId) })} className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                        <div className=''>
                          <input
                            type="checkbox"
                            checked={searchFilter.unitId}
                            onChange={(e) => setSearchFilter({ ...searchFilter, unitId: (!searchFilter.unitId) })}
                          // className="bg-[#E5EDFF] p-3 "
                          />
                        </div>
                        <div className="">
                          <p className=" text-[#000] font-[500] ps-2 text-xs">Unit</p>
                        </div>
                      </div>
                      <div onClick={(e) => setSearchFilter({ ...searchFilter, brandId: (!searchFilter.brandId) })} className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                        <div className=''>
                          <input
                            type="checkbox"
                            checked={searchFilter.brandId}
                            onChange={(e) => setSearchFilter({ ...searchFilter, brandId: (!searchFilter.brandId) })}
                          // className="bg-[#E5EDFF] p-3 "
                          />
                        </div>
                        <div className="">
                          <p className=" text-[#000] font-[500] ps-2 text-xs">Brand</p>
                        </div>
                      </div>
                      <div className="flex justify-between w-full col-span-3 items-center pt-[10px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                        <div onClick={(e) => setSearchFilter({ ...searchFilter, stockIn: (!searchFilter.stockIn) })} className={`relative ${searchFilter.stockIn ? 'bg-[#407BFF] cursor-pointer text-white' : 'bg-gray-100'}  border-gray-100 py-1 border w-full flex-1 rounded-xl`}>
                          <p className="w-full text-[13px] cursor-pointer text-center">Stock In</p>
                        </div>
                        <div onClick={(e) => setSearchFilter({ ...searchFilter, stockOut: (!searchFilter.stockOut) })} className={`relative ${searchFilter.stockOut ? 'bg-[#407BFF] cursor-pointer text-white' : 'bg-gray-100'}  border-gray-100 py-1 border w-full flex-1 rounded-xl`}>
                          <p className="w-full text-[13px] cursor-pointer text-center ">Stock Out</p>
                        </div>
                      </div>
                      {/* <div>
                            <p className="p-2 text-md">Date</p>
                            <div className="flex justify-between w-full col-span-3 items-center mt-[5px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                                <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                                    <DatePicker
                                    selected={searchFilter.startDate}
                                    onChange={(date) => setSearchFilter({...searchFilter, startDate: date})}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="00/00/00"
                                    className="w-full px-2 text-xs focus:outline-none"
                                    />
                                      <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                        Start Date
                                    </span>
                                </div>
                                <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                                    <DatePicker
                                    selected={searchFilter.endDate}
                                    onChange={(date) => setSearchFilter({...searchFilter, endDate: date})}
                                    dateFormat="dd/MM/yyyy"
                                    className="w-full px-2 text-xs focus:outline-none"
                                    />
                                      <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                        End Date
                                    </span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                  )}
                </div>
                <label
                  htmlFor="my_modal_product"
                  onClick={handleQuickProductFormClick}
                  className="flex w-[5%]  justify-center items-center bg-[#407BFF] text-[#FFF] text-[11px] font-nunito font-bold py-2 px-4 cursor-pointer rounded-[5px] capitalize"
                >
                  <i className="fa-solid fa-plus"></i>
                </label>
              </div>
            </div>
            {loading && (
              <div className="custom-loader-logo">
                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                <img
                  className=""
                  src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                  alt="logo"
                />
              </div>
            )
            }

            {searchKeyword &&
              filteredProducts?.length > 0 &&
              filteredProducts?.length < 5 ? (
              <div className="searchList sliderArea">
                {loader && <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg"> <div className="custom-loader"></div> </div>}


                {!loader ?
                  filteredProducts?.map((item, index) => (
                    item?.imeis?.length > 0 ?
                      <div
                        key={index}
                        // onClick={() => {handleSelectedFood(item)}}
                        onClick={item?.imeis?.length ? () => handleSelectedImeis(item) : () => handleSelectedFood(item)}
                        className="flex flex-col gap"
                      >
                        <div className={`overflow-hidden border-2 ${
                          // item?.current_stock === 0
                          (item?.imeis?.length ? (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0 :
                          (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0)
                            // (Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 0)
                            // ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0))  === 0
                            ? "border-[#ca1830]"
                            // : item?.current_stock <= 5
                            :
                            (item?.imeis?.length ? (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 5 :
                            (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 5)
                              //  (Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 5)
                              // ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0))  <= 5
                              ? "border-[#FBBC05]"
                              : "border-[#18CA29]"
                          } bg-white mr-[20px] shadow-sm rounded-[16px] single-product hover:scale-[1.2] hover:sticky hover:z-10  transform duration-300  relative md:mr-[25px]`}
                        >
                          <img
                            src={item?.image_path ? item?.image_path : shop_logo}
                            alt="categoryDymmyImaage"
                            className="w-[130px] lg:w-[150px] object-contain rounded-tl-[16px] rounded-tr-[16px]"
                          />
                          <div className="mt-[10px] text-center flex justify-center items-center h-[62px] font-nunito text-sm px-1.5 w-[150px] font-bold">
                            <p className="break-words">{item?.name?.length > 28 ? eclipse(item.name, 28) : item.name}</p>
                          </div>
                        </div>
                      </div> :
                      <div
                        key={index}
                        // onClick={() => {handleSelectedFood(item)}}
                        onClick={() => handleSelectedFood(item)}
                        className="flex flex-col gap"
                      >
                        <div className={`overflow-hidden border-2 ${
                          // item?.current_stock === 0
                          (item?.imeis?.length ? (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0 :
                            (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0)
                            //  (Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 0)
                            // ( Number.parseInt(item?.current_stock ? item?.current_stock : 0))  === 0
                            ? "border-[#ca1830]"
                            // : item?.current_stock <= 5
                            :
                            (item?.imeis?.length ? (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 5 :
                              (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 5)
                              // (Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 5)
                              // ( Number.parseInt(item?.current_stock ? item?.current_stock : 0))  <= 5
                              ? "border-[#FBBC05]"
                              : "border-[#18CA29]"
                          } bg-white mr-[20px] shadow-sm rounded-[16px] single-product hover:scale-[1.2] hover:sticky hover:z-10  transform duration-300  relative md:mr-[25px]`}
                        >
                          {/* <img src={item.foodImg} alt="" /> */}
                          <img
                            src={item?.image_path ? item?.image_path : shop_logo}
                            alt="categoryDymmyImaage"
                            className="w-[130px] lg:w-[150px] object-contain rounded-tl-[16px] rounded-tr-[16px]"
                          />
                          <div className="mt-[10px] text-center flex justify-center items-center h-[62px] font-nunito text-sm px-1.5 w-[150px] font-bold">
                            <p className="break-words">{item?.name?.length > 28 ? eclipse(item.name, 28) : item.name}</p>
                          </div>
                        </div>
                      </div>
                  )) : null}
              </div>
            ) : (!loader && searchKeyword && filteredProducts.length > 3) ||
              (!loader && filteredProducts.length > 3) ? (
              <div>
                <div className="parent-sliderArea mb-[40px] md:mb-[0px]">
                  <Slider {...settings}>
                    {filteredProducts?.map((item, index) => (
                      type !== "purchase" && item?.imeis?.length  ?
                        <div
                          key={index}
                          // onClick={() => {handleSelectedFood(item)}}
                          onClick={item?.imeis?.length ? () => handleSelectedImeis(item) : () => handleSelectedFood(item)}
                          className="flex flex-col justify-center items-center"
                        >
                          <div
                            className={`overflow-hidden border-2 ${
                              // item?.current_stock === 0
                              (item?.imeis?.length ? (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0  :
                              (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0)
                                // (Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 0)
                                // ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0))  === 0
                                ? "border-[#ca1830]"
                                // : item?.current_stock <= 5
                                :
                                (item?.imeis?.length ? (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 5 :
                                (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 5)
                                  // (Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 5)
                                  // ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0))  <= 5
                                  ? "border-[#FBBC05]"
                                  : "border-[#18CA29]"
                              } bg-white mr-[20px] shadow-sm rounded-[16px] single-product hover:scale-[1.2] hover:sticky hover:z-10  transform duration-300  relative md:mr-[25px]`}
                          >
                            {/* <img
                            src={item?.image_path ? item.image_path : shop_logo}
                            alt="categoryDymmyImaage"
                            className="w-[130px] lg:w-[125px] object-cover rounded-tl-[16px] rounded-tr-[16px]"
                          /> */}
                            <div className="w-[150px] flex justify-center ">
                              <img
                                src={
                                  item.image_path ? item.image_path : shop_logo
                                }
                                alt="categoryDymmyImaage"
                                className="h-[140px] object-contain"
                              />
                            </div>
                            <div className="mt-[10px] text-center flex justify-center items-center h-[62px] font-nunito text-sm px-1.5 w-[150px] font-bold">
                              {/*  */}
                              <p className="break-words">{item.name.length > 28 ? eclipse(item.name, 28) : item.name}</p>
                            </div>
                          </div>
                        </div> :
                        <div
                          key={index}
                          // onClick={() => {handleSelectedFood(item)}}
                          onClick={() => handleSelectedFood(item)}
                          className="flex flex-col justify-center items-center"
                        >
                          <div
                            className={`overflow-hidden border-2 ${
                              // item?.current_stock === 0
                              (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0
                                ? "border-[#ca1830]"
                                // : item?.current_stock <= 5
                                : (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 5
                                  ? "border-[#FBBC05]"
                                  : "border-[#18CA29]"
                              } bg-white mr-[20px] shadow-sm rounded-[16px] single-product hover:scale-[1.2] hover:sticky hover:z-10  transform duration-300  relative md:mr-[25px]`}
                          >
                            {/* <img
                            src={item?.image_path ? item.image_path : shop_logo}
                            alt="categoryDymmyImaage"
                            className="w-[130px] lg:w-[125px] object-cover rounded-tl-[16px] rounded-tr-[16px]"
                          /> */}
                            <div className="w-[150px] flex justify-center ">
                              <img
                                src={
                                  item.image_path ? item.image_path : shop_logo
                                }
                                alt="categoryDymmyImaage"
                                className="h-[140px] object-contain"
                              />
                            </div>
                            <div className="mt-[10px] text-center flex justify-center items-center h-[62px] font-nunito text-sm px-1.5 w-[150px] font-bold">
                              {/*  */}
                              <p className="break-words">{item.name.length > 28 ? eclipse(item.name, 28) : item.name}</p>
                            </div>
                          </div>
                        </div>
                    ))}
                  </Slider>
                </div>
              </div>
            ) : (
              <div className="searchList sliderArea !justify-start">
                {loader && <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg"> <div className="custom-loader"></div> </div>}


                {!loader && type !== 'purchase' ?
                  filteredProducts?.map((item, index) => {
                    // console.log('executing ');
                    const hasImeis = item?.imeis?.length > 0;
                    const stock = Number.parseInt(item?.current_stock || '0');

                    // Define onClick handler based on whether the item has IMEIs
                    const handleClick = hasImeis ? () => handleSelectedImeis(item) : () => handleSelectedFood(item);

                    // Define border color based on stock level
                    let borderColor;
                    if (stock <= 0) {
                      borderColor = "border-[#ca1830]"; // Out of stock
                    } else if (stock <= 5) {
                      borderColor = "border-[#FBBC05]"; // Low stock
                    } else {
                      borderColor = "border-[#18CA29]"; // Sufficient stock
                    }

                    return (
                      <div
                        key={index}
                        onClick={handleClick}
                        className="flex flex-col min-w-[152px] justify-center items-center"
                      >
                        <div
                          className={`overflow-hidden border-2 ${borderColor} bg-white mr-[20px] shadow-sm rounded-[16px] single-product hover:scale-[1.2] hover:sticky hover:z-10 transform duration-300 relative md:mr-[25px]`}
                        >
                          <img
                            src={item?.image_path || shop_logo}
                            alt="categoryDummyImage"
                            className="w-[150px] object-contain rounded-tl-[16px] rounded-tr-[16px]"
                          />
                          <div className="mt-[10px] text-center flex justify-center items-center h-[62px] font-nunito text-sm px-1.5 w-[150px] font-bold">
                            <p className="break-words">
                              {item?.name?.length > 28 ? eclipse(item.name, 28) : item.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                   : filteredProducts?.length > 0 && filteredProducts.length < 3 &&
                   filteredProducts?.map((item, index) => {
                    // console.log('executing ');
                    // const hasImeis = item?.imeis?.length > 0;
                    const stock = Number.parseInt(item?.current_stock || '0');

                    // Define onClick handler based on whether the item has IMEIs
                    const handleClick = () => handleSelectedFood(item);

                    // Define border color based on stock level
                    let borderColor;
                    if (stock <= 0) {
                      borderColor = "border-[#ca1830]"; // Out of stock
                    } else if (stock <= 5) {
                      borderColor = "border-[#FBBC05]"; // Low stock
                    } else {
                      borderColor = "border-[#18CA29]"; // Sufficient stock
                    }

                    return (
                      <div
                        key={index}
                        onClick={handleClick}
                        className="flex flex-col min-w-[152px] justify-center items-center"
                      >
                        <div
                          className={`overflow-hidden border-2 ${borderColor} bg-white mr-[20px] shadow-sm rounded-[16px] single-product hover:scale-[1.2] hover:sticky hover:z-10 transform duration-300 relative md:mr-[25px]`}
                        >
                          <img
                            src={item?.image_path || shop_logo}
                            alt="categoryDummyImage"
                            className="w-[150px] object-contain rounded-tl-[16px] rounded-tr-[16px]"
                          />
                          <div className="mt-[10px] text-center flex justify-center items-center h-[62px] font-nunito text-sm px-1.5 w-[150px] font-bold">
                            <p className="break-words">
                              {item?.name?.length > 28 ? eclipse(item.name, 28) : item.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>



          {/* {searchKeyword !== '' && modifiedFilteredProducts.length != 0 ? ( */}
          <div className="">
            <div className="flex flex-col mt-[0px] md:mt-[10px] mb-[20px] px-0 md:px-3">
              <div>
                {/* pagination component */}
                {/* {shouldRenderPagination && (
                  <Pagination
                    lastPage={pagination.last_page}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                )} */}
              </div>

              <div className="flex justify-between">
                {/* <h4 className="text-[#000232] font-nunito text-[23px] font-bold">
                  Menu
                </h4> */}
                <h4
                  className={`${!shouldRenderPagination && "right-0"
                    } text-[#00023252] ml-5 font-nunito text-lg font-bold px-3`}
                >
                  {pagination && pagination.total} Results
                </h4>
              </div>
            </div>
            <div className="foodList p-2.5 cursor-pointer">
              {!loader &&
                <div className={`flex flex-col sm:flex-row gap-[2%] ${type != "purchase" && selectedFood?.have_variant == 1 ? '' : 'lg:flex-col'}`}>
                  { (type == "purchase" || selectedFood?.have_variant == null || selectedFood?.have_variant == 0) && <div className="flex w-[100%] align-top bg-transparent bg-base-100 mb-3 gap-2 p-2 border-b text-gray-400">
                    <div className="flex-2">
                      <div className="flex flex-col items-center">
                        <h2 className="font-nunito text-sm px-1 font-bold mb-[2px]">
                          No.
                        </h2>
                      </div>

                    </div>
                    <div className="flex-1">
                      <div className="flex flex-col items-center">
                        <h2 className="font-nunito text-sm px-1 font-bold mb-[2px]">Name</h2>
                      </div>

                    </div>
                    <div className="flex-1">
                      <div className="flex flex-col items-center">
                        <h2 className=" font-nunito text-sm px-1 font-bold mb-[2px]">Stock</h2>
                      </div>

                    </div>

                    <div className="flex-1">
                      <div className="flex flex-col items-center">
                        <h2 className=" font-nunito text-sm px-1 font-bold mb-[2px]">Purchase Price</h2>
                      </div>

                    </div>

                    <div className="flex-1">
                      <div className="flex flex-col items-center">
                        <h2 className=" font-nunito text-sm px-1 font-bold mb-[2px]">Price</h2>
                      </div>

                    </div>

                    </div>}
                  <div className={`w-[100%] sm:w-[47%] ${selectedFood?.have_variant == 1 && type != "purchase" ? 'md:w-[47%]' : 'md:w-[100%]'} h-[761px] overflow-y-auto custom-scrollbar`}>
                    <div className="w-[100%] ml-[3px] mt-[3px]">
                      {modifiedFilteredProducts.map((item, index) => {
                        let discount = 0;
                        if (
                          (item?.discount_type == "Percentage" ||
                            item?.discount_type == "2") &&
                          item?.discount
                        ) {
                          discount =
                            type === "purchase"
                              ? formatPrice(0)
                              : (type === "wholesale"
                                ? formatPrice(0)
                                : formatPrice(item.retails_price * (item.discount / 100)));
                        } else {
                          discount =
                            type === "purchase"
                              ? formatPrice(0)
                              : (type === "wholesale"
                                ? formatPrice(0)
                                : formatPrice(item.discount));
                        }
                        return (
                          item?.imeis?.length ?
                            <div
                              // onClick={() => {
                              //   handleSelectedFood(item);
                              // }}
                              onClick={item?.imeis?.length && type != "purchase" ? () => handleSelectedImeis(item) : () => handleSelectedFood(item)}

                              className={`${selectedFood ? "w-[100%]" : "w-[100%]"
                                } flex align-top bg-transparent bg-base-100 shadow-none single-product-item ${ type != "purchase" ? "border-2 rounded-[18px] items-center mb-6 gap-2 p-2" : "border-b mb-3 gap-2 pb-2 pt-2 pl-3"}
                                ${
                                  // item?.current_stock === 0
                                  (item?.imeis?.length && type != "purchase" ? (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0 :
                                  type != "purchase" && (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0)
                                    //(Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 0)
                                    // ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0))  === 0
                                    ? "border-[#ca1830]"
                                    // : item?.current_stock <= 5
                                    :
                                    (item?.imeis?.length && type != "purchase" ? (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 5 :
                                    (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 5) && type != "purchase"
                                      //(Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 5)
                                      // ( Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0))  <= 5
                                      ? "border-[#FBBC05]"
                                      : type != "purchase" && "border-[#18CA29]"
                                  }
                            `}
                              key={index}
                            >

                              {/* <div className="w-[90px] min-w-[90px] max-h[70px]"> */}
                              {type != "purchase" &&
                                <div className="w-14 h-14">
                                  <img
                                    // className="w-[90px] min-h-[70px] max-h-[70px] rounded-[16px] object-cover"
                                    className="h-full w-full rounded-lg object-contain"
                                    src={
                                      item?.image_path ? item.image_path : shop_logo
                                    }
                                    alt="Movie"
                                  />
                                </div>
                              }
                              <div className="flex-2 mr-3">
                                <div className="flex flex-col items-center">
                                  {/* <h2 className="text-[#000] font-nunito text-sm px-1 font-bold mb-[2px]">
                                    No.
                                  </h2> */}

                                  {index !== undefined && (
                                    <p className="text-sm mb-[2px] font-semibold">
                                      {index + 1}
                                    </p>
                                  )}
                                </div>
                                </div>

                              <div className="flex-1">
                                <div>
                                  <h2 className={`text-[#000] font-nunito ${type=="purchase" ? 'text-xs' : 'text-sm' } px-1 font-bold mb-[2px]`}>
                                    {/* {eclipse(item.name, 10)} */}
                                    {item.name}
                                  </h2>

                                  {type != "purchase" && item?.category?.name && (
                                    <p className="text-sm mb-[2px]">
                                      {item?.category?.name}
                                    </p>
                                  )}
                                </div>
                              </div>
                                {item?.have_variant == 0 && (
                                  <div className="flex flex-wrap gap-1 text-[#000] font-nunito text-xs font-bold">
                                    <div className="whitespace-nowrap">
                                      {`${formatPrice(
                                        type === "purchase"
                                          ? item.purchase_price
                                          : (type === "wholesale"
                                            ? item.wholesale_price
                                            : item.retails_price - discount)
                                      )} `}
                                      <span className="text-xs">
                                        {invoiceSetting?.currency_info?.code
                                          ? invoiceSetting.currency_info.code
                                          : "BDT"}
                                      </span>
                                    </div>
                                    {discount != 0 &&
                                      (type === "booking" || type === "billing") && (
                                        <div className="flex gap-1 items-end pb-1 text-red-500 line-through text-xs whitespace-nowrap">
                                          {type === "wholesale" ? formatPrice(item.wholesale_price) : formatPrice(item.retails_price)}
                                          <span className="text-xs">
                                            {invoiceSetting?.currency_info?.code
                                              ? invoiceSetting.currency_info.code
                                              : "BDT"}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                )}

                              { (item?.have_variant == null || item?.have_variant == 0) &&  <div className="flex-1">
                                  <div className="flex flex-col items-center ">
                                    {/* <h2 className="text-[#000] font-nunito text-sm font-bold mb-[2px]">
                                      Stock
                                    </h2> */}

                                  {item?.current_stock && (
                                    <p className="text-sm mb-[2px] font-semibold">
                                      {item?.current_stock}
                                    </p>
                                  )}
                                  </div>
                              </div>}

                            </div>
                            :
                            <div
                              // onClick={() => {
                              //   handleSelectedFood(item);
                              // }}
                              onClick={() => handleSelectedFood(item)}

                              className={`${selectedFood ? "w-[100%]" : "w-[100%]"
                                } flex align-top bg-transparent bg-base-100 mb-3 gap-2 pb-2 pt-2 pl-3 shadow-none single-product-item border-b
                            `}
                              key={index}
                            >
                              {/* ${
                                // item?.current_stock === 0
                                // ( ? (Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 0 :
                                  (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 0)
                                  // ( Number.parseInt(item?.current_stock ? item?.current_stock : 0))  === 0
                                  ? "border-l-[#ca1830]"
                                  // : item?.current_stock <= 5
                                  :
                                  // ( ? (Number.parseInt(item?.stock_unit_no ? item?.stock_unit_no : 0)) <= 5 :
                                    (Number.parseInt(item?.current_stock ? item?.current_stock : 0)) <= 5)
                                    // ( Number.parseInt(item?.current_stock ? item?.current_stock : 0))  <= 5
                                    ? "border-l-[#FBBC05]"
                                    : "border-l-[#18CA29]"
                                } */}

                              {/* <div className="w-[90px] min-w-[90px] max-h[70px]"> */}
                              {(item?.have_variant == null || item?.have_variant == 0) &&
                              <div className="flex-2 mr-3">
                                <div className="flex flex-col items-center">
                                  {/* <h2 className="text-[#000] font-nunito text-sm px-1 font-bold mb-[2px]">
                                    No.
                                  </h2> */}

                                  {index !== undefined && (
                                    <p className="text-sm mb-[2px] font-semibold">
                                      {index + 1}
                                    </p>
                                  )}
                                </div>
                                </div> }
                              {(item?.have_variant != null && item?.have_variant != 0) && <div className="w-14 h-14">
                                <img
                                  // className="w-[90px] min-h-[70px] max-h-[70px] rounded-[16px] object-cover"
                                  className="h-full w-full rounded-lg object-contain"
                                  src={
                                    item?.image_path ? item.image_path : shop_logo
                                  }
                                  alt="Movie"
                                />
                              </div>}

                              <div className="flex-1">
                                <div className="flex flex-col items-center justify-center">
                                  <h2 className="text-[#000] font-nunito text-xs font-bold mb-[2px]">
                                    {/* {eclipse(item.name, 10)} */}
                                    {item.name}
                                  </h2>

                                  {/* {item?.category?.name && (
                                    <p className="text-sm mb-[2px]">
                                      {item?.category?.name}
                                    </p>
                                  )} */}
                                </div>

                                {(item?.have_variant != null && item?.have_variant != 0) && <div className="flex flex-wrap gap-1 text-[#000] font-nunito text-xs font-bold">
                                  <div className="whitespace-nowrap">
                                    {`${formatPrice(
                                      type === "purchase"
                                        ? item.purchase_price
                                        : (type === "wholesale"
                                          ? item.wholesale_price
                                          : item.retails_price - discount)
                                    )} `}
                                    <span className="text-xs">
                                      {invoiceSetting?.currency_info?.code
                                        ? invoiceSetting.currency_info.code
                                        : "BDT"}
                                    </span>
                                  </div>
                                  {discount != 0 &&
                                    (type === "booking" || type === "billing") && (
                                      <div className="flex gap-1 items-end pb-1 text-red-500 line-through text-xs whitespace-nowrap font-semibold">
                                        {type === "wholesale" ? formatPrice(item.wholesale_price) : formatPrice(item.retails_price)}
                                        <span className="text-xs">
                                          {invoiceSetting?.currency_info?.code
                                            ? invoiceSetting.currency_info.code
                                            : "BDT"}
                                        </span>
                                      </div>
                                    )}
                                </div>}
                              </div>
                              { (item?.have_variant == null || item?.have_variant == 0) &&  <div className="flex-1">
                                  <div className="flex flex-col items-center ">
                                    {/* <h2 className="text-[#000] font-nunito text-sm font-bold mb-[2px]">
                                      Stock
                                    </h2> */}

                                  {item?.current_stock && (
                                    <p className="text-sm mb-[2px] font-semibold">
                                      {item?.current_stock}
                                    </p>
                                  )}
                                  </div>
                              </div>}

                              { (item?.have_variant == null || item?.have_variant == 0) && <div className="flex-1">
                                  <div className="flex flex-col items-center ">
                                    {/* <h2 className="text-[#000] font-nunito text-sm font-bold mb-[2px]">
                                      Purchase Price
                                    </h2> */}

                                  {item?.purchase_price && (
                                    <p className="text-sm mb-[2px] font-semibold">
                                      {item?.purchase_price} {invoiceSetting?.currency_info?.code
                                            ? invoiceSetting.currency_info.code
                                            : "BDT"}
                                    </p>

                                  )}
                                  </div>
                              </div>}
                              { (item?.have_variant == null || item?.have_variant == 0) && <div className="flex-1">
                                <div className="flex flex-col items-center ">
                                    {/* <h2 className="text-[#000] font-nunito text-sm font-bold mb-[2px]">
                                      Price
                                    </h2> */}
                                <div className="flex flex-wrap gap-1 text-[#000] font-nunito text-xs font-semibold">

                                   <div className="whitespace-nowrap font-semibold">
                                    {`${formatPrice(
                                      type === "purchase"
                                        ? item.purchase_price
                                        : (type === "wholesale"
                                          ? item.wholesale_price
                                          : item.retails_price - discount)
                                    )} `}
                                    <span className="text-xs">
                                      {invoiceSetting?.currency_info?.code
                                        ? invoiceSetting.currency_info.code
                                        : "BDT"}
                                    </span>
                                  </div>
                                  {discount != 0 &&
                                    (type === "booking" || type === "billing") && (
                                      <div className="flex gap-1 items-end pb-1 font-semibold text-red-500 line-through text-xs whitespace-nowrap">
                                        {type === "wholesale" ? formatPrice(item.wholesale_price) : formatPrice(item.retails_price)}
                                        <span className="text-xs">
                                          {invoiceSetting?.currency_info?.code
                                            ? invoiceSetting.currency_info.code
                                            : "BDT"}
                                        </span>
                                      </div>
                                    )}
                                </div>
                                </div>
                                </div>

                              }
                            </div>
                        );
                      })}
                    </div>
                  </div>
                 {selectedFood?.have_variant == 1 && type != "purchase" &&
                 <div className="w-[100%] mt-[35px] sm:mt-0 sm:w-[53%] md:w-[53%]">
                    {selectedFood
                      ? (() => {
                        let discount = 0;
                        // console.log("============");
                        // console.log(selectedFood);
                        if (
                          (selectedFood?.discount_type == "Percentage" ||
                            selectedFood?.discount_type == "2") &&
                          selectedFood?.discount
                        ) {
                          discount =
                            type === "purchase"
                              ? formatPrice(0)
                              : type === "wholesale"
                                ? formatPrice(0)
                                : formatPrice(
                                  selectedFood.retails_price *
                                  (selectedFood.discount / 100)
                                );
                        } else {
                          discount =
                            type === "purchase" ? 0 : selectedFood?.discount;
                        }
                        return (
                          <div className="shadow-md h-[100%] rounded-[18px] bg-base-100 p-[10px] mb-3 single-product-item">
                            <div className="block border-0">
                              <div className="">
                                <img
                                  className="w-full sm:max-w-[390px] 2xl:max-w-[442px] m-auto rounded-[16px] object-cover"
                                  src={
                                    selectedFood?.image_path && selectedFoodImg
                                      ? selectedFoodImg
                                      : selectedFood?.image_path
                                        ? selectedFood.image_path
                                        : shop_logo
                                  }
                                  alt="Movie"
                                />
                              </div>
                              {type != "purcahse" && selectedFood?.image_path1 ||
                                selectedFood?.image_path1 ? (
                                <div style={{ rowGap: "7px" }} className="flex gap-[2.5%] flex-wrap mt-[16px]">
                                  {selectedFood.image_path && (
                                    <img
                                      className="w-[90px] h-[62px] md:w-[60px] md:h-[50px] lg:w-[90px] lg:h-[62px] object-cover rounded-[8px]"
                                      src={selectedFood.image_path}
                                      onClick={() => {
                                        handleSelectedFoodImage(
                                          selectedFood.image_path
                                        );
                                      }}
                                      alt=""
                                    />
                                  )}

                                  {selectedFood.image_path1 && (
                                    <img
                                      className="w-[90px] h-[62px] md:w-[60px] md:h-[50px] lg:w-[90px] lg:h-[62px] object-cover rounded-[8px]"
                                      src={selectedFood.image_path1}
                                      alt=""
                                      onClick={() => {
                                        handleSelectedFoodImage(
                                          selectedFood.image_path1
                                        );
                                      }}
                                    />
                                  )}

                                  {selectedFood.image_path2 && (
                                    <img
                                      className="w-[90px] h-[62px] md:w-[60px] md:h-[50px] lg:w-[90px] lg:h-[62px] object-cover rounded-[8px]"
                                      src={selectedFood.image_path2}
                                      alt=""
                                      onClick={() => {
                                        handleSelectedFoodImage(
                                          selectedFood.image_path2
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              ) : null}

                              <div className="w-[100%] mt-2.5">
                                <div className="flex flex-row items-center gap-2">
                                  <p className="text-[#000] font-nunito text-[24px] font-bold mb-[2px]">
                                    {selectedFood.name}
                                  </p>

                                  {selectedFood?.brands?.name && (
                                    <p className="text-[16px] mb-[2px]">
                                      {selectedFood?.brands?.name}
                                    </p>
                                  )}
                                </div>

                                <div>
                                  {selectedFood?.category?.name && (
                                    <p className="text-base mb-[2px]">
                                      {selectedFood?.category?.name}
                                    </p>
                                  )}
                                </div>

                                <div>
                                  {selectedFood?.current_stock && (
                                    <p className="text-[16px] mb-[2px]">
                                      Quantity:{" "}
                                      <span className="font-bold">
                                        {`${selectedFood?.current_stock?.toFixed(2)} ${selectedFood?.unit?.name || ""}`}
                                      </span>
                                    </p>
                                  )}
                                </div>

                                <div className="flex flex-wrap justify-between gap-1 text-[#000] font-nunito text-lg font-bold">
                                  <div className="flex gap-1">
                                    {selectedFood?.have_variant == 1 && selectedFood?.checked == 1 ? (
                                      <>
                                        <span>
                                          {
                                            type === "purchase" ?
                                              selectedFood?.purchase_price
                                              : type === "wholesale" ?
                                                formatPrice(selectedFood.wholesale_price)
                                                : (selectedFood.retails_price - discount)
                                          }
                                        </span>
                                        <span className="text-xs pt-2">
                                          {selectedFood.currency_code
                                            ? selectedFood.currency_code
                                            : "BDT"}
                                        </span>

                                      </>
                                    ) : null}
                                    {selectedFood?.have_variant == 0 ?
                                      (
                                        <>
                                          <span>
                                            {
                                              type === "purchase" ?
                                                formatPrice(selectedFood?.purchase_price)
                                                : (type === "wholesale" ?
                                                  formatPrice(selectedFood.wholesale_price)
                                                  : formatPrice(selectedFood.retails_price - discount))}
                                          </span>
                                          <span className="text-xs pt-2">
                                            {invoiceSetting?.currency_info?.code
                                              ? invoiceSetting?.currency_info?.code
                                              : "BDT"}
                                          </span>
                                        </>
                                      ) : null}
                                    {discount != 0
                                      && selectedFood?.have_variant == 0 &&
                                      (type === "booking" ||
                                        type === "billing") && (
                                        <div className="">
                                          <span className=" text-red-500 line-through text-xs pt-2">
                                            {" "}
                                            {
                                              type === "wholesale"
                                                ? formatPrice(selectedFood?.wholesale_price)
                                                : formatPrice(selectedFood.retails_price)}
                                          </span>{" "}
                                          <span className="text-xs pt-2 text-red-500 line-through">
                                            {selectedFood?.currency_code ? selectedFood?.currency_code : invoiceSetting?.currency_info?.code
                                              ? invoiceSetting?.currency_info
                                                ?.code
                                              : "BDT"}
                                          </span>
                                        </div>
                                      )}
                                    {discount != 0
                                      && selectedFood?.have_variant == 1
                                      && selectedFood?.checked == 1 &&
                                      (type === "booking" ||
                                        type === "billing") && (
                                        <div className="">
                                          <span className=" text-red-500 line-through text-xs pt-2">
                                            {" "}
                                            {
                                              type === "wholesale"
                                                ? formatPrice(selectedFood?.wholesale_price)
                                                : formatPrice(selectedFood.retails_price)}
                                          </span>{" "}
                                          <span className="text-xs pt-2 text-red-500 line-through">
                                            {selectedFood?.currency_code ? selectedFood?.currency_code : invoiceSetting?.currency_info?.code
                                              ? invoiceSetting?.currency_info
                                                ?.code
                                              : "BDT"}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                </div>

                                <div className="py-2">
                                  { type === "wholesale" && selectedFood?.imeis?.length > 0 ? (
                                    <div>
                                      <div className='rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-[20px]'>
                                        <div className='flex justify-between items-center mb-2 gap-3'>
                                          <div className='font-lato font-[700] text-[12px] text-[#000232]'>List Of Variations</div>

                                          <input
                                            type="text"
                                            placeholder="Search by IMEI"
                                            className='px-3 py-1 border-[1px] border-[#E7EBF4] rounded-[8px] text-[12px]'
                                            value={searchVarientItem}
                                            onChange={(e) => setSearchVarientItem(e.target.value)}
                                          />
                                        </div>

                                        <div className='grid grid-cols-[min-content_1fr_1fr_1fr] gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]'>
                                          <div className='py-[5px]'></div>
                                          <div className='py-[5px]'>Condition</div>
                                          <div className='py-[5px] text-center'>Price</div>
                                          <div className='py-[5px]'>IMEI/SL</div>
                                        </div>
                                        {filteredImeis.map((imei, index) => (

                                          <div key={index} className={`grid grid-cols-[min-content_1fr_1fr_1fr] gap-2 font-nunito font-[400] text-[14px] text-[#000232] ${index === (filteredImeis.length - 1) ? "" : "border-b-[1px]"} border-solid border-[#E7EBF4]`}>
                                            <div className='py-[4px] flex items-center justify-center'>
                                              <input
                                                type="checkbox"
                                                checked={selectedImeis.includes(imei.id) || barcodeImei === imei.imei}
                                                onChange={() => toggleImeiSelection(imei)}
                                              />

                                            </div>
                                            <div className='py-[5px] flex items-center'>{imei?.item_condition}</div>
                                            <div className='py-[5px] flex items-center justify-center'>
                                              {type === 'wholesale'
                                                ? imei?.currency_rate !== null
                                                ? Number.parseFloat(imei?.wholesale_price).toFixed(2)
                                                  : Number.parseFloat(imei?.wholesale_price).toFixed(2)
                                                  // ? Number.parseFloat(imei?.sale_price).toFixed(2)
                                                  // : Number.parseFloat(imei?.sale_price).toFixed(2)
                                                : null}
                                              {imei?.currency_rate !== null ? imei?.base_currency_code : imei.purchase_currency_code}
                                            </div>
                                            <div className='py-[5px] flex items-center'>{imei?.imei}</div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="py-2">
                                  { type !== "purchase" && type === 'billing' && selectedFood?.imeis?.length > 0 ? (
                                    <div>
                                      <div className='rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-[20px]'>
                                        <div className='flex justify-between items-center mb-2 gap-3'>
                                          <div className='font-lato font-[700] text-[12px] text-[#000232]'>List Of Variations</div>

                                          <input
                                            type="text"
                                            placeholder="Search by IMEI"
                                            className='px-3 py-1 border-[1px] border-[#E7EBF4] rounded-[8px] text-[12px]'
                                            value={searchVarientItem}
                                            onChange={(e) => setSearchVarientItem(e.target.value)}
                                          />
                                        </div>

                                        <div className='grid grid-cols-[min-content_1fr_1fr_1fr] gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]'>
                                          <div className='py-[5px]'></div>
                                          <div className='py-[5px]'>Condition</div>
                                          <div className='py-[5px] text-center'>Price</div>
                                          <div className='py-[5px]'>IMEI/SL</div>
                                        </div>
                                        {filteredImeis.map((imei, index) => (

                                          <div key={index} className={`grid grid-cols-[min-content_1fr_1fr_1fr] gap-2 font-nunito font-[400] text-[14px] text-[#000232] ${index === (filteredImeis.length - 1) ? "" : "border-b-[1px]"} border-solid border-[#E7EBF4]`}>
                                            <div className='py-[4px] flex items-center justify-center'>
                                              <input
                                                type="checkbox"
                                                checked={selectedImeis.includes(imei.id) || barcodeImei === imei.imei}
                                                onChange={() => toggleImeiSelection(imei)}
                                              />

                                            </div>
                                            <div className='py-[5px] flex items-center'>{imei?.item_condition}</div>
                                            <div className='py-[5px] flex items-center justify-center'>
                                              {type === 'billing'
                                                ? imei?.currency_rate !== null
                                                  ? Number.parseFloat(imei?.sale_price).toFixed(2)
                                                  : Number.parseFloat(imei?.sale_price).toFixed(2)
                                                : imei?.currency_rate !== null
                                                  ? Number.parseFloat(imei?.purchase_price).toFixed(2)
                                                  : Number.parseFloat(imei?.purchase_price).toFixed(2)}
                                              {imei?.currency_rate !== null ? imei?.base_currency_code : imei.purchase_currency_code}
                                            </div>
                                            <div className='py-[5px] flex items-center'>{imei?.imei}</div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })()
                      : null}
                  </div>
                  }
                </div>
              }
            </div>
          </div>
          {/* ): null} */}

        </div>
        {/* bill - right div */}
        <div className="w-full md:w-[40%] bg-[#fff] shadow-xs rounded-[15px] px-[15px] box-border relative">
          <h4 className="text-[#000232] font-nunito font-bold text-lg pt-[18px] pb-[12px] ">
            Bills
          </h4>
          <div className="mt-[10px] mb-5">
            <div
              className={`p-[5px] ${orderList.length > 4
                ? "h-[400px] overflow-y-scroll overflow-x-hidden"
                : "overflow-hidden"
                }`}
            >
              {orderList &&
                orderList.map((orderFood, inx) => {
                  let discount = 0;
                  if (
                    (orderFood?.discount_type == "Percentage" ||
                      orderFood?.discount_type == "2") &&
                    orderFood?.discount
                  ) {
                    discount =
                      type === "purchase"
                        ? formatPrice(0)
                        : (type === "wholesale"
                          ? formatPrice(0)
                          : formatPrice(orderFood.wholesale_price * (orderFood.discount / 100)));
                  } else {
                    discount = type === "purchase" || type === "wholesale" ? 0 : orderFood?.discount;
                  }
                  //discount = orderFood.current_stock * orderFood.discount;
                  let subtotalPrice = 0;

                  if (type == "purchase") {
                    if (orderFood.variantsSum) {
                      subtotalPrice = orderFood * subtotalPurchasePrice;
                    }
                    else {
                      /* subtotalPrice = formatPrice(
                        orderFood.qty * orderFood?.purchase_price
                      ); */
                      subtotalPrice = orderFood.qty * orderFood?.purchase_price;
                    }
                    subtotal += Number(subtotalPrice);
                  }
                  else if (type == "wholesale") {
                    if (orderFood.variantsSum) {
                      subtotalPrice = orderFood * subtotalPurchasePrice;
                    }
                    else {
                      /* subtotalPrice = formatPrice(
                        orderFood.qty * orderFood?.wholesale_price
                      ); */
                      subtotalPrice = orderFood.qty * orderFood?.wholesale_price;
                    }
                    subtotal += Number(subtotalPrice);
                  } else {
                    /* subtotalPrice = formatPrice(
                      orderFood.qty * (orderFood?.retails_price - discount)
                    ); */
                    subtotalPrice = orderFood.qty * (orderFood?.retails_price - discount);
                    subtotal += Number(subtotalPrice);
                  }
                  //vats = formatPrice(0.15 * subtotal);
                  //taxes = formatPrice(0.1 * subtotal);
                  const qty = `x${orderFood?.qty}`;
                  return (
                    <div
                      key={inx}
                      className="flex items-center justify-between rounded-[16px] bg-[#fff] px-[0px] custom_shadow p-2 box-border mb-[15px]"
                    >
                      {/* prev */}
                      {/* <div className="flex gap-x-2 float-left">
                      <div className="w-[60px] h-[50px]">
                        <img
                          className="w-full h-full rounded-md"
                          src={orderFood?.image_path ? orderFood.image_path : shop_logo}
                          alt=""
                        />
                      </div>
                    <div className="flex-1">
                        <h4>
                          {orderFood.name?.length > 20 ? (
                            <>{orderFood.name.slice(0, 15) + "..."}</>
                          ) : (
                            orderFood.name
                          )}
                        </h4>
                        <div className="flex gap-x-[17px] mt-[5px]">
                          <div className="flex gap-2">
                            <p className="text-[#00B894] text-[7px] font-nunito font-bold px-2 pt-[4px] pb-[2px] bg-[#00B8941a] text-center rounded-[5px]">
                              {orderFood?.discount + ((orderFood?.discount_type === 'Percentage' || orderFood?.discount_type === '2') ? '%' : (invoiceSetting?.currency_info?.code || 'BDT'))} off
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-x-3 items-center float-right">
                      <div className="flex-1">
                        <p className="flex gap-x-1 text-[#00023280] font-nunito text-xs font-bold">
                          <span>{subtotalPrice}</span>
                          <span className="text-[7px]">{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</span>
                        </p>
                        <div className="flex items-center gap-x-1 w-[75px] h-[20px] mt-2.5">
                          <button
                          className="quantity-increment"
                          onClick={() => {
                            handleSelectedFood(orderFood, 'decrement');
                          }}
                          >
                            <FontAwesomeIcon icon={faAngleLeft} />
                          </button>
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow">
                            <input
                              type="text"
                              value={qty}
                              className="text-center bg-white pb-[3px] pl-[2px] pr-[0px] pt-[3px] w-[100%]"
                              onChange={(e) => handleSelectedFoodQty(orderFood, e)}
                            />
                          </p>
                          <button
                          className="quantity-decrement"
                          onClick={() => {
                            handleSelectedFood(orderFood, 'increment');
                          }}
                          >
                            <FontAwesomeIcon icon={faAngleRight} />
                          </button>
                        </div>
                      </div>
                      <p
                        className="w-[23px] bg-[#FF0303] rounded-[5px] p-1 h-6"
                        onClick={() => {
                          handleDelete(inx);
                        }}
                      >
                        <MdClose className="text-[#FFFFFF]"></MdClose>
                      </p>
                    </div> */}
                      {/* new */}

                      {/* first col */}
                      <div className="flex gap-2 w-3/6 items-center">
                        {/* image */}
                        <div className="w-[50px] h-[50px]">
                          <img
                            className="w-full h-full rounded-md object-contain"
                            src={
                              orderFood?.image_path
                                ? orderFood.image_path
                                : shop_logo
                            }
                            alt={
                              orderFood?.name
                            }
                          />
                        </div>
                        {/* product name & discount */}
                        <div className="space-y-[2px]">
                          <h4 className="text-sm">
                            {orderFood.name?.length > 20 ? (
                              <>{orderFood.name.slice(0, 15) + "..."}</>
                            ) : (
                              orderFood.name
                            )}
                          </h4>
                          <div className="flex gap-1 items-center">
                            {/* discount info */}
                            {type !== 'purchase' &&
                              <p className="text-[#00B894] w-fit text-[7px] font-nunito font-bold px-2 pt-[4px] pb-[2px] bg-[#00B8941a] text-center rounded-[5px]">
                                {orderFood?.discount + ' ' +
                                  (orderFood?.discount_type === "Percentage" ||
                                    orderFood?.discount_type === "2"
                                    ? "%"
                                    : selectedFood?.currency_code ? selectedFood?.currency_code
                                      : invoiceSetting?.currency_info?.code ||
                                      "BDT")}{" "}
                                off
                              </p>
                            }


                            {/* warranty modal button */}
                            {(type === "booking" || type === "billing" || type === "wholesale") ?
                              <label htmlFor="my_modal_warranty" className="cursor-pointer" onClick={() => { setWarrantyModal(true), setSelectedItem(orderFood) }}>
                                <img src={warrantyIcon} alt="warrantyIcon" className="w-5" />
                              </label> :
                              <label htmlFor="my_modal_productVariation" className="cursor-pointer" onClick={() => {   if (orderFood.have_variant == 1) {
                                setProductVariation(true);
                                setSelectedItem(orderFood);
                              } }}>
                                <img src={ProductVariationIcon} alt="productVariation" className="w-5" />
                              </label>
                            }
                          </div>
                        </div>
                      </div>
                      {/* second col */}
                      <div className="w-2/6 ">
                        {(type == 'purchase') ?
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow p-2">
                            <input
                              type="number"
                              value={orderFood.have_variant == 1 ? subtotalPurchasePrice : subtotalPrice}
                              className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                              onChange={(e) =>{
                                //setSubtotalPurchasePrice(e.target.value);
                                handleSelectedFoodPrice(orderFood, e.target.value, inx)
                              }

                              }
                              onFocus={handleFocus} // Automatically select text on focus
                            />
                          </p> : (type === 'wholesale' ?
                          <p className="flex justify-center gap-x-1 text-[#00023280] font-nunito text-xs font-bold ">
                            {/* <span>{subtotalPrice}</span> */}
                            <input
                              type="number"
                              value={(subtotalPrice)}
                              className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                              onChange={(e) =>{
                                setSubtotalPurchasePrice(e.target.value)
                                handleSelectedFoodPriceBilling(orderFood, e.target.value, inx,'wholesale')}
                              }
                              onFocus={handleFocus}
                            />
                            <span className="text-[7px]">
                              {invoiceSetting?.currency_info?.code
                                ? invoiceSetting?.currency_info?.code
                                : "BDT"}
                            </span>
                          </p>
                          :
                          <p className="flex justify-center gap-x-1 text-[#00023280] font-nunito text-xs font-bold ">
                            {/* <span>{subtotalPrice}</span> */}
                            <input
                              type="number"
                              value={subtotalPrice}//parseFloat(subtotalPrice)
                              className="w-[100px] mb-1 text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-[6px]"
                              onChange={(e) =>
                                handleSelectedFoodPriceBilling(orderFood, e.target.value, inx,type)
                              }
                              onFocus={handleFocus}
                            />
                            <span className="text-[7px]">
                              {invoiceSetting?.currency_info?.code
                                ? invoiceSetting?.currency_info?.code
                                : "BDT"}
                            </span>
                          </p>
                          )
                        }

                        <div className="flex items-center gap-x-1 w-[75px] h-[20px]">

                          {type !== "purchase" && orderFood?.imeis?.length ?
                            <button className="quantity-decrement">
                              <FontAwesomeIcon icon={faAngleLeft} />
                            </button> :
                            <button
                              className="quantity-decrement"
                              onClick={() => {
                                handleSelectedFood(orderFood, "decrement");
                              }}
                            // onClick={ orderFood?.imeis?.length ? "" : () => {handleSelectedFood(orderFood, "decrement")}}
                            >
                              <FontAwesomeIcon icon={faAngleLeft} />
                            </button>
                          }
                          <p className="text-[#000232] font-nunito font-semibold text-xs flex-grow">
                            {orderFood?.imeis?.length ?
                              <input
                                type="text"
                                value={qty}
                                className="text-center bg-white pb-[3px] pl-[2px] pr-[0px] pt-[3px] w-[100%]"
                              /> :
                              <input
                                type="text"
                                value={qty}
                                className="text-center bg-white pb-[3px] pl-[2px] pr-[0px] pt-[3px] w-[100%]"
                                onChange={(e) =>
                                  handleSelectedFoodQty(orderFood, e)
                                }
                               /*  onBlur={(e) => handleSelectedFoodQtyHoverMove(orderFood, e)} */
                              // onBlur={(e) => handleSelectedFoodQtyHoverMove(orderFood, e)}
                              />
                            }
                          </p>
                          {/* {console.log(orderFood)} */}
                          {type !== "purchase" && orderFood?.imeis?.length ?
                            <button
                              className="quantity-increment"
                            >
                              <FontAwesomeIcon icon={faAngleRight} />
                            </button>
                            :
                            <button
                              className="quantity-increment"
                              onClick={() => {
                                handleSelectedFood(orderFood, "increment");
                              }}
                            // onClick={ orderFood?.imeis?.length ? "" : () => {handleSelectedFood(orderFood, "increment")}}
                            >
                              <FontAwesomeIcon icon={faAngleRight} />
                            </button>
                          }
                        </div>
                      </div>
                      {/* third col */}
                      <p
                        className="w-[23px] bg-[#FF0303] rounded-[5px] p-1 h-6"
                        onClick={() => {
                          handleDelete(inx);
                        }}
                      >
                        <MdClose className="text-[#FFFFFF]"></MdClose>
                      </p>

                    </div>
                  );
                })}
            </div>
            {
              (type === "purchase") && (
                 warehouseList.length > 1 &&  <div className="mt-[50px] flex gap-2">
                <div className="w-full relative">
                  <select
                    name="wharehouse"
                    onChange={handleChangeWarehouse}
                    className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  >
                    <option disabled=""> Select Warehouse</option>
                    {warehouseList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                  Wharehouse
                </span>
                </div>
              </div>
              )
            }
            {(type === "booking" || type === "billing" || type === "wholesale") && (
              <div className="mt-[50px] flex gap-2">
                <div className="w-full relative">
                  <select
                    name="discount_type"
                    onChange={handleChangeDiscountType}
                    className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  >
                    <option disabled=""> Select Discount Type</option>
                    {discountType.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  type="text"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none`}
                  placeholder="Discount"
                  value={moreDiscount}
                  onChange={(e) => handleChangeDiscount(e.target.value)}
                  name={"discount"}
                />
              </div>
            )}
             <div className="flex justify-between items-start mt-10 ">
             <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
                <DatePicker
                  selected={transactionDate}
                  onChange={(date) => setTransactionDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="w-full px-2 focus:outline-none"
                />
                <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                  Transaction Date
                </span>
                {/* <span className={`${startDate && startDate.toLocaleDateString() === "DD / MM / YYYY" ? "text-[#91919F]" : "text-[#232324]"} absolute left-[6px] px-1  text-sm lg:text-base bg-white`}>
              {startDate && startDate.toLocaleDateString()}
            </span> */}
              </div>
             </div>
            <div className="flex justify-between items-start mt-10 border-b border-dashed border-[#7F8098]">
              <div>
                <h4 className="text-[#000232] font-nunito font-bold text-lg mb-[15px]">
                  Subtotal
                </h4>
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  Vat ({(type === 'purchase' ? 0 : vat) + "%"})
                </p>
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  Tax ({(type === 'purchase' ? 0 : tax) + "%"})
                </p>
                {(type === "booking" || type === "billing" || type === "wholesale") && (
                  <h4 className="text-[#000232] font-nunito font-bold text-lg pt-[10px] mb-[15px]">
                    Delivery Fee
                  </h4>
                )}
                {/* <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  Discount ({moreDiscount + (selectedDiscountType === 'Percentage' ? '%' : (invoiceSetting?.currency_info?.code || 'BDT'))})
                </p> */}

              </div>
              <div className="text-right">
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  {formatPrice(subTotalNew)}{" "}
                  <span className="text-[13px]">
                    {" "}
                    {selectedFood?.currency_code ? selectedFood?.currency_code
                      : invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : "BDT"}
                  </span>
                </p>
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  {/* {formatPrice(
                    vat && vat > 0 ? (vat / 100) * Number(subTotalNew) : 0
                  )}{" "} */}
                  {
                    type === 'purchase' ?
                      0
                      :
                      formatPrice(
                        vat && vat > 0 ? (vat / 100) * Number(subTotalNew) : 0
                      )
                  }

                  <span className="text-[13px]">
                    {" "}
                    {selectedFood?.currency_code ? selectedFood?.currency_code
                      : invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : "BDT"}
                  </span>
                </p>
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  {/* {formatPrice(
                    tax && tax > 0 ? (tax / 100) * Number(subTotalNew) : 0
                  )}{" "} */}
                  {
                    type === 'purchase' ?
                      0
                      :
                      formatPrice(
                        tax && tax > 0 ? (tax / 100) * Number(subTotalNew) : 0
                      )
                  }
                  <span className="text-[13px]">
                    {" "}
                    {selectedFood?.currency_code ? selectedFood?.currency_code
                      : invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : "BDT"}
                  </span>
                </p>
                {(type === "booking" || type === "billing" || type === "wholesale") && (
                  <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                    {
                      (deliveryFormData?.deliveryName).toLowerCase() != 'hand-to-hand' ? <input
                        type="text"
                        // disabled={ (deliveryFormData?.deliveryName).toLowerCase()=='hand-to-hand' ? true : false }
                        className={`w-[80px] text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-2`}
                        placeholder="0.00"
                        value={
                          deliveryAmount
                        }
                        style={inputStyle}
                        onChange={(e) => handleChangeDeliveryAmount(e.target.value)}
                        name={"delivery_fee"}
                      /> :
                        <span>{deliveryAmount}</span>
                    }
                    <span className="text-[13px]">
                      {" "}
                      {selectedFood?.currency_code ? selectedFood?.currency_code
                        : invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                    </span>
                  </p>)}
                {/* <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  {totalMoreDiscount} <span className="text-[13px]"> {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</span>
                </p> */}
              </div>
            </div>

            <div className="flex justify-between items-center border-b border-dashed border-[#7F8098]">
              <div>
                <h4 className="text-[#000232] font-nunito font-bold text-lg mb-[15px]">
                  Grand Total
                </h4>
                {(type === "booking" || type === "billing" || type == 'purchase' || type == 'wholesale') && (
                  <h4 className="text-[#000232] font-nunito font-bold text-lg mb-[15px]">
                    Paid Amount
                  </h4>
                )}
              </div>
              <div>
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px] text-right">
                  {grandTotalAfterDiscount}{" "}
                  <span className="text-[13px]">
                    {selectedFood?.currency_code ? selectedFood?.currency_code : invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : "BDT"}
                  </span>
                </p>

                {(type == 'wholesale' || type === "booking" || type === "billing" || type === "purchase") && (
                  <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px] text-right">
                    {/* <input
                      type="text"
                      className={`w-[80px] text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-2`}
                      placeholder="0.00"
                      value={
                        paidAmount
                          // ? paidAmount
                          // :
                          // Number(grandTotalAfterDiscount) - due
                      }
                      style={inputStyle}
                      // onChange={(e) => handleChangePaidAmount(e.target.value)}
                      name={"paid_amount"}
                    /> */}
                    <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                      {Number.parseFloat(paidAmount).toFixed(2)}{" "}
                      <span className="text-[13px]">
                        {selectedFood?.currency_code ? selectedFood?.currency_code
                          : invoiceSetting?.currency_info?.code
                            ? invoiceSetting?.currency_info?.code
                            : "BDT"}
                      </span>
                    </p>
                    {/* <span className="text-[13px]">
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : "BDT"}
                    </span> */}
                  </p>
                )}

                {(type == 'wholesale1') && (
                  <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px] text-right">
                    <input
                      type="text"
                      className={`w-[80px] text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-2`}
                      placeholder="0.00"
                      value={
                        paidAmount
                          ? paidAmount
                          :
                          Number(grandTotalAfterDiscount) - due
                      }
                      style={inputStyle}
                      onChange={(e) => handleChangePaidAmount(e.target.value)}
                      name={"paid_amount"}
                    />
                    <span className="text-[13px]">
                      {selectedFood?.currency_code ? selectedFood?.currency_code
                        : invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                    </span>
                  </p>
                )}

              </div>
            </div>

            {(type === "booking" || type === "billing" || type === "wholesale") && (
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="text-[#000232] font-nunito font-bold text-lg mb-[15px]">
                    {" "}
                    Due Amount
                  </h4>
                </div>
                <div>
                  <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                    {Number.parseFloat(due).toFixed(2)}{" "}
                    <span className="text-[13px]">
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : "BDT"}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {(type == 'purchase') && (
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="text-[#000232] font-nunito font-bold text-lg mb-[15px]">
                    {" "}
                    Due Amount
                  </h4>
                </div>
                <div>
                  <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                    {Number.parseFloat(due).toFixed(2)}{" "}
                    <span className="text-[13px]">
                      {selectedFood?.currency_code ? selectedFood?.currency_code
                        : invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info?.code
                          : "BDT"}
                    </span>
                  </p>
                </div>
              </div>
            )}


            {/* <div className="mt-[10px]">
              <div className="flex gap-x-5 mt-[15px] mb-[22px]">
                <div
                  onClick={() => {
                    handleClicks("cash");
                  }}
                  className={`${
                    money === "cash"
                      ? "border-2 border-solid border-[#407BFF]  opacity-100"
                      : "opacity-50"
                  } w-[30%] h-[109px] bg-[#407bff1a] shadow-lg flex justify-center items-center rounded-[13px]`}
                >
                  <div className="flex flex-col justify-center items-center gap-y-2">
                    <img src={moneyIcon} alt="" />

                    <p className="text-xs text-[#407BFF] font-nunito font-bold">
                      cash
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleClicks("card");
                  }}
                  className={`${
                    money === "card"
                      ? "border-2 border-solid border-[#407BFF]  opacity-100"
                      : "opacity-50"
                  } w-[30%]  h-[109px] bg-[#407bff1a] shadow-lg flex justify-center items-center rounded-[13px]`}
                >
                  <div className="flex flex-col justify-center items-center gap-y-2">
                    <img src={cardIcon} alt="" />

                    <p className="text-xs text-[#407BFF] font-nunito font-bold">
                      Debit card
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleClicks("qr");
                  }}
                  className={`${
                    money === "qr"
                      ? "border-2 border-solid border-[#407BFF]  opacity-100"
                      : "opacity-50"
                  } w-[30%] h-[109px] bg-[#407bff1a] shadow-lg flex justify-center items-center rounded-[13px]`}
                >
                  <div className="flex flex-col justify-center items-center gap-y-2">
                    <img src={qrIcon} alt="" />

                    <p className="text-xs text-[#407BFF] font-nunito font-bold">
                      Mobile Banking
                    </p>
                  </div>
                </div>
              </div>
              {warningMessage && (
                <div className="warning-message">something wrong!</div>
              )}
              {flashMessage && (
                <div
                  className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
                    flashMessage.type
                  )}`}
                >
                  {flashMessage.text}
                </div>
              )} */}

            <div className="">

              {/* { ( type=='purchase') && (
              <div className="flex gap-x-5 mt-[15px] mb-[22px]">
                <div
                  onClick={() => {
                    handlePurchesClicks("cash");
                  }}
                  className={`${
                    money === "cash"
                      ? "border-2 border-solid border-[#407BFF]  opacity-100"
                      : "opacity-50"
                  } w-[30%] h-[109px] bg-[#407bff1a] shadow-lg flex justify-center items-center rounded-[13px]`}
                >
                  <div className="flex flex-col justify-center items-center gap-y-2">
                    <img src={moneyIcon} alt="" />

                    <p className="text-xs text-[#407BFF] font-nunito font-bold">
                      cash
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handlePurchesClicks("card");
                  }}
                  className={`${
                    money === "card"
                      ? "border-2 border-solid border-[#407BFF]  opacity-100"
                      : "opacity-50"
                  } w-[30%]  h-[109px] bg-[#407bff1a] shadow-lg flex justify-center items-center rounded-[13px]`}
                >
                  <div className="flex flex-col justify-center items-center gap-y-2">
                    <img src={cardIcon} alt="" />

                    <p className="text-xs text-[#407BFF] font-nunito font-bold">
                      Debit card
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handlePurchesClicks("qr");
                  }}
                  className={`${
                    money === "qr"
                      ? "border-2 border-solid border-[#407BFF]  opacity-100"
                      : "opacity-50"
                  } w-[30%] h-[109px] bg-[#407bff1a] shadow-lg flex justify-center items-center rounded-[13px]`}
                >
                  <div className="flex flex-col justify-center items-center gap-y-2">
                    <img src={qrIcon} alt="" />

                    <p className="text-xs text-[#407BFF] font-nunito font-bold">
                      Mobile Banking
                    </p>
                  </div>
                </div>
                </div>)} */}

              {(type === "billing" || type === "booking" || type === "wholesale") && (
                <div className='flex items-center justify-center py-5 gap-3 px-3' onClick={() => setMethodIndex(0)}>
                  <ItemSelectBox2
                    name="delivery_id"
                    options={deliveryList}
                    value={deliveryFormData.deliveryId}
                    onChange={handleChangeSelect.bind(this, 'delivery_id')}
                    label={"Delivery Method"}
                    placeholder={deliveryFormData.deliveryName}
                    style={{ maxHeight: '300px', overflowY: 'scroll' }}
                  />
                </div>)}


              {(type === "booking" || type === "billing" || type == 'purchase' || type == 'wholesale') && (
                <div className="flex justify-center items-center">
                  <div className="grid grid-cols-3 gap-y-5 w-full">
                    {
                      paymentList.map((items, index) => (
                        <label
                          htmlFor="my_modal_payment"
                          onClick={() => handleClicks(items)}
                          className={`${money === items?.type_name && orderList.length > 0
                            ? " bg-[#29B170] text-[#fff]"
                            : "opacity-50 bg-[#2e509b1a] text-blue-700"
                            } cursor-pointer w-[80%] h-[109px] flex justify-center mx-auto items-center rounded-[13px] break-words overflow-hidden`}
                        >
                          <div className={` flex flex-col justify-center w-full items-center gap-y-2 p-2`}>
                            <h2 className={`font-bold text-3xl`}>{items.icon_letter}</h2>
                            <p className="text-sm  text-center font-nunito font-bold w-[90%]">
                              {items.type_name == "cash" ? "Cash" : items.type_name}
                            </p>
                          </div>
                        </label>
                      ))
                    }
                    {/* <label
                        htmlFor="my_modal_payment"
                        onClick={ () => handleClicks(items)}
                        className={`${money === items.type_name && orderList.length>0
                            ? " bg-[#29B170] text-[#fff]"
                            : "opacity-50 bg-[#2e509b1a] text-blue-700"
                            } cursor-pointer w-[80%] h-[109px] flex justify-center mx-auto items-center rounded-[13px]`}
                      >
                        <div className={` flex flex-col justify-center w-full items-center gap-y-2 p-2`}>
                          <h2 className={`font-bold text-3xl`}>{items.icon_letter}</h2>
                          <p className="text-sm font-nunito font-bold">
                            {items.type_name}
                          </p>
                        </div>
                      </label> */}
                  </div>
                </div>)}
              {warningMessage && (
                <div className="warning-message">
                  something wrong!
                </div>
              )}
              {flashMessage && (
                <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
                  {flashMessage.text}
                </div>
              )}


              {/*
              {( type === "billing") ?
                (<div className='flex items-center justify-center py-5 gap-3 px-3' onClick={()=>setMethodIndex(0)}>
                  <ItemSelectBox
                    name="delivery_id"
                    options={deliveryList}
                    value={deliveryFormData.deliveryId}
                    onChange={handleChangeSelect.bind(this, 'delivery_id')}
                    label={"Delivery Method"}
                    placeholder={deliveryFormData.deliveryName }
                    style={{ maxHeight: '300px', overflowY: 'scroll' }}
                  />
                </div>
              ) : null } */}

              {warrantyModal ? (
                <Modal
                  state={state}
                  isOpen={warrantyModal}
                  setOpen={setWarrantyModal}
                  filteredProducts={filteredProducts}
                  setWarningMessage={setWarningMessage}
                  selectedProductWarrantyList={selectedProductWarrantyList}
                  setSelectedProductWarrantyList={setSelectedProductWarrantyList}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  orderList={orderList}
                  title="Warranty Info"
                  formId="my_modal_warranty"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="warranty"
                />
              ) : null}

              {productVariation && selectedItem.qty >= 1 ? (
                <Modal
                  state={state}
                  isOpen={productVariation}
                  setOpen={setProductVariation}
                  // variationData={variationData}
                  // seteVariationData={seteVariationData}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  orderList={orderList}
                  setOrderList={setOrderList}
                  title={selectedItem.name}
                  formId="my_modal_productVariation"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="productVariation"
                />
              ) : null}


              {showPaymentModal ? (
                <Modal
                  isOpen={showPaymentModal}
                  setOpen={setShowPaymentModal}
                  getData={paymentData}
                  listData={paymentList}
                  formData={formData}
                  setFormData={setFormData}
                  paymentAccountList={paymentAccountList}
                  setData={setPaymentData}
                  filteredAccountList={filteredAccountList}
                  setPaidAmount={setPaidAmount}
                  setFilteredAccountList={setFilteredAccountList}
                  setSelectedAccountList={setSelectedPaymentAccountList}
                  setWarningMessage={setWarningMessage}
                  grandTotalAfterDiscount={grandTotalAfterDiscount}
                  title="Payment Info"
                  formId="my_modal_payment"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="payment"
                />
              ) : null}

              {/* Modal Wholesaler  */}
              {showModalWholeSaler ? (
                <Modal
                  isOpen={showModalWholeSaler}
                  setOpen={setShowModalWholeSaler}
                  setData={setWholeSaler}
                  setWarningMessage={setWarningMessage}
                  title="Wholesaler Info"
                  formId="my_modal_wholesaler"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="wholesaler"
                  filteredWholeSaler={filteredWholeSaler}
                  setFilteredWholeSaler={updateFilteredWholeSaler}

                />
              ) : null}
              {/* Modal One  */}
              {showModal ? (
                <Modal
                  notify={notify}
                  isOpen={showModal}
                  setOpen={setShowModal}
                  setData={setCustomer}
                  setWarningMessage={setWarningMessage}
                  title="Customer Info"
                  formId="my_modal_2"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="customer"
                  filteredCustomers={filteredCustomers}
                  setFilteredCustomers={updateFilteredCustomer}
                />
              ) : null}
              {/* Modal Two  */}
              {showModalSeller ? (
                <Modal
                  isOpen={showModalSeller}
                  setOpen={setShowModalSeller}
                  setData={setSeller}
                  setWarningMessage={setWarningMessage}
                  title="Seller Info"
                  formId="my_modal_seller"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="seller"
                  filteredSeller={filteredSeller}
                  setFilteredSeller={setFilteredSeller}
                />
              ) : null}
              {showModalVendor ? (
                <Modal
                  isOpen={showModalVendor}
                  setOpen={setShowModalVendor}
                  setData={setVendor}
                  setWarningMessage={setWarningMessage}
                  title="Vendor Info"
                  formId="my_modal_vendor"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="vendor"
                />
              ) : null}
              {showQuickProductAddForm ? (
                <Modal
                  isOpen={showQuickProductAddForm}
                  setOpen={setShowQuickProductAddForm}
                  setData={setVendor}
                  setWarningMessage={setWarningMessage}
                  title="Quick Entry"
                  formId="my_modal_product"
                  loadingCallback={loadingCallback}
                  navigate={navigate}
                  type="product"
                  saleType={type}
                  updateBrandList={updateBrandList}
                  getTopSaleProductlist={getTopSaleProductlist}
                />
              ) : null}

              {(type === "wholesale") &&
                orderList &&
                orderList.length > 0 &&
                money != "" ? (
                <label
                  htmlFor="my_modal_wholesaler"
                  onClick={handleOpenWholesaler}
                  className={`mt-[22px] border-[#034AFF] border-[1px]  ${wholeSaler ? "bg-[#034AFF] text-white" : "bg-white text-[#034AFF]"} p-[6px] w-full font-nunito font-semibold text-lg text-center cursor-pointer rounded-[5px] capitalize`}
                >
                  Add Wholesaler
                </label>
              ) : null}
              {(type === "booking" || type === "billing") &&
                orderList &&
                orderList.length > 0 &&
                money != "" ? (
                <label
                  htmlFor="my_modal_2"
                  onClick={handleOpen}
                  className={`mt-[22px] border-[#034AFF] border-[1px]  ${customer ? "bg-[#034AFF] text-white" : "bg-white text-[#034AFF]"} p-[6px] w-full font-nunito font-semibold text-lg text-center cursor-pointer rounded-[5px] capitalize`}
                >
                  Add Customer
                </label>
              ) : null}
              {showSeller &&
                (type === "booking" || type === "billing") &&
                orderList &&
                orderList.length > 0 &&
                money != "" ? (
                <label
                  htmlFor="my_modal_seller"
                  onClick={handleOpenSeller}
                  className={`mt-[22px] border-[#034AFF] border-[1px]  ${seller ? "bg-[#034AFF] text-white" : "bg-white text-[#034AFF]"} p-[6px] w-full font-nunito font-semibold text-lg text-center cursor-pointer rounded-[5px] capitalize`}
                >
                  Add Seller
                </label>
              ) : null}
              {type === "purchase" &&
                orderList &&
                orderList.length > 0 &&
                money != "" ? (
                <label
                  htmlFor="my_modal_vendor"
                  onClick={handleOpenVendor}
                  className={`mt-[22px] border-[#034AFF] border-[1px] ${vendor ? "bg-[#034AFF] text-white" : "bg-white text-[#034AFF]"} p-[6px] w-full font-nunito font-semibold text-lg text-center cursor-pointer rounded-[5px] capitalize`}
                >
                  Add Vendor
                </label>
              ) : null}
              {(type === "billing" ||
                vendor ||
                customer ||
                (type === "booking" && (vendor || customer))
                ||
                (type === "wholesale" && wholeSaler)
              ) &&
                orderList &&
                orderList.length > 0 &&
                money !== "" ? (
                <FormPayment
                  formId="PaymentForm"
                  status={status}
                  deliveryAccountList={deliveryAccountList}
                  setStatus={setStatus}
                  subtotal={subTotalNew}
                  deliveryFormData={deliveryFormData}
                  setDeliveryFormData={setDeliveryFormData}
                  invoice={invoice}
                  vats={formatPrice(
                    vat && vat > 0 ? (vat / 100) * Number(subTotalNew) : 0
                  )}
                  type={type}
                  taxes={formatPrice(
                    tax && tax > 0 ? (tax / 100) * Number(subTotalNew) : 0
                  )}
                  customer={customer}
                  seller={seller}
                  wholeSaler={wholeSaler}
                  vendor={vendor}
                  grandTotal={grandTotalAfterDiscount}
                  currentUser={currentUser ? currentUser : null}
                  product={orderList ? orderList : null}
                  discount={totalMoreDiscount}
                  onOrderSubmit={handleSubmitOrder}
                  setInvoice={setInvoice}
                  setWarningMessage={setWarningMessage}
                  selectedValue={selectedValue}
                  handleSelectChange={handleSelectChange}
                  setIsSave={setSaveNoRedirect}
                />
              ) : null}
            </div>
          </div>


          <div className="absolute top-0 right-0 pr-[18px] pt-[18px] pb-[12px]">
            <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
              {grandTotalAfterDiscount}{" "}
              <span className="text-[13px]">
                {invoiceSetting?.currency_info?.code
                  ? invoiceSetting?.currency_info?.code
                  : "BDT"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderProduct;
