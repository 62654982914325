import { useEffect, useState } from "react";
import axios from "axios";

const AddUserDataForm = (props) => {
  const {
    loadingCallback,
    navigate,
    setShowModal,
    isOpen,
    title,
    type,
    formData,
    setFormData,
    refetch,
    modalData,
    setModalData,
    handleModalChange,
    handleModalSubmit,
    handleChange
  } = props;
  const token = localStorage.getItem("token");

  return(
    <div className="duration-500">
      <div className="w-full relative">
        <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
          Name
        </label>
        <input
          type="text"
          name="user_email"
          placeholder="Name"
          value={formData.u_email}
          className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
        />
      </div>
      <div className="w-full relative mt-2">
        <label className="absolute font-nunito text-xs text-[#102048]  -top-[8px] left-[12px] bg-white px-1 font-semibold">
          Password
        </label>
        <input
          type="text"
          name="link"
          onChange={handleChange}
          placeholder="Please Enter Password"
          className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
        />
      </div>
      <div>
        <button
          onClick={handleModalSubmit}
          className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
        >
          Done
        </button>
      </div>
    </div>
  );

}

export default AddUserDataForm;