import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from "../../utils/Modal";
import arrowLeft from "../../assets/images/arrow-left.png";
import manIcon from "../../assets/images/man.png";
import { get, post } from '../../services/apiService.js';
import { formatDate } from '../../utils/Common.js';


const AddExporter = (props) => {
  const {
    loadingCallback,
    id,
    setSuccessFlashMessage,
    setErrorFlashMessage,
    setWarningMessage,
    orderData,
    setAddedProduct,
    addedProduct,
    setAddedCarrier,
    addedCarrier,
    showModalBeforeDelete,
    showModalBeforeDeleteCarrier,
    setPayment,
    productQty,
    receivedQty
  } = props;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => setShowModal(true);
  const [exporterProduct, setExporterProduct] = useState(null);
  const [exporterCarrier, setExporterCarrier] = useState(null);
  const [baseCurrency, setBaseCurrency] = useState(orderData?.base_currency);

  const [showModalProduct, setShowModalProduct] = useState(false);
  const [productData, setProductData] = useState(null);
  const handleShowModalProductModal = (id) => () => {

    getExporterProductDetail(id)

  };

  const getExporterProductDetail = (id) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}exporter-product-details/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setProductData(fetchedData);
          setShowModalProduct(!showModalProduct);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  const [showCarrierModal, setShowCarrierModal] = useState(false);
  const handleCarrierOpen = () => setShowCarrierModal(true);

  const [carrierData, setCarrierData] = useState(null);
  const [showCarrierInfoViewModal, setShowCarrierInfoViewModal] = useState(false);
  const handleShowCarrierInfoView = (id) => () =>  {
    getExporterCarrierDetail(id)
  };
  const getExporterCarrierDetail = (id) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}exporter-carrier-details/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setCarrierData(fetchedData);
          setShowCarrierInfoViewModal(!showCarrierInfoViewModal)
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };

  const [showPayentDetailModal, setShowPayentDetailModal] = useState(false);
  const handleShowPaymentDetails = () => setShowPayentDetailModal(!showPayentDetailModal);

  const orderUpdate = (type='hold') => {
    const formData = {
      id: orderData?.id,
      status: type
    };
    loadingCallback(true);
    // Use your API service functions to make API requests here
    //if(isValid){
    post("save-exporter-order", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          setSuccessFlashMessage("Exporter updated successfully");
          if (type==3) {
            navigate(`/exporter`);
          } else {
          navigate(`/add-exporter/${response.data.id}`);
          }
        } else {
          console.log("Exporter update failed");
          setErrorFlashMessage("Exporter update failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
        console.log(error);
      });
    //}
  };

  const handleSubmit = (e) =>{
    e.preventDefault();
    orderUpdate(3);
    //orderUpdate(paymentSubmitForm);
  }
  const holdSubmit = (e) =>{
    e.preventDefault();
    orderUpdate(2);
  }

  return (
    <>
      {showModal ? (
        <Modal
          isOpen={showModal}
          setOpen={setShowModal}
          setData={setExporterProduct}
          setWarningMessage={setWarningMessage}
          title="Search Product"
          formId="exporter_search_product_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          exporterId={id}
          baseCurrency={baseCurrency}
          type="exporter_search_product"
          exporterOrder={orderData}
          addedProduct={addedProduct}
          setAddedProduct={setAddedProduct}
        />
      ) : null}

      {showModalProduct ? (
        <Modal
          isOpen={showModalProduct}
          setOpen={setShowModalProduct}
          productData={productData}
          baseCurrency={baseCurrency}
          setWarningMessage={setWarningMessage}
          title="Description"
          formId="exporter_product_view_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          exporterId={id}
          type="exporter_product_view"
          exporterOrder={orderData}
        />
      ) : null}

      {showCarrierModal ? (
        <Modal
          isOpen={showCarrierModal}
          setOpen={setShowCarrierModal}
          setData={setExporterCarrier}
          baseCurrency={baseCurrency}
          setWarningMessage={setWarningMessage}
          title="Carrier Info"
          formId="exporter_search_carrier_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          exporterId={id}
          type="exporter_search_carrier"
          exporterOrder={orderData}
          addedCarrier={addedCarrier}
          setAddedCarrier={setAddedCarrier}
          productQty={productQty}
          receivedQty={receivedQty}
        />
      ) : null}

      {showCarrierInfoViewModal ? (
        <Modal
        isOpen={showCarrierInfoViewModal}
        setOpen={setShowCarrierInfoViewModal}
        carrierData={carrierData}
        baseCurrency={baseCurrency}
        setWarningMessage={setWarningMessage}
        title="Description"
        formId="exporter_carrier_info_view_modal"
        loadingCallback={loadingCallback}
        navigate={navigate}
        exporterId={id}
        type="exporter_carrier_info_view"
        exporterOrder={orderData}
      />
      ) : null}

      {showPayentDetailModal ? (
        <Modal
        isOpen={showPayentDetailModal}
        setOpen={setShowPayentDetailModal}
        baseCurrency={baseCurrency}
        setWarningMessage={setWarningMessage}
        setSuccessFlashMessage={setSuccessFlashMessage}
        setErrorFlashMessage={setErrorFlashMessage}
        title="Payment Details"
        formId="exporter_payment_detail_modal"
        loadingCallback={loadingCallback}
        navigate={navigate}
        exporterId={id}
        type="exporter_payment_detail"
        exporterOrder={orderData}
        setPayment={setPayment}
        />
      ) : null}

      <div className="bg-[#F8F9FA] rounded-[16px] px-4 py-4 mt-[30px]">
        <div className="flex flex-row gap-3 items-center mb-4">
          <Link to={`/exporter`}>
            <img src={arrowLeft} />
          </Link>

          <div className="font-nunito font-[600] text-[22px] text-[#000232]">
            Exporter Info
          </div>
        </div>

        <div className="rounded-[20px] border-[1px] border-solid border-[#C1CFEF] p-[30px]">
          <div className="flex flex-col lg:flex-row gap-3">
            <div className="w-[30%]">
              <div className="flex flex-row gap-3 items-center">
                <div className="w-[80px] ">
                  <img src={manIcon} className="w-[100%] rounded-[40px]" />
                </div>
                <div className="font-nunito">
                  <h2 className="text-[#000] font-[700] text-[20px] mb-1">
                    {orderData?.name}
                  </h2>
                  <div className="text-[#6F8EB6] text-[18px] fonnt-[500]">
                    #{orderData?.exporter_id}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="font-nunito mb-3">
                <div className="font-[700] text-[12px] text-[#6F8EB6] mb-1">
                  Email
                </div>
                <div className="font-[400] text-[12px] text-[#000000]">
                  {orderData?.email}
                </div>
              </div>

              <div className="font-nunito">
                <div className="font-[700] text-[12px] text-[#6F8EB6]">
                  Address
                </div>
                <div className="font-[400] text-[16px] text-[#000000]">
                  {orderData?.address}
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="font-nunito mb-3">
                <div className="font-[700] text-[12px] text-[#6F8EB6] mb-1">
                  Phone
                </div>
                <div className="font-[400] text-[16px] text-[#000000]">
                  {orderData?.phone_no}
                </div>
              </div>

              <div className="font-nunito">
                <div className="font-[700] text-[12px] text-[#6F8EB6]">
                  Additional Phone
                </div>
                <div className="font-[400] text-[16px] text-[#000000]">
                 {orderData?.additional_phone_no}
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="font-nunito mb-3">
                <div className="font-[700] text-[12px] text-[#6F8EB6] mb-1">
                  Organization
                </div>
                <div className="font-[400] text-[16px] text-[#000000]">
                  {orderData?.org_name}
                </div>
              </div>

              <div className="font-nunito">
                <div className="font-[700] text-[12px] text-[#6F8EB6]">
                  Organization Email
                </div>
                <div className="font-[400] text-[16px] text-[#000000]">
                  {orderData?.org_email}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-3 items-center mt-4">
          <div className="font-nunito font-[600] text-[24px] text-[#000232]">
            Add Product Info
          </div>
          <label onClick={handleOpen} htmlFor="exporter_search_product_modal">
            <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-3 py-2 rounded-[10px] cursor-pointer"></i>
          </label>
        </div>

        <div className="mt-5">
          <div className="grid grid-cols-5 sm:grid-cols-7 gap-4 font-nunito font-bold text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
            <div className="py-[8px]">
              Product
              <br />
              Name
            </div>
            <div className="py-[8px] text-center">
              Category
              <br />
              Name
            </div>
            <div className="py-[8px] text-center hidden sm:block">Subcategory</div>
            <div className="py-[8px] text-center hidden sm:block">
              Booking
              <br />
              Date
            </div>
            <div className="py-[8px] text-center">QTY</div>
            <div className="py-[8px] text-center">
              Unit Purchase
              <br />
              Price
            </div>
            <div className="py-[8px] text-right">Action</div>
          </div>
          {orderData?.exporter_products ? orderData?.exporter_products.map((product, index) => (
          <div className="grid grid-cols-5 sm:grid-cols-7 gap-4 items-center font-nunito font-[500] text-[14px] text-[#000000] border-b-[1px] border-solid border-[#E7EBF4]">
            <div className="py-[15px]">{product?.product_name}</div>
            <div className="py-[15px] text-center">{product?.category_name}</div>
            <div className="py-[15px] text-center hidden sm:block">{product?.sub_category}</div>
            <div className="py-[15px] text-center hidden sm:block">{product?.booking_date}</div>
            <div className="py-[15px] text-center">{product?.qty}</div>
            <div className="py-[15px] text-center">{product?.total_purchase_price.toFixed(2)}{" "}{orderData?.currency_rate===null ? product?.purchase_currency : orderData?.base_currency}</div>

            <div class="text-[12px] py-[15px] flex flex-col lg:flex-row gap-2 font-bold justify-end">

              <a
                onClick={handleShowModalProductModal(product.id)} htmlFor="exporter_product_view_modal"
                class="ml-auto lg:ml-0 w-[46px] text-center border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] py-[2px]"
                href="#"
              >
                VIEW
              </a>
              <a onClick={() => showModalBeforeDelete(product?.id, index)}
                class="ml-auto lg:ml-0 w-[49px] text-center text-[#FFF] hover:text-[#FFF] bg-[#FF0303] rounded-[5px] py-[2px]"
                href="#"
              >
                Delete
              </a>
            </div>
          </div>
          )) : null}
        </div>

        <div className="flex flex-row gap-3 items-center mt-4">
          <div className="font-nunito font-[600] text-[24px] text-[#000232]">
            Carrier Info
          </div>
          <label onClick={handleCarrierOpen} htmlFor="exporter_search_carrier_modal">
            <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-3 py-2 rounded-[10px] cursor-pointer"></i>
          </label>
        </div>

        <div className="mt-5">
          <div className="grid grid-cols-5 sm:grid-cols-6 items-center gap-4 font-nunito font-bold text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
            <div className="py-[8px]">
              Carrier
              <br />
              Name
            </div>
            <div className="py-[8px] text-center">
              Carrier
              <br />
              Email
            </div>
            <div className="py-[8px] text-center hidden sm:block">
              Phone
              <br />
              Number
            </div>
            <div className="py-[8px] text-center">QTY</div>

            <div className="py-[8px] text-right">Action</div>
          </div>
          {orderData?.exporter_carriers ? orderData?.exporter_carriers.map((carrier, index) => (
            <div className="grid grid-cols-5 sm:grid-cols-6 items-center gap-4 font-nunito font-[500] text-[14px] text-[#000000] border-b-[1px] border-solid border-[#E7EBF4]">
              <div className="py-[15px]">{carrier?.name}</div>
              <div className="py-[15px] text-center">{carrier?.email}</div>
              <div className="py-[15px] text-center  hidden sm:block">{carrier?.phone_no}</div>
              <div className="py-[15px] text-center">{carrier?.received_qty}</div>

              <div class="text-[12px] py-[15px] flex flex-col lg:flex-row gap-2 font-bold justify-end">
                <a
                  onClick={handleShowCarrierInfoView(carrier.id)} htmlFor="exporter_carrier_info_view_modal"
                  class="ml-auto lg:ml-0  border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] py-[2px]"
                  href="#"
                >
                  VIEW
                </a>
                <a
                  onClick={() => showModalBeforeDeleteCarrier(carrier?.id, index)}
                  class="ml-auto lg:ml-0  text-[#FFF] hover:text-[#FFF] bg-[#FF0303] rounded-[5px] px-[5px] py-[2px]"
                  href="#"
                >
                  Delete
                </a>
              </div>
            </div>
          )) : null}
        </div>

        <div className="flex flex-row gap-3 items-center mt-4">
          <div className="font-nunito font-[600] text-[24px] text-[#000232]">
            Payment Info
          </div>
          <label onClick={handleShowPaymentDetails} htmlFor="exporter_payment_detail_modal">
            <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-3 py-2 rounded-[10px] cursor-pointer"></i>
          </label>
        </div>

        <div className="mt-5">
          <div className="grid grid-cols-4 sm:grid-cols-6 gap-4 font-nunito font-bold text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
            <div className="py-[8px]">
              Payment
              <br />
              Date
            </div>
            <div className="py-[8px] text-center hidden sm:block">
              Purchase
              <br />
              Currency
            </div>
            <div className="py-[8px] text-center hidden sm:block">
              Base
              <br />
              Currency
            </div>

            <div className="py-[8px] text-center">
              Total
              <br />
              Amount
            </div>

            <div className="py-[8px] text-center">
              Paid
              <br />
              Amount
            </div>
            <div className="py-[8px] text-right">Due<br/>Amount</div>
          </div>
          {orderData?.exporter_payments && Array.isArray(orderData.exporter_payments) ? orderData?.exporter_payments?.map((payment, index) => (
          <div className="grid grid-cols-4 sm:grid-cols-6 items-center gap-4 font-nunito font-[500] text-[14px] text-[#000000] border-b-[1px] border-solid border-[#E7EBF4]">
            <div className="py-[15px]">{formatDate(payment?.payment_date)}</div>
            <div className="py-[15px] text-center hidden sm:block">{payment?.purchased_currency}</div>
            <div className="py-[15px] text-center hidden sm:block">{orderData?.base_currency}</div>
            <div className="py-[15px] text-center">{payment?.total_amount?.toFixed(2)}{orderData?.base_currency}</div>
            <div className="py-[15px] text-center">{payment?.paid_amount?.toFixed(2)}{orderData?.base_currency}</div>
            <div className="py-[15px] text-right">{payment?.due_amount?.toFixed(2)}{orderData?.base_currency}</div>
          </div>
          )) : null}

        </div>

        <div className="flex flex-col lg:flex-row items-center gap-3 max-w-[600px] mt-[100px] ml-auto mr-auto">
          {orderData?.exporter_payments?.length > 0 &&
          orderData?.exporter_products?.length > 0 &&
          orderData?.exporter_carriers?.length > 0 ? (
            <button
              type="button"
              onClick={holdSubmit}
              // ... other button attributes
              className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
            >
              Hold Process
            </button>
          ) : (
            <button
              type="button"
              // ... other button attributes
              className="btn w-[48%] text-[#ccc] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize cursor-not-allowed opacity-50"
              disabled
            >
              Hold Process
              <span
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden text-red-500"
                role="img"
                aria-label="Cross Icon"
              >
                &#x2716;
              </span>
            </button>
          )}
          {orderData?.exporter_payments?.length > 0 &&
          orderData?.exporter_products?.length > 0 &&
          orderData?.exporter_carriers?.length > 0 &&
          orderData.status != 3 ? (
            <button
            type="button"
            onClick={handleSubmit}
            className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
          >Complete Process
          </button>
          ) : (
            <button
            type="button"
            className="btn w-[48%] text-[#ccc] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize cursor-not-allowed opacity-50"
          >Complete Process
            <span
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden text-red-500"
              role="img"
              aria-label="Cross Icon"
            >
              &#x2716;
            </span>
          </button>
          )}

        </div>
      </div>
    </>
  );
};

export default AddExporter;
