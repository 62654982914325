import classNames from 'classnames';
import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { useFlashMessage } from "../context/FlashMessageContext";
//import Select2 from "react-chosen";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { post } from "../services/apiService";
import { useNavigate } from 'react-router-dom';
import FlagIcon from "./FlagIcon";
import { faToggleOn } from '@fortawesome/free-solid-svg-icons';
import MemberShipLogo from './Group 7109.png';
import { getDialCodeByCountryCode } from '../utils/Common';
import { FlashMessage } from '../utils/FlashMessage';
import { ToastContainer, toast } from 'react-toastify';
import { AppContext } from "../context/context";


const NewCustomerForm = props => {
  const {
    loadingCallback,
    setCustomer,
    setWarningMessage,
    setShowModal,
    setCustomerData,
    customerData
  } = props;

  // Gneral Focus Hook
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
  }
  const [inputRef, setInputFocus] = useFocus();
  const { state } = useContext(AppContext);
  const { countryList } = state;
  const [country, setCountry] = useState('');
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState('');
  const [cityList, setCityList] = useState([]);
  const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [showNewCustomerForm, setShowNewCustomerForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const page = 1;
  const perPage = 20;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.country) {
      setCountry(selectedCustomer.country);
      getDistrictList(selectedCustomer.country);
    } else {
      getDistrictList(country);
    }

  }, [country, selectedCustomer]);

  const getDistrictList = (country) => {
    post("get-district-by-country", { country_id: country }, token)
      .then((response) => {
        if (response.success) {
          setDistrictList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityList = (district) => {
    setDistrict(district);
    post("get-cities-by-district", { district_id: district }, token)
      .then((response) => {
        if (response.success) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };











  const handleValidation = (formData) => {
    const errors = {};
    let validate = true
    // if (formData.customer_email === '') {
    //   errors.customer_email = 'Email is required.';
    //   setIsValid(false);
    //   validate = false;
    // }
    if (formData.customer_email && formData.customer_email !== '') {
      if (formData.customer_email.indexOf('@') === -1) {
        errors.customer_email = 'Email is not valid.';
        setIsValid(false);
        validate = false;
      }  else {
        //Email address cannot have only numbers before @
        const emailParts = formData.customer_email.split('@');
        if (emailParts[0].match(/^[0-9]+$/) !== null) {
          errors.customer_email = 'Email is not valid.';
          setIsValid(false);
          validate = false;
        }
      }
    }
    if (formData.customer_name === '') {
      errors.customer_name = 'Customer name is required.';
      setIsValid(false);
      validate = false;

    }

    if (formData.customer_phone === '') {
      errors.customer_phone = 'Phone Number is required.';
      setIsValid(false);
      validate = false;
    }
    setFormErrors(errors);
    if (!validate) {
      // setErrorFlashMessage('Please fill all the required fields.');
      FlashMessage('Please fill all the required fields.','error');
    }

    return validate;
  };

  const handleSubmit =  (event) => {
    //event.preventDefault();
    if (event) {
      event.preventDefault(); // Prevent form submission if event is provided
    }
    // setCustomerData('ok');
    // return;


    const formData = new FormData(event.target);
    const formObject = {};

    formData.forEach((value, key) => {
      formObject[key] = value;
    });
    if (!selectedCustomer) {
      formObject.cutomer_id = 0;
    }

    console.log('FormObject ',formObject);


    if (handleValidation(formObject)) {
      const customerData = {
        name: formObject.customer_name ? formObject.customer_name : null,
        email: formObject.customer_email ? formObject.customer_email : null,
        country_code: formObject.country_code ? formObject.country_code : null,
        mobile_number: formObject.customer_phone ? formObject.customer_phone : null,
        address: formObject.customer_address ? formObject.customer_address : null,
        country: formObject.country_id ? Number(formObject.country_id) : null,
        district: formObject.district_id ? Number(formObject.district_id) : null,
        city: formObject.city_id ? Number(formObject.city_id) : null,
        zipcode: formObject.customer_zip ? Number(formObject.customer_zip) : null,
        nid: formObject.nid ? Number(formObject.nid) : null,
      };
      createCustomer(customerData);

    } else {
      console.error('Form validation failed.');
    }

  };

  const createCustomer = (formData) => {
    loadingCallback(true);
    // setCustomerData(formData);
    // setCustomer(formData);
    post("save-customer", formData, token)
      .then((response) => {
        loadingCallback(false);
        if (response.status === 200) {
          setCustomerData(response.data);
          setCustomer(response.data);
          //setShowModal(false);
          // setSuccessFlashMessage('customer saved successfully');
          FlashMessage('customer saved successfully','success');
          //setShowNewCustomerForm(false);
        } else {
          console.log("customer insertion failed");

          setWarningMessage(true);
          const timer = setTimeout(() => {
            setWarningMessage(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.setItem('token', '');
          localStorage.setItem('token', '');
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
        console.log(error);
      });
  }


  const handleErrorInputChange = (fieldName, value) => {
    if (value !== '') {
      //formErrors[fieldName] = null;
      // unset formErrors by fieldName
      const updatedFormErrors = { ...formErrors };
      delete updatedFormErrors[fieldName];
      setFormErrors(updatedFormErrors);
    }

  }


  return (
    <>
    <form
      method="get"
      className="col-lg-12 text-[#91919F] overflow-scroll"
      data-autosubmit="false"
      autoComplete="off"
      onSubmit={handleSubmit}
    >

      <ToastContainer />
      <div className='flex gap-3'>
        <div className="mb-3">
          <input
            type="text"
            id="customer_name"
            name="customer_name"
            className={classNames(
              'form-control',
              'rounded-[15px]',
              'p-3',
              'bg-white'

            )}
            placeholder="Customer Name"
            ref={inputRef}
            onChange={(e) => {
              handleErrorInputChange('customer_name', e.target.value)
            }}
          />
          {formErrors && formErrors.customer_name ? <p className="error-message text-red-500">{formErrors.customer_name}</p> : null}
        </div>
        <div className="mb-3">
          <input
            type="text"
            id="customer_email"
            name="customer_email"
            className={classNames(
              'form-control',
              'rounded-[15px]',
              'p-3',
              'bg-white'

            )}
            placeholder="Customer Email"
            ref={inputRef}
            onChange={(e) => {
              //delete formErrors.customer_email;
              handleErrorInputChange('customer_email', e.target.value)
            }}
          />
          {formErrors && formErrors.customer_email ? <p className="error-message text-red-500">{formErrors.customer_email}</p> : null}
        </div>
      </div>
      <div className="mb-3">
        <div className="flex ">
          <div className="flex-grow w-4/5">
          <input
            type="text"
            id="customer_phone"
            name="customer_phone"
            className={classNames(
              'form-control',
              'rounded-[15px]',
              'p-3',
              'bg-white'

            )}
            placeholder=" Phone Number"
            ref={inputRef}
            onChange={(e) => {
              handleErrorInputChange('customer_phone', e.target.value)
            }}
          />
          {formErrors && formErrors.customer_phone ? <p className="error-message text-red-500">{formErrors.customer_phone}</p> : null}
          </div>
        </div>

      </div>

      {/* {country select box} */}
      <div className="mb-3">
        <select
          id="country_id"
          name="country_id"
          className={classNames(
            'w-full',
            'rounded-[15px]',
            'p-3',
            'bg-white'
          )}
          style={{ border: "1px solid #ccc" }}
          onChange={e => setCountry(e.target.value)}
        >
          <option value="">Select Country</option>
          {countryList.map((country) => (
            <option key={country.id} value={country.id}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <div className='flex gap-3'>
        <div className="mb-3 flex-1">
          <input
            type="text"
            id="customer_address"
            name="customer_address"
            className={classNames(
              'form-control',
              'rounded-[15px]',
              'pt-3',
              'pb-3',
              'bg-white'
            )}
            placeholder="Customer Address"
            ref={inputRef}
          />
        </div>
        <div className="mb-3 flex-1">
          <select
            id="district_id"
            name="district_id"
            className={classNames(
              'rounded-[15px]',
              'p-3',
              'bg-white',
              'mr-2'
            )}
            style={{ border: "1px solid #ccc" }}
            onChange={e => getCityList(e.target.value)}
          >
            <option value="">Branch District</option>
            {districtList.map((district) => (
              <option key={district.id} value={district.id}>
                {district.name}
              </option>
            ))}
          </select>
        </div>
      </div>




      <div className='flex gap-3'>
        <div className="mb-3 flex-1">
          <select
          id="city_id"
          name="city_id"
          className={classNames(
            'w-full',
            'rounded-[15px]',
            'p-3',
            'bg-white'
          )}
          style={{ border: "1px solid #ccc" }}
        >
          <option value="">Select City</option>
          {cityList.map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </select></div>
        <div className='mb-3 flex-1'>
          <input
            type="text"
            id="customer_zip"
            name="customer_zip"
            className={classNames(
              'form-control',
              'rounded-[15px]',
              'p-3',
              'bg-white',

            )}
            placeholder="Zip Code"
            ref={inputRef}
          />
        </div>
      </div>


      <div className='flex justify-between items-center'>
        <div className='flex gap-1 mb-3'>
        <div className='w-[45px] h-[45px]'><img src={MemberShipLogo} alt="MemberShipLogo" />
        </div>
        <div className='font-nunito text-[#102048]'><h1 className='font-semibold text-[18px]'>Membership</h1> <p className='text-[12px]'>Members Can addittional discount and ...</p></div>
        </div>
        <div  className="w-[40px] h-[25px] text-[#BF9950]  "><FontAwesomeIcon icon={faToggleOn} className="text-3xl"/></div>
      </div>

      <div >{/*className="button-disabled" */}
        <button
          //htmlFor='my_modal_2'
          type="submit"
          className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
        >Save
        </button>
      </div>

    </form>
    </>
  );
};

export default NewCustomerForm;
