import React, { useState } from 'react';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { IoPrint } from 'react-icons/io5';

const WareHouseDetailsForm = (props) => {
  const {formData,warehouseData} = props;
  const [dropdownPdf, setDropdownPdf] = useState(false);
  const [dropdownPrint, setDropdownPrint] = useState(false);
  const creationDate = new Date(warehouseData.created_at).toISOString().split('T')[0]
  return (
    <div>
       <div class="flex flex-row justify-between items-center py-3 px-1">
        <h1 class="text-md font-semibold font-lato pb-1 px-1">Address</h1>
        <div class="">
          <p class="text-md px-[4px] py-[2px] font-semibold">{creationDate}</p>
        </div>
      </div>
      <div className="font-lato text-[15px] py-2 px-2">
          <div className="font-[400] text-[#808080]">{formData?.address || ''}</div>
      </div>
      <div className="mb-[0px] pb-10 px-2">
        <div className="flex flex-row gap-2 font-nunito font-bold text-[13px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
          <div className="py-[5px] flex-1">
            Warehouse Name
          </div>
          <div className="py-[5px] text-right w-[30%]">Phone Number</div>
        </div>
        <div className="flex flex-row gap-2 font-nunito font-[500] text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
          <div className="py-[5px] flex-1">{formData?.name}</div>
          <div className="py-[5px] text-right flex-1">{formData?.phone || ''}</div>
        </div>
      </div>
      {/* Download and Print */}
      <div className='flex gap-10  justify-between text-xs md:text-sm lg:text-base mt-6'>
          <div className={`flex-1 transform duration-300 ${dropdownPdf || dropdownPrint ? "!h-[270px] md:!h-[300px]" : "h-[90px]"} space-y-2 relative`}>
              <button onClick={() => setDropdownPdf(dropdownPdf)} className="flex justify-center gap-1 md:gap-3 items-center bg-[#034AFF] text-[#f1effc] absolute z-10 !px-1 py-3 w-full rounded-xl font-bold shadow-md">
                  <span><FaCloudDownloadAlt size={22}/></span> Download PDF <div className={`transform duration-300 ${dropdownPdf ? "-rotate-180" : ""}`}></div>
              </button>
          </div>
          <div className={`flex-1 space-y-2 transform duration-300 ${dropdownPdf || dropdownPrint ? "!h-[270px] md:!h-[300px]" : "h-[90px]"} relative`}>
              <button onClick={() => setDropdownPrint(dropdownPrint)} className="bg-[#034AFF] flex justify-center items-center gap-1 md:gap-3 text-[#f1effc] font-bold shadow-md absolute z-10 !px-1 py-3 w-full rounded-xl">
                  <span><IoPrint size={22}/></span> Print <div className={`transform duration-300 ${dropdownPrint ? "-rotate-180" : ""}`}></div>
              </button>
          </div>
      </div>
    </div>
  );
};

export default WareHouseDetailsForm;