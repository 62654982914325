import React, { useEffect, useState,useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ContentLayout from "../../layout/ContentLayout";
import arrowLeft from "../../assets/images/arrow-left.png";
// import { useParams } from "react-router-dom";
//import CommonUploader from "./CommonUploader";
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

const EmployeeViewPage = () => {

  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  const navigate = useNavigate();

  // useEffect(() => {
  //   getEmployeeDetails(employeeId)
  // }, [employeeId]);

  //---------------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");
    if (!employeeApiResponse) {
      setHasPermission(true);
      return;
    }
    if (employeeApiResponse) {
      const employeeData = JSON.parse(employeeApiResponse);
      const hrmFeature = employeeData.role.features.find(
        (feature) => feature.name === "HRM"
      );
      if (hrmFeature && hrmFeature.status === 1) {
        const viewHrmOptionActive = hrmFeature.feature_options.find(
          (option) => option.name === "View" && option.status === 1
        );
        if (viewHrmOptionActive && location.pathname === "employee-view/:id") {
          setHasPermission(true);
          return;
        }
      }
      setHasPermission(false);
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
  }, [navigate, location]);
  //---------------------

  useEffect(() => {
    if (id) {
      if(hasPermission){
        getEmployeeDetails(id);
        getRoleList();
      }
    }
  }, [id,hasPermission]);

  const [employeeData, setEmployeeData] = useState(null);

  const getEmployeeDetails = (id) => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}employee/${id}`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        console.log("employee details",response.data.data);
        const employees = response.data.data;
        // const matchedEmployee = employees.find(
        //   (employee) => employee.id === parseInt(id)
        // );
        loadingCallback(false)
        //setEmployeeData(response?.data?.data?.data)
        setEmployeeData(employees);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };

  const [roleData, setRoleData] = useState(null);

  const getRoleList = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}role-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        console.log("roles:",response.data.data);
        setRoleData(response.data.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };

  return(
    <ContentLayout>
      <div className="main-content recentTableContent">
        <div className="flex flex-row gap-2 items-center mb-5">
          {/* <Link className="block w-[24px]" to={`/employee`}>
            <img src={arrowLeft} className="w-[100%]" />
          </Link> */}
          <h1 className='text-[#000232] text-[22px] font-[600] font-lato'>Employee Details</h1>
        </div>
        {/* SHOW IMAGE HERE */}
        {employeeData?.emp_image && (
          <div className="text-center flex items-center justify-center py-4">
            <img
              src={employeeData.emp_image}
              alt="Employee"
              className="w-[140px] h-[140px] object-cover rounded-full"
            />
          </div>
        )}
        {/* <div className="text-center flex items-center justify-center py-4">
          <CommonUploader
            name={"image"}
            isBig={true}
            image={employeeData.image ? employeeData.image : null}
            className="w-[140px]"
          ></CommonUploader>
        </div> */}
        <div class="flex flex-row justify-between items-center py-2 px-1">
            <h1 class="text-md font-semibold font-lato pb-1 px-1">Personal Information</h1>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Name:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.name}</div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Email Address:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.email}</div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Phone Number:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.mobile_number}</div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Date of Birth:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.dob}</div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Blood Group:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.blood_group_id_info?.blood_group_name}</div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Address:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.address}</div>
          </div>
        </div>
        <div class="flex flex-row justify-between items-center py-2 px-1 mt-2">
          <h1 class="text-md font-semibold font-lato pb-1 px-1">Emergency Contact</h1>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Contact Person:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.relation_with}</div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Phone Number:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.emergency_mobile_number}</div>
          </div>
        </div>
        <div class="flex flex-row justify-between items-center py-2 px-1 mt-2">
          <h1 class="text-md font-semibold font-lato pb-1 px-1">Personal Information</h1>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Working Email:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.work_email}</div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Employee ID:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.employee_id}</div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Designation:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.designation_info?.name || "No Designation"}</div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Role:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{roleData?.find(role => String(role.id) === employeeData?.role_id)?.name || "No Role Assigned"}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Department:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.department_info?.name || "No Department"}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Warehouse:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.warehouse_info?.name || "No Warehouse"}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Working Days:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.working_day}</div>
          </div>
        </div>
        <div class="flex flex-row justify-between items-center py-2 px-1 mt-2">
          <h1 class="text-md font-semibold font-lato pb-1 px-1">Career Information</h1>
        </div>
        <div className="flex items-center space-x-4 py-2 px-2">
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080]">Joining Date:</div>
          </div>
          <div className="font-lato text-[15px]">
            <div className="font-[400] text-[#808080] font-semibold">{employeeData?.joining_date}</div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
}

export default EmployeeViewPage;