import React from 'react'
import ContentLayout from '../../layout/ContentLayout'
import Footer from '../../components/footer/Index'
import "./index.css"
import SettingsComp from '../../components/settings/SettingsComp'
const SettingsPage= () => {
    return (
    <>
        <ContentLayout>
            {/* settings component */}
            <div className="main-content">
                <SettingsComp />
                <Footer />
            </div>
        </ContentLayout>
    </>
    );
};
export default SettingsPage