import axios from "axios";
import { useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { formatDate } from "../utils/Common";

import { IoPrint } from "react-icons/io5";
import Swal from "sweetalert2";
import moment from "moment";

const ViewFinanceForm = ({formData,selectType}) => {

  return (
    <div className="duration-500 px-[15px] font-nunito">
      <div className="flex items-center mt-2  text-base font-semibold justify-between py-2 gap-3">
        <h2>Asset Description</h2>{" "}
        <p>
          {selectType === "chartOfAcc" || selectType === "partyNameList"
            ? formatDate(formData.created_at)
            : `TRX ID : ${formData.id}`}{" "}
        </p>
      </div>
      <div className="py-2 gap-3 text-[#91919F]">
        {formData.head_description || formData.transaction_description || formData.description}
      </div>
      <div>
        <div className="table-conatiner text-[12px]">
          {selectType === "chartOfAcc" ? (
            <>
              <div className="grid grid-cols-2 gap-2 items-center font-nunito font-[700] text-[#000232]">
                <div className="py-[8px]">Type of Transaction</div>
                <div className="py-[8px] text-right">Amount of Transaction</div>
              </div>
              <div className="border-t-[1px] border-solid font-[700] border-[#E7EBF4] py-[8px] font-nunito text-[#000232] grid grid-cols-2 items-start">
                <div>
                  <div className="flex flex-col sm:flex-row gap-2">
                    <div className="font-nunito ">
                      <div className="capitalize text-black">
                        {formData?.head_name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-right">
                    {formData.acc_head_transaction_sum_transaction_amount || 0}{" "}
                    BDT
                  </div>
                </div>
              </div>
            </>
          ) : selectType === "expense" ? (
            <>
              <div className="grid grid-cols-5 gap-2 items-start font-nunito font-[700] text-[#000232]">
                <div className="py-[8px]">Name of Expense</div>
                <div className="py-[8px]">Expense Type</div>
                <div className="py-[8px] text-center">Transaction Type</div>
                <div className="py-[8px] text-center">Transaction Category</div>
                <div className="py-[8px] text-right">Amount of Transaction</div>
              </div>
              <div className="border-t-[1px] font-bold border-solid border-[#E7EBF4] py-[8px] font-nunito  text-[#000232] grid grid-cols-5 items-start">
                <div>
                  <div className="flex flex-col sm:flex-row gap-2">
                    <div className="font-nunito ">
                      <div className="capitalize text-black">
                        {formData?.transaction_name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">{formData?.expense_types?.expense_name}</div>
                <div className={`text-center`}>
                  {formData?.expense_types?.expense_name}
                </div>
                <div
                  className={`text-center ${
                    formData.transaction_category === "In"
                      ? "text-[#0B8B00]"
                      : "text-[#FF0303]"
                  }`}
                >
                  {formData?.expense_types?.transaction_category}
                </div>
                <div className="text-right">
                  <div className="text-right">
                    {formData.transaction_amount || 0}
                  </div>
                </div>
              </div>
            </>
          ) : selectType === "partyNameList" ? (
            <>
            <div className="grid grid-cols-2 gap-2 items-center font-nunito font-[700] text-[#000232]">
              <div className="py-[8px]">Last Paid amount</div>
              <div className="py-[8px] text-right">Total Paid amount</div>
            </div>
            <div className="border-t-[1px] border-solid font-[700] border-[#E7EBF4] py-[8px] font-nunito text-[#000232] grid grid-cols-2 items-start">
              <div>
                <div className="flex flex-col sm:flex-row gap-2">
                  <div className="font-nunito ">
                    <div className="capitalize text-black">
                      {formatDate.last_transaction_amount || 0}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right">
                <div className="text-right">
                  {formData.expense_history_sum_transaction_amount || 0}
                </div>
              </div>
            </div>
          </>
          ) : (
            <>
              <div className="grid grid-cols-4 gap-2 items-center font-nunito font-[700] text-[#000232]">
                <div className="py-[8px]">Name of Transaction</div>
                <div className="py-[8px]">type of Transaction</div>
                <div className="py-[8px] text-center">Transaction Category</div>
                <div className="py-[8px] text-right">Amount of Transaction</div>
              </div>
              <div className="border-t-[1px] font-bold border-solid border-[#E7EBF4] py-[8px] font-nunito  text-[#000232] grid grid-cols-4 items-start">
                <div>
                  <div className="flex flex-col sm:flex-row gap-2">
                    <div className="font-nunito ">
                      <div className="capitalize text-black">
                        {formData?.transaction_name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">{formData?.transaction_type?.head_name}</div>
                <div
                  className={`text-center ${
                    formData.transaction_category === "In"
                      ? "text-[#0B8B00]"
                      : "text-[#FF0303]"
                  }`}
                >
                  {formData.transaction_category}
                </div>
                <div className="text-right">
                  <div className="text-right">
                    {formData.transaction_amount}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="hrtable"></div>
        </div>
      </div>
      <div>
        <div className="flex justify-between gap-3 items-center">
          <button
            onClick={() => Swal.fire("Download option coming soon.")}
            className="cursor-pointer flex justify-center items-center gap-2 w-full mt-4 bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-md"
          >
            <FaCloudDownloadAlt size={22} /> Download
          </button>
          <button
            onClick={() => Swal.fire("Printing option coming soon.")}
            className="cursor-pointer flex justify-center items-center gap-2 w-full mt-4 bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-md"
          >
            <IoPrint size={22} /> Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewFinanceForm;
