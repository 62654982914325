import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./index.css";
import axios from "axios";
import { BeatLoader } from 'react-spinners';
import { Button, Form, Input, Checkbox } from "antd";
const BASE_URL = process.env.REACT_APP_ROOT_URL;
function PasswordVerify(props) {
  const navigate = useNavigate();
  const [warningMessage, setWarningMessage] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    email: "",
    phone: "",
    passwordMismatch: false,
  });
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };


  const outletName = localStorage.getItem("outletname");
  const ownerName = localStorage.getItem("ownername");
  const phoneNumber = localStorage.getItem("phoneNumber");
  const phoneNumberWithoutPrefix = phoneNumber.replace(/^(\+88)/, '');
  const email = localStorage.getItem("email");

  const onFinish = (values) => {
    if (values.password !== values.confirmpassword) {
      setErrorMessages({
        ...errorMessages,
        passwordMismatch: "Password mismatch",
      });
      setWarningMessage(true);
      const timer = setTimeout(() => {
        setWarningMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      loadingCallback(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}user-registration`,
          {
            outletName: outletName,
            ownerName: ownerName,
            phone: phoneNumberWithoutPrefix,
            email: email,
            password: values.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);

          const credentials = {
            email: email,
            password: values.password,
          };
          if (response.data.status === 400) {
            // Handle specific errors
            loadingCallback(false);
            const errorData = response?.data?.errors;
            if (errorData?.email) {
              setErrorMessages({
                ...errorMessages,
                email: errorData.email[0],
              });
            }
            else if (errorData?.phone) {
              setErrorMessages({
                ...errorMessages,
                phone: errorData.phone[0],
              });
            } else {
              setErrorMessages({
                ...errorMessages,
                email: response?.data[0] ? response?.data[0] : null,
                phone: response?.data[1] ? response?.data[1] : null
              });
            }
            setWarningMessage(true);
            const timer = setTimeout(() => {
              setWarningMessage(false);
            }, 3000);
            return () => clearTimeout(timer);
          } else if (response.data.status === 200) {
            //loadingCallback(true);
            axios
              .post(`${process.env.REACT_APP_API_URL}user-login`, credentials)
              .then((response) => {
                console.log(response);
                loadingCallback(false);
                localStorage.setItem(
                  "token",
                  response.data.authorisation.token
                );
                navigate("/set-pin");
                //history('/dashboard');
              })
              .catch((error) => {
                  setWarningMessage(true);
                  loadingCallback(false);
                  const timer = setTimeout(() => {
                    setWarningMessage(false);
                  }, 3000);
                  return () => clearTimeout(timer);
              });

            // localStorage.setItem("token", response.data.authorization?.token);
          }
        })
        .catch((error) => {
          loadingCallback(false);
          if (error.response.data.status === 400) {
            // Handle specific errors
            const errorData = error.response?.data?.errors;
            if (errorData?.email) {
              setErrorMessages({
                ...errorMessages,
                email: errorData.email[0],
              });
            }
            else if (errorData?.phone) {
              setErrorMessages({
                ...errorMessages,
                phone: errorData.phone[0],
              });
            } else {
              setErrorMessages({
                ...errorMessages,
                email: error?.response?.data?.data[0] ? error?.response?.data?.data[0] : null,
                phone: error?.response?.data?.data[1] ? error?.response?.data?.data[1] : null
              });
            }
            setWarningMessage(true);
            const timer = setTimeout(() => {
              setWarningMessage(false);
            }, 3000);
            return () => clearTimeout(timer);
          }
          console.log(error);
        });
    }
  };
  // const onFinishFailed = (errorInfo) => {
  //     console.log('Failed:', errorInfo);
  // };
  return (
    <section style={{ background: "#000" }}>
      <div className="passverify-container">
        <div className="passverify-left">
          <img
            src={`${BASE_URL}/assets/registration/webp/passverify.webp`}
            alt="passverifyimage"
          />
        </div>
        <div className="passverify-right">
          <div className="passverify-right-card">
            <div className="passverify-log-img">
              <img src={`${BASE_URL}/assets/registration/webp/logo1.webp`} alt="logo" />
            </div>
            <div className="passverify-desctitle">
              <p>
                Set Up A Password For <br></br> Secure Your Account
              </p>
            </div>

            <div className="passverify-description">
              <p>
                Create a Password and keep <br></br> track your expense for you.
              </p>
            </div>
            {errorMessages.email && (
              <div className="warning-message">{errorMessages.email}</div>
            )}
            {errorMessages.phone && (
              <div className="warning-message">{errorMessages.phone}</div>
            )}
            {warningMessage && errorMessages.passwordMismatch && (
              <div className="warning-message">{errorMessages.passwordMismatch}</div>
            )}
            {loading && (

              <div className="custom-loader-logo">
                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
              </div>
            )}

            <div className="passverify-form-main">
              <Form
                name="normal_passverify"
                className="passverify-form"
                initialValues={{
                  remember: false,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #F1F1FA",
                      borderRadius: "16px",
                      fontSize: "16px",
                      paddingLeft: "0px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      transaction:"none"
                    }}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item
                  name="confirmpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password Again!",
                    },
                  ]}
                >
                  <Input.Password
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #F1F1FA",
                      borderRadius: "16px",
                      fontSize: "16px",
                      paddingLeft: "0px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      transaction:"none"
                    }}
                    type="password"
                    placeholder="Confirm Password"
                  />
                </Form.Item>
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Oops! It looks like you forgot to agree to our privacy terms and policy. Please check the checkbox to proceed.');
                      },
                    },
                  ]}
                >
                  <Checkbox className="password-checkbox">
                    By signing up, you agree to the{" "}
                    <a href="#" target="_blank">
                      Terms of Service and Privacy Policy
                    </a>
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className="passverify-form-button">
                    Sign Up
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PasswordVerify;
