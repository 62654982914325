import React, { useContext, useEffect, useState } from "react";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/context.js";
import { get } from "../../../services/apiService";
import InvoiceItem from "../../Invoice/InvoiceItem";
import { timeDiff, invoiceCalculate, formatPrice } from "../../../utils/Common";
import "./index.scss";

const BASE_URL = process.env.REACT_APP_ROOT_URL;

function RecentOrdersTable(props) {
  const { type, invoiceData } = props;
  const { state, isLoading, updateInvoiceList } = useContext(AppContext);
  const { invoiceList, invoiceSetting } = state;
  const token = localStorage.getItem("token");

  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  useEffect(() => {
    getInvoicelist(currentPage);
  }, [currentPage]);

  const getInvoicelist = (page) => {
    get(`invoice-list?page=${page}&limit=${perPage}`, token)
      .then((response) => {
        if (response.success) {
          const listData = response.data.data;

          const transformedInvoiceArray = listData.map((item) => ({
            id: item.id,
            icon: <RiMoneyDollarCircleLine />,
            name: item.invoice_id,
            sname: item.customer_name,
            product: item.sales_details
              ? item.sales_details
                  .filter((sale) => sale.product_info)
                  .map((sale) => sale.product_info.name)
                  .join(", ")
              : "",
            price: invoiceSetting?.currency_info?.code
              ? formatPrice(invoiceCalculate(item).grandTotal) + " " + invoiceSetting.currency_info.code
              : formatPrice(invoiceCalculate(item).grandTotal) + " BDT",
            due: invoiceSetting?.currency_info?.code
              ? formatPrice(invoiceCalculate(item).dueAmount) +
                " " +
                invoiceSetting.currency_info.code
              : formatPrice(invoiceCalculate(item).dueAmount) + " BDT",
            time: timeDiff(new Date(item.updated_at)),
            date: item.updated_at,
            colors: "#00A3EE",
          }));
          updateInvoiceList(transformedInvoiceArray);
          //updateInvoiceList(response.data.data.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const shouldRenderPagination = invoiceList.total > perPage;

  return (
    <>
      <div className="RecentOrdersTable-container flex flex-col w-full">
        <div className="title-link">
          <h1>Recent Selling Invoice</h1>
          <Link className="whitespace-nowrap" to="/invoice">
            View All
          </Link>
        </div>
        <div className="table-conatiner">
          <div className="cursor-pointer">
            <div className="table-data flex invoiceItemTable">
              <div className="text-sm font-nunito font-bold">Name</div>
              <div className="text-sm font-nunito font-bold text-center">
                <h1>Amount</h1>
              </div>
              <div className="text-sm font-nunito font-bold text-center">
                <h1>Due</h1>
              </div>
              <div className="text-sm font-nunito font-bold text-right">
                <h1>Date/Time</h1>
              </div>
            </div>
          </div>
          {invoiceList.map((invoice) => {
            return <InvoiceItem key={invoice.id} invoice={invoice} />;
          })}
          <div className="hrtable"></div>
        </div>
      </div>
    </>
  );
}

export default RecentOrdersTable;
