import html2pdf from 'html2pdf.js';
import React, { useState } from 'react';
import QRCode from "react-qr-code";
import { numberToWords, formatPrice } from '../../utils/Common';
import PdfHtmlContent from './PdfHtmlContent/PdfHtmlContent';
import PdfHtmlContentFor3mm from './PdfHtmlContentFor3mm/PdfHtmlContentFor3mm';
import PdfHtmlContentFor2mm from './PdfHtmlContentFor2mm/PdfHtmlContentFor2mm';
// import Watermark from './Watermark/Watermark';
import { FaChevronDown, FaCloudDownloadAlt } from "react-icons/fa";
import { IoPrint } from "react-icons/io5";
import { event } from 'jquery';
import { useNavigate } from 'react-router-dom';


const BASE_URL = process.env.REACT_APP_ROOT_URL;

const InvoiceDetail = props => {
  const [pageSelect, setpageSelect] = useState(null);
  const [downloadPdfView, setdownloadPdfView] = useState(false)
  const [dropdownPdf, setDropdownPdf] = useState(false)
  const [dropdownPrint, setDropdownPrint] = useState(false)
  const [printContentVisible, setPrintContentVisible] = useState(false);
  const originalContents = document.body.innerHTML;
  const navigate = useNavigate();

  const {
    invoice,
    invoiceSetting,
    invoiceCondition,
    paperSize,
    deliveryMode,
  } = props;


  // const invoiceDate = new Date(invoice?.created_at);
  // const year = invoiceDate.getFullYear();
  // const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  // const monthName = invoiceDate.toLocaleString("en-US", { month: "long" });
  // const day = String(invoiceDate.getDate()).padStart(2, '0');
  // const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  // const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  // const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';
  // const [id, setId] = useState('')


  // const formattedDate = `${year}-${month}-${day}`;
  // const formattedDateTime = `${day} ${monthName} ${year} ( ${hours}:${minutes}${ampm} )`;

  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  //const shop_logo = `${BASE_URL}/layoutlogo.svg`;
  // let totalQty = 0;
  // let totalDiscount = 0;

  const handlePrint = (elementId) => {
    setPrintContentVisible(true);

    const printContents = document.getElementById(elementId).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
  };
  window.onafterprint = () => {
    setPrintContentVisible(false);
    document.body.innerHTML = originalContents;
     // Restore original content
      window.location.reload();
  };

  const handleGeneratePdf = async (pageSize, pageSizeNumber) => {
    const element = document.getElementById('pdfConentAreas');
    const element2 = document.getElementById('pdfConentAreas3');
    const element3 = document.getElementById('pdfConentAreas2');

    let selectedElement;
    setdownloadPdfView(true);
    if (pageSizeNumber === 1) {
      console.log("page size", pageSizeNumber)
      console.log(element)
      setpageSelect(pageSizeNumber);

      selectedElement = element;
    } else if (pageSizeNumber === 2) {
      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
      selectedElement = element2;
    } else if (pageSizeNumber === 3) {

      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
      selectedElement = element3;
    }

    const formatOptions = {
      'a4': 'a4',
      '3mm': [80, 297],
      '2mm': [56, 297],
    };

    const opt = {
      margin: 0.1,
      filename: 'myPdf.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 900 / 96,
        proxy: 'https://api-outlet-expense.squadinnovators.com',
        useCORS: true,
        allowTaint: false,
        logging: false,
      },
      jsPDF: {
        unit: 'mm',
        format: formatOptions[pageSize],
        orientation: 'portrait',
        marginLeft: 10,
        marginRight: 10,
        image: { type: 'jpeg', quality: 1 },
        fontFaces: [
          { family: 'Arial', src: 'path/to/arial.ttf' },
        ]
      },
    };

    const generatePdf = () => {
      html2pdf()
        .set(opt)
        .from(selectedElement)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          pdf.save(`${invoice?.exporter?.invoice_id}_invoice_${pageSize}.pdf`);
        });
    };

    generatePdf();

  };

  const handleNavigate = () =>{
    navigate('/')
  }

  return (
    <div>
    {/* create files for exporter invoices */}
        <div>
          {paperSize === 'A4' && (
            <PdfHtmlContent
              shop_logo={shop_logo}
              invoice={invoice}
              id={"pdfConentAreas"}
              invoiceSetting={invoiceSetting}
            />
          )}
          {/* <div className='hidden'>
          <PdfHtmlContentFor3mm
              shop_logo={shop_logo}
              invoice={invoice}
              id={"pdfConentAreas3"}
              invoiceSetting={invoiceSetting}
            />
          </div>

          <div className='hidden'>
          <PdfHtmlContentFor2mm
              shop_logo={shop_logo}
              invoice={invoice}
              id={"pdfConentAreas2"}
              invoiceSetting={invoiceSetting}
            />
          </div> */}
        </div>


     {/* new pdf and print buttons */}
      <div className='flex gap-12  justify-between text-xs md:text-sm lg:text-base mt-6'>
        {/* pdf buttons */}
        <div className={`flex-1 transform duration-300 ${dropdownPdf || dropdownPrint ? "!h-[270px] md:!h-[300px]" : "h-[90px]"} space-y-2 relative`}>
          {/* pdf drop down button */}
          <button onClick={() => setDropdownPdf(!dropdownPdf)} className=" flex justify-center gap-1 md:gap-3 items-center bg-[#f1effc] text-[#034AFF] absolute z-10 !px-1 py-3 w-full rounded-xl font-bold shadow-md">
            <span><FaCloudDownloadAlt size={22}/></span>  Download PDF <div className={`transform duration-300 ${dropdownPdf ? "-rotate-180" : ""}`}> <FaChevronDown size={18}/></div>
          </button>
          {/* child buttons */}
          <button onClick={() => handleGeneratePdf('a4', 1)} className={`text-[#034AFF] font-semibold flex gap-1 md:gap-3  items-center justify-center ${dropdownPdf ? "translate-y-[3.7rem] " : "-translate-y-[0.6rem] opacity-0"} hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <FaCloudDownloadAlt size={22}/> <span>Download A4 PDF</span>
          </button>
          {/* <button onClick={() => handleGeneratePdf('3mm', 2)} className={`text-[#034AFF] font-semibold flex gap-1 md:gap-3 items-center justify-center ${dropdownPdf ? "translate-y-[3.7rem] " : "-translate-y-[4.8rem] opacity-0"}  hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <FaCloudDownloadAlt size={22}/> <span>Download 3mm PDF</span>
          </button>
          <button onClick={() => handleGeneratePdf('2mm', 3)} className={` text-[#034AFF] font-semibold flex gap-1 md:gap-3 items-center justify-center ${dropdownPdf ? "translate-y-[3.7rem] " : "-translate-y-[8.4rem] opacity-0"} border hover:bg-[#034AFF] hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <FaCloudDownloadAlt size={22}/> <span>Download 2mm PDF</span>
          </button> */}
        </div>
        {/* print buttons */}
        <div className={`flex-1 space-y-2 transform duration-300 ${dropdownPdf || dropdownPrint ? "!h-[270px] md:!h-[300px]" : "h-[90px]"} relative`}>
          {/* print drop down button */}
          <button onClick={() => setDropdownPrint(!dropdownPrint)} className=" bg-[#f1effc] flex justify-center items-center gap-1 md:gap-3 text-[#034AFF] font-bold shadow-md absolute z-10 !px-1 py-3 w-full rounded-xl">
            <span><IoPrint size={22}/></span>  Print <div className={`transform duration-300 ${dropdownPrint ? "-rotate-180" : ""}`}> <FaChevronDown  size={18} /></div>
          </button>
          {/* child buttons */}
          <button onClick={() => { handlePrint('pdfConentAreas') }} className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[0.6rem] opacity-0"} hover:text-[#eaeffb] hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300  py-3 w-full  rounded-xl`}>
          <IoPrint size={22}/><span>Print A4</span>
          </button>
            {/* <button onClick={() => { handlePrint('pdfConentAreas3') }} className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[4.8rem] opacity-0"}  hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <IoPrint size={22}/> <span>Print 3 inch</span>
            </button>
            <button onClick={() => { handlePrint('pdfConentAreas2') }} className={` flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[8.4rem] opacity-0"} border hover:bg-[#034AFF] hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
             <IoPrint size={22}/><span>Print 2 inch</span>
            </button> */}
        </div>
      </div>
      {/* back to list button */}
      <button onClick={()=>handleNavigate()} className=" bg-[#034AFF] py-3 w-1/2 mt-3 mx-auto flex justify-center text-white rounded-xl text-xs md:text-sm lg:text-base">
        Back to list
      </button>
    </div>
  );
};



export default InvoiceDetail;
