import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    position: "relative",
  },
  header: {
    marginBottom: 10,
    textAlign: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 4,
  },
  shopInfo: {
    fontSize: 10,
    marginBottom: 2,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },
  thickDivider: {
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },
  reportTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginTop: 2,
    marginBottom: 3,
  },
  table: {
    display: "table",
    width: "100%",
    marginVertical: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    padding: 5,
    color: "#000232",
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: "#000",
    backgroundColor: "#E7EBF4",
  },
  tableColSl: {
    flex: 1,
    padding: 5,
    textAlign: "center",
    fontSize: 10,
  },
  tableCol: {
    flex: 2.25,
    padding: 5,
    textAlign: "center",
    fontSize: 10,
  },
  tableColCenter: {
    flex: 1,
    padding: 5,
    textAlign: "center",
    fontSize: 10,
  },
  tableColEndAlign: {
    flex: 2.25,
    padding: 5,
    textAlign: "right",
    fontSize: 10,
  },
  tableRowAlternate: {
    backgroundColor: "#F5F5F5",
  },
  grandTotalRow: {
    backgroundColor: "#E7EBF4",
    fontWeight: "bold",
  },
  footer: {
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: 10,
  },
});

// PDF document component
const MonthlySalesDayCountPDFDocument = ({
  reports,
  // closingBalance,
  user,
  startDate,
  endDate,
  dayCount,
  grandTotal
}) => (
  <Document>
    <Page style={styles.page} size="A4" orientation="landscape">
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>{user?.invoice_settings?.shop_name || "N/A"}</Text>
        <Text style={styles.shopInfo}>{user?.address}</Text>
        <Text style={styles.shopInfo}>Mobile: {user?.phone || "N/A"}</Text>

        {/* Horizontal Lines */}
        <View style={styles.thickDivider} />
        <View style={styles.divider} />
        <View style={styles.thickDivider} />

        <Text style={styles.reportTitle}>Monthly Sales Day Counting Report</Text>
        <Text style={styles.shopInfo}>({startDate} to {endDate})</Text>
      </View>

      {/* Table */}
      <View style={styles.table}>
        {/* Table Header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableColHeader, styles.tableColSl]}>Sl.</Text>
          <Text style={[styles.tableColHeader, styles.tableCol]}>Transaction Date</Text>
          <Text style={[styles.tableColHeader, styles.tableCol]}>Voucher Number</Text>
          <Text style={[styles.tableColHeader, styles.tableCol]}>Customer Name</Text>
          <Text style={[styles.tableColHeader, styles.tableCol]}>Product Name</Text>
          <Text style={[styles.tableColHeader, styles.tableColCenter]}>Product Qty</Text>
          <Text style={[styles.tableColHeader, styles.tableColEndAlign]}>Sales Amount</Text>
        </View>

        {/* Table Body */}
        {reports.map((report, index) => (
          <View key={index} style={[styles.tableRow, index % 2 === 0 && styles.tableRowAlternate]}>
            <Text style={styles.tableColSl}>{index + 1}</Text>
            <Text style={styles.tableCol}>{report?.date || "N/A"}</Text>
            <Text style={styles.tableCol}>{report?.invoice_id || "N/A"}</Text>
            <Text style={styles.tableCol}>{report?.customer_name || "N/A"}</Text>
            <Text style={styles.tableCol}>{report?.product_name}</Text>
            <Text style={styles.tableColCenter}>{report?.qty}</Text>
            <Text style={styles.tableColEndAlign}>{report?.price || 0}</Text>
          </View>
        ))}

        {/* Grand Total Row */}
        <View style={[styles.tableRow, styles.grandTotalRow]}>
          <Text style={styles.tableColSl}></Text>
          <Text style={styles.tableCol}>{dayCount || 0} Day(s)</Text>
          <Text style={styles.tableCol}>Grand Total</Text>
          <Text style={styles.tableCol}></Text>
          <Text style={styles.tableCol}></Text>
          <Text style={styles.tableColCenter}></Text>
          <Text style={styles.tableColEndAlign}>{grandTotal || 0}</Text>
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Prepared by</Text>
        <Text>Checked/Recommended by</Text>
        <Text>Authorized by</Text>
      </View>
    </Page>
  </Document>
);

export default MonthlySalesDayCountPDFDocument;
