import React, { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { timeDiff,formatPrice } from '../../utils/Common';
import Pagination from '../../utils/pagination'; // Adjust the path accordingly
import { AppContext } from '../../context/context.js';
import DatePicker from "react-datepicker";
import ContentLayout from '../../layout/ContentLayout';
import { get, post } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import CustomerListItem from '../../components/Invoice/CustomerListItem.js';
import { useFlashMessage } from '../../context/FlashMessageContext';
import { BiPlus } from "react-icons/bi";
import { Modal } from "../../utils/Modal";
import ExpenseCategoryItem from "../../components/Expense/ExpenseCategoryItem.js";
import EmployeeItem from "../../components/HRM/EmployeeItem.js";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

const BASE_URL = process.env.REACT_APP_ROOT_URL;

const EmployeePage = () => {
  const { flashMessage, getFlashMessageStyle } = useFlashMessage();
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const [employeeList, setEmployeeList] = useState([]);
  const [refetch, setRefetch] = useState(false)
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;

  //--------
  const [searchFilter, setSearchFilter] = useState({
    keyword: "",
  })
  const { state, isLoading, updateEmployeeList } = useContext(AppContext);
  //------
  const [employeeData, setEmployeeData] = useState([]);
  const [currentTotal, setCurrentTotal] = useState(0);
  const shouldRenderPagination = employeeData?.total!=currentTotal &&  employeeData?.total > perPage;
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  //----------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [isViewActive, setIsViewActive] = useState(false);
  const [isCreateActive, setIsCreateActive] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);

  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");
    if (!employeeApiResponse) {
      setHasPermission(true);
      setIsCreateActive(true);
      setIsEditActive(true);
      return;
    }
      const employeeData = JSON.parse(employeeApiResponse);
      const hrmFeature = employeeData.role.features.find(
        (feature) => feature.name === "HRM" && feature.status === 1
      );
      if (hrmFeature && location.pathname === "/employee") {
        const viewHrmOptionActive = hrmFeature.feature_options.find(
          (option) => option.name === "View" && option.status === 1
        );
        const createHrmOptionActive = hrmFeature.feature_options.find(
          (option) => option.name === "Create" && option.status === 1
        );
        const editHrmOptionActive = hrmFeature.feature_options.find(
          (option) => option.name === "Edit" && option.status === 1
        );
        if (viewHrmOptionActive) {
          setIsViewActive(true);
          setHasPermission(true);
        } else {
          setIsViewActive(false);
          setHasPermission(false);
        }
        setIsCreateActive(!!createHrmOptionActive);
        setIsEditActive(!!editHrmOptionActive);
      } else {
        setHasPermission(false);
        setIsCreateActive(false);
        setIsEditActive(false);
        navigate("/dashboard", { replace: true });
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Permission Denied",
            text: "You do not have permission for this feature.",
          });
        }, 100);
      }
    }
  , [location.pathname, navigate]);
  //-----------------

  useEffect(()=>{
    if ((isViewActive && hasPermission) || !localStorage.getItem("employee")) {
      getEmployeeList(currentPage)
    }
  },[currentPage, searchFilter,isViewActive,hasPermission])

  const getEmployeeList = (page) => {
    if (searchFilter.keyword !== "") {
      const data = {
        keyword: searchFilter.keyword,
      }
      loadingCallback(true);
      post(`search-employee?page=${page}&limit=${perPage}`, data, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            const listData = response.data;
            setEmployeeData(response.data)
            setCurrentTotal(listData.length)
            setEmployeeList(response.data)
            console.log("employee names after search", listData)

            const transformedEmployeeArray = listData.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateEmployeeList(transformedEmployeeArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        });
    } else {
      loadingCallback(true);
      get(`employee?page=${page}&limit=${perPage}`, token)
        .then((response) => {
          loadingCallback(false);
          if (response.success) {
            console.log("employee list before search", response.data.data)
            const listData = response.data.data;
            setEmployeeData(response.data)
            setCurrentTotal(listData.length)
            setEmployeeList(listData)
            const transformedEmployeeArray = listData.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateEmployeeList(transformedEmployeeArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log( error);
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
      });
    }
  };


  const [roleData, setRoleData] = useState(null);

  useEffect(() => {
    if ((isViewActive && hasPermission) || !localStorage.getItem("employee")) {
      getRoleList();
    }
  }, [isViewActive,hasPermission]);

  const getRoleList = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}role-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        console.log("roles:",response.data.data);
        setRoleData(response.data.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };

  // --------------------

  const GotoAddEmployee = () => {
    if (isCreateActive) {
      navigate("/employee-add");
    }
  };


  return (
    <>
      <ContentLayout>
      <div className="main-content recentTableContent">
        <div className="flex flex-col w-full">
              <div className='flex flex-col md:flex-row justify-between mb-4'>
                <div>
                  <h1 className='text-[#000232] text-[22px] font-[600] font-lato mb-2'>Employee List</h1>
                </div>
                <div className='flex gap-2 justify-center'>
                  <div className="flex relative  w-[95%]">
                    <input
                      autoFocus
                      type="text"
                      className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                      placeholder="Search Employee"
                      onChange={(e) => {
                        setSearchFilter({...searchFilter, keyword: e.target.value})
                      }}
                    />
                    <div className="absolute top-[37%] left-[10px]">
                      <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                    </div>
                  </div>
                  <button
                    onClick={GotoAddEmployee}
                    className={`cursor-pointer bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 pl-[22px] pr-[24px] flex items-center gap-2 ${
                      !isCreateActive && "opacity-50 cursor-not-allowed"
                    }`}
                    style={{ pointerEvents: isCreateActive ? "auto" : "none" }}
                  >
                    <BiPlus className="text-xl "></BiPlus>
                  </button>
                </div>
              </div>
        </div>
        {(isViewActive && hasPermission) || !localStorage.getItem("employee") ? (
          <div className="table-conatiner">
                <div className="grid grid-cols-8 gap-2 items-center font-nunito font-[700] text-[14px] text-[#000232]">
                  <div className="py-[8px]">SL No</div>
                  <div className="py-[8px] text-center">Name</div>
                  <div className="py-[8px] text-center">Email</div>
                  <div className="py-[8px] text-center">Designation</div>
                  <div className="py-[8px] text-center">Role</div>
                  <div className="py-[8px] text-center">Department</div>
                  <div className="py-[8px] text-center">Phone Number</div>
                  <div className="py-[8px] text-right">Action</div>
                </div>
                {flashMessage && (
                  <div
                    className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
                      flashMessage.type
                    )}`}
                  >
                    {flashMessage.text}
                  </div>
                )}
                {loading && (
                      <div className="custom-loader-logo">
                        <img
                          className=""
                          src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                          alt="logo"
                        />
                      </div>
                )}
                {employeeList
                    ? employeeList.map((item, index) => (
                      <EmployeeItem
                        key={item.id}
                        index={index}
                        loadingCallback={loadingCallback}
                        navigate={navigate}
                        employeeitem={item}
                        loading={loading}
                        roleData={roleData}
                        isEditActive={isEditActive}
                      />
                      ))
                    : null}
                <div className="hrtable"></div>
                {/* Pagination component */}
                {shouldRenderPagination && (
                  <Pagination
                    lastPage={employeeData?.last_page}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                )}
          </div>
        ) : null}
      </div>

      </ContentLayout>
    </>
  );
};

export default EmployeePage;
