import React, { useContext, useState, useRef, useEffect } from "react";

import { AppContext } from "../../../context/context.js";
import Pagination from "../../../utils/pagination.js";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../SettingsV2Components.jsx/Breadcrumb.js";
import WarrantyTableItemsV2 from "../SettingsV2Components.jsx/WarrantyTableItemsV2.js";
import { Helmet } from "react-helmet-async";

const WarrantySettingsV2 = () => {
  const { state, isLoading, updateWarrantyList } = useContext(AppContext);

  const { warrantyList } = state;
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;

  useEffect(() => {
    getWarranty(currentPage);
  }, [currentPage]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const getWarranty = (page) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}get-warranty-list?page=${page}&limit=${perPage}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          console.log(response.data.data);
          updateWarrantyList(response.data.data);
          console.log("============");
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  const shouldRenderPagination = state.warrantyList?.total > perPage;

  console.log(warrantyList);

  return (
    <div className="rounded-[27px]" style={{ backgroundColor: "#FAFAFA" }}>
      <Helmet>
        <title>Outlet Expense | Settings | Warranty Settings</title>
      </Helmet>
      <Breadcrumb></Breadcrumb>

      <WarrantyTableItemsV2
        loading={loading}
        data={warrantyList}
        accountData={warrantyList}
        getWarranty={getWarranty}
      ></WarrantyTableItemsV2>
      {shouldRenderPagination && (
        <Pagination
          currentPage={currentPage}
          lastPage={state.warrantyList.last_page}
          onPageChange={onPageChange}
        />
      )}
      <div className="w-full relative px-10 md:px-[27px] overflow-x-auto sm:rounded-lg">
        {loading && <div className="custom-loader"></div>}
      </div>
    </div>
  );
};

export default WarrantySettingsV2;
