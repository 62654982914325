import React, { useEffect, useState,useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrowLeft from "../../assets/images/arrow-left.png";
import axios from "axios";
import { useFlashMessage } from '../../context/FlashMessageContext';
import { get, post } from "../../services/apiService";
import Resizer from "react-image-file-resizer";
import { ToastContainer, toast } from 'react-toastify';

import ContentLayout from "../../layout/ContentLayout";
import RolePermissionAddForm from "../../forms/RolePermissionAddForm";
import { AppContext } from "../../context/context";
import { FlashMessage } from "../../utils/FlashMessage";
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

const RoleAddPage = () => {

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage();

  const initialFormData = {
    role_name: "",
    role_description: "",
    features: [
      {
        name: "Products",
        status: false,
        feature_options: [
          { name: "Create", status: false },
          { name: "Update", status: false }
        ]
      },
      {
        name: "Product Purchase Price",
        status: false,
        feature_options: [
          { name: "Visible", status: false },
          { name: "Hidden", status: false }
        ]
      },
      {
        name: "Sale",
        status: false,
        feature_options: [
          { name: "Sale Billing", status: false },
          { name: "Sale Return", status: false },
          { name: "Advanced Booking", status: false },
          { name: "Customer", status: false }
        ]
      },
      // {
      //   name: "Sale Return",
      //   status: false,
      //   feature_options: [
      //     { name: "Create", status: false },
      //     { name: "View", status: false },
      //     { name: "Delete", status: false }
      //   ]
      // },
      // {
      //   name: "Advanced Booking",
      //   status: false,
      //   feature_options: [
      //     { name: "Create", status: false },
      //     { name: "View", status: false },
      //     { name: "Delete", status: false }
      //   ]
      // },
      // {
      //   name: "Customer",
      //   status: false,
      //   feature_options: [
      //     { name: "Add", status: false },
      //     { name: "Edit", status: false },
      //     { name: "Delete", status: false }
      //   ]
      // },
      {
        name: "Purchase",
        status: false,
        feature_options: [
          { name: "Purchase Billing", status: false },
          { name: "Purchase Return", status: false },
          { name: "Vendor List", status: false }
        ]
      },
      // {
      //   name: "Purchase Billing",
      //   status: false,
      //   feature_options: [
      //     { name: "Add", status: false },
      //     { name: "Edit", status: false },
      //     { name: "Delete", status: false }
      //   ]
      // },
      // {
      //   name: "Purchase Return",
      //   status: false,
      //   feature_options: [
      //     { name: "Add", status: false },
      //     { name: "Edit", status: false },
      //     { name: "Delete", status: false }
      //   ]
      // },
      // {
      //   name: "Vendor List",
      //   status: false,
      //   feature_options: [
      //     { name: "Add", status: false },
      //     { name: "Edit", status: false },
      //     { name: "Delete", status: false }
      //   ]
      // },
      {
        name: "Expense",
        status: false,
        feature_options: [
          { name: "Create", status: false },
          { name: "View", status: false }
        ]
      },
      {
        name: "HRM",
        status: false,
        feature_options: [
          { name: "Create", status: false },
          { name: "Edit", status: false },
          { name: "View", status: false }
        ]
      },
      {
        name: "Wholesale",
        status: false,
        feature_options: [
          { name: "Create", status: false },
          { name: "Edit", status: false },
          { name: "View", status: false }
        ]
      },
      {
        name: "Finance",
        status: false,
        feature_options: [
          { name: "Create", status: false },
          { name: "View", status: false }
        ]
      },
      {
        name: "Exporter",
        status: false,
        feature_options: [
          // { name: "Visible", status: false },
          // { name: "Hidden", status: false }
        ]
      },
      {
        name: "Analytics",
        status: false,
        feature_options: [
          // { name: "Visible", status: false },
          // { name: "Hidden", status: false }
        ]
      },
      {
        name: "Settings",
        status: false,
        feature_options: [
          // { name: "Visible", status: false },
          // { name: "Hidden", status: false }
        ]
      },
    ]
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("input data", formData);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleCheckboxChange = (e) => {
  //   const { name, checked } = e.target;

  //   const featureMap = {
  //     add_product: "Add Product",
  //     product_purchase_price: "Product Purchase Price",
  //     sale_billing: "Sale Billing",
  //     sale_return: "Sale Return",
  //     advanced_booking: "Advanced Booking",
  //     customer: "Customer",
  //     purchase_billing: "Purchase Billing",
  //     purchase_return: "Purchase Return",
  //     vendor_list: "Vendor List",
  //     expense: "Expense",
  //     hrm: "HRM",
  //     wholeseller: "Wholeseller",
  //     exporter: "Exporter",
  //     finance: "Finance",
  //     analytics: "Analytics",
  //     shop_settings: "Shop Settings",
  //   };

  //   const optionMap = {
  //     create_product: { parent: "Add Product", name: "Create" },
  //     update_product: { parent: "Add Product", name: "Update" },
  //     visible_purchase_price: { parent: "Product Purchase Price", name: "Visible" },
  //     hidden_purchase_price: { parent: "Product Purchase Price", name: "Hidden" },

  //     add_sale_billing: { parent: "Sale Billing", name: "Add" },
  //     edit_sale_billing: { parent: "Sale Billing", name: "Edit" },
  //     view_sale_billing: { parent: "Sale Billing", name: "View" },

  //     create_sale_return: { parent: "Sale Return", name: "Create" },
  //     view_sale_return: { parent: "Sale Return", name: "View" },
  //     delete_sale_return: { parent: "Sale Return", name: "Delete" },

  //     create_advanced_booking: { parent: "Advanced Booking", name: "Create" },
  //     view_advanced_booking:  { parent: "Advanced Booking", name: "View" },
  //     delete_advanced_booking:  { parent: "Advanced Booking", name: "Delete" },

  //     add_customer: { parent: "Customer", name: "Add" },
  //     edit_customer:  { parent: "Customer", name: "Edit" },
  //     delete_customer: { parent: "Customer", name: "Delete" },

  //     add_purchase_billing: { parent: "Purchase Billing", name: "Add" },
  //     edit_purchase_billing: { parent: "Purchase Billing", name: "Edit" },
  //     delete_purchase_billing: { parent: "Purchase Billing", name: "Delete" },

  //     add_purchase_return: { parent: "Purchase Return", name: "Add" },
  //     edit_purchase_return: { parent: "Purchase Return", name: "Edit" },
  //     delete_purchase_return: { parent: "Purchase Return", name: "Delete" },

  //     add_vendor_list: { parent: "Vendor List", name: "Add" },
  //     edit_vendor_list: { parent: "Vendor List", name: "Edit" },
  //     delete_vendor_list: { parent: "Vendor List", name: "Delete" },

  //     create_expense: { parent: "Expense", name: "Create" },
  //     view_expense: { parent: "Expense", name: "View" },

  //     add_hrm: { parent: "HRM", name: "Create" },
  //     edit_hrm: { parent: "HRM", name: "Edit" },
  //     view_hrm: { parent: "HRM", name: "View" },

  //     add_wholeseller: { parent: "Wholeseller", name: "Create" },
  //     edit_wholeseller: { parent: "Wholeseller", name: "Edit" },
  //     view_wholeseller: { parent: "Wholeseller", name: "View" },

  //     exporter_visible: { parent: "Exporter", name: "Visible" },
  //     exporter_hidden: { parent: "Exporter", name: "Hidden" },

  //     create_finance: { parent: "Finance", name: "Create" },
  //     view_finance: { parent: "Finance", name: "View" },

  //     analytics_visible: { parent: "Analytics", name: "Visible" },
  //     analytics_hidden: { parent: "Analytics", name: "Hidden" },

  //     shop_settings_visible: { parent: "Shop Settings", name: "Visible" },
  //     shop_settings_hidden: { parent: "Shop Settings", name: "Hidden" },
  //   };

  //   setFormData((prevFormData) => {
  //     let updatedFeatures = [...prevFormData.features];

  //     // Handle feature selection
  //     if (featureMap[name]) {
  //       const featureName = featureMap[name];
  //       const featureExists = updatedFeatures.find(f => f.name === featureName);

  //       if (checked) {
  //         // Add the feature if it doesn't exist and store its options as unchecked
  //         if (!featureExists) {
  //           updatedFeatures.push({
  //             name: featureName,
  //             status: true,
  //             feature_options: Object.values(optionMap)
  //               .filter(opt => opt.parent === featureName)
  //               .map(opt => ({ name: opt.name, status: false }))
  //           });
  //         }
  //       } else {
  //         // Remove feature if unchecked
  //         updatedFeatures = updatedFeatures.filter(f => f.name !== featureName);
  //       }
  //     }

  //     // Handle feature options selection
  //     else if (optionMap[name]) {
  //       const { parent, name: optionName } = optionMap[name];
  //       let parentFeature = updatedFeatures.find(f => f.name === parent);

  //       // If the parent feature doesn't exist yet, create it and store all other options as false
  //       if (!parentFeature && checked) {
  //         parentFeature = {
  //           name: parent,
  //           status: true,
  //           feature_options: Object.values(optionMap)
  //             .filter(opt => opt.parent === parent)
  //             .map(opt => ({ name: opt.name, status: opt.name === optionName ? true : false }))
  //         };
  //         updatedFeatures.push(parentFeature);
  //       } else if (parentFeature) {
  //         // Check if the option exists under the parent feature
  //         const optionExists = parentFeature.feature_options.find(opt => opt.name === optionName);

  //         if (checked) {
  //           // Mark the option as true if selected
  //           if (!optionExists) {
  //             parentFeature.feature_options.push({ name: optionName, status: true });
  //           } else {
  //             optionExists.status = true;
  //           }
  //         } else {
  //           // Mark the option as false if unselected
  //           if (optionExists) {
  //             optionExists.status = false;
  //           } else {
  //             parentFeature.feature_options.push({ name: optionName, status: false });
  //           }
  //         }

  //         // If no options are left selected, keep them with false status
  //         if (parentFeature.feature_options.length === 0) {
  //           parentFeature.feature_options = Object.values(optionMap)
  //             .filter(opt => opt.parent === parent)
  //             .map(opt => ({ name: opt.name, status: false }));
  //         }
  //       }
  //     }

  //     return {
  //       ...prevFormData,
  //       features: updatedFeatures,
  //     };
  //   });
  // };

  //----------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [isCreateActive, setIsCreateActive] = useState(false);

  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");
    if (!employeeApiResponse) {
      // Allow access if no employee data is found in localStorage (optional)
      setHasPermission(true);
      setIsCreateActive(true);
      return;
    }

    const employeeData = JSON.parse(employeeApiResponse);
    const hrmFeature = employeeData.role.features.find(
      (feature) => feature.name === "HRM" && feature.status === 1
    );

    if (hrmFeature) {
      const createHrmOptionActive = hrmFeature.feature_options.find(
        (option) => option.name === "Create" && option.status === 1
      );

      if (createHrmOptionActive && location.pathname === "/role-add") {
        setHasPermission(true);
        setIsCreateActive(true);
      } else {
        setHasPermission(false);
        setIsCreateActive(false);
        navigate("/dashboard", { replace: true });
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }
    } else {
      setHasPermission(false);
      setIsCreateActive(false);
      navigate("/dashboard", { replace: true });
      Swal.fire({
        icon: "error",
        title: "Permission Denied",
        text: "You do not have permission for this feature.",
      });
    }
  }, [location.pathname, navigate]);

  //-----------------

  const handleCheckboxChange = (e, featureIndex) => {
    const updatedFeatures = [...formData.features];

    // Update the feature's status
    updatedFeatures[featureIndex].status = e.target.checked;

    // If the feature is being unchecked, set all its feature options to false
    if (!e.target.checked) {
      updatedFeatures[featureIndex].feature_options = updatedFeatures[featureIndex].feature_options?.map(option => ({
        ...option,
        status: false,
      }));
    }

    setFormData({
      ...formData,
      features: updatedFeatures,
    });
  };


  const handleOptionCheckboxChange = (e, featureIndex, optionIndex) => {
    const updatedFeatures = [...formData.features];

    // Update the feature option's status
    updatedFeatures[featureIndex].feature_options[optionIndex].status = e.target.checked;

    // If the feature itself is unchecked but an option is checked, set the feature to true
    if (e.target.checked && !updatedFeatures[featureIndex].status) {
      updatedFeatures[featureIndex].status = true;
    }

    setFormData({
      ...formData,
      features: updatedFeatures,
    });
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('FormData before submission:', formData);

    const requestBody = {
      name: formData.role_name,
      description: formData.role_description,
      features: formData.features.length ? formData.features : [],
    };

    const token = localStorage.getItem("token");

    axios.post(`${process.env.REACT_APP_API_URL}save-role`, requestBody, {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      console.log("saved data:", response.data);
      FlashMessage('Role Permission saved successfully','success');
      navigate('/role');
    })
    .catch(error => {
      console.error("Error saving role:", error);
    });
  };


  return(
    <ContentLayout>
      <div className="rounded-[16px] bg-white px-[35px] py-[30px] mt-[30px]">
        <div className="flex flex-row gap-2 items-center mb-5">
          <Link className="block w-[24px]" to={`/role`}>
            <img src={arrowLeft} className="w-[100%]" />
          </Link>
          <h1 className='text-[#000232] text-[22px] font-[600] font-lato '>Add Role Permission</h1>
        </div>
        {warningMessage && (
          <div className="warning-message">
            something wrong!
          </div>
        )}
        {flashMessage && (
          <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
            {flashMessage.text}
          </div>
        )}
        {loading && (
          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
          </div>
        )}
        <RolePermissionAddForm
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
          initialFormData={initialFormData}
          handleOptionCheckboxChange={handleOptionCheckboxChange}
        />
      </div>
    </ContentLayout>
  );

}

export default RoleAddPage;