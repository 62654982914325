import React, { useState } from 'react';
import ContentLayout from '../../layout/ContentLayout';
import { BiSave } from 'react-icons/bi';
import ImageUploaderWithPost from './ImageUploaderWithPost';

const CreateSlider = () => {
  const [formData,setFormData] = useState({
    title : '',
    short_description : '',
    images : []
  })

  const handleFileChange = (files, name) => {
    console.log('Selected files:', files);
  };

  const handleChange = (e) => {
    const {name,value} = e.target;
    setFormData((prevFormData) =>( {
      ...prevFormData,
      [name] : value
    }))
  }

  // console.log(formData);

  const handleSubmit = () => {

    console.log('submitted',formData);
  }
  return (
    <div>
      <ContentLayout>
      <div className="bg-white rounded-3xl mt-3 font-nunito p-[26px] ">
          <div className="mb-5">
            <span className="text-gray-400 text-xs">Banner</span>
            <p className="font-semibold text-sm">Create New Banner</p>
          </div>
          {/*slider form  */}
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1 space-y-5">
              <div className={` relative`}>
                <input
                  onChange={handleChange}
                  value={formData.title}
                  name="title"
                  placeholder="Slider Title"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                />

                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Title
                </label>
              </div>
              <div className="relative">
                <textarea
                  rows={8}
                  name="short_description"
                  value={formData.short_description}
                  onChange={handleChange}
                  placeholder="Slider Description"
                  className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] border`}
                ></textarea>
                <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
                  Description
                </label>
              </div>
            </div>
            <div className="col-span-1">
              <ImageUploaderWithPost
                name={"image_path1"}
                isBig={true}
                onChange={handleFileChange}
                formData={formData}
                setFormData={setFormData}
                apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}
              ></ImageUploaderWithPost>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col space-y-3">
                <ImageUploaderWithPost
                  name={"image_path2"}
                  onChange={handleFileChange}
                  formData={formData}
                  setFormData={setFormData}
                  apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}
                ></ImageUploaderWithPost>
                <ImageUploaderWithPost
                  name={"image_path3"}
                  onChange={handleFileChange}
                  apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}
                  formData={formData}
                  setFormData={setFormData}
                ></ImageUploaderWithPost>
              </div>

            </div>
          </div>
          <button
                onClick={handleSubmit}
                type="submit"
                className="bg-[#407BFF] mx-auto mt-5 rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 px-[67px] flex items-center gap-2"
              >
                <BiSave className="text-xl"></BiSave> <span>Add Slider</span>
              </button>
        </div>
      </ContentLayout>
    </div>
  );
};

export default CreateSlider;