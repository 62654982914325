import React from "react";
import LogoAlignLeft from './bigLogo.png';
import { formatDate, formatPrice } from "../../utils/Common";
import ProductStockReportItem from "./ProductStockReportItem";
const ProductStockDetails = (props) => {

  const {activeTab,detailsData,fullApiData} = props;
  let createdDate,updatedDate;
  detailsData.map(el =>{
    createdDate = el.created_at;
    updatedDate = el.updated_at;
   } )
   let sumStock = detailsData.reduce((total, cData) => {
    return cData?.completed ? total + cData?.current_stock : total;
  }, 0);
   const storedUserData = JSON.parse(localStorage.getItem('user'));
   const todayDate = new Date().toLocaleDateString();
  return (
    <div
      id="productStockHistory"

    >
      {/* <div className="flex justify-between items-center">
        {outlet logo}
        <div  className="w-20 cursor-pointer -pb-[4px]">
          <img src={LogoAlignLeft} className="w-full h-full object-contain" />
        </div>
        {title and date}
        <div className="text-[#000232] text-right">
          <h2 className="text-sm font-semibold">Product Stock Report</h2>
          <p className="text-xs">{formatDate(createdDate)} - {formatDate(updatedDate)}</p>
        </div>
      </div> */ }
      <table className="w-[99%]">
      <tbody>
          <tr>
            <td colSpan="3" className="border text-center m-0 p-1 text-xs">Product Stock Report</td>
          </tr>
          <tr>
            <td className="border text-left align-top p-2 text-xs">
              <div>Shop Name: {storedUserData?.outlet_name}</div>
              <div>Email: {storedUserData?.email}</div>
              <div>Address: {storedUserData?.address}</div>
            </td>
            <td className="border text-left align-top p-2 text-xs">
              <div>Date: {todayDate}</div>
              <div>Mobile: {storedUserData?.phone}</div>
            </td>
            <td className="border text-left align-top p-2 text-xs">
              {storedUserData?.invoice_settings.shop_logo && (
                <img src={storedUserData?.invoice_settings.shop_logo} alt="Shop Logo" className="w-24 h-auto" />
              )}
            </td>
          </tr>
         {/*  <tr>
            <td colSpan="3" className="border text-center p-2 text-xs">
              From: {formatDate(createdDate)} to {formatDate(updatedDate)}
            </td>
          </tr> */}
        </tbody>
      </table>
      {/* table head */}
      <div className="grid text-center mt-4 pt-[4px] items-center text-md font-bold bg-[#41a9e0] text-white grid-cols-4 border-1 border-black" style={{ WebkitPrintColorAdjust: "exact" }}>
        <div className="text-center col-span-1 border-l border-black">SL No.</div>
        <div className="col-span-2 border-l border-black">Product Name</div>
        <div className="col-span-1 border-l border-black text-center">Current Stock</div>
      </div>
      {/* table body component */}
      {detailsData.map((cData, index) =>
         (cData?.completed &&
          (
          <ProductStockReportItem
          categoryData={cData}
            tabActive={activeTab}
            key={index}
            index={index}
          />  )

      )

      )}

      <hr className="border-[#000232] border-[1px] pt-20" />
      <div className="w-[38%] ml-auto text-xs font-[500]">
{/*         <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Product</p>
          <p>{fullApiData?.total_product}</p>
        </div> */}
       {/*  <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Sold Product Unit</p>
          <p>{fullApiData?.total_sales_sum_qty}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Purchase Product Unit</p>
          <p>{fullApiData?.total_purchase_sum_qty}</p>
        </div> */}
{/*         <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Customer</p>
          <p>{fullApiData?.total_customer}</p>
        </div> */}
        {/* <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Sold Amount </p>
          <p>{formatPrice(fullApiData?.total_sales_amount)}</p>
        </div>
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Purchase Amount </p>
          <p>{formatPrice(fullApiData?.total_purchase_amount)}</p>
        </div> */}
        <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
          <p>Total Stock</p>
          <p>{formatPrice(sumStock)}</p>
        </div>
      </div>
    </div>
  );
};

/* const styles = {
  productRoot: {
    display: 'flex',
    flexDirection: "column",
    ustifyContent: 'center'
  },
  tableTop: {
    width: '100%',
  },
  titletext: {
    border: '1px solid black',
    textAlign: 'center',
    margin: '0',
    padding: '2px',
    fontSize: '12px',
    width: '100%'
  },
  table: {
    borderCollapse: 'collapse',
    paddingRight: '20px'
  },
  column: {
    border: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'top',
    padding: '5px',
    fontSize: '12px',
  },
  logo: {
    width: '100px', // Adjust size as needed
    height: 'auto',
  },
  dateRange: {
    textAlign: 'center',
    padding: '5px',
    fontSize: '12px',
    border: '1px solid black',
  },
  productRow: {
    border: '1px solid black',
    textAlign: 'center',
    padding: '5px',
    fontSize: '12px',
    width: '100%',
  },
  th: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
    backgroundColor: 'deepskyblue',

  },
  printTh: {
    backgroundColor: '#407BFF',
    color: 'white',
    WebkitPrintColorAdjust: 'exact',
  },
  td: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
  },
  tdTransactionDate: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },

}; */


export default ProductStockDetails;