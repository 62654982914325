import React, { useEffect, useState } from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';
import { formatTimeStr } from 'antd/es/statistic/utils';

const ProductHistoryItem = (props) => {
  const {
    categoryData,
    tabActive,
    selectAllChecked,
    onSelect,
  } = props;

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(selectAllChecked);
  }, [selectAllChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelect(categoryData.id);
  };

  const createdDate = new Date(categoryData.created_at);

  const price = categoryData?.price * categoryData?.qty;

  return (
    <>
      {tabActive &&
        <div id={`categoryProductHistoryItem-${categoryData.id}`} className="grid text-center text-[14px] mt-8 py-[8px] font-nunito font-bold text-[#000232] grid-cols-6 border-b-[1px] border-[#E7EBF4]">
          <div className='text-left'>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </div>
          {/* date/time */}
          <div className="">
            <h1 className=" text-[#000] font-[600]">{formatDate(categoryData?.created_at)}</h1>
            <p className=" text-[#87889E] font-[500] text-xs">{timeDiff(createdDate)}</p>
          </div>
          {/* product name */}
          <div className="text-[#000] font-[500] text-center">
            {categoryData?.product_info?.name}
          </div>
          {/* category name */}
          <div className="text-[#000] font-[500] text-center">
            {categoryData?.product_info?.category?.name}
          </div>



          {/* customer */}
          <div className="text-[#000] font-[500] text-center">
            <p>#{categoryData.id}</p>
            <p className="text-[#87889E] font-[500] text-center">{categoryData?.sales?.customer_name}</p>
          </div>
          {/* Total */}
          <div className=" text-[#000] font-[500] text-right ">{price}</div>
        </div>}
    </>
  );
};
ProductHistoryItem.defaultProps = { className: null };

export default ProductHistoryItem;