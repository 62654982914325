import Item from 'antd/es/list/Item';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';


const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '10px',
    borderColor: '#C1CFEF',
    width: '100%',
    //boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#C1CFEF' : 'white',
    color: '#102048',
    '&:hover': {
      backgroundColor: '#C1CFEF',
    },
  }),
};

const WarrantyTypeForm = (props) => {
  const {
    loadingCallback,
    setWarningMessage,
    state,
    setShowModal,
    isOpen,
    formId,
    filteredProducts,
    setFilteredProducts,
    selectedProductWarrantyList,
    setSelectedProductWarrantyList,
    selectedItem,
    setSelectedItem,
    orderList,
  } = props;

  const selectedWarranty = selectedProductWarrantyList?.find((item) => item?.id === selectedItem?.id && item?.warranty );
  // const newSelectedWarrantyList = selectedWarranty ? selectedWarranty.warranty : null;

  const [newSelectedWarrantyList, setNewSelectedWarrantyList] = useState([])
  useEffect(()=>{
    setNewSelectedWarrantyList(selectedWarranty?.warranty);
  },[selectedWarranty])

  const {warrantyList} = state;
  const [newWarrantyList, setNewWarrantyList] = useState([...warrantyList, {id: "", warranties_count: selectedItem?.warranties_count, name: selectedItem?.warrenty}]);

  const handleSubmit = (e) =>{
    e.preventDefault();
    const uniqueNamesMap = new Map();
    const uniqueArray = newSelectedWarrantyList.filter((item) => {
      if (!uniqueNamesMap.has(item.id)) {
        uniqueNamesMap.set(item.id, true);
        return true;
      }
      return false;
    });

    const newSelectedProductWarrantyList = selectedProductWarrantyList.map((item) =>
      item.id === selectedItem.id ? { ...item, warranty: uniqueArray } : item
    );

    console.log( newSelectedProductWarrantyList);
    setSelectedProductWarrantyList(newSelectedProductWarrantyList);
    setShowModal(!isOpen)
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const handleSelectWaranty = (name, index) =>{
    const {value, label} = name;
    const selectedMethodObject = newWarrantyList.find((method) => method?.id === value );
    const newArray = [...newSelectedWarrantyList];
    newArray[index] = selectedMethodObject;
    setNewSelectedWarrantyList(newArray)

    console.log("SelectedArray", selectedMethodObject, value);


  }

  const handleAddWarranty = (index) => {
    if(newSelectedWarrantyList && newSelectedWarrantyList?.length>=newWarrantyList?.length) return;
    setNewSelectedWarrantyList([...newSelectedWarrantyList, newWarrantyList[newSelectedWarrantyList.length]])
  }

  const handleCancelField = async(item, idx) =>{
    const temp = [...newSelectedWarrantyList];
    temp.splice(idx, 1);
    setNewSelectedWarrantyList(temp);

  }
  console.log('default warranty', );

  return (
    <div  className=''>
      {
        newSelectedWarrantyList?.map((item, index)=>(
          <div key={index} className='pb-3' >
            <div className='flex flex-row justify-between items-center py-3 px-1'>
              <h1 className='text-md font-semibold font-lato px-1'>Warranty {index+1}</h1>
              {index!=0 && <div
                className='bg-red-600 text-white rounded-md cursor-pointer'
                onClick={()=>handleCancelField(item, index)}
              >
                <p className='text-sm px-[4px] py-[2px]'>Cancel</p>
              </div>}
            </div>

            <div className='flex items-center justify-center py-3 gap-3' >
              <Select
                onChange={(event) => {
                  handleSelectWaranty(event, index);
                }}
                styles={customStyles}
                defaultValue={{value: item?.warranty_id, label: item.warranties_count + " " + (item.name ? item.name : "" )}}
                className={`select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none px-[0px]  text-md focus:outline-none`}
                onMenuOpen={()=>setIsMenuOpen(true)}
                onMenuClose={()=>setIsMenuOpen(false)}
                options={ newWarrantyList?.map((warranty) => ({
                  value: warranty?.id,
                  label: warranty?.warranties_count + " " + (warranty.name ? warranty.name : ""),
                }))}
              />
            </div>

           { newSelectedWarrantyList.length>1 && <hr/> }
          </div>
        ))
      }
      <div className='flex flex-row justify-between items-center py-3 px-1'>
        <div>
          <h1 className='text-md font-semibold'>Add more condition</h1>
        </div>
        <div>
          <div
            onClick={handleAddWarranty}
            className="flex justify-center z-20 items-center border-2 text-[#000] text-[18px] font-nunito font-bold py-[10px] px-[10px] cursor-pointer rounded-lg capitalize"
          >
            <i class="fa-solid fa-plus"></i>
          </div>
        </div>
      </div>
      <div>
        <label
          htmlFor='my_modal_warranty'
          onClick={handleSubmit}

          className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-semibold text-lg"
        >Save
        </label>
      </div>
    </div>
  );
};

export default WarrantyTypeForm;