
import React, { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import Resizer from "react-image-file-resizer";
import { validateData } from '../utils/Common';
import { useFlashMessage } from '../context/FlashMessageContext';
import ImageUploader2 from './ImageUploader2';
import TextArea from '../components/products/productsadd/TextArea';
import { FlashMessage } from '../utils/FlashMessage';
import { ToastContainer, toast } from 'react-toastify';

//import arrayMutators from 'final-form-arrays';

const InvoiceSetting = props => {
  const { setUploadedFiles, setUploadedFilesWatermark, uploadDetails, setUploadDetails, currentUser } = props;
  const { setErrorFlashMessage } = useFlashMessage();
  const [isValid, setIsValid] = useState(true);
  const [fileLogo, setFile] = useState(props.initialValues?.invoiceSetting?.shop_logo);
  const [fileSign, setFileSign] = useState(props.initialValues?.invoiceSetting?.sign_authority);
  const [watermarkFile, setWatermarkFile] = useState(props.initialValues?.invoiceSetting?.watermark_type == 'Logo' ? props.initialValues.invoiceSetting.watermark_text : null);

  console.log(props.initialValues?.invoiceSetting);
  const [selectedValue, setSelectedValue] = useState(props.initialValues.invoiceSetting.watermark_type ? props.initialValues.invoiceSetting.watermark_type : 'text');

  const handleSelectChange = (event) => {
    //event.preventDefault();

    setSelectedValue(event.target.value);
  };


  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const handleImageChange = async (name, files) => {
    let tempUploadDetails = [...uploadDetails];
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        // setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        FlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,'error');
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob], files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      const existingIndex = tempUploadDetails.findIndex(
        (item) => item.input_name === name
      );

      if (existingIndex !== -1) {
        // Replace existing entry
        tempUploadDetails[existingIndex] = {
          name: files[i].name,
          size: sizeName,
          percentage: 0,
          file: file,
          input_name: name,
        };
      } else {
        // Add new entry
        tempUploadDetails.push({
          name: files[i].name,
          size: sizeName,
          percentage: 0,
          file: file,
          input_name: name,
        });
      }

    }
    if (name == 'shop_logo') {
      setFile(URL.createObjectURL(files[0]));
      setUploadedFiles(files);
      setUploadDetails(tempUploadDetails);
    } else if (name == 'sign_authority') {
      setFileSign(URL.createObjectURL(files[0]));
      setUploadedFiles(files);
      setUploadDetails(tempUploadDetails);
    } else {
      setWatermarkFile(URL.createObjectURL(files[0]));
      setUploadedFilesWatermark(files);
      setUploadDetails(tempUploadDetails);
    }
  };
  const handleWatermarkImageChange = async (files, name) => {
    let tempUploadDetails = [...uploadDetails];
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        // setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        FlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,'error');
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob], files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
        file: file,
        input_name: name,
      });
    }
    setWatermarkFile(URL.createObjectURL(files[0]));
    setUploadedFilesWatermark(files);

    setUploadDetails(tempUploadDetails);
  };



  return (
    <div className="px-3 pb-4">
      <ToastContainer />
      <FinalForm
        {...props}
        initialValues={props.initialValues.invoiceSetting}
        onSubmit={props.onSubmit}
        validate={(values) => ({
          shop_name: validateData(values.shop_name, 'Shop Name'),
          //shop_address: validateData(values.shop_address, 'Shop address'),
          //sign_authority: validateData(values.sign_authority, 'Sign of Authority'),
          name_authority: validateData(values.name_authority, 'Name Authority', false),
          //watermark_text: validateData(values.watermark_text, 'Text Watermark'),
        })}
        render={formRenderProps => {
          const {
            handleSubmit,
            handleChange,
            file,
            currency
          } = formRenderProps;
          return (
            <>
              <form
                method="post"
                className="col-sm-12"
                data-autosubmit="false"
                autoComplete="off"
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
                encType="multipart/form-data"
              >
                <div className="flex flex-col md:flex-row gap-3 mb-3 pt-4 border-t-[1px] border-solid border-[#E7EBF4]">
                  <div className="flex-1 flex flex-col justify-between">
                    <div className="mb-3">

                      <input
                        value={props.initialValues?.invoiceSetting?.users?.outlet_name ? props.initialValues?.invoiceSetting?.users?.outlet_name : props.initialValues?.invoiceSetting?.shop_name}  // Use defaultValue instead of value for read-only input
                        type="text"
                        placeholder="Shop Name"
                        className="bg-[#FFF] text-[#91919F] w-full h-[50px] rounded-[16px] px-3 focus:outline-none"
                        readOnly
                      />
                    </div>

                    <div className="">
                      <input
                        type="text"
                        value={props.initialValues?.invoiceSetting?.users?.address ? props.initialValues?.invoiceSetting?.users?.address : props.initialValues?.invoiceSetting?.shop_address}
                        placeholder="Shop Address"
                        className="bg-[#FFF] text-[#91919F] w-full h-[50px] rounded-[16px] px-3 focus:outline-none"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flex-1 flex flex-row gap-3">
                    <div className="flex-1">
                      <div className='text-[#A0AEC0] text-[18px] text-center pb-1'>Shop Logo</div>
                      <div>
                        <Field name="file-input">
                          {({ input }) => (<>
                            <ImageUploader2
                              name={"shop_logo"}
                              isBig={true}
                              onChange={(files) => handleImageChange("shop_logo", files)}
                              image={fileLogo ? fileLogo : props.initialValues?.invoiceSetting?.shop_logo}
                            />
                            {/* {file ? (
                          <img src={file} className="mt-2 rounded-lg object-contain w-[full] h-[full] mx-auto" alt="Shop Logo" />
                        ): null} */}

                          </>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="flex-1">
                      <div className='h-[15%] text-[#A0AEC0] text-[18px] text-center pb-1'>Shop Authority</div>
                      <div className='h-[85%]'>
                        <Field name="file-input">
                          {({ input }) => (<>
                            <ImageUploader2
                              name={"sign_authority"}
                              isBig={true}
                              onChange={(files) => handleImageChange("sign_authority", files)}
                              image={fileSign ? fileSign : props.initialValues?.invoiceSetting?.sign_authority}
                            />
                          </>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col sm:flex-row gap-3 mb-3'>
                  <div className='flex-1'>
                    <Field name="name_authority">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Name Authority"
                            className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                          />
                          {meta.touched && meta.error && (
                            <div className="warning-message">
                              {meta.error}
                            </div>
                          )}

                        </>
                      )}
                    </Field>
                  </div>

                  <div className='flex-1'>
                    {/* Select Field */}
                    <Field name="currency_id" component="select" className='w-full bg-[#FFF] rounded-[16px] px-3 py-[14px] focus:outline-none customSelect'>
                      <option className="text-blue-500" disabled value="">
                        Select Currency
                      </option>
                      {currency.map((item, index) => (
                        <option className="text-blue-500" key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>


                <div className='flex flex-col sm:flex-row gap-3 mb-3'>
                  <div className='flex-1'>
                    <Field
                      name="watermark_type"
                      component="select"
                      //onChange={handleSelectChange}
                      parse={value => {
                        // Do something with the selected value if needed
                        handleSelectChange({ target: { value } });
                        return value;
                      }}
                      initialValue={selectedValue}
                      className='w-full bg-[#FFF] rounded-[16px] px-3 py-[14px] focus:outline-none customSelect'
                    >
                      {/* <option className='text-blue-500' disabled value="">
                        Select Watermark Type
                      </option> */}
                      <option className="text-blue-500 text-[14px]" value="text">
                        Watermark Text
                      </option>
                      {/* <option className="text-blue-500 text-[14px]" value="Logo">
                        Watermark Image
                      </option> */}
                    </Field>
                  </div>

                  <div className='flex-1'>
                    <Field name="watermark_text">
                      {({ input, meta }) => (
                        <>
                          {/* {selectedValue !== 'Logo' && ( */}
                          <div id="inputText">
                            <input
                              {...input}
                              type="text"
                              placeholder="Text Watermark"
                              className="w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]"
                            />
                            {/* {meta.touched && meta.error && (
                                <div className="warning-message">
                                  {meta.error}
                                </div>

                              )} */}
                          </div>
                          {/* )} */}
                          {/* {selectedValue === 'Logo' && (
                            <div id="imageUploader2" className="mb-3">
                              <ImageUploader2
                                name={"watermark_text"}
                                isBig={true}
                                //onChange={(files) => handleWatermarkImageChange("image_path", files)}
                                onChange={handleWatermarkImageChange}
                                image={watermarkFile ? watermarkFile : null}
                              />
                            </div>
                          )} */}

                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className='flex flex-col sm:flex-row gap-3 mb-3'>
                  <div className='flex-1'>

                    <Field name="vat">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="number"
                            placeholder="Vat In Parcentage"
                            className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                          />


                        </>
                      )}
                    </Field>
                  </div>
                  <div className='flex-1'>
                    <Field name="tax">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="number"
                            placeholder="Tax In Parcentage"
                            className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                          />

                        </>
                      )}
                    </Field>
                  </div>
                </div>
                {/* mobile number */}
                <div className='flex mb-3'>
                    <Field name="mobile_number">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Mobile Number"
                            className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                          />

                        </>
                      )}
                    </Field>
                  </div>
                {/* social link,website */}
                <div className='flex flex-col sm:flex-row gap-3 mb-3'>


                <div className='flex-1'>
                    <Field name="social_link">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Social Link"
                            className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                          />

                        </>
                      )}
                    </Field>
                  </div>

                  <div className='flex-1'>
                    <Field name="web_address">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Web Address"
                            className='w-full h-[50px] rounded-[16px] px-3 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                          />

                        </>
                      )}
                    </Field>
                  </div>

                </div>


                {/* sale condition */}
                <div className='mb-2'>
                  <p className='font-nunito text-[14px] text-[#102048]    px-3 py-1 font-semibold'>Sale Condition</p>
                  <div className=''>
                    {/* Other form fields */}
                    <Field
                      name="sale_condition"
                      initialValue={props.initialValues?.invoiceSetting?.sale_condition}
                      render={({ input, meta }) => (
                        <TextArea
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          placeholder="Sale Condition"
                          label={false}
                          className='w-full rounded-[16px] min-h-[120px] px-3 py-2 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                        />
                      )}
                    />
                  </div>
                </div>

                {/* purchase condition */}
                <div className='mb-2'>
                  <p className='font-nunito text-[14px] text-[#102048]    px-3 py-1 font-semibold'>Purchase Condition</p>
                  <div className=''>
                    {/* Other form fields */}
                    <Field
                      name="purchase_condition"
                      initialValue={props.initialValues?.invoiceSetting?.purchase_condition}
                      render={({ input, meta }) => (
                        <TextArea
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          placeholder="Purchase Condition"
                          label={false}
                          className='w-full rounded-[16px] min-h-[120px] px-3 py-2 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                        />
                      )}
                    />
                  </div>
                </div>

                {/* wholsesale condition */}
                <div className='mb-3'>
                  <p className='font-nunito text-[14px] text-[#102048]    px-3 py-1 font-semibold'>Wholesale Condition</p>
                  <div className=''>
                    {/* Other form fields */}
                    <Field
                      name="wholesale_condition"
                      initialValue={props.initialValues?.invoiceSetting?.wholesale_condition}
                      render={({ input, meta }) => (
                        <TextArea
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          placeholder="Wholesale Condition"
                          label={false}
                          className='w-full rounded-[16px] min-h-[120px] px-3 py-2 focus:outline-none bg-[#FFF] text-[#91919F] placeholder:text-[#91919F]'
                        />
                      )}
                    />
                  </div>
                </div>

                <div className='mb-3'>
                  <button
                    type="submit"
                    className='w-full bg-[#034AFF] text-[#FFF] text-[18px] font-lato font-600 py-2 rounded-[16px]'
                  >
                    {''}
                    Save{''}
                  </button>
                </div>
              </form>
            </>
          );
        }}
      />
    </div>)
}



export default InvoiceSetting;