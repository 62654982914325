import React from "react";
import icon from "../assits/icon.svg";
import { useDropzone } from "react-dropzone";

const ImageUploader = ({ isBig, onChange, name, image }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Pass the accepted files to the onFileChange callback
      onChange(acceptedFiles,name);
    },
  });

  const files = acceptedFiles.map((file) => (
    <p key={file.path}>
      {file.path} - {file.size} bytes
    </p>
  ));

  return (
    <div
      className={`flex h-[100%] bg-[#FFF] gap-1 items-center justify-center ${isBig ? "p-3 md:py-16" : "p-6"
        } border-dashed border-2 border-[#6D81B4] rounded-[22px] cursor-pointer relative`}
    >
      <div {...getRootProps({ className: "dropzone" })}>
        <label htmlFor="drop" className="cursor-pointer">
          {acceptedFiles.length > 0 ? (
            <img
              className="w-full object-contain"
              src={URL.createObjectURL(acceptedFiles[0])} // Display the first uploaded file
              alt="Uploaded"
            />
          ) : (image !== null && image !='http://127.0.0.1:8000/uploads/15-Islam/1690304960.jpg') ? (
            <img
              className="w-full object-contain"
              src={image ? image : `${process.env.REACT_APP_ROOT_URL + "/icon.svg"}`} // Display the first uploaded file
              alt="Uploaded"
            />
          ) : (
            // Add additional conditions here if needed
            <div className={`${isBig ? "w-20" : "w-10"} mx-auto `}>
              <img className="w-full object-contain" src={`${process.env.REACT_APP_ROOT_URL + "/icon.svg"}`} alt="" />
            </div>
          )}
          <p
            className={`text-[#3E4F71] text-center font-lato  ${isBig ? "text-base" : "text-[9px]"
              } font-bold mb-[14px]`}
          >
            {acceptedFiles.length > 0
              ? "Image uploaded"
              : "Drop your image here, or "}
            <span className="text-[#407BFF]"><br/>Browse</span>
          </p>
          <p
            className={`text-[#3E4F71] ${isBig ? "text-xs" : "text-[7px]"
              } text-center  font-normal`}
          >
            Supports: JPG, JPEG, PNG
          </p>
        </label>
        <input
          {...getInputProps()}
          name={name}
        />
      </div>

    </div>
  );
};

export default ImageUploader;
