import React, { useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import Resizer from "react-image-file-resizer";
import { validateData } from "../utils/Common";
import { useFlashMessage } from "../context/FlashMessageContext";
import ImageUploader2 from "./ImageUploader2";
import TextArea from "../components/products/productsadd/TextArea";
import { FlashMessage } from "../utils/FlashMessage";
import { ToastContainer, toast } from "react-toastify";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";

//import arrayMutators from 'final-form-arrays';

const InvoiceSettingFormV2 = (props) => {
  const [links, setLinks] = useState({
    facebook: "",
    linkedin: "",
    instagram: "",
  });

  const [selected, setSelected] = useState({
    facebook: false,
    linkedin: false,
    instagram: false,
  });

  const handleInputChange = (e, platform) => {
    setLinks({ ...links, [platform]: e.target.value });
  };

  const handleCheckboxChange = (platform) => {
    setSelected({ ...selected, [platform]: !selected[platform] });
  };

  const {
    setUploadedFiles,
    setUploadedFilesWatermark,
    uploadDetails,
    setUploadDetails,
    currentUser,
  } = props;
  const { setErrorFlashMessage } = useFlashMessage();
  const [isValid, setIsValid] = useState(true);
  const [fileLogo, setFile] = useState(
    props.initialValues?.invoiceSetting?.shop_logo
  );
  const [fileSign, setFileSign] = useState(
    props.initialValues?.invoiceSetting?.sign_authority
  );
  const [watermarkFile, setWatermarkFile] = useState(
    props.initialValues?.invoiceSetting?.watermark_type == "Logo"
      ? props.initialValues.invoiceSetting.watermark_text
      : null
  );

  console.log(props.initialValues?.invoiceSetting);
  const [selectedValue, setSelectedValue] = useState(
    props.initialValues.invoiceSetting.watermark_type
      ? props.initialValues.invoiceSetting.watermark_type
      : "text"
  );

  const handleSelectChange = (event) => {
    //event.preventDefault();

    setSelectedValue(event.target.value);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const handleImageChange = async (name, files) => {
    let tempUploadDetails = [...uploadDetails];
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    for (let i = 0; i < files.length; i++) {
      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        // setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        FlashMessage(
          `Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,
          "error"
        );
        console.error(
          `Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`
        );
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = "";

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + "MB";
      } else {
        sizeName = size.toFixed(2) + "KB";
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(",")[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob], files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      const existingIndex = tempUploadDetails.findIndex(
        (item) => item.input_name === name
      );

      if (existingIndex !== -1) {
        // Replace existing entry
        tempUploadDetails[existingIndex] = {
          name: files[i].name,
          size: sizeName,
          percentage: 0,
          file: file,
          input_name: name,
        };
      } else {
        // Add new entry
        tempUploadDetails.push({
          name: files[i].name,
          size: sizeName,
          percentage: 0,
          file: file,
          input_name: name,
        });
      }
    }
    if (name == "shop_logo") {
      setFile(URL.createObjectURL(files[0]));
      setUploadedFiles(files);
      setUploadDetails(tempUploadDetails);
    } else if (name == "sign_authority") {
      setFileSign(URL.createObjectURL(files[0]));
      setUploadedFiles(files);
      setUploadDetails(tempUploadDetails);
    } else {
      setWatermarkFile(URL.createObjectURL(files[0]));
      setUploadedFilesWatermark(files);
      setUploadDetails(tempUploadDetails);
    }
  };
  const handleWatermarkImageChange = async (files, name) => {
    let tempUploadDetails = [...uploadDetails];
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    for (let i = 0; i < files.length; i++) {
      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        // setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        FlashMessage(
          `Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,
          "error"
        );
        console.error(
          `Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`
        );
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = "";

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + "MB";
      } else {
        sizeName = size.toFixed(2) + "KB";
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(",")[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob], files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
        file: file,
        input_name: name,
      });
    }
    setWatermarkFile(URL.createObjectURL(files[0]));
    setUploadedFilesWatermark(files);

    setUploadDetails(tempUploadDetails);
  };

  return (
    <div className="px-3 pb-4">
      <ToastContainer />
      <FinalForm
        {...props}
        initialValues={props.initialValues.invoiceSetting}
        onSubmit={props.onSubmit}
        validate={(values) => ({
          shop_name: validateData(values.shop_name, "Shop Name"),
          //shop_address: validateData(values.shop_address, 'Shop address'),
          //sign_authority: validateData(values.sign_authority, 'Sign of Authority'),
          name_authority: validateData(
            values.name_authority,
            "Name Authority",
            false
          ),
          //watermark_text: validateData(values.watermark_text, 'Text Watermark'),
        })}
        render={(formRenderProps) => {
          const { handleSubmit, handleChange, file, currency } =
            formRenderProps;
          return (
            <>
              <form
                method="post"
                className="col-sm-12"
                data-autosubmit="false"
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
                encType="multipart/form-data"
              >
                <div className="grid md:grid-cols-3 gap-6 col-span-3">
                  <div className="relative col-span-3">
                    <label
                      htmlFor="shopName"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Shop Name
                    </label>
                    <input
                      value={
                        props.initialValues?.invoiceSetting?.users?.outlet_name
                          ? props.initialValues?.invoiceSetting?.users
                              ?.outlet_name
                          : props.initialValues?.invoiceSetting?.shop_name
                      } // Use defaultValue instead of value for read-only input
                      type="text"
                      placeholder="Shop Name"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                      readOnly
                    />
                  </div>
                  {/* mobile number */}
                  <div className="relative col-span-3 md:col-span-1">
                    <label
                      htmlFor="phoneNumber"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Phone Number
                    </label>

                    <Field name="mobile_number">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Mobile Number"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  {/* Additional Phone Number */}
                  <div className="relative col-span-3 md:col-span-1">
                    <label
                      htmlFor="additional_mobile_number"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Additional Mobile Number
                    </label>

                    <Field name="additional_mobile_number">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Additional Mobile Number"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="col-span-3 md:col-span-1 row-span-2 flex gap-3">
                    {/* File Upload 1*/}
                    <div className="flex-1">
                      <div className="text-[#A0AEC0] font-semibold text-xs text-center pb-1">
                        Shop Logo
                      </div>
                      <div>
                        <Field name="file-input">
                          {({ input }) => (
                            <>
                              <ImageUploader2
                                name={"shop_logo"}
                                isBig={true}
                                onChange={(files) =>
                                  handleImageChange("shop_logo", files)
                                }
                                image={
                                  fileLogo
                                    ? fileLogo
                                    : props.initialValues?.invoiceSetting
                                        ?.shop_logo
                                }
                              />
                              {/* {file ? (
                          <img src={file} className="mt-2 rounded-lg object-contain w-[full] h-[full] mx-auto" alt="Shop Logo" />
                        ): null} */}
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                    {/* File Upload 2 */}
                    <div className="flex-1">
                      <div className="text-[#A0AEC0] font-semibold text-xs text-center pb-1">
                        Shop Authority
                      </div>
                      <div className="h-[85%]">
                        <Field name="file-input">
                          {({ input }) => (
                            <>
                              <ImageUploader2
                                name={"sign_authority"}
                                isBig={true}
                                onChange={(files) =>
                                  handleImageChange("sign_authority", files)
                                }
                                image={
                                  fileSign
                                    ? fileSign
                                    : props.initialValues?.invoiceSetting
                                        ?.sign_authority
                                }
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>

                  {/* Email Address */}
                  <div className="relative  col-span-3 md:col-span-1">
                    <label
                      htmlFor="email"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Email Address
                    </label>

                    <Field name="email">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Email Address"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  {/* Additional Email Address */}
                  <div className="relative  col-span-3 md:col-span-1">
                    <label
                      htmlFor="addtional_email"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Additional Email Address
                    </label>

                    <Field name="addtional_email">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Additional Email Address"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  {/* Shop Address */}
                  <div className="relative col-span-3">
                    <label
                      htmlFor="shop_address"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Shop Address
                    </label>
                    <input
                      type="text"
                      value={
                        props.initialValues?.invoiceSetting?.users?.address
                          ? props.initialValues?.invoiceSetting?.users?.address
                          : props.initialValues?.invoiceSetting?.shop_address
                      }
                      placeholder="Shop Address"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                      readOnly
                    />
                  </div>

                  {/* Authority Name */}

                  <div className="relative  col-span-3 md:col-span-1">
                    <label
                      htmlFor="name_authority"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Authority Name
                    </label>
                    <Field name="name_authority">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Authority Name"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                          {meta.touched && meta.error && (
                            <div className="warning-message">{meta.error}</div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  {/* Watermark text */}
                  <div className="relative  col-span-3 md:col-span-1">
                    <label
                      htmlFor="watermark_text"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Watermark
                    </label>
                    <Field name="watermark_text">
                      {({ input, meta }) => (
                        <>
                          {/* {selectedValue !== 'Logo' && ( */}
                          <div id="inputText">
                            <input
                              {...input}
                              type="text"
                              placeholder="Text Watermark"
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                            />
                            {/* {meta.touched && meta.error && (
                                <div className="warning-message">
                                  {meta.error}
                                </div>

                              )} */}
                          </div>
                          {/* )} */}
                          {/* {selectedValue === 'Logo' && (
                            <div id="imageUploader2" className="mb-3">
                              <ImageUploader2
                                name={"watermark_text"}
                                isBig={true}
                                //onChange={(files) => handleWatermarkImageChange("image_path", files)}
                                onChange={handleWatermarkImageChange}
                                image={watermarkFile ? watermarkFile : null}
                              />
                            </div>
                          )} */}
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="relative  col-span-3 md:col-span-1">
                    <label
                      htmlFor="web_address"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Web Address
                    </label>

                    <Field name="web_address">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Web Address"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="col-span-3 text-gray-600 font-semibold">
                    <h1 className="text-start">Social Media Links</h1>
                  </div>

                  {/* Facebook */}
                  <div className="relative  col-span-3 md:col-span-1">
                    <label
                      htmlFor="social_link"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Facebook
                    </label>
                    <Field name="social_link">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Facebook"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  {/* LinkedIn */}
                  <div className="relative  col-span-3 md:col-span-1">
                    <label
                      htmlFor="social_link2"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      LinkedIn
                    </label>
                    <Field name="social_link2">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="LinkedIn"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="relative  col-span-3 md:col-span-1">
                    <label
                      htmlFor="social_link3"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Instagram
                    </label>
                    <Field name="social_link3">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            placeholder="Instagram"
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="col-span-3 flex flex-col md:flex-row gap-3">
                    {/* Vat in Percentage */}
                    <div className="relative flex-1">
                      <label
                        htmlFor="vat"
                        className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                      >
                        Vat In Percentage
                      </label>
                      <Field name="vat">
                        {({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              type="number"
                              placeholder="Vat In Percentage"
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                            />
                          </>
                        )}
                      </Field>
                    </div>

                    {/* Tax in Percentage */}
                    <div className="relative flex-1">
                      <label
                        htmlFor="tax"
                        className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                      >
                        Tax In Percentage
                      </label>
                      <Field name="tax">
                        {({ input, meta }) => (
                          <>
                            <input
                              {...input}
                              type="number"
                              placeholder="tax In Percentage"
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  {/* sale condition */}
                  <div className="relative col-span-3">
                    <label
                      htmlFor="sale_condition"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Sale Condition
                    </label>
                    <div className="">
                      {/* Other form fields */}
                      <Field
                        name="sale_condition"
                        initialValue={
                          props.initialValues?.invoiceSetting?.sale_condition
                        }
                        render={({ input, meta }) => (
                          <TextArea
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            placeholder="Sale Condition"
                            label={false}
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* purchase condition */}
                  <div className="relative col-span-3">
                    <label
                      htmlFor="purchase_condition"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Purchase Condition
                    </label>
                    <div className="">
                      {/* Other form fields */}
                      <Field
                        name="purchase_condition"
                        initialValue={
                          props.initialValues?.invoiceSetting
                            ?.purchase_condition
                        }
                        render={({ input, meta }) => (
                          <TextArea
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            placeholder="Purchase Condition"
                            label={false}
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* wholsesale condition */}
                  <div className="relative col-span-3">
                    <label
                      htmlFor="wholesale_condition"
                      className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10"
                    >
                      Wholesale Condition
                    </label>
                    <div className="">
                      {/* Other form fields */}
                      <Field
                        name="wholesale_condition"
                        initialValue={
                          props.initialValues?.invoiceSetting
                            ?.wholesale_condition
                        }
                        render={({ input, meta }) => (
                          <TextArea
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            placeholder="Wholesale Condition"
                            label={false}
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* <div className="col-span-3 flex flex-col gap-3">
                    <div className="text-gray-600 font-semibold">
                      Social Media Links
                    </div>
                    <div className="flex gap-6">

                      <div className="flex items-center border rounded-lg p-3 w-60 gap-2">
                        <FaLinkedinIn className="text-blue-700 text-xl" />
                        <input
                          type="text"
                          placeholder="http://"
                          value={links.linkedin}
                          onChange={(e) => handleInputChange(e, "linkedin")}
                          className="outline-none text-sm w-full"
                        />
                        <input
                          type="checkbox"
                          checked={selected.linkedin}
                          onChange={() => handleCheckboxChange("linkedin")}
                          className="form-checkbox border-2 h-5 w-5 rounded-md text-blue-500 focus:ring-blue-500"
                        />
                      </div>


                      <div className="flex items-center border rounded-lg p-3 w-60 gap-2">
                        <FaInstagram className="text-pink-500 text-xl" />
                        <input
                          type="text"
                          placeholder="http://"
                          value={links.instagram}
                          onChange={(e) => handleInputChange(e, "instagram")}
                          className="outline-none text-sm w-full"
                        />
                        <input
                          type="checkbox"
                          checked={selected.instagram}
                          onChange={() => handleCheckboxChange("instagram")}
                          className="form-checkbox border-2 h-5 w-5 rounded-md text-blue-500 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div> */}
                </div>

                {/* there code are in backup of file*/}

                {/* <div className="mb-3">
                  <button
                    type="submit"
                    className="w-full bg-[#034AFF] text-[#FFF] text-[18px] font-lato font-600 py-2 rounded-[16px]"
                  >
                    {""}
                    Save{""}
                  </button>
                </div> */}
                <div className="grid md:grid-cols-2 col-span-3 mt-3">
                  {/* Save Button */}
                  <div className="md:col-span-2 flex justify-between gap-2">
                    <button
                      type="button"
                      className="flex-1 px-4 py-2 text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-100"
                      // onClick={() => setIsEditing(false)}
                    >
                      Discard Changes
                    </button>
                    <button
                      type="submit"
                      className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            </>
          );
        }}
      />
    </div>
  );
};

export default InvoiceSettingFormV2;
