import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import SelectBox from "./Select";

const ExpenseEditForm = (props) => {
  const {
    expenseFormData,
    formData,
    setFormData,
    expenseId,
    setOpen,
    refetch
  } = props;

  const [newFormData, setNewFormData] = useState({
  });

  const handleSubmit = () => {
    const token = localStorage.getItem("token");

    axios.post(`${process.env.REACT_APP_API_URL}update-expense/${expenseId}`, formData, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.success) {
        console.log("Expense updated successfully:", response.data.data);
        // Optionally close the modal or trigger a refresh of the data
        setOpen(false);
        refetch();
      } else {
        console.log("Failed to update expense:", response.data.errors);
      }
    })
    .catch((error) => {
      console.error("Error updating expense:", error);
    });
  };

  return(
    <>
      <div className="duration-500">

        <div className="w-full relative">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Name
          </label>
          <input
            type="text"
            name="party_name"
            placeholder="Name"
            value={formData.catogory_name || ''}
            onChange={(e) => setFormData({
              ...formData,
              catogory_name: e.target.value
            })}
            className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
          />
        </div>
        <div className="w-full relative mt-2">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Date
          </label>
          <input
            type="date"
            name="party_name"
            placeholder="Date"
            value={formData.transaction_date || ''}
            onChange={(e) => {
              const selectedDate = e.target.value; // The value from date picker in YYYY-MM-DD format
              setFormData({
                ...formData,
                transaction_date: selectedDate
              });
            }}
            className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
          />
        </div>
        {/* <div className="w-full relative mb-3">
          <label className=" font-nunito text-xs text-[#102048]  bg-white px-1 font-semibold">
            Expense Type
          </label>
            <SelectBox
              name="txnCategoryType"
              options={txnTypeList}
              value={formData.expense_name}
              //onChange={handleTxnTypeChange}
              //label={false}
              placeholder="Expense Type"
              className="w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]"
            />
        </div> */}
        <div className="w-full relative mt-2">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Amount
          </label>
          <input
            type="text"
            name="party_name"
            placeholder="0.00"
            value={formData.amount || ''}
            // onChange={(e) => setFormData({
            //   ...formData,
            //   amount: e.target.value
            // })}
            className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
          />
        </div>
        <div className="w-full relative mt-2">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            Description
          </label>
          <textarea
            type="text"
            name="description"
            value={formData.description || ''}
            onChange={(e) => setFormData({
              ...formData,
              description: e.target.value
            })}
            placeholder="Text"
            className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full  h-20 pt-2 rounded-xl bg-white resize-none"
          />
        </div>

        <div>
          <button
            //htmlFor="my_modal_payment_info"
            onClick={handleSubmit}
            //type="submit"
            className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
          >
            Save
          </button>
        </div>
      </div>
    </>
  )
}

export default ExpenseEditForm;