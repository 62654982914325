import React, { useContext, useEffect, useState } from 'react';
import { FaUsers } from 'react-icons/fa';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import CategorySaleGraph from './CategorySaleGraph/CategorySaleGraph';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../utils/Common';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const revenueDataState = {
  series: [
    {
      name: "series1",
      data: [390, 400, 280, 351, 412, 109, 100, 390, 400, 280, 351, 412],
    },
  ],
  options: {
    chart: {
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    xaxis: {
      labels: {
        format: "MMM",
      },
      type: "datetime",
      categories: [
        "2018-01-19T00:00:00.000Z",
        "2018-02-19T01:30:00.000Z",
        "2018-03-19T02:30:00.000Z",
        "2018-04-19T03:30:00.000Z",
        "2018-05-19T04:30:00.000Z",
        "2018-06-19T05:30:00.000Z",
        "2018-07-19T06:30:00.000Z",
        "2018-08-19T06:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
        "2018-10-19T06:30:00.000Z",
        "2018-11-19T06:30:00.000Z",
        "2018-12-19T06:30:00.000Z",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  },
};
const CategoryReport = (props) => {
  const {
    categoryReportsSvg,
    totalCategorySvg,
    saleCategorySvg,
    dueAmountSvg,
    loadingCallback,
    activeTab,
    WalletBlue,
    WalletGreen,
    WalletPurple,
    WalletOrrange,

   } = props

  const [dropDown, setDropDown] = useState(false);
  const [revenueData, setRevenueData] = useState(revenueDataState);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [allFetchedData, setAllFetchedData] = useState([]);
  const [salesChartData, setSaleChartData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  //---------------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      // Parse the employee data from local storage
      const employeeData = JSON.parse(employeeApiResponse);

      // Get the "Exporter" feature from the employee's role features
      const analyticsFeature = employeeData.role.features.find(
        (feature) => feature.name === "Analytics"
      );

      // Check if the "Exporter" feature and "Visible" option are both active
      if (analyticsFeature && analyticsFeature.status === 1) {

        // Grant access based on the route and permissions
        if (location.pathname === "/analytics") {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
      }
      setHasPermission(false);
      // If no permission, navigate to dashboard first
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
    // If not an employee, the useEffect gets skipped
  }, [navigate, location]);
  //---------------------

  const getCategoryReport = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}web-analytical-data?interval=${activeTab}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setAllFetchedData(fetchedData);
          const categoriesData = {
            categorySaleReport: {
              total: fetchedData?.category_sale_amount,
              percentage: fetchedData?.category_sales_percentage
                ? fetchedData?.category_sales_percentage
                : "0%",
            },
            totalCategory: {
              total: fetchedData?.total_category,
              // percentage: fetchedData?.total_category - percentage
              //   ? fetchedData?.total_category - percentage
              //   : "0%",
            },
            categorySales: {
              total: fetchedData?.category_sold,
              percentage: fetchedData?.category_sold_percentage
                ? fetchedData?.category_sold_percentage
                : "0%",
            },
            categoryDue: {
              total: fetchedData?.category_sale_due,
              percentage: fetchedData?.category_sale_due_percentage
                ? fetchedData?.category_sale_due_percentage
                : "0%",
            },
            mostSellingCategory: {
              total: fetchedData?.most_selling_category_amount,
              // percentage: fetchedData?.most_selling_category_amount
              //   ? fetchedData?.most_selling_category_amount
              //   : "0%",
            },
            totalSection: {
              totalUnit: fetchedData?.most_selling_category_product,
              totalAmount: fetchedData?.most_selling_total_amount,
              totalCustomer: fetchedData?.most_selling_customer_count,
              totalDue: fetchedData?.most_selling_total_due,
            }

          };

        console.log("==========");
        console.log(categoriesData);
          setCategoryData(categoriesData);
          setSaleChartData(fetchedData?.sales_chart);

        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  useEffect(() => {
    if(hasPermission){
      getCategoryReport(activeTab);
    }
  }, [activeTab,hasPermission]);
  useEffect(() => {
    if (salesChartData && salesChartData.length > 0) {
      let xaxisCategories = [];
      let xaxisCategoriesAmount = [];

      let xaxisFormat = "dd"; // Default format
      let xaxisType = "category"; // Default type

      // Check the type and set format accordingly
      if (salesChartData && salesChartData.length > 0) {
        const firstItem = salesChartData[0];
        if (activeTab === "monthly") {
          xaxisCategories = salesChartData.map((item) => item?.name);
          xaxisFormat = "MMM"; // Monthly format
          xaxisCategoriesAmount = salesChartData.map((item) => item?.amount);
        } else if (activeTab === "yearly") {
          xaxisCategories = salesChartData.map((item) => item?.name);
          xaxisFormat = "yyyy"; // Yearly format
          xaxisCategoriesAmount = salesChartData.map((item) => item?.amount);
        } else if (activeTab === "weekly") {
          xaxisCategories = salesChartData.map((item) => item?.name);
          xaxisFormat = "dd"; // Default format for daily
          xaxisCategoriesAmount = salesChartData.map((item) => item?.amount);
        } else {
          xaxisCategories = salesChartData.map((item) => item?.name);
          xaxisCategoriesAmount = salesChartData.map((item) => item?.amount);
        }
        // Add more conditions as needed
      }

      const revenueDataState = {
        series: [
          {
            name: "series1",
            data: xaxisCategoriesAmount,
          },
        ],
        options: {
          chart: {
            type: "area",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            labels: {
              format: xaxisFormat,
            },
            type: xaxisType,
            categories: xaxisCategories,
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        },
      };

      // Now, revenueDataState has the updated series.data and options.xaxis.categories
      console.log(revenueDataState);
      setRevenueData(revenueDataState);
    }
  }, [salesChartData]);


  return (
    <>

        <div className='mt-7 font-lato'>
          <div className="category-report rounded-[25px] bg-[#fff]   mt-4">
            <div className='flex justify-between items-center px-5 py-4 cursor-pointer' onClick={() => setDropDown(!dropDown)}>
              <div className='text-[#000232] font-semibold '>
                <p className='text-[25px]'>Category Sale Report</p>
              </div>
              <div  >
                <div  className={`flex items-center justify-center w-[40px] h-[40px] transform duration-300 ${dropDown ? "-rotate-180" : ""}   `}>
                  <FontAwesomeIcon icon={faAngleDown} className="text-[25px]" />
                </div>

              </div>
            </div>
            {dropDown && <div className='transform duration-500 px-5 py-4 '>

              <div className='grid grid-cols-1 md:grid-cols-2 gap-2 '>
                <div className="left-cards p-3">
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-3 mb-3'>
                    <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#FF7A00] border-2 border-solid border-[#FF7A00] flex justify-between py-8  px-3 rounded-xl">
                      <div className="space-y-1">
                        <img src={`${WalletOrrange}`}/>
                        <p>{categoryData?.categorySaleReport?.total?categoryData?.categorySaleReport?.total:0}  <sub className="text-xs font-extralight ml-1">tk</sub></p>
                        <p className="text-sm font-extralight">Category Sale Report</p>
                      </div>
                      <div className="text-right flex flex-col gap-2 justify-between">
                        <p className="">{categoryData?.categorySaleReport?.percentage}</p>
                        <img
                          src={`${categoryReportsSvg}`}
                          alt="logo"
                        />
                      </div>
                    </div>
                    <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#9B54E1] border-2 border-solid border-[#9B54E1] flex justify-between py-8  px-3 rounded-xl">
                      <div className="space-y-1">
                      <img src={`${WalletPurple}`}/>
                        <p>{categoryData?.totalCategory?.total?categoryData?.totalCategory?.total:0}</p>
                        <p className="text-sm font-extralight">Total Category</p>
                      </div>
                      <div className="text-right flex flex-col gap-2 justify-between">
                        <p className="">0</p>
                        <img
                          src={`${totalCategorySvg}`}
                          alt="logo"
                        />
                      </div>
                    </div>
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                    <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#29B170] border-2 border-solid border-[#29B170] flex justify-between py-8  px-3 rounded-xl">
                      <div className="space-y-1">
                      <img src={`${WalletGreen}`}/>
                        <p>{categoryData?.categorySales?.total?categoryData?.categorySales?.total:0}</p>
                        <p className="text-sm font-extralight">Sale Category</p>
                      </div>
                      <div className="text-right flex flex-col gap-2 justify-between">
                        <p className="">{categoryData?.categorySales?.percentage}</p>
                        <img
                          src={saleCategorySvg}
                          alt="logo"
                        />
                      </div>
                    </div>
                    <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#347AE2] border-2 border-solid border-[#347AE2] flex justify-between py-7  px-3 rounded-xl">
                      <div className="space-y-1">
                      <img src={`${WalletBlue}`}/>
                        <p>{formatPrice(categoryData?.categoryDue?.total?categoryData?.categoryDue?.total:0)} <sub>Tk</sub></p>
                        <p className="text-sm font-extralight">Due Amount</p>
                      </div>
                      <div className="text-right flex flex-col gap-2 justify-between">
                        <p className="">{categoryData?.categoryDue?.percentage}</p>
                        <img
                          src={dueAmountSvg}
                          alt="logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="  border-2 border-solid border-[#29B170]  rounded-[50px] p-3">
                  <CategorySaleGraph
                    allFetchedData={allFetchedData}
                    revenueData={revenueData}
                    type={activeTab}
                    loadingCallback={loadingCallback}
                    chartName="Category Sale Chart"
                  />
                </div>
              </div>

              <div>
                <div className='text-[#000232] font-semibold '>
                  <p className='text-[25px]'>Highest Selling Category</p>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 '>

                  <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] col-span-1 bg-transparent text-[#9B54E1] border-2 border-solid border-[#9B54E1] flex justify-between py-8  px-3 rounded-xl">
                    <div className="space-y-1">
                    <img src={`${WalletPurple}`}/>
                      <p>{formatPrice(categoryData?.mostSellingCategory.total)}  <sub className="text-xs font-extralight ml-1">tk</sub></p>
                      <p className="text-sm font-extralight">item name</p>
                    </div>
                    <div className="text-right flex flex-col gap-2 justify-between">
                      <p className="">0%</p>
                      <img
                        src={totalCategorySvg}
                        alt="logo"
                      />
                    </div>
                  </div>

                  <div className='font-lato text-[20px] col-span-2 p-3 rounded-lg border-2 shadow-[0_0_5px_rgba(0,0,0,0.1)]'>
                    <div className='flex flex-col sm:flex-row items-center gap-2'>
                      <p className="w-full sm:w-[150px]">Total Sale Unit</p>
                      <hr className='flex-grow bg-green-600 h-4 mx-3'></hr>
                      <p>{formatPrice(categoryData?.totalSection?.totalUnit?categoryData?.totalSection?.totalUnit:0)}</p>
                    </div>
                    <div className='flex flex-col sm:flex-row items-center gap-2'>
                      <p className="w-full sm:w-[150px] whitespace-nowrap ">Total Sale Amount</p>
                      <hr className='flex-grow bg-green-600 h-4 mx-3'></hr>
                      <p>{formatPrice(categoryData?.totalSection?.totalAmount?categoryData?.totalSection?.totalAmount:0)}<sub className="text-xs font-extralight ml-1">BDT</sub></p>
                    </div>
                    <div className='flex flex-col sm:flex-row items-center gap-2'>
                      <p className="w-full sm:w-[150px]">Total Customer</p>
                      <hr className='flex-grow bg-green-600 h-4 mx-3'></hr>
                      <p>{formatPrice(categoryData?.totalSection?.totalCustomer?categoryData?.totalSection?.totalCustomer:0)}</p>
                    </div>
                    <div className='flex flex-col sm:flex-row items-center gap-2'>
                      <p className="w-full sm:w-[150px]">Total Due</p>
                      <hr className='flex-grow bg-green-600 h-4 mx-3'></hr>
                      <p>{formatPrice(categoryData?.totalSection?.totalDue?categoryData?.totalSection?.totalDue:0)}<sub className="text-xs font-extralight ml-1">BDT</sub></p>
                    </div>
                  </div>

                </div>
              </div>
              <div>

              </div>

              <div className="flex justify-end my-3" onClick={() => handleNavigate('/category-history')}>
                <button className="border-b-2 cursor-pointer border-[#034AFF] text-[#034AFF] text-[16px]">View All</button>
              </div>

            </div>}
          </div>
        </div>


    </>
  );
};

export default CategoryReport;