import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { formatDate, timeDiff } from '../../../utils/Common';


const RecentlyProductDetailHistory = ({purchaseData}) => {
  console.log("data", purchaseData);
  const navigate = useNavigate();
  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    console.log(purchaseData?.invoice_id);
    // navigate(`/purchase-invoice/${purchaseData?.invoice_id}`);
    navigate(`/purchase-invoice/${purchaseData?.invoice_id}`);
  };

  const createdDate = new Date(purchaseData?.purchase_date_time);


  return (
    <div>
      <div className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-6 items-start">

        <div className="text-left col-span-1">
          <p className="text-[#000] font-[500] text-md">#{purchaseData?.invoice_id}</p>
          <p className="text-[#000] font-[500] text-md">{purchaseData?.exporter_name}</p>
        </div>

        <div className="text-[#000] font-[500] text-center col-span-1">
          <p className="text-[#000] font-[500] text-md">{purchaseData?.purchase_unit_price}</p>
        </div>


        <div className="text-[#000] font-semibold text-center col-span-1">
          <p className="text-[#000] font-[500] text-md">{purchaseData?.purchase_qty}</p>
        </div>


        <div className="text-[#000] font-[500] text-center col-span-1">
          <h1 className=" text-[#000] font-[600]">{formatDate(purchaseData?.purchase_date_time)}</h1>
          <p className=" text-[#87889E] font-[500] text-sm">{timeDiff(createdDate)}</p>
        </div>

        <div className="text-[#000] font-[500] text-center col-span-1">
          <div className={` ${purchaseData?.status === 1 ? "text-red-600" : purchaseData?.status==2 ?  "text-orange-500" : "text-green-600" } font-[500] text-center`}>{purchaseData?.status === 1 ? "Processing" : purchaseData?.status==2 ? "Hold" :   "Completed"}</div>
        </div>

        {/* <div className=" text-[#000] font-[500] flex justify-end col-span-1">
          <button onClick={handleClick} className="cursor-pointer border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[8px] pt-[2px] pb-[0px] my-[2px]">View</button>
        </div> */}
        <div class="flex justify-end">
          <Link
            class="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]"
            to={`/exporter-invoice/${purchaseData?.invoice_id}`}
          >
            VIEW
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RecentlyProductDetailHistory;