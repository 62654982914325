import React from "react";
import TableRow from "./TableRow";

const Table = ({data,type='invoice',getCategory,getSubCategory,getBrand,getUnits,getInvoicelist,getSingleCategoryList}) => {

  const tableData = data.data ? data.data : data;
  return (

      <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
      <table className=" w-full text-sm text-left">
        <tbody>
          {tableData.map((items,index)=><TableRow key={index} type={type} items={items} getCategorys={getCategory} getSingleCategoryList={getSingleCategoryList} getSubCategorys={getSubCategory} getBrands={getBrand} getUnit={getUnits} getInvoicelist={getInvoicelist}></TableRow>) }
        </tbody>
      </table>
    </div>
  );
};

export default Table;
