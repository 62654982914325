import html2pdf from 'html2pdf.js';
import React, { useEffect, useState } from 'react';
import QRCode from "react-qr-code";
import { formatPhoneNumber, numberToWords, formatPrice,invoiceCalculate } from '../../utils/Common';
import PdfHtmlContent from './PdfHtmlContent/PdfHtmlContent';
import PdfHtmlFor2mm from './PdfHtmlContentFor2mm/PdfHtmlFor2mm';
import PdfHtmlFor3mm from './PdfHtmlContentFor3mm/PdfHtmlContentFor3mm';
import Watermark from './Watermark/Watermark';
import { FaChevronDown, FaCloudDownloadAlt } from "react-icons/fa";
import { IoPrint } from "react-icons/io5";
import axios from 'axios';
import DeliveryAdcanceInvoice from './DeliveryInvoicePage/DeliveryAdcanceInvoice';
import DeliveryAdvanceInvoice3mm from './DeliveryInvoicePage/DeliveryAdvanceInvoice3mm';
import DeliveryAdvanceInvoice2mm from './DeliveryInvoicePage/DeliveryAdvanceInvoice2mm';

const AdvanceBookingDetail = props => {
  const [pageSelect, setpageSelect] = useState(null);
  const [dropdownPdf, setDropdownPdf] = useState(false)
  const [dropdownPrint, setDropdownPrint] = useState(false)
  const originalContents = document.body.innerHTML;


  const {
    invoice,
    invoiceSetting,
    paperSize,
    // deliveryData,
    id
  } = props;
  const userInfo = invoice.user_info;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const monthName = invoiceDate.toLocaleString("en-US", { month: "long" });
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';
  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalQty = 0;
  let totalItems = 0;
  let totalDiscount = 0;
  let totalPrice = 0;

  const handlePrint = (elementId) => {
    const printContents = document.getElementById(elementId).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
  };

  window.onafterprint = () => {
    document.body.innerHTML = originalContents;
    //  Restore original content
      window.location.reload();
  };

  const handleGeneratePdf = async (pageSize, pageSizeNumber) => {
    const element = document.getElementById('pdfConentAreas');
    const element2 = document.getElementById('pdfConentAreas3');
    const element3 = document.getElementById('pdfConentAreas2');

    if (pageSizeNumber === 1) {
      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
    }
    else if (pageSizeNumber === 2) {
      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
    }
    else if (pageSizeNumber === 3) {
      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
    }

    const formatOptions = {
      'a4': 'a4',
      '3mm': [80, 297], // Custom size for '3mm' format
      '2mm': [56, 297], // Custom size for '2mm' format
    };
    const shopLogoUrlWithProxy = `<span class="math-inline">${API_BASE_URL}</span>${shop_logo}`; // Prepend proxy URL
    const opt = {
      margin: 0.1,
      filename: 'myPdf.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      //image: { type: 'jpeg', quality: 0.98, baseURL: 'https://api-outlet-expense.squadinnovators.com' },

      html2canvas: {
        scale: 1,
        proxy: shopLogoUrlWithProxy,
        useCORS: true,
        allowTaint: false,
        logging: false,
      },
      jsPDF: {
        unit: 'mm',
        format: formatOptions[pageSize], // 'a4', '3mm', or '2mm'
        orientation: 'portrait',
        marginLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
      },
    };

    //const cssUrl = window.location.origin + '/static/pdfstyles.module.css';
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    //link.href = cssUrl;
    head.appendChild(link);
    const images = element?.querySelectorAll('img');
    // const images2 = element2.querySelectorAll('img');
    // const images3 = element3.querySelectorAll('img');
    let loadedImages = 0;

    // const checkImagesLoaded = () => {
    //   loadedImages++;

    const generatePdf = () => {
      html2pdf()
        .set(opt)
        .from(element)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
          link.remove();
        });
    };
    const generatePdf2 = () => {
      html2pdf()
        .set(opt)
        .from(element2)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
          link.remove();
        });
    };
    const generatePdf3 = () => {
      html2pdf()
        .set(opt)
        .from(element3)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
          link.remove();
        });
    };
    //   if (loadedImages === images?.length) {
        // All images are loaded, proceed to generate the PDF
        if (pageSizeNumber === 1) {
          generatePdf();
        }
        else if (pageSizeNumber === 2) {
          generatePdf2();
        }
        else if (pageSizeNumber === 3) {
          generatePdf3();
        }

      //}
      // if (loadedImages === images2.length) {
      //   // All images are loaded, proceed to generate the PDF
      //   generatePdf2();
      // }
      // if (loadedImages === images3.length) {
      //   // All images are loaded, proceed to generate the PDF
      //   generatePdf3();
      // }

    //};



    // Add event listener to each image to check when they are loaded
    // if (images != null) {
    //   images.forEach(img => {
    //     img.addEventListener('load', checkImagesLoaded);
    //   });
    // }

    // // Start the PDF generation process
    // if (images != null) {
    //   images.forEach(img => {
    //     if (img.complete) {
    //       // If the image is already cached, trigger the load event manually
    //       checkImagesLoaded();
    //     }
    //   })
    // };


  };

  const [deliveryData, setDeliveryData] = useState({
    type_name: "",
    company_name: ""
  });

  const getDelivery = () => {
    const token = localStorage.getItem("token");
    axios.get(
      `${process.env.REACT_APP_API_URL}delivery-method-list`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success){
        const data = response.data.data.data;
        const method = data.find((item) => item.id === invoice.delivery_method_id);
        console.log("method", method);
        if((method.type_name).toLowerCase() == 'hand-to-hand'){
          // deliveryData.type_name = method.type_name;
          setDeliveryData({...deliveryData, type_name: method.type_name});
        }
        else{
          deliveryData.type_name = method.type_name;
          const matchingInfo = method.delivery_infos.find(
            (info) => info.id == invoice.delivery_info_id
          );
          if (matchingInfo) {
            // deliveryData.company_name = matchingInfo.company_name;
            setDeliveryData({...deliveryData, type_name: method.type_name, company_name: matchingInfo.company_name});
          }
        }
        // console.log("delivery", deliveryData);
      }
    })
    .catch((error) => {
      // navigate('/login');
      // console.log(error);
    });
  };
  useEffect(()=>{
    getDelivery()
  },[invoice])

  return (
    <div>
      {
        (deliveryData.type_name) == 'hand-to-hand' ?
        <>
          <div className="invoice-detail-page font-nunito">
          {/* invoice header */}
            <div className="text-center text-xs md:text-sm lg:text-base">
              <div>
                <img
                  src={shop_logo}
                  alt={invoice.invoice_id}
                  className="mx-auto w-1/2 md:w-2/3 max-w-[260px]"
                />
                <div className=' leading-4 sm:leading-5 md:leading-6 mt-[16px] font-normal'>
                  {userInfo?.outlet_name &&
                    <p className='capitalize text-[22px] font-semibold'>{userInfo?.outlet_name} </p>
                  }
                  {userInfo?.address &&
                    <p>{userInfo?.address}</p>
                  }
                  {invoice?.user_info?.phone &&
                    <p>{formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}</p>
                  }
                </div>
              </div>
            </div>
            {/* customer info and QR */}
            <div className="flex items-center mt-[20px] justify-between">
              <div className="text-black space-y-[2px] md:space-y-[6px] font-nunito text-xs md:text-base lg:text-lg">
                <p> <span className='font-semibold'>Customer Name</span>  :  {invoice.customer_name} </p>
                <p><span className='font-semibold'>Customer Phone</span> :  {invoice.country_code}{invoice.customer_phone}</p>
                <p><span className='font-semibold'> Customer ID </span> :#{invoice.customer_id}</p>
                <p><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p>
              </div>
              <div className="w-[45px] sm:w-[65px] md:w-[100px]">
                <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={100} />
              </div>
            </div>
            <div className='text-xs md:text-base lg:text-lg' style={{ width: '100%', backgroundColor: '#3056FA', padding: '10px', textAlign: 'center', margin: '15px auto' }}>
              <p className="text-white font-nunito py-1 text-14 font-bold uppercase">Invoice No: {invoice.invoice_id}</p>
            </div>
          {/* table */}
          <div className='max-w-[calc(100vw-28vw)] w-[calc(100vw-28vw)] mx-auto md:w-full md:max-w-full overflow-x-auto'>
          <table className='table'>
                <thead>
                  <tr className="leading-normal text-sm md:text-base lg:text-lg font-nunito tracking-wide">
                    <th className='font-nunito'>Item Name</th>
                    <th className='font-nunito text-center '>Warranty</th>
                    <th className='font-nunito text-center'>Quantity</th>
                    <th className='font-nunito text-center'>Price</th>
                    <th className='font-nunito text-right'>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark> */}
                  {invoice.sales_details.map((item) => {
                    totalQty += Number(item.qty);
                    totalItems += 1;
                    let discount = 0;
                    if (item.product_info.discount_type == 'Percentage' || item.product_info.discount_type == '2') {
                      discount = item.product_info.retails_price * (item.product_info.discount / 100);
                    } else {
                      discount = item.product_info.discount;
                    }
                    totalDiscount += Number(discount);
                    const subtotalPrice = formatPrice(item.qty * item.price);
                    totalPrice += Number(subtotalPrice);
                    return (
                      <tr key={item.id} className=" font-nunito border-[#E7EBF4] font-normal text-sm md:text-base lg:text-lg relative">
                        <td className=''>{item?.product_info?.name} <br /><span className='text-[13px]'>{item?.product_info?.serial}</span></td>
                        {/* <td className='text-center'>{item?.product_info?.warrenty ? item?.product_info?.warrenty : '        '}</td> */}
                        <td className='text-center'>
                          {/* {
                            item.defaultwarranties ?
                            (
                              item.defaultwarranties.map((warrantyItem) => (
                                warrantyItem.invoice_id === item.invoice_id && warrantyItem.product_id===item.product_id  &&
                                (

                                  warrantyItem.warranty  ? (
                                    <h1 className='text-sm my-1'>{warrantyItem?.warranty?.warranties_count} {" "}{warrantyItem?.warranty?.name}</h1>
                                  ) : (
                                    <h1 className='text-sm my-1'>{warrantyItem?.default_warranties_count} {" "}
                                    {item?.product_info?.warrenty}
                                    </h1>
                                  )
                                )
                              ))
                            )
                            : (
                              <h1 className='text-sm my-1'>{item?.product_info?.warrenty}</h1>
                            )
                          } */}
                          {
                          invoice.defaultwarranties &&
                          invoice.defaultwarranties.map((warrantyItem) =>{
                            return (
                             warrantyItem.product_id===item.product_id &&
                            (
                              warrantyItem.warranty_id==null ? (
                                <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                                {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                                </h1>
                              ) : (
                                <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                                {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                              )
                            )
                          )}
                          )
                        }
                        </td>
                        <td className='text-center'>{item.qty}</td>
                        <td className='text-center'>{item.price}</td>
                        <td className=' text-right font-semibold md:font-semibold'>{subtotalPrice}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</td>
                      </tr>
                    );
                  }
                  )}
                </tbody>
                <Watermark text={invoice?.user_info?.invoiceSetting?.watermark_text}></Watermark>
              </table>
          </div>

            {/* <DashedLine2 /> */}
            <br />
            <div className='flex justify-between gap-2'>
              <div className="terms-condition w-1/2 font-normal pt-2 md:pt-0 pr-4  md:font-medium text-[12px] md:text-[18px]">
                {invoice?.user_info?.invoice_settings?.condition ?
                  invoice?.user_info?.invoice_settings?.condition
                  : (
                    ""
                    // <p>Damage caused by misuse, abuse, or neglect.<br />
                    //   Unauthorized modifications or repairs.<br />
                    //   Acts of nature, such as floods, earthquakes, or other disasters.<br />
                    //   This may include proper use.</p>
                  )}
              </div>
              {/* invoice sumary */}
              <div className="w-1/2 text-xs md:text-sm lg:text-base font-semibold pl-2  md:pl-8">
                <div className='space-y-[2px] mb-2'>
                <p className='flex justify-between'>
                  <span>TOTAL ITEMS</span>
                  <span>{totalItems}</span>
                </p>
                <p className='flex justify-between'>
                  <span>SUB TOTAL</span>
                  <span>{formatPrice(totalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
                </p>
                <p className='flex justify-between'>
                  <span>DISCOUNT</span>
                  <span>{formatPrice(invoice.discount ?invoice.discount : 0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
                </p>
                <p className='flex justify-between' >
                  <span>Vat {''}</span>
                  <span>{invoice.vat ? formatPrice(invoice.vat) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
                </p>
                <p className='flex justify-between'>
                  <span>Service Charge {''}</span>
                  <span>{invoice.tax ? formatPrice(invoice.tax) : 0.00}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
                </p>
                </div>
                <hr></hr>
                <div className='my-2'>
                <p className='flex justify-between'>
                  <span>Grand Total</span>
                  <span>{formatPrice(invoiceCalculate(invoice).grandTotal)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
                </p>
                <p className='flex justify-between'>
                  <span>Paid Amount</span>
                  {/* <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(totalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
                  <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
                </p>
                </div>
                <hr></hr>
                <p className='flex justify-between mt-2'>
                  <span>Due Amount</span>
                  <span>{formatPrice(invoiceCalculate(invoice).dueAmount)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'}</span>
                </p>
              </div>
            </div>
            <p className='text-gray-900 mt-5 font-nunito text-[20px] font-semibold leading-normal text-center'>In word: {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))} taka only</p>
            <div style={{ width: '100%', backgroundColor: '#034AFF', padding: '10px', textAlign: 'center', margin: '25px auto' }}>
              <p className="text-white font-nunito text-14 font-bold">You saved {formatPrice(invoice.discount ?invoice.discount : 0)}{invoice?.user_info?.invoiceSetting?.currency_info?.code ? invoice?.user_info?.invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
            </div>
            {/* signature */}
              <div className='text-xs md:text-sm lg:text-base'>
                <div className='flex justify-between'>
                  <p className="invisible">.</p>

                </div>
                <div className="flex justify-between m-2">
                  <p><hr></hr> Customer Signature</p>
                  <p><hr></hr> Seller Signature </p>
                </div>
              </div>
            {/* footer */}
            <div className='text-center font-nunito mt-8 text-xs md:text-sm lg:text-base'>
              <h2 className='text-orange-500  text-xl font-semibold '>Thanks for purchase</h2>
              <p className='text-black mt-3 text-xs '>A Product of SQUAD INNOVATORS </p>
            </div>

          </div>
        </> :
        <>
          <div id="invoice-detail-page" className="invoice-detail-page font-nunito">
            <div className="text-center text-xs md:text-sm grid grid-cols-3 lg:text-base">

              {/* blank */}
              <div></div>
              {/* outlet info */}
              <div>
                <img
                  src={shop_logo}
                  alt={invoice.invoice_id}
                  className="mx-auto w-1/2 md:w-2/3 max-w-[260px]"
                />
                {/* <div className=" leading-4 sm:leading-5 md:leading-6 mt-[16px] font-normal"> */}
                <div className=" leading-4 sm:leading-5 md:leading-6 mt-[16px] font-semibold space-y-1">
                  {userInfo?.outlet_name ? (
                    <p className='text-[22px] font-semibold'>{userInfo?.outlet_name} </p>
                  ) : (
                    ""
                    // <p>Outlet Name</p>
                  )}
                  {userInfo?.address ? (
                    <p>{userInfo?.address}</p>
                  ) : (
                    ""
                    // <p>Outlet Address</p>
                  )}
                  {invoice?.user_info?.phone ? (
                    <p>{formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}</p>
                  ) : (
                    ""
                    // <p>Outlet phone </p>
                  )}
                </div>
              </div>
              {/* QR */}
              <div className="w-[45px] sm:w-[65px] md:w-[85px] ml-auto">
                <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={100} />
              </div>
            </div>
            <div className="flex items-start gap-6 lg:gap-10 mt-[20px] md:mt-[30px] justify-between">
              <div className="text-black  w-1/2 space-y-[2px] md:space-y-[6px] font-nunito text-xs md:text-base lg:text-lg">
                <p>
                  {" "}
                  <span className="font-semibold">Customer Name</span> :{" "}
                  {invoice.customer_name}{" "}
                </p>
                <p>
                  <span className="font-semibold">Customer Phone</span> :{" "}
                  {invoice.country_code}
                  {invoice.customer_phone}
                </p>
                <p>
                  <span className="font-semibold"> Customer ID </span> : #
                  {invoice.customer_id}
                </p>
                <p className="flex gap-1">
                  <span className="font-semibold whitespace-nowrap"> Invoice Date </span>:{" "}
                <span> {formattedDateTime}{" "}</span>
                </p>
              </div>
              <div className="text-black  w-1/2 font-nunito text-xs md:text-base lg:text-lg">
                <div className="w-5/6 ml-auto space-y-[2px] capitalize md:space-y-[6px]">
                  <p>
                    {" "}
                    <span className="font-semibold">Delivery Method</span> :{" "}
                    {deliveryData.type_name}{" "}
                  </p>
                  <p className="flex gap-1">
                    <span className="font-semibold whitespace-nowrap">Delivery Company</span> :{" "}
                    <span>{deliveryData.company_name}{" "}</span>
                  </p>
                  <p className="flex gap-1">
                    <span className="font-semibold whitespace-nowrap">
                      Receiver Name
                    </span>
                    :{" "}
                    <span className="whitespace-wrap">
                      {invoice.delivery_customer_name}
                    </span>
                  </p>
                  <p className="flex gap-1">
                    <span className="font-semibold whitespace-nowrap">
                      Receiver Phone{" "}
                    </span>{" "}
                    : <span className="">{invoice.delivery_customer_phone}</span>
                  </p>
                  <p className="flex gap-1">
                    <span className="font-semibold whitespace-nowrap">
                      Receiver Address
                    </span>{" "}
                    : <span className="">{invoice.delivery_customer_address} </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="text-xs md:text-base lg:text-lg"
              style={{
                width: "100%",
                backgroundColor: "#3056FA",
                padding: "10px",
                textAlign: "center",
                margin: "15px auto",
              }}
            >
              <p className="text-white font-nunito py-1 text-14 font-bold uppercase">
                Invoice No: {invoice.invoice_id}
              </p>
            </div>
            <div className="overflow-x-auto">
              <table className="table">
                <thead id="pdfContentTHead">
                  <tr className="leading-normal text-sm md:text-base lg:text-lg font-nunito tracking-wide">
                    <th className="font-nunito">Item Name</th>
                    <th className="font-nunito text-center ">Warranty</th>
                    <th className="font-nunito text-center">Quantity</th>
                    <th className="font-nunito text-center">Price</th>
                    <th className="font-nunito text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark> */}
                  {invoice?.sales_details?.map((item) => {
                    totalQty += Number(item.qty);
                    totalItems += 1;
                    {
                      /* const subtotalPrice = formatPrice(item.qty * item.price); */
                    }
                    const subtotalPrice = item.qty * item.price;
                    totalPrice += Number(subtotalPrice);
                    return (
                      <tr
                        key={item.id}
                        className=" font-nunito border-[#E7EBF4] font-normal text-sm md:text-base lg:text-lg relative"
                      >
                        <td className="">{item?.product_info?.name}<br /><span className='text-[13px]'>{item?.product_info?.serial}</span></td>
                        <td className="text-center">
                          {/* {item?.product_info?.warrenty
                            ? item?.product_info?.warrenty
                            : "        "} */}
                            {/* {(item?.product_info?.defaultwarranties[0]?.invoice_id===invoice.invoice_id && <h1 className='text-sm my-1'>
                              {item?.product_info?.defaultwarranties[0]?.default_warranties_count}
                            </h1> )} */}
                          {
                            invoice.defaultwarranties &&
                            invoice.defaultwarranties.map((warrantyItem) =>{
                              return (
                              warrantyItem.product_id===item.product_id &&
                              (
                                warrantyItem.warranty_id==null ? (
                                  <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                                  {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                                  </h1>
                                ) : (
                                  <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                                  {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                                )
                              )
                            )}
                            )
                          }
                        </td>
                        <td className="text-center">{item.qty}</td>
                        <td className="text-center">{item.price}</td>
                        <td className=" text-right font-semibold md:font-semibold">
                          {subtotalPrice}
                          {invoice?.user_info?.invoiceSetting?.currency_info?.code
                            ? invoice?.user_info?.invoiceSetting?.currency_info
                                ?.code
                            : " BDT"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <br />
            <div className="flex justify-between">
              {/* disclaimer */}
              <div className="w-1/2 text-xs md:text-base pr-1 md:pr-0">
                {invoiceSetting?.condition ? (
                invoiceSetting?.condition
              ) : (
                ""
                // <p>
                //   Damage caused by misuse, abuse, or neglect.
                //   <br />
                //   Unauthorized modifications or repairs.
                //   <br />
                //   Acts of nature, such as floods, earthquakes, or other disasters.
                //   <br />
                //   This may include proper use.
                // </p>
              )}
                {/* <p>
                  Damage caused by misuse, abuse, or neglect.
                  <br />
                  Unauthorized modifications or repairs.
                  <br />
                  Acts of nature, such as floods, earthquakes, or other disasters.
                  <br />
                  This may include proper use.
                </p> */}
              </div>
              {/* invoice sumary */}
              <div className="w-1/2 text-xs md:text-sm lg:text-base font-semibold pl-2  md:pl-8">
                <div className="space-y-[2px] mb-2">
                  <p className="flex justify-between">
                    <span>TOTAL ITEMS</span>
                    <span>{totalItems}</span>
                  </p>
                  <p className="flex justify-between">
                    <span>SUB TOTAL</span>
                    <span>
                      {formatPrice(totalPrice)}
                      {/* {totalPrice} */}
                      {invoice?.user_info?.invoiceSetting?.currency_info?.code
                        ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                  <p className="flex justify-between">
                    <span>DISCOUNT</span>
                    <span>
                      {formatPrice(invoice.discount ? invoice.discount : 0)}
                      {/* {invoice.discount ? invoice.discount : 0} */}
                      {invoice?.user_info?.invoiceSetting?.currency_info?.code
                        ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                  <p className="flex justify-between">
                    <span>Vat</span>
                    <span>
                      {invoice.vat ? formatPrice(invoice.vat): 0.0000}
                      {/* {invoice.vat} */}
                      {invoice?.user_info?.invoiceSetting?.currency_info?.code
                        ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                  <p className="flex justify-between">
                    <span>Service Charge </span>
                    <span>
                      {invoice.tax ? formatPrice(invoice.tax) : 0.0}
                      {/* {invoice.tax} */}
                      {invoice?.user_info?.invoiceSetting?.currency_info?.code
                        ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                  <p className="flex justify-between">
                    <span>Delivery fees </span>
                    <span>
                      {invoice.delivery_fee ? formatPrice(invoice.delivery_fee) : 0.0}
                      {/* {invoice.delivery_fee} */}
                      {invoice?.user_info?.invoiceSetting?.currency_info?.code
                        ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                </div>
                <hr></hr>
                <div className="my-2">
                  <p className="flex justify-between">
                    <span>Grand Total</span>
                    <span>
                      {formatPrice(invoiceCalculate(invoice).grandTotal)}
                      {/* {invoice.sub_total} */}
                      {invoice?.user_info?.invoiceSetting?.currency_info?.code
                        ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                  <p className="flex justify-between">
                    <span>Paid Amount</span>
                    <span>
                      {/* {formatPrice(invoice.paid_amount)} */}
                      {formatPrice(invoice.paid_amount)}
                      {invoice?.user_info?.invoiceSetting?.currency_info?.code
                        ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                </div>
                <hr></hr>
                <p className="flex justify-between mt-2">
                  <span>Due Amount</span>
                  <span>
                    {Number.parseFloat(invoiceCalculate(invoice).dueAmount).toFixed(2)}
                    {/* {invoice.sub_total - invoice.paid_amount} */}
                    {invoice?.user_info?.invoiceSetting?.currency_info?.code
                      ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p>
              </div>
            </div>
            <p className="text-gray-900 mt-4 font-nunito text-sm md:text-base lg:text-lg font-semibold leading-normal text-center">
              <span className="text-[#7282A0]">In Word:</span>{" "}
              {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))} taka only
              {/* numberToWords(invoice.sub_total) taka only */}
            </p>
            <div
              style={{
                width: "100%",
                backgroundColor: "#034AFF",
                padding: "10px",
                textAlign: "center",
                margin: "10px auto",
              }}
            >
              <p className="text-white font-nunito text-14 font-bold">
                You saved {formatPrice(invoice.discount ? invoice.discount : 0)}
                {/* You saved {invoice.discount ? invoice.discount : 0} */}
                {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}{" "}
                in this purchase{" "}
              </p>
            </div>
            <div className="text-xs md:text-sm lg:text-base">
              <div className="flex justify-between">
                {/* <p className="invisible">
                  I dont know what or where this section is :(
                </p> */}
                {invoiceSetting?.sign_authority && (
                <div className="w-[120px] h-[120px] rounded-lg">
                  {/* <img
                    src={invoiceSetting.sign_authority}
                    className="object-cover"
                  /> */}
                </div>
              )}

                <div className="w-[120px] h-[120px] rounded-lg">
                  {/* <img
                    className="object-cover"
                    alt="invoice-setting-sign-authority"
                  /> */}
                </div>
              </div>
              <div className="flex justify-between m-2">
                <p>
                  <hr></hr> Customer Signature
                </p>
                <p>
                  <hr></hr> Seller Signature{" "}
                </p>
              </div>
            </div>
            <div className="text-center font-nunito mt-8 text-xs md:text-sm lg:text-base">
              <h2 className="text-orange-500  text-xl font-semibold ">
                Thanks for purchase
              </h2>
              <p className="text-black mt-3 text-xs">
                A Product of SQUAD INNOVATORS{" "}
              </p>
            </div>
          </div>
        </>
      }

      <div>
        {
          deliveryData.type_name == 'hand-to-hand' ?
            <>
            {<div style={{ display: 'none' }} >
              <PdfHtmlContent
                shop_logo={shop_logo}
                invoice={invoice}
                invoiceSetting={invoice?.user_info?.invoice_settings ? invoice?.user_info?.invoice_settings : null}
                id="pdfConentAreas"
              />
            </div>}
            {<div style={{ display: 'none' }}>
              <PdfHtmlFor3mm
                shop_logo={shop_logo}
                invoice={invoice}
                invoiceSetting={invoice?.user_info?.invoice_settings ? invoice?.user_info?.invoice_settings : null}
                id="pdfConentAreas3"
              />
            </div>}
            {<div style={{ display: 'none' }}>
              <PdfHtmlFor2mm
                shop_logo={shop_logo}
                invoice={invoice}
                invoiceSetting={invoice?.user_info?.invoice_settings ? invoice?.user_info?.invoice_settings : null}
                id="pdfConentAreas2"
              />
            </div>}
            </>
          :
          <>
            <div>
              <div  className='hidden'>
                <DeliveryAdcanceInvoice
                  shop_logo={shop_logo}
                  invoice={invoice}
                  invoiceSetting={invoice?.user_info?.invoice_settings ? invoice?.user_info?.invoice_settings : null}
                  deliveryData={deliveryData}
                  id="pdfConentAreas"
                />
              </div>

              <div className='hidden'>
                <DeliveryAdvanceInvoice3mm
                    shop_logo={shop_logo}
                    invoice={invoice}
                    invoiceSetting={invoice?.user_info?.invoice_settings ? invoice?.user_info?.invoice_settings : null}
                    id="pdfConentAreas3"
                    deliveryData={deliveryData}
                  />
                </div>
                <div className='hidden'>
                <DeliveryAdvanceInvoice2mm
                    shop_logo={shop_logo}
                    invoice={invoice}
                    invoiceSetting={invoice?.user_info?.invoice_settings ? invoice?.user_info?.invoice_settings : null}
                    id="pdfConentAreas2"
                    deliveryData={deliveryData}
                  />
                </div>
            </div>
          </>
        }
      </div>


       {/* new pdf and print buttons */}
       <div className='flex gap-12 justify-between text-xs md:text-sm lg:text-base mt-6'>
        {/* pdf buttons */}
        <div className={`flex-1 transform duration-300 ${dropdownPdf? "!h-[270px] md:!h-[300px]" : "h-[90px]"} space-y-2 relative`}>
          {/* pdf drop down button */}
          <button onClick={() => setDropdownPdf(!dropdownPdf)} className=" flex justify-center gap-1 md:gap-3 items-center bg-[#f1effc] text-[#034AFF] absolute z-10 !px-1 py-3 w-full rounded-xl font-bold shadow-md">
            <span><FaCloudDownloadAlt size={22}/></span>  Download PDF <div className={`transform duration-300 ${dropdownPdf ? "-rotate-180" : ""}`}> <FaChevronDown size={16} /></div>
          </button>
          {/* child buttons */}
          <button onClick={() => handleGeneratePdf('a4', 1)} className={`text-[#034AFF] font-semibold flex gap-1 md:gap-3  items-center justify-center ${dropdownPdf ? "translate-y-[3.7rem] " : "-translate-y-[0.6rem] opacity-0"} hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <FaCloudDownloadAlt size={22}/> <span>Download A4 PDF</span>
          </button>
          <button onClick={() => handleGeneratePdf('3mm', 2)} className={`text-[#034AFF] font-semibold flex gap-1 md:gap-3 items-center justify-center ${dropdownPdf ? "translate-y-[3.7rem] " : "-translate-y-[4.8rem] opacity-0"}  hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
          <FaCloudDownloadAlt size={22}/> <span>Download 3mm PDF</span>
          </button>
          <button onClick={() => handleGeneratePdf('2mm', 3)} className={` text-[#034AFF] font-semibold flex gap-1 md:gap-3 items-center justify-center ${dropdownPdf ? "translate-y-[3.7rem] " : "-translate-y-[8.4rem] opacity-0"} border hover:bg-[#034AFF] hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
          <FaCloudDownloadAlt size={22}/> <span>Download 2mm PDF</span>
          </button>
        </div>
        {/* print buttons */}
        <div className={`flex-1 space-y-2 transform duration-300 ${dropdownPdf || dropdownPrint ? "!h-[270px] md:!h-[300px]" : "h-[90px]"} relative`}>
          {/* print drop down button */}
          <button onClick={() => setDropdownPrint(!dropdownPrint)} className=" bg-[#f1effc] flex justify-center items-center gap-1 md:gap-3 text-[#034AFF] font-bold shadow-md absolute z-10 !px-1 py-3 w-full rounded-xl">
            <span><IoPrint size={22}/></span>  Print <div className={`transform duration-300 ${dropdownPrint ? "-rotate-180" : ""}`}> <FaChevronDown className='text-base md:text-lg' /></div>
          </button>
          {/* child buttons */}

          <button onClick={() => { handlePrint('pdfConentAreas') }} className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[0.6rem] opacity-0"} hover:text-[#eaeffb] hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300  py-3 w-full  rounded-xl`}>
          <IoPrint size={22}/> <span>Print A4</span>
          </button>

            <button onClick={() => { handlePrint('pdfConentAreas3') }} className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[4.8rem] opacity-0"}  hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
            <IoPrint size={22}/> <span>Print 3 inch</span>
            </button>


             <button onClick={() => { handlePrint('pdfConentAreas2') }} className={` flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${dropdownPrint ? "translate-y-[3.7rem] " : "-translate-y-[8.4rem] opacity-0"} border hover:bg-[#034AFF] hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}>
             <IoPrint size={22}/> <span>Print 2 inch</span>
            </button>

        </div>
      </div>
    </div>
  );
};



export default AdvanceBookingDetail;
