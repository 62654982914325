import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { formatNumber } from "../utils/Common";

const ExporterPaymentDescriptionForm = (props) => {
  const {
    formId,
    navigate,
    loadingCallback,
    setWarningMessage,
    setShowModal,
    updateModalTitle,
    exporterOrder
  } = props;
  const token = localStorage.getItem("token");
  const [grandTotalAfterRate, setGrandTotalAfterRate] = useState(0.00);



  const [paymentDetails, setPaymentDetails] = useState(null);
  const [exporterInfo, setExporterInfo] = useState(null);
  const [exporterProducts, setExporterProducts] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [currency, setCurrency] = useState(null);


  const getPaymentDetails = (id) => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}exporter-order-payment-details/${id}`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        loadingCallback(false)
        console.log("============");
        console.log(response);
        setPaymentDetails(response?.data?.data);
        setExporterInfo(response?.data?.data?.exporter_info[0]);
        setExporterProducts(response?.data?.data?.exporter_products);
        setTotalCost(response?.data?.data?.grand_total);
        setCurrency(response?.data?.data?.currencies);
        //setShowProductViewModal(!showProductViewModal)
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  useEffect(() => {
    //bdtRate
    if (currency && totalCost) {
      if (currency?.rate_in_base_currency==0) {
        setGrandTotalAfterRate((totalCost?.sum_total_product_price) * 1);
      } else {
        setGrandTotalAfterRate((totalCost?.sum_total_product_price) * currency.rate_in_base_currency);
      }
    }
  }, [totalCost,currency]);
  useEffect(() => {
    getPaymentDetails(exporterOrder)
  }, [exporterOrder]);

  const modalClose = () => setShowModal(false);
  return (
    <>
      <div className="font-lato font-bold text-[#16px] tex-[#000232] mb-3">
        Product Description
      </div>

      <div className="grid grid-cols-4 gap-x-2 gap-y-5 mb-4">
        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6]">Exporter Name</div>
          <div className="font-[400] text-[#000000]">{exporterInfo?.name}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6]">Email</div>
          <div className="font-[400] text-[#000000] break-words">{exporterInfo?.email}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6]">Phone</div>
          <div className="font-[400] text-[#000000]">{exporterInfo?.phone_no}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6]">Organization</div>
          <div className="font-[400] text-[#000000] break-words">{exporterInfo?.org_name}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6]">Total QTY</div>
          <div className="font-[400] text-[#000000]">{exporterInfo?.total_qty}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6]">Address</div>
          <div className="font-[400] text-[#000000] break-words">{exporterInfo?.address}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6]">Additional Phone</div>
          <div className="font-[400] text-[#000000]">{exporterInfo?.additional_phone_no}</div>
        </div>

        <div className="font-nunito text-[12px]">
          <div className="font-[700] text-[#6F8EB6]">Organization Email</div>
          <div className="font-[400] text-[#000000] break-words">{exporterInfo?.org_email}</div>
        </div>
      </div>

      <div className="mb-[0px]">
        <div className="flex flex-row gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
          <div className="py-[5px] flex-1">
            Product
            <br />
            Name
          </div>
          <div className="py-[5px] text-center flex-1">QTY</div>
          <div className="py-[5px] text-center flex-1">
            Received
            <br />
            QTY
          </div>
          <div className="py-[5px] text-center flex-1">
            Number
            <br />
            of Carrier
          </div>
          <div className="py-[5px] text-right w-[25%]">Total Price</div>
        </div>
        {exporterProducts ? exporterProducts?.map((product, index) => (
          <div className="flex flex-row gap-2 font-nunito font-[500] text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
            <div className="py-[5px] flex-1">{product?.product_name}</div>
            <div className="py-[5px] text-center flex-1 ">{product?.qty}</div>
            <div className="py-[5px] text-center flex-1 ">{product?.received_qty}</div>
            <div className="py-[5px] text-center flex-1 ">{product?.no_of_carrier}</div>
            <div className="py-[5px] text-center flex-1">{product?.total_product_price}</div>
          </div>
        )) : null}

      </div>

      <div className="flex flex-row gap-2 font-nunito font-bold text-[14px] text-[#000232] py-[5px] border-b-[1px] border-solid border-[#E7EBF4]">
        Price
      </div>

      <div className="font-nunito font-[500] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
        <div className="flex flex-row justify-between ">
          <div className="py-[5px]">Product Price</div>
          <div className="py-[5px] text-right">{totalCost?.sum_total_product_price} {currency?.purchase_currency}</div>
        </div>
      </div>

      {/* <div className="font-nunito font-[500] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
        <div className="flex flex-row gap-2 justify-between ">
          <div className="py-[5px]">Carrier Charge</div>
          <div className="py-[5px] text-right">{totalCost?.carrier_charge} BDT</div>
        </div>
      </div> */}

      <div className="font-nunito font-[600] text-[14px] overflow-hidden mt-[10px] mb-[25px]">
        <div className="float-right w-[70%]">
          <div className="flex flex-row gap-5 justify-between">
            <div className="py-[5px]">Grand Total</div>
            <div className="py-[5px]">{totalCost?.sum_total_product_price } {currency?.purchase_currency}</div>
          </div>

          <div className="flex flex-row gap-2 justify-between">
            <div className="py-[5px]">BDT Rate</div>
            <div className="py-[5px]">{currency?.rate_in_base_currency} BDT</div>
          </div>

          <div className="flex flex-row gap-2 justify-between">
            <div className="py-[5px]">Grand Total in BDT</div>
            <div className="py-[5px]">{formatNumber(grandTotalAfterRate) } {currency?.base_currency}</div>
          </div>

          <div className="flex flex-row gap-2 justify-between border-b-[1px] border-solid border-[#E7EBF4]">
            <div className="py-[5px]">Paid Amount</div>
            <div className="py-[5px]">{totalCost?.paid_amount } BDT</div>
          </div>

          <div className="flex flex-row gap-2 justify-between">
            <div className="py-[5px]">Due Amount</div>
            <div className="py-[5px]">{totalCost?.due_amount } BDT</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExporterPaymentDescriptionForm;
