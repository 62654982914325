import React, { useContext, useState, useRef, useEffect } from "react";
import classNames from "classnames";
import axios from "axios";
import { useFlashMessage } from "../context/FlashMessageContext";
//import Select2 from "react-chosen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import FlagIcon from "./FlagIcon";
import DialCodes from "../data/dialCodes.json";
import SelectBox from "./Select";
import Input from "./Input";
import { get, post } from "../services/apiService";
import { AppContext } from '../context/context';

const ExporterSearchProduct = (props) => {
  const {
    formId,
    loadingCallback,
    navigate,
    setOpen,
    setWarningMessage,
    setShowNestedModal,
    setNestedModalData,
    setShowModal,
    setExporterProduct,
    updateFilteredProduct,
    filteredProduct,
    exporterId,
    exporterOrder,
    setAddedProduct,
    addedProduct
  } = props;

  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedExporterProduct, setSelectedExporterProduct] = useState(null);
  const [showNewCustomerForm, setShowNewCustomerForm] = useState(false);


  const [formData, setFormData] = useState({
    name: "",
    category_id: 0,
    sub_category_id: 0,
    brand_id: 0,
    qty: 0,
    booking_date: "",
  });

  const perPage = 100;
  const token = localStorage.getItem("token");
  const getProductListData = async (searchKeyword = "") => {
    loadingCallback(true);
    const page1 = 1;
    if (searchKeyword === "") {
      const options = [];
      axios
        .get(`${process.env.REACT_APP_API_URL}product?limit=${perPage}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          loadingCallback(false);
          console.log(response);
          if (response.status === 200) {
            const customers = response?.data?.data?.data
              ? response.data.data.data
              : response.data.data;
            // Append other options if there are customers
            if (customers.length > 0) {
              const customerOptions = customers.map((item) => ({
                id: item.id,
                name: item.name,
                brand_id: item?.brand_id,
                category_id: item?.category_id,
                sub_category_id: item?.sub_category_id,
                barcode: item.barcode,
                image_path: item?.image_path ? item?.image_path :null,
                image_path1: item?.image_path1 ? item?.image_path1 :null,
                image_path2: item?.image_path2 ? item?.image_path2 :null,
                image_path3: item?.image_path3 ? item?.image_path3 :null,
              }));
              options.push(...customerOptions);
            }
          } else {
            console.log("Data Not Found!");
          }
          updateFilteredProduct(options);
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    } else {
      updateFilteredProduct([]);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}search-product-v1?page=${page1}&limit=${perPage}`,
          { keyword: searchKeyword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          loadingCallback(false);
          const options = [];
          if (response.status === 200) {
            //setfilteredExporter(response.data.data.data);
            const customers = response?.data?.data?.data
              ? response.data.data.data
              : response.data.data;


            // Append other options if there are customers
            if (customers.length > 0) {
              const customerOptions = customers.map((item) => ({
                id: item.id,
                name: item.name,
                brand_id: item?.brand_id,
                category_id: item?.category_id,
                sub_category_id: item?.sub_category_id,
                barcode: item.barcode,
                image_path: item?.image_path ? item?.image_path :null,
                image_path1: item?.image_path1 ? item?.image_path1 :null,
                image_path2: item?.image_path2 ? item?.image_path2 :null,
                image_path3: item?.image_path3 ? item?.image_path3 :null,
              }));
              options.push(...customerOptions);
            }
          } else {
            console.log("Data Not Found!");
            const customerOptions = [];
            options.push(customerOptions);
          }
          updateFilteredProduct(options);
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem("token", false);
            // Clear session and local storage
            localStorage.setItem("isPinVerify", false);

            navigate("/login");
          }
        });
    }
  };
  useEffect(() => {
    getProductListData(searchKeyword);
  }, [searchKeyword]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData1 = new FormData(event.target);
    const formObject = {};

    formData1.forEach((value, key) => {
      formObject[key] = value;
    });
    if (formObject.product_id) {
      const product_id = parseInt(formData.product_id);
      const selectedProduct = filteredProduct.find(
        (customer) => customer.id === product_id
      );
      setSelectedExporterProduct(selectedProduct);

      if (selectedProduct) {
        setNestedModalData({
          title: "Add Product Info",
          type: "exporter_add_product_info",
          setData: setSelectedExporterProduct,
          setAddedProduct: setAddedProduct,
          exporterProduct: selectedProduct,
          product_id: product_id,
          exporterOrder: exporterOrder
        });
        setShowNestedModal(true);
      }
      setShowNewCustomerForm(false);
    } else {
      console.error("Form validation failed.");
    }

  };
  const handleInputChange = (inputValue) => {
    if (inputValue !== "") {
      setSearchKeyword(inputValue);
    }
  };


  const handleSelectChange = (name, option) => {
    const { value, label } = option;
    if (value === "") {
      setSelectedExporterProduct(null);
      return;
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (addedProduct) {
      setShowModal(false);
    }
  }, [addedProduct]);


  const handleShowAddNewExporterModal = () => {
    setNestedModalData({
      title: "Add Product Info",
      type: "exporter_add_product_info",
      setData: setSelectedExporterProduct,
      setAddedProduct: setAddedProduct,
      //exporterProduct: selectedExporterProduct,
      exporterId: exporterId,
      exporterOrder: exporterOrder
      //exporterProduct: selectedExporterProduct,
    });
    setShowNestedModal(true);
  };




  return (
    <form
      method="get"
      className="col-sm-12 min-h-[30vh]"
      data-autosubmit="false"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-row gap-3 mt-3 mb-6">
        <div className="w-[90%]">
          <SelectBox
            name="product_id"
            options={filteredProduct}
            value={formData?.product_id ? formData?.product_id : ""}
            //onChange={handleCustomerSelect}
            transparentBorder={false}
            onInputChangeData={handleInputChange}
            onChange={handleSelectChange.bind(this, "product_id")}
            placeholder={"Search Product"}
            className={classNames("w-full", "rounded-[15px]", "bg-white")}
          />
        </div>
        <label htmlFor="nested_modal" onClick={handleShowAddNewExporterModal}>
          <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-3 py-2 rounded-[10px] cursor-pointer"></i>
        </label>
      </div>

      <div className="pt-6">
        <input
          type="submit"
          value="Next"
          className="btn btn-primary w-full text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF]"
        />
      </div>
    </form>
  );
};

export default ExporterSearchProduct;
