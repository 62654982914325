import React, { useEffect, useState } from 'react';

const ImeiReportHistory = ({item,onSelect,index}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelect(index,item)
  }
  const [invoiceToggle, setInvoiceToggle] = useState(false);
  useEffect(()=>{

  },[invoiceToggle])

  return (
                  <div className="grid grid-cols-[70px_70px_repeat(9,_1fr)]  md:gap-2 gap-2 items-center justify-start font-nunito font-[500] text-[12px] text-[#000232] border-t-[1px] border-solid border-[#E7EBF4] py-[8px]">
        {/* <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start"> */}
                  <input
                  type='checkbox'
                  onChange={handleCheckboxChange}
                  checked={item?.completed}
                  className='text-start justify-start w-fit'/>
                  <div className="font-[500] text-[#000232] text-center">#{index+1}</div>

                    <div>
                      <p className='text-start font-[700] text-[#87889E]'>{item?.date}</p>
                    </div>

                    <div>
                      <p className='text-center'>{item?.brand_name}</p>
                    </div>
                    <div >
                      <p className='text-start'>{item?.product_name}</p>
                    </div>
                    <div >
                      <p className='text-start'>{item?.imei}</p>
                    </div>
                    <div >
                      <p className='text-center' onClick={()=>{setInvoiceToggle(true)}}>{invoiceToggle ? item.sale_invoice || item.purchase_invoice : item.purchase_invoice || "N/A" }</p>
                    </div>
                    <div >
                      <p className='text-center'>{item?.purchase_price}</p>
                    </div>
                    <div >
                      <p className='text-center'>{item?.sale_price}</p>
                    </div>
                    <div >
                      <p className='text-center'>{ item?.vendor_name}</p>
                    </div>
                    <div >
                      <p className='text-center'>{item?.customer_name}</p>
                    </div>

                  </div>
  );
};

export default ImeiReportHistory;