import React, { useState } from 'react';
import axios from 'axios';


const ExporterProductViewForm = (props) => {

  const {
    formId,
    navigate,
    loadingCallback,
    setWarningMessage,
    setShowModal,
    productData: initialProductData,
    baseCurrency,
  } = props;

  const [productData, setProductData] = useState(initialProductData);

  const token = localStorage.getItem("token");

  const modalClose = () => setShowModal(false);

  const [editingIndex, setEditingIndex] = useState(null);
  const [editedImei, setEditedImei] = useState('');


  const handleEditClick = (index, imei) => {
    setEditingIndex(index);
    setEditedImei(imei);
  };

  const handleInputChange = (e) => {
    setEditedImei(e.target.value);
  };

  const handleSubmit = async (id) => {
    try {
      loadingCallback(true); // Start loading
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}update-product-imei/${id}`,
        {
          imei: editedImei,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log('IMEI updated:', response.data);

      // Update the productData state with the new IMEI
      const updatedProductImeis = productData.product_imeis.map((imei, index) =>
        index === editingIndex ? { ...imei, imei: editedImei } : imei
      );

      setProductData({ ...productData, product_imeis: updatedProductImeis });

      // After successful update, reset editing state
      setEditingIndex(null);
      loadingCallback(false);


    } catch (error) {
      console.error('Error updating IMEI:', error);
    }
  };

  return (
    <>
      <div className='font-nunito font-bold text-[#16px] tex-[#000232] mb-2'>Product Description</div>
      <div className='grid grid-cols-3 gap-3 mb-4'>
        <div className='font-nunito text-[12px]'>
          <div className='font-[700] text-[#6F8EB6]'>Product Name</div>
          <div className='font-[400] text-[#000000]'>{productData?.product_name}</div>
        </div>

        <div className='font-nunito text-[12px]'>
          <div className='font-[700] text-[#6F8EB6]'>Booking Date</div>
          <div className='font-[400] text-[#000000]'>{productData?.booking_date}</div>
        </div>

        {/* <div className='font-nunito text-[12px]'>
          <div className='font-[700] text-[#6F8EB6]'>Carrier Name</div>
          <div className='font-[400] text-[#000000]'></div>
        </div> */}

        <div className='font-nunito text-[12px]'>
          <div className='font-[700] text-[#6F8EB6]'>QTY</div>
          <div className='font-[400] text-[#000000]'>{productData?.qty}</div>
        </div>

        {/* <div className='font-nunito text-[12px]'>
          <div className='font-[700] text-[#6F8EB6]'>Email</div>
          <div className='font-[400] text-[#000000]'></div>
        </div>

        <div className='font-nunito text-[12px]'>
          <div className='font-[700] text-[#6F8EB6]'>Phone Number</div>
          <div className='font-[400] text-[#000000]'></div>
        </div> */}
      </div>

      <div className='rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[15px] mb-[20px]'>
        <div className='font-lato font-[700] text-[12px] text-[#000232] mb-2'>List of IMEI</div>

        <div className='flex flex-row gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]'>
          <div className='py-[5px] flex-1'>SL<br />No</div>
          <div className='py-[5px] text-center flex-1'>Condition</div>
          <div className='py-[5px] text-center flex-1'>Ram</div>
          <div className='py-[5px] text-center flex-1'>Storage</div>
          <div className='py-[5px] text-right w-[30%]'>IMEI<br />Number</div>
        </div>
        {productData?.product_imeis ? productData?.product_imeis.map((imei, index) => (
        <div key={index} className='flex flex-row gap-2 font-nunito font-[400] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]'>
          <div className='py-[5px] flex-1'>{index + 1}</div>
          <div className='py-[5px] text-center flex-1'>{productData?.condition}</div>
          <div className='py-[5px] text-center flex-1'>{productData?.ram}</div>
          <div className='py-[5px] text-center flex-1'>{productData?.storage}</div>
          <div className='py-[5px] text-right w-[30%]'>
            {editingIndex === index ? (
              <input
                type='text'
                value={editedImei}
                onChange={handleInputChange}
                className='w-full p-1 border border-gray-300 rounded'
              />
            ) : (
              imei?.imei
            )}
          </div>
          <div className='py-[5px] text-right w-[5%]'>
            {editingIndex === index ? (
              <button onClick={() => handleSubmit(imei?.id)} className='text-blue-500'>Submit</button>
            ) : (
              <i
                className='fa-regular fa-pen-to-square cursor-pointer'
                onClick={() => handleEditClick(index, imei?.imei)}
              ></i>
            )}
          </div>
        </div>
      )) : null}

      </div>

      <div className="flex flex-row gap-3">
        <input
          onClick={modalClose}
          type="button"
          value="Back"
          className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
        />
        <input
          onClick={modalClose}
          type="button"
          value="Done"
          className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
        />
      </div>
    </>
  )
}

export default ExporterProductViewForm