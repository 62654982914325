
import React, { useContext, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import Resizer from "react-image-file-resizer";
import { validateData } from '../utils/Common';
import { useFlashMessage } from '../context/FlashMessageContext';
import ImageUploader2 from './ImageUploader2';
import { FlashMessage } from '../utils/FlashMessage';
//import arrayMutators from 'final-form-arrays';

const InvoiceSettingForm = props => {
  const {setUploadedFiles, setUploadedFilesWatermark, uploadDetails, setUploadDetails} = props;
  const { setErrorFlashMessage } = useFlashMessage();
  const [isValid, setIsValid] = useState(true);
  const [fileLogo, setFile] = useState(null);
  const [watermarkFile, setWatermarkFile] = useState(props.initialValues?.invoiceSetting?.watermark_type=='Logo' ? props.initialValues.invoiceSetting.watermark_text : null);


  const [selectedValue, setSelectedValue] = useState(props.initialValues.invoiceSetting.watermark_type ? props.initialValues.invoiceSetting.watermark_type : 'text');

  const handleSelectChange = (event) => {
    event.preventDefault();

    setSelectedValue(event.target.value);
  };


  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const handleImageChange = async (files, name) => {
    let tempUploadDetails = [...uploadDetails];
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        // setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        FlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,'error');
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob], files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
        file: file,
        input_name: name,
      });
    }
    setFile(URL.createObjectURL(files[0]));
    setUploadedFiles(files);

    setUploadDetails(tempUploadDetails);
  };
  const handleWatermarkImageChange = async (files, name) => {
    let tempUploadDetails = [...uploadDetails];
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        // setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        FlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,'error');
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob], files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
        file: file,
        input_name: name,
      });
    }
    setWatermarkFile(URL.createObjectURL(files[0]));
    setUploadedFilesWatermark(files);

    setUploadDetails(tempUploadDetails);
  };



  return (<>
    <FinalForm
      {...props}
      initialValues={props.initialValues.invoiceSetting}
      onSubmit={props.onSubmit}
      validate={(values) => ({
        shop_name: validateData(values.shop_name, 'Shop Name'),
        shop_address: validateData(values.shop_address, 'Shop address'),
        sign_authority: validateData(values.sign_authority, 'Sign of Authority'),
        name_authority: validateData(values.name_authority, 'Name Authority'),
        watermark_text: validateData(values.watermark_text, 'Text Watermark'),
      })}
      render={formRenderProps => {
        const {
          handleSubmit,
          handleChange,
          file,
          currency
        } = formRenderProps;
        return (
          <>
            <form
              method="post"
              className="col-sm-12"
              data-autosubmit="false"
              autoComplete="off"
              onSubmit={e => {
                e.preventDefault();
                handleSubmit(e);
              }}
              encType="multipart/form-data"
            >
              <Field name="shop_name">
                {({ input, meta }) => (
                  <>
                    <input
                      {...input}
                      type="text"
                      placeholder="Shop Name"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {input.value && input.value === '' && (
                      <div className="warning-message">
                        This field cannot be empty.
                      </div>
                    )}
                    {meta.touched && meta.error && (
                      <div className="warning-message">
                        {meta.error}
                      </div>
                    )}
                  </>
                )}
              </Field>

              <div className="flex justify-between flex-col border border-opacity-[0.2px] w-full mb-[15px] rounded-2xl">
                <h4 className="pl-4 py-3">Shop Logo</h4>
                <Field name="file-input">
                  {({ input }) => (<>
                    <ImageUploader2
                      name={"shop_logo"}
                      isBig={true}
                      //onChange={(files) => handleImageChange("shop_logo", files)}
                      onChange={handleImageChange}
                      image={fileLogo ? fileLogo : props.initialValues?.invoiceSetting?.shop_logo}
                    />
                    {/* {file ? (
                      <img src={file} className="mt-2 rounded-lg object-contain w-[full] h-[full] mx-auto" alt="Shop Logo" />
                    ): null} */}

                  </>
                  )}
                </Field>
              </div>
              {/* Other Fields using Field component */}
              <Field name="shop_address">
                {({ input, meta }) => (
                  <>
                    <input
                      {...input}
                      type="text"
                      placeholder="Shop Address"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {meta.touched && meta.error && (
                      <div className="warning-message">
                        {meta.error}
                      </div>
                    )}
                  </>
                )}
              </Field>
              <Field name="sign_authority">
                {({ input, meta }) => (
                  <>
                    <input
                      {...input}
                      type="text"
                      placeholder="Sign of Authority"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {meta.touched && meta.error && (
                      <div className="warning-message">
                        {meta.error}
                      </div>
                    )}
                  </>
                )}
              </Field>
              <Field name="name_authority">
                {({ input, meta }) => (
                  <>
                    <input
                      {...input}
                      type="text"
                      placeholder="Name Authority"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />
                    {meta.touched && meta.error && (
                      <div className="warning-message">
                        {meta.error}
                      </div>
                    )}

                  </>
                )}
              </Field>
              <Field name="vat">
                {({ input, meta }) => (
                  <>
                    <input
                      {...input}
                      type="text"
                      placeholder="Vat"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />


                  </>
                )}
              </Field>
              <Field name="tax">
                {({ input, meta }) => (
                  <>
                    <input
                      {...input}
                      type="text"
                      placeholder="Tax"
                      className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                    />

                  </>
                )}
              </Field>
              {/* Select Field */}
              <Field name="currency_id" component="select" className="select select-bordered w-full rounded-2xl mb-[15px] bg-white">
                <option disabled value="">
                  Select Currency
                </option>
                {currency.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Field>
              <p className='font-semibold m-2'>Select Watermark type</p>
              <div className="flex-none m-[10px] w-[full] bg-transparent p-2 grid-cols-1fr font-nunito text-lg text-center border border-black-700 text-gray-900 rounded-[16px]">
                <Field
                  name="watermark_type"
                  component="select"
                  onChange={handleSelectChange}
                  initialValue={selectedValue}
                  className="select select-bordered w-full rounded-2xl mb-[15px] bg-white">
                  <option disabled value="">
                    Select Watermark Type
                  </option>
                  <option className="text-[14px]" value="text">
                    Watermark Text
                  </option>
                  <option className="text-[14px]" value="Logo">
                    Watermark Image
                  </option>
                </Field>
              </div>

              <Field name="watermark_text">
                {({ input, meta }) => (
                  <>
                    {selectedValue !== 'Logo' && (
                      <div id="inputText">
                        <input
                          {...input}
                          type="text"
                          placeholder="Text Watermark"
                          className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                        />
                        {meta.touched && meta.error && (
                          <div className="warning-message">
                            {meta.error}
                          </div>

                        )}
                      </div>
                    )}

                  </>
                )}
              </Field>
              {selectedValue === 'Logo' && (
                <div id="imageUploader2" className="mb-3">
                  <ImageUploader2
                    name={"watermark_text"}
                    isBig={true}
                    //onChange={(files) => handleWatermarkImageChange("image_path", files)}
                    onChange={handleWatermarkImageChange}
                    image={watermarkFile ? watermarkFile : null}
                  />
                </div>
              )}

              <div className="button-disabled mt-2">
                <button
                  htmlFor="modal_Invoice"
                  type="submit"
                  className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                >
                  {''}
                  Save{''}
                </button>
              </div>
            </form>
          </>
        );
      }}
    />
  </>)
}



export default InvoiceSettingForm;