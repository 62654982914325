import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newSalesBillingActions } from "../../../../app/newSalesBilling";

const DeliveryMethod = ({
  deliveryList,
  setDeliveryFormData,
  setDeliveryAmount,
  deliveryAmount,
  setShowPaymentRightDiv,
}) => {

  console.log(deliveryList)

  const posDeliveryFormData = useSelector(state => state.newSalesBilling.posDeliveryFormData);
  console.log(posDeliveryFormData)
  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [showForm, setShowForm] = useState(false);

  const handleSelectCompany = (deliveryId, deliveryName,companyId, companyName) => {
    setSelectedCompany(deliveryName);
    setShowForm(true);
    dispatch(newSalesBillingActions.setPosDeliveryFormData({
      ...posDeliveryFormData,
      deliveryId: deliveryId,
      deliveryName: deliveryName,
      companyId,
      companyName
    }))

    // setDeliveryFormData((prevData) => ({
    //   ...prevData,
    //   companyName: companyName,
    //   companyId: companyId,
    // }));
  };

  const newSalesBillingState = useSelector((state) => state.newSalesBilling)

  console.log(newSalesBillingState.posDeliveryFormData)

  const handleConfirm = () => {

    dispatch(newSalesBillingActions.setPosDeliveryFormData(
     { ...posDeliveryFormData,
      customerName: customerName,
      customerAddress: customerAddress,
      customerPhone: customerPhone,
      deliveryFee: deliveryAmount,}
    ))


    // setDeliveryFormData((prevData) => ({
    //   ...prevData,
    //   customerName: customerName,
    //   customerAddress: customerAddress,
    //   customerPhone: customerPhone,
    //   deliveryFee: deliveryAmount,
    // }));

    setShowPaymentRightDiv(true);
  };

  return (
    <div className="w-full bg-white p-4 rounded-xl shadow-sm md:w-[35%]">
      <div className="w-full max-w-md">
        <h2 className="text-2xl font-semibold text-center mb-6">
          Delivery Method
        </h2>

        {/* Delivery Method Icons Grid */}
        <div className="grid grid-cols-4 gap-4 mb-6">
          {deliveryList.map((deliveryCompany, index) => (
            <div
              key={deliveryCompany?.id}
              onClick={() =>{
                const companyId = deliveryCompany?.delivery_infos?.length ? deliveryCompany.delivery_infos[0].id : "";
                const companyName = deliveryCompany?.delivery_infos?.length ? deliveryCompany.delivery_infos[0].company_name : "";
                handleSelectCompany(
                  deliveryCompany?.id,
                  deliveryCompany?.type_name,
                  companyId,
                  companyName
                )}
              }
              className={`flex justify-center items-center px-4 py-3 border rounded-[12px]  hover:shadow-md hover:transition-shadow duration-200 cursor-pointer
              ${
                selectedCompany === deliveryCompany?.type_name
                  ? "outline outline-[#407BFF] "
                  : "border-blue-500"
              }`}
            >
              <span className="text-2xl font-bold">
                {deliveryCompany?.icon_letter.toUpperCase()}
              </span>
            </div>
          ))}
        </div>

        {showForm && selectedCompany && (
          <div className="mb-6">
            {/* Company (read-only) */}
            <div className="relative mb-4">
              <label className="absolute -top-3 left-2 px-1 bg-white text-gray-700 font-semibold z-10">
                Company
              </label>
              <input
                type="text"
                value={selectedCompany}
                readOnly
                className="w-full p-3 border border-gray-300 rounded-lg text-gray-700  z-0"
              />
            </div>

            {/* Customer Info */}
            <div className="relative mb-4">
              <label className="absolute -top-3 left-2 px-1 bg-white text-gray-700 font-semibold z-10">
                Name
              </label>
              <input
                type="text"
                placeholder="Abdullah Al Masum"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0"
              />
            </div>

            <div className="relative mb-4">
              <label className="absolute -top-3 left-2 px-1 bg-white text-gray-700 font-semibold z-10">
                Address
              </label>
              <input
                type="text"
                placeholder="Main Road, Bloc C, Aftab Nagore"
                value={customerAddress}
                onChange={(e) => setCustomerAddress(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0"
              />
            </div>

            <div className="relative mb-4">
              <label className="absolute -top-3 left-2 px-1 bg-white text-gray-700 font-semibold z-10">
                Phone
              </label>
              <div className="flex items-center border border-gray-300 rounded-lg">
                <input
                  type="text"
                  placeholder="1896-379528"
                  value={customerPhone}
                  onChange={(e) => setCustomerPhone(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0"
                />
              </div>
            </div>

            <div className="relative mb-4">
              <label className="absolute -top-3 left-2 px-1 bg-white text-gray-700 font-semibold z-10">
                Delivery Fee
              </label>
              <input
                type="text"
                value={deliveryAmount}
                onChange={(e) => setDeliveryAmount(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0"
              />
            </div>
          </div>
        )}

        {/* Buttons */}
        <div className="flex justify-between">
          <button className="px-6 py-2 border border-blue-500 text-blue-500 rounded-[10px] hover:bg-blue-500 hover:text-white transition-colors">
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className={`px-6 py-2 rounded-[10px] ${
              selectedCompany
                ? "bg-[#407BFF] text-white"
                : "bg-gray-200 text-gray-500"
            }`}
            disabled={!selectedCompany}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryMethod;
