import React, { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { timeDiff,formatPrice } from '../../utils/Common';
import Pagination from '../../utils/pagination'; // Adjust the path accordingly
import { AppContext } from '../../context/context.js';
import DatePicker from "react-datepicker";
import ContentLayout from '../../layout/ContentLayout';
import { get, post } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import CustomerListItem from '../../components/Invoice/CustomerListItem.js';
import { useFlashMessage } from '../../context/FlashMessageContext';
import { BiPlus } from "react-icons/bi";
import { Modal } from "../../utils/Modal";
import ExpenseCategoryItem from "../../components/Expense/ExpenseCategoryItem.js";
import DesignationItem from "../../components/HRM/DesignationItem.js";
import { useLocation } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_ROOT_URL;

const DesignationPage = () => {
  const { flashMessage, getFlashMessageStyle } = useFlashMessage();
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const [refetch, setRefetch] = useState(false)
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [warningMessage, setWarningMessage] = useState(false);

  const [designationList, setDesignationList] = useState([]);
  const [showAddDesignationModal, setShowAddDesignationModal] = useState(false);
  const [formData, setFormData] = useState({});

  //---------
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const [searchFilter, setSearchFilter] = useState({
    keyword: "",
  })
  const { state, isLoading, updateDesignationList } = useContext(AppContext);
  //------

  //----------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [isViewActive, setIsViewActive] = useState(false);
  const [isCreateActive, setIsCreateActive] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);

  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");
    if (!employeeApiResponse) {
      setHasPermission(true);
      setIsCreateActive(true);
      setIsEditActive(true);
      return;
    }
      const employeeData = JSON.parse(employeeApiResponse);
      const hrmFeature = employeeData.role.features.find(
        (feature) => feature.name === "HRM" && feature.status === 1
      );
      if (hrmFeature && location.pathname === "/designation") {
        const viewHrmOptionActive = hrmFeature.feature_options.find(
          (option) => option.name === "View" && option.status === 1
        );
        const createHrmOptionActive = hrmFeature.feature_options.find(
          (option) => option.name === "Create" && option.status === 1
        );
        const editHrmOptionActive = hrmFeature.feature_options.find(
          (option) => option.name === "Edit" && option.status === 1
        );
        if (viewHrmOptionActive) {
          setIsViewActive(true);
          setHasPermission(true);
        } else {
          setIsViewActive(false);
          setHasPermission(false);
        }
        setIsCreateActive(!!createHrmOptionActive);
        setIsEditActive(!!editHrmOptionActive);
      } else {
        setHasPermission(false);
        setIsCreateActive(false);
        setIsEditActive(false);
        navigate("/dashboard", { replace: true });
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Permission Denied",
            text: "You do not have permission for this feature.",
          });
        }, 100);
      }
    }
  , []);
  //-----------------

  useEffect(()=>{
    if ((isViewActive && hasPermission) || !localStorage.getItem("employee")) {
      getDesignationList(currentPage)
    }
  },[currentPage, searchFilter,isViewActive,hasPermission])

  const getDesignationList = (page, searchKeyword) => {
    if (searchFilter.keyword !== "") {
      const data = {
        keyword: searchFilter.keyword,
      }
      loadingCallback(true);
      post(`search-designation?page=${page}&limit=${perPage}`, data, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            const listData = response.data.data;
            setDesignationList(listData)
            console.log("designation names after search", listData)

            const transformedEmployeeArray = listData.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateDesignationList(transformedEmployeeArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        });
    } else{
      loadingCallback(true);
      get(`designation?page=${page}&limit=${perPage}`, token)
        .then((response) => {
          loadingCallback(false);
          if (response.success) {
            console.log("designation list before search", response.data.data)
            const listData= response.data.data
            setDesignationList(listData)
            const transformedEmployeeArray = listData.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateDesignationList(transformedEmployeeArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log( error);
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        });
    }
  };

  const handleCreateClick = () => {
    if (isCreateActive) {
      // Show modal if creation is allowed
      setShowAddDesignationModal((val) => !val);
    }
  };

  return (
    <>
      <ContentLayout>
      <div className="main-content recentTableContent">
        <div className="flex flex-col w-full">
              <div className='flex flex-col md:flex-row justify-between mb-4'>
                <div>
                  <h1 className='text-[#000232] text-[22px] font-[600] font-lato mb-2'>Designation List</h1>
                </div>
                <div className='flex gap-2 justify-center'>
                  <div className="flex relative  w-[95%]">
                    <input
                      autoFocus
                      type="text"
                      className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                      placeholder="Search Designation"
                      onChange={(e) => {
                        setSearchFilter({...searchFilter, keyword: e.target.value})
                      }}
                    />
                    <div className="absolute top-[37%] left-[10px]">
                      <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                    </div>
                  </div>
                  <button
                    htmlFor="add_designation_modal"
                    onClick={handleCreateClick}
                    //onClick={() => setShowAddDesignationModal((val) => !val)}
                    className={`cursor-pointer bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 pl-[22px] pr-[24px] flex items-center gap-2 ${
                      !isCreateActive && "opacity-50 cursor-not-allowed"
                    }`}
                    style={{ pointerEvents: isCreateActive ? "auto" : "none" }}
                  >
                    <BiPlus className="text-xl "></BiPlus>
                  </button>
                </div>
              </div>
        </div>
        {(isViewActive && hasPermission) || !localStorage.getItem("employee") ? (
          <>
            <div className="table-conatiner">
                  <div className="grid grid-cols-3 gap-2 items-center font-nunito font-[700] text-[14px] text-[#000232]">
                    <div className="py-[8px]">SL No</div>
                    <div className="py-[8px] text-center">Name</div>
                    <div className="py-[8px] text-right">Action</div>
                  </div>
            </div>
            {flashMessage && (
                    <div
                      className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
                        flashMessage.type
                      )}`}
                    >
                      {flashMessage.text}
                    </div>
              )}
              {loading && (
                    <div className="custom-loader-logo">
                      <img
                        className=""
                        src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                        alt="logo"
                      />
                    </div>
              )}
            <div className="max-h-[70vh] overflow-y-auto">
                {designationList
                      ? designationList.map((item, index) => (
                        <DesignationItem
                          key={item.id}
                          index={index}
                          loadingCallback={loadingCallback}
                          navigate={navigate}
                          designationitem={item}
                          loading={loading}
                          isEditActive={isEditActive}
                        />
                        ))
                      : null}


                  <div className="hrtable"></div>
            </div>
          </>
        ) : null}
      </div>

      {showAddDesignationModal ? (
          <Modal
          loadingCallback={loadingCallback}
          navigate={navigate}
          isOpen={showAddDesignationModal}
          setOpen={setShowAddDesignationModal}
          title="Add Designation"
          formId="add_designation_modal"
          type="addDesignationModal"
          selectType="designation"
          refetch={()=>setRefetch(val=>!val)}
          formData={formData}
          setFormData={setFormData}
          setWarningMessage={setWarningMessage}
          />
        ) : (
          ""
        )}

      </ContentLayout>
    </>
  );
};

export default DesignationPage;
