import React, { useContext, useState, useRef, useEffect } from "react";
import Breadcrumb from '../Settings_components/Breadcrumb';
import Table from '../Settings_components/Table';
import { AppContext } from '../../../context/context.js';
import Pagination from '../../../utils/pagination';

//import { brands } from '../../../data/data';

import axios from "axios";

const BranchList = () => {
    const {
        state,
        isLoading,
        updateBranchList
    } = useContext(AppContext);
    const token = localStorage.getItem("token");
    const [currentPage, setCurrentPage] = useState(1);
    const [tatalBranchList, setTotalBranchList] = useState([]);
    const perPage = 20;

    console.log("saz",state)
    useEffect(() => {
        getBranch(currentPage);
    }, [currentPage]);
    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const [loader, setLoader] = useState(true);

    const getBranch = (page) => {

        axios
        .get(
            `${process.env.REACT_APP_API_URL}branch?page=${page}&limit=${perPage}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        )
        .then((response) => {
            if(response.data.success)
            {
                setLoader(false)
                setTotalBranchList(response.data.data);
                updateBranchList(response.data.data.data)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    const shouldRenderPagination = tatalBranchList.total > perPage;


    return (
        <div className='rounded-[27px]'  style={{backgroundColor: 'rgba(0, 184, 148, 0.15)' }}>
            <Breadcrumb></Breadcrumb>
           <Table data={state?.branchList} type="branchList" getBranch={getBranch}></Table>
           {shouldRenderPagination && (
                <Pagination
                    currentPage={currentPage}
                    lastPage={tatalBranchList?.last_page}
                    onPageChange={onPageChange}
                />
            )}
            <div className="w-full relative px-10 md:px-[27px] overflow-x-auto sm:rounded-lg">
                {loader && (
                    <div className="custom-loader"></div>
                )}
            </div>
        </div>
    );
};
export default BranchList;