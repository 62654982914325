// import React, { useContext, useState } from 'react';
// import banner from '../../../assets/images/bannerV2-bg.png';
// import pic from '../../../assits/pic.png';
// import { AiOutlineCamera } from 'react-icons/ai';
// import { AppContext } from '../../../context/context';
// import { uploadFileMultipleInputVariants } from '../../../utils/Common';

// const BannerV2 = () => {
//     const [coverUrl, setCoverUrl] = useState(null);
//     const { state, updateInvoiceSetting } = useContext(AppContext);
//     const userData = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
//     const { owner_name, outlet_name, cover_pic } = userData || {};
//     const token = localStorage.getItem("token");

//     const handleCoverPhoto = async (e) => {
//         const file = e.target.files[0];

//         try {
//             // Step 1: Upload the cover photo and get the path
//             const response = await uploadFileMultipleInputVariants([{
//                 file,
//                 input_name: 'cover_pic',
//             }], 'cover_pic');

//             if (response) {
//                 // Step 2: Update localStorage with the new cover_pic path
//                 const updatedUser = { ...userData, cover_pic: response.path };
//                 localStorage.setItem('user', JSON.stringify(updatedUser));
//                 setCoverUrl(response.path); // Set the uploaded cover photo URL

//                 // Step 3: Send updated user data to the API
//                 await saveUserDataToAPI(updatedUser);
//             }
//         } catch (error) {
//             console.error('Error uploading cover photo or saving data:', error);
//         }
//     };

//     // Function to post the updated user data to the API
//     const saveUserDataToAPI = async (updatedUser) => {
//         try {
//             const apiResponse = await fetch(`${process.env.REACT_APP_API_URL}outlet-update`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     authorization: `Bearer ${token}`,
//                 },
//                 body: JSON.stringify(updatedUser),  // Send the updated user data to the API
//             });

//             if (!apiResponse.ok) {
//                 throw new Error('Failed to update user data in API');
//             }

//             const result = await apiResponse.json();
//             console.log('User data successfully updated in API:', result);
//         } catch (error) {
//             console.error('Error saving user data to API:', error);
//         }
//     };

//     return (
//         <div className='w-full relative'>
//             {/* Banner Background Image */}
//             <div>
//                 <img
//                     className='w-full h-[200px] object-cover rounded-md'
//                     src={coverUrl || cover_pic || banner} // Use uploaded cover URL or fallback to saved cover_pic or default banner
//                     alt='Banner'
//                 />
//             </div>

//             {/* Edit Cover Photo Button */}
//             <div className='absolute top-4 right-4'>
//                 <label htmlFor='cover-photo-input' className='cursor-pointer'>
//                     <div className='flex items-center space-x-2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md hover:bg-gray-700'>
//                         <AiOutlineCamera className='text-xl' />
//                         <span>Edit cover photo</span>
//                     </div>
//                 </label>
//                 {/* Hidden file input for cover photo */}
//                 <input
//                     type='file'
//                     id='cover-photo-input'
//                     accept='image/*'
//                     hidden
//                     onChange={handleCoverPhoto}
//                 />
//             </div>

//             {/* Profile Picture and Text (Left-Aligned) */}
//             <div className='absolute flex items-center top-[45%] left-8 space-x-4'>
//                 {/* Profile Picture */}
//                 <div className='relative group'>
//                     <label htmlFor='drop' className='cursor-pointer'>
//                         <div className='w-[60px] h-[60px] md:w-[80px] md:h-[80px] p-1 rounded-full bg-white'>
//                             <img
//                                 className='w-full h-full rounded-full object-cover'
//                                 src={pic} // Assuming the profile picture remains the same for now
//                                 alt={`Profile pic of ${owner_name}`}
//                             />
//                         </div>
//                     </label>
//                 </div>

//                 {/* Text Section */}
//                 <div className='font-nunito'>
//                     <h2 className='text-white text-2xl font-bold'>{owner_name}</h2>
//                     <p className='text-[#E5E5E5] text-sm font-semibold'>{outlet_name}</p>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default BannerV2;

import React, { useContext, useState } from "react";
import banner from "../../../assets/images/bannerV2-bg.png";
import pic from "../../../assits/pic.png";
import { AiOutlineCamera } from "react-icons/ai";
import { AppContext } from "../../../context/context";
import { uploadFileMultipleInputVariants } from "../../../utils/Common";
import FileResizer from "react-image-file-resizer";
import Swal from "sweetalert2";

const BannerV2 = () => {
  const [coverUrl, setCoverUrl] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null); // New state for profile picture

  const { state, updateInvoiceSetting } = useContext(AppContext);
  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const { owner_name, outlet_name, cover_pic, profile_pic } = userData || {}; // Add profile_pic from user data
  const token = localStorage.getItem("token");

  const base64ToBlob = (base64, mime) => {
    const byteString = atob(base64.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mime });
  };

  const resizeFile = (file, imageName) =>
    new Promise((resolve) => {
      let width, height;

      if (imageName === "cover_pic") {
        width = 1080;
        height = 200;
      }

      if (imageName === "profile_pic") {
        width = 300; // default width
        height = 300; // default height
      }

      FileResizer.imageFileResizer(
        file,
        width,
        height,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const handleCoverPhoto = async (e) => {
    const file = e.target.files[0];

    // Allowed image extensions
    const allowedExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];

    // Check if the uploaded file is an image
    if (!allowedExtensions.includes(file?.type)) {
      alert("Please upload a valid image file (JPEG, PNG, GIF).");
      return;
    }

    try {
      const resizedFile = await resizeFile(file, "cover_pic");
      const blobFile = base64ToBlob(resizedFile, file?.type); // Convert base64 to Blob
      const response = await uploadFileMultipleInputVariants(
        [
          {
            file: blobFile,
            input_name: "cover_pic",
          },
        ],
        "cover_pic"
      );

      if (response) {
        const updatedUser = { ...userData, cover_pic: response.path };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        setCoverUrl(response.path);
        await saveUserDataToAPI(updatedUser);
      }
    } catch (error) {
      console.error("Error uploading cover photo or saving data:", error);
    }
  };

  // New function to handle profile picture upload
  const handleProfilePhoto = async (e) => {
    const file = e.target.files[0];
    console.log(file)
    // Allowed image extensions
    const allowedExtensions = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
      ];

      // Check if the uploaded file is an image
      if (!allowedExtensions.includes(file?.type)) {
        alert("Please upload a valid image file (JPEG, PNG, GIF).");
        return;
      }

    try {
      const resizedFile = await resizeFile(file, "profile_pic");
      const blobFile = base64ToBlob(resizedFile, file?.type); // Convert base64 to Blob
      const response = await uploadFileMultipleInputVariants(
        [
          {
            file: blobFile,
            input_name: "profile_pic", // Specify 'profile_pic'
          },
        ],
        "profile_pic"
      );

      if (response) {
        const updatedUser = { ...userData, profile_pic: response.path };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        setProfileUrl(response.path); // Set the uploaded profile picture URL
        await saveUserDataToAPI(updatedUser);
      }
    } catch (error) {
      console.error("Error uploading profile photo or saving data:", error);
    }
  };

  const saveUserDataToAPI = async (updatedUser) => {
    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_API_URL}outlet-update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedUser),
        }
      );

      if (!apiResponse.ok) {
        throw new Error("Failed to update user data in API");
      }

      const result = await apiResponse.json();
      Swal.fire("Image has been uploaded successfully!");
      console.log("User data successfully updated in API:", result);
    } catch (error) {
      console.error("Error saving user data to API:", error);
    }
  };

  return (
    <div className="w-full relative">
      {/* Banner Background Image */}
      <div>
        <img
          className="w-full h-[200px] object-cover rounded-md"
          src={coverUrl || cover_pic || banner}
          alt="Banner"
        />
      </div>

      {/* Edit Cover Photo Button */}
      <div className="absolute top-4 right-4">
        <label htmlFor="cover-photo-input" className="cursor-pointer">
          <div className="flex items-center space-x-2 px-3 py-2 bg-gray-800 text-white text-sm rounded-md hover:bg-gray-700">
            <AiOutlineCamera className="text-xl" />
            <span>Edit cover photo</span>
          </div>
        </label>
        <input
          type="file"
          id="cover-photo-input"
          accept="image/*"
          hidden
          onChange={handleCoverPhoto} // Handle cover photo change
        />
      </div>

      {/* Profile Picture and Text (Left-Aligned) */}
      <div className="absolute flex items-center top-[45%] left-8 space-x-4">
        {/* Profile Picture */}
        <div className="relative group">
          <label htmlFor="profile-photo-input" className="cursor-pointer">
            <div className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] p-1 rounded-full bg-white">
              <img
                className="w-full h-full rounded-full object-cover"
                src={profileUrl || profile_pic || pic} // Use uploaded profile URL or saved profile_pic or default pic
                alt={`Profile pic of ${owner_name}`}
              />
            </div>
            {/* Camera icon hidden by default, shown on hover */}
            <div className="flex justify-center items-center rounded-full bg-[#fff] p-[3px] absolute top-[84%] left-[50%] translate-x-[-50%] translate-y-[-50%] opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <span>
                <AiOutlineCamera />
              </span>
            </div>
          </label>

          <input
            type="file"
            id="profile-photo-input"
            accept="image/*"
            hidden
            onChange={handleProfilePhoto} // Handle profile photo change
          />
        </div>

        {/* Text Section */}
        <div className="font-nunito">
          <h2 className="text-white text-2xl font-bold">{owner_name}</h2>
          <p className="text-[#E5E5E5] text-sm font-semibold">{outlet_name}</p>
        </div>
      </div>
    </div>
  );
};

export default BannerV2;
