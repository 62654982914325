import React, { useContext } from "react";

import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from '../../utils/Common';

const HoldInvoiceItem = (props) => {
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;

  const {
    showModalBeforeDelete,
    showModal,
    handleShowModal,
    loadingCallback,
    navigate,
    setSearchKeyword,
    searchKeyword,
    shouldRenderPagination,
    currentPage,
    onPageChange,
    loading,
    index,
    setShowModal,
    setWarningMessage,
    invoice,
    type
  } = props;


  return (

    // <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-4 sm:grid-cols-5 items-center">
    <>
      {showModal ? (
        <Modal
          loadingCallback={loadingCallback}
          navigate={navigate}
          formId="my_modal"
          type={type}
          title="Add Return"
          isOpen={showModal}
          setOpen={setShowModal}
          setData={null}
          setWarningMessage={setWarningMessage}
        />
      ) : null}
      <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-5 sm:grid-cols-6 items-center">
        <div className="text-[14px]">
          <h1 className="text-[#000232] font-[500]">{invoice.name}</h1>
          <p className="text-[#87889E] font-[700]">{invoice.sname}</p>
        </div>

        <div className="text-[16px] text-[#000] font-[500] text-center">{invoice.price}</div>
        <div className="text-[16px] text-[#000] font-[500] text-center">{invoice.due}</div>
        <div className="hidden sm:block">
          <h1 className="text-[14px] text-[#000] font-[500] text-center">{formatDate(invoice.date)}</h1>
          <p className="text-[14px] text-[#87889E] font-[600] text-center">{invoice.time}</p>
        </div>
        <div className="text-[16px] text-red-600 font-[500] text-center">{invoice.status === 0 && "Hold"}</div>
        <div className="text-[12px] font-nunito font-[700] flex flex-col lg:flex-row gap-2 justify-end items-center">
          {type==="wholesale" ?
           <a className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]" href={`/wholesale-invoice/${invoice.name}`}>VIEW</a>
          :
            <a className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[0px]" href={`/invoice/${invoice.name}`}>VIEW</a>
          }
          {type==="wholesale" ?
          <a className="block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#407BFF] px-[12px] pt-[2px] pb-[2px]" href={`/wholesale-invoice/edit/${invoice.name}`}>EDIT</a>

          :
            <a className="block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#407BFF] px-[12px] pt-[2px] pb-[2px]" href={`/invoice/edit/${invoice.name}`}>EDIT</a>
          }

          <a onClick={() => showModalBeforeDelete(invoice?.id, index)} className="cursor-pointer block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#FF0303] px-[5px] pt-[2px] pb-[2px]">DELETE</a>

        </div>
      </div>
    </>



  );
};

HoldInvoiceItem.defaultProps = { className: null };

export default HoldInvoiceItem;