import { useEffect, useState } from "react";
import axios from "axios";

const ChoosePrintOptions = (props) => {
  const {
    loadingCallback,
    navigate,
    setShowModal,
    isOpen,
    title,
    //setWarningMessage,
    type,
    //formData,
    //setFormData,
    //refetch
    handlePrint
  } = props;
  const token = localStorage.getItem("token");

  return(
    <div className="duration-500">
      <div className="w-full relative">
        <button onClick={() => { handlePrint('pdfConentAreasA3') }} className=" w-full bg-[#034AFF] py-3 mt-3 mx-auto flex justify-center text-white rounded-xl text-xs md:text-sm lg:text-base">
          Print Sales Invoice
        </button>
        <button onClick={() => { handlePrint('pdfConentAreasA3Challan') }} className=" w-full bg-[#034AFF] py-3 mt-3 mx-auto flex justify-center text-white rounded-xl text-xs md:text-sm lg:text-base">
          Print Sales Challan
        </button>
      </div>
    </div>
  );


};

export default ChoosePrintOptions;