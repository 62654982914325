import Select from "react-select";
import FlagIcon from "./FlagIcon";
import DialCodes from '../data/dialCodes.json';

// Custom option component
const CustomOption = ({ innerRef, innerProps, data }) => (
  <div ref={innerRef} {...innerProps} className="flex items-center p-2">
    <FlagIcon countryCode={data.countryCode} className="mr-2" />
    <span>{data.label}</span>
  </div>
);

const getDialCodeByCountryCode = (code) => {
  const country = DialCodes.find((item) => item.code === code);
  return country ? country.dial_code : '';
};

// Custom styles for react-select
const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: "15px",
    border: "1px solid #f3f3fb",
    padding: "5px",
    height: "50px",
    fontSize: "14px",
    fontColor: "#f3f3fb"
  }),
  option: (base) => ({
    ...base,
    fontSize: "14px",
  }),
};

// Example usage of the Select component
const SelectCountryCode = ({ state, handleChange, selectedCountryCode }) => {
  const options = state.countryList.map((country) => ({
    value: getDialCodeByCountryCode(country.code),
    label: `${country.name} (${getDialCodeByCountryCode(country.code)})`, // Updated label to include country name
    countryCode: country.code, // For flag display
  }));

  return (
    <Select
      name="country_code"
      value={options.find(option => option.value === selectedCountryCode)} // Match selected option correctly
      onChange={(selectedOption) => handleChange("country_code", selectedOption.value)}
      options={options}
      components={{ Option: CustomOption }} // Use custom option component
      styles={customStyles} // Custom styles
      placeholder="Select"
      isSearchable={true} // Searchable select box
    />
  );
};


export default SelectCountryCode;