import React, { useState } from "react";
import CalculatorPopup from "../CalculatorPopup/CalculatorPopup";
import './ChatPopup.css'
// Import the CalculatorPopup component

const ChatPopup = ({type}) => {
  const [isCalculatorOpen, setCalculatorOpen] = useState(false);

  const toggleCalculator = () => {
    setCalculatorOpen(!isCalculatorOpen);
  };

  return (
    <div className="chat-popup-container">
      <div
        className="calculator-icon"
        onClick={toggleCalculator}
        id="calculator-icon"
      >
        {/* You can use any icon or text here for your calculator icon */}
        <div className="calculate-icon "><i className="fi fi-rr-calculator-simple text-3xl"></i></div>
      </div>
      <CalculatorPopup isOpen={isCalculatorOpen} setCalculatorOpen={setCalculatorOpen} isCalculatorOpen={isCalculatorOpen}  />
    </div>
  );
};

export default ChatPopup;
