
import React, { useEffect, useState, useRef } from "react";
import Select from 'react-select';

import './select.css';
import { logDOM } from "@testing-library/react";

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '10px',
    borderColor: '#C1CFEF',
    width: '100%',
    //boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#C1CFEF' : 'white',
    color: '#102048',
    '&:hover': {
      backgroundColor: '#C1CFEF',
    },
  }),
};

const ItemSelectBox2 = ({ onInputChangeData,label, placeholder, className, name, options, value, onChange, error, setFormErrors = false }) => {

  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const handleOptionClick = (option) => {
    setIsOpen(false);
  };

  const handleDelayedOptions = () => {
    setTimeout(() => {
      setIsOpen(true);
    }, 200);
  };

  useEffect(() => {
    if (options?.length > 10) {
      //handleDelayedOptions();
    }
  }, []);

  const errorClass = error ? " border-1 border-red-500" : " border ";

  let selectedOption = { label: placeholder, value: value };
  const placeholderOption = {id: value, name: placeholder };

  return (
    <div className={`${className} w-full relative`}>
      <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
        {label}
      </label>
      <Select
        name={name}
        onChange={(event) => {
          onChange(event);
          if (setFormErrors) {
            setFormErrors({ name: null });
          }
        }}
        className={` select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none px-[0px]  text-md focus:outline-none ${
          error ? " border-1 border-red-500" : ""
        }`}
        // options={ options?.map((option) => ({
        //   value: option.id,
        //   label: option.type_name ? option.type_name: option.payment_category_name
        // }))}
        options={[
          placeholder ? { value: placeholderOption.id, label: placeholderOption.name } : { value: placeholderOption.id, label: placeholderOption.name },
          ...(Array.isArray(options)
            ? options.filter((option)=>option.id != placeholderOption.id).map((option) => (
              { value: option.id, label: option.type_name?option.type_name: option.payment_category_name }
              ))
            : []),
          ...(isOpen && Array.isArray(options)
            ? options.filter((option)=>option.id != placeholderOption.id).map((option) => (
              { value: option.id, label: option.type_name?option.type_name: option.payment_category_name }
              ))
            : []),
        ]}
        value={selectedOption}
        styles={customStyles}
        // placeholder={placeholder}
        isSearchable
        // onMenuOpen={() => handleDelayedOptions()}
        onMenuClose={() => setIsOpen(false)}
        onInputChange={(input) => {
          if (onInputChangeData) {
            onInputChangeData(input);
          }
        }}
      />

      {/* <Select
        onChange={(event) => {
          onChange(event);
        }}
        styles={customStyles}
        // defaultValue={{ value: value, label: placeholder }}
        className={`select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none px-[0px]  text-md focus:outline-none`}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        options={options?.filter((option) => option.id !== value).map((option) => ({
          value: option.id,
          label: option.type_name ? option.type_name : option.payment_category_name,
        }))}
      /> */}

      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
    </div>
  );
};
ItemSelectBox2.defaultProps = {
  className: ''
};
export default ItemSelectBox2;
