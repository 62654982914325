import React, { useEffect, useState } from 'react';

const ExporterProductReportList1 = React.forwardRef((props, ref) => {
  const { data, filters } = props;
  const { exporter_name, carrier_name, start_date, end_date, stock_type, customer_name, product_name } = filters;
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem('user'));
    if (storedUserData) {
      setUserData(storedUserData);
    }
  }, []);
  const todayDate = new Date().toLocaleDateString();
  let serialNumber = 1;
  const priceLabel = stock_type === 'stock_in' ? 'Purchase Price' : 'Sale Price';
  const title = stock_type === 'stock_in' ? 'In' : 'Out'
  const formattedStartDate = start_date ? new Date(start_date).toLocaleDateString() : 'N/A';
  const formattedEndDate = end_date ? new Date(end_date).toLocaleDateString() : 'N/A';
  // const showExporterOnTop = exporter_name && stock_type === 'stock_in';
  // const showCarrierOnTop = carrier_name && stock_type === 'stock_in';
  // const showBothOnTop = showExporterOnTop && showCarrierOnTop;
  // const showCustomerOnTop = customer_name && stock_type === 'stock_out';

  return(
    <div ref={ref}>
      <div>
        <table>
          <tbody>
            <tr>
              <td colSpan="3" style={styles.titletext}>Stock {title} Report</td>
            </tr>
            <tr>
              <td style={styles.column}>
                <div>Shop Name: {userData?.outlet_name}</div>
                <div>Email: {userData?.email}</div>
                <div>Address: {userData?.address}</div>
              </td>
              <td style={styles.column}>
                <div>Date: {todayDate}</div>
                <div>Mobile: {userData?.phone}</div>
              </td>
              <td style={styles.column}>
                {userData?.invoice_settings.shop_logo && (
                  <img src={userData.invoice_settings.shop_logo} alt="Shop Logo" style={styles.logo} />
                )}
              </td>
            </tr>
            <tr>
              <td colSpan="3" style={styles.dateRange}>
                From: {formattedStartDate} to {formattedEndDate}
              </td>
            </tr>
            {/* {showBothOnTop && (
                  <tr>
                    <td colSpan="3" style={styles.column}>
                      <div><strong>Exporter Name:</strong> {exporter_name || 'N/A'}</div>
                      <div><strong>Carrier Name:</strong> {carrier_name || 'N/A'}</div>
                    </td>
                  </tr>
            )} */}
            {exporter_name && (
              <tr>
                <td colSpan="3" style={styles.column}>
                  <strong>Exporter Name:</strong> {exporter_name || 'N/A'}
                </td>
              </tr>
            )}
                {/* {!showBothOnTop && showExporterOnTop && (
                  <tr>
                    <td colSpan="3" style={styles.column}>
                      <strong>Exporter Name:</strong> {exporter_name || 'N/A'}
                    </td>
                  </tr>
                )} */}
            {carrier_name && (
              <tr>
                <td colSpan="3" style={styles.column}>
                  <strong>Carrier Name:</strong> {carrier_name || 'N/A'}
                </td>
              </tr>
            )}
                {/* {!showBothOnTop && showCarrierOnTop && (
                  <tr>
                    <td colSpan="3" style={styles.column}>
                      <strong>Carrier Name:</strong> {carrier_name || 'N/A'}
                    </td>
                  </tr>
                )} */}
            {customer_name && (
              <tr>
                <td colSpan="3" style={styles.column}>
                  <strong>Customer Name:</strong> {customer_name || 'N/A'}
                </td>
              </tr>
            )}
            {product_name && (
              <tr>
                <td colSpan="3" style={styles.column}>
                  <strong>Product Name:</strong> {product_name || 'N/A'}
                </td>
              </tr>
            )}
                {/* {showCustomerOnTop && (
                  <tr>
                    <td colSpan="3" style={styles.column}>
                      <strong>Customer Name:</strong> {customer_name || 'N/A'}
                    </td>
                  </tr>
                )} */}
          </tbody>
        </table>
      </div>
      {Object.keys(data).map((brandName) => {
        return(
          <div key={brandName}>
            <table style={styles.table}>
              <tbody>
                <tr>
                  <td style={styles.productRow}>Product Name: {brandName}</td>
                </tr>
              </tbody>
            </table>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={{ ...styles.th, ...styles.printTh }}>SL No.</th>
                  <th style={{ ...styles.th, ...styles.printTh }}>Transaction Date</th>
                  <th style={{ ...styles.th, ...styles.printTh }}>IMEI</th>
                  {/* <th style={{ ...styles.th, ...styles.printTh }}>{priceLabel}</th> */}

                  {/* no filter */}
                  {(!stock_type && !exporter_name && !carrier_name && !customer_name && !product_name) && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Exporter</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Carrier</th>
                    </>
                  )}

                  {/* stock in filter */}
                  {stock_type === 'stock_in' && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Exporter</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Carrier</th>
                    </>
                  )}

                  {/* stock out filter */}
                  {(stock_type === 'stock_out' || customer_name) && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Invoice</th>
                      {!customer_name && stock_type === 'stock_out' && (
                        <th style={{ ...styles.th, ...styles.printTh }}>Customer Name</th>
                      )}
                    </>
                  )}

                  {/* product filter */}
                  {(product_name) && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Brand Name</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Customer Name</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Vendor Name</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Exporter Name</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Carrier Name</th>
                    </>
                  )}

                  {/* exporter/carrier/both filter */}
                  {(exporter_name || carrier_name) && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                      {!exporter_name && carrier_name && (
                        <th style={{ ...styles.th, ...styles.printTh }}>Exporter Name</th>
                      )}
                      {!carrier_name && exporter_name && (
                        <th style={{ ...styles.th, ...styles.printTh }}>Carrier Name</th>
                      )}
                    </>
                  )}

                  {/* {stock_type === 'stock_in' && carrier_name && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Price</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Exporter</th>
                    </>
                  )} */}

                  {/* {stock_type === 'stock_in' && !showBothOnTop && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                      {!showExporterOnTop && (
                        <th style={{ ...styles.th, ...styles.printTh }}>Exporter</th>
                      )}
                      {!showCarrierOnTop && (
                        <th style={{ ...styles.th, ...styles.printTh }}>Carrier</th>
                      )}
                    </>
                  )} */}

                  {/* {stock_type === 'stock_in' && showBothOnTop && (
                    <th style={{ ...styles.th, ...styles.printTh }}>Purchase Invoice</th>
                  )} */}

                  {/* {stock_type === 'stock_out' && !showCustomerOnTop && (
                    <>
                      <th style={{ ...styles.th, ...styles.printTh }}>Sale Invoice</th>
                      <th style={{ ...styles.th, ...styles.printTh }}>Customer</th>
                    </>
                  )} */}
                  {/* {stock_type === 'stock_out' && showCustomerOnTop && (
                    <th style={{ ...styles.th, ...styles.printTh }}>Sale Invoice</th>
                  )} */}
                </tr>
              </thead>
              <tbody>
                {data[brandName].map((product, index) => (
                  <tr key={index}>
                    <td style={styles.td}>{serialNumber++}</td>
                    <td style={styles.tdTransactionDate}>{product.date}</td>
                    <td style={styles.td}>{product.imei}</td>
                    {/* <td style={styles.td}>
                      {stock_type === 'stock_in' ? product.purchase_price : product.sale_price}
                    </td> */}

                  {(!stock_type && !exporter_name && !carrier_name && !customer_name && !product_name) && (
                    <>
                      <td style={styles.td}>{product.purchase_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                      <td style={styles.td}>{product.sale_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                      <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                      <td style={styles.td}>{product.sale_invoice || 'N/A'}</td>
                      <td style={styles.td}>{product.exporter_name || 'N/A'}</td>
                      <td style={styles.td}>{product.carrier_name || 'N/A'}</td>
                    </>
                  )}

                    {stock_type === 'stock_in' && (
                      <>
                        <td style={styles.td}>{product.purchase_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                        <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                        <td style={styles.td}>{product.exporter_name || 'N/A'}</td>
                        <td style={styles.td}>{product.carrier_name || 'N/A'}</td>
                      </>
                    )}

                    {(stock_type === 'stock_out' || customer_name) && (
                      <>
                        <td style={styles.td}>{product.purchase_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                        <td style={styles.td}>{product.sale_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                        <td style={styles.td}>{product.sale_invoice || 'N/A'}</td>
                        {!customer_name && stock_type === 'stock_out' && (
                          <td style={styles.td}>{product.customer_name || 'N/A'}</td>
                        )}
                      </>
                    )}

                  {(product_name) && (
                    <>
                      <td style={styles.td}>{product.brand_name || 'N/A'}</td>
                      <td style={styles.td}>{product.purchase_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                      <td style={styles.td}>{product.sale_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                      <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                      <td style={styles.td}>{product.sale_invoice || 'N/A'}</td>
                      <td style={styles.td}>{product.customer_name || 'N/A'}</td>
                      <td style={styles.td}>{product.vendor_name || 'N/A'}</td>
                      <td style={styles.td}>{product.exporter_name || 'N/A'}</td>
                      <td style={styles.td}>{product.carrier_name || 'N/A'}</td>
                    </>
                  )}

                  {(exporter_name || carrier_name) && (
                    <>
                      <td style={styles.td}>{product.purchase_price || 'N/A'} {userData?.invoice_settings.currency_info.code}</td>
                      <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                      {!exporter_name && carrier_name && (
                        <td style={styles.td}>{product.exporter_name || 'N/A'}</td>
                      )}
                      {!carrier_name && exporter_name && (
                        <td style={styles.td}>{product.carrier_name || 'N/A'}</td>
                      )}
                    </>
                  )}

                    {/* {stock_type === 'stock_in' && carrier_name && (
                      <>
                        <td style={styles.td}>{product.purchase_price || 'N/A'}</td>
                        <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                        <td style={styles.td}>{product.exporter_name || 'N/A'}</td>
                      </>
                    )} */}

                    {/* {stock_type === 'stock_in' && !showBothOnTop && (
                      <>
                        <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                        {!showExporterOnTop && (
                          <td style={styles.td}>{product.exporter_name || 'N/A'}</td>
                        )}
                        {!showCarrierOnTop && (
                          <td style={styles.td}>{product.carrier_name || 'N/A'}</td>
                        )}
                      </>
                    )} */}

                    {/* {stock_type === 'stock_in' && showBothOnTop && (
                      <td style={styles.td}>{product.purchase_invoice || 'N/A'}</td>
                    )} */}

                    {/* {stock_type === 'stock_out' && !showCustomerOnTop && (
                      <>
                        <td style={styles.td}>{product.sale_invoice || 'N/A'}</td>
                        <td style={styles.td}>{product.customer_name || 'N/A'}</td>
                      </>
                    )} */}
                    {/* {stock_type === 'stock_out' && showCustomerOnTop && (
                      <td style={styles.td}>{product.sale_invoice || 'N/A'}</td>
                    )} */}
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        );
      })};
    </div>
  );

});

const styles = {
  titletext: {
    border: '1px solid black',
    textAlign: 'center',
    margin: '0',
    padding: '2px',
    fontSize: '12px',
    width: '100%'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  column: {
    border: '1px solid black',
    textAlign: 'left',
    verticalAlign: 'top',
    padding: '5px',
    fontSize: '12px',
  },
  logo: {
    width: '100px', // Adjust size as needed
    height: 'auto',
  },
  dateRange: {
    textAlign: 'center',
    padding: '5px',
    fontSize: '12px',
    border: '1px solid black',
  },
  productRow: {
    border: '1px solid black',
    textAlign: 'center',
    padding: '5px',
    fontSize: '12px',
    width: '100%',
  },
  th: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
    backgroundColor: 'deepskyblue',

  },
  printTh: {
    backgroundColor: '#407BFF',
    color: 'white',
    WebkitPrintColorAdjust: 'exact',
  },
  td: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
  },
  tdTransactionDate: {
    border: '1px solid black',
    padding: '5px',
    textAlign: 'center',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },

};

export default ExporterProductReportList1;