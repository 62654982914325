import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons
import InputField from "../SettingsV2Components.jsx/InputField";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import { Helmet } from "react-helmet-async";

const SECRET_KEY = process.env.REACT_APP_CRYPTO_ENCRYPTION_SECRET_KEY;

const ChangePassword = () => {
  const token = localStorage.getItem("token");
  const { email, phone, password } = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Password visibility states
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const currentUserEmail = email;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(""); // Clear error on input change
    setSuccess(""); // Clear success message on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trim the input values to remove leading/trailing whitespace
    const currentPassword = formData.currentPassword.trim();
    const newPassword = formData.newPassword.trim();
    const confirmPassword = formData.confirmPassword.trim();

    // Validate that no fields are empty
    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("Please fill in all password fields!");
      return;
    }

    // Get the stored user from localStorage and decrypt the password
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const encryptedPassword = storedUser.password;

    // Decrypt the stored password
    const decryptedPassword = CryptoJS.AES.decrypt(
      encryptedPassword,
      SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);

    // Compare decrypted password with user input for the current password
    if (currentPassword !== decryptedPassword) {
      setError("Your current password did not match, please recheck!");
      return;
    }

    // Validate new password length
    if (newPassword.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}reset-password`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: currentUserEmail,
            password: newPassword, // Pass the new password to the API
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setError("");
        // Use SweetAlert2 for success message
        Swal.fire({
          title: "Success!",
          text: "Password successfully reset!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setFormData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        setSuccess("");
        setError(
          data.message || "An error occurred while resetting the password."
        );
      }
    } catch (error) {
      setSuccess("");
      setError("Failed to reset password. Please try again later.");
    }
  };

  // Toggle visibility for each password field
  const toggleVisibility = (field) => {
    if (field === "currentPassword")
      setShowCurrentPassword(!showCurrentPassword);
    if (field === "newPassword") setShowNewPassword(!showNewPassword);
    if (field === "confirmPassword")
      setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="rounded-[20px] bg-[#FAFAFA] p-3 font-nunito">
      <Helmet>
        <title>Outlet Expense | Settings | Change Password</title>
      </Helmet>
      <form
        onSubmit={handleSubmit}
        className="p-4 shadow rounded-md max-w-md mx-auto"
      >
        <h2 className="text-xl font-semibold mb-6">Change Password</h2>

        {/* Input for Current Password */}
        <div className="relative">
          <InputField
            label="Current Password"
            type={showCurrentPassword ? "text" : "password"} // Toggling type
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
          />
          <span
            className="absolute right-3 top-3 cursor-pointer"
            onClick={() => toggleVisibility("currentPassword")}
          >
            {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>

        {/* Input for New Password */}
        <div className="relative">
          <InputField
            label="New Password"
            type={showNewPassword ? "text" : "password"} // Toggling type
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
          />
          <span
            className="absolute right-3  top-3 cursor-pointer"
            onClick={() => toggleVisibility("newPassword")}
          >
            {showNewPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>

        {/* Input for Confirm New Password */}
        <div className="relative">
          <InputField
            label="Confirm New Password"
            type={showConfirmPassword ? "text" : "password"} // Toggling type
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          <span
            className="absolute right-3 top-3 cursor-pointer"
            onClick={() => toggleVisibility("confirmPassword")}
          >
            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>

        {/* Error message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Success message */}
        {success && <p className="text-green-500 mb-4">{success}</p>}

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-[#407bff] text-white py-2 rounded-md hover:bg-blue-700 focus:ring focus:ring-blue-300"
        >
          Change Password
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
