import React from 'react'
import "./index.css"
import ReactApexChart from "react-apexcharts";
function RevenueGraph({revenueData,allFetchedData}) {

    


    return (
        <>
            <div className="chart-info w-full">
                <div className="chart-info-title">
                    <p>Revenue Overview</p>
                </div>
                <div className="chart-info-details">
                    <p><span>({allFetchedData.revenue_change}) </span>{allFetchedData.revenue_report}</p>
                </div>
            </div>
            <div id="chart">
                <ReactApexChart options={revenueData.options} series={revenueData.series} type="area" />
            </div>
        </>
    )
}

export default RevenueGraph