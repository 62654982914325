import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import { AiOutlineSearch } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from "../../context/context.js";
import "../products/productlist/index.scss";
import { formatNumber } from '../../utils/Common';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const shop_logo = `${BASE_URL}/layoutlogo.svg`;
const MAX_MOBILE_SCREEN_WIDTH = 767;



const MostSellingProducts = (props) => {
    const { type, mostSellingData, loadingCallback } = props;
    const {
        state
    } = useContext(AppContext);
    const { invoiceSetting } = state;
    const token = localStorage.getItem("token");
    const [filterItem, setFilterItem] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    const [isMobile, setIsMobile] = useState(false);

    const navigate = useNavigate();
    const GotoAddProducts = () => {
        navigate("/addproducts")
    }


    const getTopSaleProductlist = () => {
        loadingCallback(true);
        axios
        .get(`${process.env.REACT_APP_API_URL}top-sales`, {
            headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            if (response.data.success) {
                loadingCallback(false);
                setFilterItem(response.data.data.data);
                setFilteredProducts(response.data.data.data);
            }
        })
        .catch((error) => {
            console.log(error);
            loadingCallback(false);
            if (error?.response?.status === 401) {
                localStorage.setItem('token', false);
                localStorage.setItem('token', false);
                localStorage.setItem('user', '');
                // Clear session and local storage
                localStorage.setItem('isPinVerify', false);

                navigate('/login');
            }
        });
    };

    useEffect(() => {
        getTopSaleProductlist();
        if(typeof window !== 'undefined' && document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH){
            setIsMobile(true);
        }


        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [isMobile]);

    const handleResize = () => {
        if (typeof window !== 'undefined' && document.documentElement.clientWidth <= MAX_MOBILE_SCREEN_WIDTH) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    // Calculate the number of products to show in the carousel

    const eclipse = (str, length) => {
        return str && str.length > 0 &&  str.length > length ? str.substring(0, length) + "..." : str;
    }
    const perPage = 10;
    const shouldRenderPagination = mostSellingData?.total > perPage;

    return (
    <>
      <div className='productsList-container'>

        <div className='flex flex-col md:flex-row justify-between mb-4'>
          <div className="title-link mt-2 mb-3 md:mb-0 md:mt-0">
              <h1 className="text-[22px] text-[#000232] font-semibold font-lato">Most Selling Products</h1>
          </div>

          <div className='flex gap-2 justify-center'>
            <div className="flex relative w-[95%]">
                <input
                    autoFocus
                    type="text"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                    placeholder="Search Invoice"
                    onChange={(e) => {
                        setSearchKeyword(e.target.value);
                    }}
                />

                <div className="absolute top-[37%] left-[10px]">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                </div>

                <div className="absolute top-[36%] right-[10px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z" fill="#102048"/>
                        <path d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z" fill="#102048"/>
                        <path d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z" fill="#102048"/>
                        <path d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z" fill="#102048"/>
                        <path d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z" fill="#102048"/>
                        <path d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z" fill="#102048"/>
                        <path d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z" fill="#102048"/>
                        <path d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z" fill="#102048"/>
                        <path d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z" fill="#102048"/>
                    </svg>
                </div>
            </div>

            <button onClick={GotoAddProducts} className='bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 pl-[22px] pr-[24px] flex items-center gap-2'>
                <BiPlus className='text-xl'></BiPlus>
            </button>
          </div>
        </div>

        {/* Pagination component */}
        {/* {shouldRenderPagination && (
          <Pagination
            currentPage={currentPage}
            lastPage={mostSellingData?.last_page}
            onPageChange={onPageChange}
          />
        )} */}

        {mostSellingData.map((item, index) => {
          //let item = pdoductData.product_info;
          return (
            <div
              className={`${
                item?.product_info?.current_stock === 0
                  ? 'border-[#ca1830]'
                  : 'border-[#18CA29]'
              } border-1 border-solid productlist-table`}
              key={item?.product_info?.id}
            >
              <div className="table-content">
                <div className="table-content-image w-full md:w-[160px] h-auto md:h-[160px]">
                  <img
                    className="w-[160px] h-[160px] md:w-[100%] md:max-h-[160px] md:min-h-[160px] m-auto md:m-0"
                    src={
                      item?.product_info?.image_path
                        ? item?.product_info?.image_path
                        : shop_logo
                    }
                    alt="img"
                  />
                </div>
                <div className="table-content-data">
                  <div className="flex flex-row gap-[20px] mb-[12px]">
                    <div className="font-nunito">
                      <div className="text-[#2D3748] text-[16px] font-bold">
                        {item?.product_info?.name}
                      </div>
                      <div className="text-[#718096] text-[14px] font-extrabold">
                        #{item?.product_info?.barcode}
                      </div>
                    </div>
                    <div className="border-l-[1px] border-solid border-#718096 p-l-[16px] flex items-center">
                      <span className="bg-[#FF6B02] rounded-[5px] text-[#FFF] text-[12px] font-extrabold px-2 pt-[2px] pb-[2px] ml-[20px]">
                        {item?.product_info?.discount ? item?.product_info?.discount : 0 +
                          ((item?.product_info?.discount_type ===
                              'Percentage' || item?.product_info?.discount_type ===
                              '2')
                              ? '%'
                              : invoiceSetting
                                  ?.currency_info
                                  ?.code || 'BDT')}
                      </span>
                    </div>
                  </div>
                  <div className="table-content-data-details">
                    <div className="left table-content-data-left grid grid-cols-[100px,1fr] gap-x-[5px] gap-y-[2px] ">
                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Category
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {item?.product_info?.category
                          ? item?.product_info?.category?.name
                          : null}
                      </div>

                      <div className="text-[#718096] whitespace-nowrap text-[14px] font-medium font-nunito">
                        Sub Category
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {item?.product_info?.sub_category
                          ? item?.product_info?.sub_category?.name
                          : null}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Brand
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {item?.product_info?.brands
                          ? item?.product_info?.brands?.name
                          : null}
                      </div>

                      <div
                        className={`${
                          item?.product_info?.current_stock === 0
                            ? 'text-[#F00000]'
                            : 'text-[#718096]'
                        }  text-[14px] font-medium font-nunito`}
                      >
                        {item?.product_info?.current_stock === 0
                          ? 'Stock Out'
                          : 'Stock In'}
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        : {formatNumber(item?.product_info?.current_stock)}{' '}
                        {item?.product_info?.unit_id
                          ? item?.product_info?.unit?.name
                          : null}
                      </div>
                    </div>
                    <div className="right table-content-data-left grid grid-cols-[100px,1fr] gap-x-[5px] gap-y-[2px] ">
                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Unit
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {item?.product_info?.unit_id
                          ? item?.product_info?.unit?.name
                          : null}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                        Sale Price
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {formatNumber(item?.product_info?.purchase_price)}{' '}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info
                              ?.code
                          : 'BDT'}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                      Total Sale Unit
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        : {formatNumber(item?.product_info?.retails_price)}{' '}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info
                              ?.code
                          : 'BDT'}
                      </div>

                      <div className="text-[#718096] text-[14px] font-medium font-nunito">
                      Total Sale Price
                      </div>
                      <div className="text-[#2D3748] text-[14px] font-bold font-nunito">
                        :{' '}
                        {formatNumber(item?.product_info?.wholesale_price)}{' '}
                        {invoiceSetting?.currency_info?.code
                          ? invoiceSetting?.currency_info
                              ?.code
                          : 'BDT'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <Link className="bg-[#407BFF] text-[12px] text-[#FFF] font-nunito font-bold rounded-[5px] px-[8px] py-[4px] hover:text-[#FFF]" to={`/products/${item?.product_info?.id}`}><span>View Details</span></Link>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default MostSellingProducts