import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";

const DesignationDetailForm = (props) => {
  const {
    formId,
    navigate,
    loadingCallback,
    setWarningMessage,
    setShowModal,
    updateModalTitle,
    designationId,
    formData,
  } = props;

  const token = localStorage.getItem("token");

  useEffect(() => {
    getDesignationDetails(designationId)
  }, [designationId]);

  const [designationDescription, setDesignationDescription] = useState(null);
  const [designationName, setDesignationName] = useState(null);

  const getDesignationDetails = (id) => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}designation/${id}`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        loadingCallback(false)
        console.log("============");
        console.log(response);
        //
        const designationData = response?.data?.data;
        setDesignationName(designationData?.name);
        setDesignationDescription(designationData?.description);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };



  return(
    <>
      <div class="flex flex-row justify-between items-center py-2 px-1">
          <h1 class="text-md font-semibold font-lato pb-1 px-1">Description</h1>
      </div>
      <div className="font-lato text-[15px] py-2 px-2">
          <div className="font-[400] text-[#808080]">{designationDescription}</div>
      </div>
      <div className="mb-[0px] pb-10 px-2">
        <div className="flex flex-row gap-2 font-nunito font-bold text-[13px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
          <div className="py-[5px] flex-1">
            Designation Name
          </div>
        </div>
        <div className="flex flex-row gap-2 font-nunito font-[500] text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
          <div className="py-[5px] flex-1">{designationName}</div>
        </div>
      </div>
    </>
  );
}

export default DesignationDetailForm;