import React from 'react';
import DashedLine from '../../../components/DashedLine/DashedLine';
import DashedLine2 from '../../../components/DashedLine/DashedLine2';
import SolidLine from '../../../components/DashedLine/SolidLine';
import { formatPhoneNumber, formatPrice, numberToWords,invoiceCalculate } from '../../../utils/Common';
import Watermark from '../Watermark/Watermark';
import './PdfHtmlContentFor3mm.css';



import QRCode from 'react-qr-code';

const PdfHtmlFor3mm = props => {
  const { invoice, invoiceSetting, id, parentClassName } = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice?.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';
  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;


  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  const formattedTime = `${hours}:${minutes}${ampm}`;
  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;




  return (
    // <div style={{padding:"0px", fontFamily:"Arial", fontSize:"14px"}}  id="pdfConentAreas3" className="pdfConentAreas3 p-0  ">

    //   <div id="invoice-detail-page" className="invoice-detail-page font-extrabold ">

    //     <div className="invoice-header">
    //       <div className="invoice-logo invoice-logo-pdf flex justify-center">

    //         <img src={shop_logo} alt={invoice.invoice_id} className={parentClassName + ' h-[80px]'} />

    //       </div>
    //       <DashedLine2 />
    //       <div className='flex justify-between items-center'>
    //       <div>
    //           {invoice?.user_info?.outlet_name && (
    //             <h1 className=" text-black    uppercase ">
    //               {invoice?.user_info?.outlet_name}
    //             </h1>
    //           )}
    //           {invoice?.user_info?.address && (
    //             <p className="mt-1 mb-1    text-black relative ">
    //               {invoice?.user_info?.address}
    //             </p>
    //           )}
    //           {invoiceSetting?.mobile_number && (
    //             <p className="   text-black ">
    //               Mobile: {formatPhoneNumber(invoiceSetting?.mobile_number, invoice?.user_info?.country_code)}
    //             </p>
    //           )}
    //                     <div className='flex flex-col '>
    //        <span>{invoiceSetting?.web_address ?
    //           invoiceSetting?.web_address
    //         : (
    //           ""
    //         )}</span>
    //         <span>
    //         {invoiceSetting?.social_link ?
    //           invoiceSetting?.social_link
    //         : (
    //           ""
    //         )}
    //         </span>

    //         </div>
    //         </div>
    //         <div className="w-[70px]">
    //           <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice?.invoice_id} size={60} />
    //         </div>
    //       </div>
    //     </div>
    //     <DashedLine />

    //     <div className="invoice-customer flex justify-between">
    //       <div>
    //         <p className='   '>Date: {formattedDateTime}</p>
    //         <p className='   '>Customer Id:  #{invoice?.customer_id}</p>
    //       </div>
    //       <div>
    //         <p className='   ' > Name: {invoice?.customer_name} </p>
    //         <p className='   '> Phone: {invoice?.country_code}{invoice?.customer_phone}</p>
    //       </div>
    //     </div>
    //     <div style={{ textAlign: 'center', margin: '0 auto' }}>
    //       <p className="    p-2  uppercase ">Invoice No: {invoice?.invoice_id}</p>
    //     </div>

    //     <div className="invoice-items">
    //       <table className="table">
    //         <thead>
    //           <tr className=" ">
    //             <th className='!text-[14px] '>Name</th>
    //             <th className=' !text-[14px]'>Qty</th>
    //             <th className=' !text-[14px]'>Price</th>
    //             <th className='!text-[14px] text-right'>Total</th>
    //           </tr>
    //         </thead>

    //         <tbody>
    //           <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark>
    //           {invoice?.sales_details.map((item) => {
    //             totalQty += Number(item.qty);
    //             totalItems += 1;
    //             const subtotalPrice = formatPrice(item.qty * item.price);
    //             totalPrice += Number(subtotalPrice);
    //             return (
    //               <tr key={item.id}>
    //               <td style={{fontSize:"14px", }}>{item?.product_info?.name}<br/><span className=''>{item?.product_info?.serial}</span></td>
    //               <td style={{ fontSize:"14px", whiteSpace: 'nowrap' }}>{item.qty}</td>
    //               <td style={{ fontSize:"14px", whiteSpace: 'nowrap' }}>{item.price}</td>
    //               <td style={{ fontSize:"14px", textAlign:"right" }}>{subtotalPrice}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</td>
    //             </tr>
    //             );
    //           }
    //           )}
    //         </tbody>
    //       </table>
    //     </div>
    //     <DashedLine2 />
    //     <div className="invoice-summary font-extrabold">
    //       <p className='flex   justify-between   '>
    //         <span className=''>TOTAL ITEMS</span>
    //         <span className=''>{totalItems}</span>
    //       </p>

    //       <p className='flex   justify-between   '>
    //         <span className=''>SUB TOTAL</span>
    //         <span className=''>{totalPrice}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
    //       </p>

    //       <p className='flex   justify-between   '>
    //         <span className=''>DISCOUNT</span>
    //         <span className=''>{formatPrice(invoice?.discount ? invoice?.discount : 0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
    //       </p>
    //       <p className='flex   justify-between    '>
    //         <span className=''>Vat {''}</span>
    //         <span className=''>{invoice?.vat ? formatPrice(invoice?.vat) : 0.00}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
    //       </p>
    //       <p className='flex  justify-between '>
    //         <span className=''>Service Charge {''}</span>
    //         <span className=''>{invoice?.tax ? formatPrice(invoice?.tax) : 0.00}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
    //       </p>
    //       <DashedLine2 />
    //       <p className='flex   justify-between '>
    //         <span className=''>Grand Totals</span>
    //         <span className=''>{formatPrice(invoiceCalculate(invoice).grandTotal)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
    //       </p>
    //     </div>
    //     <p className='flex   justify-between    '>
    //       <span className=''>Paid Amount</span>
    //       <span className=''>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
    //     </p>
    //     <p className='flex   justify-between    '>
    //       <span className=''>Due Amount</span>

    //       <span className=''>{formatPrice(invoiceCalculate(invoice).dueAmount) }{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
    //     </p>
    //     <hr></hr>
    //     <p className='flex flex-col  '>
    //       <p className='   '>In word: {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))} taka only</p>
    //     </p>
    //     <div className='' style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
    //       <p className="    p-2  uppercase ">You saved {formatPrice(invoice?.discount ? invoice?.discount : 0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
    //       <p className='    pl-2 pr-2  uppercase '>Thanks for purchase</p>
    //       <p className='   text-[10px] pl-2 pr-2  uppercase '>A Product of SQUAD INNOVATORS </p>
    //     </div>

    //   </div>
    // </div>
    <div style={{padding:"0px", fontFamily:"Arial", fontSize:"14px", margin: "0"}}  id="pdfConentAreas3" className="pdfConentAreas3 p-0 ">
      <div style={{ padding:"0px", maxWidth: "350px", margin: "0 auto", fontFamily:"Arial", fontSize:"14px",}} id="pdfConentAreas3" className="pdfConentAreas3 p-0">
        <div id="invoice-detail-page" className="invoice-detail-page ">
          <div className="invoice-header">
            <div className="invoice-logo invoice-logo-pdf flex justify-center">

              <img src={shop_logo} alt={invoice.invoice_id} className={parentClassName + ' h-[80px]'} />

            </div>
            {/* <DashedLine2 /> */}
            {/* Container with fixed width and centered content */}
            <div className='flex justify-center items-center'>
              {/* added fixed width */}
              <div style={{fontSize:"12px"}} className="w-[400px] text-center my-2">
                  {invoice?.user_info?.outlet_name && (
                    <h1 className=" text-black    uppercase font-bold">
                      {invoice?.user_info?.outlet_name}
                    </h1>
                  )}
                  {invoice?.user_info?.address && (
                    <p className="mt-1 mb-1  font-bold  text-black relative ">
                      Address: {invoice?.user_info?.address}
                    </p>
                  )}
                  {invoiceSetting?.mobile_number && (
                    <p className="   text-black ">
                      Mobile: {formatPhoneNumber(invoiceSetting?.mobile_number, invoice?.user_info?.country_code)}
                    </p>
                  )}
                  {/* centered */}
                  <div className='flex flex-col text-center'>
                    {invoiceSetting?.web_address ?
                        invoiceSetting?.web_address
                      : (
                        ""
                      )}
                    {invoiceSetting?.social_link ?
                        invoiceSetting?.social_link
                      : (
                        ""
                      )}
                  </div>
                  {/* margin-top added */}
                  {/* <div className="mt-2">
                    <QRCode className="mx-auto w-[70px] object-contain" value={BASE_URL + '/invoice/' + invoice?.invoice_id} size={60} />
                  </div> */}
                  {/* Sales Receipt Text */}
                  <div className="mt-2">
                    <h2 className="text-lg text-black font-bold">
                      Sales Receipt
                    </h2>
                  </div>
                  {/* Invoice ID */}
                  <div className="mt-1">
                    <p className="text-sm font-bold text-black">
                      Invoice: {invoice?.invoice_id}
                    </p>
                  </div>
                  <SolidLine />
                </div>
            </div>
          </div>
          <div style={{fontSize:"12px"}} className="invoice-customer flex flex-col items-start">
            <div>
              <p className='font-semibold'>Date: {formattedDate}</p>
            </div>
            <div>
              <p className='font-semibold'>Time: {formattedTime}</p>
            </div>
            <div>
              <p className='font-semibold'>Attended By: {invoice?.saler?.name}</p>
            </div>
          </div>
          <div className="invoice-items mt-3">
            <table className="table">
              <thead className="border-1 border-black">
                <tr className="border-none">
                  <th className='!text-[14px] font-semibold'>Name</th>
                  <th className=' !text-[14px] font-semibold'>Price</th>
                  <th className=' !text-[14px] font-semibold'>Qty</th>
                  <th className='!text-[14px] text-right font-semibold'>Total</th>
                </tr>
              </thead>

              <tbody className="p-0">
                <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark>
                {invoice?.sales_details.map((item) => {
                  totalQty += Number(item.qty);
                  totalItems += 1;
                  const subtotalPrice = formatPrice(item.qty * item.price);
                  totalPrice += Number(subtotalPrice);
                  return (
                    <tr key={item.id}>
                    <td className='border-b border-gray-300 pr-2 text-xs font-bold break-words whitespace-normal' style={{paddingLeft: "0", fontSize:"12px", fontWeight: "bold"}}>{item?.product_info?.name}<br/><span className=''>{item?.product_info?.serial}</span></td>
                    <td className='border-b border-gray-300' style={{ fontSize:"12px", whiteSpace: 'nowrap', fontWeight: "bold" }}>{item.price}</td>
                    <td className='border-b border-gray-300' style={{ fontSize:"12px", whiteSpace: 'nowrap', fontWeight: "bold" }}>{item.qty}</td>
                    <td className='border-b border-gray-300' style={{ paddingRight: "0", fontSize:"12px", textAlign:"right", fontWeight: "bold" }}>{subtotalPrice}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ''}</td>
                  </tr>
                  );
                }
                )}
              </tbody>
            </table>
          </div>
          <div className="invoice-summary font-bold">
            <p style={{ fontSize:"12px"}} className='flex  text-black font-bold justify-between   border-b border-black mt-2 py-2'>
              <span className=''>TOTAL ITEMS</span>
              <span className=''>{totalItems}</span>
            </p>
            <p style={{ fontSize:"12px"}} className='flex text-black font-bold  justify-between   border-b border-black py-2'>
              <span className=''>SUB TOTAL</span>
              <span className=''>{totalPrice}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p style={{ fontSize:"12px"}} className='flex text-black font-bold  justify-between   border-b border-black py-2'>
              <span className=''>DISCOUNT</span>
              <span className=''>{formatPrice(invoice?.discount ? invoice?.discount : 0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p style={{ fontSize:"12px"}} className='flex text-black font-bold  justify-between    border-b border-black py-2'>
              <span className=''>Vat {''}</span>
              <span className=''>{invoice?.vat ? formatPrice(invoice?.vat) : 0.00}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p style={{ fontSize:"12px"}} className='flex text-black font-bold justify-between border-b border-black py-2'>
              <span className=''>Service Charge {''}</span>
              <span className=''>{invoice?.tax ? formatPrice(invoice?.tax) : 0.00}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
            <p style={{ fontSize:"12px"}} className='flex  text-black font-bold justify-between py-2'>
              <span className=''>Grand Totals</span>
              <span className=''>{formatPrice(invoiceCalculate(invoice).grandTotal)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
            </p>
          </div>
          <p style={{ fontSize:"12px"}} className='flex text-black font-bold  justify-between    '>
            <span className=''>Paid Amount</span>
            <span className=''>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          {invoice.multiple_payment?.length > 0 && (
            <div style={{ fontSize: "12px" }} className="text-black font-bold py-2">
              {invoice.multiple_payment.map((payment, index) => (
                <p
                  key={index}
                  style={{ fontSize: "12px" }}
                  className="flex text-black font-bold justify-between "
                >
                  <span>{payment.payment_type?.type_name || "N/A"}</span>
                  <span>
                    {payment.payment_amount
                      ? `${formatPrice(payment.payment_amount)}${invoiceSetting?.currency_info?.code || " BDT"}`
                      : "0 BDT"}
                  </span>
                </p>
              ))}
            </div>
          )}

          <p style={{ fontSize:"12px"}} className='flex  text-black font-bold justify-between '>
            <span className=''>Due Amount</span>
            <span className=''>{formatPrice(invoiceCalculate(invoice).dueAmount) }{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
          </p>
          <hr></hr>
          <div className='' style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', fontSize:"12px" }}>
            <p className='font-bold p-2 text-black '>In word: {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))} taka only</p>
            <p className="    p-2  uppercase text-black">You saved {formatPrice(invoice?.discount ? invoice?.discount : 0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'} in this purchase </p>
            <p className='    pl-2 pr-2  uppercase text-black'>Thanks for purchase</p>
            <div className="mt-2">
              <QRCode className="mx-auto w-[70px] object-contain" value={BASE_URL + '/invoice/' + invoice?.invoice_id} size={60} />
            </div>
            <p className='   text-[10px] pl-2 pr-2  uppercase mt-2 font-bold text-black'>A Product of SQUAD INNOVATORS </p>
            <a href="https://www.outletexpense.com" className='text-[9px] pl-2 pr-2 mt-1 font-bold text-black' target="_blank" rel="noopener noreferrer"  >www.outletexpense.com</a>

          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfHtmlFor3mm;