import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const WareHouseEditForm = (props) => {
  const {loadingCallback,setWarningMessage,refetch,setOpen,type,formData,setFormData,warehouseId} = props;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleEditHouse = () => {
      loadingCallback(true);
      axios.post(`${process.env.REACT_APP_API_URL}warehouses/${warehouseId}`,formData,{
        headers : {
          authorization : `Bearer ${token}`,
          'content--type' : 'application/json'
        }
      })
      .then(res => {
        console.log(res.data);
        if(res.data){
          loadingCallback(false)
          setOpen(false)
          refetch();
        }
      })
      .catch(error => {
        loadingCallback(false);
        navigate('/login');
        console.log(error);
      })
    }
  return (
    <div>
      <div>
            <div className="w-full relative">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    defaultValue={formData.name || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      name: e.target.value
                    })}
                    className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
                  />
                </div>
                <div className="w-full relative mt-2">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    defaultValue={formData.email || ''}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        email: e.target.value
                      });
                    }}
                    className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
                  />
                </div>

                <div className="w-full relative">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    defaultValue={formData.phone || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      phone: e.target.value
                    })}
                    className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
                  />
                </div>
                <div className="w-full relative mt-2">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                    Address
                  </label>
                  <textarea
                    type="text"
                    name="address"
                    defaultValue={formData.address || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      address: e.target.value
                    })}
                    placeholder="Text"
                    className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full  h-20 pt-2 rounded-xl bg-white resize-none"
                  />
                   <button onClick={() => handleEditHouse()} className="cursor-pointer w-full  bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl mt-4 text-[#fff] font-lato font-semibold text-lg">Save</button>
                </div>
    </div>
    </div>
  );
};

export default WareHouseEditForm;