import React from "react";
import "./index.css";
// import outletbannner from "../../../../assets/registration/outletbanner.svg"

// import outletlogo from "../../../../assets/registration/outletlogo.svg"
// import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from "antd";
import { Link } from "react-router-dom";
import {
  validateData
} from '../../../../utils/Common';

const BASE_URL = process.env.REACT_APP_ROOT_URL;

function StoreInfo(props) {
  const onFinish = (values) => {
    console.log("Success:", values);
    localStorage.setItem("outletname", values.outletname);
    localStorage.setItem("ownername", values.ownername);
    props.continueSubmit();
  };
  // const onFinishFailed = (errorInfo) => {
  //     console.log('Failed:', errorInfo);
  // };
  return (
    <section style={{ background: "#000" }}>
      <div className="store-container">
        <div className="store-left">
          <img
            src={`${BASE_URL}/assets/registration/webp/outletbanner.webp`}
            alt="storeimage"
          />
        </div>
        <div className="store-right">
          <div className="store-right-card">
            <div className="store-log-img">
              <img
                src={`${BASE_URL}/assets/registration/webp/outletlogo.webp`}
                alt="logo"
              />
              <h1>Create Account</h1>
              {/* <p>your expense for you.</p> */}
            </div>

            <div className="hr"></div>
            <div className="store-desctitle">
              <p>
                Provide us your & <br></br> Store Name
              </p>
            </div>
            <div className="store-description">
              <p>
                To create a store for you, and keep<br></br>  track your expense for you.
              </p>
            </div>
            <div className="flex flex-col">
            <div className="store-form-main">
              <Form
                name="normal_store"
                className="store-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="ownername"
                  rules={[
                    {
                      validator: (_, value) => {
                        const errorMessage = validateData(value, 'Owner name');
                        if (errorMessage) {
                          return Promise.reject(errorMessage);
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder="Owner name" type="text" />
                </Form.Item>
                <Form.Item
                  name="outletname"
                  rules={[
                    {
                      validator: (_, value) => {
                        const errorMessage = validateData(value, 'Owner name');
                        if (errorMessage) {
                          return Promise.reject(errorMessage);
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder="Outlet name" type="text" />
                </Form.Item>

                <Form.Item>
                  <Button htmlType="submit" className="store-form-button">
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="storeform-console">
              <p>
                Already have an account?
                <span style={{ color: "#034AFF", textDecoration: "underline" }}>
                  <Link to="/login"> Login</Link>
                </span>
              </p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StoreInfo;
