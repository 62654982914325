import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/context";

const NavItem = ({
  Menu,
  index,
  onLogout,
  open,
}) => {
  const {
    state,
    updateActiveMenu
} = useContext(AppContext);

const {activeMenu} = state;

  const [isDropdownOpen, setIsDropdownOpen] = useState(
    activeMenu == Menu.title
  );
  const toggleDropdown = (title) => {
    updateActiveMenu(title);
    setIsDropdownOpen((val) => !val);
  };
  return (
    <Link
      to={`${!Menu?.dropDown ? Menu?.src : ""}`}
      key={index}
      className={`block px-[0px] text-[#202530] ${
        !open && "w-[54px] mx-auto"
      } !hover:bg-white xl:px-[20px] parentLink`}
      style={{ textAlign: "center", marginLeft: "auto" }}
      // onClick={Menu?.dropDown ? ()=>toggleDropdown(Menu.title) : ""}
      onClick={()=>toggleDropdown(Menu.title)}
    >
      <li
        key={index}
        // remove position sticky after adding space and line between menu items and logo top.
        className={`!flex justify-center sticky transform duration-200 z-10 ${
          Menu.title === "Sign Out" ? "li-signout" : ""
        } items-center gap-[6px] lg:gap-[12px] ${!open && "mt-4"} ${
          index === 0 && "bg-light-white"
        }
        ${ Menu.src === location.pathname && "active"}
        ${Menu.title == activeMenu && Menu.dropDown? "dropdown-parent !bg-[#ECF2FF] !text-[#407BFF] hover:!text-[#407BFF] active:!text-[#407BFF] ":""}
        `}
         // ${Menu.title == activeMenu && Menu.dropDown? "!bg-[#ECF2FF] !text-[#407BFF] hover:!text-[#407BFF]":""}
        onClick={Menu.src || Menu.dropDown ? null : onLogout}
      >
        <i
          className={`fa-solid ${Menu.icon} icon w-[30px] h-[30px] ${
            Menu.title === "Sign Out" ? "icon-red" : ""
          }  flex items-center justify-center
          ${open? "small-icon":""}`}
          // onClick={() => toggleDropdown(Menu.title)}
        ></i>
        <a
          href={Menu.src || ""}
          className={`flex-1 ${!open && "hidden"}`}
        >
          <span
            className={`flex justify-between items-center ${
              Menu.title === "Sign Out" ? "title-red" : ""
            }`}
          >
            {Menu.title}
            {Menu.dropDown && (
              <div className="relative  text-left flex justify-start">
                <button
                  // onClick={() => toggleDropdown(Menu.title)}
                  type="button"
                  className="text-gray-600 relative focus:outline-none  font-medium rounded-lg text-sm py-2.5 mr-4 text-center inline-flex items-center  "
                >
                  <div
                    className={`plus absolute text-base font-semibold w-4 h-4 flex justify-center items-center top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] ${
                      isDropdownOpen ? "opacity-0" : "opacity-100"
                    } text-xl`}
                  >
                    +
                  </div>
                  <div
                    className={`minus absolute !text-3xl w-4 h-4 flex justify-center items-center top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] ${
                      isDropdownOpen ? "opacity-100" : "opacity-0"
                    } text-xl`}
                  >
                    -
                  </div>
                </button>
              </div>
            )}
          </span>
        </a>
      </li>
      {isDropdownOpen && Menu?.dropDown ? (
        <ul>
          {Menu.dropDown.map((nestedMenu, idx) => (
            <Link
              to={`${nestedMenu.src}`}
              key={nestedMenu.title}
              className={`block px-[0px] text-[#202530] ${
                !open && "w-[54px] mx-auto"
              } !hover:bg-white pl-[20px] parentLink`}
              style={{ textAlign: "center", marginLeft: "auto" }}
              onClick={() => {
                toggleDropdown(Menu.title)
                // window.scrollTo({ top: 0, behavior: "smooth" })
              }}
            >
              <li
                key={idx}
                // remove position sticky after adding space and line between menu items and logo top.
                className={`!flex justify-center sticky z-10 items-center gap-[12px] ${
                  !open && "mt-4"
                } ${idx === 0 && "bg-light-white"} ${
                  nestedMenu.src === location.pathname && "active"
                }`}
              >
                <i
                  className={`fa-solid ${nestedMenu.icon} icon w-[30px] h-[30px] flex items-center justify-center`}
                  onClick={() => toggleDropdown(Menu.title)}
                ></i>
                <a
                  href={nestedMenu.src || ""}
                  className={`flex-1 ${!open && "hidden"} duration-200`}
                  // onClick={() => toggleDropdown(Menu.title)}
                >
                  <span className={`flex justify-between items-center `}>
                    {nestedMenu.title}
                  </span>
                </a>
              </li>
            </Link>
          ))}
        </ul>
      ) : (
        ""
      )}

      <span className={`${!open && "hidden"} cursor-auto block`}>
        {Menu.gap && (
          <div className="font-nunito mt-7 font-semibold mb-4 text-xs text-[#2D3748] text-left">
            ACCOUNT
            <br />
          </div>
        )}
      </span>
    </Link>
  );
};

export default NavItem;
