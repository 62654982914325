// import React from 'react';

// const AccountingHistoryPDFDetails = () => {
//   return (
//     <div>

//     </div>
//   );
// };

// export default AccountingHistoryPDFDetails;

import React from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const AccountingHistoryPDFDetails = (props) => {
  const {
    transactionData,
    paymentList,
    paymentAccountList,
    activeTab,
    index
} = props;


const paymentData = paymentList.data ? paymentList.data : paymentList;
const paymentAccountData = paymentAccountList.data ? paymentAccountList.data : paymentAccountList;

  const createdDate = new Date(transactionData?.date);

  return (
    <>

     <div className="border-b-[1px] text-[.70rem] border-solid px-3 last:border-none border-[#E7EBF4] -pt-[4px] pb-2 font-nunito grid grid-cols-10 items-start">

     <div className="col-span-1">
        <h1 className=" text-[#000] font-[600]">{ (index+1)<10 ? "0" + (index+1) :  index + 1}</h1>
      </div>
     <div className="col-span-1">
          <h1 className=" text-[#000] font-[600]">{formatDate(transactionData?.created_at)}</h1>
        {/* <p className=" text-[#87889E] font-[500] text-sm">{timeDiff(createdDate)}</p> */}
      </div>

      <div className="text-[#000] font-[500] text-center col-span-2">
        {
          transactionData?.multiple_payments.map((item)=>(
            item?.payment_type?.type_name =='cash' ?
            <div>
              <p className='text-center'>{item?.payment_type?.type_name}</p>
            </div>
            :
            <div>
              <p className='whitespace-nowrap text-center'>{item?.payment_type?.type_name}</p>
              <p className='font-semibold whitespace-nowrap text-center'>{item?.payment_type_category?.payment_category_name}</p>
              <p className='text-center'>{item?.payment_type_category?.account_number}</p>
            </div>
          ))
        }
      </div>

      <div className="text-[#000]col-span-1  font-semibold text-center">
        <p className="text-[#000] font-[500] text-md">{transactionData?.transaction_category == "in" ? "Debit" : "Credit" }</p>
      </div>

      <div className="text-[#000] col-span-1 font-[500] text-center">
        <p className="text-[#000] font-[500] text-md">{transactionData?.transaction_type?.head_name}</p>
      </div>

      <div className="text-[#000] col-span-2 font-[500] text-center">
        <p className="text-[#000] font-[500] text-center">{transactionData?.transaction_description}</p>
      </div>

      {/* <div className=" text-[#000] w-[250px] font-[500] text-center ">{transactionData?.invoice}</div> */}

      <div className=" text-[#000] col-span-1 font-[500] text-right">{transactionData?.transaction_name}</div>
      <div className=" text-[#000] col-span-1 font-[500] text-right">{Number.parseFloat(transactionData?.transaction_amount).toFixed(2)}</div>
   </div>
    </>
  );
};
AccountingHistoryPDFDetails.defaultProps = { className: null };
export default AccountingHistoryPDFDetails;