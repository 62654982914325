import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from './../../utils/Modal';
import Swal from "sweetalert2";

const ExpenseListItem = ({expense, loadingCallback}) => {
  const navigate = useNavigate()
  const [showViewModal, setShowViewModal] = useState(false);
  return (
    <div>
      <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start">
        <div>
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="font-nunito text-[14px]">
              <div className="font-[500] text-[#000232]">#{expense.id}</div>
              <div className="font-[700] text-[#87889E]">{expense?.transaction_name}</div>
            </div>
          </div>
        </div>
        <div className="text-center">{expense.expense_date}</div>
        <div className="text-center">{expense.transaction_amount || 0 }  {" BDT"}</div>
        <div className="text-center">{
          expense?.multiple_payments?.map((item,idx)=>(
            item?.payment_type?.type_name =='cash' ?
            <div key={idx}>
              <p className='text-center'>{item?.payment_type?.type_name}</p>
            </div>
            :
            <div key={idx}>
              <p className='whitespace-nowrap text-center'>{item?.payment_type?.type_name}</p>
              <p className='font-semibold whitespace-nowrap text-center'>{item?.payment_type_category?.payment_category_name}</p>
              <p className='text-center'>{item?.payment_type_category?.expense_number}</p>
            </div>
          ))
        }</div>
        <div className={`text-center `}>{expense?.expense_types?.expense_name}</div>
        <div className={`text-center ${expense?.expense_types?.transaction_category === "In" ? 'text-[#0B8B00]':"text-[#FF0303]"}`}>{expense?.expense_types?.transaction_category}</div>
        <div className="flex gap-2  text-xs justify-end">
        <label
          htmlFor="viewChartOfAcc_modal"
          onClick={()=>setShowViewModal(val=>!val)}

          className="border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[6px] py-1"
        >
         VIEW
        </label>
        <label
          htmlFor="editAccTxn_modal"
          onClick={()=> Swal.fire("Edit option coming soon")}
          className="border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-[5px] px-[7px] py-1"
        >
         EDIT
        </label>
        </div>
      </div>
      {showViewModal ? (
        <Modal
        formData={expense}
          title={"Details"}
          formId="viewChartOfAcc_modal"
          isOpen={showViewModal}
          setOpen={setShowViewModal}
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="viewFinance"
          selectType={"expense"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ExpenseListItem;