import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import DueReportHistory from './DueReportHistory';
// import LogoAlignLeft from './bigLogo.png';

// const printableComponent = () => {

// }
const user = JSON.parse(localStorage.getItem('user'));

const DueReportPDFDetails = ({reports,componentRef,checkedAll,handleCheckboxChange,total_due}) => {

  // const today = new Date().toISOString().split('T')[0];
  const today = new Date().toLocaleDateString('en-us',{
    month : 'numeric',
    day : 'numeric',
    year : 'numeric'
  });
  const currentTime = new Date().toLocaleTimeString('en-us',{
    hour : '2-digit',
    minute : '2-digit',
    hour12 : true

  });


  return (
    <div ref={componentRef} className='px-8 mt-12'>

      <div className='flex justify-between mb-5'>
        <p className='font-extrabold'>{currentTime}</p>
        <p className='font-extrabold'>Print</p>
      </div>

      <table class="w-full border border-gray-300">
      <tr>
          <th colspan="3" class="p-4 border-b border-gray-300">
            <h1 class="text-lg font-bold text-center">Due Report</h1>
          </th>
        </tr>
      <tbody>
        <tr>
          <td class="border border-gray-300 p-4">
            <p className='font-normal'>Shop Name: {user?.invoice_settings.shop_name}</p>
            <p className='font-normal'>Email: {user?.email}</p>
            <p className='font-normal'>Address: {user?.address}</p>
          </td>
          <td class="border border-gray-300 p-4">
            <p className='font-normal'>Date: {today}</p>
            <p className='font-normal'>Mobile: {user?.phone}</p>
          </td>
          <td class="border border-gray-300 p-4 text-center">
          <img src={user?.invoice_settings?.shop_logo} alt="Company Logo" class="w-32 h-auto mx-auto"/>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="border border-gray-300 text-center font-semibold p-4">
            From: N/A to N/A
          </td>
        </tr>
      </tbody>

    </table>

      <div className=" grid grid-cols-6 text-left text-[14px] mt-3  font-nunito font-bold text-[#000232]  border-b-[1px] border-[#E7EBF4] border-l border-r bg-blue-500">
        <div className="text-left  text-white border-r">No</div>
        <div className="text-left  text-white border-r">Name</div>
        <div className="text-left  text-white border-r">Paid Amount</div>
        <div className="text-left  text-white border-r">Total Amount</div>
        <div className="text-left  text-white border-r">Due Amount</div>
        <div className="text-left  text-white border-r">Invoice Id</div>
      </div>
    {
      reports.map((report,index) => {
        return (
          <div  className="grid grid-cols-6 md:gap-5  gap-2 items-center justify-start font-nunito font-[500] text-[14px] text-[#000232] border-t-[1px] border-solid border-[#E7EBF4] py-[8px] ">
        {/* <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start"> */}
                    <div>
                      <p>{index+1}</p>
                    </div>
                  <div>
                    <div className="">
                      {/* <div className="font-nunito text-[14px]"> */}
                        <div className="font-[500] text-[#000232]">#{report?.name}</div>
                        <div className="font-[700] text-[#87889E]">{report?.id}</div>
                      {/* </div> */}
                    </div>
                  </div>
          {/* <div className="text-center">{expense.catogory_name}</div> */}
                    <div >
                      <p className='text-left'>{report?.paid_amount?.toFixed(2)}</p>
                    </div>
                    <div >
                      <p className='text-left'>{report?.total_amount?.toFixed(2)}</p>
                    </div>
                    <div >
                      <p className='text-left'>{report?.due?.toFixed(2)}</p>
                    </div>
                    <div >
                      <p className='text-left'>{report?.invoice_id}</p>
                    </div>

        </div>
        )
      })
    }
    <div className="flex items-center justify-end mr-10 mt-5">
      <p className="font-bold text-[#000232] mr-2">Total Due:</p>
      <p className="text-start">{total_due?.toFixed(2)}</p>
    </div>
  </div>
  );
};

export default DueReportPDFDetails;