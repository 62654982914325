import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import RecentInvoiceItem from '../../components/Invoice/RecentInvoiceItem.js';
import { timeDiff,formatPrice,invoiceCalculate, invoiceCalculateWithCurrentVatTax } from '../../utils/Common.js';
import Pagination from '../../utils/pagination.js'; // Adjust the path accordingly
import { AppContext } from '../../context/context.js';
import ContentLayout from '../../layout/ContentLayout/index.jsx';
import { get, post } from '../../services/apiService.js';
import HoldInvoiceItem from '../../components/Invoice/HoldInvoiceItem.js';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_ROOT_URL;

const HoldWholeSaleInvoiceListPage = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const { state, isLoading, updateWholesaleHoldInvoiceList } = useContext(AppContext);
  const { wholesaleHoldInvoiceList, invoiceSetting } = state;
  const token = localStorage.getItem('token');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  //----------------
const location = useLocation();
const [hasPermission, setHasPermission] = useState(false);
const [isViewActive, setIsViewActive] = useState(false);

useEffect(() => {
  const employeeApiResponse = localStorage.getItem("employee");
  if (!employeeApiResponse) {
    setHasPermission(true);
    return;
  }
    const employeeData = JSON.parse(employeeApiResponse);
    const wholesaleFeature = employeeData.role.features.find(
      (feature) => feature.name === "Wholesale" && feature.status === 1
    );
    if (wholesaleFeature && location.pathname === "/hold-wholesale-invoice-list") {
      const viewWholesaleOptionActive = wholesaleFeature.feature_options.find(
        (option) => option.name === "View" && option.status === 1
      );
      if (viewWholesaleOptionActive) {
        setIsViewActive(true);
        setHasPermission(true);
      } else {
        setIsViewActive(false);
        setHasPermission(false);
      }
    } else {
      setHasPermission(false);
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
  }
, []);
//-----------------
  useEffect(() => {
    if ((isViewActive && hasPermission) || !localStorage.getItem("employee")) {
      getInvoicelist(currentPage, searchKeyword);
    }
  }, [currentPage, searchKeyword,isViewActive,hasPermission]);
  const getInvoicelist = (page, searchKeyword) => {
    if (searchKeyword !== "") {
      const reqData = {
        keyword: searchKeyword,
        sales_type:'wholesale'
      };
      post(`search-hold-list?page=${page}&limit=${perPage}`, reqData, token)
        .then((response) => {

          if (response.status === 200) {
            const listData = response.data.data;
            setInvoiceData(response.data)

            const transformedInvoiceArray = listData
              .filter(item => item.status === 0)
              .map(item => ({
                id: item.id,
                icon: <RiMoneyDollarCircleLine />,
                name: item.invoice_id,
                sname: item?.wholeseller?.name,
                product: item.sales_details
                  ? item.sales_details
                    .filter(sale => sale.product_info)
                    .map(sale => sale.product_info.name)
                    .join(', ')
                  : '',
                  price: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculateWithCurrentVatTax(item, invoiceSetting).grandTotal) + ' ' + invoiceSetting.currency_info.code : formatPrice(invoiceCalculateWithCurrentVatTax(item, invoiceSetting).grandTotal) + ' BDT',
                  due: invoiceSetting?.currency_info?.code ? formatPrice((invoiceCalculateWithCurrentVatTax(item, invoiceSetting).dueAmount))+ ' ' + invoiceSetting.currency_info.code : formatPrice((invoiceCalculateWithCurrentVatTax(item, invoiceSetting).dueAmount)) + ' BDT',
                  time: timeDiff(new Date(item.updated_at)),
                date: item.updated_at,
                colors: "#00A3EE",
                status: item.status ? item.status : null
              }));
            updateWholesaleHoldInvoiceList(transformedInvoiceArray)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      get(`hold-list?sales_type=wholesale&page=${page}&limit=${perPage}`, token)
        .then((response) => {
          if (response.data.data) {
            const listData = response.data.data;
            setInvoiceData(response.data)

            const transformedInvoiceArray = listData.map(item => ({
              id: item.id,
              icon: <RiMoneyDollarCircleLine />,
              name: item.invoice_id,
              sname: item?.wholeseller ? item.wholeseller.name : item.customer_name,
              product: item.sales_details
                ? item.sales_details
                  .filter(sale => sale.product_info)
                  .map(sale => sale.product_info.name)
                  .join(', ')
                : '',
              price: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculateWithCurrentVatTax(item, invoiceSetting).grandTotal) + ' ' + invoiceSetting.currency_info.code : formatPrice(invoiceCalculateWithCurrentVatTax(item, invoiceSetting).grandTotal) + ' BDT',
              due: invoiceSetting?.currency_info?.code ? formatPrice((invoiceCalculateWithCurrentVatTax(item, invoiceSetting).dueAmount))+ ' ' + invoiceSetting.currency_info.code : formatPrice((invoiceCalculateWithCurrentVatTax(item, invoiceSetting).dueAmount)) + ' BDT',
              time: timeDiff(new Date(item.updated_at)),
              date: item.updated_at,
              colors: "#00A3EE",
              // status added
              status: item.status
            }));
            updateWholesaleHoldInvoiceList(transformedInvoiceArray)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const shouldRenderPagination = invoiceData.total > perPage;
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
      setLoading(loading);
  };

  const showModalBeforeDelete = (id, index) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteData(id, index);
        }
    });
}
const deleteData = (id, index) => {
    loadingCallback(true);
    const reqData = { invoice_id: id };
    // Use your API service functions to make API requests here
    get(`delete-sale/${id}`, token)
        .then((response) => {
            loadingCallback(false);
            if (response.success) {
                console.log("invoice deleted");
                let cartProducts = [...wholesaleHoldInvoiceList];

                cartProducts.splice(index, 1);
                updateWholesaleHoldInvoiceList(cartProducts);

            } else {
                console.log("booking delete failed");
            }
        })
        .catch((error) => {
            loadingCallback(false);
            if (error?.response?.status === 401) {
                localStorage.clear();
                localStorage.setItem('token', false);
                // Clear session and local storage
                localStorage.setItem('isPinVerify', false);

                navigate('/login');
            }
            console.log(error);
        });
  }
  console.log("hold wholesale", invoiceData.total, shouldRenderPagination);

  return (
    <>
      <ContentLayout>
        <div className="main-content recentTableContent">
          <div className="flex flex-col w-full">

            <div className='flex flex-col md:flex-row justify-between mb-4'>
              <div>
                <h1 className='text-[#000232] text-[22px] font-[600] font-nunito mb-2'>Hold Invoice List (wholesale)</h1>
              </div>

              <div className='flex gap-2 justify-center'>
                <div className="flex relative w-full">
                  <input
                    autoFocus
                    type="text"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                    placeholder="Search Invoice"
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />

                  <div className="absolute top-[37%] left-[10px]">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>

                  <div className="absolute top-[36%] right-[10px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z" fill="#102048" />
                      <path d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z" fill="#102048" />
                      <path d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z" fill="#102048" />
                      <path d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z" fill="#102048" />
                      <path d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z" fill="#102048" />
                      <path d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z" fill="#102048" />
                      <path d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z" fill="#102048" />
                      <path d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z" fill="#102048" />
                      <path d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z" fill="#102048" />
                    </svg>
                  </div>
                </div>
              </div>

            </div>

            {(isViewActive && hasPermission) || !localStorage.getItem("employee") ? (
              <div className="table-conatiner">

                {/* <div className='grid grid-cols-4 sm:grid-cols-5'> */}
                <div className='grid grid-cols-5 sm:grid-cols-6'>
                  <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px]'>Name</div>
                  <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px] text-center'>Total Amount</div>
                  <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px] text-center'>Due</div>
                  <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px] text-center hidden sm:block'>Date/Time</div>
                  <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px] text-center'>Status</div>
                  <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px] text-center'>Action</div>
                </div>

                {wholesaleHoldInvoiceList
                  .filter(invoice => invoice.status === 0)
                  .map((filteredInvoice,index) => (
                    <HoldInvoiceItem
                    key={filteredInvoice.id}
                    type="wholesale"
                    index={index}
                    showModalBeforeDelete={showModalBeforeDelete}
                    loadingCallback={loadingCallback}
                    navigate={navigate}
                    setSearchKeyword={setSearchKeyword}
                    searchKeyword={searchKeyword}
                    shouldRenderPagination={shouldRenderPagination}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                    loading={loading}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    invoice={filteredInvoice} />
                  ))}

                <div className="hrtable"></div>
                {/* Pagination component */}
                {shouldRenderPagination && (
                  // {invoiceData.length > 2 && (
                  <Pagination
                    // currentPage={invoiceList.current_page}
                    lastPage={invoiceData.last_page}
                    currentPage={currentPage}
                    //lastPage={10}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default HoldWholeSaleInvoiceListPage;