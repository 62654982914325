import React, { useState } from 'react';
import "./CategorySaleGraph.css";

import ReactApexChart from 'react-apexcharts';

const CategorySaleGraph = ({ revenueData, allFetchedData,chartName}) => {


  return (
    <>
      <div className="chart-info w-full">
        <div className="chart-info-title">
          <p className='text-[24px]'>{chartName}</p>
        </div>
      </div>
      <div id="category-chart">
        <ReactApexChart options={revenueData.options} series={revenueData.series} type="area" height={300} />
      </div>
    </>
  );
};

export default CategorySaleGraph;
