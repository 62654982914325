import { useEffect, useState } from "react";
import axios from "axios";

const AddDesignationForm = (props) => {
  const {
    loadingCallback,
    navigate,
    setShowModal,
    isOpen,
    title,
    setWarningMessage,
    type,
    formData,
    setFormData,
    refetch
  } = props;
  const token = localStorage.getItem("token");

  const handleSubmit = () => {
    loadingCallback(true);
    console.log("form data",formData)
    axios.post(`${process.env.REACT_APP_API_URL}save-designation`, formData, {
      headers:{
        authorization: `Bearer ${token}`,
        "content-type":"application/json",
      }
    }).then((res) => {
        loadingCallback(false);
        if (res.data.success) {
          console.log(res)
        refetch();
        }
      }).catch(err=>{
        loadingCallback(false)
        console.log(err)
      })
    setShowModal(val=>!val)
  };

  return(
    <div className="duration-500">
      <div className="w-full relative">
        <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
          Name
        </label>
        <input
          type="text"
          name="designation_name"
          placeholder="Name"
          onChange={(e) => setFormData({
            ...formData,
            name: e.target.value
          })}
          className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
        />
      </div>
      <div className="w-full relative mt-2">
        <label className="absolute font-nunito text-xs text-[#102048]  -top-[8px] left-[12px] bg-white px-1 font-semibold">
          Description
        </label>
        <textarea
          type="text"
          name="description"
          onChange={(e) => setFormData({
            ...formData,
            description: e.target.value
          })}
          placeholder="Text"
          className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full  h-20 pt-2 rounded-xl bg-white resize-none"
        />
      </div>
      <div>
        <button
          onClick={()=>handleSubmit()}
          className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
        >
          Done
        </button>
      </div>
    </div>
  );

}

export default AddDesignationForm;