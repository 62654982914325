import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import AppProvider from "./context/context.js";
import FlashMessageProvider from "./context/FlashMessageContext.js";
import routeConfiguration from "./routeConfiguration";
import RouteComponent from "./Routes";
import { Provider } from "react-redux";
import { store } from "./app/store.js";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <AppProvider>
          <FlashMessageProvider>
            <HelmetProvider>
              <RouteComponent routes={routeConfiguration()} />
            </HelmetProvider>
          </FlashMessageProvider>
        </AppProvider>
      </Provider>
    </div>
  );
}

export default App;
