import React from 'react';
import  { formatDate, formatPrice, timeDiff } from './../../utils/Common'
const  ProductStockReportItem = (props) => {
  const { categoryData,
    tabActive,
    shouldRenderPagination,
    currentPage,
    onPageChange,
    loading,
    index
} = props;



const indexNumber = index+1;
  return (
    <>
   {tabActive &&
   <div className="border-b-[1px] text-sm border-solid last:border-none border-[#E7EBF4] pt-2 pb-[13px] font-nunito grid grid-cols-4">
     <div className="text-[#000] font-[500] text-center col-span-1">
       {indexNumber}
     </div>
     {/* category name */}
     <div className="text-[#000] font-[500] text-center col-span-2">
        {categoryData?.name}
     </div>
     {/* Total */}
     <div className="pr-3 text-[#000] font-[500] text-center col-span-1">{categoryData?.current_stock ?? 0}</div>
   </div>}

    </>
  );
};
ProductStockReportItem.defaultProps = { className: null };
export default ProductStockReportItem;