import React, { useEffect, useState,useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrowLeft from "../../assets/images/arrow-left.png";
import axios from "axios";
import { useFlashMessage } from '../../context/FlashMessageContext';
import { get, post } from "../../services/apiService";
import Resizer from "react-image-file-resizer";
import { ToastContainer, toast } from 'react-toastify';

import ContentLayout from "../../layout/ContentLayout";
import RolePermissionEditForm from "../../forms/RolePermissionEditForm";
import { AppContext } from "../../context/context";
import { FlashMessage } from "../../utils/FlashMessage";
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

const RoleEditPage = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage();

  const [initialFormData, setInitialFormData] = useState({});
  const [formData, setFormData] = useState({
    role_name: '',
    role_description: '',
    features: [], // Make sure features is initialized as an empty array
  });

  //---------------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");
    if (!employeeApiResponse) {
      setHasPermission(true);
      return;
    }
    if (employeeApiResponse) {
      const employeeData = JSON.parse(employeeApiResponse);
      const hrmFeature = employeeData.role.features.find(
        (feature) => feature.name === "HRM"
      );
      if (hrmFeature && hrmFeature.status === 1) {
        const editHrmOptionActive = hrmFeature.feature_options.find(
          (option) => option.name === "Edit" && option.status === 1
        );
        if (editHrmOptionActive && location.pathname === "role-edit/:id") {
          setHasPermission(true);
          return;
        }
      }
      setHasPermission(false);
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
  }, [navigate, location]);
  //---------------------

  useEffect(() => {
    if (id) {
      if(hasPermission){
      getRoleFeatureOptionsList(id);
      }
    }
  }, [id,hasPermission]);

  const getRoleFeatureOptionsList = (id) => {
    loadingCallback(true);
    get(`role-feature-option-list`, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          console.log("full response", response);
          console.log("role+feature+option list", response.data);
          const role = response.data.find(role => role.id == id);
          if (role) {
            const formInitialData = {
              role_name: role.name,
              role_description: role.description,
              features: role.features || []
            };
            console.log("data before change", formInitialData)
            setInitialFormData (formInitialData);
            setFormData(formInitialData);
          }
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log( error);
        localStorage.clear();
        localStorage.setItem("token", false);
        // Clear session and local storage
        localStorage.setItem("isPinVerify", false);

        navigate("/login");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    console.log("input form data", formData)
  };

const handleCheckboxChange = (e, feature, option = null) => {
  const { checked } = e.target;

  setFormData((prevFormData) => {
    const updatedFeatures = prevFormData.features.map((f) => {
      if (f.id === feature.id) {
        // If an option is passed, toggle the feature option
        if (option) {
          const updatedOptions = f.feature_options.map((opt) => {
            if (opt.id === option.id) {
              return {
                ...opt,
                status: checked ? 1 : 0, // Toggle the option status
              };
            }
            return opt;
          });

          // If an option is checked and the feature is not checked, make the feature checked
          return {
            ...f,
            status: checked ? 1 : f.status, // Turn feature status true if any option is checked
            feature_options: updatedOptions,
          };
        }

        // If no option is passed, toggle the feature itself
        const updatedFeature = {
          ...f,
          status: checked ? 1 : 0, // Toggle the feature status
          feature_options: f.feature_options.map((opt) => ({
            ...opt,
            status: checked ? opt.status : 0, // Set all options to false if feature is unchecked
          })),
        };

        return updatedFeature;
      }
      return f;
    });

    return {
      ...prevFormData,
      features: updatedFeatures,
    };
  });
};


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('FormData before submission:', formData);
    loadingCallback(true);

    const payload = {
      id,
      name: formData.role_name,
      description: formData.role_description,
      features: formData.features.length ? formData.features : [],
    };

    axios.post(`${process.env.REACT_APP_API_URL}update-role`, payload, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      loadingCallback(false);
      if (response.data.success) {
        toast.success("Role updated successfully");
        navigate('/role');
      } else {
        toast.error("Failed to update role: " + response.data.message);
      }
    })
    .catch((error) => {
      loadingCallback(false);
      console.error(error);
      toast.error("An error occurred: " + error.message);
    });
  };

  return(
    <ContentLayout>
      <div className="rounded-[16px] bg-white px-[35px] py-[30px] mt-[30px]">
        <div className="flex flex-row gap-2 items-center mb-5">
          <Link className="block w-[24px]" to={`/role`}>
            <img src={arrowLeft} className="w-[100%]" />
          </Link>
          <h1 className='text-[#000232] text-[22px] font-[600] font-lato '>Edit Role Permission</h1>
        </div>
        {warningMessage && (
          <div className="warning-message">
            something wrong!
          </div>
        )}
        {flashMessage && (
          <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
            {flashMessage.text}
          </div>
        )}
        {loading && (
          <div className="custom-loader-logo">
            <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
          </div>
        )}
        <RolePermissionEditForm
          formData={formData}
          initialFormData={initialFormData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleCheckboxChange={handleCheckboxChange}
          setFormData={setFormData}
        />
      </div>

    </ContentLayout>
  );

}

export default RoleEditPage;