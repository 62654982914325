import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  posDeliveryFormData: {
    deliveryName: "",
    deliveryId: "",
    companyName: "",
    companyId: "",
    customerName: "",
    customerAddress: "",
    customerPhone: "",
    deliveryFee: "",
  },
};

export const newSalesBillingSlicer = createSlice({
  name: "newSalesBilling",
  initialState,
  reducers: {
    setPosDeliveryFormData: (state, action)=> {
      state.posDeliveryFormData = action.payload;
    }
  },
});

export const newSalesBillingActions = newSalesBillingSlicer.actions;
