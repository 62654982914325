import React, { useState } from 'react';

const Input = ({ label, className, name, placeholder, onChange, value, error,setFormErrors=null }) => {


  const errorClass = error ? " border-1 border-red-500" : " border ";

  return (
    <div className={`${className} relative`}>
      <input
        name={name}
        value={value ? value : ''}
        placeholder={placeholder}
        className={`w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pt-3 pl-[15px] pb-2.5 text-sm rounded-[10px] border-[#C1CFEF] ${className} ${errorClass}`}
        onChange={(event) => {
          onChange(event);
          if (setFormErrors) {
            // Clear the error message when the input value changes
            setFormErrors({name:null})
          }
        }}
      ></input>
      {error && <p className="error-message text-sm text-red-500">{error}</p>}
      <label className="absolute font-nunito text-[12px] text-[#102048] -top-[11px] left-[12px] bg-white px-1 font-semibold">
        {label}
      </label>
    </div>
  );
};


Input.defaultProps = {
  className: ''
};
export default Input;
