import React from 'react'
import { Progress } from 'antd';
import "./index.css"
import RevenueGraph from './revenuegraph/Index';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    ResponsiveContainer
} from "recharts";
const BASE_URL = process.env.REACT_APP_ROOT_URL;



const CustomBar = (props) => {
    const { x, y, width, height } = props;


    return (
        <g>
            <rect
                x={x}
                y={y}
                width={width}
                height={height - 10}
                fill={props.fill}
                rx={5}
            />
        </g>
    );
};
function GraphChart({ type, expenseData, revenueData, allFetchedData, invoiceSetting }) {
    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-[30px] flex-shrink-0">
                <div className="graphchart-graph-progress bg-[#FFF] rounded-[25px] px-[30px] py-[28px] flex-shrink-0">
                    <div className="graphchart-graph-progress-graph">
                        <h1 className='text-pink-700 font-bold  text-2xl mb-3 md:hidden'>Sales</h1>
                        <div className='balance-chart'>
                            <ResponsiveContainer width="100%" aspect={3}>
                                <BarChart
                                    width={400}
                                    height={200}
                                    style={{
                                        background: "rgb(244,248,255)",
                                        background: "linear-gradient(90deg, rgba(244,248,255,1) 0%, rgba(229,237,255,1) 100%)", borderRadius: "8px"
                                    }}
                                    data={expenseData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                >
                                    <XAxis />
                                    <YAxis dataKey="uv" />
                                    <Bar dataKey="" />
                                    <Bar dataKey="pv" fill="black" shape={<CustomBar />} />
                                    <Bar dataKey="" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div className="graphchart-graph-progress-info md:max-w-510">

                        <div className="graphchart-graph-progress-container w-full">
                            <div className="progres-graph-card-1">
                                <div className="graphchart-graph-progress-container-title">
                                    <img src={`${BASE_URL}/assets/dashboard/balanceicon.svg`} alt='icon' />
                                    <p>Balance</p>
                                </div>
                                <div className="graphchart-graph-progress-container-mainprogress">

                                    {/* <p>{allFetchedData.balance} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</p> */}
                                    <p>{allFetchedData?.balance} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</p>
                                    <Progress percent={50} showInfo={false} size="small" />
                                    <div className="chart-info-details">
                                        <p><span>({allFetchedData.balance_change}) </span>{allFetchedData.balance_report}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="progres-graph-card-2">
                                <div className="graphchart-graph-progress-container-title">
                                    <img src={`${BASE_URL}/assets/dashboard/expenseicon.svg`} alt='icon' />
                                    <p>Expense</p>
                                </div>
                                <div className="graphchart-graph-progress-container-mainprogress">

                                    {/* <p>{allFetchedData.expense} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</p> */}
                                    <p>{allFetchedData?.expense} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</p>
                                    <Progress percent={75} showInfo={false} size="small" />
                                    <div className="chart-info-details">
                                        <p><span>({allFetchedData.expense_change}) </span>{allFetchedData.expense_report}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="progres-graph-card-3">
                                <div className="graphchart-graph-progress-container-title">
                                    <img src={`${BASE_URL}/assets/dashboard/incomeicon.svg`} alt='icon' />
                                    <p>Income</p>
                                </div>
                                <div className="graphchart-graph-progress-container-mainprogress">

                                    {/* <p>{allFetchedData.income} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</p> */}
                                    <p>{allFetchedData?.income} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</p>
                                    <Progress percent={30} showInfo={false} size="small" />
                                    <div className="chart-info-details">
                                        <p><span>({allFetchedData.income_change}) </span>{allFetchedData.income_report}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="graphchart-revenuegraph bg-[#FFF] rounded-[25px] px-[15px] lg:px-[30px] py-[28px] flex-shrink-0">
                    <RevenueGraph allFetchedData={allFetchedData} revenueData={revenueData} />
                </div>
            </div>

        </>
    )
}

export default GraphChart