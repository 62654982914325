import React, { useEffect, useState,useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useFlashMessage } from '../../context/FlashMessageContext';
import { get, post } from "../../services/apiService";
import Resizer from "react-image-file-resizer";
import { ToastContainer, toast } from 'react-toastify';

import ContentLayout from "../../layout/ContentLayout";
import EmployeeAddForm from "../../forms/EmployeeAddForm";
import { AppContext } from "../../context/context";
import {
  uploadFile,
  uploadFileMultipleInput,
  uploadFileMultipleInputVariants,
  validateData
} from '../../utils/Common';
import arrowLeft from "../../assets/images/arrow-left.png";
import { FlashMessage } from "../../utils/FlashMessage";
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

const EmployeeAddPage = () => {

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage();

  const userData = JSON.parse(localStorage.getItem('user'));

  //----------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  const [isCreateActive, setIsCreateActive] = useState(false);

  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");
    if (!employeeApiResponse) {
      // Allow access if no employee data is found in localStorage (optional)
      setHasPermission(true);
      setIsCreateActive(true);
      return;
    }

    const employeeData = JSON.parse(employeeApiResponse);
    const hrmFeature = employeeData.role.features.find(
      (feature) => feature.name === "HRM" && feature.status === 1
    );

    if (hrmFeature) {
      const createHrmOptionActive = hrmFeature.feature_options.find(
        (option) => option.name === "Create" && option.status === 1
      );

      if (createHrmOptionActive && location.pathname === "/employee-add") {
        setHasPermission(true);
        setIsCreateActive(true);
      } else {
        setHasPermission(false);
        setIsCreateActive(false);
        navigate("/dashboard", { replace: true });
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }
    } else {
      setHasPermission(false);
      setIsCreateActive(false);
      navigate("/dashboard", { replace: true });
      Swal.fire({
        icon: "error",
        title: "Permission Denied",
        text: "You do not have permission for this feature.",
      });
    }
  }, [location.pathname, navigate]);

  //-----------------

  const [formData, setFormData] = useState({
    id: "",
    emp_name: "",
    emp_email: "",
    country_code: "",
    emp_phone: "",
    dob: null,
    blood_group: "",
    address: "",
    emergency_contact: "",
    emergency_contact_rel: "",
    w_email: "",
    emp_id: "",
    password: "",
    warehouse: "",
    emp_type: "",
    designation: "",
    emp_role: "",
    join_date: null,
    status: "",
    branch: "",
    working_day: "",
    w_hours: "",
    image: "",
    u_email: userData.email,
    link: "",
    department: ""
  });
  // --------------
  const { state, updateBloodGroups } = useContext(AppContext);
  const [bloodGroupList, setBloodGroupList] = useState(
    state?.bloodGroups?.map((item) => ({ ...item, name: item.blood_group_name }))
  );
  useEffect(() => {
    if ((isCreateActive && hasPermission) || !localStorage.getItem("employee")) {
      getBloodGroupList();
    }
  }, [isCreateActive,hasPermission]);
  const getBloodGroupList = () => {
    axios
    .get(
        `${process.env.REACT_APP_API_URL}get-blood-group`,
        {
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    )
    .then((response) => {
        if(response.data.success)
        {
          updateBloodGroups(response.data.data)
          setBloodGroupList(response.data.data.map((item) => ({ ...item, name: item.blood_group_name })));
        }
    })
    .catch((error) => {
        console.log(error);
    });
  };
  // ------------
  const [designationList, setDesignationList] = useState([]);
  useEffect(() => {
    if ((isCreateActive && hasPermission) || !localStorage.getItem("employee")) {
      getDesignations();
    }
  }, [isCreateActive,hasPermission]);
  const getDesignations = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}designation`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setDesignationList(response.data.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching designations:", error);
      });
  };
  //---------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "blood_group" && value === "Select Blood Group") {
      setFormData({
        ...formData,
        blood_group: "",
      });
    }
    else if (name === "emergency_contact_rel" && value === "Select") {
      setFormData({
        ...formData,
        emergency_contact_rel: "",
      });
    }
    else if (name === "designation" && value === "Select Type") {
      setFormData({
        ...formData,
        designation: "",
      });
    }
    else if (name === "emp_role" && value === "Select Type") {
      setFormData({
        ...formData,
        emp_role: "",
      });
    }
    else if (name === "status" && value === "Select Type") {
      setFormData({
        ...formData,
        status: "",
      });
    }
    else if (name === "warehouse" && value === "Select") {
      setFormData({
        ...formData,
        warehouse: "",
      });
    }
    else if (name === "department" && value === "Select") {
      setFormData({
        ...formData,
        department: "",
      });
    }
    else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    console.log("input data", formData);
  };
  //---------
  const handleDobDateChange = (date) => {
    setFormData({
      ...formData,
      dob: date,
    });
  };
  const handleJoinDateChange = (date) => {
    setFormData({
      ...formData,
      join_date: date,
    });
  };
  //-----------
  const [uploadDetails, setUploadDetails] = useState([]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });

  const handleImageChange = async (files, name, id=null) => {
    let tempUploadDetails = uploadDetails;
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        FlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,'error');
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob],files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      const existingIndex = tempUploadDetails.findIndex(
          (variant) => variant.input_name === name
        );
        if (existingIndex !== -1) {
          loadingCallback(true);
          // If a variant with the same name exists, update it
          tempUploadDetails[existingIndex] = {
            name: files[i].name,
            size: sizeName,
            percentage: 0,
            file: file,
            input_name: name,
            image_path: URL.createObjectURL(files[i]),
          };
          const brandLogo = await uploadFileMultipleInputVariants(
            tempUploadDetails,
            name
          );
          if (brandLogo) {
            setFormData({
              ...formData,
              [name]: brandLogo.path,
            });
            loadingCallback(false);
          }
        } else {
          loadingCallback(true);
          tempUploadDetails.push({
            name: files[i].name,
            size: sizeName,
            percentage: 0,
            file: file,
            input_name: name,
            image_path: URL.createObjectURL(files[i]),
          });

          const brandLogo = await uploadFileMultipleInputVariants(
            tempUploadDetails,
            name
          );

          if (brandLogo) {
            loadingCallback(false);
            setFormData({
              ...formData,
              [name]: brandLogo.path,
            });
          }
        }
    }
    setUploadDetails(tempUploadDetails);
  };

  //----------
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    if ((isCreateActive && hasPermission) || !localStorage.getItem("employee")) {
      getRoles();
    }
  }, [isCreateActive,hasPermission]);

  const getRoles = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}role-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setRoleList(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching designations:", error);
      });
  };

  //-----------
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    if ((isCreateActive && hasPermission) || !localStorage.getItem("employee")) {
      getDepartments();
    }
  }, [isCreateActive,hasPermission]);

  const getDepartments = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}department`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          const dListData= response.data.data.data
          console.log("department list", dListData)
          setDepartmentList(dListData);
        }
      })
      .catch((error) => {
        console.error("Error fetching designations:", error);
      });
  };
  //----------
  const [warehouseList, setWarehouseList] = useState([]);

  useEffect(() => {
    if ((isCreateActive && hasPermission) || !localStorage.getItem("employee")) {
      getWarehouses();
    }
  }, [isCreateActive,hasPermission]);

  const getWarehouses = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}warehouses-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          const wListData= response.data.data
          console.log("warehouse list", wListData)
          setWarehouseList(wListData);
        }
      })
      .catch((error) => {
        console.error("Error fetching designations:", error);
      });
  };

  const handleSubmit = async (event) => {

        const formObject = formData;
        const formattedLink = `https/${formObject.u_email}/${formObject.link}`;

        const employeeData = {
          name: formObject.emp_name ? formObject.emp_name : null,
          email: formObject.emp_email ? formObject.emp_email : null,
          // country_code: formObject.country_code ? formObject.country_code : null,
          mobile_number: formObject.emp_phone ? formObject.emp_phone : null,
          address: formObject.address ? formObject.address : null,
          emergency_mobile_number: formObject.emergency_contact ? formObject.emergency_contact : null,
          password: formObject.password ? formObject.password : null,
          //pin: formObject.pin ? formObject.pin : null,
          warehouse_id: formObject.warehouse ? formObject.warehouse : null,
          department_id: formObject.department ? formObject.department : null,
          // country: formObject.country ? Number(formObject.country) : null,
          // district: formObject.district ? Number(formObject.district) : null,
          // city: formObject.city ? Number(formObject.city) : null,
          joining_date: formObject.join_date ? formObject.join_date : null,
          dob: formObject.dob ? formObject.dob : null,
          working_day: formObject.working_day ? formObject.working_day : null,
          // zipcode: formObject.zipcode ? Number(formObject.zipcode) : null,
          // nid: formObject.nid ? Number(formObject.nid) : null,
          // customer_id: formObject.customer_id ? Number(formObject.customer_id) : null,
          work_email: formObject.w_email ? formObject.w_email : null,
          blood_group_id: formObject.blood_group ? formObject.blood_group : null,
          relation_with: formObject.emergency_contact_rel ? formObject.emergency_contact_rel : null,
          designation_id: formObject.designation ? formObject.designation : null,
          role_id: formObject.emp_role ? formObject.emp_role : null,
          status: formObject.status ? formObject.status : null,
          // police_station: formObject.police_station ? formObject.police_station : null,
          emp_image: formObject.image ? formObject.image : null,
          u_email: formObject.u_email ? formObject.u_email : null,
          link: formattedLink
        };
        createEmployee(employeeData);
  };

  const createEmployee = (formData) => {
    loadingCallback(true);
    console.log("data to save", formData)
      post("save-employee", formData, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status===200) {
            FlashMessage('Customer saved successfully','success');
            navigate('/employee');
          } else {
            console.log("Customer insertion failed");
              setWarningMessage(true);
              const timer = setTimeout(() => {
                setWarningMessage(false);
              }, 3000);
              return () => clearTimeout(timer);
          }
        })
        .catch((error) => {
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            localStorage.setItem('isPinVerify', false);
            navigate('/login');
          }
          console.log(error);
        });
    //}
  }

  return(
    <ContentLayout>
      <div className="rounded-[16px] bg-white px-[35px] py-[30px] mt-[30px]">
        {warningMessage && (
          <div className="warning-message">
            something wrong!
          </div>
        )}
        {flashMessage && (
          <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
            {flashMessage.text}
          </div>
        )}
        {loading && (
          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
          </div>
        )}
        <EmployeeAddForm
          bloodGroupList={bloodGroupList}
          designationList={designationList}
          roleList={roleList}
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          handleSubmitForm={handleSubmit}
          handleJoinDateChange={handleJoinDateChange}
          handleDobDateChange={handleDobDateChange}
          handleImageChange={handleImageChange}
          loadingCallback={loadingCallback}
          navigate={navigate}
          warehouseList={warehouseList}
          departmentList={departmentList}
        />
      </div>
    </ContentLayout>
  );
}

export default EmployeeAddPage;