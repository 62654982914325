import React, { useContext, useState, useRef, useEffect } from "react";
import Breadcrumb from '../Settings_components/Breadcrumb';
import Table from '../Settings_components/Table';
//import { productItems } from '../../../data/data';
import { AppContext } from '../../../context/context.js';
import { get, post } from "../../../services/apiService";
import {RiMoneyDollarCircleLine} from "react-icons/ri";
import { timeDiff } from '../../../utils/Common';
import Pagination from '../../../utils/pagination';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


const InvoiceList = () => {
    const {
        state,
        isLoading,
        updateInvoiceList
    } = useContext(AppContext);
    const { searchKeyword,invoiceList } = state;
    const token = localStorage.getItem("token");

    const [loader, setLoader] = useState(true);

    //---------------------
    const location = useLocation();
    const navigate = useNavigate();
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
      const employeeApiResponse = localStorage.getItem("employee");

      if (!employeeApiResponse) {
        setHasPermission(true);
        return; // Exit useEffect early
      }

      if (employeeApiResponse) {
        // Parse the employee data from local storage
        const employeeData = JSON.parse(employeeApiResponse);

        // Get the "Exporter" feature from the employee's role features
        const settingsFeature = employeeData.role.features.find(
          (feature) => feature.name === "Settings"
        );

        // Check if the "Exporter" feature and "Visible" option are both active
        if (settingsFeature && settingsFeature.status === 1) {

          // Grant access based on the route and permissions
          if (location.pathname === "/settings") {
            setHasPermission(true);
            return; // Exit useEffect if permission is granted
          }
        }
        setHasPermission(false);
        // If no permission, navigate to dashboard first
        navigate("/dashboard", { replace: true });
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Permission Denied",
            text: "You do not have permission for this feature.",
          });
        }, 100);
      }
      // If not an employee, the useEffect gets skipped
    }, [navigate, location]);
    //---------------------

    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const shouldRenderPagination = invoiceList.total > perPage;

    useEffect(() => {
      if(hasPermission){
        getInvoicelist(currentPage,searchKeyword);
      }
    }, [currentPage,searchKeyword,hasPermission]);

    const getInvoicelist = (page,searchKeyword) => {
        if (searchKeyword !== "") {
            const reqData = { keyword: searchKeyword };
            post(`search-invoice?page=${page}&limit=${perPage}`, reqData, token)
            .then((response) => {

                if(response.status === 200)
                {
                    const listData = response.data.data;

                    const transformedInvoiceArray = listData.map(item => ({
                        id: item.id,
                        icon: <RiMoneyDollarCircleLine/>,
                        name: item.invoice_id,
                        sname: item.customer_name,
                        product: item.sales_details
                        ? item.sales_details
                            .filter(sale => sale.product_info)
                            .map(sale => sale.product_info.name)
                            .join(', ')
                        : '',
                        price: item.paid_amount,
                        time: timeDiff(new Date(item.updated_at)),
                        colors: "#00A3EE"
                    }));
                    updateInvoiceList(transformedInvoiceArray)
                }
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
        } else {
            get(`invoice-list?page=${page}&limit=${perPage}`, token)
            .then((response) => {

                if(response.status === 200)
                {
                    const listData = response.data.data;

                    const transformedInvoiceArray = listData.map(item => ({
                        id: item.id,
                        icon: <RiMoneyDollarCircleLine/>,
                        name: item.invoice_id,
                        sname: item.customer_name,
                        product: item.sales_details
                        ? item.sales_details
                            .filter(sale => sale.product_info)
                            .map(sale => sale.product_info.name)
                            .join(', ')
                        : '',
                        price: item.paid_amount,
                        time: timeDiff(new Date(item.updated_at)),
                        colors: "#00A3EE"
                    }));
                    updateInvoiceList(transformedInvoiceArray)
                }
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
        }

    };

    return (
        <div
            className="rounded-[27px]"
            style={{ backgroundColor: 'rgba(0, 163, 238, 0.15)' }}
        >
            <Breadcrumb></Breadcrumb>
            <Table data={invoiceList} type="invoice" getInvoicelist={getInvoicelist}></Table>
            {shouldRenderPagination && (
                <Pagination
                    currentPage={currentPage}
                    lastPage={state.singleCategoryList.last_page}
                    onPageChange={onPageChange}
                />
            )}
            <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
            {loader && (
                <div className="custom-loader"></div>
            )}
            </div>
        </div>
    );
};

export default InvoiceList;