import React from 'react'
// import thirdsignupimg from "../../../../assets/registration/webp/thirdsignup.webp"
// import illustrationimg3 from "../../../../assets/registration/thirdillus.svg"
// import indicatorimg3 from "../../../../assets/registration/Indicator3.svg"
import "./index.css"
const BASE_URL = process.env.REACT_APP_ROOT_URL;
function ThirdStep(props) {
    return (
        <section style={{ background: "#000" }} >
            <div className='third-container'>
                <div className="third-left">
                    <img src={`${BASE_URL}/assets/registration/webp/thirdsignup.webp`} alt='thirdimage' className="img-fluid" />
                </div>
                <div className="third-right ">
                    <div className="third-right-card thirdcard">
                        <div className="third-log-img">
                            <img src={`${BASE_URL}/assets/registration/webp/thirdillus.webp`} alt="logo" className="img-fluid" />
                        </div>
                        <div className="third-form-main">

                            <p>Planning Ahead for<br></br>
                                better Experience</p>
                        </div>
                        <div className="thirdform-console">
                            <p>Setup your budget for each category
                                <br></br> so you in control </p>
                        </div>
                        <div className="continue-button ">
                            <button className='continue-form-button' onClick={props.backToprev}>Back</button>
                        </div>
                        <div className="continue-button ">
                            <button className='continue-form-button' onClick={props.continueSubmit}>Continue</button>
                        </div>
                        <div className="third-progressbar-signup indicator-third">
                            {/* <img src={indicatorimg3} alt="logo" className="img-fluid" /> */}
                            <div className="indicator-two" onClick={props.backToprev}></div>
                            <div className="indicator-three" onClick={props.backToprev}></div>
                            <div className="indicator-one"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ThirdStep