import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProfitAndLossAccountReport = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/profit-loss-account-report');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div  onClick={() => handleNavigate()} className="due-report rounded-[25px] bg-[#fff] mt-4">
        <div className='flex justify-between items-center px-5 py-4 cursor-pointer'>
        <div className='text-[#000232] font-semibold '>
          <p className='text-[25px]'>Profit And Loss Account Report</p>
        </div>
        <div  >
        <div className={"flex items-center justify-center w-[40px] h-[40px] transform duration-300 rotate-[270deg]" } >
          <FontAwesomeIcon icon={faAngleDown} className="text-[25px]" />
        </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitAndLossAccountReport;