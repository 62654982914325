

import React, { useState } from "react";

import LogoAlignLeft from '../../assets/bigLogo.png';
import { formatDate, formatPrice } from "../../utils/Common";
import DueCollectionPDFData from "./DueCollectionPDFData";
import ExpoMethodReport from "./ExpoMethodReport";
import AccountHistoryPDFDetails from "./AccountHistoryPDFDetails";



const TranCollectionPDFDetails = (props) => {
  const {
    paymentList,
    paymentAccountList,
    transactionData,
    fullApiData,
    total,
    actTab,
    paymentMethodHistory,
    type
   } = props;
   console.log("pdf data", paymentMethodHistory, transactionData, fullApiData);
  return (
    <div id="duepayReport">
      <div className="bg-white rounded-3xl font-nunito p-[26px] mb-20">
        <div className="flex justify-between items-center">
          {/* outlet logo */}
          <div className="w-20 cursor-pointer -pb-[4px]">
            <img src={LogoAlignLeft} className="w-full h-full object-contain" />
          </div>
          {/* title and date */}
          <div className="text-[#000232] text-right">
            <h2 className="text-sm font-semibold">Transaction History</h2>
            {/* <p className="text-xs">{formatDate(createdDate)} - {formatDate(updatedDate)}</p> */}
          </div>
        </div>
        {/* table head */}
        <div className="grid text-center mt-4 pt-[4px] pb-[18px] items-center text-xs font-bold bg-[#000232] text-white px-3 grid-cols-10 border-b-[1px] border-[#E7EBF4]">
        <div className="text-left col-span-1">No.</div>
        <div className="text-left col-span-1">Date/Time</div>
        <div className="text-center col-span-1">AC/Number</div>
        <div className="text-center col-span-1">Transection Category</div>
        <div className="text-center col-span-1">Transection Type</div>
        <div className="text-center col-span-1">Ref ID</div>
        <div className="text-right col-span-1">Transaction Name</div>
        <div className="text-right col-span-1">Due</div>
        <div className="text-right col-span-1">Amount</div>
        <div className="text-right col-span-1">Running Balance</div>
      </div>
      {/* table body component */}
      {transactionData?.map((cData, index) =>

          (
          <AccountHistoryPDFDetails
            index={index}
            paymentList={paymentList}
            paymentAccountList={paymentAccountList}
            transactionData={cData}
            activeTab={actTab}
            key={index}
          />
          )
      )
      }
        <hr className="border-[#000232] border-[1px]" />
        <div className="flex justify-center items-start">
          <div className="w-[38%] ml-auto text-xs font-[500]">
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Amount of Transaction</p>
              <p>{formatPrice(total?.total_amount_of_transaction)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Credit Amount</p>
              <p>{formatPrice(total?.total_credit_amount)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Debit Amount</p>
              <p>{formatPrice(total?.total_debit_amount)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Due Revenue</p>
              <p>{formatPrice(total?.total_due_amount)}</p>
            </div>

          </div>
        </div>
      </div>
      {paymentMethodHistory && (
      <ExpoMethodReport
        balanceData={transactionData}
        paymentList={paymentList}
        // fullApiData={fullApiData}
        paymentMethodHistory={paymentMethodHistory}
      />
      )}
    </div>
  );
};

export default TranCollectionPDFDetails;