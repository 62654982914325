import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";

const EditDepartmentForm = (props) => {
  const {
    formData,
    setFormData,
    departmentId,
    setOpen,
    refetch,
    loadingCallback,
  } = props;

  const token = localStorage.getItem("token");

  const handleSubmit = () => {
    console.log("departmentId:", departmentId);
    console.log("updated data:", formData);
    loadingCallback(true);
    axios.post(`${process.env.REACT_APP_API_URL}update-department/${departmentId}`, formData, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      console.log("API Response after edit: ", response);
      loadingCallback(false);
      if (response.data.success) {
        console.log("Department type updated successfully:", response.data.data);
        // Close the modal
        setOpen(false);
        // Trigger a refresh of the data to reflect the changes
        refetch();
      } else {
        console.log("Failed to update expense type:", response.data.errors);
      }
    })
    .catch((error) => {
      loadingCallback(false);  // Stop loading
      console.error("Error updating expense type:", error);
    });
  };

  return(
    <div className="duration-500">
      <div className="w-full relative">
        <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
          Name
        </label>
        <input
          type="text"
          name="department_name"
          placeholder="Name"
          value={formData.name || ''}
          onChange={(e) => setFormData({
            ...formData,
            name: e.target.value
          })}
          className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
        />
      </div>
      <div className="w-full relative mt-2">
        <label className="absolute font-nunito text-xs text-[#102048]  -top-[8px] left-[12px] bg-white px-1 font-semibold">
          Description
        </label>
        <textarea
          type="text"
          name="description"
          onChange={(e) => setFormData({
            ...formData,
            description: e.target.value
          })}
          placeholder="Text"
          value={formData.description || ''}
          className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full  h-20 pt-2 rounded-xl bg-white resize-none"
        />
      </div>
      <div>
        <button
          onClick={()=>handleSubmit()}
          className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
        >
          Done
        </button>
      </div>
    </div>
  );

}

export default EditDepartmentForm;