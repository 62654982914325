import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemSelectBox from "./ItemSelectBox";
import ItemSelectBox2 from "./ItemSelectBox2";
import ReactDatePicker from "react-datepicker";
import SelectBox from "./Select";
import axios from "axios";
import moment from "moment";
import { get } from "../services/apiService";

const AddAccountsTransactionForm = (props) => {
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const {
    loadingCallback,
    navigate,
    setShowPaymentModal,
    setShowModal,
    isOpen,
    title,
    setWarningMessage,
    type,
    formData,
    setFormData,
    selectType,
  } = props;
  const token = localStorage.getItem("token");
  const [txnList, setTxnList] = useState([]);
  const [expenseType, setExpenseType] = useState([]);
  // const [txnCategoryList, setTxnCategoryList]=useState(["In","Out"])
  const [txnCategoryList, setTxnCategoryList] = useState([
    { id: 1, name: "In", state_id: 1, status: 1 },
    { id: 2, name: "Out", state_id: 2, status: 2 },
  ]);

  const handleSubmit = (e) => {
    // validate dummy select option
    e.preventDefault();
    setShowPaymentModal((val) => !val);
    setShowModal((val) => !val);
  };
  const handlePartyNameChange = (e) => {
    selectType === "expense"
      ? setFormData({
          ...formData,
          transaction_name: e.target.value,
        })
      : setFormData({
          ...formData,
          transaction_name: e.target.value,
        });
  };
  const handleTxnCategoryTypeChange = (e) => {
    setFormData({ ...formData, transaction_category: e.label });
  };
  const handleTxnTypeChange = (e) => {
    setFormData({ ...formData, transaction_type_id: e.value });
  };
  const handleExpenseTypeChange = (e) => {
    setFormData({ ...formData, expense_type_id: e.value });
  };
  const getTxnTypeList = (page, searchKeyword) => {
    loadingCallback(true);
    axios(`${process.env.REACT_APP_API_URL}transaction-type-list`, {
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        loadingCallback(false);
        if (res.data.success) {
          setTxnList(res.data.data.data);
        }
      })
      .catch((err) => {
        loadingCallback(false);
        console.log(err);
      });
  };
  const getExpenseList = (page, searchKeyword) => {
    loadingCallback(true);
    get(`expense-type-list`, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          setExpenseType(response.data.data);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        localStorage.clear();
        localStorage.setItem("token", false);
        // Clear session and local storage
        localStorage.setItem("isPinVerify", false);
        navigate("/login");
      });
  };
  useEffect(() => {
    if (selectType === "accounts") {
      getTxnTypeList();
    }
    if (selectType === "expense") {
      getExpenseList();
    }
  }, []);
  // handle Date change
  useEffect(() => {
    if (selectType === "expense") {
      setFormData({
        ...formData,
        expense_date: date,
      });
    } else {
      setFormData({
        ...formData,
        transaction_date: date,
      });
    }
  }, [formData.transaction_date, date]);
  return (
    <div className="duration-500">
      <div className="flex items-center mt-2 justify-center py-2 gap-3">
        <div className="flex-1 relative">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Name
          </label>
          <input
            type="text"
            name="party_name"
            placeholder="Name"
            onChange={handlePartyNameChange}
            className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
          />
        </div>
        <div className="relative border-[#C1CFEF] border-[1px] -mt-[10px] py-[11px]  flex-1 rounded-xl">
          <ReactDatePicker
            bordered={false}
            selected={date}
            onChange={(pickerDate) => {
              setDate(moment(pickerDate).format("YYYY-MM-DD"));
            }}
            dateFormat="dd/MM/yyyy"
            className="w-full px-2 focus:outline-none"
          />
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Date
          </label>
        </div>
      </div>
      <div className="flex items-center justify-center py-2 gap-3">
        <div className="w-full relative">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Description
          </label>
          <textarea
            type="text"
            name="description"
            onChange={(e) =>
              setFormData({
                ...formData,
                transaction_description: e.target.value,
              })
            }
            placeholder="Description"
            className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full mb-[10px] h-16 pt-2 rounded-xl bg-white resize-none"
          />
        </div>
      </div>
      <div className="flex items-center justify-center py-2 gap-3">
        <div className="w-full relative">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Amount
          </label>
          <input
            type="number"
            name="name"
            defaultValue={formData.transaction_amount}
            onChange={(e) => {
              if (e.target.value < 0) {
                setWarningMessage("Can not receive negative value");
                return;
              } else {
                setFormData({
                  ...formData,
                  transaction_amount: Number(e.target.value),
                });
              }
            }}
            placeholder="0.00 BDT"
            className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full mb-[10px] rounded-xl bg-white"
          />
        </div>
      </div>
      {console.log(expenseType)}
      {console.log(txnList)}
      <div className="flex items-center justify-center py-2 gap-3">
        <div className="flex-1">
          {selectType === "expense" ? (
            <SelectBox
              name="expenseType"
              options={expenseType}
              value={expenseType[0]}
              onChange={handleExpenseTypeChange}
              label={false}
              placeholder="Select Party Name"
              className="w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]"
            />
          ) : (
            <SelectBox
              name="txnType"
              options={txnList}
              value={txnList[0]}
              onChange={handleTxnTypeChange}
              label={false}
              placeholder="Txn Type"
              className="w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]"
            />
          )}
        </div>
        {selectType === "accounts" && (
          <div className="flex-1">
            <SelectBox
              name="txnCategoryType"
              options={txnCategoryList}
              value={txnCategoryList[0]}
              onChange={handleTxnCategoryTypeChange}
              label={false}
              placeholder="Txn Category"
              className="w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]"
            />
          </div>
        )}
      </div>

      <div>
        <button
          onClick={handleSubmit}
          className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AddAccountsTransactionForm;
