import React from 'react';
import PropTypes from 'prop-types';

const Watermark = (props) => {
  const {watermark_type, watermark_text } = props
  
  const watermarkStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
  };
  
  const textContainerStyle = {
    // position: 'absolute',
    // top: '50%',
    // left: '90%',
    // transform: 'translate(0%, -50%) rotate(-45deg)', // Rotate the text
    // opacity: 0.5,
    // fontSize: '3em', // Adjusted font size
    // fontFamily: 'nunito',
    // fontWeight: 'bold',
    // color: '#CCCCCC',
    // userSelect: 'none',
    // whiteSpace: 'nowrap', // Prevent text from wrapping
    // fontStyle: 'italic',
    // width: '300px',
    // height: '300px',
    position: 'absolute',
    left: '150%',
    transform: 'translate(0%, 10%) rotate(-60deg)', // Rotate the text
    opacity: 0.3,
    fontSize: '3em', // Adjusted font size
    fontFamily: 'nunito',
    fontWeight: 'bold',
    color: '#CCCCCC',
    userSelect: 'none',
    whiteSpace: 'nowrap', // Prevent text from wrapping
    fontStyle: 'italic', 
    backgroundPosition: 'center',
    width: '300px',
    height: '300px',
  };

  return (
    <div style={watermarkStyle}>
      <div style={textContainerStyle}>
        { watermark_type ==='text' ? <h1>{watermark_text}</h1> : <img src={watermark_text}></img> }
      </div> 
    </div>
  );
};

Watermark.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Watermark;
