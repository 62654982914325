import React from "react";

import PaymentInfo from "../../components/VendorProfile/PaymentInfo/Index";
import InvoiceHistory from "../../components/VendorInvoiceHistory/InvoiceHistory/Index";

const VendorInvoiceHistory = (props) => {
  const {
    hideModal,
    dueCollectionData,
    type,
    activeTab,
    loadingCallback,
    vendordData,
    fetchedData,
    onSubmitPayment,
    invoiceData,
    vendorId,
  } = props;
  return (
    <>
      <div className="flex flex-col xl:flex-row gap-[3%]">
        <div className="w-[100%] xl:w-[300px] mb-[25px] xl:mb-0 bg-[#F8F9FA] rounded-[16px] p-3">
          <PaymentInfo
            activeTab={activeTab}
            loadingCallback={loadingCallback}
            vendordData={vendordData}
            fetchedData={fetchedData}
            invoiceData={invoiceData}
            onSubmitPayment={onSubmitPayment}
            type={type}
            dueCollectionData={dueCollectionData}
            setSuccessFlashMessage={props.setSuccessFlashMessage}
            setErrorFlashMessage={props.setErrorFlashMessage}
            setWarningMessage={props.setWarningMessage}
          />
        </div>
        <div className="flex-1">
          <InvoiceHistory
            activeTab={activeTab}
            loadingCallback={loadingCallback}
            type={type}
            id={vendorId}
          />
        </div>
      </div>
    </>
  );
};

export default VendorInvoiceHistory;
