import React from 'react';

const DashedLine = () => {
  const dashedLineStyle = {
    border: '1px dashed #000', // You can adjust the thickness and color of the dashed line
    width: '100%',
    height: '0',
    marginTop:'5px',
    marginBottom: '5px', // Adjust as needed
  };

  return <div style={dashedLineStyle}></div>;
};

export default DashedLine;
