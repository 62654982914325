import React from 'react';
import  { formatDate, timeDiff } from './../../utils/Common'
const ProductStockReportItem = (props) => {
  const { categoryData,
    actTab,
    shouldRenderPagination,
    currentPage,
    onPageChange,
    loading,
    index
} = props;

  const createdDate = new Date(categoryData?.created_at);


  let dueAmount;
  if (categoryData?.du_amount == null) {
    dueAmount = 0;
  }
  else {
    dueAmount = categoryData?.du_amount;
  }

   const categoryName = categoryData?.product_info?.category?.name;
   const productName = categoryData?.product_info?.name;

  return (
    <>

     <div className="border-b-[1px] text-xs border-solid last:border-none border-[#E7EBF4] -pt-[4px] pb-[13px] font-nunito grid grid-cols-8 items-start">
     <div className="text-[#000] font-[500] text-center col-span-1">
       {index}
     </div>
     {/* category name */}
     <div className="text-[#000] font-[500] text-center col-span-2">
        {productName}
     </div>
     {/* product name */}
     <div className="text-[#000] font-[500] text-center col-span-1">
     {categoryData?.sales_sum_qty}
     </div>
     <div className="text-[#000] font-[500] text-center col-span-2 ">
     {categoryData?.purchase_sum_qty}
     </div>
     {/* Total */}
     <div className="pr-3 text-[#000] font-[500] text-right col-span-2">{categoryData?.total_sale_amount}</div>
   </div>
    </>
  );
};
ProductStockReportItem.defaultProps = { className: null };
export default ProductStockReportItem;