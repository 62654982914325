import React, { useEffect, useRef, useState } from "react";
import ContentLayout from "../../layout/ContentLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectBox from "../../forms/Select";
import axios from "axios";
import Swal from "sweetalert2";
import { AiOutlineSearch } from "react-icons/ai";
import pdfIcon from "../../assets/images/pdf-Icon.png";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import MinimumStockReportPDFDocument from "./MinimumStockReportPDFDocument";

const MinimumStockReportExpand = () => {
  const [activeTab, setActiveTab] = useState("daily");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [toggle, setToggle] = useState(true);
  const [reports, setReports] = useState([]);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [grandTotalDebit, setGrandTotalDebit] = useState(0);
  const [grandTotalCredit, setGrandTotalCredit] = useState(0);
  const [closingBalance, setClosingBalance] = useState(0);
  const [selected, setSelected] = useState("");
  const [formData, setFormData] = useState({
    start_date: startDate,
    end_date: endDate,
    // due: "customer" || selected,
  });
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));


  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const generatePdf = async () => {
    // Format the dates
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    const doc = (
      <MinimumStockReportPDFDocument
        reports={reports}
        grandTotalDebit={grandTotalDebit}
        grandTotalCredit={grandTotalCredit}
        openingBalance={openingBalance}
        closingBalance={closingBalance}
        user={user}
        startDate={formattedStartDate}
        endDate={formattedEndDate}
      />
    );

    // Generate the PDF as a blob
    const pdfBlob = await pdf(doc).toBlob();

    // Create a URL for the blob and open it in a new tab
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };

  const loadingCallback = (loading) => {
    setLoading(loading);
  };


  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFormData((prevFormData) => ({ ...prevFormData, start_date: date }));
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFormData((prevFormData) => ({ ...prevFormData, end_date: date }));
  };


  // ledger report api
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const fetchReportData = (interval = activeTab, useDateRange = false) => {
    loadingCallback(true);

    const requestData = useDateRange
      ? { start_date: startDate, end_date: endDate }
      : { interval };

    axios
      .post(`${process.env.REACT_APP_API_URL}monthly-ledger-report`, requestData, {
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        loadingCallback(false);
        if (res.data.success) {
          setOpeningBalance(res.data.data.opening_balance);
          setGrandTotalDebit(res.data.data.grand_total_debit);
          setGrandTotalCredit(res.data.data.grand_total_credit);
          setClosingBalance(res.data.data.closing_balance);
          setReports(res.data.data.ledger_data);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.error("Error fetching report data:", error);
      });
  };



  useEffect(() => {
    // Call API with interval on initial load and whenever `activeTab` changes
    fetchReportData(activeTab);
  }, [activeTab]);



  return (
    <>
      <ContentLayout>
        {/* tabs */}
        <div className="flex flex-row gap-[1%] pt-32 md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">
          <div
            // onClick={() => handleTabClick("daily")}
            onClick={() => setActiveTab("daily")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "daily"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Daily
          </div>
          <div
            // onClick={() => handleTabClick("weekly")}
            onClick={() => setActiveTab("weekly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "weekly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Weekly
          </div>
          <div
            // onClick={() => handleTabClick("monthly")}
            onClick={() => setActiveTab("monthly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "monthly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Monthly
          </div>
          <div
            // onClick={() => handleTabClick("yearly")}
            onClick={() => setActiveTab("yearly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "yearly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Yearly
          </div>
        </div>
        {/* datepicker field */}
        <div className="bg-white rounded-3xl mt-3 font-nunito p-[26px] grid grid-cols-1 gap-y-4 md:gap-y-0 md:gap-x-4 md:grid-cols-12 justify-between items-center">
          {/* card */}
          <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] w-full col-span-3 items-stretch bg-[#FF7A00] text-white flex justify-between py-7 px-3 rounded-2xl">
            <div className="space-y-1">
              <p className="font-semibold whitespace-nowrap text-xl">
                <span>{closingBalance}</span> <sub className="text-[9px] font-extralight ml-1">BDT</sub>
              </p>
              <p className="text-xs font-normal">Minimum Stock Report</p>
            </div>
          </div>
          {/* date pickers */}
          <div className="col-span-7">
            <div className="flex justify-between w-full  items-center mt-[12px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
              <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="dd/MM/yyyy"
                  className="w-full px-2 focus:outline-none z-20"
                />
                <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                  Start Date
                </span>
                {/* <span className={`${startDate && startDate.toLocaleDateString() === "DD / MM / YYYY" ? "text-[#91919F]" : "text-[#232324]"} absolute left-[6px] px-1  text-sm lg:text-base bg-white`}>
              {startDate && startDate.toLocaleDateString()}
            </span> */}
              </div>
              <div className="relative border-[#C1CFEF] py-2.5  border w-full flex-1 rounded-xl">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  dateFormat="dd/MM/yyyy"
                  className="w-full px-2 focus:outline-none z-20"
                />
                <span className="bg-white absolute left-2 px-1 font-semibold text-xs top-0 text-[#102048] translate-y-[-50%]">
                  End Date
                </span>
              </div>
            </div>
          </div>
          <button
            onClick={() => fetchReportData(null, true)}
            className=" bg-[#034AFF] cursor-pointer col-span-2 font-nunito text-white rounded-xl px-3 xl:px-5 py-2 text-md"
          >
            Report
          </button>
        </div>

        {/* table */}
        <div>
          <div className="bg-white rounded-3xl font-nunito mt-4 p-[26px]">
            <div className="flex justify-between items-center">
              {/* section title & switch,search & pdf button*/}
              <div className="flex gap-3 items-center">
                {/* section title */}
                {toggle ? (
                  <h2 className="text-xl font-semibold">
                    Minimum Stock Report
                  </h2>
                ) : (
                  <h2 className="text-xl font-semibold">
                    Ledger Status Report
                  </h2>
                )}
                {/* switch button */}
                {/* <div
                  className="cursor-pointer transform hover:scale-110 duration-200"
                  onClick={() => setToggle(!toggle)}
                  // onClick={handleChangeTable}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17.85 14.1C17.81 14.19 17.76 14.27 17.69 14.34L14.65 17.38C14.5 17.53 14.31 17.6 14.12 17.6C13.93 17.6 13.74 17.53 13.59 17.38C13.3 17.09 13.3 16.61 13.59 16.32L15.35 14.56H6.85C6.44 14.56 6.1 14.22 6.1 13.81C6.1 13.4 6.44 13.06 6.85 13.06H17.16C17.26 13.06 17.35 13.08 17.45 13.12C17.63 13.2 17.78 13.34 17.86 13.53C17.92 13.71 17.92 13.92 17.85 14.1ZM17.15 10.93H6.85C6.75 10.93 6.65 10.91 6.56 10.87C6.38 10.79 6.23 10.65 6.15 10.46C6.07 10.28 6.07 10.07 6.15 9.89C6.19 9.8 6.24 9.72 6.31 9.65L9.35 6.61C9.64 6.32 10.12 6.32 10.41 6.61C10.7 6.9 10.7 7.38 10.41 7.67L8.66 9.43H17.16C17.57 9.43 17.91 9.77 17.91 10.18C17.91 10.59 17.57 10.93 17.15 10.93Z"
                      fill="#407BFF"
                    />
                  </svg>
                </div> */}
              </div>
              {/* switch,search & pdf button */}
              <div className="flex gap-3 items-center">
                {/* <div
                // onClick={handleSearch}
                className="bg-[#EBF1FF] rounded-full w-[34px] h-[34px] flex items-center justify-center cursor-pointer transform hover:scale-110 duration-200"
              >
                <img className="w-[20px]" src={searchIcons} alt="searchIcon" />
              </div> */}

                <div className="flex relative w-[95%]">
                  <input
                    autoFocus
                    type="text"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                    placeholder="Search By Particulars"
                    // onChange={(e) => setSearchFilter({...searchFilter, keyword: e.target.value})}
                  />
                  <div className="absolute top-[17px] left-[10px]">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>

                  <div className="absolute top-[17px]  right-[10px]">
                    <div
                      // ref={menuRef}
                      // ref={buttonRef}
                      // onClick={handleButtonClick}

                      className=" relative flex flex-col items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z"
                          fill="#102048"
                        />
                        <path
                          d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z"
                          fill="#102048"
                        />
                        <path
                          d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z"
                          fill="#102048"
                        />
                        <path
                          d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z"
                          fill="#102048"
                        />
                        <path
                          d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z"
                          fill="#102048"
                        />
                        <path
                          d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z"
                          fill="#102048"
                        />
                        <path
                          d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z"
                          fill="#102048"
                        />
                        <path
                          d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z"
                          fill="#102048"
                        />
                        <path
                          d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z"
                          fill="#102048"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  // onClick={handlePrint}
                  onClick={generatePdf}
                  // onClick={() => handlePrintPdf('transactionHistory')}
                  className="bg-[#EBF1FF] rounded-full w-[34px] h-[34px] flex items-center justify-center cursor-pointer transform hover:scale-110 duration-200"
                >
                  <img className="w-[16px]" src={pdfIcon} alt="pdfIcon" />
                </div>
              </div>
            </div>

            {toggle ? (
              <div>
                <div className=" grid grid-cols-3 text-left text-[14px] mt-3 py-[8px] font-nunito font-bold text-[#000232]  border-[2px] border-black pl-4">
                  <div className="text-center">Item Name</div>
                  <div className="text-center">Minimum Stock</div>
                  <div className="text-center">Closing Stock</div>


                </div>

                {loading ? (
                  <div className="custom-loader-logo">
                  {/* <BeatLoader color={"#123abc"} size={20} /> */}
                  <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
                </div>
                ) : (
                  <>

                    {/* Mapped Rows */}
                    {reports.map((report, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-3 items-center justify-start font-nunito font-medium text-[14px] text-[#000232] border-[1px] border-solid border-[#E7EBF4] py-2 pl-3"
                      >
                        <div className="text-center">
                          <div className="font-medium text-[#000232]">
                            {report?.particulars}
                          </div>
                        </div>
                        <div className="text-center">{report?.debit}</div>
                        <div className="text-center">{report?.closing_balance}</div>
                      </div>
                    ))}

                    {/* Grand Total Row */}
                    {/* <div className="grid grid-cols-3 font-nunito font-bold text-[14px] text-[#000232] border-t-[2px] border-solid border-[#000] py-2 pl-3 bg-gray-200">
                      <div>Grand Total</div>
                      <div>{grandTotalDebit}</div>
                      <div>{grandTotalCredit}</div>
                      <div className="text-center">
                       {closingBalance}
                      </div>
                    </div> */}
                  </>
                )}
              </div>
            ) : (
              <div>
                <div className="grid text-center text-[14px] mt-8 py-[8px] font-nunito font-bold text-[#000232] grid-cols-5 border-b-[1px] border-[#E7EBF4]">
                  <div className="text-left whitespace-nowrap">
                    <p className="text-[14px] font-bold justify-start items-center flex">
                      <input
                        className="me-1"
                        type="checkbox"
                        // checked={selectAllChecked}
                        // onChange={handleSelectAllChange}
                      />{" "}
                      Select All{" "}
                    </p>
                  </div>
                  <div className="">Transaction Status</div>
                  <div>Transaction Type</div>
                  <div>Transaction Id</div>
                  <div className="text-center">Amount</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default MinimumStockReportExpand;
