import React, { useContext, useState, useRef, useEffect } from "react";
import Breadcrumb from '../Settings_components/Breadcrumb.js';
import Table from '../Settings_components/Table.js';
//import { subcategory } from '../../../data/data';
import { AppContext } from '../../../context/context.js';
import axios from "axios";
import Pagination from '../../../utils/pagination.js';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const SubcategoryPage = () => {
    const {
        state,
        isLoading,
        updateSubCategoryList
    } = useContext(AppContext);
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;

    //---------------------
    const location = useLocation();
    const navigate = useNavigate();
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
      const employeeApiResponse = localStorage.getItem("employee");

      if (!employeeApiResponse) {
        setHasPermission(true);
        return; // Exit useEffect early
      }

      if (employeeApiResponse) {
        // Parse the employee data from local storage
        const employeeData = JSON.parse(employeeApiResponse);

        // Get the "Exporter" feature from the employee's role features
        const settingsFeature = employeeData.role.features.find(
          (feature) => feature.name === "Settings"
        );

        // Check if the "Exporter" feature and "Visible" option are both active
        if (settingsFeature && settingsFeature.status === 1) {

          // Grant access based on the route and permissions
          if (location.pathname === "/settings/subcategory") {
            setHasPermission(true);
            return; // Exit useEffect if permission is granted
          }
        }
        setHasPermission(false);
        // If no permission, navigate to dashboard first
        navigate("/dashboard", { replace: true });
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Permission Denied",
            text: "You do not have permission for this feature.",
          });
        }, 100);
      }
      // If not an employee, the useEffect gets skipped
    }, [navigate, location]);
    //---------------------

    useEffect(() => {
      if(hasPermission){
        getSubCategory(currentPage);
      }
    }, [currentPage, hasPermission]);

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const [subCategory, setSubCategory] = useState([state.subCategoryList]);
    const token = localStorage.getItem("token");

    const [loader, setLoader] = useState(true);


    const getSubCategory = (page) => {

        axios
        .get(
            `${process.env.REACT_APP_API_URL}sub-category?page=${page}&limit=${perPage}`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        )
        .then((response) => {

            console.log(response)

            if(response.data.success)
            {
                setLoader(false)
                setSubCategory(response.data.data);
                updateSubCategoryList(response.data.data.data)

            }

        })
        .catch((error) => {
            console.log(error);
        });
    };
    const shouldRenderPagination = subCategory?.total > perPage;
    return (

        <div className='rounded-[27px]' style={{backgroundColor: 'rgba(253, 34, 83, 0.15)' }}>
            <Breadcrumb title="Sub Category"></Breadcrumb>
            <Table
              data={subCategory}
              type="subCategory"
              getSubCategory={getSubCategory}></Table>
            {shouldRenderPagination && (
                <Pagination
                    currentPage={currentPage}
                    lastPage={subCategory.last_page}
                    onPageChange={onPageChange}
                />
            )}
            <div className="w-full relative px-10 md:px-[27px] overflow-x-auto sm:rounded-lg">
                {loader && (
                    <div className="custom-loader"></div>
                )}
            </div>
        </div>

    );
};

export default SubcategoryPage;
