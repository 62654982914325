import React, { useEffect, useState,useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFlashMessage } from '../../context/FlashMessageContext';
import { get, post } from "../../services/apiService";
import Resizer from "react-image-file-resizer";
import { ToastContainer, toast } from 'react-toastify';

import ContentLayout from "../../layout/ContentLayout";
import EmployeeAddForm from "../../forms/EmployeeAddForm";
import { AppContext } from "../../context/context";
import arrowLeft from "../../assets/images/arrow-left.png";
import { FlashMessage } from "../../utils/FlashMessage";
import { useParams, useLocation } from "react-router-dom";
import { AiOutlineSearch } from 'react-icons/ai';
import EmployeeItem from "../../components/HRM/EmployeeItem.js";
import Pagination from '../../utils/pagination'; // Adjust the path accordingly
import Swal from "sweetalert2";

const RoleViewPage = () => {

  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  const navigate = useNavigate();
  const { flashMessage, getFlashMessageStyle } = useFlashMessage();

  //---------------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");
    if (!employeeApiResponse) {
      setHasPermission(true);
      return;
    }
    if (employeeApiResponse) {
      const employeeData = JSON.parse(employeeApiResponse);
      const hrmFeature = employeeData.role.features.find(
        (feature) => feature.name === "HRM"
      );
      if (hrmFeature && hrmFeature.status === 1) {
        const viewHrmOptionActive = hrmFeature.feature_options.find(
          (option) => option.name === "View" && option.status === 1
        );
        if (viewHrmOptionActive && location.pathname === "role-view/:id") {
          setHasPermission(true);
          return;
        }
      }
      setHasPermission(false);
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
  }, [navigate, location]);
  //---------------------

  useEffect(() => {
    if (id) {
      if(hasPermission){
      getRoleFeatureDeatils(id);
      }
      //getEmployeeList(id);
    }
  }, [id,hasPermission]);

  const [roleFeature, setRoleFeature] = useState(null);
  const [featureName, setFeatureName] = useState([]);
  const [roleName, setRoleName] = useState([]);

  const getRoleFeatureDeatils = (id) => {
    loadingCallback(true);
    get(`role-feature-option-list`, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          const allData = response.data;
          console.log("All data", allData);
          const matchedData = allData.find(
            (allData) => allData.id === parseInt(id)
          );
          console.log("id wise role feature", matchedData)
          setRoleFeature(matchedData);

          const roleName = matchedData.name;
          console.log("id wise role name:", roleName)
          setRoleName(roleName);

          // const featureNames = matchedData.features.map((feature) => feature.name);
          const featureNames = matchedData.features
          .filter((feature) => feature.status === 1)
          .map((feature) => feature.name);

          console.log("Id wise feature names:", featureNames);
          setFeatureName(featureNames);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log( error);
        localStorage.clear();
        localStorage.setItem("token", false);
        // Clear session and local storage
        localStorage.setItem("isPinVerify", false);

        navigate("/login");
      });
  };

  //----------------
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;

  const [searchFilter, setSearchFilter] = useState({
    keyword: "",
  })
  const { state, isLoading, updateEmployeeList } = useContext(AppContext);

  const [employeeData, setEmployeeData] = useState([]);
  const [currentTotal, setCurrentTotal] = useState(0);
  const shouldRenderPagination = employeeData?.total!=currentTotal &&  employeeData?.total > perPage;
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (id) {
      if(hasPermission){
      getEmployeeList(id, currentPage);
      }
    }
  }, [id, currentPage, searchFilter,hasPermission]);

  const [employeeList, setEmployeeList] = useState([]);

  const getEmployeeList = (id, page) => {

    const userData = JSON.parse(localStorage.getItem('user'));
    const userId = userData ? userData.id : null;
    if (!userId) {
      console.error("User ID not found in local storage.");
      return;
    }

    const data = {
      keyword: searchFilter.keyword,
    };

    loadingCallback(true);

    if (searchFilter.keyword !== "") {
      // const data = {
      //   keyword: searchFilter.keyword,
      // }
      loadingCallback(true);
      post(`search-employee?page=${page}&limit=${perPage}`, data, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            const listData = response.data;
            const filteredList = listData.filter((employee) => {
              return (
                employee.role_id === id.toString() &&
                employee.user_id === parseInt(userId) // Match against user_id
              );
            });
            const searchFilteredList = filteredList.filter((employee) => {
              return (
                employee.name.toLowerCase().includes(searchFilter.keyword.toLowerCase()) ||
                employee.employee_id.toLowerCase().includes(searchFilter.keyword.toLowerCase())
              );
            });
            setEmployeeData(response.data)
            setCurrentTotal(searchFilteredList.length)
            setEmployeeList(searchFilteredList)
            console.log("employee names after search", searchFilteredList)

            const transformedEmployeeArray = searchFilteredList.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateEmployeeList(transformedEmployeeArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        });
    } else {
      loadingCallback(true);
      get(`employee?page=${page}&limit=${perPage}`, token)
        .then((response) => {
          loadingCallback(false);
          if (response.success) {
            console.log("employee list before search", response.data.data)
            const listData = response.data.data;
            const filteredList = listData.filter((employee) => {
              return (
                employee.role_id === id.toString() &&
                employee.user_id === parseInt(userId) // Match against user_id
              );
            });

            const searchFilteredList = filteredList.filter((employee) => {
              return (
                employee.name.toLowerCase().includes(searchFilter.keyword.toLowerCase()) ||
                employee.employee_id.toLowerCase().includes(searchFilter.keyword.toLowerCase())
              );
            });

            console.log("Filtered employee list:", searchFilteredList);
            setEmployeeData(response.data)
            setCurrentTotal(searchFilteredList.length)
            setEmployeeList(searchFilteredList);

            const transformedEmployeeArray = searchFilteredList.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateEmployeeList(transformedEmployeeArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log( error);
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
      });
    }
  }

  const featureDisplay = featureName.map(feature => feature).join(', ');

  return(
    <ContentLayout>
      <div className="main-content recentTableContent">
        <div className="flex flex-col w-full">
          <div className='flex flex-col md:flex-row justify-between mb-4'>
            <div className="flex flex-row gap-2 items-center mb-5">
              <Link className="block w-[24px]" to={`/role`}>
                <img src={arrowLeft} className="w-[100%]" />
              </Link>
              <h1 className='text-[#000232] text-[22px] font-[600] font-lato'>{roleName}</h1>
            </div>
          </div>
        </div>
        <div className="w-full mb-4 mt-4">
          <h3 className="text-[#102048] font-semibold mb-3">Assigned Permissions</h3>
          <div className="border-[#C1CFEF] border-[1px] p-4 mb-2 " style={{ backgroundColor: '#f5f5f5' }}>
            {featureDisplay || 'No permissions assigned'}
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-between mb-4 mt-10'>
                <div>
                <h3 className="text-[#102048] font-semibold mb-3">Employee List</h3>
                </div>
                <div className='flex gap-2 justify-center'>
                  <div className="flex relative  w-[95%]">
                    <input
                      autoFocus
                      type="text"
                      className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                      placeholder="Search Employee"
                      onChange={(e) => {
                        setSearchFilter({...searchFilter, keyword: e.target.value})
                      }}
                    />
                    <div className="absolute top-[37%] left-[10px]">
                      <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                    </div>
                  </div>
                </div>
        </div>
        <div className="table-conatiner">
              <div className="grid grid-cols-8 gap-2 items-center font-nunito font-[700] text-[14px] text-[#000232]">
              <div className="py-[8px]">SL No</div>
                <div className="py-[8px] text-center">Name</div>
                <div className="py-[8px] text-center">Email</div>
                <div className="py-[8px] text-center">Designation</div>
                <div className="py-[8px] text-center">Role</div>
                <div className="py-[8px] text-center">Department</div>
                <div className="py-[8px] text-center">Phone Number</div>
                <div className="py-[8px] text-right">Action</div>
              </div>
        </div>
        {flashMessage && (
                <div
                  className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
                    flashMessage.type
                  )}`}
                >
                  {flashMessage.text}
                </div>
          )}
          {loading && (
                <div className="custom-loader-logo">
                  <img
                    className=""
                    src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                    alt="logo"
                  />
                </div>
          )}
          <div className="max-h-[70vh] overflow-y-auto">
            {employeeList
                  ? employeeList.map((item, index) => (
                    <EmployeeItem
                      key={item.id}
                      index={index}
                      loadingCallback={loadingCallback}
                      navigate={navigate}
                      employeeitem={item}
                      loading={loading}
                      // roleData={roleData}
                    />
                    ))
                  : null}
              <div className="hrtable"></div>
              {/* Pagination component */}
              {shouldRenderPagination && (
                <Pagination
                  lastPage={employeeData?.last_page}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                />
              )}
          </div>
      </div>

    </ContentLayout>

  );

}

export default RoleViewPage;