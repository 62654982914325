import React, { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import RecentPurchaseInvoiceItem from '../../components/Invoice/RecentPurchaseInvoiceItem';
import { timeDiff,formatPrice, scrollUp, invoiceCalculate } from '../../utils/Common.js';
import DatePicker from "react-datepicker";
import Pagination from '../../utils/pagination.js'; // Adjust the path accordingly
import { AppContext } from '../../context/context.js';
import ContentLayout from '../../layout/ContentLayout/index.jsx';
import { get, post } from '../../services/apiService.js';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const PurchaseInvoiceListPage = () => {
    const [invoiceData, setInvoiceData] = useState([]);

    // useEffect(() => {
    //     fetch('invoiceData.json')
    //         .then((res) => res.json())
    //         .then((data) => setInvoiceData(data));
    // }, []);
    const { state, isLoading, updatePurchaseInvoiceList } = useContext(AppContext);
    const { purchaseInvoiceList, invoiceSetting } = state;
    const token = localStorage.getItem('token');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [loading, setLoading] = useState(false);
    const loadingCallback = loading => {
        setLoading(loading);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;

    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());
    const [showNavMenu , setShowNavMenu] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const [searchFilter, setSearchFilter] = useState({
        keyword: "",
        nameId: false,
        emailId: false,
        phoneId: false,
        startDate: 0,
        endDate: new Date(),
    })


    console.log(searchFilter);

    // useEffect(()=>{
    //     getFilterPurchaseInvoiceList(currentPage)
    // },[searchFilter])

    // const getFilterPurchaseInvoiceList = (page) =>{
    //     const data = {
    //         keyword: searchFilter.keyword,
    //         nameId: searchFilter.nameId,
    //         emailId: searchFilter.emailId,
    //         phoneId: searchFilter.phoneId,
    //         startDate: searchFilter.startDate,
    //         endDate: searchFilter.endDate,
    //     }
    //     console.log("searchData", data, searchFilter);
    //     post(`search-purchase-invoice?page=${page}&limit=${perPage}`, data, token)
    //     .then((response) => {

    //         if(response.status === 200)
    //         {
    //             const listData = response.data.data;
    //             setInvoiceData(response.data);

    //             const transformedInvoiceArray = listData.map(item => ({
    //                 id: item.id,
    //                 icon: <RiMoneyDollarCircleLine/>,
    //                 name: item.invoice_id,
    //                 sname: item.vendor ? item.vendor.name : '',
    //                 product: item.purchase_details
    //                 ? item.purchase_details
    //                     .filter(sale => sale.product_info)
    //                     .map(sale => sale.product_info.name)
    //                     .join(', ')
    //                 : '',
    //                 price: invoiceSetting?.currency_info?.code ? formatPrice(item.sub_total) +' '+invoiceSetting.currency_info.code : formatPrice(item.sub_total)+' BDT',
    //                 due: invoiceSetting?.currency_info?.code ? formatPrice(item.sub_total - item.paid_amount) +' '+invoiceSetting.currency_info.code : formatPrice(item.sub_total - item.paid_amount)+' BDT',
    //                 time: timeDiff(new Date(item.updated_at)),
    //                 colors: "#00A3EE"
    //             }));
    //             updatePurchaseInvoiceList(transformedInvoiceArray)
    //         }
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
    // }

    useEffect(() => {
        console.log("filterData", searchFilter);
        getPurchaseInvoicelist(currentPage);
    }, [currentPage, searchFilter]);



    const getPurchaseInvoicelist = (page) => {
        if (searchFilter.keyword !== "" || searchFilter.emailId || searchFilter.emailId || searchFilter.phoneId || searchFilter.startDate || searchFilter.endDate) {
            const data = {
                keyword: searchFilter.keyword,
                nameId: searchFilter.nameId,
                emailId: searchFilter.emailId,
                phoneId: searchFilter.phoneId,
                startDate: searchFilter.startDate,
                endDate: searchFilter.endDate,
            }
            // loadingCallback(true)
            post(`search-purchase-invoice?page=${page}&limit=${perPage}`, data, token)
            .then((response) => {
                loadingCallback(false)
                if(response.status === 200)
                {
                    const listData = response.data.data;
                    setInvoiceData(response.data);
                    console.log("Purchase Invoice", response.data.data);
                    const transformedInvoiceArray = listData.map(item => ({
                        id: item.id,
                        icon: <RiMoneyDollarCircleLine/>,
                        name: item.invoice_id,
                        sname: item.vendor_name ? item.vendor_name : '',
                        product: item.purchase_details
                        ? item.purchase_details
                            .filter(sale => sale.product_info)
                            .map(sale => sale.product_info.name)
                            .join(', ')
                        : '',
                        price: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculate(item,'purchase').grandTotal) +' '+invoiceSetting.currency_info.code : formatPrice(invoiceCalculate(item,'purchase').grandTotal)+' BDT',
                        due: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculate(item,'purchase').dueAmount) +' '+invoiceSetting.currency_info.code : formatPrice(invoiceCalculate(item,'purchase').dueAmount)+' BDT',
                        time: timeDiff(new Date(item.updated_at)),
                        colors: "#00A3EE"
                    }));
                    updatePurchaseInvoiceList(transformedInvoiceArray)
                }
            })
            .catch((error) => {
                loadingCallback(false)
                console.log(error);
            });
        } else {
            get(`purchase-invoice-list?page=${page}&limit=${perPage}`, token)
                .then((response) => {
                    if (response.data.data) {
                        const listData = response.data.data;
                        setInvoiceData(response.data);


                        const transformedInvoiceArray = listData.map(item => ({
                            id: item.id,
                            icon: <RiMoneyDollarCircleLine/>,
                            name: item.invoice_id,
                           sname: item.vendor_name ? item.vendor_name : '',
                            product: item.purchase_details
                            ? item.purchase_details
                                .filter(sale => sale.product_info)
                                .map(sale => sale.product_info.name)
                                .join(', ')
                            : '',
                            price: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculate(item,'purchase').grandTotal) +' '+invoiceSetting.currency_info.code : formatPrice(invoiceCalculate(item,'purchase').grandTotal)+' BDT',
                            due: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculate(item,'purchase').dueAmount) +' '+invoiceSetting.currency_info.code : formatPrice(invoiceCalculate(item,'purchase').dueAmount)+' BDT',
                            time: timeDiff(new Date(item.updated_at)),
                            date: item.updated_at,
                            colors: "#00A3EE"
                        }));
                        updatePurchaseInvoiceList(transformedInvoiceArray)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    // useEffect(() => {
    //     getPurchaseInvoicelist(currentPage,searchKeyword);
    // }, [currentPage,searchKeyword]);
    // const getPurchaseInvoicelist = (page,searchKeyword) => {
    //     if (searchKeyword !== "") {
    //         const reqData = { keyword: searchKeyword };
    //         post(`search-purchase-invoice?page=${page}&limit=${perPage}`, reqData, token)
    //         .then((response) => {

    //             if(response.status === 200)
    //             {
    //                 const listData = response.data.data;
    //                 setInvoiceData(response.data);

    //                 const transformedInvoiceArray = listData.map(item => ({
    //                     id: item.id,
    //                     icon: <RiMoneyDollarCircleLine/>,
    //                     name: item.invoice_id,
    //                     sname: item.vendor ? item.vendor.name : '',
    //                     product: item.purchase_details
    //                     ? item.purchase_details
    //                         .filter(sale => sale.product_info)
    //                         .map(sale => sale.product_info.name)
    //                         .join(', ')
    //                     : '',
    //                     price: invoiceSetting?.currency_info?.code ? formatPrice(item.sub_total) +' '+invoiceSetting.currency_info.code : formatPrice(item.sub_total)+' BDT',
    //                     due: invoiceSetting?.currency_info?.code ? formatPrice(item.sub_total - item.paid_amount) +' '+invoiceSetting.currency_info.code : formatPrice(item.sub_total - item.paid_amount)+' BDT',
    //                     time: timeDiff(new Date(item.updated_at)),
    //                     colors: "#00A3EE"
    //                 }));
    //                 updatePurchaseInvoiceList(transformedInvoiceArray)
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    //     } else {
    //         get(`purchase-invoice-list?page=${page}&limit=${perPage}`, token)
    //             .then((response) => {
    //                 if (response.data.data) {
    //                     const listData = response.data.data;
    //                     setInvoiceData(response.data);


    //                     const transformedInvoiceArray = listData.map(item => ({
    //                         id: item.id,
    //                         icon: <RiMoneyDollarCircleLine/>,
    //                         name: item.invoice_id,
    //                         sname: item.vendor ? item.vendor.name : '',
    //                         product: item.purchase_details
    //                         ? item.purchase_details
    //                             .filter(sale => sale.product_info)
    //                             .map(sale => sale.product_info.name)
    //                             .join(', ')
    //                         : '',
    //                         price: invoiceSetting?.currency_info?.code ? formatPrice(item.sub_total ) +' '+invoiceSetting.currency_info.code : formatPrice(item.sub_total)+' BDT',
    //                         due: invoiceSetting?.currency_info?.code ? formatPrice(item.sub_total - item.paid_amount) +' '+invoiceSetting.currency_info.code : formatPrice(item.sub_total - item.paid_amount)+' BDT',
    //                         time: timeDiff(new Date(item.updated_at)),
    //                         date: item.updated_at,
    //                         colors: "#00A3EE"
    //                     }));
    //                     updatePurchaseInvoiceList(transformedInvoiceArray)
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     }
    // };
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const shouldRenderPagination = invoiceData.total > perPage;

    const handleButtonClick = () => {
        setShowNavMenu((prevShowNavMenu) => !prevShowNavMenu);
    };

    const handleClickOutside = (event) => {
        if (
            (menuRef.current && !menuRef.current.contains(event.target)) &&
            (buttonRef.current && !buttonRef.current.contains(event.target))
        ) {
            setShowNavMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    useEffect( ()=>{
            if(purchaseInvoiceList){
                scrollUp();
            }
        }
    ,[purchaseInvoiceList])
    return (
        <>
            <ContentLayout>
                <div className="main-content recentTableContent bg-[#FFF] mt-[30px] rounded-[25px]">
                    <div className="RecentOrdersTable-container flex flex-col w-full">

                    <div className='flex flex-col md:flex-row justify-between mb-4'>
                            <div className="title-link">
                                <h1>All Purchase Invoice</h1>
                            </div>

                            <div className='flex gap-2 justify-center'>
                                <div className="flex relative w-full">
                                    <input
                                        autoFocus
                                        type="text"
                                        className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                                        placeholder="Search Invoice"
                                        onChange={(e) => {
                                            // setSearchKeyword(e.target.value);
                                            setSearchFilter({...searchFilter, keyword: e.target.value})
                                        }}
                                    />

                                    <div className="absolute top-[37%] left-[10px]">
                                        <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                                    </div>

                                    <div
                                     ref={buttonRef}
                                     onClick={handleButtonClick}
                                     className="absolute top-[36%] right-[10px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z" fill="#102048"/>
                                            <path d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z" fill="#102048"/>
                                            <path d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z" fill="#102048"/>
                                            <path d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z" fill="#102048"/>
                                            <path d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z" fill="#102048"/>
                                            <path d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z" fill="#102048"/>
                                            <path d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z" fill="#102048"/>
                                            <path d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z" fill="#102048"/>
                                            <path d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z" fill="#102048"/>
                                        </svg>
                                    </div>

                                    {showNavMenu && (
                                        <div ref={menuRef} className="absolute z-50  w-[230px] shadow-sm left-[15px] top-[50px] bg-[#FFF] border-1 border-solid p-3 rounded-[10px]">
                                            <div onClick={(e)=> setSearchFilter({...searchFilter, nameId: (!searchFilter.nameId)})}className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                                                <div className=''>
                                                    <input
                                                        type="checkbox"
                                                        checked={searchFilter.nameId}
                                                        onChange={(e)=>setSearchFilter({...searchFilter, nameId: (!searchFilter.nameId)})}
                                                        // className="bg-[#E5EDFF] p-3 "
                                                    />
                                                </div>
                                                <div className="">
                                                    <p className=" text-[#000] font-[500] ps-2 whitespace-nowrap text-xs">Vendor Name</p>
                                                </div>
                                            </div>
                                            <div onClick={(e)=> setSearchFilter({...searchFilter, emailId: (!searchFilter.emailId)})}className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                                                <div className=''>
                                                    <input
                                                        type="checkbox"
                                                        checked={searchFilter.emailId}
                                                        onChange={(e)=>setSearchFilter({...searchFilter, emailId: (!searchFilter.emailId)})}
                                                        // className="bg-[#E5EDFF] p-3 "
                                                    />
                                                </div>
                                                <div className="">
                                                    <p className=" text-[#000] font-[500] ps-2 whitespace-nowrap text-xs">Vendor Email</p>
                                                </div>
                                            </div>
                                            <div onClick={(e)=> setSearchFilter({...searchFilter, phoneId: (!searchFilter.phoneId)})}className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                                                <div className=''>
                                                    <input
                                                        type="checkbox"
                                                        checked={searchFilter.phoneId}
                                                        onChange={(e)=>setSearchFilter({...searchFilter, phoneId: (!searchFilter.phoneId)})}
                                                        // className="bg-[#E5EDFF] p-3 "
                                                    />
                                                </div>
                                                <div className="">
                                                    <p className=" text-[#000] font-[500] ps-2 whitespace-nowrap text-xs">Vendor Number</p>
                                                </div>
                                            </div>
                                            {/* <div className="flex justify-between w-full col-span-3 items-center pt-[10px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                                                <div onClick={(e)=> setSearchFilter({...searchFilter, stockIn: (!searchFilter.stockIn)})} className={`relative ${ searchFilter.stockIn? 'bg-[#407BFF] text-white' : 'bg-gray-100'}  border-gray-100 py-1 border w-full flex-1 rounded-xl`}>
                                                    <p className="w-full text-[13px] text-center">Stock In</p>
                                                </div>
                                                <div onClick={(e)=> setSearchFilter({...searchFilter, stockOut: (!searchFilter.stockOut)})} className={`relative ${ searchFilter.stockOut? 'bg-[#407BFF] text-white' : 'bg-gray-100'}  border-gray-100 py-1 border w-full flex-1 rounded-xl`}>
                                                    <p className="w-full text-[13px] text-center ">Stock Out</p>
                                                </div>
                                            </div> */}
                                            <div>
                                                <p className="p-2 text-md">Date</p>
                                                <div className="flex justify-between w-full col-span-3 items-center mt-[5px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                                                    <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                                                        <DatePicker
                                                        selected={searchFilter.startDate}
                                                        onChange={(date) => setSearchFilter({...searchFilter, startDate: date})}
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholderText="00/00/00"
                                                        className="w-full px-2 text-xs focus:outline-none"
                                                        onClick={(e) => e.stopPropagation()}
                                                        />
                                                        <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                                            Start Date
                                                        </span>
                                                    </div>
                                                    <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                                                        <DatePicker
                                                        selected={searchFilter.endDate}
                                                        onChange={(date) => setSearchFilter({...searchFilter, endDate: date})}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="w-full px-2 text-xs focus:outline-none"
                                                        />
                                                        <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                                            End Date
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>

                        </div>

                        {loading && (
                            <div className="custom-loader-logo">
                                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                                <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
                            </div>
                        )}



                        <div className="table-conatiner">
                            <div className='grid grid-cols-4 sm:grid-cols-5'>
                                <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px]'>Name</div>
                                <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px] text-center'>Total Amount</div>
                                <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px] text-center'>Due</div>
                                <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px] text-center hidden sm:block'>Date/Time</div>
                                <div className='text-[14px] font-nunito font-bold text-[#000232] py-[8px] text-right'>Action</div>
                            </div>

                            {purchaseInvoiceList.map((invoice) => {
                                return (
                                    <RecentPurchaseInvoiceItem key={invoice.id} invoice={invoice} />
                                );
                            })}
                            <div className="hrtable"></div>
                            {/* Pagination component */}
                            {shouldRenderPagination && (
                            // {invoiceData.length > 2 && (
                                <Pagination
                                    // currentPage={invoiceList.current_page}
                                    lastPage={invoiceData.last_page}
                                    currentPage={currentPage}
                                    //lastPage={10}
                                    onPageChange={onPageChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </ContentLayout>
        </>
    );
};

export default PurchaseInvoiceListPage;
