import Swal from "sweetalert2";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../utils/Modal";

const DesignationItem = ({designationitem, loadingCallback, key,isEditActive}) => {

  const navigate = useNavigate();
  const [warningMessage, setWarningMessage] = useState(false);
  const [refetch, setRefetch] = useState(false)

  const [showDesignationEditModal, setShowDesignationEditModal] = useState(false);
  const [designationData, setDesignationData] = useState({});
  const [formData, setFormData] = useState({});

  const handleDesignationEditOpen = (id) => () => {
    const token = localStorage.getItem("token");
    setShowDesignationEditModal(true);
    setDesignationData(id);
    axios.get(`${process.env.REACT_APP_API_URL}designation/${id}`, {
        headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
      console.log("id wise designation data:", response);
        if (response.data.success) {
            const designationData = response.data.data;
            setFormData({
              name: designationData.name,
              description: designationData.description,
            });
            console.log("editable data: ", designationData)
        }
    })
    .catch((error) => {
      loadingCallback(false);
      console.log(error);
      localStorage.clear();
      localStorage.setItem("token", false);
      // Clear session and local storage
      localStorage.setItem("isPinVerify", false);

      navigate("/login");
    });
};

const [showDesignationViewModal, setShowDesignationViewModal] = useState(false);

const handleDesignationViewOpen = (id) => () => {
  setShowDesignationViewModal(true)
  setDesignationData(id);
};

  return(
    <>
      <div>
        <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] grid grid-cols-3 gap-2 items-center font-nunito font-[500] text-[14px] text-[#000232]">
        {/* <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start"> */}
          <div>
            <div className="flex flex-col sm:flex-row gap-2">
              <div className="font-nunito text-[14px]">
                <div className="font-[500] text-[#000232]">#{designationitem.id}</div>
                {/* <div className="font-[700] text-[#87889E]">{expense?.category_name}</div> */}
              </div>
            </div>
          </div>
          <div className="text-center">{designationitem.name}</div>
          <div className="flex gap-2  text-xs justify-end">
            <label
              htmlFor="view_designation_modal"
              onClick={handleDesignationViewOpen(designationitem?.id)}
              //onClick={()=> Swal.fire("View option coming soon")}

              className="cursor-pointer border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[6px] py-1"
            >
            VIEW
            </label>
            <label
              htmlFor="edit_designation_modal"
              onClick={handleDesignationEditOpen(designationitem?.id)}
              className={`cursor-pointer bg-[#407BFF] rounded-[5px] text-white px-[7px] py-1 flex items-center gap-2 ${
                !isEditActive && "opacity-50 cursor-not-allowed"
              }`}
              style={{ pointerEvents: isEditActive ? "auto" : "none" }}
            >
            EDIT
            </label>
          </div>
        </div>
      </div>

      {showDesignationEditModal ? (
        <Modal
          isOpen={showDesignationEditModal}
          setOpen={setShowDesignationEditModal}
          setWarningMessage={setWarningMessage}
          title="Edit Designation"
          formId="edit_designation_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="edit_designation_modal"
          designationData={designationData}
          formData={formData}
          setFormData={setFormData}
          designationId={designationData}
          refetch={()=>setRefetch(val=>!val)}
        />
      ) : null}

      {showDesignationViewModal ? (
        <Modal
          isOpen={showDesignationViewModal}
          setOpen={setShowDesignationViewModal}
          setWarningMessage={setWarningMessage}
          title="Details"
          formId="view_designation_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="view_designation_modal"
          designationId={designationData}
          formData={formData}
        />
      ) : null}
    </>
  );
}
export default DesignationItem;