import React, { useEffect, useState } from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';
import { formatTimeStr } from 'antd/es/statistic/utils';

const ProductStockItems = (props) => {
  const { categoryData,
    tabActive,
    selectAllChecked,
    onSelect,
    index
  } = props;


  const [isChecked, setIsChecked] = useState(false);

  // useEffect(() => {
  //   setIsChecked(selectAllChecked);
  // }, [selectAllChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelect(index, categoryData);
  };


  return (
    <>
    {tabActive &&
      <div id={`productstock-item-${categoryData.id}`} className="grid text-left text-[14px] mt-3 py-[8px] font-nunito font-bold text-[#000232] grid-cols-6 border-b-[1px] border-[#E7EBF4]">
        {/* name */}
        <div className='text-left'>
           <input
             type="checkbox"
             checked={categoryData?.completed}
             onChange={handleCheckboxChange}
           />
         </div>

        <div className="text-center">
          <h1 className=" text-[#000] font-[600]">{categoryData?.name}</h1>

        </div>
        {/* total stock */}
        <div className="text-[#000] font-[500] text-center">
        {formatPrice(categoryData?.sales_sum_qty)}
        </div>

        {/* stock unit */}
        <div className="text-[#000] font-[500] text-center">
        {formatPrice(categoryData?.current_stock)}
        </div>

        {/* Purchase sale unit */}
        <div className="text-[#000] font-[500] text-center">
          <p className=" font-[500] text-center">{formatPrice(categoryData?.purchase_sum_qty)}</p>
        </div>
        {/* Total */}
        <div className=" text-[#000] font-[500] text-right">{formatPrice(categoryData?.total_sales_amount)}</div>
      </div>}
    </>
  );
};
ProductStockItems.defaultProps = { className: null };

export default ProductStockItems;