import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/navigation/Index'
import TopHeader from '../../components/topheader/Index'
import "../../index.scss"
import axios from "axios";
import { AppContext } from '../../context/context'
import { get } from "../../services/apiService";


const ContentLayout = ({ children, className }) => {
  const {
    state,
    isLoading,
    updateCountryList,
    updateBloodGroups,
    updateInvoiceSetting,
    updateCategoryList
  } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const isPinVerify = localStorage.getItem("isPinVerify");
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  //console.log(parts);
  let firstPart, secondPart;
  let checker;
  // if (parts[1]) {
  //   [firstPart, secondPart] = parts[2].split("-");
  //   checker=firstPart;
  // } else {
  //   checker = '';
  // }

  useEffect(() => {
    if (token && isPinVerify) {
      if (!state.categoryList.length && parts[1] !== 'settings' &&
          parts[1] !== 'editproduct' &&
          parts[1] !== 'addproducts'
          && parts[1] !== 'due-invoice'
          ) {
        //getCategory();
      }

      if (
        parts[1] !== "editproduct" &&
        parts[1] !== "addproducts" &&
        !state.countryList.length &&
        (parts[1] !== "settings" ||
          (parts[2] && parts[2] == "shop-settings") ||
          (parts[2] && parts[2] == "branch-settings"))
      ) {
        //getCountryList();
      }

      if (!state.invoiceSetting
      && parts[1] !== 'invoice'
      && parts[1] !== 'editproduct'
      && parts[1] !== 'addproducts') {
        //getInvoiceSetting();
      }
    }
  },[]);
  const navigate = useNavigate();
  const handleLogout= (e) => {
    e.preventDefault();
    try {
      localStorage.clear();
      localStorage.setItem('token', false);
      // Clear session and local storage
      localStorage.setItem('isPinVerify', false);

      navigate('/login');
      // Navigate to the '/login' route

    } catch (error) {
      console.error('Error navigating:', error);
    }
  }

  const page = 1;
  const perPage = 100;

  const getCountryList = () => {
    axios
    .get(
        `${process.env.REACT_APP_API_URL}get-countries?page=${page}&limit=${perPage}`,
        {
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    )
    .then((response) => {
        if(response.data.success)
        {
          updateCountryList(response.data.data)
        }
    })
    .catch((error) => {
        console.log(error);
    });
  };


  const getInvoiceSetting = () => {
    get("get-invoice-settings", token)
    .then((response) => {
        if (response.success) {
            updateInvoiceSetting(response.data)
        }
    })
    .catch((error) => {
        console.log(error);
    });
  };

  const getCategory = () => {

        axios
        .get(
            `${process.env.REACT_APP_API_URL}get-all-category`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        )
        .then((response) => {
            if(response.data.success)
            {
                updateCategoryList(response.data.data)
            }

        })
        .catch((error) => {
            console.log(error);
        });
    };

  const location = useLocation();
  const isDashboardRoute = location.pathname === '/dashboard';

  return (
    <main className={`${isDashboardRoute ? 'mainDashboard' : 'dashboard'} flex w-full`}>
        <Sidebar
          onLogout={handleLogout}
        />
        <div className={`dashboardContainer ${className}`}>
            <div className="pagecontent ">
                {(token && isPinVerify) ? (
                <TopHeader onLogout={handleLogout} />
                ) : (
                  <TopHeader isPublic={true} />
                )}
                {children}
            </div>
        </div>
    </main>
  );
};

export default ContentLayout;
