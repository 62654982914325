import React, { useContext, useState, useRef, useEffect } from "react";
import { AppContext } from "../../../context/context.js";
import Pagination from "../../../utils/pagination.js";

//import { brands } from '../../../data/data';

import axios from "axios";
import Breadcrumb from "../SettingsV2Components.jsx/Breadcrumb.js";
import Table from "../SettingsV2Components.jsx/Table.js";
import { Helmet } from "react-helmet-async";
const BrandsV2 = () => {
  const { state, isLoading, updateBrandList } = useContext(AppContext);

  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;

  useEffect(() => {
    getBrand(currentPage);
  }, [currentPage]);
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const [loader, setLoader] = useState(true);

  const getBrand = (page) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}brands?page=${page}&limit=${perPage}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        //console.log(response.data.data.data)

        if (response.data.success) {
          setLoader(false);
          //setBrands(response.data.data.data);
          updateBrandList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const shouldRenderPagination = state.brandList?.total > perPage;

  return (
    <div className="rounded-[27px]" style={{ backgroundColor: "#FAFAFA" }}>
      <Helmet>
        <title>Outlet Expense | Settings | Brands</title>
      </Helmet>
      <Breadcrumb></Breadcrumb>
      <Table data={state.brandList} type="brands" getBrand={getBrand}></Table>
      {shouldRenderPagination && (
        <Pagination
          currentPage={currentPage}
          lastPage={state.brandList.last_page}
          onPageChange={onPageChange}
        />
      )}
      <div className="w-full relative px-10 md:px-[27px] overflow-x-auto sm:rounded-lg">
        {loader && <div className="custom-loader"></div>}
      </div>
    </div>
  );
};

export default BrandsV2;
