import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const CustomNavLinkV2 = ({ to, children, activeColor, className, menuName, showNavigation }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  // Styles for active and non-active states
  const activeStyle = {
    backgroundColor: isActive ? activeColor : 'transparent',
  };
  const activeTextStyle = {
    color: isActive ? 'text-[#0094FF] font-bold' : 'inherit', // If active, set color to blue
  };

  const isSettingsActive =
    location.pathname === '/settings-v2' ||
    location.pathname === '/settings-v2/shop-settings';

  const activeSettingsStyle = {
    backgroundColor: isSettingsActive ? activeColor : 'transparent',
  };

  return (
    <>
      {showNavigation ? (
        <>
          <a
            href="javascript:void(0);"
            onClick={showNavigation}
            style={activeSettingsStyle}
            className={className}
          >
            {children}
          </a>
          {/* Make the h4 visible and change its color when active */}
          <h4
            style={isSettingsActive ? activeTextStyle : {}}
            className={`pt-[5px] font-lato font-semibold uppercase text-[9px] ${
              isSettingsActive ? 'text-[#0094FF] font-bold' : ''
            }`}
          >
            {menuName}
          </h4>
        </>
      ) : (
        <>
          <NavLink to={to} style={activeStyle} className={className}>
            {children}
          </NavLink>
          {/* Make the h4 visible and change its color when active */}
          <h4
            style={isActive ? activeTextStyle : {}}
            className={`pt-[5px] font-lato font-semibold uppercase text-[9px] ${
              isActive ? 'text-[#0094FF] font-bold' : ''
            }`}
          >
            {menuName}
          </h4>
        </>
      )}
    </>
  );
};

export default CustomNavLinkV2;
