import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  header: {
    marginBottom: 10,
    textAlign: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 4,
  },
  shopInfo: {
    fontSize: 10,
    marginBottom: 2,
  },

  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },
  thickDivider: {
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },

  reportTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginTop: 2,
    marginBottom: 3,
  },
  table: {
    display: "table",
    width: "100%",
    marginVertical: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    padding: 5,
    fontSize: 10,
    borderColor: "#E7EBF4",
    borderWidth: 1,
  },
  tableHeader: {
    fontSize: 12,
    fontWeight: "bold",
    backgroundColor: "#F5F5F5",
  },
  totalRow: {
    fontWeight: "bold",
    backgroundColor: "#E7EBF4",
  },
  tableTextRight: {
    textAlign: "right",
  },
  footer: {
    position: "absolute",
    bottom: 15, // Adjust this value if needed to fit nicely at the bottom
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: 10,
  },
  totalTextContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
});

// PDF document component
const ProfitAndLossAccountReportPDFDoc = ({
  reports,
  user,
  startDate,
  endDate,
}) => (
  <Document>
    <Page style={styles.page} size="A4">
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>
          {user?.invoice_settings?.shop_name ||
            "Bhai Bhai Sewing Corner (Mirpur)"}
        </Text>
        <Text style={styles.shopInfo}>
          {user?.address ||
            "Shop No-5 (2nd Floor) SN Mansion, Mirpur-1, Dhaka-1216."}
        </Text>
        <Text style={styles.shopInfo}>
          Mobile: {user?.phone || "01819132220"}
        </Text>
        <View style={styles.thickDivider} />
        <View style={styles.divider} />
        <View style={styles.thickDivider} />
        <Text style={styles.reportTitle}>Profit And Loss Account Report</Text>
        <Text style={styles.shopInfo}>
          ({startDate} to {endDate})
        </Text>
      </View>

      {/* Table */}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {/* Left Column - Indirect Expenses */}
          <View style={styles.tableCol}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={styles.tableCol}>Particulars</Text>
              <Text style={[styles.tableCol, styles.tableTextRight]}>
                Amount (In BDT)
              </Text>
            </View>
            {reports?.expenses &&
              Object.entries(reports.expenses).map(([key, value], index) => (
                <View key={index} style={styles.tableRow}>
                  <Text style={styles.tableCol}>{key}</Text>
                  <Text style={[styles.tableCol, styles.tableTextRight]}>
                    {value}
                  </Text>
                </View>
              ))}
          </View>

          {/* Right Column - Gross Profit */}
          <View style={styles.tableCol}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={styles.tableCol}>Particulars</Text>
              <Text style={[styles.tableCol, styles.tableTextRight]}>
                Amount (In BDT)
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCol}>Gross Profit b/d</Text>
              <Text style={[styles.tableCol, styles.tableTextRight]}>
                {reports?.gross_profit ? reports.gross_profit : 0}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCol}>Net Profit</Text>
              <Text style={[styles.tableCol, styles.tableTextRight]}>
                {reports?.gross_profit ? reports.net_profit : 0}
              </Text>
            </View>
          </View>
        </View>

        {/* Total Row */}
        {/* <View style={styles.tableRow}>
          <View style={[styles.tableCol, styles.totalRow]}>
            <Text>Total Expenses (In BDT):</Text>
            <Text style={styles.tableTextRight}>
              {reports?.total_expenses || 0}
            </Text>
          </View>
          <View style={[styles.tableCol, styles.totalRow]}>
            <Text>Total (In BDT):</Text>
            <Text style={styles.tableTextRight}>986,777.03</Text>
          </View>
        </View> */}

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, styles.totalRow]}>
            <View style={styles.totalTextContainer}>
              <Text>Total Expenses (In BDT):</Text>
              <Text style={styles.tableTextRight}>
                {reports?.total_expenses || 0}
              </Text>
            </View>
          </View>
          <View style={[styles.tableCol, styles.totalRow]}>
            <View style={styles.totalTextContainer}>
              <Text>Total (In BDT):</Text>
              <Text style={styles.tableTextRight}>{reports?.gross_profit || 0}</Text>
            </View>
          </View>
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Prepared by</Text>
        <Text>Checked/Recommended by</Text>
        <Text>Authorized by</Text>
      </View>
    </Page>
  </Document>
);

export default ProfitAndLossAccountReportPDFDoc;
