import html2pdf from 'html2pdf.js';
import React, { useState } from 'react';
import { numberToWords, formatPrice} from '../../utils/Common';
import ReturnDetail from './ReturnDetail';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SalesReturnDetail = props => {
  const [pageSelect, setpageSelect] = useState(null);

  const {
    invoice,
    invoiceSetting,
    invoiceCondition,
    paperSize,
    type
  } = props;

  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const monthName = invoiceDate.toLocaleString("en-US", { month: "long" });
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';


  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day} ${monthName} ${year} ( ${hours}:${minutes}${ampm} )`;

  //const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;

  const handleGeneratePdf = async (pageSize, pageSizeNumber) => {
    const element = document.getElementById('pdfConentAreas');
    const element2 = document.getElementById('pdfConentAreas3');
    const element3 = document.getElementById('pdfConentAreas2');

    if (pageSizeNumber === 1) {
      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
    }
    else if (pageSizeNumber === 2) {
      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
    }
    else if (pageSizeNumber === 3) {
      console.log("page size", pageSizeNumber)
      setpageSelect(pageSizeNumber);
    }

    const formatOptions = {
      'a4': 'a4',
      '3mm': [80, 297], // Custom size for '3mm' format
      '2mm': [56, 297], // Custom size for '2mm' format
    };
    const shopLogoUrlWithProxy = `<span class="math-inline">${API_BASE_URL}</span>${shop_logo}`; // Prepend proxy URL
    const opt = {
      margin: 0.1,
      filename: 'myPdf.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      //image: { type: 'jpeg', quality: 0.98, baseURL: 'https://api-outlet-expense.squadinnovators.com' },

      html2canvas: {
        scale: 1,
        proxy: shopLogoUrlWithProxy,
        useCORS: true,
        allowTaint: false,
        logging: false,
      },
      jsPDF: {
        unit: 'mm',
        format: formatOptions[pageSize], // 'a4', '3mm', or '2mm'
        orientation: 'portrait',
        marginLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
      },
    };

    //const cssUrl = window.location.origin + '/static/pdfstyles.module.css';
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    //link.href = cssUrl;
    head.appendChild(link);
    const images = element?.querySelectorAll('img');
    // const images2 = element2.querySelectorAll('img');
    // const images3 = element3.querySelectorAll('img');
    let loadedImages = 0;

    // const checkImagesLoaded = () => {
    //   loadedImages++;

    //   if (loadedImages === images?.length) {
        // All images are loaded, proceed to generate the PDF
        if (pageSizeNumber === 1) {
          generatePdf();
        }
        else if (pageSizeNumber === 2) {
          generatePdf2();
        }
        else if (pageSizeNumber === 3) {
          generatePdf3();
        }

      //}
      // if (loadedImages === images2.length) {
      //   // All images are loaded, proceed to generate the PDF
      //   generatePdf2();
      // }
      // if (loadedImages === images3.length) {
      //   // All images are loaded, proceed to generate the PDF
      //   generatePdf3();
      // }

    //};


    const generatePdf = () => {
      html2pdf()
        .set(opt)
        .from(element)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
          link.remove();
        });
    };
    const generatePdf2 = () => {
      html2pdf()
        .set(opt)
        .from(element2)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
          link.remove();
        });
    };
    const generatePdf3 = () => {
      html2pdf()
        .set(opt)
        .from(element3)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
          link.remove();
        });
    };
    // Add event listener to each image to check when they are loaded
    // if(images !=null){
    // images.forEach(img => {
    //   img.addEventListener('load', checkImagesLoaded);
    // })};

    // // Start the PDF generation process
    // if(images !=null){
    // images.forEach(img => {
    //   if (img.complete) {
    //     // If the image is already cached, trigger the load event manually
    //     checkImagesLoaded();
    //   }
    // })};


  };
  return (
    <div className="main-content">
      <ReturnDetail
        invoice={invoice}
        invoiceSetting={invoiceSetting}
        invoiceCondition={invoiceCondition}
        paperSize={paperSize}
        handleGeneratePdf={handleGeneratePdf}
        pageSelect={pageSelect}
        type={type}
      />
    </div>
  );
};



export default SalesReturnDetail;