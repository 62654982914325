
// import React, { useState } from 'react';
// import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

// const TransactionHistoryPDFDetails = (props) => {
//   const {
//     transactionData,
//     paymentList,
//     paymentAccountList,
//     activeTab,
//     shouldRenderPagination,
//     currentPage,
//     onPageChange,
//     loading,
//   } = props;

//   console.log("transactionList", transactionData);

//   const paymentData = paymentList.data ? paymentList.data : paymentList;
//   const paymentAccountData = paymentAccountList.data ? paymentAccountList.data : paymentAccountList;

//   const createdDate = new Date(transactionData?.date);

//   return (
//     <>
//     {activeTab &&
//       <div className="border-b-[1px] text-sm border-solid  border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-6 items-start">

//         <div className="">
//           <h1 className=" text-[#000] font-[600]">{formatDate(transactionData?.date)}</h1>
//           <p className=" text-[#87889E] font-[500] text-sm">{timeDiff(createdDate)}</p>
//         </div>

//         <div className="text-[#000] font-[500] text-center">
//           {transactionData?.payment_method && transactionData?.payment_method.map((item, index) => (
//             (item?.payment_type_category_id && item?.payment_type_id) ? (

//               paymentData.map((payment) => (
//                 (payment?.id === item?.payment_type_id) ? (
//                   payment?.payment_type_category.map((account)=>(
//                     ( account?.id==item?.payment_type_category_id) &&
//                       (
//                         <div className='w-20 mx-auto'>
//                           <p key={index} className="text-[#000] font-[500]  text-md">
//                             {payment.type_name}
//                           </p>
//                           { String(payment?.type_name).toLowerCase() !='cash' &&
//                           <p className=''>{" "}{account?.payment_category_name}{" "}{account?.account_number}{" "}
//                           <span className='font-bold text-emerald-600'>{item?.payment_amount}{"BDT"}</span>
//                           </p> }
//                           <hr />
//                         </div>
//                       )
//                       // : null
//                   ))

//                 ) : null
//               ))
//             ) : (
//               <div className='w-20 mx-auto'>
//                 <p key={index} className="text-[#000] whitespace-nowrap font-[500] text-md">{"Cash-"}
//                 { item?.payment_amount==null? 0 : <span className='font-bold text-emerald-600'>{item?.payment_amount}{"BDT"}</span>}
//                 </p>
//                 {/* { index<paymentData.length && date} */}
//               </div>
//             )
//           ))}
//         </div>


//         <div className="text-[#000] font-semibold text-center">
//           <p className="text-[#000] font-[500] text-md">{transactionData?.transaction_history?.transaction_status}</p>
//         </div>


//         <div className="text-[#000] font-[500] text-center">
//           <p className="text-[#000] font-[500] text-md">{transactionData?.type}</p>
//         </div>

//         <div className=" text-[#000] font-[500] text-center ">{transactionData?.invoice}</div>

//         <div className=" text-[#000] font-[500] text-right">{transactionData?.total_paid}</div>
//       </div>
//       }
//     </>
//   );
// };
// TransactionHistoryPDFDetails.defaultProps = { className: null };
// export default TransactionHistoryPDFDetails;



import React from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const TransactionHistoryPDFDetails = (props) => {
  const {
    transactionData,
    paymentList,
    paymentAccountList,
    activeTab,
    index
} = props;



const paymentData = paymentList.data ? paymentList.data : paymentList;
const paymentAccountData = paymentAccountList.data ? paymentAccountList.data : paymentAccountList;

  const createdDate = new Date(transactionData?.date);

  return (
    <>

     <div className="border-b-[1px] text-xs border-solid px-3 last:border-none border-[#E7EBF4] -pt-[4px] pb-[13px] font-nunito grid grid-cols-10 items-start">

     <div className="col-span-1">
        <h1 className=" text-[#000] font-[600]">{ (index+1)<10 ? "0" + (index+1) :  index + 1}</h1>
      </div>
     <div className="col-span-2">
        <h1 className=" text-[#000] font-[600]">{formatDate(transactionData?.date)}</h1>
        {/* <p className=" text-[#87889E] font-[500] text-sm">{timeDiff(createdDate)}</p> */}
      </div>

      <div className="text-[#000] font-[500] text-center col-span-2">
          {transactionData.payment_method && transactionData.payment_method.map((item, index) => (
            (item.payment_type_category_id && item.payment_type_id) ? (

              paymentData.map((payment) => (
                (payment.id === item.payment_type_id) ? (
                  payment?.payment_type_category.map((account)=>(
                    ( account.id==item.payment_type_category_id) &&
                      (
                        <div className='w-20 mx-auto'>
                          <p key={index} className="text-[#000] font-[500] whitespace-nowrap text-left text-md">
                            {payment.type_name}
                          </p>
                          { String(payment?.type_name).toLowerCase() !='cash' &&
                          <p className='text-left'>{" "}{account.payment_category_name}<br/><span className='whitespace-nowrap text-[#FF0000]'>{"AC: "}{account.account_number}</span><br></br>
                            <span className='font-bold text-emerald-600'>{Number.parseFloat(item.payment_amount).toFixed(2)}{" BDT"}</span>
                          </p> }

                        </div>
                      )
                      // : null
                  ))

                ) : null
              ))
            ) : (
              <div className='w-20 mx-auto'>
                <p key={index} className="text-[#000] text-left whitespace-nowrap font-[500] text-md">{"Cash-"}
                { item.payment_amount==null? 0 : <span className='font-bold text-emerald-600'>{Number.parseFloat(item.payment_amount).toFixed(2)}{" BDT"}</span>}
                </p>
                {/* { index<paymentData.length && date} */}
              </div>
            )
          ))}
        </div>


      <div className="text-[#000]col-span-1  font-semibold text-center">
        <p className="text-[#000] font-[500] text-md">{transactionData?.transaction_history?.transaction_status}</p>
      </div>

      <div className="text-[#000] col-span-1 font-[500] text-center">
        <p className="text-[#000] font-[500] text-md">{transactionData?.type}</p>
      </div>

      <div className="text-[#000] col-span-2 font-[500] text-center">
        <p className="text-[#000] font-[500] text-center">{transactionData?.invoice}</p>
      </div>

      {/* <div className=" text-[#000] w-[250px] font-[500] text-center ">{transactionData?.invoice}</div> */}

      <div className=" text-[#000] col-span-1 font-[500] text-right">{transactionData?.total_paid}</div>
   </div>
    </>
  );
};
TransactionHistoryPDFDetails.defaultProps = { className: null };
export default TransactionHistoryPDFDetails;