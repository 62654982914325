import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    position: "relative"
  },
  header: {
    marginBottom: 10,
    textAlign: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  shopInfo: {
    fontSize: 10,
    marginBottom: 2,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },
  thickDivider: {
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },
  reportTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginTop: 2,
    marginBottom: 3,
  },
  tableContainer: {
    flexDirection: "row",
    marginVertical: 10,
    alignItems: "stretch",
  },
  tableSection: {
    width: "50%",
    paddingRight: 0,
  },
  table: {
    width: "100%",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f2f2f2",
    borderWidth: 0.5,
    borderColor: "#000",
  },
  tableHeaderCell: {
    padding: 5,
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    flex: 1,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    padding: 5,
    fontSize: 10,
    textAlign: "right",
    borderWidth: 0.5,
    borderColor: "#000",
    flex: 1,
  },
  tableCellLeft: {
    textAlign: "left",
    paddingLeft: 10,
  },
  totalRow: {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
    flexDirection: "row",
    borderTopWidth: 1,
    borderColor: "#000",
  },
  totalCell: {
    padding: 5,
    fontSize: 10,
    flex: 1,
    textAlign: "right",
  },
  footer: {
    position: "absolute",
    bottom: 15, // Adjust this value if needed to fit nicely at the bottom
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: 10,
  },
});

const liabilities = [
  {
    name: "Paid up Capital",
    debit: "28,382,717.25",
    credit: ""
  },
  {
    name: "Capital Account",
    debit: "",
    credit: "28,382,717.25"
  },
  {
    name: "Current Liabilities",
    debit: "",
    credit: "1,578,607.00"
  },
  {
    name: "Sundry Creditors",
    debit: "1,578,607.00",
    credit: ""
  },
  {
    name: "Profit & Loss Accounts",
    debit: "",
    credit: "7,067,321.79"
  },
  {
    name: "Opening Balance",
    debit: "2,349,120.06",
    credit: ""
  },
  {
    name: "Current Period",
    debit: "4,718,201.73",
    credit: ""
  }
];

const assets = [
  {
    name: "Current Assets",
    debit: "",
    credit: "37,028,646.04"
  },
  {
    name: "Bank Accounts",
    debit: "5,919,726.00",
    credit: ""
  },
  {
    name: "Cash in Hand",
    debit: "69,700.00",
    credit: ""
  },
  {
    name: "Closing Stock",
    debit: "27,693,475.54",
    credit: ""
  },
  {
    name: "Loan & Advance",
    debit: "1,365,500.00",
    credit: ""
  },
  {
    name: "Sundry Debtors",
    debit: "1,980,244.50",
    credit: ""
  }
];



// PDF document component
const BalanceSheetReportPDFDocument = ({
  reports, grandTotalDebit, grandTotalCredit, openingBalance, closingBalance, user, startDate, endDate
  // liabilities,
  // assets,
  // liabilitiesTotal,
  // assetsTotal,
  // user,
  // startDate,
  // endDate,

}) => (
  <Document>
    <Page style={styles.page} size="A4" orientation="portrait">
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>{user?.invoice_settings?.shop_name || "Bhai Bhai Sewing Corner (Mirpur)"}</Text>
        <Text style={styles.shopInfo}>{user?.address || "Shop No-5 (2nd Floor) SN Mansion, Mirpur-1, Dhaka-1216."}</Text>
        <Text style={styles.shopInfo}>Mobile: {user?.phone || "01819132220"}</Text>
        <View style={styles.thickDivider} />
        <View style={styles.divider} />
        <View style={styles.thickDivider} />
        <Text style={styles.reportTitle}>Balance Sheet as at</Text>
        <Text style={styles.shopInfo}>({startDate} to {endDate})</Text>
      </View>

      {/* Table Section */}
      <View style={styles.tableContainer}>
        {/* Liabilities Column */}
        <View style={styles.tableSection}>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Liabilities</Text>
              <Text style={styles.tableHeaderCell}>Amount (In BDT)</Text>
              <Text style={styles.tableHeaderCell}>Amount (In BDT)</Text>
            </View>
            {liabilities.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={[styles.tableCell, styles.tableCellLeft]}>{item.name || ""}</Text>
                <Text style={styles.tableCell}>{item.debit || ""}</Text>
                <Text style={styles.tableCell}>{item.credit || ""}</Text>
              </View>
            ))}
            {/* Liabilities Total Row */}
            <View style={styles.totalRow}>
              <Text style={[styles.totalCell, styles.tableCellLeft]}>Total:</Text>
              <Text style={styles.totalCell}>{2000000}</Text>
              <Text style={styles.totalCell}></Text>
            </View>
          </View>
        </View>

        {/* Assets Column */}
        <View style={styles.tableSection}>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={[styles.tableHeaderCell, styles.tableCellLeft]}>Assets</Text>
              <Text style={styles.tableHeaderCell}>Amount (In BDT)</Text>
              <Text style={styles.tableHeaderCell}>Amount (In BDT)</Text>
            </View>
            {assets.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={[styles.tableCell, styles.tableCellLeft]}>{item.name}</Text>
                <Text style={styles.tableCell}>{item.debit || ""}</Text>
                <Text style={styles.tableCell}>{item.credit || ""}</Text>
              </View>
            ))}
            {/* Assets Total Row */}
            <View style={styles.totalRow}>
              <Text style={[styles.totalCell, styles.tableCellLeft]}>Total:</Text>
              <Text style={styles.totalCell}>{23553425636}</Text>
              <Text style={styles.totalCell}></Text>
            </View>
          </View>
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Prepared by</Text>
        <Text>Checked/Recommended by</Text>
        <Text>Authorized by</Text>
      </View>
    </Page>
  </Document>
);

export default BalanceSheetReportPDFDocument;
