import React from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { formatDate } from '../utils/Common';

const CarrierPendingProductView = (props) => {

  const {
    formId,
    loadingCallback,
    setShowModal,
    productData,
    title,
    total_due
  } = props;

  const modalClose = () => setShowModal(false);
  const handlePrintPdf = async (elementId) => {
    // const total = dueCollectionData.reduce((cnt, item) => {
    //   if (item.completed) {return cnt + 1;}
    //   return cnt;
    // }, 0);

      handlePDFDownload(elementId)

  };

  const handlePDFDownload = (elementId) =>{
    const printContents = document.getElementById(elementId).innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();

    window.html2canvas = html2canvas;
    const pdf = new jsPDF("p", "mm", "a4");
    //const printContents = TransactionDetailsRef?.current?.innerHTML;
    pdf.html(printContents, {
      html2canvas: {
        scale: .285,
      },
      margin: [6, 5, 6, 5],
      width: 700,
      windowWidth: 700,
      callback: () => {
        pdf.save("carrier_pending_products.pdf");
      },
    });
  }


  return (
    <>
      {/* <div id="pending_product" className='rounded-[15px] border-[1px] border-solid border-[#F1F1FA] px-[12px] py-[4px] mb-[5px]'>
        <div className='font-lato font-[700] text-[12px] text-[#000232] mb-2'>List of Products</div>

        <div className='flex flex-row gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]'>
          <div className='py-[5px] flex-01'>SL<br />No</div>
          <div className='py-[5px] text-center flex-2 w-[30%]'>Name</div>
          <div className='py-[5px] text-center flex-1'>Price</div>
          <div className='py-[5px] text-center flex-1'>Date</div>
          <div className='py-[5px] text-center flex-1'>Ram</div>
          <div className='py-[5px] text-center flex-1'>Storage</div>
          <div className='py-[5px] text-right w-[30%]'>IMEI<br />Number</div>
        </div>
        {productData ? productData.map((product, index) => (
          <div className='flex flex-row gap-2 font-nunito font-[400] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]'>
            <div className='py-[5px] flex-01'>{index+1}</div>
            <div className='py-[5px] text-center flex-2 w-[30%]'>{product?.name}</div>
            <div className='py-[5px] text-center flex-1'>{product?.purchase_price} {product?.purchase_currency_code}</div>
            <div className='py-[5px] text-center flex-1'>{formatDate(product?.date)}</div>
            <div className='py-[5px] text-center flex-1'>{product?.ram}</div>
            <div className='py-[5px] text-center flex-1'>{product?.storage}</div>
            <div className='py-[5px] text-right w-[30%]'>{product?.imei}</div>
          </div>
        )) : null}
        <div class="flex justify-between">
          <div class="w-1/2 text-xs  pr-1 md:pr-0"></div>
          <div class="w-1/2 text-xs md:text-sm lg:text-base font-semibold pl-2  md:pl-8">
            <div className="flex justify-between items-start">
              <h4 className="text-[#000232] font-nunito font-bold text-lg pt-[18px] pb-[12px]">
                Total Due
              </h4>
              <div className="pt-[18px] pb-[12px]">
                <p className="text-[#7F8098] font-nunito font-bold text-lg mb-[15px]">
                  {total_due ? total_due.toFixed(2) : 0}
                  <span className="text-[13px]"></span>
                </p>
              </div>
            </div>
          </div>
        </div>


      </div> */}

     <div id="pending_product" className="rounded-[15px] px-[16px] py-[8px] mb-[10px]">
  <div className="font-lato font-bold text-[14px] text-[#1A202C] mb-4">Product List</div>

  {/* Table Header */}
  <div className="flex flex-row gap-4 font-nunito font-semibold text-[12px] text-[#2D3748] border-b-[1px] border-solid border-[#CBD5E0] pb-[8px]">
    <div className="py-[5px] w-[5%] text-left">#</div>
    <div className="py-[5px] w-[35%] text-left">Product Name</div>
    <div className="py-[5px] w-[20%] text-center">Price</div>
    <div className="py-[5px] w-[20%] text-center">Date</div>
    <div className="py-[5px] w-[20%] text-right">IMEI Number</div>
  </div>

  {/* Product Rows */}
  {productData ? productData.map((product, index) => (
    <div key={index} className="flex flex-row gap-4 font-nunito font-normal text-[12px] text-[#4A5568] border-b-[1px] border-solid border-[#E2E8F0] py-[8px]">
      <div className="w-[5%] text-left">{index + 1}</div>
      <div className="w-[35%] text-left">{product?.name}</div>
      <div className="w-[20%] text-center">{product?.purchase_price} {product?.purchase_currency_code}</div>
      <div className="w-[20%] text-center">{formatDate(product?.date)}</div>
      <div className="w-[20%] text-right">{product?.imei}</div>
    </div>
  )) : (
    <div className="text-center text-[#718096] py-[10px]">No products available.</div>
  )}

  {/* Total Due Section */}
  <div className="flex justify-end items-start mt-[20px]">
    <div className="w-[50%] text-right font-nunito font-bold text-[#1A202C] text-lg">
      <h4 className="pb-[12px]">Total Due</h4>
      <p className="text-[#2D3748] font-semibold">
        {total_due ? total_due.toFixed(2) : 0}
      </p>
    </div>
  </div>
</div>

      <div class="flex justify-between">
          <div class="w-2/4 text-xs  pr-1 md:pr-0"></div>
          <div class="w-2/4 text-xs md:text-sm lg:text-base font-semibold pl-2  md:pl-8">
            <div className="flex flex-row gap-[1%] pt-32 md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">
              <input
                onClick={() => handlePrintPdf('pending_product')}
                type="button"
                value="PDF Download"
                className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
              />
            </div>
          </div>

      </div>
    </>
  )
}

export default CarrierPendingProductView