import React from "react";

const CashBookReport = ({ tableRef, reports,openingBalance,closingBalance, currentDebit, currentCredit,dateFrom,dateTo,paymentTypeName }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div>

      <table ref={tableRef} className="w-full font-nunito mt-8">
        <thead>
        <tr>
          <td className="text-center" colSpan={7}>
            <h2 className="text-2xl font-semibold font-nunito text-nowrap">
              {user?.invoice_settings.shop_name}
            </h2>
          </td>
        </tr>
        <tr>
          <td className="text-center" colSpan={7}>
          <p className="mb-2">
            {user?.address}
          </p>
          </td>
        </tr>
        <tr>
          <td className="text-center " colSpan={7}><p className="mb-2">
              Mobile - {user?.phone}
            </p></td>
        </tr>
        <tr>
          <td className="text-center " colSpan={7}><div className="h-[2px] mb-[2px] bg-black w-full">
          </div></td>
        </tr>
        <tr>
          <td className="text-center" colSpan={7}>
          <div className="h-[2px] mb-[2px] bg-black w-full">
          </div>
          </td>
        </tr>
        <tr>
          <td className="text-center w-full" colSpan={7}><div className="h-[2px]  bg-black w-full">
          </div></td>
        </tr>
        <tr>
          <td className="text-center w-full" colSpan={7}>
          <p className="text-lg font-semibold text-blue-500 mb-2">
             Liquid Cash Book (Detail)
            </p>
          </td>
        </tr>
        <tr>
          <td className="text-center w-full" colSpan={7}>
          <p className="mb-2">
              <span className="font-semibold">{dateFrom}</span> to <span className="font-semibold">{dateTo}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td className="text-center w-full" colSpan={7}>
          <p className="text-lg font-bold text-blue-900 mb-2">
            {paymentTypeName}
            </p>
          </td>
        </tr>
        </thead>

        <thead className="border-2 mt-2 border-black bg-lime-200">
          <tr className="text-left text-[14px] ">
            <th className="py-[8px] px-2 font-semibold font-nunito text-center ">
              Serial No
            </th>
            <th className="py-[8px] px-2 font-semibold font-nunito text-center ">
              Transaction Date
            </th>
            <th className="py-[8px] px-2 font-semibold font-nunito text-center">
              Particulars
            </th>
            <th className="py-[8px] px-2 font-semibold font-nunito text-center">
              Vch Types
            </th>
            <th className="py-[8px] px-2 font-semibold font-nunito text-center">
              Vch Number
            </th>
            <th className="py-[8px] px-2 font-semibold font-nunito text-center">
              Debit <br />
              <span className="text-center">(in BDT)</span>
            </th>
            <th className="py-[8px] px-2 font-semibold font-nunito text-center">
              Credit <br />
              <span className="text-center">(in BDT)</span>
            </th>
          </tr>
        </thead>
        <tbody className="">
          <tr className="border-b-2 text-[14px] mt-[2px]">
            <td className="py-[8px] px-2 font-semibold border-r-2  font-nunito text-center"></td>
            <td className="py-[8px] px-2 font-semibold border-r-2  font-nunito text-center"></td>
            <td className="py-[8px] px-2 font-semibold font-nunito text-center">
              Opening Balance
            </td>
            <td className="py-[8px] px-2 font-semibold font-nunito text-center"></td>
            <td className="py-[8px] px-2 font-semibold font-nunito text-center"></td>
            <td className="py-[8px] px-2 font-semibold font-nunitofont-semibold font-nunito text-center">
            { openingBalance ? new Intl.NumberFormat().format(openingBalance) : 0}
            </td>
            <td className="py-[8px] px-2 font-semibold font-nunito text-center">
              0
            </td>
          </tr>
          {reports.length > 0 && reports.map((report, idx) => {
            return (
              <tr className="border-b-2 text-[14px] mt-[2px]">
                <td className="py-[8px] px-2 font-semibold border-r-2  font-nunito text-center">
                  {idx}
                </td>
                <td className="py-[8px] px-2 font-semibold border-r-2  font-nunito text-center">
                  {report?.date}
                </td>
                <td className="py-[8px] px-2 font-semibold font-nunito text-center">
                {report?.particulars}
                </td>
                <td className="py-[8px] px-2 font-semibold font-nunito text-center">
                {report?.type}
                </td>
                <td className="py-[8px] px-2 font-semibold font-nunito text-center">
                {report?.invoice_id}
                </td>
                <td className="py-[8px] px-2 font-semibold font-nunitofont-semibold font-nunito text-center">
                {(report?.status.toLowerCase() === 'out' || report?.status.toLowerCase() === 'debit') ? report.payment_amount : 0}
                </td>
                <td className="py-[8px] px-2 font-semibold font-nunito text-center">
                {report?.status.toLowerCase() === "credit" ? report.payment_amount : 0}
                </td>
              </tr>
            );
          })}
          <tr className="border-b-2 text-[14px] mt-[2px]">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="py-[8px] px-2 font-black font-nunito text-center">
              Current Total
            </td>
            <td className="py-[8px] px-2 font-black font-nunito text-center">
              {currentDebit ? new Intl.NumberFormat().format(currentDebit) : 0}
            </td>
            <td className="py-[8px] px-2 font-black font-nunito text-center">
              {currentCredit ? new Intl.NumberFormat().format(currentCredit) : 0}
            </td>
          </tr>
          <tr className="border-b-2 text-[14px] mt-[2px]">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="py-[8px] px-2 font-black font-nunito text-center text-blue-500">
              Closing Balance
            </td>
            <td className="py-[8px] px-2 font-black font-nunito text-center"></td>
            <td className="py-[8px] px-2 font-black font-nunito text-center">
            {closingBalance ?  new Intl.NumberFormat().format(closingBalance) : 0}
            </td>
          </tr>
        </tbody>
        <tfoot className="border-none">
        <tr className="border-none">
          <td className="pt-16 pb-2 border-none bg-transparent">
            <div className="text-center">
              <div className="border-t border-black w-40 mx-auto"></div>
              <span className="text-sm pb-5">Prepared by</span>
            </div>
          </td>
          <td className="border-none bg-transparent"></td>
          <td></td>
          <td className="pt-16 pb-2 border-none bg-transparent">
            <div className="text-center">
              <div className="border-t border-black w-52 mx-auto"></div>
              <span className="text-gray-700 text-sm">Checked/Recommended by</span>
            </div>
          </td>
          <td className="border-none bg-transparent"></td>
          <td className="border-none bg-transparent"></td>
          <td className="pt-16 border-none pb-2 bg-transparent">
            <div className="text-center">
              <div className="border-t border-black w-40 mx-auto"></div>
              <span className="text-gray-700 text-sm">Authorized by</span>
            </div>
          </td>
        </tr>
      </tfoot>

      </table>
    </div>
  );
};

export default CashBookReport;
