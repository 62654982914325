import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AppContext } from "../../context/context";
import { formatPrice, formatDate } from "../../utils/Common";
import { Modal } from "../../utils/Modal";
import axios from "axios";


const Exporter = (props) => {
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;

  const {
    exporter,
    showModalBeforeDelete,
    index,
    loadingCallback,
    token,
    setSuccessFlashMessage,
    setPayment
  } = props;

  const [warningMessage, setWarningMessage] = useState(false);



  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [orderData, setOrderData] = useState({});
  const handleOpen = (id) => () => {

    setOrderData(id);
    setShowModal(!showModal);
    //setShowProductViewModal(!showProductViewModal)

  };

  const [showProductViewModal, setShowProductViewModal] = useState(false);
  //const handleProductViewOpen = () => setShowProductViewModal(!showProductViewModal);
  const handleProductViewOpen = (id) => () => {

    getExporterOrderDetail(id)
    //setShowProductViewModal(!showProductViewModal)

  };
  // useEffect(() => {
  //   orderData?.id && setShowProductViewModal(true)
  // }, [orderData]);
  const getExporterOrderDetail = (id) => {
    //loadingCallback(true);
    setShowProductViewModal(true)
    setOrderData(id);
    // axios
    //   .get(
    //     `${process.env.REACT_APP_API_URL}exporter-order-details/${id}`,
    //     {
    //       headers: {
    //         authorization: `Bearer ${token}`,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     if (response.data.success) {
    //       loadingCallback(false);
    //       const fetchedData = response.data.data;
    //       setOrderData(fetchedData);

    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     loadingCallback(false);
    //     if (error?.response?.status === 401) {
    //       localStorage.clear();
    //       localStorage.setItem("token", false);
    //       // Clear session and local storage
    //       localStorage.setItem("isPinVerify", false);

    //       navigate("/login");
    //       // Navigate to the '/login' route
    //     }
    //   });
  };


  return (
    <>
      {showModal ? (
        <Modal
          isOpen={showModal}
          setOpen={setShowModal}
          setWarningMessage={setWarningMessage}
          setSuccessFlashMessage={setSuccessFlashMessage}
          title="Carrier with product list"
          formId="exporter_carrier_product_list_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="exporter_carrier_product_list"
          exporterOrder={orderData}
          setPayment={setPayment}
        />
      ) : null}

      {showProductViewModal ? (
        <Modal
          isOpen={showProductViewModal}
          setOpen={setShowProductViewModal}
          setWarningMessage={setWarningMessage}
          title="Product Info"
          formId="exporter_product_description_view_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="exporter_product_description_view"
          exporterOrder={orderData}
        />
      ) : null}

      <div className="border-b-[1px] border-solid border-[#E7EBF4] grid grid-cols-4 sm:grid-cols-7 gap-2 items-center font-nunito font-[500] text-[14px] text-[#000000]">
        <div className="py-[8px]">
          <div className="text-[#000232]">{exporter?.name}</div>
          <div className="font-[700] text-[#87889E]">#{exporter?.id}</div>
        </div>
        <div className="py-[8px] text-center hidden sm:block">
          {formatDate(exporter?.booking_date)}
        </div>
        <div className="py-[8px] text-center hidden sm:block">
          {exporter?.purchase_number}
        </div>
        <div className="py-[8px] text-center hidden sm:block">
          {exporter?.received_product_no}
        </div>
        <div className="py-[8px] text-center">{exporter?.due}</div>
        <div className="py-[8px] text-center">{exporter?.purchase_amount}</div>

        <div className="py-[8px] pr-3">
          <div className="font-nunito font-[700] text-[12px] flex flex-col gap-2 justify-end">
            <div className="flex flex-col lg:flex-row gap-2 justify-end">
              <a
              onClick={handleOpen(exporter?.id)}
              htmlFor="exporter_carrier_product_list_modal"
              className="block border-[1px] bg-[#407BFF] text-[#FFF] cursor-pointer hover:text-[#FFF] rounded-[5px] px-[6px] pt-[2px] pb-[2px] text-center">
                Received
              </a>
              <Link
                to={`/add-exporter/${exporter?.id}`}
                className="block border-[1px] border-solid border-[#12B274] text-[#12B274] cursor-pointer hover:text-[#12B274] rounded-[5px] px-[5px] pt-[2px] pb-[1px] text-center"
              >
                Edit
              </Link>
            </div>

            <div className="flex flex-col lg:flex-row gap-2 justify-end">
              <a
                onClick={() => showModalBeforeDelete(exporter?.id, index)}
                className="cursor-pointer block rounded-[5px] text-[#FFF] hover:text-[#FFF] bg-[#FF0303] px-[6px] pt-[2px] pb-[2px] text-center">
                Delete
              </a>
              <a
                onClick={handleProductViewOpen(exporter?.id)}
                htmlFor="exporter_product_description_view_modal"
                className="block border-[1px] border-solid border-[#407BFF] text-[#407BFF] cursor-pointer hover:text-[#407BFF] rounded-[5px] px-[5px] pt-[2px] pb-[1px] text-center"
              >
                View
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exporter;
