import React, { useContext } from "react";
import ContentLayout from '../../layout/ContentLayout/index.jsx'
import TopHeader from "../../components/topheader/Index.jsx";
import Footer from "../../components/footer/Index.jsx";
import "./index.css";
import OrderProduct from "../../components/OrderProduct/OrderProduct.js";
import { AppContext } from '../../context/context.js';

const PurchaseBillingPage = () => {
  const { state} = useContext(AppContext);
  const { currentUser } = state;
  return (
    <>
      <ContentLayout>
        <div className="">
          <OrderProduct type="purchase" currentUser={currentUser ? currentUser : null}></OrderProduct>
        </div>
      </ContentLayout>
          <Footer />
    </>
  );
};

export default PurchaseBillingPage;
