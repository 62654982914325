import React, { createContext, useContext, useState, useEffect } from 'react';

export const FlashMessageContext = createContext();

export function FlashMessageProvider({ children }) {
  const [flashMessage, setFlashMessage] = useState(null);

  useEffect(() => {
    if (flashMessage) {
      const timer = setTimeout(() => {
        setFlashMessage(null);
      }, 5000); // Adjust the timing as needed

      return () => clearTimeout(timer);
    }
  }, [flashMessage]);

  const setErrorFlashMessage = (message) => {
    setFlashMessage({
      text: message,
      type: 'error', // You can add an error type here
    });
  };
  
  const setSuccessFlashMessage = (message) => {
    setFlashMessage({
      text: message,
      type: 'success', // You can add an error type here
    });
  };

  const getFlashMessageStyle = (type) => {
    switch (type) {
      case 'error':
        return 'text-red-600'; // Adjust styles for error messages
      default:
        return 'text-green-600'; // Default styles for other messages
    }
  };

  return (
    <FlashMessageContext.Provider value={{ flashMessage, setSuccessFlashMessage, setErrorFlashMessage, getFlashMessageStyle}}>
      {children}
    </FlashMessageContext.Provider>
  );
}

export function useFlashMessage() {
  return useContext(FlashMessageContext);
}

export default FlashMessageProvider;