import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    position: "relative"
  },
  header: {
    marginBottom: 10,
    textAlign: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 4,
  },
  shopInfo: {
    fontSize: 10,
    marginBottom: 2,
  },

  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },
  thickDivider: {
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    marginVertical: 1,
    width: "100%",
  },

  reportTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginTop: 2,
    marginBottom: 3,
  },
  table: {
    display: "table",
    width: "100%",
    marginVertical: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "45%",
    padding: 6,
    backgroundColor: "#f2f2f2",
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold", // Increase font weight for more focus
    borderWidth: 1,
    borderColor: "#000",
  },
  tableCol: {
    width: "45%",
    padding: 5,
    textAlign: "center",
    fontSize: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  tableColNo: {
    width: "10%", // Adjusted to match the SE column width
    padding: 5,
    textAlign: "center",
    fontSize: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  tableColSpan3: {
    width: "60%",
    padding: 5,
    textAlign: "left",
    fontSize: 10,
    paddingLeft: "10px",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableRowAlternate: {
    backgroundColor: "#f9f9f9",
  },
  grandTotalRow: {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
  },
  footer: {
    position: "absolute",
    bottom: 15, // Adjust this value if needed to fit nicely at the bottom
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: 10,
  },
});

// PDF document component
const SalesRegisterReportPDFDocument = ({ reports, totalSaleAmount, user, startDate, endDate }) => (
  <Document>
    <Page style={styles.page} size="A4" orientation="portrait">
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>{user?.invoice_settings?.shop_name || "Bhai Bhai Sewing Corner (Mirpur)"}</Text>
        <Text style={styles.shopInfo}>{user?.address || "Shop No-5 (2nd Floor) SN Mansion, Mirpur-1, Dhaka-1216."}</Text>
        <Text style={styles.shopInfo}>Mobile: {user?.phone || "01819132220"}</Text>
        {/* Horizontal Lines */}
        <View style={styles.thickDivider} />
        <View style={styles.divider} />
        <View style={styles.thickDivider} />

        <Text style={styles.reportTitle}>Sales Register Report</Text>
        <Text style={styles.shopInfo}>({startDate} to {endDate})</Text>
      </View>

      {/* Table */}
      <View style={styles.table}>
        {/* Table Header */}
        <View style={styles.tableRow}>
          <Text style={[styles.tableColNo]}>SL No.</Text>
          <Text style={styles.tableColHeader}>Date</Text>
          <Text style={styles.tableColHeader}>Sales Amount (in BDT)</Text>
        </View>

        {/* Table Body */}
        {reports.map((report, index) => (
          <View key={index} style={[styles.tableRow, index % 2 === 0 && styles.tableRowAlternate]}>
            <Text style={styles.tableColNo}>{index + 1}</Text>
            <Text style={styles.tableCol}>{report?.date || "N/A"}</Text>
            <Text style={styles.tableCol}>{report?.sales_amount || 0}</Text>
          </View>
        ))}

        {/* Grand Total Row */}
        <View style={[styles.tableRow, styles.grandTotalRow]}>
          <Text style={styles.tableColNo}>-</Text>
          <Text style={styles.tableCol}>Grand Net Total:</Text>
          <Text style={styles.tableCol}>{totalSaleAmount}</Text>
        </View>
      </View>

      {/* Footer */}
      <View style={styles.footer}>
        <Text>Prepared by</Text>
        <Text>Checked/Recommended by</Text>
        <Text>Authorized by</Text>
      </View>
    </Page>
  </Document>
);

export default SalesRegisterReportPDFDocument;
