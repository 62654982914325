import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { formatNumber } from "../utils/Common";
import { FaChevronDown, FaCloudDownloadAlt } from "react-icons/fa";
import { IoPrint } from "react-icons/io5";

const ExpenseDetailForm = (props) => {

  const {
    formId,
    navigate,
    loadingCallback,
    setWarningMessage,
    setShowModal,
    updateModalTitle,
    expenseId
  } = props;
  const token = localStorage.getItem("token");

  const [expDate, setExpDate] = useState(null);
  const [expDescription, setExpDescription] = useState(null);
  const [expName, setExpName] = useState(null);
  const [expAmount, setExpAmount] = useState(null);

  useEffect(() => {
    getExpenseDetails(expenseId)
  }, [expenseId]);

  const getExpenseDetails = (id) => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}expense/${id}`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        loadingCallback(false)
        console.log("============");
        console.log(response);
        //
        const expenseData = response?.data?.data;
        setExpName(expenseData?.catogory_name);
        setExpDate(expenseData?.transaction_date);
        setExpAmount(expenseData?.amount);
        setExpDescription(expenseData?.description);
        //
        // setExpDate(response?.data?.data);
        // setExpDescription(response?.data?.data?.exporter_info[0]);
        // setExpName(response?.data?.data?.exporter_products);
        // setExpAmount(response?.data?.data?.grand_total);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  // -----------------------
  const [dropdownPdf, setDropdownPdf] = useState(false)
  const [dropdownPrint, setDropdownPrint] = useState(false)

  return (
    <>
      <div class="flex flex-row justify-between items-center py-3 px-1">
        <h1 class="text-md font-semibold font-lato pb-1 px-1">Description</h1>
        <div class="">
          <p class="text-md px-[4px] py-[2px] font-semibold">{expDate}</p>
        </div>
      </div>
      <div className="font-lato text-[15px] py-2 px-2">
          <div className="font-[400] text-[#808080]">{expDescription}</div>
      </div>
      <div className="mb-[0px] pb-10 px-2">
        <div className="flex flex-row gap-2 font-nunito font-bold text-[13px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
          <div className="py-[5px] flex-1">
            Expense Name
          </div>
          <div className="py-[5px] text-right w-[30%]">Transaction Amount</div>
        </div>
        <div className="flex flex-row gap-2 font-nunito font-[500] text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
          <div className="py-[5px] flex-1">{expName}</div>
          <div className="py-[5px] text-right flex-1">{expAmount}</div>
        </div>
      </div>
      {/* Download and Print */}
      <div className='flex gap-10  justify-between text-xs md:text-sm lg:text-base mt-6'>
          <div className={`flex-1 transform duration-300 ${dropdownPdf || dropdownPrint ? "!h-[270px] md:!h-[300px]" : "h-[90px]"} space-y-2 relative`}>
              <button onClick={() => setDropdownPdf(dropdownPdf)} className="flex justify-center gap-1 md:gap-3 items-center bg-[#034AFF] text-[#f1effc] absolute z-10 !px-1 py-3 w-full rounded-xl font-bold shadow-md">
                  <span><FaCloudDownloadAlt size={22}/></span> Download PDF <div className={`transform duration-300 ${dropdownPdf ? "-rotate-180" : ""}`}></div>
              </button>
          </div>
          <div className={`flex-1 space-y-2 transform duration-300 ${dropdownPdf || dropdownPrint ? "!h-[270px] md:!h-[300px]" : "h-[90px]"} relative`}>
              <button onClick={() => setDropdownPrint(dropdownPrint)} className="bg-[#034AFF] flex justify-center items-center gap-1 md:gap-3 text-[#f1effc] font-bold shadow-md absolute z-10 !px-1 py-3 w-full rounded-xl">
                  <span><IoPrint size={22}/></span> Print <div className={`transform duration-300 ${dropdownPrint ? "-rotate-180" : ""}`}></div>
              </button>
          </div>
      </div>

    </>
  );
}

export default ExpenseDetailForm;