import React from 'react';
import { formatDate, timeDiff } from './../../utils/Common'
const ProductHistoryReportItem = (props) => {
  const { categoryData,
    actTab,
    shouldRenderPagination,
    currentPage,
    onPageChange,
    loading,
    index
  } = props;

  const createdDate = new Date(categoryData?.created_at);

  let dueAmount;
  if (categoryData?.du_amount == null) {
    dueAmount = 0;
  }
  else {
    dueAmount = categoryData?.du_amount;
  }
  const indexNumber = index+1;
  const categoryName = categoryData?.product_info?.category?.name;
  const productName = categoryData?.product_info?.name;

  return (
    <>

      <div className="border-b-[1px] text-xs border-solid last:border-none border-[#E7EBF4] -pt-[4px] pb-[13px] font-nunito grid grid-cols-11 items-start">
        <div className="text-[#000] font-[500] text-left col-span-1 ">
          {indexNumber}
        </div>
        {/* date/time */}
        <div className=" pl-3 col-span-2 text-center">
          <h1 className=" text-[#000] font-[600]">{formatDate(categoryData?.created_at)}</h1>
          <p className=" text-[#87889E] font-[500] text-xs">{timeDiff(createdDate)}</p>
        </div>
        {/* category name */}
        <div className="text-[#000] font-[500] text-center col-span-2 ">
          {categoryName}
        </div>
        {/* product name */}
        <div className="text-[#000] font-[500] text-center col-span-2 ">
          {productName}
        </div>
        {/* customer */}
        <div className="text-[#000] font-[500] text-center col-span-2">
          <p>#{categoryData.id}</p>
          <p className="text-[#87889E] font-[500] text-xs">{categoryData?.sales?.customer_name}</p>
        </div>
        {/* Total */}
        <div className="pr-3 text-[#000] font-[500] text-right col-span-2">{categoryData?.sales.sub_total}</div>
      </div>
    </>
  );
};
ProductHistoryReportItem.defaultProps = { className: null };
export default ProductHistoryReportItem;