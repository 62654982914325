import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from '../utils/Modal';
import { formatNumber, formatPrice } from "../utils/Common";
import ExporterPaymentTypeForm from "../forms/ExporterPaymentTypeForm";
import { get } from "../services/apiService";
import axios from "axios";
import DuePaymentForm from "../forms/DuePaymentForm";

const ExporterPaymentDetailForm = (props) => {
  const {
    formId,
    navigate,
    loadingCallback,
    setWarningMessage,
    setSuccessFlashMessage,
    setErrorFlashMessage,
    setShowModal,
    updateModalTitle,
    exporterOrder,
    setPayment,
    baseCurrency
  } = props;

  const modalClose = () => setShowModal(false);
  const token = localStorage.getItem("token");

  const [paymentDetails, setPaymentDetails] = useState({});
  const [paymentDeatilsForm, setPaymentDeatilsForm] = useState(true);
  const [paymentAddForm, setPaymentAddForm] = useState(false);
  const [paymentConfirmForm, setPaymentConfirmForm] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ paymentAccountList,setPaymentAccountList] = useState([]);
  const [selectedPaymentAccountList, setSelectedPaymentAccountList] = useState(null)
  const [ filteredAccountList,setFilteredAccountList,] = useState({})
  const [paidAmount, setPaidAmount] = useState(0.00);
  const [paymentSubmitForm, setPaymentSubmitForm] = useState(null);
  const [bdtRate, setBdtRate] = useState(0.00);
  const [formData, setFormData] = useState({
    name: "",
    method_id: 0,
    account_id: 0,
    account_number: "",
    methodLabel: "",
    accountLabel: "",
    type_name: "",
  });
  const getPayment = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success){
        loadingCallback(false)
        setPaymentData([...paymentData, response?.data?.data?.data[0]])
        setPaymentList(response?.data?.data?.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  const [exporterInfo, setExporterInfo] = useState({});
  const [exporterProducts, setExporterProducts] = useState([]);
  const [totalCost, setTotalCost] = useState([]);
  const [grandTotalAfterRate, setGrandTotalAfterRate] = useState(0.00);
  const [currency, setCurrency] = useState(null);
  useEffect(()=>{
    getPayment();
    getPaymentAccountList()
    getPaymentDetails(exporterOrder?.id)
  },[exporterOrder ])

  useEffect(() => {
    //bdtRate
    if (currency && totalCost) {
      setBdtRate(currency?.rate_in_base_currency)
    }
  }, [currency]);
  useEffect(() => {
    //bdtRate
    if (bdtRate==0.00) {
      setGrandTotalAfterRate((totalCost?.sum_total_product_price) * 1);
    } else {
      setGrandTotalAfterRate((totalCost?.sum_total_product_price) * bdtRate);
    }
  }, [bdtRate,totalCost]);
  const getPaymentDetails = (id) => {
    //loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}exporter-order-payment-details/${id}`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        //loadingCallback(false)
        setPaymentDetails(response?.data?.data);
        setExporterInfo(response?.data?.data?.exporter_info[0]);
        setExporterProducts(response?.data?.data?.exporter_products);
        setTotalCost(response?.data?.data?.grand_total);
        setCurrency(response?.data?.data?.currencies);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  // console.log("============");
  // console.log(paymentDetails?.exporter_info[0]);

  const getPaymentAccountList = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-category-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        loadingCallback(false)
        setPaymentAccountList(response?.data?.data?.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };

  const handlePaymentDeatilsSubmit = async (event) => {
    event.preventDefault();

    setPaymentDeatilsForm(false);
    setPaymentAddForm(true);

    updateModalTitle("Payment Info");
  };

  const handlePaymetMethodSubmit = (paymentSubmitForm) => {
    if(paymentSubmitForm){
      setPaymentDeatilsForm(false);
      setPaymentAddForm(false);
      setPaymentConfirmForm(true);
      updateModalTitle("Payment Details");
    }
  };

  const handlePaymentConfirmSubmit = async (event) => {
    event.preventDefault();
    submitPayment(paymentSubmitForm);

    // setShowModal(false)
  };
  const submitPayment = (paymentSubmitForm) => {
    paymentSubmitForm.currency_rate=bdtRate ? bdtRate : 1;
    paymentSubmitForm.exporter_id=exporterOrder ? exporterOrder.exporter_id : null;
    //loadingCallback(true)
    axios.post(
      `${process.env.REACT_APP_API_URL}save-exporter-order-payment`,paymentSubmitForm,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {

      if(response?.data.success){

        //loadingCallback(false)
        setPaymentDeatilsForm(false);
        setPaymentAddForm(false);
        setPaymentConfirmForm(false);
        updateModalTitle("Payment Details");
        setSuccessFlashMessage('payment successfully added')
        setPayment(true)
        setShowModal(false)
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };

  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;

  return (
    <>
      {paymentDeatilsForm ? (
        <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handlePaymentDeatilsSubmit}
        >
          <div className="font-nunito font-bold text-[#16px] tex-[#000232] mb-2">
            Payment Description
          </div>

          <div className="grid grid-cols-4 gap-x-2 gap-y-5 mb-4">
            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Exporter Name</div>
              <div className="font-[400] text-[#000000]">{exporterOrder?.name}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Email</div>
              <div className="font-[400] text-[#000000] break-words">{exporterOrder?.email}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Phone Number</div>
              <div className="font-[400] text-[#000000]">{exporterOrder?.phone_no}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Organization</div>
              <div className="font-[400] text-[#000000]">{exporterOrder?.org_name}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Total QTY</div>
              <div className="font-[400] text-[#000000]">{exporterInfo ? exporterInfo?.total_qty : null}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Address</div>
              <div className="font-[400] text-[#000000]">{exporterOrder?.address}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Additional Phone</div>
              <div className="font-[400] text-[#000000] break-words">{exporterOrder?.additional_phone_no}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">
                Organization Email
              </div>
              <div className="font-[400] text-[#000000] break-words">
                {exporterOrder?.org_email}
              </div>
            </div>
          </div>

          <div className="mb-[0px]">
            <div className="flex flex-row gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
              <div className="py-[5px] flex-1">
                Product
                <br />
                Name
              </div>
              <div className="py-[5px] text-center flex-1">QTY</div>
              <div className="py-[5px] text-center flex-1">
                Number
                <br />
                of Carrier
              </div>
              <div className="py-[5px] text-right w-[25%]">Total Price</div>
            </div>
            {exporterProducts ? exporterProducts.map((item,index) => {
                totalQty += Number(item.qty);
                totalItems += 1;
                totalPrice += Number(item?.total_product_price);
                return (
                  <div key={index} className="flex flex-row gap-2 font-nunito font-[500] text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
                    <div className="py-[5px] flex-1">{item?.product_name}</div>
                    <div className="py-[5px] text-center flex-1 ">{item?.qty}</div>
                    <div className="py-[5px] text-center flex-1">{item?.no_of_carrier}</div>
                    <div className="py-[5px] text-right w-[25%]">{item?.total_product_price} {currency?.purchase_currency} </div>
                  </div>
                )
            }) : null}
          </div>

          <div className="flex flex-row gap-2 font-nunito font-bold text-[14px] text-[#000232] py-[5px] border-b-[1px] border-solid border-[#E7EBF4]">
            Price
          </div>

          <div className="font-nunito font-[500] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
            <div className="flex flex-row justify-between ">
              <div className="py-[5px]">Product Price</div>
              <div className="py-[5px] text-right">{totalCost?.sum_total_product_price} {currency?.purchase_currency}</div>
            </div>
          </div>


          <div className="font-nunito font-[600] text-[14px] overflow-hidden mt-[10px] mb-[25px]">
            <div className="float-right w-[70%]">
              <div className="flex flex-row gap-5 justify-between">
                <div className="py-[5px]">Grand Total</div>
                <div className="py-[5px]">{totalCost?.sum_total_product_price} {currency?.purchase_currency}</div>
              </div>

              <div className="flex flex-row gap-2 justify-between">
                <div className="py-[5px]">BDT Rate</div>
                <div className="py-[5px]">
                  <input
                    type="text"
                    className={`w-[80px] text-right text-[#102048] font-semibold placeholder:text-[#91919F] bg-transparent outline-none text-sm rounded-[5px] border border-[#C1CFEF] focus:outline-none p-2`}
                    placeholder="0.00"
                    value={
                      bdtRate
                        // ? paidAmount
                        // :
                        // Number(grandTotalAfterDiscount) - due
                    }
                    //style={inputStyle}
                    onChange={(e) => setBdtRate(e.target.value)}
                    name={"bdt_rate"}
                  />

                  {baseCurrency}
                </div>
              </div>

              <div className="flex flex-row gap-2 justify-between border-b-[1px] border-solid border-[#E7EBF4]">
                <div className="py-[5px]">Grand Total in BDT</div>
                <div className="py-[5px]">{bdtRate ? formatNumber(grandTotalAfterRate) : formatNumber(totalCost?.sum_total_product_price)} BDT</div>
              </div>

              <div className="flex flex-row gap-2 justify-between">
                <div className="py-[5px]">Due Amount</div>
                <div className="py-[5px]">{totalCost?.due_amount ? totalCost?.due_amount : '0.00'} BDT</div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-3">
            <input
              onClick={modalClose}
              type="button"
              value="Back"
              className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
            />
            <input
              type="submit"
              value="Pay Now"
              className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
            />
          </div>
        </form>
      ) : paymentAddForm ? (
        <ExporterPaymentTypeForm
          formId='my_modal_payment'
          dueAmount={bdtRate ? grandTotalAfterRate : totalCost?.sum_total_product_price}
          paymentData={paymentData}
          paymentList={paymentList}
          paymentAccountList={paymentAccountList}
          setData={setPaymentData}
          formData={formData}
          exporterInfo={exporterInfo}
          exporterProducts={exporterProducts}
          totalCost={totalCost}
          handlePaymetMethodSubmit={handlePaymetMethodSubmit}
          setPaymentDeatilsForm={setPaymentDeatilsForm}
          setPaymentAddForm={setPaymentAddForm}
          setPaymentConfirmForm={setPaymentConfirmForm}
          updateModalTitle={updateModalTitle}
          setFormData={setFormData}
          filteredAccountList={filteredAccountList}
          setFilteredAccountList={setFilteredAccountList}
          setPaidAmount={setPaidAmount}
          loadingCallback={loadingCallback}
          selectedAccountList={selectedPaymentAccountList}
          setSelectedAccountList={setSelectedPaymentAccountList}
          navigate={navigate}
          setShowModal={setPaymentAddForm}
          isOpen={paymentAddForm}
          title='payment info'
          setWarningMessage={setWarningMessage}
          type='payment'
          exporterOrder={exporterOrder}
          exporterOrderId={exporterOrder?.id}
          paymentSubmitForm={paymentSubmitForm}
          setPaymentSubmitForm={setPaymentSubmitForm}
        />
      ) : paymentConfirmForm ? (
        <form
          method="get"
          className="col-sm-12 min-h-[10vh]"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handlePaymentConfirmSubmit}
        >
          <div className="font-nunito font-bold text-[#16px] tex-[#000232] mb-2">
            Payment Description
          </div>

          <div className="grid grid-cols-4 gap-x-2 gap-y-5 mb-4">
            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Exporter Name</div>
              <div className="font-[400] text-[#000000]">{exporterOrder?.name}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Email</div>
              <div className="font-[400] text-[#000000] break-words">{exporterOrder?.email}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Phone Number</div>
              <div className="font-[400] text-[#000000]">{exporterOrder?.phone_no}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Organization</div>
              <div className="font-[400] text-[#000000]">{exporterOrder?.org_name}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Total QTY</div>
              <div className="font-[400] text-[#000000]">{exporterInfo ? exporterInfo?.total_qty : null}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Address</div>
              <div className="font-[400] text-[#000000] break-words">{exporterOrder?.address}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">Additional Phone</div>
              <div className="font-[400] text-[#000000]">{exporterOrder?.additional_phone_no}</div>
            </div>

            <div className="font-nunito text-[12px]">
              <div className="font-[700] text-[#6F8EB6]">
                Organization Email
              </div>
              <div className="font-[400] text-[#000000] break-words">
                {exporterOrder?.org_email}
              </div>
            </div>
          </div>

          <div className="mb-[0px]">
            <div className="flex flex-row gap-2 font-nunito font-bold text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
              <div className="py-[5px] flex-1">
                Product
                <br />
                Name
              </div>
              <div className="py-[5px] text-center flex-1">QTY</div>
              <div className="py-[5px] text-center flex-1">
                Number
                <br />
                of Carrier
              </div>
              <div className="py-[5px] text-right w-[25%]">Total Price</div>
            </div>

            {exporterProducts ? exporterProducts.map((item,index) => (
              <div key={index} className="flex flex-row gap-2 font-nunito font-[500] text-[12px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
                <div className="py-[5px] flex-1">{item?.product_name}</div>
                <div className="py-[5px] text-center flex-1 ">{item?.qty}</div>
                <div className="py-[5px] text-center flex-1">{item?.no_of_carrier}</div>
                <div className="py-[5px] text-right w-[25%]">{item?.total_product_price} {currency?.purchase_currency}</div>
              </div>
            )) : null}
          </div>

          <div className="flex flex-row gap-2 font-nunito font-bold text-[14px] text-[#000232] py-[5px] border-b-[1px] border-solid border-[#E7EBF4]">
            Price
          </div>

          <div className="font-nunito font-[500] text-[14px] text-[#000232] border-b-[1px] border-solid border-[#E7EBF4]">
            <div className="flex flex-row justify-between ">
              <div className="py-[5px]">Product Price</div>
              <div className="py-[5px] text-right">{formatNumber(totalCost?.sum_total_product_price)} {currency?.purchase_currency}</div>
            </div>
          </div>


          <div className="font-nunito font-[600] text-[14px] overflow-hidden mt-[10px] mb-[25px]">
            <div className="float-right w-[70%]">
              <div className="flex flex-row gap-5 justify-between">
                <div className="py-[5px]">Grand Total</div>
                <div className="py-[5px]">{formatNumber(totalCost?.sum_total_product_price) } {currency?.purchase_currency}</div>
              </div>

              <div className="flex flex-row gap-2 justify-between">
                <div className="py-[5px]">BDT Rate</div>
                <div className="py-[5px]">{bdtRate}</div>
              </div>

              <div className="flex flex-row gap-2 justify-between">
                <div className="py-[5px]">Grand Total in BDT</div>
                <div className="py-[5px]">{bdtRate ? formatNumber(grandTotalAfterRate) : formatNumber(totalCost?.sum_total_product_price)} {baseCurrency}</div>
              </div>

              <div className="flex flex-row gap-2 justify-between border-b-[1px] border-solid border-[#E7EBF4]">
                <div className="py-[5px]">Paid Amount</div>
                <div className="py-[5px]">{formatPrice(paymentSubmitForm?.paidAmount) } {baseCurrency}</div>
              </div>

              <div className="flex flex-row gap-2 justify-between">
                <div className="py-[5px]">Due Amount</div>
                <div className="py-[5px]">{formatNumber(bdtRate ? grandTotalAfterRate-paymentSubmitForm?.paidAmount : totalCost?.sum_total_product_price - paymentSubmitForm?.paidAmount)} {baseCurrency}</div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-3">
            <input
              onClick={() => {
                setPaymentDeatilsForm(false);
                setPaymentAddForm(true);
                setPaymentConfirmForm(false);
                setPaymentSubmitForm(null)
                setSelectedPaymentAccountList(null)
                setPaymentAccountList([])
                updateModalTitle("Payment Info");
              }}
              type="button"
              value="Back"
              className="btn w-[48%] text-[#034AFF] rounded-[16px] bg-[#FFF] hover:bg-[#FFF] border-[1px] border-solid border-[#034AFF] hover:text-[#034AFF] hover:border-[#034AFF] hover:outline-none capitalize"
            />
            <input
              type="submit"
              value="Confirm"
              className="btn w-[48%] text-[#FFF] rounded-[16px] bg-[#034AFF] hover:bg-[#034AFF] hover:text-[#FFF] capitalize"
            />
          </div>
        </form>
      ) : null}
    </>
  );
};

export default ExporterPaymentDetailForm;
