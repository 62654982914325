import React from 'react';
import LogoAlignLeft from './bigLogo.png';
import { formatDate, formatPrice } from "../../utils/Common";
import MethodCreditReportItem from './MethodCreditReportItem';
import MethodDebitReportItem from './MethodDebitReportItem';
import BankBalanceHistoryItem from './BankBalanceHistoryItem';
const MethodReport = (props) => {
  const { activeTab, balanceData, fullApiData, paymentList, paymentAccountList } = props;
  let createdDate, updatedDate;
  balanceData.map(bd => {
    createdDate = bd.created_at;
    updatedDate = bd.updated_at;
  })
  let totalCredit = 0, totalDebit = 0,totalBankAmount=0;
  fullApiData?.credit?.map(cr => {
    totalCredit += cr?.payment_amount;
  })
  fullApiData?.debit?.map(cr => {
    totalDebit += cr?.payment_amount;
  })
  fullApiData?.balances?.map(cr => {
    totalBankAmount += cr?.total_payment_amount;
  })
  return (
    <>

      <div
        className="bg-white rounded-3xl font-nunito p-[26px] mb-20"
      >
        <div className="flex justify-between items-center">
          {/* outlet logo */}
          <div className="w-20 cursor-pointer -pb-[4px]">
            <img src={LogoAlignLeft} className="w-full h-full object-contain" />
          </div>
          {/* title and date */}
          <div className="text-[#000232] text-right">
            <h2 className="text-sm font-semibold">Method Report</h2>
            <p className="text-xs">{formatDate(createdDate)} - {formatDate(updatedDate)}</p>
          </div>
        </div>
        {/* credit report */}
        <div>
          <p className='text-2xl py-3 text-blue-600'>Credit</p>
          {/* table head */}
          <div className="grid  mt-2 pt-[4px] pb-[18px]  text-xs font-bold  grid-cols-3 border-b-[1px] border-[#E7EBF4]">

            <div className='text-left'>Bank</div>
            <div className='text-center'>Account</div>
            <div className='text-right'>Amount</div>
          </div>
          {/* table body component */}
          {fullApiData?.credit?.map((cr, index) => {
            return (<>
              <MethodCreditReportItem creditData={cr} key={index} />
            </>)

          })}

          <div className='flex justify-end gap-20'><p className=''>Total Amount</p><span className='text-center'>{totalCredit}</span></div>
        </div>
        {/* Debit report */}
        <div>
          <p className='text-2xl py-3 text-blue-600'>Debit</p>
          {/* table head */}
          <div className="grid  mt-2 pt-[4px] pb-[18px] text-xs font-bold    grid-cols-3 border-b-[1px] border-[#E7EBF4]">

            <div className='text-left'>Bank</div>
            <div className='center'>Account</div>
            <div className='text-right'>Amount</div>
          </div>
          {/* table body component */}
          {fullApiData?.debit?.map((cr, index) => {
            return (<>
              <MethodDebitReportItem creditData={cr} key={index} />
            </>)

          })}

          <div className='flex justify-end gap-20'><p className=''>Total Amount</p><span className='text-center'>{formatPrice(totalCredit)}</span></div>
        </div>

        {/* Mobile Bank report */}
        <div>
          <p className='text-2xl text-blue-600'>Bank Balance History</p>
          {/* table head */}


          {/* table body component */}
          {fullApiData?.balances?.map((cr, index) => {
            return (<>
              <BankBalanceHistoryItem creditData={cr} key={index} />
            </>)

          })}
         <hr className="border-[#363749] border-[1px] mt-[10px]" />
          <div className='flex justify-end gap-20'><p className=''>Total Balance Amount</p><span className='text-center'>{totalBankAmount}</span></div>
        </div>
      </div>
    </>
  );
};

export default MethodReport;