import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

import ContentLayout from "../../layout/ContentLayout";
import ProductsList from "../../components/VendorProfile/ProductsList/Index";

const VendorMostPurchasePage = () => {
  return (
    <>
      <ContentLayout>
        <ProductsList
        type="vendor"
        />
      </ContentLayout>
    </>
  );
};

export default VendorMostPurchasePage;
