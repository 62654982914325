// import React from 'react';

// const TransferBox = () => {
//   return (
//     <div>

//     </div>
//   );
// };

// export default TransferBox;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ItemSelectBox2 from '../../forms/ItemSelectBox2';
import Select from 'react-select';
const token = localStorage.getItem("token");


const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '10px',
    borderColor: '#C1CFEF',
    width: '100%',
    //boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#C1CFEF' : 'white',
    color: '#102048',
    '&:hover': {
      backgroundColor: '#C1CFEF',
    },
  }),
};

const TransferBox = ({paymentAccountList, selectOption, setSelectOption, handleFundTransfer}) => {
  const AccountList = paymentAccountList.data;
  console.log("payment account", AccountList);

  const [showError, setShowError] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleChangeSelect = ({lavel, value}) =>{
    setSelectOption({...selectOption, account_from: value});
  }

  const handleChangeSelect2 = ({lavel, value}) =>{
    setSelectOption({...selectOption, account_to: value});
  }

  const handleTransfer = () =>{
    if(selectOption.account_from==selectOption.account_to ||
      Number(selectOption.account_from)<1 ||
      Number(selectOption.account_to)<1 ||
        Number(selectOption.amount)<1
      ) {
      return;
    }

    handleFundTransfer();
  }

  return (
    <div className=" rounded-[25px] bg-[#fff] my-3">
        <div className='px-5 pt-5'>
          <h1 className='text-2xl font-semibold'>Fund Transfer</h1>
        </div>
      <div className='flex flex-col lg:flex-row justify-center items-center p-5'>
        <div className='w-full relative'>
          <div className='flex flex-col gap-3'>
            <div className='w-full relative'>
              <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">From</label>
              <Select
                  onChange={(event) => {
                    handleChangeSelect(event);
                  }}
                  styles={customStyles}
                  className={`select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none px-[0px]  text-md focus:outline-none`}
                  onMenuOpen={()=>setIsMenuOpen(true)}
                  onMenuClose={()=>setIsMenuOpen(false)}
                  options={ AccountList?.map((account) => ({
                    value: account?.id,
                    label: account?.payment_category_name?.toLowerCase() === 'cash'
                    ? account.payment_category_name
                    : account.payment_category_name + " (" + (account?.account_number ? account?.account_number : "") + ")"
                  }))}
                  // options={[
                  //   { value: null, label: 'Select account (From)' },
                  //   ...AccountList?.map((account) => ({
                  //     value: account?.id,
                  //     label: account.payment_category_name === 'cash'
                  //       ? account.payment_category_name
                  //       : account.payment_category_name + " (" + (account?.account_number ? account?.account_number : "") + ")"
                  //   }))
                  // ]}
                />
            </div>
            <div className='w-full relative'>
              <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">To</label>
              <Select
                onChange={(event) => {
                  handleChangeSelect2(event);
                }}
                styles={customStyles}
                className={`select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none px-[0px]  text-md focus:outline-none`}
                onMenuOpen={()=>setIsMenuOpen(true)}
                onMenuClose={()=>setIsMenuOpen(false)}
                options={ AccountList?.map((account) => ({
                  value: account?.id,
                  label: account?.payment_category_name?.toLowerCase() === 'cash'
                  ? account.payment_category_name
                  : account.payment_category_name + " (" + (account?.account_number ? account?.account_number : "") + ")"
                }))}
              />
            </div>
          </div>

        </div>
        <div className='w-16 relative'>
          {/* <div className='mx-3 md:my-3'> */}
            <div
                className="cursor-pointer transform hover:scale-110 duration-200 mx-3 my-3"
                // onClick={handleChangeTable}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17.85 14.1C17.81 14.19 17.76 14.27 17.69 14.34L14.65 17.38C14.5 17.53 14.31 17.6 14.12 17.6C13.93 17.6 13.74 17.53 13.59 17.38C13.3 17.09 13.3 16.61 13.59 16.32L15.35 14.56H6.85C6.44 14.56 6.1 14.22 6.1 13.81C6.1 13.4 6.44 13.06 6.85 13.06H17.16C17.26 13.06 17.35 13.08 17.45 13.12C17.63 13.2 17.78 13.34 17.86 13.53C17.92 13.71 17.92 13.92 17.85 14.1ZM17.15 10.93H6.85C6.75 10.93 6.65 10.91 6.56 10.87C6.38 10.79 6.23 10.65 6.15 10.46C6.07 10.28 6.07 10.07 6.15 9.89C6.19 9.8 6.24 9.72 6.31 9.65L9.35 6.61C9.64 6.32 10.12 6.32 10.41 6.61C10.7 6.9 10.7 7.38 10.41 7.67L8.66 9.43H17.16C17.57 9.43 17.91 9.77 17.91 10.18C17.91 10.59 17.57 10.93 17.15 10.93Z"
                    fill="#407BFF"
                  />
                </svg>
            </div>
          {/* </div> */}
        </div>
        <div className='w-full relative'>
          <div className='flex flex-col gap-3'>
           <div className='w-full relative'>
            <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            Amount</label>
            <input
              type='text'
              name="name"
              placeholder='000'
              value={ selectOption.amount }
              onChange={(e)=>{
                setSelectOption({...selectOption, amount: e.target.value}),
                setShowError(false)
              }}
              className={`input input-bordered border-2 ${ showError? 'border-red-500' : 'border-gray-300'}  w-full rounded-xl bg-white`}
            />
          </div>
          <div
            onClick={handleTransfer}
            className='flex items-center cursor-pointer transform hover:scale-105 duration-200 justify-center'>
            <button className='bg-[#034AFF] w-full rounded-xl py-[10px] text-[#ffffff]'>Transfer</button>
          </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default TransferBox;