import React from "react";

const InputField = ({ label, type, name, value, onChange }) => {
  return (
    <div className="relative mb-4">
      <label className="absolute -top-2 left-2 px-1 bg-[#FAFAFA] text-[#102048] font-semibold text-xs z-10">
        {label}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:border-blue-500 focus:outline-blue-500 z-0 placeholder:text-sm"
      />
    </div>
  );
};

export default InputField;
