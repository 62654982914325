import React from "react";
import { Helmet } from "react-helmet-async";
import { RiFileList3Line } from "react-icons/ri";
import TermsAndConditionForm from "./TermsAndConditionForm";

const TermsAndCondition = () => {
  return (
    <div className="flex items-center  bg-[#FAFAFA] rounded-[27px] py-10">
      <Helmet>
        <title>Outlet Expense | Settings | T&C</title>
      </Helmet>

      <div className="flex flex-col items-start">
      <div className="flex justify-between items-center px-10 md:px-[27px] pt-[29px] pb-[13px]">
        <h4 className="font-semibold font-lato text-[22px] text-[#000232] capitalize flex items-center gap-2">
          <p>Terms and Condition</p>
          <span className="text-xl">
            <RiFileList3Line  />
          </span>
        </h4>

        {/* <p className="font-semibold font-lato text-xs text-[#034AFF] underline text-right">
                    <a href="#">View All</a>
                </p> */}
      </div>

          {/* ---form---- */}

        <TermsAndConditionForm />


      </div>
    </div>
  );
};

export default TermsAndCondition;
