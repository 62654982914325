import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/context.js";
import EmailVerify from "./emailVerify/Index";
import PhoneInfo from "./fifth/Index";
import FirstStep from "./first/Index";
import StoreInfo from "./fourth/Index";
import "./index.css";
import SecondStep from "./second/Index";
import ConfirmSecurityPin from "./securitypin/ConfirmSecurityPin";
import SecurityPin from "./securitypin/SecurityPin";
import PasswordVerify from "./seventh/Index";
import OtpInputs from "./sixth/Index";
import ThirdStep from "./third/Index";

function SignUp() {
  const {
    state,
    isLoading,
    setCurrentUser,
    updateCurrentPage,
    updateNextPage
  } = useContext(AppContext);
  const [page, setPage] = useState(state.currentPage === "login" ? 3 : 0);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const isPinVerify = localStorage.getItem("isPinVerify");
  const employee = localStorage.getItem("employee");

  useEffect(() => {
    if (token != "false" && employee) {
      navigate("/dashboard");
    }
    else if (token != "false" && isPinVerify != "false") {
      navigate("/dashboard");
    } else if (token != "" && token != "false" && isPinVerify == "false") {
      console.log("signup")
      navigate("/verifypin");
    }
  }, []);

  const FormTitles = [
    "First Step",
    "Second Step",
    "Third Step",
    "StoreName",
    "PhoneInfo",
    "EmailVerify",
    "OtpInputs",
    "PasswordVerify",
    "scurityPin",
    "ConfirmscurityPin",
  ];

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <FirstStep continueSubmit={continueSubmit} gotoLogin={gotoLogin} />
      );
    } else if (page === 1) {
      return (
        <SecondStep backToprev={backToprev} continueSubmit={continueSubmit} />
      );
    } else if (page === 2) {
      return (
        <ThirdStep backToprev={backToprev} continueSubmit={continueSubmit} />
      );
    } else if (page === 3) {
      return <StoreInfo continueSubmit={continueSubmit} />;
    } else if (page === 4) {
      return <PhoneInfo continueSubmit={continueSubmit} />;
    } else if (page === 5) {
      if (process.env.REACT_APP_OTP_DISABLED == "true") {
        return <PasswordVerify continueSubmit={continueSubmit} />;
      } else {
        return <EmailVerify continueSubmit={continueSubmit} />;
      }
    }
    // else if (page == 6) {
    //   if(process.env.REACT_APP_OTP_DISABLED == "true") {
    //     return <SecurityPin continueSubmit={continueSubmit} />;
    //   } else {
    //     return <OtpInputs continueSubmit={continueSubmit} />;
    //   }
    // }
    else if (page == 6) {
      if (process.env.REACT_APP_OTP_DISABLED == "true") {
        return <ConfirmSecurityPin />;
      } else {
        return <PasswordVerify continueSubmit={continueSubmit} />;
      }
    } else if (page == 7) {
      return <SecurityPin continueSubmit={continueSubmit} />;
    } else if (page == 8) {
      return <ConfirmSecurityPin />;
    }
  };
  const continueSubmit = () => {
    if (page === FormTitles.length - 1) {
      // alert("FORM SUBMITTED");
      console.log("FORM SUBMITTED");
    } else {
      setPage((currPage) => currPage + 1);
    }
  };
  const backToprev = () => {
    setPage((currPage) => currPage - 1);
  };
  const gotoLogin = () => {
    navigate("/login");
  };
  return (
    <div className="form" style={{ height: '100%' }}>
      <div className="form-container">
        <div className="body">{PageDisplay()}</div>
        {page > 2 ? (
          ""
        ) : (
          <div className="footer">
            <button
              disabled={page == 0}
              onClick={backToprev}
              className={page == 0 ? "dpnone" : ""}
            >
              Back
            </button>
            {process.env.REACT_APP_OTP_DISABLED_SIGNUP == "true"
              ?
              <button onClick={page == 0 ? gotoLogin : continueSubmit}>
                {page === FormTitles.length - 1 ? "Submit" : "Continue"}
              </button>
              :
              <button onClick={continueSubmit}>
                {page === FormTitles.length - 1 ? "Submit" : "Continue"}
              </button>
            }
            {page == 0 ? <button onClick={gotoLogin} className="md:absolute md:left-48">Skip</button> : ""}
          </div>
        )}
      </div>
    </div>
  );
}

export default SignUp;
