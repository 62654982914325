
import React, { useContext } from 'react';
import { AppContext } from '../../context/context';
import {useNavigate, Link } from 'react-router-dom';
import "../products/productlist/index.scss"
import {MdDelete,MdModeEdit} from 'react-icons/md';



const BarcodeItem = props => {
  const { barcode, className } = props;
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;
  const navigate = useNavigate();
  
  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/editproduct/${barcode.id}`);
  };
	const shop_logo = `${BASE_URL}/layoutlogo.svg`;
  return (
		<div className="productlist-table">
			<div className="table-content">
				<div className="table-content-image">
					<img src={barcode?.image_path ? barcode.image_path : shop_logo} alt="img" />
				</div>
				<div className="first">
						<div className="firstdata">
								<h1>{barcode.name}</h1>
								<p>{barcode.brand_name}</p>
						</div>
				</div>
				<div className="third">
						<h1>{barcode.price}</h1>
						<p>{barcode.category_name}</p>
				</div>
			</div>

			<div className="table-action-barcode">
				<div className="table-action-delete">
					<button className="flex text-[10px] justify-center items-center text-[#E53E3E] bg-transparent">
						<i className={`fa-solid fa-print icon`}></i>
					</button>
				</div>
				<div className="table-action-edit">
					<button className="flex text-[10px] justify-center items-center text-[#E53E3E] bg-transparent">
						<Link to={`/editproduct/${barcode.id}`}><i className={`fa-solid fa-pencil icon`}></i></Link>
					</button>
				</div>
			</div>
		</div>
  );
};

BarcodeItem.defaultProps = { className: null };

export default BarcodeItem;