import React, { useContext, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import CommonUploader from "./CommonUploader";
import Input from "./Input";
import DatePicker from "react-datepicker";
import axios from "axios";
import { FlashMessage } from "../utils/FlashMessage";
import { Modal } from "../utils/Modal";

const EmployeeAddForm = props => {

  const [formErrors, setFormErrors] = useState({});

  const {
    handleSubmitForm,
    countryList,
    formData,
    setErrorFlashMessage,
    districtList,
    cityList,
    handleChangeSelect,
    actionType,
    type,
    bloodGroupList,
    handleImageChange,
    designationList,
    setFormData,
    handleChange,
    handleDobDateChange,
    handleJoinDateChange,
    roleList,
    loadingCallback,
    navigate,
    departmentList,
    warehouseList
  } = props;

  const [isValid, setIsValid] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (handleValidation(formData)) {
      handleSubmitForm();
    } else {
      console.error('Form validation failed.');
    }
  };

  const handleValidation = (formData) => {
    const errors = {};
    let validate = true
    if (formData.emp_email && formData.emp_email !== '') {
      if (formData.emp_email.indexOf('@') === -1) {
        errors.emp_email = 'Email is not valid.';
        setIsValid(false);
        validate = false;
      }  else {
        //Email address cannot have only numbers before @
        const emailParts = formData.emp_email.split('@');
        if (emailParts[0].match(/^[0-9]+$/) !== null) {
          errors.emp_email = 'Email is not valid.';
          setIsValid(false);
          validate = false;
        }
      }
    }
    if (formData.w_email && formData.w_email !== '') {
      if (formData.w_email.indexOf('@') === -1) {
        errors.w_email = 'Email is not valid.';
        setIsValid(false);
        validate = false;
      }  else {
        //Email address cannot have only numbers before @
        const emailParts = formData.w_email.split('@');
        if (emailParts[0].match(/^[0-9]+$/) !== null) {
          errors.w_email = 'Email is not valid.';
          setIsValid(false);
          validate = false;
        }
      }
    }
    if (formData.emp_name==='') {
      errors.emp_name = 'name is required.';
      setIsValid(false);
      validate=false;

    }
    if (formData.emp_phone==='') {
      errors.emp_phone = 'Phone Number is required.';
      setIsValid(false);
      validate=false;
    }
    setFormErrors(errors);
    if (!validate) {
      // setErrorFlashMessage('Please fill all the required fields.');
      FlashMessage('Please fill all the required fields.','error');
    }
    return validate;
  };

  //----------
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const [selectedDays, setSelectedDays] = useState([]);
  const handleDayClick = (day) => {
    let updatedDays = [...selectedDays];
    if (updatedDays.includes(day)) {
      // Remove the day if it's already selected
      updatedDays = updatedDays.filter((d) => d !== day);
    } else {
      // Add the day if it's not selected
      updatedDays.push(day);
    }
    setSelectedDays(updatedDays);
    // Update formData with comma-separated string of selected days
    setFormData({
      ...formData,
      working_day: updatedDays.join(',')
    });
  };

  //------------
  //const userData = JSON.parse(localStorage.getItem('user'));

  const [userDataModalOpen, setUserDataModalOpen] = useState(false);

  const handleSaveClick = (e) => {
    e.preventDefault();
    setUserDataModalOpen(true);
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    setUserDataModalOpen(false);

    console.log("user data in formData?", formData)

    handleSubmit(e);
  };


  return(
    <>
    <div className="">
      <ToastContainer />
      <form
      method="post"
      data-autosubmit="false"
      autoComplete="off"
      onSubmit={handleSubmit}
      // onSubmit={(e) => {
      //   e.preventDefault();
      //   handleSaveClick(e);
      // }}
      >
        <div className="text-center flex items-center justify-center py-4">
          <CommonUploader
            name={"image"}
            isBig={true}
            onChange={handleImageChange}
            image={formData.image ? formData.image : null}
            className="w-[140px]"
          ></CommonUploader>
        </div>
        <div className="w-full pt-5">
          {/* Section 1 */}
          <div className="flex flex-col sm:flex-row gap-3 mb-4 font-semibold">Personal Information</div>
          <div className="flex flex-col sm:flex-row gap-3 mb-4">
            <div className="flex-1">
              <div className="relative">
                <Input
                  label="Name"
                  id="emp_name"
                  name="emp_name"
                  placeholder="Name"
                  value={formData.emp_name}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mb-4">
            <div className="flex-1">
              <div className="relative">
                <Input
                  label="Email"
                  id="emp_email"
                  name="emp_email"
                  placeholder="Email"
                  value={formData.emp_email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex-1">
                <Input
                  label="Phone"
                  id="emp_phone"
                  name="emp_phone"
                  placeholder="Phone"
                  value={formData.emp_phone}
                  onChange={handleChange}
                />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mb-4">
            <div className="w-[100%] sm:w-[30%] relative border-[#C1CFEF] border py-2.5 flex-1 rounded-xl">
              <DatePicker
                  selected={formData.dob}
                  onChange={handleDobDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  className="w-full px-3 focus:outline-none text-sm"
                />
                <span className="bg-white font-nunito absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                  Date of Birth
                </span>
            </div>
            <div className="w-[100%] sm:w-[30%] relative">
              <select
                name="blood_group"
                onChange={handleChange}
                value={formData.blood_group}
                className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <option disabled=""> Select Blood Group</option>
                {bloodGroupList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Blood Group
              </label>
            </div>
            <div className="w-[100%] sm:w-[40%]">
              <Input
                label="Address"
                id="address"
                name="address"
                placeholder="Address"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Section 2 */}
          <div className="flex flex-col sm:flex-row gap-3 mb-4 font-semibold mt-4">Emergency Information</div>
          <div className="flex flex-col sm:flex-row gap-3 mb-4">
            <div className="flex-1">
                <Input
                  label="Emergency Contact"
                  id="emergency_contact"
                  name="emergency_contact"
                  placeholder="Phone"
                  value={formData.emergency_contact}
                  onChange={handleChange}
                  // error={formErrors.customer_phone}
                  // setFormErrors={setFormErrors}
                />
            </div>
            <div className="flex-1">
              <div className="relative">
                <select
                  name="emergency_contact_rel"
                  className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                  onChange={handleChange}
                  value={formData.emergency_contact_rel}
                >
                  <option disabled=""> Select </option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Wife">Wife</option>
                  <option value="Husband">Husband</option>
                  <option value="Brother">Brother</option>
                  <option value="Sister">Sister</option>
                  <option value="Friend">Friend</option>
                  <option value="Collegue">Collegue</option>
                </select>
                <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                  Relationship with Emergency Contact
                </label>
              </div>
            </div>
          </div>

          {/* Section 3 */}
          <div className="flex flex-col sm:flex-row gap-3 mb-4 font-semibold mt-4">Professional Information</div>
          <div className="flex flex-col sm:flex-row gap-3 mb-4">
            <div className=" w-[100%] sm:w-[25%] relative">
              <div className="relative">
                <Input
                  label="Work Email"
                  id="w_email"
                  name="w_email"
                  placeholder="1234@gmail.com"
                  value={formData.w_email}
                  onChange={handleChange}
                  // error={formErrors.customer_email}
                  // setFormErrors={setFormErrors}
                />
              </div>
            </div>
            <div className="w-[100%] sm:w-[25%] relative">
              <div className="relative">
                <Input
                  label="Password"
                  id="password"
                  name="password"
                  placeholder="*******"
                  value={formData.password}
                  onChange={handleChange}
                  // error={formErrors.customer_email}
                  // setFormErrors={setFormErrors}
                />
              </div>
            </div>
            <div className="w-[100%] sm:w-[25%] relative">
              {/* <div className="relative">
                <Input
                  label="PIN"
                  id="pin"
                  name="pin"
                  placeholder="000000"
                  value={formData.pin}
                  onChange={handleChange}
                  // error={formErrors.customer_email}
                  // setFormErrors={setFormErrors}
                />
              </div> */}
              <select
                name="warehouse"
                onChange={handleChange}
                value={formData.warehouse}
                className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <option disabled=""> Select </option>
                {warehouseList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Warehouse
              </label>
            </div>
            <div className="w-[100%] sm:w-[25%] relative">
              {/* <div className="relative">
                <Input
                  label="PIN"
                  id="pin"
                  name="pin"
                  placeholder="000000"
                  value={formData.pin}
                  onChange={handleChange}
                  // error={formErrors.customer_email}
                  // setFormErrors={setFormErrors}
                />
              </div> */}
              <select
                name="department"
                onChange={handleChange}
                value={formData.department}
                className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <option disabled=""> Select </option>
                {departmentList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Department
              </label>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mb-4">
            <div className="w-[100%] sm:w-[20%] relative">
              <select
                name="designation"
                onChange={handleChange}
                value={formData.designation}
                className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <option disabled=""> Select Type </option>
                {designationList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Designation
              </label>
            </div>
            <div className="w-[100%] sm:w-[20%] relative">
              <select
                name="emp_role"
                onChange={handleChange}
                value={formData.emp_role}
                className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
              >
                <option disabled=""> Select Type </option>
                {roleList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Role
              </label>
            </div>
            <div className="w-[100%] sm:w-[20%] relative border-[#C1CFEF] border py-2.5 flex-1 rounded-xl">
              <DatePicker
                  selected={formData.join_date}
                  onChange={handleJoinDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  className="w-full px-3 focus:outline-none text-sm"
                />
                <span className="bg-white font-nunito absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                  Joining Date
                </span>
            </div>
            <div className="w-[100%] sm:w-[20%] relative">
              <select
                name="status"
                className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                style={{ maxHeight: "300px", overflowY: "scroll" }}
                onChange={handleChange}
                value={formData.status}
              >
                <option disabled=""> Select Type </option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Active/Inactive
              </label>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-3 mb-4">
                {days.map((day) => (
                  <div className="w-[100%] sm:w-[14%] relative" key={day}>
                    <div
                      className={`bg-white border-2 rounded-[16px] text-[18px] font-nunito font-[600] py-3 px-[50px] flex items-center justify-center mt-3 cursor-pointer
                        ${selectedDays.includes(day) ? 'text-blue-500 border-blue-500' : 'text-gray-400 border-gray-300'}`}
                      onClick={() => handleDayClick(day)}
                    >
                      {day}
                    </div>
                  </div>
                ))}
          </div>

          <div className="flex items-center justify-center mt-3">
            <button
              htmlFor="add_user_data_modal"
              type="button"
              className="bg-[#407BFF] rounded-[16px] text-[#FFF] text-[18px] font-nunito font-[600] py-3 px-[167px] flex items-center"
              onClick={handleSaveClick}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
    {userDataModalOpen ? (
          <Modal
          loadingCallback={loadingCallback}
          navigate={navigate}
          isOpen={userDataModalOpen}
          setOpen={setUserDataModalOpen}
          title="Verification"
          formId="add_user_data_modal"
          type="addUserDataModal"
          selectType="employee"
          formData={formData}
          setFormData={setFormData}
          handleModalSubmit={handleModalSubmit}
          handleChange={handleChange}
          />
        ) : (
          ""
        )}
    </>
  );
}

export default EmployeeAddForm;