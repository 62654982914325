import React from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const MethodDebitReportItem = (props) => {
  const {
    creditData
  } = props;


  return (
    <>

      <div className="border-b-[1px] text-sm border-solid  border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-3 items-start">
      <div className='text-left  text-[#000] font-[600]'>
          {creditData?.method_name}
      </div>
      <div className='text-center  text-[#000] font-[600]'>
          {creditData?.account?.payment_type_category?.account_number}
      </div>
      <div className='text-right  text-[#000] font-[600]'>
          {creditData?.payment_amount}
      </div>
      </div>

    </>
  );
};
MethodDebitReportItem.defaultProps = { className: null };
export default MethodDebitReportItem;
