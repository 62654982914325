import { Link } from "react-router-dom";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa6";
import { BsThreeDots } from "react-icons/bs";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaClock } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";

export const OrderTable = ({ orderList,setOrderList, type, formatPrice, subtotal, tax, handleSelectedFood,handleSelectedFoodQty, handleSelectedFoodQtyHoverMove  }) => {
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const dropdownRef = useRef(null); // Ref for dropdown
  const [count, setCount] = useState(1);

  // Function to close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenMenuIndex(null); // Close the dropdown when clicking outside
      }
    };

    // Add event listener to detect outside clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Toggle the dropdown
  const handleToggleDropdown = (event, inx) => {
    event.stopPropagation(); // Prevent the outside click event
    setOpenMenuIndex(inx === openMenuIndex ? null : inx);
  };

  // Handle delete logic
  const handleDelete = (indexToDelete) => {
    const updatedOrderList = orderList.filter((_, index) => index !== indexToDelete);
    setOrderList(updatedOrderList); // Update the state with the new order list
  };

  return (
    <>
      <div>
        <div className={`relative overflow-x-auto sm:rounded-lg min-h-40`}>
          {
            orderList?.length > 0 && <table className="w-full text-sm text-left rtl:text-left text-gray-500 dark:text-gray-400">{/*  */}
            <thead className="text-xs text-[#737373]  bg-gray-50 ">{/*dark:bg-gray-700 dark:text-gray-400  */}
              <tr className="border-b border-gray-300">
                {/* <div className="py-[8px]">ID</div> */}
                <th
                  scope="col"
                  className="px-6 py-1 text-[#737373] font-nunito font-bold text-xs"
                >
                  No.
                </th>
                <th
                  scope="col"
                  className="px-6 py-1 text-[#737373] font-nunito font-bold text-xs  w-[30%]"
                >
                  {" "}
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-1 text-[#737373] font-nunito font-bold text-xs "
                >
                  Stock{" "}
                </th>
                <th
                  scope="col"
                  className="px-6 py-1 text-[#737373] font-nunito font-bold text-xs"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="px-6 py-1 text-[#737373] font-nunito font-bold text-xs"
                >
                  Price
                </th>
                {/* <th
                  scope="col"
                  className="px-6 py-1 text-[#737373] font-nunito font-bold text-xs"
                >
                  Discount
                </th>
                <th
                  scope="col"
                  className="px-6 py-1 text-[#737373] font-nunito font-bold text-xs"
                >
                  Tax
                </th> */}
                <th
                  scope="col"
                  className="px-6 py-1 text-[#737373] font-nunito font-bold text-xs"
                >
                  Subtotal
                </th>
                <th
                  scope="col"
                  className="px-6 py-1 text-[#737373] font-nunito font-bold text-xs"
                >
                  Action
                </th>
              </tr>
            </thead>

            {/* Starting dynamic data */}
            {orderList &&
              orderList.map((orderedProduct, inx) => {
                let discount = 0;
                if (
                  (orderedProduct?.discount_type == "Percentage" ||
                    orderedProduct?.discount_type == "2") &&
                  orderedProduct?.discount
                ) {
                  discount =
                    type === "purchase"
                      ? formatPrice(0)
                      : type === "wholesale"
                      ? formatPrice(0)
                      : formatPrice(
                          orderedProduct.retails_price *
                            (orderedProduct.discount / 100)
                        );
                } else {
                  discount =
                    type === "purchase" || type === "wholesale"
                      ? 0
                      : orderedProduct?.discount;
                }
                //discount = orderedProduct.current_stock * orderedProduct.discount;
                let subtotalPrice = 0;

                if (type == "purchase") {
                  if (orderedProduct.variantsSum) {
                    subtotalPrice = orderedProduct.qty * subtotalPurchasePrice;
                  } else {
                    subtotalPrice = formatPrice(
                      orderedProduct.qty * orderedProduct?.purchase_price
                    );
                  }
                  subtotal += Number(subtotalPrice);
                } else if (type == "wholesale") {
                  subtotalPrice = formatPrice(
                    orderedProduct.qty *
                      (orderedProduct?.wholesale_price - discount)
                  );
                  subtotal += Number(subtotalPrice);
                } else {
                  subtotalPrice = formatPrice(
                    orderedProduct.qty *
                      (orderedProduct?.retails_price - discount)
                  );
                  subtotal += Number(subtotalPrice);
                }
                //vats = formatPrice(0.15 * subtotal);
                //taxes = formatPrice(0.1 * subtotal);
                const qty = orderedProduct.qty;

                return (
                  <tbody key={inx} className="">
                    {/* <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start"> */}
                    <tr className="bg-gray-50 border-b ">{/*dark:bg-gray-800 dark:border-gray-700  */}
                      <td
                        scope="row"
                        className="px-6 py-1 font-medium text-[#0A0A0A] whitespace-nowrap"
                      >{/* dark:text-white */}
                         {inx + 1}
                      </td>
                      <td className="px-6 py-1 font-nunito font-bold text-xs  w-[30%]">
                        {orderedProduct.name?.length > 20 ? (
                          <>{orderedProduct.name.slice(0, 15) + "..."}</>
                        ) : (
                          orderedProduct.name
                        )}
                      </td>
                      <td className="px-6 py-1 font-nunito font-bold text-xs">
                        {orderedProduct?.current_stock}
                      </td>
                      <td className="px-6 py-1 font-nunito font-bold text-xs">
                        <div className="flex items-center justify-between w-full h-[25px] bg-[#E5E5E5] rounded-md py-1 px-[6px]">
                          {/* Decrement Button */}
                          {type !== "purchase" &&
                          orderedProduct?.imeis?.length ? (
                            <button className="quantity-decrement flex items-center justify-center w-8 h-8 text-gray-600">
                              <FaCaretLeft />
                            </button>
                          ) : (
                            <button
                              className="quantity-decrement flex items-center justify-center w-8 h-8 text-gray-600"
                              onClick={() =>
                                handleSelectedFood(orderedProduct, "decrement")
                              }
                            >
                              <FaCaretLeft />
                            </button>
                          )}

                          {/* Quantity Input */}
                          <div className="flex-grow">
                            {orderedProduct?.imeis?.length ? (
                              <input
                                type="text"
                                value={qty}
                                className="text-center w-full bg-transparent text-[#0A0A0A] text-sm font-semibold "
                              />
                            ) : (
                              <input
                                type="text"
                                value={qty}
                                className="text-center w-full bg-transparent text-[#0A0A0A] text-sm font-semibold"
                                onChange={(e) =>
                                  handleSelectedFoodQty(orderedProduct, e)
                                }
                                onBlur={(e) =>
                                  handleSelectedFoodQtyHoverMove(
                                    orderedProduct,
                                    e
                                  )
                                }
                              />
                            )}
                          </div>

                          {/* Increment Button */}
                          {type !== "purchase" &&
                          orderedProduct?.imeis?.length ? (
                            <button className="quantity-increment flex items-center justify-center w-8 h-8 text-gray-600">
                              <FaCaretRight />
                            </button>
                          ) : (
                            <button
                              className="quantity-increment flex items-center justify-center w-8 h-8 text-gray-600"
                              onClick={() =>
                                handleSelectedFood(orderedProduct, "increment")
                              }
                            >
                              <FaCaretRight />
                            </button>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-1 font-nunito font-bold text-xs">
                        <h1 className="bg-[#E5E5E5] rounded-md py-1 px-[6px]">
                          {orderedProduct?.retails_price * qty}
                        </h1>
                      </td>
                      {/* <td className="px-6 py-1 font-nunito font-bold text-xs">
                        <h1 className="bg-[#E5E5E5] rounded-md py-1 px-[6px]">
                          {orderedProduct?.discount
                            ? orderedProduct?.discount
                            : 0}
                        </h1>
                      </td>
                      <td className="px-6 py-1 font-nunito font-bold text-xs">
                        <h1 className="bg-[#E5E5E5] rounded-md py-1 px-[6px]">
                          {tax}
                        </h1>
                      </td> */}
                      <td className="px-6 py-1 font-nunito font-bold text-xs">
                        <h1 className="bg-[#E5E5E5] rounded-md py-1 px-[6px]">
                          {subtotalPrice}
                        </h1>
                      </td>
                      {/* <div className="text-center">Sample</div> */}
                      <td className="px-6 py-1 font-nunito font-bold text-xs">
                        <div className="dropdown dropdown-left">
                          {/* Rounded button for toggling */}
                          <div
                            // onClick={(event) =>
                            //   handleToggleDropdown(event, inx)
                            // }
                            tabIndex={0} role="button"
                            className="text-[#737373] border border-[#737373] hover:border-[#407bff] focus:ring-1 focus:outline-none focus:ring-[#737373] font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
                          >
                            <BsThreeDots className="w-4 h-4" />
                          </div>
                          <div
                              // ref={dropdownRef}
                              tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
                            >
                              <div className="flex flex-col gap-1 py-2 px-2">
                                <Link
                                  to={`/products/${orderedProduct?.id}`}
                                  className="mb-2"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <label
                                    htmlFor="view_expense_modal"
                                    className="group flex items-center px-4 py-2 text-sm cursor-pointer bg-[#E5E5E5] font-semibold rounded-md text-[#0A0A0A] hover:bg-gray-100 "
                                  >
                                    <svg
                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M3 4h18v4H3z M3 10h18v10H3z"
                                      />
                                    </svg>
                                    Details
                                  </label>
                                </Link>

                                <Link to="" className="mb-2">
                                  <label
                                    htmlFor="edit_expense_modal"
                                    className="group flex items-center px-4 py-2 text-sm cursor-pointer bg-[#E5E5E5] font-semibold rounded-md text-[#0A0A0A] hover:bg-gray-100 "
                                  >
                                    <FaClock className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                                    Warranty
                                  </label>
                                </Link>


                                  <label onClick={() => handleDelete(inx)} className="mb-2 group flex items-center px-4 py-2 text-sm cursor-pointer bg-[#E5E5E5] font-semibold rounded-md text-[#0A0A0A] hover:bg-red-100">
                                    <RiDeleteBin5Fill className="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500" />
                                    Delete
                                  </label>

                              </div>
                            </div>

                          {/* Menu popup */}
                          {/* {openMenuIndex === inx && (

                          )} */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
          }
        </div>
      </div>
    </>
  );
};
