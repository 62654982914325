import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { formatNumber, formatPrice, scrollUp } from '../../utils/Common';
import { AppContext } from "../../context/context";
import ContentLayout from '../../layout/ContentLayout';
import { get } from "../../services/apiService";
import imageIcon from './Icon.svg';

const ProductDetailPage = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const {
    state
  } = useContext(AppContext);
  const { currentUser, invoiceSetting } = state;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };

  const [productData, setProductData] = useState(null);

  useEffect(() => {
    // Fetch product data based on the ID and populate
    fetchProductData(id);
  }, [id]);

  const fetchProductData = (productId) => {
    loadingCallback(true);
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    get("product-details/" + productId, token)
      .then((response) => {
        const productData = response.data; // Replace with your API response data

        loadingCallback(false);
        setProductData(productData);
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
          // Navigate to the '/login' route
        }
      });
  };

  let discount = 0;
  if ((productData?.discount_type == 'Percentage' || productData?.discount_type == '2') && productData?.discount) {
    discount = formatPrice(productData?.retails_price * productData?.discount / 100);
  } else {
    discount = productData?.discount;
  }

  const [selectedFoodImg, setSelectedFoodImg] = useState(null);
  const handleSelectedFoodImage = (imgPath = '') => {
    setSelectedFoodImg(imgPath);
  };
//scroll up
useEffect(()=>{
  scrollUp()
},[])
  return (
    <ContentLayout>
        {productData && (
          <div className="main-content font-nunito text-product-black">
            <div className="RecentOrdersTable-container flex flex-col md:flex-row gap-5 w-full">
              <div className="product-image">
                <div className="thumbnail-image w-[140px] h-[140px] md:w-[300px] md:h-[300px] bg-[#E7E7E7] rounded-lg overflow-hidden">
                  <img
                    className="w-full h-full object-cover mx-auto"
                    src={selectedFoodImg ? selectedFoodImg : (productData?.image_path ? productData.image_path : imageIcon)}
                    alt="image-icon"
                  />
                </div>

                <div className="flex gap-3 mt-[20px]">
                <div className="w-[80px] h-[80px] md:w-[141px] md:h-[141px] bg-[#E7E7E7] rounded-lg overflow-hidden">
                      <img
                        className="w-full h-full object-cover"
                      src={productData?.image_path}
                      onClick={() => {
                        handleSelectedFoodImage(productData?.image_path)
                      }}
                      alt="" />
                  </div>
                  {productData?.image_path1 || productData?.image_path1 ? (
                    <div className="w-[80px] h-[80px] md:w-[141px] md:h-[141px] bg-[#E7E7E7] rounded-lg overflow-hidden ">
                      <img
                        className="w-full h-full object-cover "
                        src={productData.image_path1}
                        onClick={() => {
                          handleSelectedFoodImage(productData.image_path1)
                        }}
                        alt=""
                      />
                    </div>
                  ) : null}
                  {productData?.image_path2 || productData?.image_path2 ? (

                    <div className="w-[80px] h-[80px] md:w-[141px] md:h-[141px] bg-[#E7E7E7] rounded-lg overflow-hidden">
                      <img
                        className="w-full h-full object-cover"
                        src={productData.image_path2}
                        onClick={() => {
                          handleSelectedFoodImage(productData.image_path2)
                        }}
                        alt=""
                      />
                    </div>

                  ) : null}

                </div>
              </div>
              <div className="product-info ">
                <div className="product-title ">
                  <h2 className=" font-bold text-4xl">{productData?.name}</h2>
                  <p className="text-gray-400 text-[18px] font-semibold">#{productData.barcode}</p>
                </div>
                <div className="mt-10 ">
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Category         </p>          <p>  : {productData?.category?.name}</p></div>
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Sub Category     </p>          <p>  : {productData?.sub_category?.name}         </p></div>
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Brand            </p>          <p>  : {productData?.brands?.name}          </p></div>
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Quantity         </p>          <p>  : {formatNumber(productData?.current_stock)} {productData?.unit?.name}s     </p></div>
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Unit             </p>          <p>  : {productData?.unit?.name}           </p></div>
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Purchase Price   </p>          <p>  : {formatNumber(productData?.purchase_price)} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</p></div>
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Discount         </p>          <p>  : {formatNumber(discount)}  {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'} </p></div>
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Retail Price    </p>          <p>  : {formatNumber(productData?.retails_price)}  {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}     </p></div>
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Wholesale Price  </p>          <p>  : {formatNumber(productData?.wholesale_price)}  {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'} </p></div>
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Others           </p>          <p>  : {productData?.others}          </p></div>
                  {/* <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Warranty Days    </p>          <p>  : {productData?.warrenty}           </p></div> */}
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Warranty Condition</p>          <p>  : {productData?.warrenty}</p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Warranty Days    </p>          <p>  : {productData?.warranties_count}</p></div>
                  <div className="text-[20px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Serial/IMEI Number    </p>          <p>  : {productData?.serial}           </p></div>

               {/* incoming */}
                  {/* <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Category         </p>          <p>  : {productData?.category?.name}</p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Sub Category     </p>          <p>  : {productData?.sub_category?.name}         </p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Brand            </p>          <p>  : {productData?.brands?.name}          </p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Quantity         </p>          <p>  : {formatNumber(productData?.current_stock)} {productData?.unit?.name}s     </p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Unit             </p>          <p>  : {productData?.unit?.name}           </p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Purchase Price   </p>          <p>  : {formatNumber(productData?.purchase_price)} {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}</p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Discount         </p>          <p>  : {formatNumber(discount)}  {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'} </p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Retails Price    </p>          <p>  : {formatNumber(productData?.retails_price)}  {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'}     </p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Wholesale Price  </p>          <p>  : {formatNumber(productData?.wholesale_price)}  {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : 'BDT'} </p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Warranty Condition</p>          <p>  : {productData?.warrenty}</p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Warranty Days    </p>          <p>  : {productData?.warranties_count}</p></div>
                  <div className="text-[18px] font-semibold grid grid-cols-2 gap-3"><p className="text-gray-400 ">Serial/IMEI Number</p>          <p>  : {productData?.serial}           </p></div> */}
               {/* incoming */}
                </div>
              </div>

            </div>
            <div className="feature-details p-3">
              <h2 className="font-bold text-[20px]">Feature & Details </h2>
              <p className="font-500 text-[17px]">{productData.description}</p>
            </div>
          </div>
        )}
        {loading && (

          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
          </div>
        )}
    </ContentLayout>
  );
};


export default ProductDetailPage