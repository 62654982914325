import React, { useState, useRef, useEffect } from "react";
import { AiOutlinePicLeft } from "react-icons/ai";
import { BsTextIndentLeft } from "react-icons/bs";
import { TbTargetArrow } from "react-icons/tb";
import { FaDraftingCompass, FaPercent } from "react-icons/fa";
import { LiaWpforms } from "react-icons/lia";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { MdOutlinePayment } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import toparrow from "../../../assets/images/toparrow.png";
import warrantyIcon from "./../../../assets/images/warranty-icon.png";
import { TbHelpHexagon } from "react-icons/tb";
import { Link } from "react-router-dom";
import menubarcss from "./MenuBar.module.css";
import { LuSettings } from "react-icons/lu";
import CustomNavLinkV2 from "./CustomNavLinkV2";
import { FaMoneyBill } from "react-icons/fa6";
import { LuUser2 } from "react-icons/lu";
import { IoSettingsOutline } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdOutlineVpnKeyOff } from "react-icons/md";

const MenuBarV2 = () => {

  const [showNavMenu , setShowNavMenu] = useState(false);
  const menuRef = useRef(null);

  const handleButtonClick = () => {
    setShowNavMenu((prevShowNavMenu) => !prevShowNavMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowNavMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="flex gap-[5%] justify-center md:justify-normal md:gap-[158px] mt-6 mb-3">
        <div className="Service w-1/2 mt-1 ml-3 md:ml-0">
          <h3 className="pb-2.5 font-lato font-bold text-[13px] text-[#7282a096]">
            Service Setup & History
          </h3>
          <div className="flex-none md:flex grid grid-cols-3 md:grid-cols-none md:flex-row gap-6">
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/category"
                menuName="Category"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p className="text-xl font-bold">
                  <AiOutlinePicLeft></AiOutlinePicLeft>
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/subcategory"
                menuName="Subcategory"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                  <BsTextIndentLeft className="text-xl font-bold"></BsTextIndentLeft>
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/brands"
                menuName="Brands"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                  <TbTargetArrow className="text-xl font-bold"></TbTargetArrow>
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/units"
                menuName="Units"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                  <FaDraftingCompass className="text-xl font-bold "></FaDraftingCompass>
                </p>
              </CustomNavLinkV2>
            </div>

            {/* <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings/discounts"
                menuName="Discounts"
                className="p-2.5 rounded-full"
                activeColor="rgba(255, 108, 2, 0.15)"
              >
                <p>
                  <FaPercent className="text-xl font-bold text-[#FF6C02] -scale-x-100"></FaPercent>
                </p>
              </CustomNavLinkV2>
            </div> */}
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/invoice-settings"
                menuName="Invoice Settings"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                  <IoSettingsOutline className="text-xl font-bold "></IoSettingsOutline>
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/shop-settings"
                menuName="Profile"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                  <LuUser2 className="text-xl font-bold "></LuUser2>
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/payment"
                menuName="Payment"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                  <MdOutlinePayment className="text-xl font-bold "></MdOutlinePayment>
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/finance"
                menuName="Finance"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                  <FaMoneyBill className="text-xl font-bold "></FaMoneyBill>
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/delivery"
                menuName="Delivery"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                  <TbTruckDelivery className="text-xl font-bold " />
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/warranty"
                menuName="Warranty"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                <img src={warrantyIcon} alt="warrantyIcon" className="w-5"/>

                 {/* <FaSignLanguage  className="text-xl font-bold text-[#bf4444]" /> */}
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/change-password"
                menuName="Change Password"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >

                <p>
                  <RiLockPasswordLine className="text-xl font-bold "></RiLockPasswordLine>
                </p>


              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/change-pin"
                menuName="Change PIN"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                  <MdOutlineVpnKeyOff className="text-xl font-bold "></MdOutlineVpnKeyOff>
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/help"
                menuName="Help"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>
                {/* <img src={warrantyIcon} alt="warrantyIcon" className="w-5"/> */}
                <TbHelpHexagon className="w-5"/>

                 {/* <FaSignLanguage  className="text-xl font-bold text-[#bf4444]" /> */}
                </p>
              </CustomNavLinkV2>
            </div>
            <div className="flex flex-col items-center">
              <CustomNavLinkV2
                to="/settings-v2/terms-and-condition"
                menuName="T&C"
                className="p-2.5 rounded-full"
                activeColor="rgba(0, 163, 238, 0.15)"
              >
                <p>

                <LiaWpforms className="w-5"/>


                </p>
              </CustomNavLinkV2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBarV2;
