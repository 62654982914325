import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Breadcrumb from "../SettingsV2Components.jsx/Breadcrumb";

const FinanceSettingsV2 = () => {
  const [formData, setFormData] = useState({
    saleBill: { type: "Income", payment: "Credit" },
    wholesaleBill: { type: "Income", payment: "Credit" },
    purchaseBill: { type: "Income", payment: "Credit" },
    saleDeliveryCharge: { type: "Income", payment: "Credit" },
    wholesaleDeliveryCharge: { type: "Income", payment: "Credit" },
    saleBillVat: { type: "Income", payment: "Credit" },
    saleBillTax: { type: "Income", payment: "Credit" },
    saleReturn: { type: "Income", payment: "Credit" },
    purchaseReturn: { type: "Income", payment: "Credit" },
  });

  const handleRadioChange = (field, key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: { ...prevData[field], [key]: value },
    }));
  };

  return (
    <div className=" rounded-[20px]  font-nunito"> {/* bg-[#FAFAFA] */}
      <Helmet>
        <title>Outlet Expense | Settings | Finance Settings</title>
      </Helmet>
      {/* <h1 className="text-base font-semibold mb-6">Finance</h1> */}
      <Breadcrumb  className="pl-0 ml-0"></Breadcrumb>
      <div className="w-full relative overflow-x-auto px-4">
        <table className="w-full text-sm text-left rtl:text-left text-gray-500"> {/* dark:text-gray-400 */}
          <thead className="text-xs text-[#737373] "> {/*  bg-gray-50 dark:bg-gray-700 dark:text-gray-400 */}
            <tr className=" border-b ">{/* bg-gray-50 dark:bg-gray-800 dark:border-gray-700 */}
              <th className="w-1/4 p-2">Name</th>
              <th className="w-2/4 p-2">Chart of Account</th>
              <th className="w-1/4 p-2">Payment</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(formData).map((field, index) => (
              <tr
                key={index}
                className=" border-b "
              > {/* bg-gray-50 dark:bg-gray-800 dark:border-gray-700 */}
                <td className="p-2  font-medium text-[#0A0A0A] whitespace-nowrap dark:text-white">
                  {formatLabel(field)}
                </td>
                <td className="p-2  font-medium text-[#0A0A0A] whitespace-nowrap dark:text-white">
                  <div className="flex items-center space-x-4">
                    {["Income", "Asset", "Expense", "Equity"].map((option) => (
                      <label key={option} className="flex items-center">
                        <input
                          type="radio"
                          name={`${field}-type`}
                          value={option}
                          checked={formData[field].type === option}
                          onChange={() =>
                            handleRadioChange(field, "type", option)
                          }
                          className="form-radio h-4 w-4 text-blue-600"
                        />
                        <span className="ml-2">{option}</span>
                      </label>
                    ))}
                  </div>
                </td>
                <td className="p-2  font-medium text-[#0A0A0A] whitespace-nowrap dark:text-white">
                  <div className="flex items-center space-x-4">
                    {["Debit", "Credit"].map((option) => (
                      <label key={option} className="flex items-center">
                        <input
                          type="radio"
                          name={`${field}-payment`}
                          value={option}
                          checked={formData[field].payment === option}
                          onChange={() =>
                            handleRadioChange(field, "payment", option)
                          }
                          className="form-radio h-4 w-4 text-blue-600"
                        />
                        <span className="ml-2">{option}</span>
                      </label>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        className="w-full mt-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none"
        onClick={() => console.log(formData)}
      >
        Save Changes
      </button>
    </div>
  );
};

const formatLabel = (label) => {
  return label
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};

export default FinanceSettingsV2;
