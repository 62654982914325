import Swal from "sweetalert2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from './../../utils/Modal';
import axios from "axios";

const ExpenseCategoryItem = (props) => {
  const {
    showModalBeforeDelete,
    index,
    loadingCallback,
    token,
    setSuccessFlashMessage,
    expensecategory,
  } = props;

  const [warningMessage, setWarningMessage] = useState(false);
  const navigate = useNavigate();
  const [refetch, setRefetch] = useState(false)

  const [showExpenseCategoryViewModal, setShowExpenseCategoryViewModal] = useState(false);
  const [expenseCategoryData, setExpenseCategoryData] = useState({});

  const handleExpenseCategoryViewOpen = (id) => () => {
    getExpenseCategoryDetail(id)
  };

  const getExpenseCategoryDetail = (id) => {
    setShowExpenseCategoryViewModal(true)
    setExpenseCategoryData(id);
  };

  // ----------------------

  const [showExpenseCategoryEditModal, setShowExpenseCategoryEditModal] = useState(false);
  const [formData, setFormData] = useState({});

  const handleExpenseCategoryEditOpen = (id) => () => {
    const token = localStorage.getItem("token");
    setShowExpenseCategoryEditModal(true);
    setExpenseCategoryData(id);
    axios.get(`${process.env.REACT_APP_API_URL}expense-type-list/${id}`, {
        headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
      console.log("API Response:", response);
        if (response.data.success) {
            const expenseCategoryData = response.data.data;
            setFormData({
              transaction_category: expenseCategoryData.transaction_category,
              expense_name: expenseCategoryData.expense_name,
              expense_description: expenseCategoryData.expense_description,
            });
            console.log("editable data: ", expenseCategoryData)
        }
    })
    .catch((error) => {
      loadingCallback(false);
      console.log(error);
      localStorage.clear();
      localStorage.setItem("token", false);
      // Clear session and local storage
      localStorage.setItem("isPinVerify", false);

      navigate("/login");
    });
};

  return(
    <>
      <div>
        <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] grid grid-cols-5 gap-2 items-center font-nunito font-[500] text-[14px] text-[#000232]">
        {/* <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start"> */}
          <div>
            <div className="flex flex-col sm:flex-row gap-2">
              <div className="font-nunito text-[14px]">
                <div className="font-[500] text-[#000232]">#{expensecategory.id}</div>
                {/* <div className="font-[700] text-[#87889E]">{expense?.category_name}</div> */}
              </div>
            </div>
          </div>
          <div className="text-center">{expensecategory.expense_name}</div>
          <div className="text-center">{expensecategory.transaction_category}</div>
          <div className="text-center">{expensecategory.expense_description}</div>
          <div className="flex gap-2  text-xs justify-end">
            <label
              htmlFor="view_expense_category_modal"
              // onClick={()=>setShowViewModal(val=>!val)}
              // onClick={()=> Swal.fire("View option coming soon")}
              onClick={handleExpenseCategoryViewOpen(expensecategory?.id)}

              className="cursor-pointer border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[6px] py-1"
            >
            VIEW
            </label>
            <label
              htmlFor="edit_expense_category_modal"
              onClick={handleExpenseCategoryEditOpen(expensecategory?.id)}
              //onClick={()=> Swal.fire("Edit option coming soon")}
              className="border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-[5px] px-[7px] py-1"
            >
            EDIT
            </label>
            <label
                // htmlFor="editAccTxn_modal"
                // onClick={()=> Swal.fire("Edit option coming soon")}
                onClick={() => showModalBeforeDelete(expensecategory?.id, index)}
                className="cursor-pointer border-[1px] border-solid bg-[#FF0303] text-white rounded-[5px] px-[7px] py-1"
              >
              DELETE
              </label>
          </div>
        </div>
      </div>

      {setShowExpenseCategoryViewModal ? (
        <Modal
          isOpen={showExpenseCategoryViewModal}
          setOpen={setShowExpenseCategoryViewModal}
          setWarningMessage={setWarningMessage}
          title="Details"
          formId="view_expense_category_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="view_expense_category_modal"
          expenseCategoryId={expenseCategoryData}
        />
      ) : null}

      {showExpenseCategoryEditModal ? (
        <Modal
          isOpen={showExpenseCategoryEditModal}
          setOpen={setShowExpenseCategoryEditModal}
          setWarningMessage={setWarningMessage}
          title="Edit Expense Category"
          formId="edit_expense_category_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="edit_expense_category_modal"
          expenseCategoryFormData={expenseCategoryData}
          formData={formData}
          setFormData={setFormData}
          expenseCategoryId={expenseCategoryData}
          refetch={()=>setRefetch(val=>!val)}
        />
      ) : null}
    </>
  );
};

export default ExpenseCategoryItem;