import React from 'react';
import LogoAlignLeft from '../../assets/bigLogo.png';
import { formatDate, formatPrice } from "../../utils/Common";
import MethodCreditReportItem from './MethodCreditReportItem';
import MethodDebitReportItem from './MethodDebitReportItem';
import ExpoBankBalanceHistoryItem from './ExpoBankBalanceHistoryItem';
const ExpoMethodReport = (props) => {
  const {
    activeTab,
    balanceData,
    fullApiData,
    paymentList,
    paymentAccountList,
    paymentMethodHistory
  } = props;
  // let createdDate, updatedDate;
  // balanceData.map(bd => {
  //   createdDate = bd.created_at;
  //   updatedDate = bd.updated_at;
  // })
  let totalCredit = 0, totalDebit = 0,totalBankAmount=0;
  paymentMethodHistory?.credit?.map(cr => {
    totalCredit += cr?.amount;
  })
  paymentMethodHistory?.debit?.map(cr => {
    totalDebit += cr?.amount;
  })

  paymentMethodHistory?.bank_balance_history ? Object.entries(paymentMethodHistory?.bank_balance_history).map(([key, value], index) => {
    totalBankAmount += value.amount; // No optional chaining needed here
  }) : null;
  // fullApiData?.balances?.map(cr => {
  //   totalBankAmount += cr?.total_payment_amount;
  //   console.log(cr?.total_payment_amount);
  // })
  return (
    <>

      <div
        className="bg-white rounded-3xl font-nunito p-[26px] mb-20"
      >
        <div className="flex justify-between items-center">
          {/* outlet logo */}
          <div className="w-20 cursor-pointer -pb-[4px]">
            <img src={LogoAlignLeft} className="w-full h-full object-contain" />
          </div>
          {/* title and date */}
          <div className="text-[#000232] text-right">
            <h2 className="text-sm font-semibold">Method Report</h2>
            {/* <p className="text-xs">{formatDate(createdDate)} - {formatDate(updatedDate)}</p> */}
          </div>
        </div>
        {/* credit report */}
        {paymentMethodHistory?.credit && paymentMethodHistory?.credit.length > 0 ? (
          <div>
            <p className='text-2xl py-3 text-blue-600'>Credit</p>
            {/* table head */}
            <div className="grid  mt-2 pt-[4px] pb-[18px]  text-xs font-bold  grid-cols-3 border-b-[1px] border-[#E7EBF4]">

              <div className='text-left'>Account Name</div>
              <div className='text-center'>Account Number</div>
              <div className='text-right'>Amount</div>
            </div>
            {/* table body component */}
            {paymentMethodHistory?.credit?.map((cr, index) => {
              return (<>
                <MethodCreditReportItem type="expo" creditData={cr} key={index} />
              </>)

            })}

            <div className='flex justify-end gap-20'><p className=''>Total Amount</p><span className='text-center'>{formatPrice(totalCredit)}</span></div>
          </div>
        ): null}
        {/* Debit report */}
        {paymentMethodHistory?.debit && paymentMethodHistory?.debit.length > 0 ? (
          <div>
            <p className='text-2xl py-3 text-blue-600'>Debit</p>
            {/* table head */}
            <div className="grid  mt-2 pt-[4px] pb-[18px] text-xs font-bold    grid-cols-3 border-b-[1px] border-[#E7EBF4]">

              <div className='text-left'>Account Name</div>
              <div className='center'>Account Number</div>
              <div className='text-right'>Amount</div>
            </div>
            {/* table body component */}
            {paymentMethodHistory?.debit?.map((cr, index) => {
              return (<>
                <MethodDebitReportItem type="expo" creditData={cr} key={index} />
              </>)

            })}

            <div className='flex justify-end gap-20'><p className=''>Total Amount</p><span className='text-center'>{formatPrice(totalDebit)}</span></div>
          </div>
        ) : null }

        {/* Mobile Bank report */}
        {paymentMethodHistory?.bank_balance_history ? (
          <div>
            <p className='text-2xl text-blue-600'>Bank Balance History</p>
            {/* table body component */}
            <ExpoBankBalanceHistoryItem
                creditData={paymentMethodHistory?.bank_balance_history}
                // Use the key from the object for uniqueness
            />
            <hr className="border-[#363749] border-[1px] mt-[10px]" />
            <div className='flex justify-end gap-20'><p className=''>Total Balance Amount</p><span className='text-center'>{formatPrice(totalBankAmount)}</span></div>
          </div>
        ) : null }
      </div>
    </>
  );
};

export default ExpoMethodReport;