// import React from 'react';

// const TransactionHistoryPDFDetails4Cols = () => {
//   return (
//     <div>

//     </div>
//   );
// };

// export default TransactionHistoryPDFDetails4Cols;

import React from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const TransactionHistoryPDFDetails4Cols = (props) => {
  const {
    transactionData,
    paymentList,
    paymentAccountList,
    activeTab,
    index,
} = props;



const paymentData = paymentList.data ? paymentList.data : paymentList;
const paymentAccountData = paymentAccountList.data ? paymentAccountList.data : paymentAccountList;

  const createdDate = new Date(transactionData?.date);

  return (
    <>

     <div className="border-b-[1px] text-xs border-solid px-3 last:border-none border-[#E7EBF4] -pt-[4px] pb-[13px] font-nunito grid grid-cols-8 items-start">

     <div className="col-span-1">
        <h1 className=" text-[#000] font-[600] text-left">{ (index+1)<10 ? "0" + (index+1) :  index + 1}</h1>
      </div>

      <div className="text-[#000] col-span-2  font-semibold text-center">
        <p className="text-[#000] font-[500] text-md">{transactionData?.transaction_history?.transaction_status}</p>
      </div>

      <div className="text-[#000] col-span-2 font-[500] text-center">
        <p className="text-[#000] font-[500] text-md">{transactionData?.type}</p>
      </div>

      <div className="text-[#000] col-span-2 font-[500] text-center">
        <p className="text-[#000] font-[500] text-center">{transactionData?.invoice}</p>
      </div>

      <div className=" text-[#000] col-span-1 font-[500] text-right">{Number.parseFloat(transactionData?.total_paid).toFixed(2)}</div>
   </div>
    </>
  );
};
TransactionHistoryPDFDetails4Cols.defaultProps = { className: null };
export default TransactionHistoryPDFDetails4Cols;