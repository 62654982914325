import React from "react";
import { Helmet } from "react-helmet-async";

const HelpV2 = () => {
  return (
    <div className="flex justify-center items-center  bg-[#FAFAFA] rounded-[27px] py-10">
      <Helmet>
        <title>Outlet Expense | Settings | Help</title>
      </Helmet>
      <div className="text-center   rounded-lg">
        <h1 className="text-4xl font-bold text-gray-800">
          Help Page is{" "}
          <span className="text-blue-500 animate-pulse">Coming Soon</span>...
        </h1>
        <div className="mt-4">
          <p className="text-gray-500 text-lg animate-bounce">
            Stay tuned for updates!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HelpV2;
