import React, { useContext, useEffect, useRef, useState } from 'react';
import ContentLayout from '../../layout/ContentLayout/index.jsx';
import { AppContext } from '../../context/context.js';
import DatePicker from "react-datepicker";
import Pagination from '../../utils/pagination.js'; // Adjust the path accordingly
import { AiOutlineSearch } from 'react-icons/ai';
// import { get } from '../../services/apiService.js';
// import {RiMoneyDollarCircleLine} from "react-icons/ri";
// import { timeDiff } from '../../utils/Common.js';
// import { post } from '../../services/apiService.js';
// import { Link } from 'react-router-dom';
// import { FaEye } from "react-icons/fa";
// import {BiPlus} from 'react-icons/bi';
// import InvoiceItem from '../../components/Invoice/InvoiceItem.js';
// import { BeatLoader } from 'react-spinners'
import RecentlyAddedProductsList from '../../components/RecentlyAddedProductsList/RecentlyAddedProductsList.js'
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const RecentlyAddedProductsPage = () => {
    const [invoiceData, setInvoiceData] = useState([]);

    useEffect(() => {
        fetch('invoiceData.json')
            .then((res) => res.json())
            .then((data) => setInvoiceData(data));
    }, []);
    const { state, isLoading, updateInvoiceList } = useContext(AppContext);
    const { invoiceList, invoiceSetting } = state;
    const token = localStorage.getItem('token');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const [showNavMenu , setShowNavMenu] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const [searchFilter, setSearchFilter] = useState({
        keyword: "",
        categoryId: false,
        subCategoryId: false,
        unitId: false,
        brandId: false,
        stockIn: false,
        stockOut: false,
        startDate: 0,
        endDate: new Date(),
    })


    const [loading, setLoading] = useState(false);
    const loadingCallback = loading => {
        setLoading(loading);
    };
    const [products, setProducts] = useState([]);
    const [productsData, setProductsData] = useState([]);

    useEffect(()=>{
        console.log( searchFilter);
        getFilteredProductlist(currentPage);
    },[searchFilter])

    const getFilteredProductlist = (page) => {
        const data = {
            keyword: searchFilter.keyword,
            categoryId: searchFilter.categoryId,
            subCategoryId: searchFilter.subCategoryId,
            unitId: searchFilter.unitId,
            brandId: searchFilter.brandId,
            stockIn: searchFilter.stockIn,
            stockOut: searchFilter.stockOut,
        }
        loadingCallback(true)
        axios
            .post(
                `${process.env.REACT_APP_API_URL}search-product-v1?page=${page}&limit=${perPage}`,
                // { keyword: searchKeyword },
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                loadingCallback(false)
                if (response.status === 200) {
                    setProductsData(response.data.data);
                    setProductsData(response.data.data.data);
                } else {
                    console.log('Data Not Found!');
                }
            })
            .catch((error) => {
                loadingCallback(false)
                console.log(error);
                if (error?.response?.status === 401) {
                    localStorage.clear();
                    localStorage.setItem('token', false);
                    // Clear session and local storage
                    localStorage.setItem('isPinVerify', false);

                    navigate('/login');
                    // Navigate to the '/login' route
                }
            });
    };

    const getProductlist = (page, searchKeyword = null) => {
        loadingCallback(true)
        if (searchKeyword) {
            getFilteredProductlist(page, searchKeyword);
        } else {
            axios
            .get(
                `${process.env.REACT_APP_API_URL}product?page=${page}&limit=${perPage}`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {

                //console.log(response.data.data.data);

                if(response.data.success)
                {
                    loadingCallback(false)
                    setProducts(response.data.data);
                    setProductsData(response.data.data.data)
                }

            })
            .catch((error) => {
                console.log(error);
                loadingCallback(false);
                if (error?.response?.status === 401) {
                    localStorage.clear();
                    localStorage.setItem('token', false);
                    // Clear session and local storage
                    localStorage.setItem('isPinVerify', false);

                    navigate('/login');
                    // Navigate to the '/login' route
                }
            });
        }
    };
    const [prevSearchKeyword, setPrevSearchKeyword] = useState('');
    useEffect(() => {
      // Check if the search keyword has changed
      const isSearchKeywordChanged = searchKeyword !== prevSearchKeyword;

      // Update the previous search keyword
      setPrevSearchKeyword(searchKeyword);

      // If it's a new search keyword, set the page to 1
      if (isSearchKeywordChanged) {
        setCurrentPage(1);
      } else {
        getProductlist(currentPage, searchKeyword);
      }
    }, [currentPage, searchKeyword, prevSearchKeyword]);

    const handleButtonClick = () => {
        console.log("Clicked");
        setShowNavMenu((prevShowNavMenu) => !prevShowNavMenu);
    };

    // const handleClickOutside = (event) => {
    //     if (menuRef.current && !menuRef.current.contains(event.target)) {
    //     setShowNavMenu(false);
    //     }
    // };
    const handleClickOutside = (event) => {
        if (
            (menuRef.current && !menuRef.current.contains(event.target)) &&
            (buttonRef.current && !buttonRef.current.contains(event.target))
        ) {
            setShowNavMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
        document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const shouldRenderPagination = products.total > perPage;

    return (
        <>
            <ContentLayout>
                <div className="simple-tabs-container">
                    <div className="tab-content">
                        <div className="main-content">
                            <div className="flex flex-col w-full">

                                <div className='productsList-container'>

                                    <div className='flex flex-col md:flex-row justify-between mb-4'>
                                        <div className="title-link mt-2 mb-3 md:mb-0 md:mt-0">
                                            <h1 className="text-[22px] text-[#000232] font-semibold font-nunito">Recently Added Products</h1>
                                        </div>

                                        <div className='flex gap-2 justify-center'>
                                            <div className="flex relative w-[95%]">
                                                <input
                                                    autoFocus
                                                    type="text"
                                                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                                                    placeholder="Search Product"
                                                    onChange={(e) => {
                                                        // setSearchKeyword(e.target.value);
                                                        setSearchFilter({...searchFilter, keyword: e.target.value})
                                                    }}
                                                />

                                                <div className="absolute top-[37%] left-[10px]">
                                                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                                                </div>

                                                <div
                                                    ref={buttonRef}
                                                    onClick={handleButtonClick}
                                                    className="absolute top-[36%] right-[10px]"
                                                    >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z" fill="#102048"/>
                                                        <path d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z" fill="#102048"/>
                                                        <path d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z" fill="#102048"/>
                                                        <path d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z" fill="#102048"/>
                                                        <path d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z" fill="#102048"/>
                                                        <path d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z" fill="#102048"/>
                                                        <path d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z" fill="#102048"/>
                                                        <path d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z" fill="#102048"/>
                                                        <path d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z" fill="#102048"/>
                                                    </svg>
                                                </div>

                                                {showNavMenu && (
                                                    <div ref={menuRef} className="absolute z-50  w-[230px] shadow-sm left-[20px] top-[50px] bg-[#FFF] border-1 border-solid p-3 rounded-[10px]">
                                                        <div onClick={(e)=> setSearchFilter({...searchFilter, categoryId: (!searchFilter.categoryId)})}className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                                                            <div className=''>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={searchFilter.categoryId}
                                                                    onChange={(e)=>setSearchFilter({...searchFilter, categoryId: (!searchFilter.categoryId)})}
                                                                    // className="bg-[#E5EDFF] p-3 "
                                                                />
                                                            </div>
                                                            <div className="">
                                                                <p className=" text-[#000] font-[500] ps-2 text-xs">Category</p>
                                                            </div>
                                                        </div>
                                                        <div onClick={(e)=> setSearchFilter({...searchFilter, subCategoryId: (!searchFilter.subCategoryId)})}className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                                                            <div className=''>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={searchFilter.subCategoryId}
                                                                    onChange={(e)=>setSearchFilter({...searchFilter, subCategoryId: (!searchFilter.subCategoryId)})}
                                                                    // className="bg-[#E5EDFF] p-3 "
                                                                />
                                                            </div>
                                                            <div className="">
                                                                <p className=" text-[#000] whitespace-nowrap font-[500] ps-2 text-xs">Sub Category</p>
                                                            </div>
                                                        </div>
                                                        <div onClick={(e)=> setSearchFilter({...searchFilter, unitId: (!searchFilter.unitId)})}className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                                                            <div className=''>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={searchFilter.unitId}
                                                                    onChange={(e)=>setSearchFilter({...searchFilter, unitId: (!searchFilter.unitId)})}
                                                                    // className="bg-[#E5EDFF] p-3 "
                                                                />
                                                            </div>
                                                            <div className="">
                                                                <p className=" text-[#000] font-[500] ps-2 text-xs">Unit</p>
                                                            </div>
                                                        </div>
                                                        <div onClick={(e)=> setSearchFilter({...searchFilter, brandId: (!searchFilter.brandId)})} className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start">
                                                            <div className=''>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={searchFilter.brandId}
                                                                    onChange={(e)=>setSearchFilter({...searchFilter, brandId: (!searchFilter.brandId)})}
                                                                    // className="bg-[#E5EDFF] p-3 "
                                                                />
                                                            </div>
                                                            <div className="">
                                                                <p className=" text-[#000] font-[500] ps-2 text-xs">Brand</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-between w-full col-span-3 items-center pt-[10px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                                                            <div onClick={(e)=> setSearchFilter({...searchFilter, stockIn: (!searchFilter.stockIn)})} className={`relative ${ searchFilter.stockIn? 'bg-[#407BFF] cursor-pointer text-white' : 'bg-gray-100'}  border-gray-100 py-1 border w-full flex-1 rounded-xl`}>
                                                                <p className="w-full cursor-pointer text-[13px] text-center">Stock In</p>
                                                            </div>
                                                            <div onClick={(e)=> setSearchFilter({...searchFilter, stockOut: (!searchFilter.stockOut)})} className={`relative ${ searchFilter.stockOut? 'bg-[#407BFF] cursor-pointer text-white' : 'bg-gray-100'}  border-gray-100 py-1 border w-full flex-1 rounded-xl`}>
                                                                <p className="w-full text-[13px] cursor-pointer text-center ">Stock Out</p>
                                                            </div>
                                                        </div>
                                                        {/* <div>
                                                            <p className="p-2 text-md">Date</p>
                                                            <div className="flex justify-between w-full col-span-3 items-center mt-[5px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                                                                <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                                                                    <DatePicker
                                                                        selected={searchFilter.startDate}
                                                                        onChange={(date) => setSearchFilter({...searchFilter, startDate: date})}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        placeholderText="00/00/00"
                                                                        className="w-full px-2 text-xs focus:outline-none"
                                                                    />
                                                                    <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                                                        Start Date
                                                                    </span>
                                                                </div>
                                                                <div className="relative border-[#C1CFEF] py-1 border w-full flex-1 rounded-xl">
                                                                    <DatePicker
                                                                        selected={searchFilter.endDate}
                                                                        onChange={(date) => setSearchFilter({...searchFilter, endDate: date})}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="w-full px-2 text-xs focus:outline-none"
                                                                    />
                                                                    <span className="bg-white absolute left-2 font-semibold px-1 text-[8px] top-0 text-[#102048] translate-y-[-50%]">
                                                                        End Date
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Pagination component */}
                                    {shouldRenderPagination && (
                                        <Pagination
                                            currentPage={currentPage}
                                            lastPage={products.last_page}
                                            onPageChange={onPageChange}
                                        />
                                    )}

                                    <RecentlyAddedProductsList products={products} productsData={productsData} loadingCallback={loadingCallback} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {loading && (

                  <div className="custom-loader-logo">
                    {/* <BeatLoader color={"#123abc"} size={20} /> */}
                    <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
                  </div>
                )}
            </ContentLayout>
        </>
    );
};

export default RecentlyAddedProductsPage;
