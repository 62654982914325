import React, { useContext, useEffect, useState,useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ContentLayout from "../../layout/ContentLayout";
import { useFlashMessage } from '../../context/FlashMessageContext';
import ExporterProfile from "../../components/ExporterProfile/ExporterProfile";
import TranCollectionPDFDetails from "../profilePageSection/TranCollectionPDFDetails";
import { AppContext } from '../../context/context';
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

const ExporterProfilePage = () => {
  //---------------------
  const location = useLocation();
  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      // Parse the employee data from local storage
      const employeeData = JSON.parse(employeeApiResponse);

      // Get the "Exporter" feature from the employee's role features
      const exporterFeature = employeeData.role.features.find(
        (feature) => feature.name === "Exporter"
      );

      // Check if the "Exporter" feature and "Visible" option are both active
      if (exporterFeature && exporterFeature.status === 1) {
        const exporterVisibleOption = exporterFeature.feature_options.find(
          (option) => option.name === "Visible" && option.status === 1
        );
        // Check if the "Hidden" option is active for /editproduct/:id route
        const exporterHiddenOption = exporterFeature.feature_options.find(
          (option) => option.name === "Hidden" && option.status === 1
        );

        // Grant access based on the route and permissions
        if (location.pathname === "/exporter-profile/:id" && exporterVisibleOption) {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
      }
      setHasPermission(false);
      // If no permission, navigate to dashboard first
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
    // If not an employee, the useEffect gets skipped
  }, [navigate, location]);
  //---------------------
  const [activeTab, setActiveTab] = useState("all");
  const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [warningMessage, setWarningMessage] = useState(false);
  const [dueCollectionData, setDueCollectionData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const {
    state,
    updatePaymentList,
    updatePaymentAccountList
  } = useContext(AppContext);
  const { paymentList, paymentAccountList } = state;


  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  //const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [fetchedData, setFetchedData] = useState({});

  const { id } = useParams();// exporter id
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;
  useEffect(() => {
    if (hasPermission) {
      getCustomerProfileData(activeTab);
      getInvoiceData(activeTab, currentPage);
      getVendorProfileDueCollection(activeTab);
    }
  }, [activeTab,currentPage, hasPermission]);

  const getCustomerProfileData = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}exporter-profile/${id}?interval=${activeTab}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setFetchedData(fetchedData);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  const getVendorProfileDueCollection = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}exporter-due-collection/${id}?interval=${activeTab}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setDueCollectionData(fetchedData);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  const getInvoiceData = (activeTab, page) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}exporter-history/${id}?interval=${activeTab}&page=${page}&limit=${perPage}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const responseInvoiceData = response?.data?.data?.data ? response?.data?.data?.data : response?.data?.data;

          setInvoiceData(responseInvoiceData);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  const handleSubmitPayment = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}save-exporter-due`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          //setInvoice(response.data.data);
          setSuccessFlashMessage("Due payment submitted successfully.");
          getCustomerProfileData(activeTab);
          getInvoiceData(activeTab, currentPage);
          getVendorProfileDueCollection(activeTab);

        } else {
          if (response.data.message) {
            setErrorFlashMessage('something went wrong!');
          }
          if (response.data.errors) {
            setErrorFlashMessage(response.data.errors?.customer_id[0]);
          }
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  }
  const [showPdf, setShowPdf] = useState(false);
  const BalanceDetailsRef = useRef(null);
  const [transactionList, setTransactionList] = useState([])
  const [allFetchedData, setAllFetchedData] = useState([]);
  const [allApiData, setAllApiData] = useState({});
  const [balanceList, setBalanceList] = useState([]);
  const [total, setTotal] = useState(0.00);

  const [reportBtn, setReportBtn] = useState(false);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(new Date());
  const [paymentMethodHistory, setPaymentMethodHistory] = useState(null);



  const handleReportButton = () => {
    setReportBtn(true);
    // getDateWiseBalanceReport(1);
    // getDateWiseAccountTransactionReport(1);
    // getDateWiseDueReport(1)
  }

  useEffect(()=>{
    if (hasPermission) {
      getDateWiseAccountTransactionReport(1);
      getDateWiseDueReport(1);
    }
  },[activeTab, hasPermission])



  const getDateWiseDueReport = (page) =>{
    loadingCallback(true);
    // getDateWiseAccountTransactionReport(page);
    //setReportBtn(true);
    // Parse the date string into a Date object
    const fromDate1 = new Date(fromDate);

    // Format the date however you like
    const formattedDate = `${fromDate1.getFullYear()}-${String(fromDate1.getMonth() + 1).padStart(2, '0')}-${fromDate1.getDate().toString().padStart(2, '0')}`;

    // Parse the date string into a Date object
    const fromDate2 = new Date(toDate);
    const formattedDate2 = `${fromDate2.getFullYear()}-${String(fromDate2.getMonth() + 1).padStart(2, '0')}-${fromDate2.getDate().toString().padStart(2, '0')}`;


    const date = {
      type: "exporter",
      id: id,
      start_date: formattedDate,
      end_date: formattedDate2,
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}get-account-ledger?interval=${activeTab}`, date,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          const fetchedDatas= response.data.data;
          setAllApiData(fetchedDatas);
          setTransactionList(response.data.data.ledger)
          const fetchedData = response.data.data.ledger;
          setAllFetchedData(fetchedData);

          // const addCompleted2 = fetchedData.map(item => ({ ...item, completed: true }));
          // setAllFetchedDataUnSelected(addCompleted2)

          // const totalMoney = fetchedData.reduce((total, item) => {
          //   return total + item.total_paid;
          // }, 0);

          setTotal(response.data.data.total_amount);
          //setReportBtn(true);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);
          navigate("/login");
        }
      });
  }

  const getDateWiseAccountTransactionReport = (page) =>{
    loadingCallback(true);
    //setReportBtn(true);
    // Parse the date string into a Date object
    const fromDate1 = new Date(fromDate);

    // Format the date however you like
    const formattedDate = `${fromDate1.getFullYear()}-${String(fromDate1.getMonth() + 1).padStart(2, '0')}-${fromDate1.getDate().toString().padStart(2, '0')}`;

    // Parse the date string into a Date object
    const fromDate2 = new Date(toDate);
    const formattedDate2 = `${fromDate2.getFullYear()}-${String(fromDate2.getMonth() + 1).padStart(2, '0')}-${fromDate2.getDate().toString().padStart(2, '0')}`;


    const date = {
      type: "exporter",
      id: id,
      start_date: formattedDate,
      end_date: formattedDate2,
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}get-account-transaction-methods?interval=${activeTab}`, date,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          const fetchedDatas= response.data.data;
          console.log("second function",response.data.data);
          setPaymentMethodHistory(fetchedDatas);
          // setReportBtn(true);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);
          navigate("/login");
        }
      });
  }

  const handlePrintPdf = async (elementId) => {
    // const total = dueCollectionData.reduce((cnt, item) => {
    //   if (item.completed) {return cnt + 1;}
    //   return cnt;
    // }, 0);

      handlePDFDownload(elementId)

  };

  const handlePDFDownload = (elementId) =>{
    const printContents = document.getElementById(elementId).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
  }
  const originalContents = document.body.innerHTML;
  window.onafterprint = () => {
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  return (
    <>
      <ContentLayout>
        <div className="mb-[30px] flex flex-row gap-[1%] font-nunito pt-32 md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">
        <div
            onClick={() => {
              handleTabClick("all")
              //console.log("all clicked. but no data for all")
            }}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "all"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            All Time
          </div>
          <div
            onClick={() => handleTabClick("daily")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "daily"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Daily
          </div>
          <div
            onClick={() => handleTabClick("weekly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "weekly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Weekly
          </div>
          <div
            onClick={() => handleTabClick("monthly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "monthly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Monthly
          </div>
          <div
            onClick={() => handleTabClick("yearly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "yearly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Yearly
          </div>
        </div>

        <div className="mt-24 md:mt-0"> {/* tab-content  removed */}
          {warningMessage && (
            <div className="warning-message">
              something wrong!
            </div>
          )}
          {flashMessage && (
            <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
              {flashMessage.text}
            </div>
          )}
          {activeTab && (
            <>
              <ExporterProfile
                activeTab={activeTab}
                loadingCallback={loadingCallback}
                fetchedData={fetchedData}
                invoiceData={invoiceData}
                customerId={id}
                onSubmitPayment={handleSubmitPayment}
                dueCollectionData={dueCollectionData}
                setSuccessFlashMessage={setSuccessFlashMessage}
                setErrorFlashMessage={setErrorFlashMessage}
                setWarningMessage={setWarningMessage}
                handlePrintPdf={handlePrintPdf}
                // getDateWiseDueReport={getDateWiseDueReport}
                getDateWiseDueReport={handleReportButton}
                // setReportBtn={setReportBtn}
                reportBtn={reportBtn}
                setFromDate={setFromDate}
                setToDate={setToDate}
                fromDate={fromDate}
                toDate={toDate}
                paymentList={paymentList}
                paymentAccountList={paymentAccountList}
              />
            </>
          )}
        </div>

        {loading && (
          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img
              className=""
              src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
              alt="logo"
            />
          </div>
        )}
      </ContentLayout>
      <div className={`${!showPdf?'hidden':'block'}`}>
        <div ref={BalanceDetailsRef}>
          <TranCollectionPDFDetails
            paymentList={paymentList}
            paymentAccountList={paymentAccountList}
            fullApiData={allApiData}
            transactionData={allFetchedData}
            total={total}
            actTab={activeTab}
            type="exporter"
            paymentMethodHistory={paymentMethodHistory}
          />
        </div>
      </div>
    </>
  );
};

export default ExporterProfilePage;
