import React, { useContext,useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ContentLayout from "../../layout/ContentLayout";
import WholesalerProfile from "../../components/WholesalerProfile/WholesalerProfile";
import { useFlashMessage } from '../../context/FlashMessageContext';
//import DueCollectionPDFDetails from "../profilePageSection/DueCollectionPDFDetails";
import { AppContext } from '../../context/context';
import { FlashMessage } from "../../utils/FlashMessage";
import { ToastContainer } from "react-toastify";

const WholesalerProfilePage = () => {
  const [activeTab, setActiveTab] = useState("daily");
  const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const {
    state,
    updatePaymentList,
    updatePaymentAccountList
  } = useContext(AppContext);
  const { paymentList, paymentAccountList } = state;
  const [warningMessage, setWarningMessage] = useState(false);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [fetchedData, setFetchedData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [dueCollectionData, setDueCollectionData] = useState({});
  const [showPdf, setShowPdf] = useState(false);
  const BalanceDetailsRef = useRef(null);

  const { id } = useParams();// vendor id
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;
  useEffect(() => {
    getCustomerProfileData(activeTab);
    getInvoiceData(activeTab, currentPage);
    getVendorProfileDueCollection(activeTab);
  }, [activeTab,currentPage]);
  useEffect(() => {
      getMethodList();
      getMethodAccountList()
  }, [currentPage]);


  const getMethodList = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-list`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success)
      {
        loadingCallback(false)
        updatePaymentList(response.data.data)
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };

  const getMethodAccountList = () => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-category-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success){
        loadingCallback(false)
        updatePaymentAccountList(response.data.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  const getCustomerProfileData = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}whole-sellers-profile/${id}?interval=${activeTab}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setFetchedData(fetchedData);

          // console.log("==========");
          // console.log(fetchedData);
          // console.log("==========");
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };

  const getVendorProfileDueCollection = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}whole-salller-due-collection/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setDueCollectionData(fetchedData);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  const getInvoiceData = (activeTab, page) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}whole-sellers-wise-invoice/${id}?interval=${activeTab}&page=${page}&limit=${perPage}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const responseInvoiceData = response.data.data.data;
          setInvoiceData(responseInvoiceData);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  const [hideModal, setHideModal] = useState(false);
  const handleSubmitPayment = (data) => {
    loadingCallback(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}due-collection`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          //setInvoice(response.data.data);
          setHideModal(true);
          console.log(response.data.data);
          // setSuccessFlashMessage("Due payment submitted successfully.");
          FlashMessage("Due payment submitted successfully.", 'success');
          getCustomerProfileData(activeTab);
          getInvoiceData(activeTab, currentPage);
          getVendorProfileDueCollection(activeTab);

        } else {
          if (response.data.message) {
            // setErrorFlashMessage('something went wrong!');
            FlashMessage('something went wrong!', 'error');
          }
          if (response.data.errors) {
            // setErrorFlashMessage(response.data.errors?.customer_id[0]);
            FlashMessage(response.data.errors?.customer_id[0], 'error');
          }
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  }

  const handleSubmitAddDue = (data) => {
    loadingCallback(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}save-advance-due`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          //setInvoice(response.data.data);
          setHideModal(true);
          console.log(response.data.data);
          // setSuccessFlashMessage("Due payment submitted successfully.");
          FlashMessage("Due payment submitted successfully.", 'success');
          getCustomerProfileData(activeTab);
          getInvoiceData(activeTab, currentPage);
          getVendorProfileDueCollection(activeTab);

        } else {
          if (response.data.message) {
            // setErrorFlashMessage('something went wrong!');
            FlashMessage('something went wrong!','error');
          }
          if (response.data.errors) {
            // setErrorFlashMessage(response.data.errors?.customer_id[0]);
            FlashMessage(response.data.errors?.customer_id[0], 'error');
          }
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  }
  const handleSubmitAddPiad = (data) => {
    loadingCallback(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}save-advance-paid`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          //setInvoice(response.data.data);
          setHideModal(true);
          console.log(response.data.data);
          // setSuccessFlashMessage("Due payment submitted successfully.");
          FlashMessage("Due payment submitted successfully.", 'success');
          getCustomerProfileData(activeTab);
          getInvoiceData(activeTab, currentPage);
          getVendorProfileDueCollection(activeTab);

        } else {
          if (response.data.message) {
            // setErrorFlashMessage('something went wrong!');
            FlashMessage('something went wrong!','error');
          }
          if (response.data.errors) {
            // setErrorFlashMessage(response.data.errors?.customer_id[0]);
            FlashMessage(response.data.errors?.customer_id[0], 'error');
          }
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  }
  const [reportBtn, setReportBtn] = useState(false);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const getDateWiseDueReport = (page) =>{
    loadingCallback(true);
    setReportBtn(true);
    // const date = {
    //   start_date: fromDate,
    //   end_date: toDate,
    // }
    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_URL}get-balance-history-date-wise?page=${page}&limit=${perPage}`, date,
    //     {
    //       headers: {
    //         authorization: `Bearer ${token}`,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     loadingCallback(false);
    //     if (response.data.success) {
    //       const fetchedDatas= response.data;
    //       // setAllApiData(fetchedDatas);
    //       // setBalanceList(response.data.data)
    //       // const fetchedData = response.data.data.data;
    //       // const addCompleted = fetchedData.map(item => ({ ...item, completed: false }));
    //       // setAllFetchedData(addCompleted);
    //       setReportBtn(true);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     loadingCallback(false);
    //     if (error?.response?.status === 401) {
    //       localStorage.clear();
    //       localStorage.setItem("token", false);
    //       localStorage.setItem("isPinVerify", false);
    //       navigate("/login");
    //     }
    //   });
  }

  const handlePrintPdf = async (elementId) => {
    // const total = dueCollectionData.reduce((cnt, item) => {
    //   if (item.completed) {return cnt + 1;}
    //   return cnt;
    // }, 0);
    const total = dueCollectionData.length;
    if (total > 0) {
      handlePDFDownload(elementId)
    }
  };

  const handlePDFDownload = (elementId) =>{
    const printContents = document.getElementById(elementId).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
  }
  const originalContents = document.body.innerHTML;
  window.onafterprint = () => {
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  return (
    <>
      <ContentLayout>
      <ToastContainer />
        <div className="mb-[30px] flex flex-row gap-[1%] font-nunito pt-32 md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">
          <div
            onClick={() => handleTabClick("daily")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "daily"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Daily
          </div>
          <div
            onClick={() => handleTabClick("weekly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "weekly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Weekly
          </div>
          <div
            onClick={() => handleTabClick("monthly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "monthly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Monthly
          </div>
          <div
            onClick={() => handleTabClick("yearly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "yearly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Yearly
          </div>
        </div>

        <div className="mt-24 md:mt-0">{/* tab-content  removed */}
          {warningMessage && (
            <div className="warning-message">
              something wrong!
            </div>
          )}
          {flashMessage && (
            <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
              {flashMessage.text}
            </div>
          )}
          {activeTab && (
            <>
              <WholesalerProfile
                activeTab={activeTab}
                loadingCallback={loadingCallback}
                fetchedData={fetchedData}
                invoiceData={invoiceData}
                customerId={id}
                hideModal={hideModal}
                onSubmitPayment={handleSubmitPayment}
                dueCollectionData={dueCollectionData}
                handleSubmitAddDue={handleSubmitAddDue}
                handleSubmitAddPiad={handleSubmitAddPiad}
                setSuccessFlashMessage={setSuccessFlashMessage}
                setErrorFlashMessage={setErrorFlashMessage}
                setWarningMessage={setWarningMessage}
                handlePrintPdf={handlePrintPdf}
                getDateWiseDueReport={getDateWiseDueReport}
                setReportBtn={setReportBtn}
                reportBtn={reportBtn}
                setFromDate={setFromDate}
                setToDate={setToDate}
                fromDate={fromDate}
                toDate={toDate}
                paymentList={paymentList}
                paymentAccountList={paymentAccountList}
              />
            </>
          )}
        </div>

        {loading && (
          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img
              className=""
              src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
              alt="logo"
            />
          </div>
        )}
      </ContentLayout>
      {/* <div className={`${!showPdf?'hidden':'block'}`}>
        <div ref={BalanceDetailsRef}>
          <DueCollectionPDFDetails
            dueCollectionData={dueCollectionData}
            paymentList={paymentList}
            paymentAccountList={paymentAccountList}
          />
        </div>
      </div> */}
    </>
  );
};

export default WholesalerProfilePage;
