import axios from "axios";
import { useEffect, useState } from "react";

export const TopSaleProducts = ({
  handleSelectedFood,
  buttonRef,
  handleButtonClick,
  showNavMenu,
  menuRef,
  setSearchFilter,
  searchFilter,
}) => {
  const [topProducts, setTopProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterBy, setFilterBy] = useState({
    keyword: "",
    categoryId: false,
    subCategoryId: false,
    unitId: false,
    brandId: false,
    stockIn: false,
    stockOut: false,
  });

  // Fetch top sale products
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}top-sales?page=1&limit=12`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res?.data?.data;
        setTopProducts(data?.data);
      })
      .catch((error) => console.error(error));
  }, []);

  // Fetch all categories for filter options
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}get-all-category`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res?.data?.data;
        setCategories(data);
      })
      .catch((error) => console.error(error));
  }, []);

  // Fetch filtered products when filterBy changes
  useEffect(() => {
    const token = localStorage.getItem("token");

    // If the search keyword is empty, don't trigger the search API
    if (filterBy.keyword === "") {
      setFilteredProducts(topProducts); // Clear filtered products
      return;
    }

    const body = filterBy;
    axios
      .post(`${process.env.REACT_APP_API_URL}search-product-v1`, body, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res?.data?.data;
        setFilteredProducts(data?.data);
      })
      .catch((error) => console.error(error));
  }, [filterBy]); // Depend on filterBy state

  // Handle search input change
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setFilterBy((prev) => ({ ...prev, keyword: value }));
  };

  // Handle category selection change
  const handleCategoryChange = (e) => {
    console.log(e.target.value);
    setFilterBy((prev) => ({ ...prev, categoryId: e.target.value }));
  };

  return (
    <div className="w-full bg-white p-4 rounded-xl shadow-sm md:w-[35%]">
      {/* Search and Filter Section */}
      <div className=" flex justify-between items-center mb-4 gap-1">
        <input
          type="text"
          placeholder="Search Products"
          className="w-[45%] px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-xs font-medium text-[#A3A3A3]"
          onChange={handleSearchChange} // Search input handler
        />
        <div className="relative">
        <div className="flex">
          <select
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-xs font-medium text-[#A3A3A3]"
            onChange={handleCategoryChange} // Category filter handler
          >
            <option value="">All Category</option>
            {categories?.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <button  ref={buttonRef} onClick={handleButtonClick} className=" px-2  ml-2 bg-blue-500 text-white rounded-md">
          <i className="fas fa-filter"></i>
        </button>
        </div>


        {showNavMenu && (
          <div
            ref={menuRef}
            className="absolute z-50  w-[230px] shadow-sm right-[25px] top-[45px] bg-[#FFF] border-1 border-solid p-3 rounded-[10px]"
          >
            <div
              onClick={(e) =>
                setSearchFilter({
                  ...searchFilter,
                  categoryId: !searchFilter.categoryId,
                })
              }
              className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
            >
              <div className="">
                <input
                  type="checkbox"
                  checked={searchFilter.categoryId}
                  onChange={(e) =>
                    setSearchFilter({
                      ...searchFilter,
                      categoryId: !searchFilter.categoryId,
                    })
                  }
                  // className="bg-[#E5EDFF] p-3 "
                />
              </div>
              <div className="">
                <p className=" text-[#000] font-[500] ps-2 text-xs">Category</p>
              </div>
            </div>
            <div
              onClick={(e) =>
                setSearchFilter({
                  ...searchFilter,
                  subCategoryId: !searchFilter.subCategoryId,
                })
              }
              className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
            >
              <div className="">
                <input
                  type="checkbox"
                  checked={searchFilter.subCategoryId}
                  onChange={(e) =>
                    setSearchFilter({
                      ...searchFilter,
                      subCategoryId: !searchFilter.subCategoryId,
                    })
                  }
                  // className="bg-[#E5EDFF] p-3 "
                />
              </div>
              <div className="">
                <p className=" text-[#000] whitespace-nowrap font-[500] ps-2 text-xs">
                  Sub Category
                </p>
              </div>
            </div>
            <div
              onClick={(e) =>
                setSearchFilter({
                  ...searchFilter,
                  unitId: !searchFilter.unitId,
                })
              }
              className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
            >
              <div className="">
                <input
                  type="checkbox"
                  checked={searchFilter.unitId}
                  onChange={(e) =>
                    setSearchFilter({
                      ...searchFilter,
                      unitId: !searchFilter.unitId,
                    })
                  }
                  // className="bg-[#E5EDFF] p-3 "
                />
              </div>
              <div className="">
                <p className=" text-[#000] font-[500] ps-2 text-xs">Unit</p>
              </div>
            </div>
            <div
              onClick={(e) =>
                setSearchFilter({
                  ...searchFilter,
                  brandId: !searchFilter.brandId,
                })
              }
              className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[8px] font-nunito grid grid-cols-5 items-start"
            >
              <div className="">
                <input
                  type="checkbox"
                  checked={searchFilter.brandId}
                  onChange={(e) =>
                    setSearchFilter({
                      ...searchFilter,
                      brandId: !searchFilter.brandId,
                    })
                  }
                  // className="bg-[#E5EDFF] p-3 "
                />
              </div>
              <div className="">
                <p className=" text-[#000] font-[500] ps-2 text-xs">Brand</p>
              </div>
            </div>
            <div className="flex justify-between w-full col-span-3 items-center pt-[10px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
              <div
                onClick={(e) =>
                  setSearchFilter({
                    ...searchFilter,
                    stockIn: !searchFilter.stockIn,
                  })
                }
                className={`relative ${
                  searchFilter.stockIn
                    ? "bg-[#407BFF] cursor-pointer text-white"
                    : "bg-gray-100"
                }  border-gray-100 py-1 border w-full flex-1 rounded-xl`}
              >
                <p className="w-full text-[13px] cursor-pointer text-center">
                  Stock In
                </p>
              </div>
              <div
                onClick={(e) =>
                  setSearchFilter({
                    ...searchFilter,
                    stockOut: !searchFilter.stockOut,
                  })
                }
                className={`relative ${
                  searchFilter.stockOut
                    ? "bg-[#407BFF] cursor-pointer text-white"
                    : "bg-gray-100"
                }  border-gray-100 py-1 border w-full flex-1 rounded-xl`}
              >
                <p className="w-full text-[13px] cursor-pointer text-center ">
                  Stock Out
                </p>
              </div>
            </div>
          </div>
        )}
        </div>
        </div>



      {/* Products Section */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {/* Conditionally render filtered products or top products */}
        {(filteredProducts.length > 0 || filterBy.keyword
          ? filteredProducts
          : topProducts
        )?.map((item) => (
          <div
            key={item.id}
            onClick={() => handleSelectedFood(item)}
            className="card bg-base-100 border-0 w-24"
          >
            {/* Image Section */}
            <figure className="">
              <img
                src={
                  item?.image_path
                    ? item?.image_path
                    : "https://image.pngaaa.com/13/1887013-middle.png"
                }
                alt={item?.name}
                className="w-24 h-24 object-cover rounded-xl"
              />
            </figure>

            {/* Content Section */}
            <div className="p-2 flex flex-col justify-between flex-grow">
              {/* Item Name */}
              <h1 className="text-[#262626] font-normal text-[10px]">
                {item?.name?.length > 28
                  ? `${item.name.slice(0, 28)}.....`
                  : item?.name}
              </h1>

              {/* Stock Information */}
              <p className="text-[8px] text-[#0A0A0A] font-bold">
                Stock: {item?.current_stock}
              </p>

              {/* Price always at the bottom */}
              <div className="mt-auto text-xs font-bold text-[#407BFF]">
                {item?.retails_price} BDT
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
