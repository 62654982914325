import React, { useState } from 'react';
import banner from '../../../assits/banner2.png';
import pic from '../../../assits/pic.png';
import { AiOutlineCamera } from 'react-icons/ai';

const Banner = () => {
    const [url, setUrl] = useState(null);
    const handleImg = (e) => {
        setUrl(URL.createObjectURL(e.target.files[0]));
    };

    return (
        <div className='w-full relative'>
            <div className=''>
            <img className='w-full' src={banner} alt='' />
            </div>
           
            {/* <div className='absolute  top-[45%] md:top-[53%] left-[40%] md:left-[45%]'> */}
            <div className='absolute top-[95%] 2xl:top-[87%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
                <div className='relative'>
                    <label htmlFor='drop' className='w-[60px] h-[60px] md:w-[101px] md:h-[101px]'>
                        <div className='w-[60px] h-[60px] md:w-[101px] md:h-[101px] drop-shadow-md p-[6px] rounded-full bg-[#fff]'>
                            <img
                                className='w-full h-full rounded-full'
                                src={url ?? pic}
                                alt=''
                            />
                        </div>
                    </label>
                    {/* <input type='file' name='' id='drop' hidden 
                    onChange={handleImg} 
                    /> */}
                    {/* <div className='flex justify-center items-center rounded-full bg-[#fff] p-[3px] absolute top-[84%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
                        <span className=''>
                            <AiOutlineCamera></AiOutlineCamera>
                        </span>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Banner;
