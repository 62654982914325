import React from "react";
import icon from "../assits/icon.svg";
import { useDropzone } from "react-dropzone";

const ImageUploader2 = ({ isBig, onChange, name, image }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Pass the accepted files to the onFileChange callback
      onChange(acceptedFiles,name);
    },
  });
  const files = acceptedFiles.map((file) => (
    <p key={file.path}>
      {file.path} - {file.size} bytes
    </p>
  ));

  return (
    <>
      <div
        className={`flex items-center justify-center bg-[#FFF] gap-1 px-1 py-3  border-dashed border-2 border-[#6D81B4] rounded-[22px] cursor-pointer relative`}
      >
        <div {...getRootProps({ className: "dropzone" })}>
          <label htmlFor="drop" className="cursor-pointer block">
            {acceptedFiles.length > 0 ? (
              <div className={`${isBig ? "w-20" : "w-10"} mx-auto `}>
                <img
                  className="w-20 object-cover flex items-center justify-center"
                  src={URL.createObjectURL(acceptedFiles[0])} // Display the first uploaded file
                  alt="Uploaded1"
                />
              </div>
            ) :  (image !== null && image !='http://127.0.0.1:8000/uploads/15-Islam/1690304960.jpg') ? (
              <div className={`${isBig ? "w-20" : "w-10"} mx-auto `}>
                <img
                  className="w-20 object-cover flex items-center justify-center"
                  src={image} // Display the first uploaded file
                  alt="Uploaded2"
                />
              </div>
            ) : (
              // Add additional conditions here if needed
              <div className={`${isBig ? "w-20" : "w-10"} mx-auto `}>
                <img className="w-20 object-contain flex items-center justify-center" src={`${process.env.REACT_APP_ROOT_URL + "/icon.svg"}`} alt="" />
              </div>
            )}
            <p
              className={`md:block text-[#3E4F71] text-center font-lato ${isBig ? "text-[9px]" : "text-[9px]"
                } font-bold mb-[7px]`}
            >
              {acceptedFiles.length > 0
                ? "Image uploaded"
                : "Drop your image here, or "}
              <span className="text-[#407BFF] block">Browse</span>
            </p>
            <p
            className={`text-[#3E4F71] font-lato ${isBig ? "text-[7px]" : "text-[7px]"
              } text-center  font-normal`}
            >
            Supports: JPG, JPEG, PNG
          </p>
          </label>
          <input
            {...getInputProps()}
            name={name}
          />
        </div>

      </div>
    </>

  );
};

export default ImageUploader2;
