import axios from "axios";
import React, { useContext, useState } from "react";
//import Select2 from "react-chosen";
import Resizer from "react-image-file-resizer";
import { useNavigate } from 'react-router-dom';
import { uploadFile, validateData } from "../utils/Common";
import { AppContext } from '../context/context';
import ImageUploader2 from './ImageUploader2';
import { get, post } from "../services/apiService";
import { FlashMessage } from "../utils/FlashMessage";

const BrandForm = props => {
  const {
    loadingCallback,
    setBrand,
    setWarningMessage,
    state,
    setShowModal,
    formId,
    setBrandData
  } = props;
  const {
        updateBrandList
    } = useContext(AppContext);
  const [country, setCountry] = useState('');
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const page = 1;
  const perPage = 20;
  const token = localStorage.getItem("token");
  const [inputErors, setInputErrors] = useState({
      name: ''
  });



  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadDetails, setUploadDetails] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState();
  const [brandfile, setBrandfile] = useState();

  const brandDataSave = (brandLogo) => {
      //getBrand();
      axios
      .post(
          `${process.env.REACT_APP_API_URL}save-brand`,
          { image_path: brandLogo.length > 0 ? brandLogo[0] : '', name: name, description: description },
          {
              headers: {
                  authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
              },
          }
      )
      .then((response) => {
          //console.log(response.data.success);
          loadingCallback(false);
          if(response.data.success)
          {
              setName("");
              setDescription("");
              setFile("");
              //getBrand();

              setBrandData('ok');
          }
          else
          {
              setName("");
              setDescription("");
              setFile("");
          }
      })
      .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
              navigate('/login');
              localStorage.setItem('token', '');
              localStorage.setItem('token', '');
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
          }
      });
  }
  const getBrand = () => {
    get("brands?page=1&limit=500", token)
      .then((response) => {
        // console.log(response.data.data.data);

        if (response.success) {
          //setLoader(false);
          console.log("============");
          console.log(response);
          updateBrandList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const validateForm = (inputName = "Brand") => {
    let valid = true;
    const newInputErrors = { ...inputErors };
    if (validateData(name, inputName)) {
      newInputErrors.name = validateData(name, inputName);
      valid = false;
    }
    setInputErrors(newInputErrors);
    return valid;
  };
  function handleSubmitBrand(e) {
      e.preventDefault();
      //setBrandData('ok');
      if (validateForm("Brand")) {
        loadingCallback(true);
        uploadFile(
            uploadedFiles,
            (brandLogo) => {
                // Success callback
                brandDataSave(brandLogo);
            },
            (error) => {
                // Error callback
                console.log(error);
                loadingCallback(false);
            },
            (progressDetails) => {
                // Progress callback, update uploadDetails here
                setUploadDetails(progressDetails);
            }
        );
      } else {
        console.log('Form validation failed.');
      }
  }

  const resizeFile = (file) =>
        new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            "JPEG",
            100,
            0,
            (uri) => {
            resolve(uri);
            },
            "base64"
        );
    });
    const handleImageChange = async (files, name) => {
        let tempUploadDetails = [...uploadDetails];
        const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        for (let i = 0; i < files.length; i++) {

        // Validate file type
        if (!allowedImageTypes.includes(files[i].type)) {
            // setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
            FlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,'error');
            console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
            // Handle invalid file type (display a message, set an error state, etc.)
            setIsValid(false);
            continue; // Skip the current iteration for invalid file types
        }

        let size = files[i].size / 1024;
        let sizeName = '';

        if (size > 1024) {
            size = (size / 1024).toFixed(2);
            sizeName = size + 'MB';
        } else {
            sizeName = size.toFixed(2) + 'KB';
        }
        const resizedImage = await resizeFile(files[i]);
        // Extract base64 data
        const base64Data = resizedImage.split(',')[1];

        // Convert base64 to Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

        // Create a File from Blob
        const file = new File([blob],files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

        tempUploadDetails.push({
            name: files[i].name,
            size: sizeName,
            percentage: 0,
            file: file,
            input_name: name,
        });
        }
        //console.log("============");
        //console.log(tempUploadDetails);
        setFile(URL.createObjectURL(files[0]));
        setUploadedFiles(files);
        setUploadDetails(tempUploadDetails);
    };

  return (
    <form
        method="post"
        className="col-sm-12"
        data-autosubmit="false"
        autoComplete="off"
        onSubmit={handleSubmitBrand}
        encType="multipart/form-data"
    >
        <input
            type='text'
            name="name"
            value={name}
            onChange={(e) => {
                const val = e.target.value;
                setName(val);
                setInputErrors({ ...inputErors, name: null });
            }}
            placeholder='Brand Name'
            className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
        />
        {inputErors.name
        ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
        : null}
        <div className="flex justify-between flex-col border border-opacity-[0.2px] w-full mb-[15px] rounded-2xl">
            <h4 className="pl-4 py-3">Brands Logo</h4>
            <ImageUploader2
                name={"file-input"}
                isBig={true}
                //onChange={(files) => handleImageChange("image_path", files)}
                onChange={handleImageChange}
                image={brandfile ? brandfile :null}
            />
        </div>
        <textarea
            name="description"
            value={description}
            onChange={(e) => {
                const val = e.target.value;
                setDescription(val);
            }}
            className='textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white'
            placeholder='Brand Description...'
        ></textarea>

        <div className="button-disabled">
            <button
                htmlFor='my_modal_3'
                type="submit"
                className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
            >
                {""}
                Save{""}
            </button>
        </div>
    </form>
  );
};

export default BrandForm;
