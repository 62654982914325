

import React, { useState } from "react";

import LogoAlignLeft from './bigLogo.png';
import { formatDate, formatPrice } from "../../utils/Common";
import BalanceHistoryPDFDetailss from "./BalanceHistoryPDFDetailss";
import MethodReport from "./MethodReport";



const BalancHistoryPDFDetails = (props) => {
  const { activeTab, balanceData, fullApiData, paymentList, paymentAccountList } = props;
  let createdDate, updatedDate;
  balanceData.map(bd => {
    createdDate = bd.created_at;
    updatedDate = bd.updated_at;
  })
  let renderedIndex = 0;

  return (
    <div id="balanceHistory">
      <div className="bg-white rounded-3xl font-nunito p-[26px] mb-20">
        <div className="flex justify-between items-center">
          {/* outlet logo */}
          <div className="w-20 cursor-pointer -pb-[4px]">
            <img src={LogoAlignLeft} className="w-full h-full object-contain" />
          </div>
          {/* title and date */}
          <div className="text-[#000232] text-right">
            <h2 className="text-sm font-semibold">Balance Report</h2>
            <p className="text-xs">{formatDate(createdDate)} - {formatDate(updatedDate)}</p>
          </div>
        </div>
        {/* table head */}
        <div className="grid text-center mt-4 pt-[4px] pb-[18px] items-center text-xs font-bold bg-[#000232] text-white px-3 grid-cols-10 border-b-[1px] border-[#E7EBF4]">

          <div className="text-left col-span-1">No.</div>
          <div className="col-span-2">Date/Times</div>
          <div className="col-span-2">Ac/Number</div>
          <div className="col-span-1">Status</div>
          <div className="col-span-1">Type</div>
          <div className="col-span-2">Ref ID</div>
          <div className="text-right col-span-1">Amount</div>
        </div>
        {/* table body component */}
        {balanceData.map((cData, index) =>
        (cData.completed &&
          (
            <BalanceHistoryPDFDetailss
              index={renderedIndex++}
              // setPos={setPos}
              paymentList={paymentList}
              paymentAccountList={paymentAccountList}
              balanceData={cData}
              tabActive={activeTab}
              key={index}
            />
          )
        )
        )
        }
        <hr className="border-[#000232] border-[1px]" />
        <div className="flex justify-center items-start">
          <div className="w-[38%] ml-auto text-xs font-[500]">
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Transaction Amount</p>
              {/* <p>{fullApiData?.total_transaction_amount}</p> */}
              <p>{formatPrice(fullApiData?.total_transaction_amount)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Credit Amount</p>
              {/* <p>{fullApiData?.total_transaction_in_amount}</p> */}
              <p>{formatPrice(fullApiData?.total_credit_amount)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Debit Amount</p>
              {/* <p>{fullApiData?.total_transaction_out_amount}</p> */}
              <p>{formatPrice(fullApiData?.total_debit_amount)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Revenue</p>
              {/* <p>{fullApiData?.total_balance}</p> */}
              <p>{formatPrice(fullApiData?.total_revenue)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Profit</p>
              <p>{formatPrice(fullApiData?.total_profit)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Purchase Discount</p>
              <p>{formatPrice(fullApiData?.total_purchase_discount ? fullApiData?.total_purchase_discount : 0)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Sales Discount</p>
              <p>{formatPrice(fullApiData?.total_sales_discount ? fullApiData?.total_sales_discount : 0)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Actual Profit</p>
              <p>{formatPrice(fullApiData?.actual_profit ? fullApiData?.actual_profit : 0)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Stock Value</p>
              <p>{formatPrice(fullApiData?.total_stock_value ? fullApiData?.total_stock_value : 0)}</p>
            </div>
            <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
              <p>Total Stock</p>
              <p>{formatPrice(fullApiData?.total_stock ? fullApiData?.total_stock : 0)}</p>
            </div>
          </div>
        </div>
      </div>
      <MethodReport balanceData={balanceData} paymentList={paymentList} fullApiData={fullApiData}/>
    </div>
  );
};

export default BalancHistoryPDFDetails;