import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import TransactionGraph from './TransactionGraph/TransactionGraph';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_ROOT_URL;
const TransactionReport = ({ categoryReportsSvg,
  totalCategorySvg,
  saleCategorySvg,
   dueAmountSvg,
    loadingCallback,
    WalletBlue,
    WalletGreen,
    WalletPurple,
    WalletOrrange,
     activeTab }) => {
  const [dropDown, setDropDown] = useState(false);
  //const navigate = useNavigate();
  const [salesTransactionChartData, setSalesTransactionChartData] = useState([]);
  const [purchaseTransactionChartData, setPurchaseTransactionChartData] = useState([]);
  const [transactionFetchedData, setTransactionFetchedData] = useState([]);
  const token = localStorage.getItem("token");

  //---------------------
  const location = useLocation();
  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      // Parse the employee data from local storage
      const employeeData = JSON.parse(employeeApiResponse);

      // Get the "Exporter" feature from the employee's role features
      const analyticsFeature = employeeData.role.features.find(
        (feature) => feature.name === "Analytics"
      );

      // Check if the "Exporter" feature and "Visible" option are both active
      if (analyticsFeature && analyticsFeature.status === 1) {

        // Grant access based on the route and permissions
        if (location.pathname === "/analytics") {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
      }
      setHasPermission(false);
      // If no permission, navigate to dashboard first
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
    // If not an employee, the useEffect gets skipped
  }, [navigate, location]);
  //---------------------

  const transactionDataState = {
    series: [{
      name: 'Sales',
      data: [31, 40, 300, 51, 42, 109, 100]
    }, {
      name: 'Purchase',
      data: [11, 32, 45, 32, 34, 52, 41]
    }],
    options: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        labels: {
          format: "yyyy",
        },
        type: 'datetime',
        categories: [
          "2018-09-19T00:00:00.000Z",
          "2018-09-19T01:30:00.000Z",
          "2018-09-19T02:30:00.000Z",
          "2018-09-19T03:30:00.000Z",
          "2018-09-19T04:30:00.000Z",
          "2018-09-19T05:30:00.000Z",
          "2018-09-19T06:30:00.000Z"
        ]
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
    },
  };

  const handleNavigate = () => {
    navigate('/transaction-history');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  const [transactionRevenueData, setTransactionRevenueData] = useState(transactionDataState);
  useEffect(() => {
    if (salesTransactionChartData.length > 0) {
      let xaxisTransactionSalesMonths = [];
      let xaxisTransactionSalesAmount = [];

      let xaxisTransactionPurchaseMonths = [];
      let xaxisTransactionPurchaseAmount = [];

      let xaxisFormat = "dd"; // Default format
      let xaxisType = "category"; // Default type

      // Check the type and set format accordingly
      if (salesTransactionChartData.length > 0) {
        if (activeTab === "monthly") {
          xaxisTransactionSalesMonths = salesTransactionChartData.toReversed().map((item) => item?.name);
          xaxisFormat = "MMM"; // Monthly format
          xaxisTransactionSalesAmount = salesTransactionChartData.toReversed().map((item) => item?.amount);

          xaxisTransactionPurchaseMonths = purchaseTransactionChartData.toReversed().map((item) => item?.name);
          xaxisTransactionPurchaseAmount = purchaseTransactionChartData.toReversed().map((item) => item?.amount);
        } else if (activeTab === "yearly") {
          xaxisTransactionSalesMonths = salesTransactionChartData.toReversed().map((item) => item?.name);
          xaxisFormat = "yyyy"; // yearly  format
          xaxisTransactionSalesAmount = salesTransactionChartData.toReversed().map((item) => item?.amount);

          xaxisTransactionPurchaseMonths = purchaseTransactionChartData.toReversed().map((item) => item?.name);
          xaxisTransactionPurchaseAmount = purchaseTransactionChartData.toReversed().map((item) => item?.amount);
        } else if (activeTab === "weekly") {
          xaxisTransactionSalesMonths = salesTransactionChartData.toReversed().map((item) => item?.name);
          xaxisFormat = "dd"; // Monthly format
          xaxisTransactionSalesAmount = salesTransactionChartData.toReversed().map((item) => item?.amount);

          xaxisTransactionPurchaseMonths = purchaseTransactionChartData.toReversed().map((item) => item?.name);
          xaxisTransactionPurchaseAmount = purchaseTransactionChartData.toReversed().map((item) => item?.amount);
        } else {
          xaxisFormat = 'dd'
          xaxisTransactionSalesMonths = salesTransactionChartData.toReversed().map((item) => item.name);
          xaxisTransactionSalesAmount = salesTransactionChartData.toReversed().map((item) => item.amount);
          xaxisTransactionPurchaseMonths = purchaseTransactionChartData.toReversed().map((item) => item?.name);
          xaxisTransactionPurchaseAmount = purchaseTransactionChartData.toReversed().map((item) => item?.amount);
        }
        // Add more conditions as needed
      }

      const transactionData = {
        series: [
          {
            name: 'Sales',
            data: xaxisTransactionSalesAmount
          },
          {
            name: 'Purchase',
            data: xaxisTransactionPurchaseAmount
          }
        ],
        options: {
          chart: {
            type: "area",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            labels: {
              format: xaxisFormat,
            },
            type: xaxisType,
            categories: xaxisTransactionPurchaseMonths,
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        },
      };

      // Now, revenueDataState has the updated series.data and options.xaxis.categories
      console.log(transactionData);
      setTransactionRevenueData(transactionData);
    }
  }, [salesTransactionChartData, purchaseTransactionChartData]);
  useEffect(() => {
    if(hasPermission){
      getTransactionReport(activeTab);
    }
  }, [activeTab,hasPermission]);

  const getTransactionReport = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}transaction-analysis?interval=${activeTab}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("==========");
        loadingCallback(false);
        if (response.data.success) {
          const fetchedData = response.data.data;
          setTransactionFetchedData(fetchedData);
          setSalesTransactionChartData(fetchedData?.sales_chart)
          setPurchaseTransactionChartData(fetchedData?.purchase_chart)
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);
          navigate("/login");
        }
      });
  };


  return (
    <div className="category-report rounded-[25px] bg-[#fff]   mt-4">
      <div className='flex justify-between items-center px-5 py-4 cursor-pointer' onClick={() => setDropDown(!dropDown)}>
        <div className='text-[#000232] font-semibold '>
          <p className='text-[25px]'>Transaction History</p>
        </div>
        <div  >
        <div className={`flex items-center justify-center w-[40px] h-[40px] transform duration-300 ${dropDown ? "rotate-180" : ""}`}>
          <FontAwesomeIcon icon={faAngleDown} className="text-[25px]" />
        </div>


        </div>
      </div>
      {dropDown && <div className='transform duration-500 px-5 py-4 '>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-2 '>
          <div className="left-cards p-3">
            <div className='grid grid-cols-1 md:grid-cols-2 gap-3 mb-3'>
              <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#FF7A00] border-2 border-solid border-[#FF7A00] flex justify-between py-8  px-3 rounded-xl">
                <div className="space-y-1">
                <img src={`${WalletOrrange}`}/>
                  <p>{transactionFetchedData?.total_transaction_amount}<sub className="text-[9px] font-extralight ml-1">BDT</sub></p>
                  <p className="text-sm font-extralight">Total Transaction </p>
                </div>
                <div className="text-right flex flex-col gap-2 justify-between">
                  <p className="">{transactionFetchedData?.total_transaction_amount_percent}</p>
                  <img
                    src={`${categoryReportsSvg}`}
                    alt="logo"
                  />
                </div>
              </div>
              <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#9B54E1] border-2 border-solid border-[#9B54E1] flex justify-between py-8  px-3 rounded-xl">
                <div className="space-y-1">
                <img src={`${WalletPurple}`}/>
                  <p>{transactionFetchedData?.total_transaction_count}</p>
                  <p className="text-sm font-extralight">Number of Transaction  </p>
                </div>
                <div className="text-right flex flex-col gap-2 justify-between">
                  <p className="">{transactionFetchedData?.total_transaction_count_percent}</p>
                  <img
                    src={`${totalCategorySvg}`}
                    alt="logo"
                  />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
              <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#29B170] border-2 border-solid border-[#29B170] flex justify-between py-8  px-3 rounded-xl">
                <div className="space-y-1">
                <img src={`${WalletGreen}`}/>
                  <p>{transactionFetchedData?.total_transaction_in_count}</p>
                  <p className="text-sm font-extralight">In Transaction </p>
                </div>
                <div className="text-right flex flex-col gap-2 justify-between">
                  <p className="">{transactionFetchedData?.total_transaction_in_percentage}</p>
                  <img
                    src={saleCategorySvg}
                    alt="logo"
                  />
                </div>
              </div>
              <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] bg-transparent text-[#347AE2] border-2 border-solid border-[#347AE2] flex justify-between py-7  px-3 rounded-xl">
                <div className="space-y-1">
                <img src={`${WalletBlue}`}/>
                  <p>{transactionFetchedData?.total_transaction_out_count}</p>
                  <p className="text-sm font-extralight">Out Transaction</p>
                </div>
                <div className="text-right flex flex-col gap-2 justify-between">
                  <p className="">{transactionFetchedData?.total_transaction_out_percentage}</p>
                  <img
                    src={dueAmountSvg}
                    alt="logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="  border-2 border-solid border-[#29B170]  rounded-[50px] p-3">
            <TransactionGraph
              sales_chart={transactionFetchedData?.sales_chart}
              transactionRevenueData={transactionRevenueData}
            />
          </div>
        </div>

        <div>
          <div className='text-[#000232] font-semibold '>
            <p className='text-[25px]'>Highest Sell Transaction</p>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 '>

            <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] col-span-1 bg-transparent text-[#9B54E1] border-2 border-solid border-[#9B54E1] flex justify-between py-8  px-3 rounded-xl">
              <div className="space-y-1">
                <FaUsers size={22} />
                <p className='whitespace-nowrap'>{transactionFetchedData?.highest_sales_amount}<sub className="text-[9px] font-extralight ml-1">BDT</sub></p>
                <p className="text-sm font-extralight">Invoice ID: {transactionFetchedData?.highest_sales_invoice}</p>
              </div>
              <div className="text-right flex flex-col gap-2 justify-between">
                <p className=""></p>
                <img
                  src={totalCategorySvg}
                  alt="logo"
                />
              </div>
            </div>

            <div className='font-lato text-[20px] col-span-2 p-3 rounded-lg border-2 shadow-[0_0_5px_rgba(0,0,0,0.1)]'>
              <div className='flex flex-col sm:flex-row items-center gap-2'>
                <p className="w-full sm:w-[190px]">Sell Transaction</p>
                <hr className='flex-grow bg-green-600 h-4'></hr>
                <p className='whitespace-nowrap'>{transactionFetchedData?.sell_transaction ? transactionFetchedData?.sell_transaction : 0}<sub className="text-[9px] font-extralight ml-1">BDT</sub></p>
              </div>
              <div className='flex flex-col sm:flex-row items-center gap-2'>
                <p className="w-full whitespace-nowrap sm:w-[190px]">Purchase Transaction</p>
                <hr className='flex-grow bg-green-600 h-4'></hr>
                <p className='whitespace-nowrap'>{transactionFetchedData?.purchase_transaction ? transactionFetchedData?.purchase_transaction: 0 }<sub className="text-[9px] font-extralight ml-1">BDT</sub></p>
              </div>
              <div className='flex flex-col sm:flex-row items-center gap-2'>
                <p className="w-full sm:w-[190px]">Expense</p>
                <hr className='flex-grow bg-green-600 h-4'></hr>
                <p className='whitespace-nowrap'>{transactionFetchedData?.expense ? transactionFetchedData?.expense: 0}<sub className="text-[9px] font-extralight ml-1">BDT</sub></p>
              </div>
              <div className='flex flex-col sm:flex-row items-center gap-2'>
                <p className="w-full sm:w-[190px]">Due Collection</p>
                <hr className='flex-grow bg-green-600 h-4'></hr>
                <p className='whitespace-nowrap'>{transactionFetchedData?.due ? transactionFetchedData?.due: 0}<sub className="text-[9px] font-extralight ml-1">BDT</sub></p>
              </div>
            </div>

          </div>
        </div>
        <div>

        </div>

        <div className="flex justify-end my-3" onClick={() => handleNavigate()}>
          <button className="border-b-2 cursor-pointer border-[#034AFF] text-[#034AFF] text-[16px]">View All</button>
        </div>

      </div>}
    </div>
  );
};

export default TransactionReport;