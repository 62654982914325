import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/context';
import { Outlet, useLocation } from 'react-router-dom';
import SubNav from '../SubNav/SubNav';
import Banner from '../settings/Settings_components/Banner';
import { AiOutlineSearch } from 'react-icons/ai';
import BannerV2 from './SettingsV2Components.jsx/BannerV2';
import MenuBarV2 from './SettingsV2Components.jsx/MenuBarV2';

const SettingsCompV2 = () => {
  const [searchKeyword, setSearchKeyword] = useState("aa");
  const {
      updateSearchKeyword
  } = useContext(AppContext);
  let location = useLocation();
  const pathName = location.pathname;
  // Define a function to handle changes in the searchKeyword
  const handleSearchChange = (keyword) => {
    setSearchKeyword(keyword);
    updateSearchKeyword(keyword);
  };
  useEffect(() => {
    updateSearchKeyword('');
  }, [pathName]);
  return (
      <div className="settings-comp mb-[10px]">
          <SubNav pageName='Settings' Breadcrumb='Home' page='/Settings'  handleSearchChange={handleSearchChange}></SubNav>
          <BannerV2 />
          {/* {pathName === "/settings" &&
                <div className="md:w-[356px] relative m-10 top-10 md:top-5">
                <input
                  autoFocus
                  type="text"
                  className="bg-[#E5EDFF] rounded-[11px] w-full pt-[17px] pr-[17px] pl-[21px] pb-[15px] placeholder:text-[#91919F]"
                  placeholder="Search Invoice"
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                    updateSearchKeyword(e.target.value);
                  }}
                />
                <div className="absolute top-5 right-[17px]">
                  <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                </div>
              </div>
          }
          {pathName === "/settings/category" &&
            <div className="md:w-[356px] relative m-10 top-10 md:top-5">
              <input
                autoFocus
                type="text"
                className="bg-[#E5EDFF] rounded-[11px] w-full pt-[17px] pr-[17px] pl-[21px] pb-[15px] placeholder:text-[#91919F]"
                placeholder="Search Category"
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                  updateSearchKeyword(e.target.value);
                }}
              />
              <div className="absolute top-5 right-[17px]">
                <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
              </div>
            </div>
          } */}

          {/* <MenuBar></MenuBar> */}
          <MenuBarV2 />
          <Outlet></Outlet>

      </div>
  );
};

export default SettingsCompV2;