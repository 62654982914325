import React, { useContext, useState, useRef, useEffect } from "react";
import { category } from "../../../data/data.js";
import { AppContext } from "../../../context/context.js";
import Pagination from "../../../utils/pagination.js";
import { BeatLoader } from "react-spinners";
import { get, post } from "../../../services/apiService.js";

import axios from "axios";
import { set } from "react-hook-form";
import Breadcrumb from "../SettingsV2Components.jsx/Breadcrumb.js";
import Table from "../SettingsV2Components.jsx/Table.js";
import { Helmet } from "react-helmet-async";

const CategoryV2 = () => {
  const { state, isLoading, updateCategoryList, updateSingleCategoryList } =
    useContext(AppContext);
  const { searchKeyword } = state;

  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const perPage = 20;

  useEffect(() => {
    getSingleCategoryList(currentPage, searchKeyword);
  }, [currentPage, searchKeyword]);
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const [category, setCategory] = useState(state.singleCategoryList);
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const getCategory = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}get-all-category`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          //setCategory(response.data.data);
          updateCategoryList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };
  const getSingleCategoryList = (page, searchKeyword) => {
    //loadingCallback(true)
    setLoader(true);
    if (searchKeyword !== "") {
      const reqData = { keyword: searchKeyword };
      post(`search-category?page=${page}&limit=${perPage}`, reqData, token)
        .then((response) => {
          setLoader(false);
          if (response.success) {
            loadingCallback(false);
            setCategory(response.data);
            updateSingleCategoryList(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}category?page=${page}&limit=${perPage}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setLoader(false);
          if (response.data.success) {
            loadingCallback(false);
            setCategory(response.data.data);
            updateSingleCategoryList(response.data.data);
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }
  };
  const shouldRenderPagination = state.singleCategoryList?.total > perPage;
  return (
    <div className="rounded-[27px]" style={{ backgroundColor: "#FAFAFA" }}>
      <Helmet>
        <title>Outlet Expense | Settings | Category</title>
      </Helmet>
      <Breadcrumb></Breadcrumb>
      <Table
        data={state.singleCategoryList}
        type="category"
        getCategory={getCategory}
        getSingleCategoryList={getSingleCategoryList}
      ></Table>
      {shouldRenderPagination && (
        <Pagination
          currentPage={currentPage}
          lastPage={state.singleCategoryList.last_page}
          onPageChange={onPageChange}
        />
      )}
      <div className="w-full relative px-[27px] overflow-x-auto sm:rounded-lg">
        {loader && <div className="custom-loader"></div>}
      </div>
      {/* {loading && (
                    <div className="overlay">
                        <BeatLoader color={'#123abc'} size={20} />
                    </div>
                )} */}
    </div>
  );
};

export default CategoryV2;
