import React, { useState } from "react";
import { LuSettings } from "react-icons/lu";
import { GrFormClose } from "react-icons/gr";
import {Link } from 'react-router-dom';
const Breadcrumb = ({ modalOne,modalTwo,modalThree,title }) => {
  const pathname = window.location.pathname;

  const parts = pathname.split("/");
  console.log(parts)
  let firstPart, secondPart;
  if (parts[2]) {
    [firstPart, secondPart] = parts[2].split("-");

  } else {
    console.log("Invalid pathname structure");
  }

  console.log(parts);

  return (
    <div>

      <div className="flex justify-between items-center px-10 md:px-[27px] pt-[29px] pb-[13px]">
        <h4 className="font-semibold font-lato text-[22px] text-[#000232] capitalize flex items-center gap-2">
          <p>
            {title ? title : firstPart ? firstPart : "Invoice List"} {secondPart}
          </p>{" "}
          <span className="text-[#10204869] text-lg">
            <LuSettings />
          </span>
        </h4>
        {parts[1]=='settings' && !parts[2] && (
          <p className="font-semibold font-lato text-xs text-[#034AFF] text-right">
              <Link className='' to='/invoice'>
                View All
              </Link>
          </p>
        )}
        {/* <p className="font-semibold font-lato text-xs text-[#034AFF] text-right">
          {firstPart ? (
            'View all'
          ) : (
            <Link className='' to='/invoice'>
              View All
            </Link>
          )}


        </p> */}
      </div>
    </div>
  );
};

export default Breadcrumb;
