import React from 'react';
import Breadcrumb from '../Settings_components/Breadcrumb';
import Table from '../Settings_components/Table';
import { help } from '../../../data/data';
const Help = () => {
    return (
        <div>
        <div className='rounded-[27px]' style={{backgroundColor: 'rgba(131, 52, 113, 0.15)'}}>
        <Breadcrumb></Breadcrumb>
      <Table data={help} type="help" ></Table>
       </div> 
      </div>
    );
};

export default Help;