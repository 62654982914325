import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import SelectBox from "./Select1";

const ExpenseCategoryEditForm = (props) => {
  const {
    expenseCategoryFormData,
    formData,
    setFormData,
    expenseCategoryId,
    setOpen,
    refetch,
    loadingCallback,
  } = props;

  const token = localStorage.getItem("token");

  const [txnCategoryList, setTxnCategoryList] = useState([])

  useEffect(() => {
    getTxnCategoryList()
  }, [])

  const getTxnCategoryList = (page, searchKeyword) => {
    loadingCallback(true);
    axios.get(`${process.env.REACT_APP_API_URL}get-transaction-statuses`, {
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
      }
    }).then((res) => {
      loadingCallback(false);
      if (res.data) {
        const transactionStatuses = res.data.map(item => item.transaction_status);
        setTxnCategoryList(transactionStatuses)
        console.log('Transaction Category List:', transactionStatuses);
      }
    }).catch(err => {
      loadingCallback(false)
      console.log(err)
    })
  };

  const handleTxnCategoryTypeChange = (selectedOption) => {
    console.log("Selected Option:", selectedOption);
    setFormData({
      ...formData,
      transaction_category: selectedOption.value,
    })
  }

  const handleSubmit = () => {
    loadingCallback(true);
    axios.post(`${process.env.REACT_APP_API_URL}update-expense-type/${expenseCategoryId}`, formData, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      loadingCallback(false);
      if (response.data.success) {
        console.log("Expense type updated successfully:", response.data.data);
        // Close the modal
        setOpen(false);
        // Trigger a refresh of the data to reflect the changes
        refetch();
      } else {
        console.log("Failed to update expense type:", response.data.errors);
      }
    })
    .catch((error) => {
      loadingCallback(false);  // Stop loading
      console.error("Error updating expense type:", error);
    });
  };


  return(
    <>
      <div className="duration-500">
        <div className="w-full relative">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
            Name
          </label>
          <input
            type="text"
            name="party_name"
            placeholder="Name"
            value={formData.expense_name || ''}
            onChange={(e) => setFormData({
              ...formData,
              expense_name: e.target.value
            })}
            className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
          />
        </div>
        <div className="flex flex-col ">
          <div className="w-full relative my-1">
            <label className=" font-nunito text-xs text-[#102048]  bg-white px-1 font-semibold">
              Expense Category
            </label>
              <SelectBox
                name="txnCategoryType"
                options={txnCategoryList}
                //value={txnCategoryList[0]}
                value={formData.transaction_category}
                onChange={handleTxnCategoryTypeChange}
                label={false}
                placeholder="Expense Category"
                className="w-full bg-[#FFF] rounded-[16px] focus:outline-none customSelect py-[1px]"
              />
            </div>
        </div>
        <div className="w-full relative mt-2">
          <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            Description
          </label>
          <textarea
            type="text"
            name="description"
            value={formData.expense_description || ''}
            onChange={(e) => setFormData({
              ...formData,
              expense_description: e.target.value
            })}
            placeholder="Text"
            className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full  h-20 pt-2 rounded-xl bg-white resize-none"
          />
        </div>
        <div>
          <button
            onClick={()=>handleSubmit()}
            className="cursor-pointer w-full mt-4 bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}

export default ExpenseCategoryEditForm;