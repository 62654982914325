import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LuSettings } from "react-icons/lu";
import { BeatLoader } from "react-spinners";
import { uploadFile, uploadFileMultipleInput } from "../../../utils/Common";
import ImageUploader2 from "../../../forms/ImageUploader2";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AppContext } from "../../../context/context";
import { get } from "../../../services/apiService";

import "./InvoiceSettings.scss";
import { FlashMessage } from "../../../utils/FlashMessage";
import InvoiceSettingFormV2 from "../../../forms/InvoiceSettingFormV2";
import { Helmet } from "react-helmet-async";
const BASE_URL = process.env.REACT_APP_ROOT_URL;

const InvoiceSettingsV2 = () => {
  const { state, updateInvoiceSetting } = useContext(AppContext);
  const [currency, setCurrency] = useState([]);
  const currentPage = 1;

  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const invoiceSettingData = state.invoiceSetting;

  const invoiceSetting = invoiceSettingData
    ? invoiceSettingData
    : {
        outlet_name: "",
        owner_name: "",
        shop_name: "",
        shop_logo: "",
        shop_address: "",
        sign_authority: "",
        name_authority: "",
        currency_id: "",
        category_id: "",
        watermark_text: "",
        watermark_type: "",
        vat: "",
        tax: "",
        condition: "",
      };
  const [logoFile, setLogoFile] = useState();
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadedFilesWatermark, setUploadedFilesWatermark] = useState({});
  const [uploadDetails, setUploadDetails] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const getCurrency = () => {
    get("currency", token)
      .then((response) => {
        if (response.success) {
          setCurrency(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCurrency();
  }, []);
  const invoiceSettingDataSave = (values, invoiceSetting) => {
    const { ...data } = values;
    //data.shop_logo = brandLogo.length > 0 ? brandLogo[0] : invoiceSetting && invoiceSetting.shop_logo ? invoiceSetting.shop_logo : null;
    //data.shop_logo = brandLogo.length > 0 ? brandLogo[0] : invoiceSetting && invoiceSetting.shop_logo ? invoiceSetting.shop_logo : null;
    axios
      .post(`${process.env.REACT_APP_API_URL}save-invoice-settings`, data, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          console.log("flash Message");
          FlashMessage("Successfully Updated!", "success");
          getInvoiceSetting();
          setLogoFile(data.shop_logo);
          if (data.watermark_type == "Logo") {
            setUploadedFilesWatermark(data.watermark_text);
          }
          //setShowModal(false);
          loadingCallback(false);
        } else {
          loadingCallback(false);
          setLogoFile("");
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  useEffect(() => {
    getInvoiceSetting();
  }, [currentPage]);
  const getInvoiceSetting = () => {
    loadingCallback(true);
    get("get-invoice-settings", token)
      .then((response) => {
        if (response.success) {
          updateInvoiceSetting(response.data);
          loadingCallback(false);
        }
        setLogoFile(response.data.shop_logo);
        loadingCallback(false);
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
      });
  };

  function handleSubmitInvoiceSetting(values, invoiceSetting) {
    let imageFileData = uploadDetails;
    if (values.watermark_type == "text") {
      imageFileData = uploadDetails.filter(
        (item) => item.input_name != "watermark_text"
      );
    }
    // console.log("============");
    // console.log(values);

    loadingCallback(true);

    uploadFileMultipleInput(
      imageFileData,
      (shopLogo) => {
        const updatedFormData = { ...values };
        shopLogo.forEach((item) => {
          updatedFormData[item.input_name] = item.path;
        });
        // Success callback
        invoiceSettingDataSave(updatedFormData, invoiceSetting);
      },
      (error) => {
        // Error callback
        console.log(error);
        loadingCallback(false);
      },
      (progressDetails) => {
        // Progress callback, update uploadDetails here
        setUploadDetails(progressDetails);
      }
    );

    loadingCallback(false);
  }

  const handleChange = ({ target: { files } }) => {
    let tempUploadDetails = [...uploadDetails];

    for (let i = 0; i < files.length; i++) {
      let size = files[i].size / 1024;
      let sizeName = "";

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + "MB";
      } else {
        sizeName = size.toFixed(2) + "KB";
      }

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
      });
    }

    setUploadDetails(tempUploadDetails);
    setUploadedFiles(files);

    setLogoFile(URL.createObjectURL(files[0]));
  };
  return (
    <div className="rounded-[27px]" style={{ backgroundColor: "#FAFAFA" }}>
      <Helmet>
        <title>Outlet Expense | Settings | Invoice Settings</title>
      </Helmet>
      {/* <Breadcrumb></Breadcrumb> */}
      {/* <Table data={setting} type="setting"></Table> */}
      <div className="flex justify-between items-center px-10 md:px-[27px] pt-[29px] pb-[13px]">
        <h4 className="font-semibold font-lato text-[22px] text-[#000232] capitalize flex items-center gap-2">
          <p>Invoice Settings</p>
          <span className="text-[#10204869] text-lg">
            <LuSettings />
          </span>
        </h4>

        {/* <p className="font-semibold font-lato text-xs text-[#034AFF] underline text-right">
                    <a href="#">View All</a>
                </p> */}
      </div>
      {loading && (
        <div className="custom-loader-logo">
          {/* <BeatLoader color={"#123abc"} size={20} /> */}
          <img
            className=""
            src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
            alt="logo"
          />
        </div>
      )}
      <InvoiceSettingFormV2
        initialValues={{ invoiceSetting }}
        onSubmit={(values) => {
          //const { ...updateValues } = values;
          handleSubmitInvoiceSetting(values, invoiceSetting);
        }}
        currentUser={state.currentUser}
        handleChange={handleChange}
        file={logoFile}
        uploadedFilesWatermark={uploadedFilesWatermark}
        currency={currency}
        setUploadedFiles={setUploadedFiles}
        setUploadedFilesWatermark={setUploadedFilesWatermark}
        uploadDetails={uploadDetails}
        setUploadDetails={setUploadDetails}
      />
    </div>
  );
};

export default InvoiceSettingsV2;
