import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LuSettings } from "react-icons/lu";
import { BeatLoader } from "react-spinners";
import { uploadFile, uploadFileMultipleInput } from "../../../utils/Common";
import ImageUploader2 from '../../../forms/ImageUploader2';
import { useFlashMessage } from '../../../context/FlashMessageContext';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AppContext } from "../../../context/context";
import { get, post } from "../../../services/apiService";

import "./ShopSettings.scss";
import ShopSettingForm from "../../../forms/ShopSettingForm";
import { FlashMessage } from "../../../utils/FlashMessage";
import Swal from "sweetalert2";
import { useLocation } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_ROOT_URL;

const ShopSettings = () => {
    const {
        state,
        updateInvoiceSetting,
        setShopCategoryList
    } = useContext(AppContext);
    //---------------------
    const location = useLocation();
    const navigate = useNavigate();
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
      const employeeApiResponse = localStorage.getItem("employee");

      if (!employeeApiResponse) {
        setHasPermission(true);
        return; // Exit useEffect early
      }

      if (employeeApiResponse) {
        // Parse the employee data from local storage
        const employeeData = JSON.parse(employeeApiResponse);

        // Get the "Exporter" feature from the employee's role features
        const settingsFeature = employeeData.role.features.find(
          (feature) => feature.name === "Settings"
        );

        // Check if the "Exporter" feature and "Visible" option are both active
        if (settingsFeature && settingsFeature.status === 1) {

          // Grant access based on the route and permissions
          if (location.pathname === "/settings/shop-settings") {
            setHasPermission(true);
            return; // Exit useEffect if permission is granted
          }
          if (location.pathname === "/settings") {
            setHasPermission(true);
            return; // Exit useEffect if permission is granted
          }
        }
        setHasPermission(false);
        // If no permission, navigate to dashboard first
        navigate("/dashboard", { replace: true });
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Permission Denied",
            text: "You do not have permission for this feature.",
          });
        }, 100);
      }
      // If not an employee, the useEffect gets skipped
    }, [navigate, location]);
  //---------------------
    const { getFlashMessageStyle, setSuccessFlashMessage,flashMessage,setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library

    const [currency, setCurrency] = useState([]);
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(null);

    console.log(profile)
    const loadingCallback = loading => {
        setLoading(loading);
    };

    const notify = (type, msg) =>{
        if(type=='success'){
            toast.success(msg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                // transition: Bounce,
            });
        }
        if(type=='error'){
            toast.error(msg, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                // transition: Bounce,
            });
        }
    }


    const shopSettingData = profile;

    const shopSetting = shopSettingData
        ? shopSettingData
        : {
            outlet_name: '',
            address: '',
            country: '',
            district: '',
            city: ''
        }

    const [logoFile, setLogoFile] = useState();
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [uploadedFilesWatermark, setUploadedFilesWatermark] = useState({});
    const [uploadDetails, setUploadDetails] = useState([]);
    //const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [warningMessage, setWarningMessage] = useState(false);
    const getCurrency = () => {
        get('currency', token)
            .then((response) => {
                if (response.success) {
                    setCurrency(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const getShopCategory = () => {
        get('shop-category', token)
            .then((response) => {
                if (response.success) {
                    setShopCategoryList(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const getProfile = () => {
        get('profile', token)
            .then((response) => {
                if (response.success) {
                    setProfile(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const getInvoiceSetting = () => {
        loadingCallback(true);
        get('get-invoice-settings', token)
            .then((response) => {
                if (response.success) {
                    updateInvoiceSetting(response.data);
                    loadingCallback(false);
                }
                loadingCallback(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    useEffect(() => {
        if (state?.currencyList?.length === 0) {
          if(hasPermission){
            getCurrency();
          }
        }
        if (state?.shopCategoryList?.length === 0) {
          if(hasPermission){
            getShopCategory();
          }
        }
        if(hasPermission){
          getProfile();
        }
    }, [hasPermission]);
    const shopSettingDataSave = (values, shopSetting) => {
        const { ...data } = values;
        //data.shop_logo = brandLogo.length > 0 ? brandLogo[0] : shopSetting && shopSetting.shop_logo ? shopSetting.shop_logo : null;
        //data.shop_logo = brandLogo.length > 0 ? brandLogo[0] : shopSetting && shopSetting.shop_logo ? shopSetting.shop_logo : null;
        axios
            .post(
                `${process.env.REACT_APP_API_URL}outlet-update`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((response) => {
                loadingCallback(true);
                if (response.data.success) {
                    getProfile();
                    getInvoiceSetting();
                    //setLogoFile(data.shop_logo);
                    // setSuccessFlashMessage('Saved successfully');
                    // notify('success', "Successfully Updated!");
                    FlashMessage("Successfully Updated!",'success');
                    //setShowModal(false);
                }
                loadingCallback(false);
            })
            .catch((error) => {
                console.log(error);
                loadingCallback(false);
                notify('error', "Someting went wrong!");
                // setWarningMessage(true);
                // const timer = setTimeout(() => {
                //   setWarningMessage(false);
                // }, 3000);
                if (error?.response?.status === 401) {
                    localStorage.clear();
                    localStorage.setItem('token', false);
                    // Clear session and local storage
                    localStorage.setItem('isPinVerify', false);

                    navigate('/login');
                }
            });
    };
    const [country, setCountry] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [countryList, setCountryList] = useState(state?.countryList);
    const [districtList, setDistrictList] = useState([]);
    const [cityList, setCityList] = useState([]);


    const getDistrictList = (country) => {
        post("get-district-by-country", { country_id: country }, token)
        .then((response) => {
            if (response.success) {
            setDistrictList(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const getCityList = (district) => {
        setDistrict(district);
        post("get-cities-by-district", { district_id: district }, token)
        .then((response) => {
            if (response.success) {
                setCityList(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (shopSetting && shopSetting.country) {
            setCountry(shopSetting.country);
            if(hasPermission){
              getDistrictList(shopSetting.country);
            }

        }
        if (shopSetting && shopSetting.district) {
            setDistrict(shopSetting.district);
            getCityList(shopSetting.district);
        }
        else {
          if(hasPermission){
            getDistrictList(country);
          }
        }

    }, [country, shopSetting, hasPermission]);

    // Function to handle changes in the input fields
    const handleChangeSelect = (name, option) => {
        // prevents user from selecting initial dummy option
//    if(!option?.value){
//     console.log("you haven't selected anything")
//     return;
//     }
        const { value, label } = option;
        setProfile({
            ...profile,
            [name]: value,
        });
        if (name === 'country') {
            setCountry(value);
        }
        if (name === 'district') {
            setDistrict(value);
        }
    };



    function handleSubmitShopSetting(values, shopSetting) {
        let imageFileData = uploadDetails;

        // uploadFileMultipleInput(
        //     imageFileData,
        //     (shopLogo) => {
                const updatedFormData = { ...values };
                // shopLogo.forEach((item) => {
                //     updatedFormData[item.input_name] = item.path;
                // });
                // Success callback
                shopSettingDataSave(updatedFormData, shopSetting);
            // },
            // (error) => {
            //     // Error callback
            //     console.log(error);
            //     loadingCallback(false);
            // },
            // (progressDetails) => {
            //     // Progress callback, update uploadDetails here
            //     setUploadDetails(progressDetails);
            // }
        //);
    }

    const handleChange = (e) => {
      const { name, value } = e.target;
      setProfile({
          ...profile,
          [name]: value,
      });
    };
    const handleNavigate = () => {
      navigate("/settings/branch-list");
    };
    return (
      <div
        className="rounded-[27px]"
        style={{ backgroundColor: "rgba(18, 203, 196, 0.15)" }}
      >
        <ToastContainer />
        {/* <Breadcrumb></Breadcrumb> */}
        {/* <Table data={setting} type="setting"></Table> */}

        <div className="flex justify-between items-center px-10 md:px-[27px] pt-[29px] pb-[13px]">
          <h4 className="font-semibold font-lato text-[22px] text-[#000232] capitalize flex items-center gap-2">
            <p>Shop Settings</p>
            <span className="text-[#10204869] text-lg">
              <LuSettings />
            </span>
          </h4>

          {/* <p className="font-semibold font-lato text-xs text-[#034AFF] underline text-right">
                    <a onClick={()=>handleNavigate()}>View All</a>
                </p> */}
        </div>
        {warningMessage && (
          <div className="warning-message">something wrong!</div>
        )}
        {flashMessage && (
          <div
            className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
              flashMessage.type
            )}`}
          >
            {flashMessage.text}
          </div>
        )}
        {loading && (
          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img
              className=""
              src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
              alt="logo"
            />
          </div>
        )}
        <ShopSettingForm
          initialValues={shopSetting}
          onSubmit={(values) => {
            //const { ...updateValues } = values;
            handleSubmitShopSetting(values, shopSetting);
          }}
          state={state}
          countryList={countryList}
          districtList={districtList}
          cityList={cityList}
          transparentBorder={true}
          handleChangeSelect={handleChangeSelect}
          handleChange={handleChange}
          file={logoFile}
          uploadedFilesWatermark={uploadedFilesWatermark}
          currency={currency}
          setUploadedFiles={setUploadedFiles}
          setUploadedFilesWatermark={setUploadedFilesWatermark}
          uploadDetails={uploadDetails}
          setUploadDetails={setUploadDetails}
        />
      </div>
    );
};

export default ShopSettings;