// import React from 'react';

// const TransactionGraph = () => {
//   return (
//     <div>

//     </div>
//   );
// };

// export default TransactionGraph;

import React, { useState } from 'react';
import "./TransactionGraph.css";

import ReactApexChart from 'react-apexcharts';

const TransactionGraph = ({transactionRevenueData, sales_chart}) => {
  return (
    <>
      <div className="chart-info w-full">
        <div className="chart-info-title">
          <p className='text-[24px]'>Sale vs Purchase Transaction Chart</p>
        </div>
      </div>
      <div id="category-chart">
        <ReactApexChart options={transactionRevenueData?.options} series={transactionRevenueData?.series} type="area" height={300} />
      </div>
    </>
  );
};

export default TransactionGraph;
